import { CSSProperties, FC } from 'react';
import styles from './SocialIconList.module.css';
import {
  FacebookIcon,
  InstagramIcon,
  TelegramIcon,
  ViberIcon,
} from 'assets/icons';
import { SocialIcon } from './component/SocialIcon';
import classNames from 'classnames';

const icons = [
  {
    name: 'Instagram',
    icon: InstagramIcon,
    link: 'https://www.instagram.com/mamapro_media/',
  },
  {
    name: 'Telegram',
    icon: TelegramIcon,
    link: 'https://t.me/mama_pro_official_bot',
  },
  {
    name: 'Facebook',
    icon: FacebookIcon,
    link: 'https://www.facebook.com/mamapromedia',
  },
  {
    name: 'Viber',
    icon: ViberIcon,
    link: 'https://vb.me/mamapro_community_pw',
  },
];

export const SocialIconsList: FC<{
  isScrolled: boolean;
  big?: boolean;
  containerStyle?: CSSProperties;
  socialIconsListStyle?: CSSProperties;
  iconExt?: [];
}> = ({ isScrolled, big, containerStyle, socialIconsListStyle }) => {
  return (
    <div
      style={socialIconsListStyle}
      className={classNames(styles.container, big && styles.bigContainer)}
    >
      {icons.map((icon) => (
        <SocialIcon
          key={icon.name}
          icon={icon.icon}
          isScrolled={isScrolled}
          big={big}
          link={icon.link}
          containerStyle={containerStyle}
        />
      ))}
    </div>
  );
};
