import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Expert } from 'types/items/Expert';

const initialState: {
  data: Expert[];
} = {
  data: [],
};

export const expertsSlice = createSlice({
  name: 'experts',
  initialState,
  reducers: {
    saveExperts: (state, action: PayloadAction<Expert[]>) => {
      state.data = action.payload;
    },
  },
});

const { reducer, actions } = expertsSlice;

export const { saveExperts } = actions;

export default reducer;
