import './Auth.css';
import { FC, useCallback } from 'react';
import { useFormik } from 'formik';
import { getSingUpValidationSchema } from './validations/AuthValidationSchems';
import { FormFields } from './validations/constants';
import { AuthErrorCode } from './constants/constants';
import { useTranslate } from 'hooks/useTranslate';
import { AppRoutes } from 'navigation/constants';
import ArrowNavButton from 'components/Buttons/ArrowNavButton/ArrowNavButton';
import StepsLabel from './components/StepsLabel/StepsLabel';
import { STEPS_SIGNUP } from './constants/steps';
import Footer from 'components/Footer/Footer';
import SubmitButton from './components/SubmitButton/SubmitButton';
import { Title } from 'components/Text/Title/Title';
import Input from './components/Input/Input';
import ErrorMessage from 'components/Messages/ErrorMessage/ErrorMessage';
import { useAuth } from './hooks/useAuth';
import { useAppDispatch } from 'store/store';
import { resetOnboarding } from './Onboarding/store/OnboardingSlice';

const { NAME, EMAIL, PASSWORD, CONFIRM_PASSWORD } = FormFields;

export const SignUpByEmailScreen: FC = () => {
  const t = useTranslate();

  const { emailRegistration } = useAuth();

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      [NAME]: '',
      [EMAIL]: '',
      [PASSWORD]: '',
      [CONFIRM_PASSWORD]: '',
    },
    validationSchema: getSingUpValidationSchema(),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values, { setStatus, resetForm }) => {
      // resetForm();

      emailRegistration(
        values[NAME],
        values[EMAIL].trim(),
        values[PASSWORD],
        (code: AuthErrorCode) => setStatus({ error: code })
      );
    },
  });

  const handleSingUp = useCallback(() => {
    formik.handleSubmit();
  }, [formik]);

  const handleChangeFocus = (isFocused: boolean) => {
    if (isFocused) {
      formik.setStatus({});
      formik.setErrors({});
    }
  };

  return (
    <>
      <header className="auth-header">
        <div className="auth-container auth-header-container">
          <ArrowNavButton
            title={t('sing_up_come_in')}
            to={AppRoutes.LOGIN}
            cb={() => dispatch(resetOnboarding())}
          />
          <StepsLabel step={STEPS_SIGNUP} />
        </div>
      </header>
      <main className="auth-main">
        <div className="auth-container flex-column-center">
          <Title
            text={t('sing_up_title')}
            highlight={t('sing_up_title_highlight')}
            center
          />
          <div className="auth-inputs-container">
            <Input
              id={NAME}
              label={t('sing_up_placeholder_name')}
              value={formik.values[NAME]}
              onChange={formik.handleChange}
              onChangeFocus={handleChangeFocus}
              error={!formik.isValid}
            />
            <Input
              id={EMAIL}
              label={t('sing_up_placeholder_email')}
              value={formik.values[EMAIL]}
              onChange={formik.handleChange}
              onChangeFocus={handleChangeFocus}
              error={!formik.isValid}
            />
            <Input
              id={PASSWORD}
              label={t('sing_up_placeholder_password')}
              value={formik.values[PASSWORD]}
              onChange={formik.handleChange}
              onChangeFocus={handleChangeFocus}
              error={!formik.isValid}
              secret
            />
            <Input
              id={CONFIRM_PASSWORD}
              label={t('sing_up_placeholder_confirm_password')}
              value={formik.values[CONFIRM_PASSWORD]}
              onChange={formik.handleChange}
              onChangeFocus={handleChangeFocus}
              error={!formik.isValid}
              secret
            />
            {!formik.isValid && (
              <ErrorMessage
                message={
                  formik.errors[CONFIRM_PASSWORD]
                    ? t('sing_up_confirm_password_error')
                    : t('sing_up_error')
                }
              />
            )}
            {formik.status?.error && (
              <ErrorMessage message={t(formik.status.error)} />
            )}
          </div>
          <div className="auth-create-account-container">
            <SubmitButton
              title={t('sing_up_create_account')}
              onClick={handleSingUp}
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
