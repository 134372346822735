import { useTranslate } from 'hooks/useTranslate';
import { noop } from 'lodash';
import { CSSProperties, ChangeEvent, FC } from 'react';
import styles from './ComeInButton.module.css';

import ErrorMessage from 'components/Messages/ErrorMessage/ErrorMessage';
import { ProviderRemoval } from 'features/Auth/constants/constants';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { ModalButton } from '../ModalButton/ModalButton';

export const ComeInButton: FC<{
  containerStyle?: CSSProperties;
  title: string;
  icon: any;
  onPress: () => void;
  handleCancel?: (event: ChangeEvent<HTMLInputElement>) => void;
  provider: ProviderRemoval.APPLE | ProviderRemoval.GOOGLE;
  isError?: string;
}> = ({
  title,
  icon: Icon,
  onPress = noop,
  provider,
  handleCancel,
  isError,
}) => {
  const t = useTranslate();
  const { isMobile } = useAppWindowSize();
  return (
    <div className={styles.containerButtons}>
      <div
        className={
          provider === ProviderRemoval.GOOGLE
            ? styles.container
            : styles.containerFull
        }
        onClick={onPress}
      >
        {<Icon />}
        <p className={styles.title}>{t(title)}</p>
      </div>
      {!!isError && isMobile && (
        <>
          <ErrorMessage message={t(isError)} container={{ marginBottom: 10 }} />{' '}
        </>
      )}
      <ModalButton
        title={t('settings_delete_account_module_cancel_action')}
        // @ts-ignore
        onPress={handleCancel}
      />
    </div>
  );
};
