import { FC } from "react";
import styles from './FounderSection.module.css';
import { useTranslate } from "hooks/useTranslate";
import { Title } from "components/Text/Title/Title";
import photo from "assets/founder/photo.png"
import { InstagramIcon } from "assets/icons";


export const FounderSection: FC<{
  aboutRef: any;
}> = ({
  aboutRef,
}) => {
  const t = useTranslate();

  return (
    <section className={ styles.section }>
      <div ref={ aboutRef } id='about' className={ styles.anchor }></div>
      <div className={ styles.container }>
        <Title 
          text={ t('основатель Mama Pro') }
          highlight={ t('основатель') }
        />
        <div className={ styles.wrapper }>
          <img src={ photo } className={ styles.photo }  alt="founder"/>
          <div className={ styles.descriptionContainer }>
            <div className={ styles.name }>
              { t('Диана Мардас') }
            </div>
            <div className={ styles.description }>
              { t('Врач акушер-гинеколог, организатор здравоохранения, предприниматель') }
            </div>
            <ul className={ styles.list }>
              <li>Высшее медицинское образование;</li>
              <li>10+ лет опыта работы в роддоме;</li>
              <li>Автор более 50 научных публикаций;</li>
              <li>Автор медицинского блога в Instagram с более чем 60 тыс. подписчиков;</li>
              <li>Обладатель премии Forbes Women Awards Belarus 30 за социальную миссию проекта MAMA PRO.</li>
            </ul>
            <a 
              href={ 'https://www.instagram.com/doctor_diana_mardas/' } 
              className={ styles.instButton } 
              target="_blank" 
              rel="noreferrer"
            > 
              <InstagramIcon className={ styles.icon }/>
              Instagram
            </a>
          </div>
        </div>
      </div>
    </section>
  )
};