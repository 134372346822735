import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import styles from './DetailsButton.module.css';
import { Link } from 'react-router-dom';

export const DetailsButton: FC = () => {
  const t = useTranslate();

  return (
    <Link to="/waiting-page" className={styles.container}>
      {t('home_mzhr_details')}
    </Link>
  );
};
