type VideoComponentProps = {
  link: string;
  height?: number | string;
  width?: number | string;
  maxHeight?: number | string;
  maxWidth?: number | string;
};

function VideoComponent({
  link,
  height,
  width,
  maxHeight,
  maxWidth,
}: VideoComponentProps) {
  return (
    <iframe
      src={link}
      width={width ? width : '100%'}
      height={height}
      style={{
        border: 'none',
        outline: 'none',
        height: height,
        width: width ? width : '100%',
        borderRadius: 30,
        maxWidth: maxWidth,
        maxHeight: maxHeight,
      }}
    ></iframe>
  );
}

export default VideoComponent;
