import {
  b2c3q1,
  b2c3q2,
  b2c3q3,
  b2c4q1,
  b2c4q2,
  b2c4q3,
  b2c5q1,
  b2c5q2,
  b2c5q3,
  b2c6q1,
  b2c6q2,
  b2c6q3,
  b2c7q1,
  b2c7q2,
  b2c7q3,
} from 'assets/images';

export const b2 = {
  c3: {
    title: 'b2_c3_title',
    author: 'b2_c3_author',
    description: 'b2_c3_description',
    lessonsLength: 11,
    video: 'https://vimeo.com/showcase/9263360/embed',
    tests: [
      {
        title: 'b2_c3_tests_title',
        question: 'b2_c3_test1_question',
        answers: {
          a1: 'b2_c3_test1_answer_a1',
          a2: 'b2_c3_test1_answer_a2',
          a3: 'b2_c3_test1_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b2_c3_test1_wrongAnswer',
        correctAnswer: 'b2_c3_test1_correctAnswer',
        image: b2c3q1,
      },
      {
        title: 'b2_c3_tests_title',
        question: 'b2_c3_test2_question',
        answers: {
          a1: 'b2_c3_test2_answer_a1',
          a2: 'b2_c3_test2_answer_a2',
          a3: 'b2_c3_test2_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b2_c3_test2_wrongAnswer',
        correctAnswer: 'b2_c3_test2_correctAnswer',
        image: b2c3q2,
      },
      {
        title: 'b2_c3_tests_title',
        question: 'b2_c3_test3_question',
        answers: {
          a1: 'b2_c3_test3_answer_a1',
          a2: 'b2_c3_test3_answer_a2',
          a3: 'b2_c3_test3_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b2_c3_test3_wrongAnswer',
        correctAnswer: 'b2_c3_test3_correctAnswer',
        image: b2c3q3,
      },
    ],
  },
  c4: {
    title: 'b2_c4_title',
    author: 'b2_c4_author',
    description: 'b2_c4_description',
    lessonsLength: 7,
    video: 'https://vimeo.com/showcase/9263377/embed',
    tests: [
      {
        title: 'b2_c4_tests_title',
        question: 'b2_c4_test1_question',
        answers: {
          a1: 'b2_c4_test1_answer_a1',
          a2: 'b2_c4_test1_answer_a2',
          a3: 'b2_c4_test1_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b2_c4_test1_wrongAnswer',
        correctAnswer: 'b2_c4_test1_correctAnswer',
        image: b2c4q1,
      },
      {
        title: 'b2_c4_tests_title',
        question: 'b2_c4_test2_question',
        answers: {
          a1: 'b2_c4_test2_answer_a1',
          a2: 'b2_c4_test2_answer_a2',
          a3: 'b2_c4_test2_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b2_c4_test2_wrongAnswer',
        correctAnswer: 'b2_c4_test2_correctAnswer',
        image: b2c4q2,
      },
      {
        title: 'b2_c4_tests_title',
        question: 'b2_c4_test3_question',
        answers: {
          a1: 'b2_c4_test3_answer_a1',
          a2: 'b2_c4_test3_answer_a2',
          a3: 'b2_c4_test3_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b2_c4_test3_wrongAnswer',
        correctAnswer: 'b2_c4_test3_correctAnswer',
        image: b2c4q3,
      },
    ],
  },
  c5: {
    title: 'b2_c5_title',
    author: 'b2_c5_author',
    description: 'b2_c5_description',
    lessonsLength: 9,
    video: 'https://vimeo.com/showcase/9263394/embed',
    tests: [
      {
        title: 'b2_c5_tests_title',
        question: 'b2_c5_test1_question',
        answers: {
          a1: 'b2_c5_test1_answer_a1',
          a2: 'b2_c5_test1_answer_a2',
          a3: 'b2_c5_test1_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b2_c5_test1_wrongAnswer',
        correctAnswer: 'b2_c5_test1_correctAnswer',
        image: b2c5q1,
      },
      {
        title: 'b2_c5_tests_title',
        question: 'b2_c5_test2_question',
        answers: {
          a1: 'b2_c5_test2_answer_a1',
          a2: 'b2_c5_test2_answer_a2',
          a3: 'b2_c5_test2_answer_a3',
        },
        correctAnswerId: 3,
        wrongAnswer: 'b2_c5_test2_wrongAnswer',
        correctAnswer: 'b2_c5_test2_correctAnswer',
        image: b2c5q2,
      },
      {
        title: 'b2_c5_tests_title',
        question: 'b2_c5_test3_question',
        answers: {
          a1: 'b2_c5_test3_answer_a1',
          a2: 'b2_c5_test3_answer_a2',
          a3: 'b2_c5_test3_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b2_c5_test3_wrongAnswer',
        correctAnswer: 'b2_c5_test3_correctAnswer',
        image: b2c5q3,
      },
    ],
  },
  c6: {
    title: 'b2_c6_title',
    author: 'b2_c6_author',
    description: 'b2_c6_description',
    lessonsLength: 8,
    video: 'https://vimeo.com/showcase/9263410/embed',
    tests: [
      {
        title: 'b2_c6_tests_title',
        question: 'b2_c6_test1_question',
        answers: {
          a1: 'b2_c6_test1_answer_a1',
          a2: 'b2_c6_test1_answer_a2',
          a3: 'b2_c6_test1_answer_a3',
        },
        correctAnswerId: 3,
        wrongAnswer: 'b2_c6_test1_wrongAnswer',
        correctAnswer: 'b2_c6_test1_correctAnswer',
        image: b2c6q1,
      },
      {
        title: 'b2_c6_tests_title',
        question: 'b2_c6_test2_question',
        answers: {
          a1: 'b2_c6_test2_answer_a1',
          a2: 'b2_c6_test2_answer_a2',
          a3: 'b2_c6_test2_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b2_c6_test2_wrongAnswer',
        correctAnswer: 'b2_c6_test2_correctAnswer',
        image: b2c6q2,
      },
      {
        title: 'b2_c6_tests_title',
        question: 'b2_c6_test3_question',
        answers: {
          a1: 'b2_c6_test3_answer_a1',
          a2: 'b2_c6_test3_answer_a2',
          a3: 'b2_c6_test3_answer_a3',
        },
        correctAnswerId: 3,
        wrongAnswer: 'b2_c6_test3_wrongAnswer',
        correctAnswer: 'b2_c6_test3_correctAnswer',
        image: b2c6q3,
      },
    ],
  },

  c7: {
    title: 'b2_c7_title',
    author: 'b2_c7_author',
    description: 'b2_c7_description',
    last: true,
    lessonsLength: 9,
    video: 'https://vimeo.com/showcase/9263427/embed',
    tests: [
      {
        title: 'b2_c7_tests_title',
        question: 'b2_c7_test1_question',
        answers: {
          a1: 'b2_c7_test1_answer_a1',
          a2: 'b2_c7_test1_answer_a2',
          a3: 'b2_c7_test1_answer_a3',
        },
        correctAnswerId: 3,
        wrongAnswer: 'b2_c7_test1_wrongAnswer',
        correctAnswer: 'b2_c7_test1_correctAnswer',
        image: b2c7q1,
      },
      {
        title: 'b2_c7_tests_title',
        question: 'b2_c7_test2_question',
        answers: {
          a1: 'b2_c7_test2_answer_a1',
          a2: 'b2_c7_test2_answer_a2',
          a3: 'b2_c7_test2_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b2_c7_test2_wrongAnswer',
        correctAnswer: 'b2_c7_test2_correctAnswer',
        image: b2c7q2,
      },
      {
        title: 'b2_c7_tests_title',
        question: 'b2_c7_test3_question',
        answers: {
          a1: 'b2_c7_test3_answer_a1',
          a2: 'b2_c7_test3_answer_a2',
          a3: 'b2_c7_test3_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b2_c7_test3_wrongAnswer',
        correctAnswer: 'b2_c7_test3_correctAnswer',
        image: b2c7q3,
      },
    ],
  },
};
