import { FC, memo } from "react";
import styles from './TitleH3.module.css';


export const TitleH3: FC<{
  text: string;
}> = memo(({ 
  text,
}) => (
  <div className={ styles.text }>
    { text }
  </div>
));
