import { CourseData } from 'types';
import styles from './CourseInfoBlocks.module.css';
import { CourseInfoBlock } from '../CourseInfoBlock/CourseInfoBlock';
import { CourseInfoBlockFiller } from '../CourseInfoBlock/CourseInfoBlockFiller';
import { useAppWindowSize } from 'hooks/useAppWindowSize';

type CourseInfoBlocksProps = {
  content: CourseData[];
};

export const CourseInfoBlocks = ({ content }: CourseInfoBlocksProps) => {
  const { isMobile } = useAppWindowSize();

  return (
    <div className={styles.container}>
      {content.map((item, index) => (
        <CourseInfoBlock
          item={item}
          image={item.image}
          type={item.type}
          description={item.description}
          title={item.title}
          key={index}
          id={item.id}
        />
      ))}
      {!isMobile && (
        <>
          <CourseInfoBlockFiller />
          <CourseInfoBlockFiller />
          <CourseInfoBlockFiller />
        </>
      )}
    </div>
  );
};
