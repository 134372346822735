import { CourseList } from './components/CourseList/CourseList';
import { CourseData, RecommendationType } from 'types';
import styles from './CommonCoursesCategory.module.css';
import { CourseInfoBlocks } from './components/CourseInfoBlocks/CourseInfoBlocks';

import { CourseButton } from './components/CourseButton/CourseButton';
import {
  useFilteredContent,
  usePagination,
} from './CommonCoursesCategory.utils';
import { EmptyCourses } from 'components/EmptyCourses/EmptyCourses';
import { EmptyFavorite } from 'features/Profile/FavoritePage/EmptyFavorite/EmptyFavorite';
import { useNavigate, useParams } from 'react-router-dom';
import { getUrlType } from 'utils/other';
import { useState } from 'react';

type CommonCoursesCategoryProps = {
  content: CourseData[];
  title: string;
  isFavorite?: boolean;
  withoutButton?: boolean;
  isCourseList?: boolean;
  expertId?: string;
};

export const CommonCoursesCategory = ({
  content,
  isFavorite,
  withoutButton,
  isCourseList,
  expertId,
}: CommonCoursesCategoryProps) => {
  const { categoryId } = useParams();

  const navigate = useNavigate();
  const { handleButton, getEndIndex } = usePagination(12);
  const [type, setType] = useState<RecommendationType | null>(null);
  const filteredContent = useFilteredContent(
    content,
    (type as RecommendationType) || null,
    getEndIndex()
  );
  const filteredWithEnabled = filteredContent.filter((item) => item?.enable);
  const handleButtonClick = (selectedType: RecommendationType | null) => {
    setType(selectedType);
    // navigate(
    //   `/edu/${getCategoryId(categoryId)}${
    //     selectedType ? getUrlType(selectedType) : ''
    //   }`
    // );
  };

  const handleButtonExpertClick = (selectedType: RecommendationType | null) => {
    navigate(
      `/experts/${expertId}${
        selectedType ? '/' + getUrlType(selectedType) : ''
      }`
    );
  };

  const sortedContent = filteredWithEnabled.sort((a, b) => {
    const dateA = a?.createdDate;
    const dateB = b?.createdDate;

    if (dateA === null || dateA === undefined) {
      return 1;
    }
    if (dateB === null || dateB === undefined) {
      return -1;
    }

    if (dateA > dateB) {
      return -1;
    }
    if (dateA < dateB) {
      return 1;
    }
    return 0;
  });

  return (
    <div className={styles.blocksWrapper}>
      {(!!content.length && isFavorite) ||
        (isCourseList && (
          <CourseList
            handleButtonClick={
              expertId?.length ? handleButtonExpertClick : handleButtonClick
            }
            type={(type as RecommendationType) || null}
          />
        ))}
      {!!sortedContent.length ? (
        <>
          {!isFavorite && !isCourseList && (
            <CourseList
              handleButtonClick={
                expertId?.length ? handleButtonExpertClick : handleButtonClick
              }
              type={(type as RecommendationType) || null}
            />
          )}

          <CourseInfoBlocks content={sortedContent} />
          {!withoutButton && (
            <>
              {sortedContent.length > 0 &&
                sortedContent.length >= getEndIndex() && (
                  <CourseButton handleButton={handleButton} />
                )}
            </>
          )}
        </>
      ) : isFavorite ? (
        <EmptyFavorite type={type} />
      ) : (
        <EmptyCourses />
      )}
    </div>
  );
};
