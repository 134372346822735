import { RecommendationType } from 'types';
import { courseTypeSelectorConfig } from '../CourseList.utils';
import styles from './CourseList.module.css';

type CourseListProps = {
  handleButtonClick: (type: RecommendationType | null) => void;
  type: RecommendationType | null;
};

export const CourseList = ({ handleButtonClick, type }: CourseListProps) => {
  const handleCourseClick = (type: RecommendationType | null) => {
    handleButtonClick(type);
  };

  return (
    <div className={styles.coursesList}>
      {courseTypeSelectorConfig.map((item) => (
        <button
          key={item.id}
          className={
            item.type === type ? styles.activeContainer : styles.container
          }
          onClick={() => handleCourseClick(item.type)}
        >
          {item.title}
        </button>
      ))}
    </div>
  );
};
