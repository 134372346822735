import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type Shtick = {
  img: string;
  text: string;
  url: string;
};

export type ShtickState = {
  [x: string]: Record<string, Shtick>;
};

const initialState: ShtickState = {
  baby: {},
  pregnancy: {},
};

export const shticksSlice = createSlice({
  name: 'shticks',
  initialState,
  reducers: {
    updateShticks: (state, action: PayloadAction<ShtickState>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

const { reducer, actions } = shticksSlice;

export const { updateShticks } = actions;

export default reducer;
