export const COUNTRIES = [
  {
    code: 'BD',
    label: 'Bangladesh',
    localLabel: 'Bangladesh',
  },
  {
    code: 'BE',
    label: 'Belgium',
    localLabel: 'Belgium',
  },
  {
    code: 'BF',
    label: 'Burkina Faso',
    localLabel: 'Burkina Faso',
  },
  {
    code: 'BG',
    label: 'Bulgaria',
    localLabel: 'Bulgaria',
  },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    localLabel: 'Bosnia and Herzegovina',
  },
  {
    code: 'BB',
    label: 'Barbados',
    localLabel: 'Barbados',
  },
  {
    code: 'WF',
    label: 'Wallis and Futuna',
    localLabel: 'Wallis and Futuna',
  },
  {
    code: 'BL',
    label: 'Saint Barthelemy',
    localLabel: 'Saint Barthelemy',
  },
  {
    code: 'BM',
    label: 'Bermuda',
    localLabel: 'Bermuda',
  },
  {
    code: 'BN',
    label: 'Brunei',
    localLabel: 'Brunei',
  },
  {
    code: 'BO',
    label: 'Bolivia',
    localLabel: 'Bolivia',
  },
  {
    code: 'BH',
    label: 'Bahrain',
    localLabel: 'Bahrain',
  },
  {
    code: 'BI',
    label: 'Burundi',
    localLabel: 'Burundi',
  },
  {
    code: 'BJ',
    label: 'Benin',
    localLabel: 'Benin',
  },
  {
    code: 'BT',
    label: 'Bhutan',
    localLabel: 'Bhutan',
  },
  {
    code: 'JM',
    label: 'Jamaica',
    localLabel: 'Jamaica',
  },
  {
    code: 'BV',
    label: 'Bouvet Island',
    localLabel: 'Bouvet Island',
  },
  {
    code: 'BW',
    label: 'Botswana',
    localLabel: 'Botswana',
  },
  {
    code: 'WS',
    label: 'Samoa',
    localLabel: 'Samoa',
  },
  {
    code: 'BQ',
    label: 'Bonaire, Saint Eustatius and Saba ',
    localLabel: 'Bonaire, Saint Eustatius and Saba',
  },
  {
    code: 'BR',
    label: 'Brazil',
    localLabel: 'Brazil',
  },
  {
    code: 'BS',
    label: 'Bahamas',
    localLabel: 'Bahamas',
  },
  {
    code: 'JE',
    label: 'Jersey',
    localLabel: 'Jersey',
  },
  {
    code: 'BY',
    label: 'Belarus',
    localLabel: 'Беларусь',
  },
  {
    code: 'BZ',
    label: 'Belize',
    localLabel: 'Belize',
  },
  {
    code: 'RU',
    label: 'Russia',
    localLabel: 'Россия',
  },
  {
    code: 'RW',
    label: 'Rwanda',
    localLabel: 'Rwanda',
  },
  {
    code: 'RS',
    label: 'Serbia',
    localLabel: 'Serbia',
  },
  {
    code: 'TL',
    label: 'East Timor',
    localLabel: 'East Timor',
  },
  {
    code: 'RE',
    label: 'Reunion',
    localLabel: 'Reunion',
  },
  {
    code: 'TM',
    label: 'Turkmenistan',
    localLabel: 'Туркменистан',
  },
  {
    code: 'TJ',
    label: 'Tajikistan',
    localLabel: 'Таджикистан',
  },
  {
    code: 'RO',
    label: 'Romania',
    localLabel: 'Romania',
  },
  {
    code: 'TK',
    label: 'Tokelau',
    localLabel: 'Tokelau',
  },
  {
    code: 'GW',
    label: 'Guinea-Bissau',
    localLabel: 'Guinea-Bissau',
  },
  {
    code: 'GU',
    label: 'Guam',
    localLabel: 'Guam',
  },
  {
    code: 'GT',
    label: 'Guatemala',
    localLabel: 'Guatemala',
  },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    localLabel: 'South Georgia and the South Sandwich Islands',
  },
  {
    code: 'GR',
    label: 'Greece',
    localLabel: 'Greece',
  },
  {
    code: 'GQ',
    label: 'Equatorial Guinea',
    localLabel: 'Equatorial Guinea',
  },
  {
    code: 'GP',
    label: 'Guadeloupe',
    localLabel: 'Guadeloupe',
  },
  {
    code: 'JP',
    label: 'Japan',
    localLabel: 'Japan',
  },
  {
    code: 'GY',
    label: 'Guyana',
    localLabel: 'Guyana',
  },
  {
    code: 'GG',
    label: 'Guernsey',
    localLabel: 'Guernsey',
  },
  {
    code: 'GF',
    label: 'French Guiana',
    localLabel: 'French Guiana',
  },
  {
    code: 'GE',
    label: 'Georgia',
    localLabel: 'Грузия',
  },
  {
    code: 'GD',
    label: 'Grenada',
    localLabel: 'Grenada',
  },
  {
    code: 'GB',
    label: 'United Kingdom',
    localLabel: 'United Kingdom',
  },
  {
    code: 'GA',
    label: 'Gabon',
    localLabel: 'Gabon',
  },
  {
    code: 'SV',
    label: 'El Salvador',
    localLabel: 'El Salvador',
  },
  {
    code: 'GN',
    label: 'Guinea',
    localLabel: 'Guinea',
  },
  {
    code: 'GM',
    label: 'Gambia',
    localLabel: 'Gambia',
  },
  {
    code: 'GL',
    label: 'Greenland',
    localLabel: 'Greenland',
  },
  {
    code: 'GI',
    label: 'Gibraltar',
    localLabel: 'Gibraltar',
  },
  {
    code: 'GH',
    label: 'Ghana',
    localLabel: 'Ghana',
  },
  {
    code: 'OM',
    label: 'Oman',
    localLabel: 'Oman',
  },
  {
    code: 'TN',
    label: 'Tunisia',
    localLabel: 'Tunisia',
  },
  {
    code: 'JO',
    label: 'Jordan',
    localLabel: 'Jordan',
  },
  {
    code: 'HR',
    label: 'Croatia',
    localLabel: 'Croatia',
  },
  {
    code: 'HT',
    label: 'Haiti',
    localLabel: 'Haiti',
  },
  {
    code: 'HU',
    label: 'Hungary',
    localLabel: 'Hungary',
  },
  {
    code: 'HK',
    label: 'Hong Kong',
    localLabel: 'Hong Kong',
  },
  {
    code: 'HN',
    label: 'Honduras',
    localLabel: 'Honduras',
  },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
    localLabel: 'Heard Island and McDonald Islands',
  },
  {
    code: 'VE',
    label: 'Venezuela',
    localLabel: 'Venezuela',
  },
  {
    code: 'PR',
    label: 'Puerto Rico',
    localLabel: 'Puerto Rico',
  },
  {
    code: 'PS',
    label: 'Palestinian Territory',
    localLabel: 'Palestinian Territory',
  },
  {
    code: 'PW',
    label: 'Palau',
    localLabel: 'Palau',
  },
  {
    code: 'PT',
    label: 'Portugal',
    localLabel: 'Portugal',
  },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    localLabel: 'Svalbard and Jan Mayen',
  },
  {
    code: 'PY',
    label: 'Paraguay',
    localLabel: 'Paraguay',
  },
  {
    code: 'IQ',
    label: 'Iraq',
    localLabel: 'Iraq',
  },
  {
    code: 'PA',
    label: 'Panama',
    localLabel: 'Panama',
  },
  {
    code: 'PF',
    label: 'French Polynesia',
    localLabel: 'French Polynesia',
  },
  {
    code: 'PG',
    label: 'Papua New Guinea',
    localLabel: 'Papua New Guinea',
  },
  {
    code: 'PE',
    label: 'Peru',
    localLabel: 'Peru',
  },
  {
    code: 'PK',
    label: 'Pakistan',
    localLabel: 'Pakistan',
  },
  {
    code: 'PH',
    label: 'Philippines',
    localLabel: 'Philippines',
  },
  {
    code: 'PN',
    label: 'Pitcairn',
    localLabel: 'Pitcairn',
  },
  {
    code: 'PL',
    label: 'Poland',
    localLabel: 'Poland',
  },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    localLabel: 'Saint Pierre and Miquelon',
  },
  {
    code: 'ZM',
    label: 'Zambia',
    localLabel: 'Zambia',
  },
  {
    code: 'EH',
    label: 'Western Sahara',
    localLabel: 'Western Sahara',
  },
  {
    code: 'EE',
    label: 'Estonia',
    localLabel: 'Estonia',
  },
  {
    code: 'EG',
    label: 'Egypt',
    localLabel: 'Egypt',
  },
  {
    code: 'ZA',
    label: 'South Africa',
    localLabel: 'South Africa',
  },
  {
    code: 'EC',
    label: 'Ecuador',
    localLabel: 'Ecuador',
  },
  {
    code: 'IT',
    label: 'Italy',
    localLabel: 'Italy',
  },
  {
    code: 'VN',
    label: 'Vietnam',
    localLabel: 'Vietnam',
  },
  {
    code: 'SB',
    label: 'Solomon Islands',
    localLabel: 'Solomon Islands',
  },
  {
    code: 'ET',
    label: 'Ethiopia',
    localLabel: 'Ethiopia',
  },
  {
    code: 'SO',
    label: 'Somalia',
    localLabel: 'Somalia',
  },
  {
    code: 'ZW',
    label: 'Zimbabwe',
    localLabel: 'Zimbabwe',
  },
  {
    code: 'SA',
    label: 'Saudi Arabia',
    localLabel: 'Saudi Arabia',
  },
  {
    code: 'ES',
    label: 'Spain',
    localLabel: 'Spain',
  },
  {
    code: 'ER',
    label: 'Eritrea',
    localLabel: 'Eritrea',
  },
  {
    code: 'ME',
    label: 'Montenegro',
    localLabel: 'Montenegro',
  },
  {
    code: 'MD',
    label: 'Moldova',
    localLabel: 'Молдавия',
  },
  {
    code: 'MG',
    label: 'Madagascar',
    localLabel: 'Madagascar',
  },
  {
    code: 'MF',
    label: 'Saint Martin',
    localLabel: 'Saint Martin',
  },
  {
    code: 'MA',
    label: 'Morocco',
    localLabel: 'Morocco',
  },
  {
    code: 'MC',
    label: 'Monaco',
    localLabel: 'Monaco',
  },
  {
    code: 'UZ',
    label: 'Uzbekistan',
    localLabel: 'Узбекистан',
  },
  {
    code: 'MM',
    label: 'Myanmar',
    localLabel: 'Myanmar',
  },
  {
    code: 'ML',
    label: 'Mali',
    localLabel: 'Mali',
  },
  {
    code: 'MO',
    label: 'Macao',
    localLabel: 'Macao',
  },
  {
    code: 'MN',
    label: 'Mongolia',
    localLabel: 'Монголия',
  },
  {
    code: 'MH',
    label: 'Marshall Islands',
    localLabel: 'Marshall Islands',
  },
  {
    code: 'MK',
    label: 'Macedonia',
    localLabel: 'Macedonia',
  },
  {
    code: 'MU',
    label: 'Mauritius',
    localLabel: 'Mauritius',
  },
  {
    code: 'MT',
    label: 'Malta',
    localLabel: 'Malta',
  },
  {
    code: 'MW',
    label: 'Malawi',
    localLabel: 'Malawi',
  },
  {
    code: 'MV',
    label: 'Maldives',
    localLabel: 'Maldives',
  },
  {
    code: 'MQ',
    label: 'Martinique',
    localLabel: 'Martinique',
  },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
    localLabel: 'Northern Mariana Islands',
  },
  {
    code: 'MS',
    label: 'Montserrat',
    localLabel: 'Montserrat',
  },
  {
    code: 'MR',
    label: 'Mauritania',
    localLabel: 'Mauritania',
  },
  {
    code: 'IM',
    label: 'Isle of Man',
    localLabel: 'Isle of Man',
  },
  {
    code: 'UG',
    label: 'Uganda',
    localLabel: 'Uganda',
  },
  {
    code: 'TZ',
    label: 'Tanzania',
    localLabel: 'Tanzania',
  },
  {
    code: 'MY',
    label: 'Malaysia',
    localLabel: 'Malaysia',
  },
  {
    code: 'MX',
    label: 'Mexico',
    localLabel: 'Mexico',
  },
  {
    code: 'IL',
    label: 'Israel',
    localLabel: 'Israel',
  },
  {
    code: 'FR',
    label: 'France',
    localLabel: 'France',
  },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
    localLabel: 'British Indian Ocean Territory',
  },
  {
    code: 'SH',
    label: 'Saint Helena',
    localLabel: 'Saint Helena',
  },
  {
    code: 'FI',
    label: 'Finland',
    localLabel: 'Finland',
  },
  {
    code: 'FJ',
    label: 'Fiji',
    localLabel: 'Fiji',
  },
  {
    code: 'FK',
    label: 'Falkland Islands',
    localLabel: 'Falkland Islands',
  },
  {
    code: 'FM',
    label: 'Micronesia',
    localLabel: 'Micronesia',
  },
  {
    code: 'FO',
    label: 'Faroe Islands',
    localLabel: 'Faroe Islands',
  },
  {
    code: 'NI',
    label: 'Nicaragua',
    localLabel: 'Nicaragua',
  },
  {
    code: 'NL',
    label: 'Netherlands',
    localLabel: 'Netherlands',
  },
  {
    code: 'NO',
    label: 'Norway',
    localLabel: 'Norway',
  },
  {
    code: 'NA',
    label: 'Namibia',
    localLabel: 'Namibia',
  },
  {
    code: 'VU',
    label: 'Vanuatu',
    localLabel: 'Vanuatu',
  },
  {
    code: 'NC',
    label: 'New Caledonia',
    localLabel: 'New Caledonia',
  },
  {
    code: 'NE',
    label: 'Niger',
    localLabel: 'Niger',
  },
  {
    code: 'NF',
    label: 'Norfolk Island',
    localLabel: 'Norfolk Island',
  },
  {
    code: 'NG',
    label: 'Nigeria',
    localLabel: 'Nigeria',
  },
  {
    code: 'NZ',
    label: 'New Zealand',
    localLabel: 'New Zealand',
  },
  {
    code: 'NP',
    label: 'Nepal',
    localLabel: 'Nepal',
  },
  {
    code: 'NR',
    label: 'Nauru',
    localLabel: 'Nauru',
  },
  {
    code: 'NU',
    label: 'Niue',
    localLabel: 'Niue',
  },
  {
    code: 'CK',
    label: 'Cook Islands',
    localLabel: 'Cook Islands',
  },
  {
    code: 'XK',
    label: 'Kosovo',
    localLabel: 'Kosovo',
  },
  {
    code: 'CI',
    label: 'Ivory Coast',
    localLabel: 'Ivory Coast',
  },
  {
    code: 'CH',
    label: 'Switzerland',
    localLabel: 'Switzerland',
  },
  {
    code: 'CO',
    label: 'Colombia',
    localLabel: 'Colombia',
  },
  {
    code: 'CN',
    label: 'China',
    localLabel: 'China',
  },
  {
    code: 'CM',
    label: 'Cameroon',
    localLabel: 'Cameroon',
  },
  {
    code: 'CL',
    label: 'Chile',
    localLabel: 'Chile',
  },
  {
    code: 'CC',
    label: 'Cocos Islands',
    localLabel: 'Cocos Islands',
  },
  {
    code: 'CA',
    label: 'Canada',
    localLabel: 'Canada',
  },
  {
    code: 'CG',
    label: 'Republic of the Congo',
    localLabel: 'Republic of the Congo',
  },
  {
    code: 'CF',
    label: 'Central African Republic',
    localLabel: 'Central African Republic',
  },
  {
    code: 'CD',
    label: 'Democratic Republic of the Congo',
    localLabel: 'Democratic Republic of the Congo',
  },
  {
    code: 'CZ',
    label: 'Czech Republic',
    localLabel: 'Czech Republic',
  },
  {
    code: 'CY',
    label: 'Cyprus',
    localLabel: 'Cyprus',
  },
  {
    code: 'CX',
    label: 'Christmas Island',
    localLabel: 'Christmas Island',
  },
  {
    code: 'CR',
    label: 'Costa Rica',
    localLabel: 'Costa Rica',
  },
  {
    code: 'CW',
    label: 'Curacao',
    localLabel: 'Curacao',
  },
  {
    code: 'CV',
    label: 'Cape Verde',
    localLabel: 'Cape Verde',
  },
  {
    code: 'CU',
    label: 'Cuba',
    localLabel: 'Cuba',
  },
  {
    code: 'SZ',
    label: 'Swaziland',
    localLabel: 'Swaziland',
  },
  {
    code: 'SY',
    label: 'Syria',
    localLabel: 'Syria',
  },
  {
    code: 'SX',
    label: 'Sint Maarten',
    localLabel: 'Sint Maarten',
  },
  {
    code: 'KG',
    label: 'Kyrgyzstan',
    localLabel: 'Киргизия',
  },
  {
    code: 'KE',
    label: 'Kenya',
    localLabel: 'Kenya',
  },
  {
    code: 'SS',
    label: 'South Sudan',
    localLabel: 'South Sudan',
  },
  {
    code: 'SR',
    label: 'Suriname',
    localLabel: 'Suriname',
  },
  {
    code: 'KI',
    label: 'Kiribati',
    localLabel: 'Kiribati',
  },
  {
    code: 'KH',
    label: 'Cambodia',
    localLabel: 'Cambodia',
  },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
    localLabel: 'Saint Kitts and Nevis',
  },
  {
    code: 'KM',
    label: 'Comoros',
    localLabel: 'Comoros',
  },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
    localLabel: 'Sao Tome and Principe',
  },
  {
    code: 'SK',
    label: 'Slovakia',
    localLabel: 'Slovakia',
  },
  {
    code: 'KR',
    label: 'South Korea',
    localLabel: 'South Korea',
  },
  {
    code: 'SI',
    label: 'Slovenia',
    localLabel: 'Slovenia',
  },
  {
    code: 'KP',
    label: 'North Korea',
    localLabel: 'North Korea',
  },
  {
    code: 'KW',
    label: 'Kuwait',
    localLabel: 'Kuwait',
  },
  {
    code: 'SN',
    label: 'Senegal',
    localLabel: 'Senegal',
  },
  {
    code: 'SM',
    label: 'San Marino',
    localLabel: 'San Marino',
  },
  {
    code: 'SL',
    label: 'Sierra Leone',
    localLabel: 'Sierra Leone',
  },
  {
    code: 'SC',
    label: 'Seychelles',
    localLabel: 'Seychelles',
  },
  {
    code: 'KZ',
    label: 'Kazakhstan',
    localLabel: 'Казахстан',
  },
  {
    code: 'KY',
    label: 'Cayman Islands',
    localLabel: 'Cayman Islands',
  },
  {
    code: 'SG',
    label: 'Singapore',
    localLabel: 'Singapore',
  },
  {
    code: 'SE',
    label: 'Sweden',
    localLabel: 'Sweden',
  },
  {
    code: 'SD',
    label: 'Sudan',
    localLabel: 'Sudan',
  },
  {
    code: 'DO',
    label: 'Dominican Republic',
    localLabel: 'Dominican Republic',
  },
  {
    code: 'DM',
    label: 'Dominica',
    localLabel: 'Dominica',
  },
  {
    code: 'DJ',
    label: 'Djibouti',
    localLabel: 'Djibouti',
  },
  {
    code: 'DK',
    label: 'Denmark',
    localLabel: 'Denmark',
  },
  {
    code: 'VG',
    label: 'British Virgin Islands',
    localLabel: 'British Virgin Islands',
  },
  {
    code: 'DE',
    label: 'Germany',
    localLabel: 'Germany',
  },
  {
    code: 'YE',
    label: 'Yemen',
    localLabel: 'Yemen',
  },
  {
    code: 'DZ',
    label: 'Algeria',
    localLabel: 'Algeria',
  },
  {
    code: 'US',
    label: 'United States',
    localLabel: 'United States',
  },
  {
    code: 'UY',
    label: 'Uruguay',
    localLabel: 'Uruguay',
  },
  {
    code: 'YT',
    label: 'Mayotte',
    localLabel: 'Mayotte',
  },
  {
    code: 'UM',
    label: 'United States Minor Outlying Islands',
    localLabel: 'United States Minor Outlying Islands',
  },
  {
    code: 'LB',
    label: 'Lebanon',
    localLabel: 'Lebanon',
  },
  {
    code: 'LC',
    label: 'Saint Lucia',
    localLabel: 'Saint Lucia',
  },
  {
    code: 'LA',
    label: 'Laos',
    localLabel: 'Laos',
  },
  {
    code: 'TV',
    label: 'Tuvalu',
    localLabel: 'Tuvalu',
  },
  {
    code: 'TW',
    label: 'Taiwan',
    localLabel: 'Taiwan',
  },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    localLabel: 'Trinidad and Tobago',
  },
  {
    code: 'TR',
    label: 'Turkey',
    localLabel: 'Turkey',
  },
  {
    code: 'LK',
    label: 'Sri Lanka',
    localLabel: 'Sri Lanka',
  },
  {
    code: 'LI',
    label: 'Liechtenstein',
    localLabel: 'Liechtenstein',
  },
  {
    code: 'LV',
    label: 'Latvia',
    localLabel: 'Latvia',
  },
  {
    code: 'TO',
    label: 'Tonga',
    localLabel: 'Tonga',
  },
  {
    code: 'LT',
    label: 'Lithuania',
    localLabel: 'Lithuania',
  },
  {
    code: 'LU',
    label: 'Luxembourg',
    localLabel: 'Luxembourg',
  },
  {
    code: 'LR',
    label: 'Liberia',
    localLabel: 'Liberia',
  },
  {
    code: 'LS',
    label: 'Lesotho',
    localLabel: 'Lesotho',
  },
  {
    code: 'TH',
    label: 'Thailand',
    localLabel: 'Thailand',
  },
  {
    code: 'TF',
    label: 'French Southern Territories',
    localLabel: 'French Southern Territories',
  },
  {
    code: 'TG',
    label: 'Togo',
    localLabel: 'Togo',
  },
  {
    code: 'TD',
    label: 'Chad',
    localLabel: 'Chad',
  },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
    localLabel: 'Turks and Caicos Islands',
  },
  {
    code: 'LY',
    label: 'Libya',
    localLabel: 'Libya',
  },
  {
    code: 'VA',
    label: 'Vatican',
    localLabel: 'Vatican',
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    localLabel: 'Saint Vincent and the Grenadines',
  },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    localLabel: 'United Arab Emirates',
  },
  {
    code: 'AD',
    label: 'Andorra',
    localLabel: 'Andorra',
  },
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
    localLabel: 'Antigua and Barbuda',
  },
  {
    code: 'AF',
    label: 'Afghanistan',
    localLabel: 'Afghanistan',
  },
  {
    code: 'AI',
    label: 'Anguilla',
    localLabel: 'Anguilla',
  },
  {
    code: 'VI',
    label: 'U.S. Virgin Islands',
    localLabel: 'U.S. Virgin Islands',
  },
  {
    code: 'IS',
    label: 'Iceland',
    localLabel: 'Iceland',
  },
  {
    code: 'IR',
    label: 'Iran',
    localLabel: 'Iran',
  },
  {
    code: 'AM',
    label: 'Armenia',
    localLabel: 'Армения',
  },
  {
    code: 'AL',
    label: 'Albania',
    localLabel: 'Albania',
  },
  {
    code: 'AO',
    label: 'Angola',
    localLabel: 'Angola',
  },
  {
    code: 'AQ',
    label: 'Antarctica',
    localLabel: 'Antarctica',
  },
  {
    code: 'AS',
    label: 'American Samoa',
    localLabel: 'American Samoa',
  },
  {
    code: 'AR',
    label: 'Argentina',
    localLabel: 'Argentina',
  },
  {
    code: 'AU',
    label: 'Australia',
    localLabel: 'Australia',
  },
  {
    code: 'AT',
    label: 'Austria',
    localLabel: 'Austria',
  },
  {
    code: 'AW',
    label: 'Aruba',
    localLabel: 'Aruba',
  },
  {
    code: 'IN',
    label: 'India',
    localLabel: 'India',
  },
  {
    code: 'AX',
    label: 'Aland Islands',
    localLabel: 'Aland Islands',
  },
  {
    code: 'AZ',
    label: 'Azerbaijan',
    localLabel: 'Азербайджан',
  },
  {
    code: 'IE',
    label: 'Ireland',
    localLabel: 'Ireland',
  },
  {
    code: 'ID',
    label: 'Indonesia',
    localLabel: 'Indonesia',
  },
  {
    code: 'UA',
    label: 'Ukraine',
    localLabel: 'Украина',
  },
  {
    code: 'QA',
    label: 'Qatar',
    localLabel: 'Qatar',
  },
  {
    code: 'MZ',
    label: 'Mozambiqu',
    localLabel: 'Mozambiqu',
  }
].sort((a, b) => a.localLabel.localeCompare(b.localLabel));
