import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import {
  LOCALE_ID,
  getCourses,
  getLullabies,
  getMeditation,
  getPosts,
  getTips,
  updateAdviceBooked,
  updateCourseBooked,
  updateCourseLikes,
  updateLullabiesBooked,
  updateLullabiesLikes,
  updateMeditationBooked,
  updateMeditationLikes,
  updatePostBooked,
  updatePostLikes,
} from 'api/api';

import { RecommendationType, CourseData } from 'types';
import { optional } from './other';

import { JSXElementConstructor } from 'react';
import { Course } from 'types/items';
import { byOrder } from './sorting';
import { updateAdviceLikes } from '../api/api';
import {
  ContentKey,
  saveTips,
  saveCourses,
  savePosts,
  saveLullabies,
  saveMeditation,
} from 'pages/CoursesCategory/store/CourseSlice';
import { AdviceIcon, CourseIcon, PostIcon } from 'assets/icons';

export const coursesConfig: Record<
  string,
  {
    key: ContentKey;
    api: () => Promise<any>;
    updateLikes: (id: string, like: Record<string, boolean>) => Promise<any>;
    updateBooked: (id: string, booked: Record<string, boolean>) => Promise<any>;
    action: ActionCreatorWithPayload<CourseData[], string>;
    tKey: string;
    icon: JSXElementConstructor<any>;
  }
> = {
  [RecommendationType.ADVICE]: {
    key: 'tips',
    api: getTips,
    updateLikes: updateAdviceLikes,
    updateBooked: updateAdviceBooked,
    action: saveTips,
    tKey: 'advice',
    icon: AdviceIcon,
  },
  [RecommendationType.MEDITATION]: {
    key: 'meditation',
    api: getMeditation,
    updateLikes: updateMeditationLikes,
    updateBooked: updateMeditationBooked,
    action: saveMeditation,
    tKey: 'meditation',
    icon: AdviceIcon,
  },
  [RecommendationType.LULLABIES]: {
    key: 'lullabies',
    api: getLullabies,
    updateLikes: updateLullabiesLikes,
    updateBooked: updateLullabiesBooked,
    action: saveLullabies,
    tKey: 'lullabies',
    icon: AdviceIcon,
  },
  [RecommendationType.COURSE]: {
    key: 'courses',
    api: getCourses,
    updateLikes: updateCourseLikes,
    updateBooked: updateCourseBooked,
    action: saveCourses,
    tKey: 'course',
    icon: CourseIcon,
  },
  [RecommendationType.POST]: {
    key: 'posts',
    api: getPosts,
    updateLikes: updatePostLikes,
    updateBooked: updatePostBooked,
    action: savePosts,
    tKey: 'post_post',
    icon: PostIcon,
  },
};

export const defineLessons = (courses: Course[]) => {
  //@ts-ignore
  return courses.reduce((acc, currentItem) => {
    if (currentItem?.lessons?.video) {
      const lessonsList = Object.values(currentItem?.lessons?.video).map(
        (el) => {
          return {
            ...el,
            type: RecommendationType.LESSON,
            contentId: currentItem.id,
            subtitle: currentItem.title,
            enable: currentItem.enable,
            name: `Урок ${Number(el.order) + 1}`,
          };
        }
      );
      return [...acc, ...lessonsList];
    }
    return currentItem.promo
      ? [
          {
            ...currentItem.promo,
            type: RecommendationType.LESSON,
            contentId: currentItem.id,
            //@ts-ignore
            enable: currentItem.enable,
            name: 'Введение',
          },
          ...acc,
        ]
      : acc;
  }, []);
};

export const adaptCourses = (
  type: RecommendationType,
  courses: Course[],
  t: (tKey: string) => string
) => {
  const data: CourseData[] = [];
  Object.keys(courses).forEach((id) => {
    //@ts-ignore
    const course = optional<Course>(courses[id][LOCALE_ID]);
    if (type === RecommendationType.COURSE && course?.lessons?.video) {
      Object.keys(course.lessons.video).forEach((key) => {
        course.lessons.video[key].id = key;
      });
    }

    const content = {
      ...course,
      id,
      name: t(coursesConfig[type].tKey),
      type,
    };
    if (type === RecommendationType.POST) {
      const lengthOfWords = JSON.stringify(
        //@ts-ignore
        course?.data || course?.content || {}
      )
        .replace(/[^А-яЁё ]/g, '')
        .split(' ').length;
      //@ts-ignore
      content.approximateTime = lengthOfWords / 180;
    }
    //@ts-ignore
    data.push(content);
  });
  return data;
};

export const getCourseVideoLessons = ({ lessons }: Course) => {
  //@ts-ignore
  return Object.values(lessons?.video || {})?.sort?.(byOrder);
};

export const checkLikes = (userId: string, content: CourseData) =>
  content?.analytics?.likes?.[userId];

export const checkBooked = (userId: string, content: CourseData) =>
  content?.booked?.[userId];

export function getLikesCount(content: CourseData) {
  return Object.keys(content?.analytics?.likes || { '0': false }).filter(
    (a) => a
  ).length;
}
