import { DeleteButton } from '../Buttons/DeleteButton/DeleteButton';
import { useTranslate } from 'hooks/useTranslate';

type DeleteAccountProps = {
  onClick: () => void;
};
export const DeleteAccount = ({ onClick }: DeleteAccountProps) => {
  const t = useTranslate();

  return (
    <DeleteButton title={t('settings_delete_account')} onPress={onClick} big />
  );
};
