import VideoComponent from 'components/VideoComponent/VideoComponent';
import styles from './CategoryVideo.module.css';
import { useEffect, useState } from 'react';
import { PlayIconVideo } from 'assets/icons';
import { LockedContent } from 'components/LockedContent/LockedContent';
import { useUser } from 'hooks/useUser';
import { useParams } from 'react-router-dom';

type CategoryVideoProps = {
  link: string;
  isMobile: boolean;
  isTablet: boolean;
  id: string;
  linkImage: string | undefined;
};
function CategoryVideo({
  link,
  isMobile,
  isTablet,
  id,
  linkImage,
}: CategoryVideoProps) {
  const [started, setStarted] = useState<boolean>(false);
  const [pressed, setPressed] = useState<boolean>(false);
  const { type } = useParams();
  const handleStart = () => {
    setStarted(!!user?.id);
    setPressed(true);
  };
  const locked = pressed && !started;
  const { user } = useUser();
  const size = isMobile ? 56 : 80;

  const height = `50vw`;
  const maxHeight = 435;

  useEffect(() => {
    setPressed(false);
    setStarted(false);
  }, [id]);
  return (
    <div className={styles.videoWrapper}>
      {locked && (
        <LockedContent open={locked} onClose={() => setPressed(false)} />
      )}
      <VideoComponent
        link={link}
        height={height}
        width={`87.2vw`}
        maxHeight={maxHeight}
        maxWidth={760}
      />
      {!started && !user.id && (
        <div style={{ maxHeight }}>
          <img className={styles.videoImage} src={linkImage} />
          <button className={styles.startButton} onClick={handleStart}>
            <PlayIconVideo width={size} height={size} />
          </button>
        </div>
      )}
    </div>
  );
}

export default CategoryVideo;
