import { useTranslate } from 'hooks/useTranslate';
import styles from './WeeklyRecommendations.module.css';
import { WEEK_RECOMMENDATIONS } from '../MainPage.utils';
import RecommendationButton from './RecommendationButton/RecommendationButton';

function WeeklyRecommendations() {
  const t = useTranslate();

  return (
    <div className={styles.container}>
      <p className={styles.title}>{t('weekly_recommendations')}</p>
      <div className={styles.weekContainer}>
        {WEEK_RECOMMENDATIONS.map(({ icon, type, id }) => (
          <RecommendationButton key={id} icon={icon} type={type} />
        ))}
      </div>
    </div>
  );
}

export default WeeklyRecommendations;
