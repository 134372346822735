import { FC } from 'react';
import { Helmet } from 'react-helmet';

type HelmetComponentType = {
  title: string;
  description: string;
};

export const HelmetComponent: FC<HelmetComponentType> = ({
  title,
  description,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};
