import { FC, memo } from 'react';
import styles from './SubmitButton.module.css';

const PrimaryButton: FC<{
  title: string;
  onClick: () => void;
  disabled?: boolean;
}> = memo(({ title, onClick, disabled }) => {
  return (
    <button
      type="button"
      className={!disabled ? styles.container : styles.containerDisabled}
      onClick={onClick}
      disabled={disabled}>
      {title}
    </button>
  );
});

export default PrimaryButton;
