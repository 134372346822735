import { useTranslate } from 'hooks/useTranslate';
import styles from './ContentModalNotice.module.css';
import { ModalButton } from '../../Buttons/ModalButton/ModalButton';

type ContentModalNoticeProps = {
  handleModal: () => void;
  handleChangeAction: () => void;
};

export const ContentModalNotice = ({
  handleModal,
  handleChangeAction,
}: ContentModalNoticeProps) => {
  const t = useTranslate();
  return (
    <div className={styles.buttonsContainer}>
      <ModalButton
        title={t('settings_delete_account_module_cancel_notice')}
        onPress={handleModal}
      />
      <ModalButton
        title={t('settings_delete_account_module_confirm_notice')}
        flat
        onPress={handleChangeAction}
      />
    </div>
  );
};
