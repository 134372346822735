import {
  b2c3q2,
  b2c4q2,
  b2c4q3,
  b2c5q1,
  b2c5q3,
  b2c6q2,
  b2c7q1,
  b3c10q1,
  b3c11q1,
  b3c12q1,
  b3c13q2,
  b3c13q3,
  b3c8q1,
  b3c8q2,
  b3c9q1,
  b3c9q2,
  b4c14q2,
  b4c15q1,
  b5c16q3,
  b5c17q1,
  b5c17q2,
  b5c18q1,
  b5c18q2,
  b5c19q1,
  b5c19q2,
} from 'assets/images';

export const b8 = {
  c26: {
    title: 'b8_c1_title',
    author: 'b8_c1_tests_title',
    description: 'final_test_description',
    tests: [
      {
        question: 'b8_c1_test1_question',
        answers: {
          a1: 'b8_c1_test1_answer_a1',
          a2: 'b8_c1_test1_answer_a2',
          a3: 'b8_c1_test1_answer_a3',
        },
        correctAnswerId: 3,
        wrongAnswer: 'b8_c1_test1_wrongAnswer',
        correctAnswer: 'b8_c1_test1_correctAnswer',
        image: b2c5q1,
      },
      {
        question: 'b8_c2_test2_question',
        answers: {
          a1: 'b8_c2_test2_answer_a1',
          a2: 'b8_c2_test2_answer_a2',
          a3: 'b8_c2_test2_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b8_c2_test2_wrongAnswer',
        correctAnswer: 'b8_c2_test2_correctAnswer',
        image: b2c3q2,
      },
      {
        question: 'b8_c3_test2_question',
        answers: {
          a1: 'b8_c3_test2_answer_a1',
          a2: 'b8_c3_test2_answer_a2',
          a3: 'b8_c3_test2_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b8_c3_test2_wrongAnswer',
        correctAnswer: 'b8_c3_test2_correctAnswer',
        image: b2c4q2,
      },
      {
        question: 'b8_c4_test3_question',
        answers: {
          a1: 'b8_c4_test3_answer_a1',
          a2: 'b8_c4_test3_answer_a2',
          a3: 'b8_c4_test3_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b8_c4_test3_wrongAnswer',
        correctAnswer: 'b8_c4_test3_correctAnswer',
        image: b2c4q3,
      },
      {
        question: 'b8_c5_test3_question',
        answers: {
          a1: 'b8_c5_test3_answer_a1',
          a2: 'b8_c5_test3_answer_a2',
          a3: 'b8_c5_test3_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b8_c5_test3_wrongAnswer',
        correctAnswer: 'b8_c5_test3_correctAnswer',
        image: b2c5q3,
      },
      {
        question: 'b8_c6_test2_question',
        answers: {
          a1: 'b8_c6_test2_answer_a1',
          a2: 'b8_c6_test2_answer_a2',
          a3: 'b8_c6_test2_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b8_c6_test2_wrongAnswer',
        correctAnswer: 'b8_c6_test2_correctAnswer',
        image: b2c6q2,
      },
      {
        question: 'b8_c7_test1_question',
        answers: {
          a1: 'b8_c7_test1_answer_a1',
          a2: 'b8_c7_test1_answer_a2',
          a3: 'b8_c7_test1_answer_a3',
        },
        correctAnswerId: 3,
        wrongAnswer: 'b8_c7_test1_wrongAnswer',
        correctAnswer: 'b8_c7_test1_correctAnswer',
        image: b2c7q1,
      },
      {
        question: 'b8_c8_test1_question',
        answers: {
          a1: 'b8_c8_test1_answer_a1',
          a2: 'b8_c8_test1_answer_a2',
          a3: 'b8_c8_test1_answer_a3',
        },
        correctAnswerId: 3,
        wrongAnswer: 'b8_c8_test1_wrongAnswer',
        correctAnswer: 'b8_c8_test1_correctAnswer',
        image: b3c8q1,
      },
      {
        question: 'b8_c9_test2_question',
        answers: {
          a1: 'b8_c9_test2_answer_a1',
          a2: 'b8_c9_test2_answer_a2',
          a3: 'b8_c9_test2_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b8_c9_test2_wrongAnswer',
        correctAnswer: 'b8_c9_test2_correctAnswer',
        image: b3c8q2,
      },
      {
        question: 'b8_c10_test1_question',
        answers: {
          a1: 'b8_c10_test1_answer_a1',
          a2: 'b8_c10_test1_answer_a2',
          a3: 'b8_c10_test1_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b8_c10_test1_wrongAnswer',
        correctAnswer: 'b8_c10_test1_correctAnswer',
        image: b3c9q1,
      },
      {
        question: 'b8_c11_test2_question',
        answers: {
          a1: 'b8_c11_test2_answer_a1',
          a2: 'b8_c11_test2_answer_a2',
          a3: 'b8_c11_test2_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b8_c11_test2_wrongAnswer',
        correctAnswer: 'b8_c11_test2_correctAnswer',
        image: b3c9q2,
      },
      {
        question: 'b8_c12_test1_question',
        answers: {
          a1: 'b8_c12_test1_answer_a1',
          a2: 'b8_c12_test1_answer_a2',
          a3: 'b8_c12_test1_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b8_c12_test1_wrongAnswer',
        correctAnswer: 'b8_c12_test1_correctAnswer',
        image: b3c10q1,
      },
      {
        question: 'b8_c13_test1_question',
        answers: {
          a1: 'b8_c13_test1_answer_a1',
          a2: 'b8_c13_test1_answer_a2',
          a3: 'b8_c13_test1_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b8_c13_test1_wrongAnswer',
        correctAnswer: 'b8_c13_test1_correctAnswer',
        image: b3c11q1,
      },
      {
        question: 'b8_c14_test1_question',
        answers: {
          a1: 'b8_c14_test1_answer_a1',
          a2: 'b8_c14_test1_answer_a2',
          a3: 'b8_c14_test1_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b8_c14_test1_wrongAnswer',
        correctAnswer: 'b8_c14_test1_correctAnswer',
        image: b3c12q1,
      },
      {
        question: 'b8_c15_test2_question',
        answers: {
          a1: 'b8_c15_test2_answer_a1',
          a2: 'b8_c15_test2_answer_a2',
          a3: 'b8_c15_test2_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b8_c15_test2_wrongAnswer',
        correctAnswer: 'b8_c15_test2_correctAnswer',
        image: b4c14q2,
      },
      {
        question: 'b8_c16_test3_question',
        answers: {
          a1: 'b8_c16_test3_answer_a1',
          a2: 'b8_c16_test3_answer_a2',
          a3: 'b8_c16_test3_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b8_c16_test3_wrongAnswer',
        correctAnswer: 'b8_c16_test3_correctAnswer',
        image: b5c16q3,
      },
      {
        question: 'b8_c17_test1_question',
        answers: {
          a1: 'b8_c17_test1_answer_a1',
          a2: 'b8_c17_test1_answer_a2',
          a3: 'b8_c17_test1_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b8_c17_test1_wrongAnswer',
        correctAnswer: 'b8_c17_test1_correctAnswer',
        image: b4c15q1,
      },
      {
        question: 'b8_c18_test2_question',
        answers: {
          a1: 'b8_c18_test2_answer_a1',
          a2: 'b8_c18_test2_answer_a2',
          a3: 'b8_c18_test2_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b8_c18_test2_wrongAnswer',
        correctAnswer: 'b8_c18_test2_correctAnswer',
        image: b3c13q2,
      },
      {
        question: 'b8_c19_test3_question',
        answers: {
          a1: 'b8_c19_test3_answer_a1',
          a2: 'b8_c19_test3_answer_a2',
          a3: 'b8_c19_test3_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b8_c19_test3_wrongAnswer',
        correctAnswer: 'b8_c19_test3_correctAnswer',
        image: b3c13q3,
      },
      {
        question: 'b8_c20_test1_question',
        answers: {
          a1: 'b8_c20_test1_answer_a1',
          a2: 'b8_c20_test1_answer_a2',
          a3: 'b8_c20_test1_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b8_c20_test1_wrongAnswer',
        correctAnswer: 'b8_c20_test1_correctAnswer',
        image: b5c17q1,
      },
      {
        question: 'b8_c21_test2_question',
        answers: {
          a1: 'b8_c21_test2_answer_a1',
          a2: 'b8_c21_test2_answer_a2',
          a3: 'b8_c21_test2_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b8_c21_test2_wrongAnswer',
        correctAnswer: 'b8_c21_test2_correctAnswer',
        image: b5c17q2,
      },
      {
        question: 'b8_c22_test1_question',
        answers: {
          a1: 'b8_c22_test1_answer_a1',
          a2: 'b8_c22_test1_answer_a2',
          a3: 'b8_c22_test1_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b8_c22_test1_wrongAnswer',
        correctAnswer: 'b8_c22_test1_correctAnswer',
        image: b5c18q1,
      },
      {
        question: 'b8_c23_test2_question',
        answers: {
          a1: 'b8_c23_test2_answer_a1',
          a2: 'b8_c23_test2_answer_a2',
          a3: 'b8_c23_test2_answer_a3',
        },
        correctAnswerId: 3,
        wrongAnswer: 'b8_c23_test2_wrongAnswer',
        correctAnswer: 'b8_c23_test2_correctAnswer',
        image: b5c18q2,
      },
      {
        question: 'b8_c24_test1_question',
        answers: {
          a1: 'b8_c24_test1_answer_a1',
          a2: 'b8_c24_test1_answer_a2',
          a3: 'b8_c24_test1_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b8_c24_test1_wrongAnswer',
        correctAnswer: 'b8_c24_test1_correctAnswer',
        image: b5c19q1,
      },
      {
        question: 'b8_c25_test2_question',
        answers: {
          a1: 'b8_c25_test2_answer_a1',
          a2: 'b8_c25_test2_answer_a2',
          a3: 'b8_c25_test2_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b8_c25_test2_wrongAnswer',
        correctAnswer: 'b8_c25_test2_correctAnswer',
        image: b5c19q2,
      },
    ],
  },

  // c2: {
  //   title: 'b1_c2_title',
  //   author: 'b1_c2_author',
  //   last: true,
  //   description: 'b1_c2_description',
  //   lessonsLength: 1,
  //   video: 'https://vimeo.com/showcase/9263641/embed',
  // },
};
