import { CSSProperties, FC } from 'react';
import styles from './ExpertSocialIcon.module.css';
import classNames from 'classnames';

export const ExpertSocial: FC<{
  icon: any;
  link?: string;
  big?: boolean;
  isScrolled: boolean;
  containerStyle?: CSSProperties;
}> = ({ icon: Icon, link = '#!', big, isScrolled, containerStyle }) => {
  return (
    <a
      href={link}
      className={styles.container}
      target="_blank"
      rel="noreferrer"
    >
      <Icon
        style={containerStyle}
        className={classNames(
          styles.icon,
          isScrolled && styles.scrolled,
          big && styles.big
        )}
      />
    </a>
  );
};
