import { ShareIcon } from 'assets/icons';
import ArrowNavButton from 'components/Buttons/ArrowNavButton/ArrowNavButton';
import ChipWithIcon from 'components/ChipWithIcon/ChipWithIcon';
import { useTranslate } from 'hooks/useTranslate';
import { useParams } from 'react-router-dom';
import styles from './CoursesHeader.module.css';
import { getCategoryId, getType, getUrlType } from 'utils/other';

const tabletButtonContainerStyle = {
  height: 40,
  padding: 10,
  width: 158,
  marginTop: 0,
  display: 'flex',
  justifyContent: 'center',
};

const mobileButtonContainerStyle = {
  height: 33,
  padding: 10,
  width: 158,
  marginTop: 0,
  display: 'flex',
  justifyContent: 'center',
};

type CoursesHeaderProps = {
  title: string;
  isTablet?: boolean;
  isMobile?: boolean;
  to?: string;
  icon?: any;
  cb?: () => void;
  iconTitle?: string;
  withoutButton?: boolean;
};
export const CoursesHeader = ({
  title,
  isTablet,
  isMobile,
  to,
  cb,
  icon: Icon,
  iconTitle,
  withoutButton,
}: CoursesHeaderProps) => {
  const t = useTranslate();
  const { categoryId } = useParams();
  const type = getType();
  return (
    <>
      <div className={styles.container}>
        <div
          className={
            isTablet || isMobile
              ? styles.arrowContainer
              : styles.arrowContainerWithoutButton
          }
        >
          {!withoutButton && (
            <ArrowNavButton
              title={`${t('back_button')}`}
              cb={cb}
              to={
                to
                  ? to
                  : `/edu${
                      categoryId
                        ? '/' + categoryId + '/' + getUrlType(type)
                        : ''
                    }`
              }
            />
          )}

          {isMobile && (
            <div className={styles.icon}>
              <ChipWithIcon
                styleContainer={mobileButtonContainerStyle}
                icon={ShareIcon}
                text={t('share_button')}
                share
              />
            </div>
          )}
          {Icon && (
            <>
              <Icon />
              <p>{iconTitle}</p>
            </>
          )}
        </div>
        <div className={styles.titleContainer}>
          <h1 className={withoutButton ? styles.withoutButton : styles.title}>
            {title}
          </h1>
          {isTablet && (
            <div className={styles.icon}>
              <ChipWithIcon
                textStyle={{ textAlign: 'center' }}
                styleContainer={tabletButtonContainerStyle}
                icon={ShareIcon}
                text={t('share_button')}
                share
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
