import React from 'react';
import styles from './DescriptionBlock.module.css';
import LinesEllipsis from 'react-lines-ellipsis';

interface DescriptionBlockProps {
  isMobile: boolean;
  showFullDescription: boolean;
  description: string;
  toggleDescription: () => void;
}

const DescriptionBlock: React.FC<DescriptionBlockProps> = ({
  isMobile,
  showFullDescription,
  description,
  toggleDescription,
}) => {
  return (
    <div className={styles.block}>
      <p className={styles.description}>
        {isMobile && !showFullDescription ? (
          <LinesEllipsis
            text={description}
            maxLine="1"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        ) : (
          description
        )}
      </p>
      {isMobile && !showFullDescription && (
        <p onClick={toggleDescription} className={styles.moreButton}>
          Ещё
        </p>
      )}
    </div>
  );
};

export default DescriptionBlock;
