import { FC } from "react";
import styles from './LogoList.module.css';
import { MamaProLogoIcon, SupportLogoIcon, UNFPALogoIcon } from "assets/icons";


export const LogoList: FC = () => {
  return (
    <div className={ styles.container }>
      <img src={ MamaProLogoIcon } alt={ 'MamaPro logo icon' } className={ styles.icon } />
      <img src={ UNFPALogoIcon } alt={ 'UNFPA logo icon' } className={ styles.icon } />
      <img src={ SupportLogoIcon } alt={ 'support logo icon' } className={ styles.icon } />
  </div>
  )
};
