import { separator, tb5c1_1, tb5c1_2, tb5c1_3 } from 'assets/images';

export const b5_c16_text = {
  b5_c1_title: `Курс «Особенности пребывания новорожденного в роддоме»`,
  b5_c1_description: `Спикер: Алеся Корево, врач-педиатр, консультант по грудному вскармливанию и питанию детей`,
  b5_c16_text: `
    ${tb5c1_1}

    Урок 1. Ребенок в родзале. Первые минуты жизни

Первые минуты жизни малыша

Если роды прошли благополучно, мама и малыш здоровы, то ребенка сразу выкладывают на живот и на грудь маме. Это очень важный момент как для мамы, так и для малыша.

В первую очередь это важно для дальнейшего налаживания грудного вскармливания. Если малыша оставить сразу после рождения на животе у мамы или рядом с мамой, то в течение нескольких минут малыш даже сам может доползти к маминой груди и пытаться покушать. 

Также такой контакт с мамой в первые минуты жизни: 

поддержит температуру тела у ребенка, согреет его
укрепит психологическую связь между мамой и малышом
успокоит ребенка
поможет заселить организм ребенка новыми материнскими микроорганизмами, которые будут защищать его от условно патогенных и болезнетворных микробов
Если у вас были партнерские роды, то ребенка также выкладывают на грудь маме. А уже чуть позже, когда ребенок и мама будут осмотрены, в палате совместного пребывания и папа сможет подержать малыша, полежать вместе с ним.

Если у женщины была операция кесарево сечение, но наркоз был не общий и мама себя хорошо чувствует, то ей также могут выложить ребенка на грудь. Малыша можно приложить к груди, пока врачи будут заканчивать операцию. Такое первое прикладывание позитивно влияет на сокращение матки, помогает маме быстрее восстановиться.

Если после кесарева сечения мама временно не может уделить малышу должного внимания, папа может приложить новорожденного ребеночка к своему телу. Можно закутать малыша в халат или сорочку, в которой была мама. Ребенок сможет ощутить тепло папы, запах мамы – это также будет способствовать заселению организма новорожденного малыша полезными микроорганизмами его  родителей.

Шкала Апгар

На первой минуте жизни ребенка оценивают по шкале Апгар. Состояние здоровья новорожденного оценивают по 5 показателям:

частота сердечных сокращений
дыхание
мышечный тонус
рефлексы
цвет кожи младенца
Во время осмотра за каждый признак врач дает 0, 1 или 2 балла – чем выше балл, тем лучше этот показатель. Также по шкале Апгар ребенка оценивают на 5-й минуте жизни. Это нужно для того, чтобы понять, как за эти минуты изменилось состояние ребенка. Врачу важно оценить динамику. Так как оценка новорожденного ребенка по шкале Апгар проводится дважды – цифры  всегда две, например 8/9 баллов.

Помните, что оценка по шкале Апгар, независимо от того, будет она низкой или высокой, – это не диагноз. Это только лишь сигнал для врача о том, какая помощь сейчас нужна малышу или, наоборот, не нужна.

Итак, малыша выложили на грудь маме. Что происходит дальше? После того как пуповина перестает пульсировать, на нее накладывают зажимы, а после – пересекают. И теперь малыш попадает в руки врачу-неонатологу и акушерке.

Осмотр в первые минуты жизни

Ребенка осматривают на пеленальном столике с подогревом. После рождения акушерка взвешивает малыша, измеряет длину его тела, окружность головы и грудной клетки. Акушерка называет вам те самые заветные цифры роста и веса вашего малыша.

Далее к осмотру новорожденного приступает врач-неонатолог. Его главная цель – исключить возможные видимые особенности и врожденные аномалии развития, оценить состояние здоровья ребенка. Еще врач оценивает размеры большого и малого родничков, прощупывает швы черепа и щупает ключицы малыша, чтобы убедиться, не было ли перелома их в родах. Кроме этого, врач проводит осмотр кожи, наружных половых органов, выслушивает сердце и легкие, пальпирует животик. 

После осмотра ребенку надевают на руки бирочки. Там указаны фамилия, имя, отчество мамы, номер истории родов, дата и время рождения малыша, его пол, вес и рост. Бирочки завязываются на обе ручки ребеночка сразу в родзале при маме. Снять их родители смогут уже после выписки дома. После этого новорожденного пеленают и в зависимости от самочувствия малыша и мамы либо оставляют для совместного пребывания и первого прикладывания к груди прямо в родильном зале, либо уносят в отделение новорожденных. 

Сегодня активно применяется практика, когда маму и новорожденного малыша не разделяют, а позволяют им находиться рядом друг с другом сразу с момента рождения.

Иногда случаются ситуации, когда новорожденный малыш может нуждаться в особых мероприятиях или продолжительном медицинском уходе и наблюдении со стороны акушерки или врача-неонатолога. В этом случае ребенка сразу положат на пеленальный столик с подогревом, чтобы выполнить все необходимые манипуляции, или в специальную кроватку с подогревом, которая называется кувез. Там созданы оптимальные условия для благоприятного нахождения ребенка.

Как вы уже изучали ранее в курсах, после рождения малыша мама находится 2 часа в родовом отделении. И находится там она чаще всего вместе с малышом. Затем маму и малыша, если они хорошо себя чувствуют, переводят в послеродовое отделение.

${separator}

Урок 2. Совместное пребывание мамы и ребенка

После родов еще как минимум 3 суток мама и малыш находятся в родильном доме. Это время необходимо для того, чтобы мама пришла в себя, а врачи понаблюдали за ребенком, как он адаптируется к новой жизни. За эти 3 дня и маму, и малыша ждут обследования и осмотры врачами. И, конечно, это время мама может посвятить самому главному – общению с ребенком.

После рождения малыша мама находится 2 часа в родовом отделении. Чаще всего вместе с малышом. И затем в зависимости от ситуации самочувствия мама и ребенок либо могут находиться вместе сразу в палате, либо мама находится в палате, а ребенок находится в отделении новорожденных. Если мама и малыш будут находиться вместе, то ее и ребенка привезут в палату отдельно, и уже в палате мама снова встретиться со своим малышом.

В палату совместного пребывания малыша принесут завернутого в пеленки и одеяло. После того, как вы совершили нужные для вас гигиенические процедуры, и уже уверенно передвигаетесь и хорошо себя чувствуете, вы можете переодеть малыша в ту одежду, которую привезли с собой.

Лучше малышу надевать слип, где закрыты и ручки, и ножки.

${tb5c1_2}

Для совсем маленьких деток делают слипы с рукавичками. Спереди у слипа будет застежка или кнопочки – лучше выбирайте кнопочки. Он удобен, потому что его не нужно надевать малышу через голову. Так маме будет легко переодеть ребенка и поменять ему подгузник. 

В палате у вашего малыша будет своя кроватка на  колесиках, чтобы мама при необходимости легко могла перемещать ее. Также обязательно в палате есть пеленальный столик. Когда будете менять малышу подгузник, не забудьте постелить пеленку. Об уходе за новорожденным в роддоме поговорим чуть позже.

Пока малыш находится в роддоме, основные его занятия – это сон и еда. В первые дни после рождения большую часть времени малыш спит. Он может проснуться и плакать, призывая маму покормить его или поменять подгузник. Новорожденный малыш получает грудь по требованию, а в промежутках между кормлением чаще всего спит или на руках у мамы, или в кроватке. 

Условия для совместного сна мамы и малыша в настоящее время есть не в каждом роддоме. Связано это с тем, что чаще кровать мамы одноместная и узкая, а значит, безопасно спать малышу с мамой там невозможно. В случае, когда в палате мамы широкая двухместная кровать, совместный сон допускается и даже приветствуется при правильной его организации.

Осмотры малыша

Уже с 1-го дня пребывания в роддоме малыша ждут осмотры:

Минимум 1 раз в день малыша осматривает врач-неонатолог. 
В случае необходимости врач может посетить малыша и несколько раз в день. Кратность осмотров определяется индивидуально и зависит в целом от самочувствия новорожденного ребенка. Врач спросит, нет ли у вас жалоб, как малыш спал, выяснит все о кормлении, какой стул был и сколько ребенок мочился. Также он даст вам рекомендации и ответит на все вопросы. Можете заранее записать их в блокнот или в телефон.

Каждый день к вам в палату будет приходить детская медсестра. 
Она проводит взвешивание ребенка и при необходимости делает процедуры или дает лекарства, которые назначил доктор. Также она может научить вас правильно осуществлять ежедневный гигиенический уход за ребенком, который вы сможете осуществлять самостоятельно после выписки из роддома. Еще медсестра будет помогать маме в уходе за малышом, если она по состоянию своего здоровья временно делать этого не может.

Налаживание ГВ

Первые дни в роддоме очень важны, чтобы наладить грудное вскармливание. Детская медсестра или акушерка могут помочь маме и рассказать, как правильно приложить малыша к груди, как наладить достаточную выработку молока. 

Если мама с малышом находятся раздельно, то обычно доктора просят маму сцеживаться каждые 3 часа. Если доктора разрешили и помогли маме наладить грудное вскармливание, то это молочко мама может относить малышу в палату интенсивной терапии или в отделение детской реанимации. Если малыш находится отдельно от мамы, желательно, чтобы у мамы был с собой молокоотсос, или его могут передать вам при необходимости родные. Если молокоотсоса нет, то персонал обязательно поможет вам и научит сцеживаться руками.

Пребывание в роддоме – это время, когда у мамы возникает много вопросов и когда может понадобиться помощь персонала. И вы всегда сможете ее получить.

Всегда ли мама с малышом находятся вместе? Нет. Ведь с первых дней в роддоме как маму, так и малыша ждут различные обследования, анализы. Также мама будет ходить в туалет, в столовую, на процедуры при необходимости. В этом случае мама может отвезти малыша на детский пост под наблюдение медсестры.

Если малышу необходимо сделать плановые обследования или анализы, то мама может сопровождать малыша на исследования. Это обсуждается индивидуально с врачом-неонатологом. А общий анализ крови, например, лаборант приходит брать прямо в палату.

Пограничные состояния новорожденного

Они могут появиться уже в роддоме и испугать новоиспеченных родителей. Ведь в течение первых 3-5 дней жизни после рождения организм малыша активно адаптируется к условиям жизни в большом мире. И у него могут появляться так называемые пограничные или переходные состояния. Они называются так, потому что это будто граница между нормой и патологией или граница между внутриутробной и внеутробной жизнью. То есть это состояния адаптации к новой жизни. Например, одно из таких состояний – физиологическая желтуха у детей. Она может появиться на 3-и сутки. Врачи могут обнаружить повышение билирубина в анализе крови, и малышу может понадобиться помощь. Например, его могут посветить под специальной лампой и оставить в роддоме на некоторое время. 

И, конечно, эти дни в роддоме мама будет посвящать тому, что будет учиться осуществлять грамотный уход за своим малышом.

${tb5c1_3}

Урок 3. Основы ежедневного ухода за новорожденным в роддоме

Как выглядит малыш и что он уже умеет? Мамы и папы очень любят в первые дни пребывания с малышом класть пальчик ему в ручку – и малыш его крепко-крепко сжимает. Это один из рефлексов новорожденного.

С самого рождения новорожденный малыш обладает целым набором определенных рефлексов, которых в норме не встретишь у взрослых. Природа наделила такими рефлексами малышей для того, чтобы защищать и оберегать его. Расскажу вам о наиболее понятных рефлексах, случайно обнаружить которые могут даже родители. 

Хватательный рефлекс
Если вложили пальчик в ладошки младенца, он начинает крепко сжимать его. Причем схватить может так сильно, что врач во время осмотра сможет даже немного приподнять ребенка. Самим родителям поднимать малыша таким образом я не советую, так как есть высокий риск не удержать кроху, из-за чего он может удариться головой.

Поисковый рефлекс
При поглаживании кожи ребенка в области уголка рта происходит поворот головы в сторону раздражителя. 

Сосательный рефлекс
Если вложить малышу в ротик мизинец или сосок груди, малыш начнет осуществлять энергичные сосательные движения. 

Хоботковый рефлекс 
При прикосновении пальцем к губам малыша отмечается вытягивание губ. Этот рефлекс также символизирует готовность малыша приступить к кормлению.

Рефлекс автоматической походки
Проверяется он следующим образом. Если поднять малыша в области подмышки и поставить его на ровную поверхность, чуть наклонив вперед, он поднимает ножку и как будто собирается идти. 

Рефлекс ползания
Еще задолго до того, как малыш начинает ползать по-настоящему, прикладывание ладони к стопам лежащего на животе ребенка вызывает рефлекторное отталкивание и ползание.

Рефлекс Моро
Нередко родители пугаются, когда вдруг ребенок ни с того ни с сего разводит ручками, выпрямляет ножки, разжимает кулачки, а еще через мгновение или обнимает себя, или начинает сильно плакать. Это проявления того самого рефлекса Моро. Спровоцировать его могут резкие звуки вокруг или неожиданные прикосновения к младенцу. 

В норме все рефлексы новорожденного постепенно угасают и исчезают. Если при оценке рефлексов новорожденного ребенка педиатра что-то будет смущать, он порекомендует консультацию врача-невролога.

Основы ухода за малышом

Сначала вы, конечно, будете привыкать к малышу и учиться правильно брать его на руки. Самое основное для ребенка в роддоме – это, конечно же, своевременная смена подгузника и подмывание. 

Подмывание ребенка 

Делать это можно под краном.
Температура воды – 36-37°С. Это можно оценить тактильно – вода такой температуры будет ощущаться ладонью взрослого человека как нейтральная. 
Помните, что в роддоме не нужно купать ребенка полностью. Также следите за тем, чтобы вы не намочили пуп. 
Подмывание малыша в раковине нужно для того, чтобы очистить попу и половые органы от мекония. И не забываем, что подмывать ребенка вне зависимости от пола нужно спереди назад, то есть от половых органов к попе.
Подмывать ребенка нужно после каждого грязного подгузника, то есть как только малыш сходил в туалет по-большому. Один раз в день можно малыша мыть детским гелем или специальным мылом. Учитывая, что меконий (это первый кал малыша) очень жирный и липкий и часто водой он смывается сложно, можно использовать детское мыло или гель для купания.
В каком-либо дополнительном уходе, кроме подмывания попы, ребенок не нуждается. Чистить турундочками и без того свободно дышащий носик, промывать чистые глазки для профилактики новорожденному не нужно.

Ежедневный туалет пупочного остатка

Сегодня рекомендуется сухое ведение пупочного остатка. Его ничем обрабатывать не нужно. Главное – наблюдать за тем, чтобы он был сухим и чистым. Также важно беречь пупочную ранку от попадания мочи и кала и не допускать травмирования краями подгузника. Для этого край подгузника нужно немного заворачивать, чтобы он находился ниже линии пупка.

Сегодня роддома предлагают и палаты для совместного пребывания мамы и папы. Тогда папа тоже может отлично помогать в вопросах ежедневного ухода. Например, если папа находится не постоянно в палате, то он может приходить в те часы, когда у мамы осмотры, и находиться вместе с малышом. Если мама после кесарева сечения и пока восстанавливается, то папа может подать ей малыша для кормления грудью, менять подгузники и подмывать ребенка.

${separator}

Урок 4. Обследования и осмотры малыша в роддоме

Сразу после рождения и затем все время пребывания в роддоме ребенка осматривает врач-неонатолог. Как мы обсуждали ранее, в первые минуты жизни врач-неонатолог проводит оценку ребенка по шкале Апгар. И в зависимости от состояния ребенка врач-неонатолог составит план по обследованиям вашего малыша.

Обследования

В роддомах Беларуси в обязательном порядке проводится скрининг на 3 заболевания с весьма сложными названиями:

врожденный гипотиреоз
фенилкетонурия
муковисцидоз
Для этого у малыша берут анализ крови из пятки или из пальчика. Он проводится бесплатно абсолютно всем деткам во всех роддомах Республики Беларусь. Доношенным детям его выполняют на 3-4-е сутки жизни, недоношенным – на 6-7-е. 

Почему важно выявить эти заболевания еще на уровне роддома? Потому что при выявлении заболевания малышу сразу назначат базовое лечение. Чем быстрее мы найдем то или другое заболевание, тем больше шансов, что малыш почти не пострадает и заболевания никак себя клинически не проявят.

Также ребенку проводят аудиоскрининг. Это обследование на выявление пороков развития слухового аппарата. Проще говоря, проверка слуха. Он тоже нужен для того, чтобы ребенку вовремя могли помочь и он мог слышать и полноценно развиваться. Сама по себе процедура абсолютно безболезненна, не приносит малышу никакого дискомфорта. Во время обследования трубочка специального прибора помещается в ушко малыша, где создаются тестовые звуковые импульсы. Процедура аудиоскрининга совсем непродолжительная – проверка каждого ушка займет около полутора минут. Если аудиоскрининг не провели в роддоме, то доктора порекомендуют вам провести его уже после выписки в первый месяц жизни ребенка.

В роддоме в обязательном порядке у малыша берут общий анализ крови. По анализу крови смотрят, нет ли у ребенка каких-либо воспалительных заболеваний, врожденных инфекций, нет ли у него анемии. 

Чаще всего в роддомах общий анализ крови берется из пальчика младенца. Если все хорошо, малыш доношенный и имеет высокую оценку по шкале Апгар, анализ крови выполняется ему в первые сутки после рождения. И в этом случае анализ будет выполняться однократно.

Общий анализ мочи и биохимический анализ крови в роддоме делают по показаниям. Если ребенок здоровый и врач не видит никаких клинических проявлений заболевания, если у него хороший цвет кожи, нет нарушений сосания, резкого снижения веса, то биохимический анализ крови не делают.

Если биохимический анализ крови все же сделают, то основной показатель, на который смотрят доктора, – это билирубин. Есть такое понятие (я думаю, вы все о нем слышали) физиологическая желтуха новорожденных. Также есть и другие виды желтух. И в таких случаях в крови будет повышение содержания билирубина.

Если у мамы с папой отличаются резус-фактор и группа крови, то малышу после рождения могут брать анализ для определения резус-фактора и группы крови. Также делают специальный анализ, который называется проба Кумбса, на наличие гемолитической болезни новорожденных. Это гемолитическая анемия новорожденных. Она развивается, когда кровь матери и плода несовместимы по резус-фактору, группе крови и другим факторам крови.

Обязательно малышу сделают электрокардиограмму. Здоровым новорожденным электрокардиограмму проводят перед выпиской – на 3-5-й день жизни. 

Если есть какие-то шумы и врача что-то беспокоит, по показаниям могут провести УЗИ сердца. Если были проблемы в родах или врач-неонатолог видит какие-либо изменения у малыша, то его могут направить на осмотр невролога. Невролог чаще всего делает малышу УЗИ головного мозга.

Группы риска

В итоге по всем обследованиям (по тому, как протекали роды, по состоянию родившей женщины) врач-неонатолог дает оценку состояния здоровья малыша. Также врач-неонатолог выставляет группу риска новорожденных. Давайте разберемся, что это за группы, чтобы молодые мамы не переживали и знали, что делать в каждом из случаев.

Есть 4 группы риска. Чтобы их определить, учитывается история рождения малыша, а также история беременности мамы.

Риск развития патологии центральной нервной системы. Например, если у первородящей мамы были быстрые роды (меньше 4-6 часов), то ребенку могут выставить риск развития патологии ЦНС. Или ребенок был рожден с помощью вспомогательных репродуктивных технологий. Клинически по всем обследованиям малыш здоровый и не имеет никаких отклонений.
Риск реализации внутриутробной инфекции. Факторами риска могут быть недоношенность, маловесность ребенка или, например, маловодие/многоводие у мамы.
Риск развития анемии.
Риск развития синдрома внезапной детской смерти.
Зачем выставлять группы риска? Если ребенку выставляют группу риска, это не значит, что у него есть какое-то заболевание. Это делается для того, чтобы доктор в поликлинике смог определить и понять, как часто нужно наблюдать малыша, какие ему необходимы диагностические обследования в дальнейшем, когда ребенок уже будет наблюдаться после выписки участковым педиатром.

Всем ли детям выставляют группу риска? Согласно сегодняшним официальным документам, группа риска – это здоровые дети, имеющие в анамнезе факторы, которые формируют риск развития хронических заболеваний. Я обращаю ваше внимание на слово здоровые. Не стоит переживать, дорогие мамы, когда вы слышите о группе риска. Именно это поможет в дальнейшем правильно наблюдать вашего ребенка. Ведь, исходя из группы риска, проводится наблюдение детей до года, так как любое заболевание может реализовать себя в течение года.

В определенные сроки риски патологии, если они никак себя не реализовали и ребенок здоров, снимаются:

риск развития патологии центральной нервной системы снимается в 6 месяцев
риск развития анемии и риск развития синдрома внезапной детской смерти – в год
исключением является риск реализации внутриутробной инфекции. Он может реализоваться в течение 3 месяцев
Прививки

Также малыша в роддоме ждут и первые прививки (при условии согласия родителей). 

В первые сутки после рождения ребенку выполняется его первая в жизни вакцина – против вирусного гепатита В. Делается она уже в отделении для новорожденных в первые 12 часов жизни. 
Прививка против туберкулеза (БЦЖ М). Как и в случае с гепатитом В, столь ранняя вакцинация рекомендуется Всемирной организацией здравоохранения. Это связано с высокой частотой особенно тяжелых внелегочных форм туберкулеза у детей. Если нет противопоказаний, новорожденные детки вакцинируются против туберкулеза на 3-5-е сутки после рождения. 
Документы для малыша

При выписке из роддома мама получает важные документы. Один из них – обменная карта новорожденного. В ней указаны данные о ребенке: какие обследования были проведены, его диагноз, группа риска. Эту карту мама затем отдаст педиатру, который совсем скоро придет к вам в гости для первого осмотра дома. После выписки из роддома ваш педиатр изучит этот документ и расскажет, какие дальнейшие обследования рекомендуются ребенку.
    `,
};
