import { Document, Font, Image, Page, Text } from '@react-pdf/renderer';
import './CertificateDocument.style.css';
import { certificateBlank } from 'assets/images';

type CertificateDocumentProps = {
  id: string;
  firstName: string;
  lastName: string;
  country: string;
  town: string;
  date: string;
};

Font.register({
  family: 'Open Sans',
  src: 'https://fonts.cdnfonts.com/s/14884/OpenSans-Regular.woff',
});

export const CertificateDocument = ({
  id,
  firstName,
  lastName,
  country,
  town,
  date,
}: CertificateDocumentProps) => (
  <Document>
    <Page size="A4" orientation="landscape" style={{ fontFamily: 'Open Sans' }}>
      <div
        style={{
          position: 'absolute',
          minWidth: '100%',
          minHeight: '100%',
          display: 'block',
          height: '100%',
          width: '100%',
        }}>
        <Image src={certificateBlank} />
      </div>
      <div>
        <div
          style={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'flex-end',
            paddingBottom: '100px',
          }}>
          <div style={{ fontSize: 24, fontWeight: 400 }}>
            <Text>№ {id}</Text>
          </div>
          <div style={{ fontSize: 30, fontWeight: 600 }}>
            <Text style={{ fontSize: 30, fontWeight: 600 }}>
              {firstName} {lastName}
            </Text>
          </div>
          <div
            style={{
              fontSize: 24,
              fontWeight: 400,
              textAlign: 'center',
              marginTop: 30,
            }}>
            <Text>Страна: {country}</Text>
          </div>
          <div
            style={{
              fontSize: 24,
              fontWeight: 400,
              textAlign: 'center',
            }}>
            <Text>Населённый пункт: {town}</Text>
          </div>
          <div style={{ fontSize: 24, fontWeight: 400, paddingTop: 20 }}>
            <Text>Дата: {date}</Text>
          </div>
        </div>
      </div>
    </Page>
  </Document>
);
