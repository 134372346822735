import { I18n } from 'i18n-js';

import { ru } from 'localization';
import { pluralization } from 'localization/pluralization';


export const i18n = new I18n({ ru });

i18n.locale = 'ru';
i18n.enableFallback = true;
i18n.missingBehavior = 'guess';
i18n.pluralization.register(i18n.locale, pluralization);

export const useTranslate = () => {
  // TODO store locale in global store
  return i18n.t.bind(i18n);
};
