export enum FormFields {
  DAYS_1 = 'days_1',
  DAYS_10 = 'days_10',
  WEEKS_1 = 'weeks_1',
  WEEKS_10 = 'weeks_10',
  MONTH_1 = 'month_1',
  MONTH_10 = 'month_10',
  YEAR_1 = 'year_1',
  YEAR_10 = 'year_10',
  YEAR_100 = 'year_100',
  YEAR_1000 = 'year_10000',
};
