import styles from './CourseButton.module.css';
import { useTranslate } from 'hooks/useTranslate';

type CourseButtonProps = {
  handleButton: () => void;
};

export const CourseButton = ({ handleButton }: CourseButtonProps) => {
  const t = useTranslate();
  return (
    <div className={styles.wrapper}>
      <button onClick={handleButton} className={styles.container}>
        {t('see_more')}
      </button>
    </div>
  );
};
