import {
  HeartIconFavoriteWhite,
  BookMarkFavoriteWhite,
  HeartIconFavorite,
  BookMarkFavorite,
} from 'assets/icons';
import ChipWithIcon from 'components/ChipWithIcon/ChipWithIcon';
import { useContentUpdateApi } from 'hooks/useContentUpdateApi';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';
import { CourseData, RecommendationType } from 'types';
import { getLikesCount } from 'utils/courses';
import styles from './FavoriteButtons.module.css';
import { getType } from 'utils/other';

type FavoriteButtonsProps = {
  isMobile: boolean;
  item: CourseData;
};

const FavoriteButtons: React.FC<FavoriteButtonsProps> = ({
  isMobile,
  item,
}) => {
  const type = getType();
  const { updateBooked, updateLikes, isBooked, isLike, loading } =
    useContentUpdateApi(type as RecommendationType, item);

  const handleLike = debounce(() => !loading && updateLikes(), 1500, {
    leading: true,
  });
  const handleBook = debounce(() => !loading && updateBooked(), 1500, {
    leading: true,
  });
  return (
    <div className={styles.favoriteContainer}>
      <div className={styles.chipContainer} onClick={handleLike}>
        <ChipWithIcon
          styleContainer={{
            backgroundColor: isLike ? 'rgb(157, 141, 241)' : '',
            marginBottom: isMobile ? 10 : 0,
          }}
          textStyle={{ color: isLike ? 'white' : undefined }}
          icon={isLike ? HeartIconFavoriteWhite : HeartIconFavorite}
          text={'Нравится'}
          withoutStyled={isMobile}
        />
      </div>
      <div className={styles.chipContainer} onClick={handleBook}>
        <ChipWithIcon
          styleContainer={{
            backgroundColor: isBooked ? 'rgb(157, 141, 241)' : '',
          }}
          textStyle={{ color: isBooked ? 'white' : undefined }}
          icon={isBooked ? BookMarkFavoriteWhite : BookMarkFavorite}
          text={'В избранное'}
          withoutStyled={isMobile}
        />
      </div>
    </div>
  );
};

export default FavoriteButtons;
