import { FC, forwardRef, useMemo } from 'react';
import styles from './DateOfChildBirth.module.css';
import { useTranslate } from 'hooks/useTranslate';
import { ArrowNextIcon, ArrowPrevIcon, EditProfileIcon, WrongProfileIcon } from 'assets/icons';
import { updateDbUser } from 'api/userApi';
import { noop } from 'lodash';
import DatePicker from "react-datepicker";
import ru from 'date-fns/locale/ru';
import { subDays, addDays } from 'date-fns';
import { getDayMonthYears } from 'features/Profile/utils';
import { UNKNOWN_DATE_10, UNKNOWN_DATE_1000 } from 'constants/constants';


const SEPARATOR = '/';


export const DateOfChildBirth: FC<{
  dateOfChildBirth:  number | null | undefined;
}> = ({
  dateOfChildBirth,
}) => {
  const t = useTranslate();

  const [day, month, year] = getDayMonthYears(dateOfChildBirth);

  const processDate = (value: number) => (
    String(value).padStart(2, '0')
  );

  const numberStyle = useMemo(
    () => (dateOfChildBirth || dateOfChildBirth === 0) ? styles.number : styles.unknown,
    [dateOfChildBirth]
  );

  const handleOnChange = (value: Date | null) => {
    updateDbUser({ dateOfChildBirth: value?.getTime()})
      .catch(noop);
  };

  const CustomInput = forwardRef(({ onClick }: any, ref: React.ForwardedRef<any>) => (
    <div className={ styles.displayContainer } onClick={ onClick } ref={ ref }>
      <div className={ numberStyle }>
        {
          !!day ? processDate(day) : UNKNOWN_DATE_10
        }
      </div>
      <div className={ styles.separator }>{ SEPARATOR }</div>
      <div className={ numberStyle }>
        {
          !!month ? processDate(month) : UNKNOWN_DATE_10
        }
      </div>
      <div className={ styles.separator }>{ SEPARATOR }</div>
      <div className={ numberStyle }>
        {
          year || UNKNOWN_DATE_1000
        }
      </div>
      <div className={ styles.iconContainer }>
        <img src={ EditProfileIcon } className={ styles.icon }  alt={ 'icon' } />
      </div>
    </div>
  ));

  return (
    <div className={ styles.container }>
      <div className={ styles.titleContainer }>
        {
          !dateOfChildBirth &&
            <img src={ WrongProfileIcon } className={ styles.wrongIcon } alt='wrong'/>
        }
        <div className={ styles.title }>
          { t('profile_date_childbirth_title') }
        </div>
      </div>
        <DatePicker 
          selected={ dateOfChildBirth ? new Date(dateOfChildBirth) : null }
          onChange={ (date) => handleOnChange(date) }
          withPortal
          locale={ ru }
          excludeDateIntervals={[
            { start: subDays(new Date(), 0), end: addDays(new Date(), (364 * 30)) },
          ]}
          customInput={ <CustomInput /> }
          renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
            <div className='react-datepicker__nav-container'>
              <button onClick={ decreaseMonth } className='react-datepicker__nav-container--button'>
                <div className='react-datepicker__nav-container--icon'>
                  <ArrowPrevIcon />
                </div>
              </button>
              <span className='react-datepicker__current-month'>
                { (date.toLocaleString('ru-RU', { month: 'long', year: 'numeric' })).replace(' г.', '') }
              </span>
              <button onClick={ increaseMonth } className='react-datepicker__nav-container--button'>
                <div className='react-datepicker__nav-container--icon'>
                  <ArrowNextIcon />
                </div>
              </button>
            </div>
          )}
        />
    </div>
  )
};
