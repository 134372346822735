import { ChangeEvent, FC, useCallback, useTransition } from 'react';
import styles from './UserPhoto.module.css';
import { useAppSelector } from 'store/store';
import { selectUser } from 'features/User/store/selectors';
import { Avatar } from 'components/Avatar/Avatar';
import { EditButton } from '../Buttons/EditButton/EditButton';
import { useTranslate } from 'hooks/useTranslate';
import { DeleteButton } from '../Buttons/DeleteButton/DeleteButton';
import { updateDbUser } from 'api/userApi';
import { useAppWindowSize } from 'hooks/useAppWindowSize';

export const UserPhoto: FC = () => {
  const t = useTranslate();
  const user = useAppSelector(selectUser);

  const handleEditPhoto = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;

      const reader = new FileReader();

      reader.onload = (event) => {
        const photoURL = event.target?.result as string;
        updateDbUser({ photoURL }).catch(() => alert(t('error_db_update')));
      };

      reader.readAsDataURL(file);
    },
    [t]
  );
  const { isMobile } = useAppWindowSize();
  const handleButtonClick = useCallback(() => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    // @ts-ignore
    input.onchange = handleFileSelect;
    input.click();
  }, []);

  const handleFileSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;

      const reader = new FileReader();

      reader.onload = (event) => {
        const photoURL = event.target?.result as string;
        updateDbUser({ photoURL }).catch(() => alert(t('error_db_update')));
      };

      reader.readAsDataURL(file);
    },
    []
  );

  const handleDeletePhoto = useCallback(() => {
    updateDbUser({ photoURL: null }).catch(() => alert(t('error_db_update')));
  }, [t]);

  return (
    <div className={styles.container}>
      <div>
        <Avatar
          url={user?.photoURL}
          big
          containerStyle={{ marginTop: isMobile ? 30 : undefined }}
        />
      </div>

      <div className={styles.controlsContainer}>
        <EditButton
          isEmpty={!!user?.photoURL}
          title={t(!!user?.photoURL ? 'common-change' : 'settings_photo_add')}
          onPress={handleButtonClick}
        />
        {!!user?.photoURL && (
          <DeleteButton
            title={t('common-delete')}
            onPress={handleDeletePhoto}
          />
        )}
      </div>
    </div>
  );
};
