import { ClockBlackIcon, CourseIcon } from 'assets/icons';
import { Label } from 'components/Label/Label';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import React from 'react';
import { getStringDuration } from 'utils/time';

interface LabelContainerProps {
  courseText: string;
  duration?: number;
}

const LabelContainer: React.FC<LabelContainerProps> = ({
  courseText,
  duration,
}) => {
  const { isMobile } = useAppWindowSize();
  const iconSize = isMobile ? 20 : 30;
  return (
    <>
      <Label
        for={
          <CourseIcon color={'#9D8DF1'} width={iconSize} height={iconSize} />
        }
        text={courseText}
      />
      <Label
        for={
          <ClockBlackIcon
            color={'#9D8DF1'}
            width={iconSize}
            height={iconSize}
          />
        }
        text={getStringDuration(duration)}
      />
    </>
  );
};

export default LabelContainer;
