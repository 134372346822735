import { FC } from 'react';
import styles from './NavMenu.module.css';
import classNames from 'classnames';
import { CloseIcon } from 'assets/icons';
import { LogoButton } from 'components/Buttons/LogoButton/LogoButton';
import { JoinButton } from 'components/Buttons/JoinButton/JoinButton';
import { SocialIconsList } from 'components/SocialIconList/SocialIconList';
import Navbar from 'components/Navbar/Navbar';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { useUser } from 'hooks/useUser';

export const NavMenu: FC<{
  isOpen: boolean;
  close: () => void;
}> = ({ isOpen, close }) => {
  const user = useUser();
  const { isMobile } = useAppWindowSize();
  return (
    <div className={classNames(styles.container, isOpen && styles.show)}>
      <div className={styles.header}>
        <LogoButton isScrolled={false} />
        <CloseIcon className={styles.closeIcone} onClick={close} />
      </div>

      <div className={styles.main}>
        <SocialIconsList
          isScrolled={false}
          containerStyle={
            !isMobile
              ? {
                  width: 50,
                  height: 50,
                  margin: 10,
                }
              : {}
          }
          socialIconsListStyle={
            !isMobile
              ? {
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }
              : {}
          }
        />
        <Navbar isScrolled={false} close={close} />
      </div>

      <div className={styles.footer}>
        <JoinButton
          isScrolled={false}
          containerStyle={isMobile ? { width: '100%' } : {}}
        />
      </div>
    </div>
  );
};
