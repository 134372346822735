import { ChangeEvent, FC, useCallback } from 'react';
import styles from './EditButton.module.css';
import { isFunction, noop } from 'lodash';

export const EditButton: FC<{
  title: string;
  onPress?: (event: ChangeEvent<HTMLInputElement>) => void;
  isEmpty?: boolean;
}> = ({ title, onPress = noop, isEmpty }) => {
  const handleClick = useCallback(() => {
    isFunction(onPress) && onPress();
  }, [onPress]);

  return (
    <div
      className={isEmpty ? styles.container : styles.containerEmpty}
      onClick={handleClick}
    >
      {title}
    </div>
  );
};
