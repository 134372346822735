import { updateDbUser } from 'api/userApi';
import { WrongProfileIcon } from 'assets/icons';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import { UNKNOWN_NAME } from 'constants/constants';
import { selectUser } from 'features/User/store/selectors';
import { useFormik } from 'formik';
import { useBinarySwitcher } from 'hooks/useBinarySwitcher';
import { useTranslate } from 'hooks/useTranslate';
import { noop } from 'lodash';
import { AppRoutes } from 'navigation/constants';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/store';
import styles from './SettingsScreen.module.css';
import { ControlButton } from './components/Buttons/ControlButton/ControlButton';
import { DeleteAccount } from './components/DeleteAccount/DeleteAccount';
import Input from './components/Input/Input';
import { ModalWrapper } from './components/ModalWrapper/ModalWrapper';
import SelectCountry from './components/SelectCountry/SelectCountry';
import SelectLanguage from './components/SelectLanguage/SelectLanguage';
import { UserPhoto } from './components/UserPhoto/UserPhoto';
import { getUserInfoValidationSchema } from './validations/UserInfoValidationSchema';
import { FormFields } from './validations/constants';

const { NAME, SURNAME, COUNTRY, TOWN } = FormFields;
export const SettingsScreen: FC = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);

  const [isVisible, showModal, hideModal] = useBinarySwitcher();
  const { firstName, lastName, country, town } = user;
  const isEmpty = !firstName || !lastName || !country || !town;

  const formik = useFormik({
    initialValues: {
      [NAME]: firstName || '',
      [SURNAME]: lastName || '',
      [COUNTRY]: country || '',
      [TOWN]: town || '',
    },
    validationSchema: getUserInfoValidationSchema(),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      updateDbUser({
        firstName: values[NAME] || UNKNOWN_NAME,
        lastName: values[SURNAME],
        country: values[COUNTRY],
        town: values[TOWN],
      })
        .then(() => navigate(AppRoutes.PROFILE_SCREEN))
        .catch(noop);
    },
  });

  const handleChangeFocus = useCallback(
    (isFocused: boolean) => {
      if (isFocused) {
        formik.setErrors({});
        formik.setStatus({});
      }
    },
    [formik]
  );

  const handleCancel = useCallback(() => {
    navigate(AppRoutes.PROFILE_SCREEN);
  }, [navigate]);

  const handleConfirm = useCallback(() => {
    formik.handleSubmit();
  }, [formik]);

  const handleClearField = (id: FormFields) => {
    formik.setValues({
      ...formik.values,
      [id]: '',
    });
  };
  return (
    <div className={styles.settingsContainer}>
      <Header styled />
      <main className={styles.main}>
        <div className={styles.container}>
          <div className={styles.controlsContainer}>
            <ControlButton
              title={t('common-cancel')}
              light
              onPress={handleCancel}
            />
            <ControlButton title={t('common-ready')} onPress={handleConfirm} />
          </div>
          <div className={styles.wrapper}>
            <UserPhoto />
            <div className={styles.formContainer}>
              <div className={styles.titleContainer}>
                {isEmpty && (
                  <img
                    src={WrongProfileIcon}
                    className={styles.wrongIcon}
                    alt="wrong"
                  />
                )}
                <div className={styles.title}>
                  {t('settings_user_info_title')}
                </div>
              </div>
              <div className={styles.blockContainer}>
                <Input
                  id={NAME}
                  label={t('settings_user_info_name')}
                  value={
                    formik.values[NAME] !== UNKNOWN_NAME
                      ? formik.values[NAME]
                      : ''
                  }
                  onChangeFocus={handleChangeFocus}
                  onChange={formik.handleChange}
                  error={!formik.isValid}
                  handleClearField={handleClearField}
                  redText
                  top
                />
                <Input
                  id={SURNAME}
                  label={t('settings_user_info_surname')}
                  value={formik.values[SURNAME]}
                  onChangeFocus={handleChangeFocus}
                  onChange={formik.handleChange}
                  error={!formik.isValid}
                  handleClearField={handleClearField}
                  redText
                  bottom
                />
              </div>
              <div className={styles.blockContainer}>
                <SelectCountry
                  value={formik.values[COUNTRY]}
                  onChange={(value) => formik.setFieldValue(COUNTRY, value)}
                  error={false}
                />
                <Input
                  id={TOWN}
                  label={t('settings_user_info_town')}
                  value={formik.values[TOWN]}
                  onChangeFocus={handleChangeFocus}
                  onChange={formik.handleChange}
                  error={!formik.isValid}
                  handleClearField={handleClearField}
                  redText
                  bottom
                />
              </div>
              <div className={styles.languageContainer}>
                <p className={styles.titleLanguage}>
                  {t('settings_app_language_title')}
                </p>
                <SelectLanguage />
              </div>
            </div>
            <ModalWrapper
              isVisible={isVisible}
              showModal={showModal}
              hideModal={hideModal}
            />
            <DeleteAccount onClick={showModal} />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
