import { FC, useEffect, useState } from 'react';
import styles from './Certificate.module.css';
import { ListButton } from '../ListButton/ListButton';
import { useTranslate } from 'hooks/useTranslate';
import { CertificateProfileIcon, DownloadProfileIcon } from 'assets/icons';
import { useUser } from 'hooks/useUser';
import { optional } from 'utils/other';
import { pdf } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { CertificateDocument } from 'pages/CertificatePage/CertificateDocument';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import FadeLoader from 'react-spinners/FadeLoader';

export const Certificate: FC = () => {
  const t = useTranslate();
  const { user } = useUser();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleCertificateDownload = () => {
    setIsDownloading(true);
  };

  //@ts-ignore
  let keys = Object.keys(user.waeb.certificates);

  let lastKey = keys[keys.length - 1];

  //@ts-ignore
  let lastCertificate = user.waeb.certificates[lastKey];

  const certificate =
    user.waeb?.certificate ||
    //@ts-ignore
    lastCertificate;

  const { id, firstName, lastName, town, country, createdAt } = optional<{
    id: string;
    firstName: string;
    lastName: string;
    town: string;
    country: string;
    createdAt: number;
  }>(certificate);

  useEffect(() => {
    if (isDownloading) {
      const doc = (
        <CertificateDocument
          id={id}
          firstName={firstName}
          lastName={lastName}
          country={country}
          town={town}
          date={dayjs(createdAt).format('DD.MM.YYYY')}
        />
      );
      const pdfBlobPromise = pdf(doc).toBlob();

      pdfBlobPromise
        .then((pdfBlob) => {
          const url = URL.createObjectURL(pdfBlob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'certificate.pdf';
          link.click();
          URL.revokeObjectURL(url);
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  }, [isDownloading, id, firstName, lastName, country, town, createdAt]);

  const { isMobile } = useAppWindowSize();
  return (
    <>
      {isDownloading && (
        <div className={styles.overlay}>
          <FadeLoader
            color="#9D8DF1"
            height={isMobile ? 18 : 24}
            margin={isMobile ? 8 : 12}
            radius={13}
            speedMultiplier={1}
            width={isMobile ? 10 : 11}
          />
        </div>
      )}
      <ListButton
        iconLeft={CertificateProfileIcon}
        iconRight={DownloadProfileIcon}
        title={t('profile_certificate')}
        big
        onPress={handleCertificateDownload}
      />
    </>
  );
};
