import { TagMzr } from 'assets/images';
import './MzrLogoBlock.style.css';
import { LogoList } from 'features/Home/sections/2-MZHRSection/components/LogoList/LogoList';

export const MzrLogoBlock = () => (
  <div className="mzr_logo_block">
    <img className="tag_mzr" src={TagMzr} alt="логотип мы ждем ребенка" />
    <LogoList />
  </div>
);
