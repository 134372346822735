import { useTranslate } from 'hooks/useTranslate';
import { getDaysCaption, getMonthCaption, getYearsCaption } from 'utils/time';
import styles from './StatusAfterChildBirth.module.css';

interface StatusBoxProps {
  years: number;
  months: number;
  days: number;
}

const StatusAfterChildBirth: React.FC<StatusBoxProps> = ({
  years,
  months,
  days,
}) => {
  const t = useTranslate();
  return (
    <div>
      <p className={styles.afterChildBirthTitle}>
        {t('section_title_recommendation_now_child')}
      </p>
      <p className={styles.afterChildBirthDescription}>
        {years} {getYearsCaption(years)} {months} {getMonthCaption(months)}{' '}
        {days} {getDaysCaption(days)}
      </p>
    </div>
  );
};

export default StatusAfterChildBirth;
