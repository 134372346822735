import { useAllCourses } from './useAllCourses';

export const useAllBookedCourses = (id: string) => {
  const { allCourses, loading } = useAllCourses();

  return {
    bookedAllCourses: allCourses?.filter(
      (item) => item.booked && !!item.booked[id],
    ),
    loading,
  };
};
