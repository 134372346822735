import {
  getEstimatedDateOfBirth,
  getTrimester,
  getWeeksAndDays,
} from 'features/Profile/utils';
import { useTranslate } from 'hooks/useTranslate';
import { useUser } from 'hooks/useUser';
import { UsageOptions } from 'types';
import { getTimeAfter } from 'utils/time';
import styles from './StatusBox.module.css';
import StatusAfterChildBirth from './components/StatusAfterChildBirth/StatusAfterChildBirth';
import StatusContainer from './components/StatusContainer/StatusContainer';
import StatusManaging from './components/StatusManaging/StatusManaging';
import { CSSProperties } from 'react';

interface StatusBoxProps {
  icon?: any;
  title?: string;
  status?: UsageOptions | null | undefined;
  isShtick?: boolean;
  iEmpty?: boolean;
  withoutIcon?: boolean;
  containerStyle?: CSSProperties;
}

const StatusBox: React.FC<StatusBoxProps> = ({
  icon: Icon,
  title,
  status,
  isShtick,
  iEmpty,
  withoutIcon,
  containerStyle,
}) => {
  const { isEmptyDate, user } = useUser();

  const { dateOfChildBirth, dateOfPregnancy, usageOption: selected } = user;
  const [pregnancyDate, pregnancyWeek] = getWeeksAndDays(dateOfPregnancy);
  const { years, months, days, weeks } = getTimeAfter(dateOfChildBirth);
  // @ts-ignore
  const trimester = getTrimester(pregnancyWeek);

  const [estimatedDateOfBirth] = getEstimatedDateOfBirth(dateOfPregnancy);

  const containerClassName = (() => {
    if (iEmpty) {
      return styles.emptyContainer;
    } else if (status !== UsageOptions.PLANNING_PREGNANCY && !isShtick) {
      return styles.container;
    } else if (status === UsageOptions.MANAGING_PREGNANCY && !iEmpty) {
      return styles.containerPlanningPregnancy;
    } else {
      return styles.containerPlanning;
    }
  })();
  return (
    <div style={containerStyle} className={containerClassName}>
      {!!isShtick && withoutIcon && (
        <StatusContainer title={title} icon={Icon} />
      )}
      {status === UsageOptions.AFTER_CHILDBIRTH && !withoutIcon && (
        <StatusAfterChildBirth years={years} months={months} days={days} />
      )}
      {status === UsageOptions.MANAGING_PREGNANCY &&
        (!!pregnancyDate || !!pregnancyWeek) &&
        !withoutIcon && (
          <StatusManaging
            pregnancyWeek={pregnancyWeek}
            pregnancyDate={pregnancyDate}
            trimester={trimester}
            estimatedDateOfBirth={estimatedDateOfBirth}
          />
        )}
    </div>
  );
};

export default StatusBox;
