import { FC, useCallback } from "react";
import styles from './ListMenuButton.module.css';
import { isFunction, noop } from "lodash";
import classNames from "classnames";


export const ListMenuButton: FC<{
  iconLeft: string;
  title: string;
  isRed?: boolean,
  onPress?: () => void;
}> = ({
  iconLeft,
  title,
  isRed = false,
  onPress = noop,
}) => {
  const handleClick = useCallback(
    () => {
      isFunction(onPress) && onPress();
    },
    [onPress]
  );

  return (
    <button
      className={ styles.container }
      onClick={ handleClick }
    >
      {
        !!iconLeft && 
          <img 
            src={ iconLeft }
            className={ styles.iconLeft }
            alt={ 'icon' }
          />
      }
      <div className={ classNames(
          styles.title,
          isRed && styles.redTitle,
        ) }
      >
        {
          title
        }
      </div>
    </button>
  );
};
