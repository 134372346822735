import styles from './Header.module.css';
import { CSSProperties, FC, useCallback, useEffect, useState } from 'react';
import Navbar from '../Navbar/Navbar';
import './Header.css';
import { LogoButton } from 'components/Buttons/LogoButton/LogoButton';
import classNames from 'classnames';
import { JoinButton } from 'components/Buttons/JoinButton/JoinButton';
import { MenuButton } from 'components/Buttons/MenuButton/MenuButton';
import { NavMenu } from 'components/NavMenu/NavMenu';
import { SocialIconsList } from 'components/SocialIconList/SocialIconList';
import { useAppSelector } from 'store/store';
import { Avatar } from 'components/Avatar/Avatar';
import { selectUser } from 'features/User/store/selectors';
import { readFormStorage } from 'storage/storage';
import { StorageKeys } from 'storage/constants';
import { NavLink } from 'react-router-dom';
import { AppRoutes } from 'navigation/constants';

const Header: FC<{
  styled?: boolean;
  containerStyle?: CSSProperties;
}> = ({ styled = false, containerStyle: headerStyle }) => {
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const user = useAppSelector(selectUser);

  useEffect(() => {
    if (styled) return;

    function scrollFunction() {
      if (window.pageYOffset > 30) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }
    window.addEventListener('scroll', scrollFunction);

    return () => {
      window.removeEventListener('scroll', scrollFunction);
    };
  }, [styled]);

  const handleCloseMenu = useCallback(() => setIsMenuOpen(false), []);

  return (
    <header className={styles.header} style={headerStyle}>
      <div className={styles.container}>
        <div
          className={classNames(
            styles.wrapper,
            (scrolled || styled) && styles.scrolled
          )}
        >
          <div className={styles.wrapperSection}>
            {user?.id && (
              <NavLink
                to={AppRoutes.PROFILE_SCREEN}
                className={styles.infoContainer}
              >
                <Avatar url={user.photoURL} />
                <span
                  className={classNames(
                    styles.userFirstName,
                    (scrolled || styled) && styles.userFirstNameScrolled
                  )}
                >
                  {user.firstName}
                </span>
              </NavLink>
            )}
            {!user?.id && <LogoButton isScrolled={scrolled || styled} />}
            <div className={styles.visibleContainer}>
              <Navbar isScrolled={scrolled || styled} />
            </div>
          </div>
          <div className={styles.wrapperSection}>
            <div className={styles.visibleContainer}>
              <SocialIconsList isScrolled={scrolled || styled} />
            </div>

            {/* <div className="main-language-switcher">
            <div
              className={
                scrolled
                  ? 'language-switcher-title'
                  : 'language-switcher-selected'
              }
            >
              RU
            </div>
            <div className="languages">
              <Link to="#!">Русский</Link>
              <Link to="#!">English</Link>
            </div>
            </div> */}

            <div className={styles.visibleContainer}>
              {!user?.id && <JoinButton isScrolled={scrolled || styled} />}
            </div>
            <MenuButton
              isScrolled={scrolled || styled}
              onClick={() => setIsMenuOpen(true)}
            />
          </div>
        </div>
        {isMenuOpen && (
          <div className={styles.overlay} onClick={handleCloseMenu}></div>
        )}
        <NavMenu isOpen={isMenuOpen} close={handleCloseMenu} />
      </div>
    </header>
  );
};

export default Header;
