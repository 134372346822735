import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RecommendationType } from 'types';
import { as, optional } from 'utils/other';
type QueryParams = {
  content_id: string;
  content_type: RecommendationType;
  screen_id: string;
};
type QueryParamsFn = (content_id: string) => string;
const navigationMap: Record<
  string,
  Partial<Record<RecommendationType, QueryParamsFn>>
> = {
  COURSE_DETAIL: {
    [RecommendationType.COURSE]: (content_id: string) =>
      `/edu/courses/${content_id}`,
    [RecommendationType.POST]: (content_id: string) =>
      `/edu/posts/${content_id}`,
  },
  LESSON: {
    [RecommendationType.ADVICE]: (content_id: string) =>
      `/edu/advices/${content_id}`,
  },
};

export const getUrlParams = (search: string): Record<string, string> => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  return hashes.reduce((params, hash) => {
    const [key, val] = hash.split('=');
    return Object.assign(params, { [key]: decodeURIComponent(val) });
  }, {});
};

export const useDeepLink = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const { screen_id, content_id, content_type } = optional<
      Partial<QueryParams>
    >(getUrlParams(window.location.search));
    if (screen_id && content_type && content_id) {
      navigate(navigationMap[screen_id][content_type]!(content_id));
    }
  }, []);
};
