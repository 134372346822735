import { FC, memo, useCallback } from "react";
import styles from './SkipStepButton.module.css';
import { AppRoutes } from "navigation/constants";
import { useNavigate } from "react-router-dom";


const SkipStepButton: FC<{
  title: string;
  to?: string
}> = memo(({
  title,
  to = AppRoutes.INTERESTS_SCREEN,
}) => {
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    () => {
      navigate(to)
    },
    [navigate, to]
  );

  return (
    <div
      className={ styles.container }
      onClick={ handleNavigate }
    >
      { title }
    </div>
  )
});

export default SkipStepButton;
