import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { CourseData } from 'types';
import { UntypedObject } from 'types/baseTypes';

const initialState: {
  tips: CourseData[];
  courses: CourseData[];
  posts: CourseData[];
  meditation: CourseData[];
  lullabies: CourseData[];
} = {
  tips: [],
  courses: [],
  posts: [],
  meditation: [],
  lullabies: [],
};

export type ContentKey =
  | 'tips'
  | 'courses'
  | 'posts'
  | 'meditation'
  | 'lullabies';

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    saveTips: (state, action: PayloadAction<CourseData[]>) => {
      state.tips = action.payload;
    },
    saveMeditation: (state, action: PayloadAction<CourseData[]>) => {
      state.meditation = action.payload;
    },
    saveLullabies: (state, action: PayloadAction<CourseData[]>) => {
      state.lullabies = action.payload;
    },
    saveCourses: (state, action: PayloadAction<CourseData[]>) => {
      state.courses = action.payload;
    },
    savePosts: (state, action: PayloadAction<CourseData[]>) => {
      state.posts = action.payload;
    },
    updatePostDuration: (
      state,
      action: PayloadAction<{ id: string; duration: number }>
    ) => {
      const { duration, id } = action.payload;
      const index = state.posts?.findIndex((it: CourseData) => it.id === id);
      if (index !== -1 && state?.posts[index]) {
        state.posts[index].duration = duration;
      }
    },
    updateContentBooked: (
      state,
      action: PayloadAction<{
        id: string;
        userId: string;
        isBooked: boolean;
        key: ContentKey;
      }>
    ) => {
      const { key, userId, isBooked, id } = action.payload;
      const index = state[key]?.findIndex((it: CourseData) => it.id === id);
      if (index !== -1 && state?.[key]?.[index]?.booked) {
        //@ts-ignore
        state[key][index].booked[userId] = isBooked;
      }
    },
    updateContentLikes: (
      state,
      action: PayloadAction<{
        id: string;
        userId: string;
        isLike: boolean;
        key: ContentKey;
      }>
    ) => {
      const { key, userId, isLike, id } = action.payload;
      const index = state[key]?.findIndex((it: CourseData) => it.id === id);
      if (index !== -1 || state?.[key]?.[index]?.analytics) {
        //@ts-ignore
        state[key][index].analytics.likes[userId] = isLike;
      }
    },
  },
});

const { reducer, actions } = coursesSlice;

export const {
  saveTips,
  saveCourses,
  saveMeditation,
  saveLullabies,
  savePosts,
  updateContentLikes,
  updateContentBooked,
  updatePostDuration,
} = actions;

export default reducer;
