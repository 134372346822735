import { FC } from 'react';
import styles from './StatusPicker.module.css';
import classNames from 'classnames';
import { useTranslate } from 'hooks/useTranslate';
import { USAGE_OPTIONS, UsageOptions } from 'constants/constants';
import { CheckIcon } from 'assets/icons';


export const StatusPicker: FC<{
  selectedStatus?: UsageOptions | null;
  onChange: (value: UsageOptions) => () => void,
}> = ({
  selectedStatus,
  onChange,
}) => {
  const t = useTranslate();

  return (
    <div className={ styles.container }>
      {
        USAGE_OPTIONS.map(({ title, icon: Icon }) => (
            <div
              key={ title }
              className={ classNames(
                styles.wrapper,
                title === selectedStatus && styles.selected
              ) } 
              onClick={ onChange(title) }
            >
              <Icon />
              <div>
                { t(`${title}`) }
              </div>
              <div className={ styles.check }>
                <CheckIcon /> 
              </div>
            </div>
          )
        )
      }
    </div>
  )
};
