import { FC } from "react";
import styles from './LogoButton.module.css';
import { LogoIcon, LogoWhiteIcon } from "assets/icons";
import { AppRoutes } from "navigation/constants";
import { Link } from "react-router-dom";

export const LogoButton: FC<{
  isScrolled: boolean;
}>= ({
  isScrolled,
}) => (
  <Link to={ AppRoutes.HOME }>
    <img 
      src={ isScrolled ? LogoIcon : LogoWhiteIcon }
      alt="Logo"
      id="logo-button"
      className={ styles.container  }
    />
  </Link>
);
