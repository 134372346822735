import isFunction from 'lodash/isFunction';
import {
  CSSProperties,
  ChangeEvent,
  ReactElement,
  memo,
  useCallback,
} from 'react';

import classNames from 'classnames';
import styles from './ModalButton.module.css';

type ModalButtonProps = {
  containerStyle?: CSSProperties;
  titleStyle?: CSSProperties;
  title: string;
  big?: boolean;
  flat?: boolean;
  transparent?: boolean;
  disabled?: boolean;
  firstIcon?: ReactElement;
  secondIcon?: ReactElement;
  onPress: (event: ChangeEvent<HTMLInputElement>) => void;
};
export const ModalButton = memo(
  ({
    title,
    titleStyle,
    big = false,
    flat,
    transparent = false,
    disabled,
    containerStyle,
    firstIcon,
    secondIcon,
    onPress,
    ...props
  }: ModalButtonProps) => {
    const handleOnPress = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        isFunction(onPress) && onPress(event);
      },
      [onPress]
    );

    return (
      <button
        {...props}
        disabled={disabled}
        className={classNames(flat ? styles.containerFlat : styles.container)}
        // @ts-ignore
        onClick={handleOnPress}
      >
        {firstIcon}
        <p className={classNames(styles.title)}>{title}</p>
        {secondIcon}
      </button>
    );
  }
);
