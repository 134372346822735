import { Title } from 'components/Text/Title/Title';
import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { UsageOptions } from 'types';

import styles from './MainPageTitleSection.module.css';
import { MainPageStatus } from './MainPageStatus/MainPageStatus';

type MainPageTitleSectionProps = {
  status: UsageOptions | null | undefined;
  title?: string | undefined;
  showTitle: boolean;
  emptyTitle?: boolean;
};
export const MainPageTitleSection: FC<MainPageTitleSectionProps> = ({
  status,
  title,
  showTitle,
  emptyTitle,
}) => {
  const t = useTranslate();

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Title text={t('nav_link_profile')} center />
      </div>
      {status !== UsageOptions.PLANNING_PREGNANCY && (
        <MainPageStatus
          status={status}
          title={title}
          showTitle={showTitle}
          emptyTitle={emptyTitle}
        />
      )}
    </div>
  );
};
