import { FC } from "react";
import styles from "./Footer.module.css";
import { LogoWhiteIcon } from "assets/icons";
import { useTranslate } from "hooks/useTranslate";
import { SocialIconsList } from "components/SocialIconList/SocialIconList";
import classNames from "classnames";
import { StoreLink } from "components/StoreLink/StoreLink";
import { Providers } from "constants/constants";
import { NavLink } from "react-router-dom";
import { AppRoutes } from "navigation/constants";


const Footer: FC<{
  contactRef?: any;
}> = ({
  contactRef
}) => {
  const t = useTranslate();

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.logoBlock}>
          <img
            src={LogoWhiteIcon}
            className={styles.logoIcon}
            alt={"Mama Pro logo"}
          />
          <div className={styles.logoText}>{t("footer_logo_text")}</div>
          <SocialIconsList isScrolled={false} />
        </div>
        <div className={styles.nameBlock}>
          <div className={styles.name}>{t("footer_name")}</div>
          <div className={styles.unp}>{t("footer_unp")}</div>
        </div>
        <div className={styles.rightBlock}>{t("footer_right")}</div>
        <div className={styles.contactsBlock}>
          <div ref={ contactRef } id="contact" className={ styles.anchor }></div>
          <div className={styles.contactsTitle}>
            {t("footer_contacts_title")}
          </div>
          <div className={styles.contactsPhone}>
            <a href="tel:+375445578377" className={styles.phone}>
              +375(44) 557-83-77
            </a>
            <div className={styles.phoneDescription}>
              Telegram / WhatsApp / Viber
            </div>
          </div>
          <a
            href="https://t.me/mamaproAdmin"
            className={styles.contactsLink}
            target="_blank"
            rel="noreferrer"
          >
            {t("footer_telegram")}
          </a>
          <a href="mailto:info@mamapro.group" className={styles.contactsLink}>
            info@mamapro.group
          </a>
        </div>
        <div className={styles.adressBlock}>
          <div className={styles.adress}>
            <div className={styles.adressTitle}>
              {t("footer_mailing_adress_title")}
            </div>
            <div className={styles.adressDescription}>
              {t("footer_mailing_adress")}
            </div>
          </div>
          <div className={styles.adress}>
            <div className={styles.adressTitle}>
              {t("footer_legal_adress_title")}
            </div>
            <div className={styles.adressDescription}>
              {t("footer_legal_adress")}
            </div>
          </div>
        </div>
        <div className={styles.phoneBlock}>
          <div
            className={classNames(
              styles.phoneBlockTitle,
              styles.phoneBlockTitleColor
            )}
          >
            {t("footer_phone_title_1")}
          </div>
          <div className={styles.phoneBlockTitle}>
            {t("footer_phone_title_2")}
          </div>
          <div className={styles.linksContainer}>
            <StoreLink provider={Providers.APPLE} dark />
            <StoreLink provider={Providers.GOGGLE} dark />
          </div>
          <div className={styles.docsContainer}>
            <NavLink
              to={ AppRoutes.PRIVACY_SCREEN } 
              className={styles.docLink}
            >
              {t("footer_policy")}
            </NavLink>
            <NavLink 
              to={ AppRoutes.TERMS_SCREEN }
              className={styles.docLink}
            >
              {t("footer_licence")}
            </NavLink>
            <div className={styles.rightBlockGrey}>{t("footer_right")}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
