import { ClockBlackIcon } from 'assets/icons';
import { Label } from 'components/Label/Label';
import { FC } from 'react';
import styles from './ClockContainer.module.css';

interface ClockContainerProps {
  size: number;
  duration: string;
}

const ClockContainer: FC<ClockContainerProps> = ({ duration, size }) => {
  return (
    <div className={styles.clockContainer}>
      <Label
        for={<ClockBlackIcon width={size} height={size} color={'#9D8DF1'} />}
        text={duration || '~'}
      />
    </div>
  );
};

export default ClockContainer;
