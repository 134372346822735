import { auth } from 'api/constants';
import { getDBUser, setDBUser, updateDbUser } from 'api/userApi';
import { User } from 'features/User/type';
import { User as FirebaseUser, onAuthStateChanged } from 'firebase/auth';
import { noop } from 'lodash';
import { useCallback, useEffect } from 'react';
import { StorageKeys } from 'storage/constants';
import { readFormStorage, writeToStorage } from 'storage/storage';
import { useAppDispatch, useAppSelector } from 'store/store';
import { selectOnboardingData } from '../Onboarding/store/selectors';
import { updateIsLoggedIn } from '../store/AuthSlice';
import {
  selectAuthFirstName,
  selectAuthLastName,
  selectUid,
} from '../store/selectors';
import { useUserDbDataListener } from './useUserDbDataListener';

export const useAuthChangeListener = () => {
  const dispatch = useAppDispatch();

  const { startListening, stopListening } = useUserDbDataListener();
  const onboardingData = useAppSelector(selectOnboardingData);
  const firstNameFromStore = useAppSelector(selectAuthFirstName);
  const lastNameFromStore = useAppSelector(selectAuthLastName);
  const uid = useAppSelector(selectUid);

  const processAuthState = useCallback(
    async (initialUser: FirebaseUser | null) => {
      const user = initialUser || (uid ? ({ uid } as any) : null);

      if (user) {
        startListening(user.uid);

        const isLoggedIn = readFormStorage(StorageKeys.MAMAPRO_IS_LOGGED_IN);

        if (!isLoggedIn) {
          // TODO have to figure out is it need a loggedIn in redux store
          writeToStorage(StorageKeys.MAMAPRO_IS_LOGGED_IN, true);
          dispatch(updateIsLoggedIn(true));
        }

        const dbUser = await getDBUser(user.uid).catch(noop);
        if (dbUser?.id) {
          // user exists in database
          await updateDbUser({
            lastLoggedInDate: Date.now(),
            platform: 'web',
          }).catch(noop);
        }

        if (!dbUser?.id) {
          // user does not exist in database
          // create new user

          const processedData: User = {
            id: user.uid,
            dateOfChildBirth: onboardingData.dateOfChildBirth,
            dateOfPregnancy: onboardingData.dateOfPregnancy,
            interests: onboardingData.interests || [],
            username: null,
            firstName: firstNameFromStore || null,
            lastName: lastNameFromStore || null,
            photoURL: user.photoURL || null,
            email: user.email || null,
            country: null,
            town: null,
            locale: 'ru',
            platform: 'web',
            registrationDate: Date.now() || null,
            lastLoggedInDate: Date.now() || null,
            usageOption: onboardingData.usageOption,
            waeb: {
              startedAt: null,
              progress: null,
              certificate: null,
            },
          };

          if (processedData.firstName) {
            await setDBUser(user.uid, processedData).catch(noop);
          }
        }
      }

      if (!user) {
        writeToStorage(StorageKeys.MAMAPRO_IS_LOGGED_IN, false);
        dispatch(updateIsLoggedIn(false));
        stopListening();
      }
    },
    [
      dispatch,
      firstNameFromStore,
      lastNameFromStore,
      onboardingData.dateOfChildBirth,
      onboardingData.dateOfPregnancy,
      onboardingData.interests,
      onboardingData.usageOption,
      startListening,
      stopListening,
      uid,
    ]
  );

  useEffect(
    () => onAuthStateChanged(auth, processAuthState),
    [processAuthState]
  );
};
