import { AppState } from 'store/store';
import { RecommendationType } from 'types';

const typeConfig = {
  [RecommendationType.ADVICE]: 'tips',
  [RecommendationType.COURSE]: 'course',
  [RecommendationType.POST]: 'post',
};

export const selectAllCourses = (state: AppState) => state.courses;

export const selectTips = (state: AppState) => state.courses.tips;

export const selectPosts = (state: AppState) => state.courses.posts;

export const selectCourses = (state: AppState) => state.courses.courses;

export const selectContent =
  (type: RecommendationType, id: string) => (state: AppState) => {
    //@ts-ignore
    const key = typeConfig[type];
    //@ts-ignore
    const index = state.courses[key]?.findIndex(
      //@ts-ignore
      (it: CourseData) => it.id === id
    );
    //@ts-ignore
    if (index !== -1 && state.courses?.[key]?.[index]?.booked) {
      //@ts-ignore
      return state.courses[key][index];
    }
  };
