import { ClearProfileIcon, EditSettingProfile } from 'assets/icons';
import classNames from 'classnames';
import {
  FC,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './Input.module.css';

const Input: FC<
  InputHTMLAttributes<HTMLInputElement> & {
    id: any;
    label: string;
    error: boolean;
    top?: boolean;
    bottom?: boolean;
    redText?: boolean;
    onChangeFocus: (focus: boolean) => void;
    onChange?: any;
    handleClearField?: any;
  }
> = ({
  id,
  label,
  error,
  top,
  bottom,
  redText,
  onChangeFocus,
  handleClearField,
  onChange,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleOnFocus = useCallback(() => {
    setIsFocused(true);
    onChangeFocus(true);
  }, [onChangeFocus]);

  const handlePutFocus = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsFocused(false);
        onChangeFocus(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={classNames(
        styles.container,
        error && styles.containerError,
        (isFocused || props.value) && styles.focused,
        top && styles.containerTop,
        bottom && styles.containerBottom
      )}
    >
      <label
        style={{
          color: !!props.value ? undefined : !redText ? undefined : 'red',
        }}
      >
        {label}
      </label>
      <input
        {...props}
        id={id}
        onChange={onChange}
        onFocus={handleOnFocus}
        ref={inputRef}
      />
      <div className={styles.icon}>
        <img
          src={!isFocused ? EditSettingProfile : ClearProfileIcon}
          onClick={!isFocused ? handlePutFocus : () => handleClearField(id)}
          alt="edit icon"
        />
      </div>
    </div>
  );
};

export default Input;
