import { separator, tb3c6_1, tb3c6_2, tb3c6_3, tb3c6_4 } from 'assets/images';

export const b3_c13_text = {
  b3_c6_title: `Курс «Послеродовое восстановление» `,
  b3_c6_description: `
    Спикеры: Диана Мардас, врач акушер-гинеколог

    Елена Цыбулька, фитнес-тренер
    `,
  b3_c13_text: `
    ${tb3c6_1}
    
    Урок 1. Физиологические изменения в организме женщины после родов

В этом курсе мы познакомимся с восстановлением после родов. Узнаем важные нюансы, которые необходимо применять на разных этапах восстановительного периода. Для начала разберемся, какие этапы восстановления проходит организм женщины после родов. 

Восстановление после родов происходит примерно 9-12 месяцев. И все это время вам важно учитывать некоторые моменты. 

В течение 9 месяцев в женском организме происходят важные изменения. Происходит закладка последующей лактации, грудного вскармливания. И после родов какие-то из этих процессов должны стать еще более интенсивными (например, лактация), а какие-то процессы должны вернуться к исходному состоянию. Например, матка.

Когда вы родили малыша, родили плаценту – у вас начинаются процессы восстановления после родов. Первое, что происходит с нашей маткой – она сокращается. Гормон окситоцин начинает вырабатываться еще сильнее. И под воздействием окситоцина происходит два важных процесса:

сокращается матка
запускается лактация
Почему важно, чтобы матка хорошо сокращалась? В плацентарной площадке (т.е. в том месте, где ранее здесь крепилась плацента) очень много сосудов, оголенных сосудов. И если их не сжать путем сокращения маточной ткани, стенки матки – из этих сосудов будет интенсивно вытекать кровь. И у женщины будет возникать кровотечение, большая кровопотеря. Это действительно очень серьезные риски для организма.

Чтобы матка хорошо сокращалась, чтобы вырабатывался гормон окситоцин – нужно кормить грудью.  Если по каким-то причинам у вас не сложились вопросы с грудным вскармливанием (то есть вы перешли на искусственное либо смешанное вскармливание), того естественного окситоцина может быть недостаточно для сокращения матки. И вам тогда нужно обращать внимание на то, как много у вас выделений, какого эти выделения цвета, какого они запаха. И если вас что-то будет смущать – то советуем обязательно проконсультироваться с врачом акушером-гинекологом.

Когда же должны прекратиться выделения, когда должна зажить плацентарная площадка, за которой мы внимательно наблюдаем? Примерно через 3-4 недели, то есть порядка 30-40 дней. 

И к 40 дням после родов в идеале: 

у вас уже прекратились мажущие слизистые кровянистые выделения из половых путей
ваша матка вернулась в практически добеременный размер (это надо смотреть по УЗИ)
у вас установилась лактация
у вас сформировался с малышом некий режим, понятный вам
ваши швы хорошо заживают (если они у вас находятся в районе промежности либо если у вас была операция кесарево сечение)
слабость, которая после родов может присутствовать в организме состоявшейся мамы из-за нагрузки и большого выброса энергии во время беременности и в родах, уже уходит

${separator}

Урок 2. Физическая активность и образ жизни женщины после родов

Почему после родов существуют некоторые ограничения? Например, мамам не рекомендуется ходить в баню, бассейн, тренажерный зал. Все это связано с восстановительным периодом плацентарной площадки. 

Примерно 30-40 дней после родов место, где крепилась плацента, заживает. Именно это время у вас могут быть сразу кровянистые, потом сукровичные выделения из половых путей. Именно в это время восстанавливается процесс лактации, взаимоотношения с ребенком. Это то время, когда мы даем своему организму восстановиться. 

Когда же можно посещать зал, заниматься различными видами спорта? Когда прекращаются выделения. Также если вы не чувствуете усталости, чувствуете себя полными сил, заряжены энергией. Тогда вы можете приступать к физическим упражнениям.

Пока эти 30-40 дней не прошли, не рекомендуется посещать:

баню, сауну
бассейн
тренажерный зал с дополнительной нагрузкой. Если вы посещаете тренажерный зал, то это может быть, например, спокойная ходьба на беговой дорожке. 
Конечно, отличный вариант активности – это прогулки с коляской. Также вы можете делать упражнение «велосипед».

Отмечу, что вес не вернется к добеременному еще какое-то время после родов – это нормально. У кого-то вес возвращается через 2-3 месяца, у кого-то – через 7. Здесь все очень индивидуально. Животик тоже не уйдет сразу, потому что кожа очень постепенно растягивалась во время беременности, и после родов ей нужно время восстановиться.

Почему в первые недели после родов важно не давать сильную нагрузку? Не только потому, что заживает плацентарная площадка. Это связано еще и с тем, что мышцы тазового дна (т.е. те мышцы, за счет которых у нас происходит удержание женских половых органов, мышцы промежности) во время беременности испытывали очень большую нагрузку. И если после родов дать им еще дополнительную физическую нагрузку – можете столкнуться с неприятными последствиями (недержание мочи, опущение женских половых органов).

Начиная со 2-3-й недели после родов (по согласованию со своим врачом акушером-гинекологом), можете плавно и постепенно делать упражнения Кегеля.

Итак, наступил момент, когда вам уже можно заниматься спортом. Если вы собираетесь тренироваться дома, то вы можете пересмотреть наш курс о физических упражнениях от фитнес-тренера.

Также рекомендуется: 

плавание, бассейн, аквааэробика. Это поможет максимально равномерно дать нагрузку на весь организм. 
упражнение планка, где вы становитесь в определенное положение, где группируются все группы мышц, и нагрузка распределяется крайне равномерно.
прогулки с малышом. Вы можете просто гулять с коляской, а можете гулять на носочках, на пяточках. Гуляя по парку, здорово давать какую-то дополнительную нагрузку либо делать те самые упражнения Кегеля, пока малыш отдыхает в коляске, а вы находитесь рядом.
Чтобы ваш процесс восстановления после родов проходил максимально эффективно, важно, чтобы вы полноценно питались, чтобы у вас был достаточный питьевой режим, чтобы вы ничем не болели. 

Помните: чрезмерная физическая нагрузка – это не есть хорошо. Если мы даем большую физическую нагрузку, наши мышцы могут начать вырабатывать молочную кислоту, которая может попадать в грудное молоко и даже менять его вкус, цвет и запах. Во время тяговых упражнений либо других упражнений на верхнюю группу мышц (плечевой пояс), мышцы могут настолько прийти в тонус, что начать мешать нормальному прохождению молока по молочным протокам.

Перед тем, как выбирать какой-то комплекс упражнений, покупать сертификат и приходить к физическим нагрузкам, я рекомендую вам посоветоваться со своим врачом акушером-гинекологом и выяснить, нет ли у вас каких-то противопоказаний либо индивидуальных рекомендаций.

${tb3c6_2}

Урок 3. Физическая нагрузка после родов: советы фитнес-тренера

В прошлом уроке вместе с врачом акушером-гинекологом вы уже начали рассматривать вопрос физической активности после родов. В этом уроке остановимся подробнее на физической нагрузке после родов.

Всемирная организация здравоохранения поддерживает молодых мам, которые делают ежедневную зарядку, и утверждает, что такие женщины реже подвержены послеродовой депрессии и гораздо быстрее восстанавливаются после беременности и родов.

Беременность и рождение ребенка – это огромная радость в жизни каждой женщины. Но это и прибавка массы тела, что естественно. Избавиться от животика после родов и лишнего веса, вернуться к былым формам поможет фитнес после родов.

Насколько быстро женщина способна справиться с лишними килограммами и восстановить былые формы зависит питания, гормонального фона, общей физической подготовки до беременности и во время ее протекания и еще многих факторов. Тем не менее – все возможно!

Помните, что физическую форму можно привести в хорошее состояние только при регулярности.

Когда начать заниматься – решает сама женщина в команде с врачом, исходя из своего самочувствия и состояния здоровья. Перед началом любой физической активности, особенно если женщина хочет начать заниматься как можно раньше, лучше обратиться к гинекологу и исключить любые противопоказания к физическим нагрузкам и ограничения. Например:

Кесарево сечение. Заниматься физической активностью можно только с разрешения доктора и не ранее, чем через полтора-два месяца после родов.
Если был разрыв промежности. Нужно ждать, пока швы полностью заживут, ведь при интенсивном выполнении упражнений они могут разойтись.
Наличие любых хронических заболеваний в стадии обострения. Также стоит обязательно обратиться к врачу за консультацией при наличии у вас сахарного диабета, сердечно-сосудистых заболеваний, бронхиальной астмы, нарушении опорно-двигательного аппарата. Возможно вам показаны специальные тренировки под обязательным контролем инструктора лечебной физкультуры.
В том случае, если после родов организм сильно истощен.
К занятиям фитнесом Всемирная организация здравоохранения рекомендует приступать примерно через шесть недель после естественных родов в случае, если у женщины полностью закончились послеродовые выделения.

Женщинам после кесарева сечения лучше подождать немного дольше и начать заниматься не ранее, чем через 10 недель.

В среднем, этого времени вполне достаточно для восстановления организма. Но повторюсь – все индивидуально. Ваши занятия должны помогать вам, приносить удовольствие, не перенапрягать, а способствовать хорошему настроению и самочувствию.

Для того чтобы добиться максимальных результатов, советую вам помнить несколько правил:

Выберите для занятий определенное время, когда можно посвятить этому занятию 30–40 минут, не отвлекаясь на другие дела.
Занимайтесь в проветренном помещении или на свежем воздухе.
Не спешите, делайте все упражнения медленно и осознанно.
Не стремитесь сразу приступать к большим нагрузкам, повышать интенсивность нужно постепенно и плавно.
Дышите правильно, свободно и легко.
Если вы находитесь на грудном вскармливании, лучше кормить ребенка перед тренировкой. Концентрация молочной кислоты может сохраняться в молоке матери до 1,5 часов после занятий спортом. Изменение вкуса и состава молока может не понравиться вашему малышу.

Также желательно не выполнять упражнения с высокой интенсивностью, где ваш пульс будет учащаться выше 160 ударов в минуту. Это, например, игровые виды спорта, прыжки, бег, теннис, либо тяжелые силовые тренировки в тренажерном зале, где организм получает ударную нагрузку.  В этом случае есть риск потери молока. 

С чего начинать активность после родов?

Начинать стоит с простейших нагрузок, которые могут быть включены в вашу повседневную жизнь. Многие мамы отмечают, что ребенок требует много времени и внимания, и даже на зарядку порой не остается ни сил, ни желания. В этом случае можно с легкостью совместить упражнения с уходом за малышом.

Скажу вам честно, я начала тренироваться только через полгода после рождения малыша, несмотря на довольно внушительный стаж тренировок до беременности. Все очень просто – я отдавала ребенку так много сил и энергии, что тренироваться мне не хотелось. Я ждала момента, когда мне осознанно захочется надеть спортивную форму и заниматься.

Если у вас такое желание тоже пока не приходит – начинайте с простого!

Прогулки. Больше гуляйте! Ходьба – самый физиологичный вид физической нагрузки! Можете гулять по несколько часов ежедневно. Можете попробовать отказаться от коляски и в теплое время года. Попробуйте поместить ребенка в слинг или кенгуру, которые обязательно должны быть подобраны в соответствии с возрастом вашего малыша, и вперед в пеший поход! Ходьба сжигает большое количество калорий.
Во время ходьбы контролируйте положение тела, не наклоняйтесь вперед, держите спину прямо, живот подтянутым, грудную клетку раскрытой. Не рекомендую во время прогулок смотреть в телефон, так как очень сильно изменяется осанка, плечи поднимаются, голова наклоняется вниз, спина скругляется. Все это дает дополнительную нагрузку на позвоночник, плечевой пояс, нарушается кровообращение.

Очень хорошо походить за коляской выпадами. Это одно из лучших упражнений для укрепления ног, ягодиц, мышц спины и пресса. Во время прогулок, особенно в теплое время года, можно остановиться поприседать, поотжиматься от лавочки.
Можно сделать общую разминку. Это будет способствовать мышечному тонусу и улучшит ваше настроение. Если при этом вы возьмете ребенка на руки, это даст вам дополнительную нагрузку. Можно делать повороты, скручивания, поднимать ребенка вверх, и делать с ним наклоны. Ребенку будет весело, а вам такие игры принесут много пользы.
Конечно, все мамы переживают, как убрать животик. Но не стоит усердствовать в выполнении упражнений на пресс, так как живот будет сокращаться постепенно. Многое зависит от сокращения и уменьшения в размерах матки. И второй важный момент. Жир, накопленный в области живота, не будет уходить от упражнений, – это скорее заслуга правильного питания. Прежде чем приступить к работе над прессом, нужно удостовериться, что у вас нет диастаза, то есть расхождения прямых мышц живота. Помните, что большинство упражнений на пресс могут способствовать усугублению этого состояния.

Рекомендуется выполнять так называемые базовые упражнения типо приседаний, выпадов и наклонов, планок для укрепления мышц всего тела, не делая явный акцент на мышцах пресса.
Упражнения Кегеля после родов

Большинство гинекологов рекомендуют приступать к выполнению упражнений Кегеля буквально на второй день после родов, если процесс прошел без существенных проблем и разрывов. И если вы не испытываете дискомфорта, когда напрягаете мышцы промежности.

Полноценно ко всем упражнениям можно приступать спустя 6-8 недель после родов. После операции кесарева сечения рекомендуется воздержаться от интенсивных нагрузок до 2 месяцев после операции. Важно решать вопрос о начале тренировок с вашим лечащим врачом. И помните: если в процессе выполнения интимной гимнастики возникает чувство дискомфорта или боли, упражнения необходимо прекратить.

Зачем нужны такие упражнения? Они способствуют укреплению мышц тазового дна. Если регулярно делать такие упражнения, можно:

Вернуть тонус поврежденным мышцам в промежности;
Предотвратить опущение органов;
Избавиться от недержания мочи;
Скорее вернуть матку в дородовое состояние;
Восстановить ткани, растянутые во время родов;
И даже усилить сексуальное желание и ощущения.
Как делать такие упражнения – мы уже разобрали с вами в отдельном курсе «Беременность и спорт: комплекс физических упражнений». Такие же упражнения вы можете выполнять и в послеродовой период. Можете пересмотреть урок в нужный для вас момент и заниматься в удовольствие.

Физические нагрузки очень важны для здоровья любого человека, а особенно для здоровья, хорошего настроения, самочувствия и внешнего вида молодой мамочки! Физическая активность помогает нам справиться со стрессом, зарядиться энергией, привести свое тело в порядок и дать нам еще больше поводов для радости и гордости собой!

${tb3c6_3}

Урок 4.  Сексуальная жизнь после родов

Почему важно ограничивать на какое-то время сексуальные контакты? Причина все та же – заживление плацентарной площадки. Если мы представим себе стенку матки, она как будто поцарапана изнутри, она немного будто травмирована. И если к этой поверхности матки присоединится какая-то инфекция – риск возникновения более сложных последствий становится высоким. 

Также помните: если у вас были какие-то швы, родовые пути были травмированы, все это тоже может отразиться на качестве нашей сексуальной жизни. Поэтому 3-4 недели после родов рекомендуем выждать. И перед тем как приступать к половой жизни, важно закрыть два важных вопроса.

Проконсультироваться с врачом акушером-гинекологом. Важно знать, нет ли никаких противопоказаний, все ли хорошо со швами, с маткой.
Важно подумать о контрацепции. Вы скажете: «Какая контрацепция, ведь я кормлю грудью? После родов не прошло 6 месяцев, вероятности беременности нет». 
Нет, дорогие мамы, далеко не всегда это так. Если у вас не полноценная лактация, а смешанное вскармливание либо искусственное, у вас могут начаться критические дни через полтора-два месяца после родов. А значит, вместе с этим приходит вероятность возникновения беременности.

Также вы можете столкнуться с рядом нюансов. После родов организм специально уменьшает у нас количество эстрогенов. Эстроген – это гормон удовольствия, который отвечает за сексуальное влечение в том числе. Для чего организм это делает? Организм боится, чтобы мама не отвлекалась ни на что, чтобы не наступила новая беременность, чтобы было полноценное грудное вскармливание. Все эти риски организм минимизирует путем того, что уменьшает сексуальное влечение. 

Кроме этого, послеродовой период – это своего рода стресс. Этот адаптационный период тоже может сказываться на сексуальном влечении. 

Плюс ко всему, эстрогены могут вызывать сухость тканей влагалища. Не переживайте, это временное явление. В процессе восстановительного периода после родов все вернется. 

Также существуют различные страхи и факторы, которые влияют на сексуальную жизнь после родов. Первый страх – это страх того, что еще не все зажило, восстановилось. Этот страх решит прием у врача акушера-гинеколога. Врач вам обязательно скажет, что со швами все хорошо, полость матки хорошая, матка сокращается хорошо. Врач успокоит вас и даст уверенность, что приступать к половой жизни можно.

На фоне снижения эстрогенов у женщин может возникнуть еще одно явление. Женщина думает о том, что муж не помогает по дому, что ей никто не помогает в уходе за ребенком, что сейчас не время планировать сексуальную жизнь. И тут, дорогие мамы, вы должны понимать, что во взаимоотношениях, в том числе между мужчиной и женщиной, всегда работают два человека. Отношения – это работа. Попробуйте найти компромисс.

Поговорите с партнером, расскажите, какие чувства и эмоции испытываете. Вы можете сказать ему: «Дорогой, давай мы с тобой там что-то обсудим; мне бы хотелось, чтобы ты сказал, какая я молодец; чтобы ты мне помогал; чтобы я могла переключиться; чтобы я могла какое-то время побыть вне дома, сходить в магазин, сменить обстановку». 

Совет для пап. Залог семейного благополучия (в том числе и сексуального благополучия) – это ваша способность какое-то время находиться с ребенком самостоятельно. Можете придумать для вашей любимой занятие – попросите ее сходить в магазин за подгузниками/одеждой, пусть она сменит обстановку. Взаимопонимание и уважение – залог успеха.

Первый половой контакт после родов – это как первый половой контакт в жизни женщины в принципе. Важно, чтобы вы договорились с супругом о том, что он должен проявить определенную нежность. Постарайтесь подготовиться, продумать контрацепцию, приобрести специальные средства для увлажнения женских половых органов.

У вас все обязательно получится именно так, чтобы ваша сексуальная жизнь после родов никак не пострадала. Ведь это тоже залог счастливой и гармоничной семейной жизни!

${separator}

Урок 5. Питание после родов

Первый и самый важный момент – никаких специальных особенных диет после родов соблюдать не нужно. Беременность – абсолютно нормальное состояние в жизни женщины, и восстановление после родов – абсолютно нормальное состояние в жизни женщины. 

Вы можете кушать все то, что кушали и во внебеременном состоянии, ограничивая себя лишь в тех вещах, в которых рекомендуется ограничивать себя любому человеку, который следит за своим здоровьем. Здесь я имею в виду алкоголь, вредные пищевые добавки, курение, большое количество сладкого.

Различные мнения, что нельзя кушать капусту, огурцы, красные яблоки (потому что это вызовет покраснение щек у ребенка), помидоры, свеклу – это всего лишь предрассудки. Это не попадает к нашему малышу в виде капусты или помидора. Все это перерабатывается нашим организмом до молекул и, уже разделившись на белки, жиры, углеводы, витамины и микроэлементы, распределяется, кому нужнее. Что-то идет в грудное молоко и далее к малышу, что-то распределяется в другие органы и системы.

Если вы хорошо питаетесь, если у вас не было каких-то особенных моментов по беременности и родам (если вы не рожали двойню, у вас не было сложной кровопотери, у вас нет тяжелой анемии после родов, вы не проживаете в особо загрязненных местах), то вам, возможно, не понадобятся даже и поливитаминные комплексы. Но если вы замечаете, что у вас сухость кожи, вялость, усталость и вам хочется подобрать какой-то витаминно-минеральный комплекс – отправляйтесь к врачу.

Обсудим уровень жидкости. Грудное молоко более чем на 80% состоит из воды. И если у вас будет дефицит воды, то грудному молоку тоже будет очень сложно сформироваться. Это не значит, что вы должны пить воду в огромных количествах. Помните, что молоко формируется из плазмы крови. Питьевой режим должен быть индивидуальным и адаптироваться к вам. Если вам достаточно для выработки нормального количества молока полутора литров, не надо заставлять себя пить специально больше.

Хочу поговорить о специальных чаях и других средствах, которые улучшают лактацию. Здесь все, конечно, очень индивидуально, и некоторые из них действительно могут хорошо отражаться на общих процессах восстановления после родов и запуска и становления лактации. Но из опыта вам скажу, большинство таких чаев работают как-то позитивно только за счет того, что вы вместе с ними употребляете теплую воду. А теплая водичка, принятая накануне прикладывания малыша к груди, как нельзя лучше сказывается на кормлении, лактации, установлении контакта «мама-малыш».

${tb3c6_4}

Урок 6. Особые послеродовые состояния: о чем важно знать молодой маме?

Чтобы понять, как у вас идет процесс восстановления после родов, получить дополнительные рекомендации, вам необходимо посетить врача акушера-гинеколога в женской консультации. Это стоит сделать в течение 3-4 недель после родов.

Если у врача возникнут какие-то вопросы, он назначит вам дополнительные обследования либо консультации специалистов. Например, если у вас есть проблемы с каким-то образованием в районе шва, в районе промежности, с геморроем, который не проходит после родов – то вас могут направить к проктологу. Или у вас после родов появляются боли в спине, которые мешают вашей нормальной жизнедеятельности – тогда вам могут порекомендовать посетить врача-невролога. Либо у вас какие-то вопросы с артериальным давлением – тогда мы привлекаем к нашей совместной работе врача-терапевта. 

Если у вас есть какие-то хронические заболевания, то важно расписать свой график посещения узких специалистов. Беременность показывает слабые места в организме женщины. А после всей беременности и родов, под действием нагрузки оно может проявить себя как-то атипично. 

Когда вы выписываетесь из родильного дома, мы вам выдаем документ под названием «сведения родильного дома о родильнице». Там всегда в рекомендациях написано, когда вам нужно сходить к врачу, когда сдать контрольный анализ крови, если он вам показан. Эти рекомендации, дорогие мамы, важно соблюдать.

Теперь обсудим особенные состояния, которые могут возникать и пугать молодых мам.

Выпадение волос
Почему важно отдельно про него поговорить? Потому что это настолько пугающая иногда ситуация, которая может отражаться вообще на всем: на уровне стресса, на лактации, на сексуальной жизни и, вообще, на жизнедеятельности мамы после родов. Разберемся, почему так происходит. 

Во время беременности под воздействием определенных гормонов наши волосы практически не выпадают. В норме, когда вы даже полностью здоровы, каждый день при расчесывании (просто даже без расчесывания) у вас выпадает какое-то количество волос. И это норма. Во время беременности этот процесс замедляется процентов на 70. А после родов случается гормональный каскад, потом все выравнивается. И ваш организм возвращается к добеременной потере волос – то есть к тому количеству, которое вы должны терять в норме. Но теперь у вас теряются волосы и те, которые должны, и те, которые вы не потеряли во время беременности. То есть вы как будто «отдаете долг». 

Дорогие мамы, это нормально. Практически у всех мам на 2-3-м месяце после родов есть процесс выпадения волос. И на протяжении 2-3 месяцев все должно нормализоваться.

Если этого не произошло, посетите врача. Важно посмотреть уровень микроэлементов, витаминов в нашем организме, обнаружить, где нарушен баланс. И далее врач подберет вам индивидуальную программу лечения.

Можете ли вы как-то повлиять на процесс выпадения волос после родов? Вы можете делать массаж кожи головы, использовать специальные массажеры, можете делать скрабирование кожи головы. Но процесса потери волос избежать после родов не может практически ни одна женщина. Поэтому пугаться этого не стоит.

Психологическое состояние 
Здесь стоит задуматься, где есть норма, а где есть не норма.  Большинство новоиспеченных мам нередко испытывают ощущение легкой грусти, ответственности, необходимости перераспределения приоритетов в жизни. И это нормально.

И есть совершенно другое, опасное состояние – когда у женщины появляются суицидальные мысли, когда ей не хочется никого видеть, когда она думает о том, что зря планировала беременность, когда не нравится свое тело после родов. В таком случае обязательно обратитесь к специалисту – психологу, психотерапевту.

Состояние кожи, волос, ногтей
Кожа – это уникальный орган. Он будто индикатор. Когда болеет какой-то орган внутри либо есть проблемы с каким-то органом внутри – на коже могут появиться некие изменения.

Поэтому если после родов вы заметили, что с кожей что-то происходит, рекомендую вам обратиться к врачу – дерматологу или терапевту. Также помните, что вы кормите малыша грудью, и на формирование молока идет очень много витаминов, микроэлементов. Возможно, вы не совсем хорошо кушаете, возможно, у вас есть дефицит витаминов. И от этого начинается сухость кожи, крошатся ногти, появляется усталость, нарушается сон. Часто после родов женщинам не хватает омега ненасыщенных жирных кислот, кальция, витамина D. Также после родов могут появиться проблемы с кожей, которые связаны с гормональной системой. Могут появиться пигментные пятна, сухость кожи. В таком случае обратитесь к врачу. Вы сделаете скрининг, сдадите анализы и вместе с врачом подберете витаминно-минеральный комплекс или отдельно витамины.

${separator}

Урок 7. Контрацепция и планирование следующей беременности 

Согласно данным Всемирной организации здравоохранения, возникновение беременности в течение двух лет после предыдущей увеличивает риск преждевременных родов, преэклампсии, синдрома задержки роста плода и, в принципе, даже материнской и младенческой смертности. Поэтому очень важно подходить с ответственностью к вопросу контрацепции после родов и к вопросу планирования последующей беременности.

Идеально, когда беременность запланированная. Именно поэтому нужно восстановиться после родов, потом какой-то период дать организму передышку и далее вступить в этап планирования следующей беременности. 

Контрацепция

Есть определенный миф, что пока у женщины нет критических дней и она кормит грудью, паре не нужна контрацепция. Так ли это? И да, и нет.

Действительно, гормон пролактин, который отвечает за выработку грудного молока, блокирует овуляцию. Что такое овуляция? Овуляция – это ситуация, когда из яичника выпрыгивает яйцеклетка и ждет встречи со сперматозоидом.

Но этот гормон должен вырабатываться в достаточном количестве. Если его вырабатывается с каждым днем все меньше и меньше, то возможность наступления беременности выше. Почему гормон пролактин может снижаться? Потому что запрос со стороны ребенка и со стороны женского организма на кормление стал меньше. Например, вы по какой-то причине либо совсем решили отказаться от кормления грудью, либо у вас возникли какие-то вопросы с лактацией, и вы перешли на искусственное либо смешанное вскармливание. Урежается кормление малыша грудью – значит, начинает уменьшаться выработка гормона пролактина. Делаем главный вывод. Лактационная аменорея (то есть контрацепция с помощью кормления грудью и гормона пролактина) – не очень эффективный метод. 

По данным Всемирной организации здравоохранения, лактационная аменорея как метод может использоваться до 6 месяцев после родов. А когда прошло 6 месяцев после родов, нужно добавлять какой-то другой метод контрацепции. 

Может ли лактационная аменорея быть надежным методом контрацепции до 6 месяцев? Да, если соблюдены определенные условия. Самое важное –  частота кормлений. 

Если у вас полностью естественное вскармливание и вы кормите малыша как минимум 2 раза ночью и каждые 4-5 часов днем – все это может поддерживать определенный уровень гормона пролактина. Если же у вас нет ночных кормлений либо у вас оно одно, и днем у вас промежуток между кормлениями 5-6 часов, у вас не будет полноценной лактационной аменореи. То есть гормон пролактин не будет полностью выключать нашу овуляцию. 

Обратите внимание, что выделения после родов уже закончились, а критические дни не начинаются. Тем не менее возможность забеременеть возникает раньше, чем у вас появляется первая менструация.

Рассмотрим распространенные методы контрацепции и обсудим их эффективность. 

Прерванный половой акт
Его эффективность далеко не равна 100%. Также существует ряд исследований, которые говорят о том, что метод контрацепции в виде прерванного полового акта плохо сказывается на психическом здоровье мужчины. Поэтому предлагаю его, как неэффективный и плохо сказывающийся на здоровье партнера, отодвинуть немного в сторону.

Барьерная контрацепция
Это всем известный презерватив. Также сюда мы включаем так называемые женские презервативы в виде колпачков, которые действуют примерно точно так же, как и мужской презерватив, но надеваются на шейку матки и не дают сперматозоиду проникнуть к месту назначения.

Разберемся, в чем между ними разница и какие есть плюсы и минусы. Используя презерватив, мы закрываем два важных вопроса. Во-первых, это контрацепция, во-вторых, это профилактика заболеваний, передаваемых половым путем. И, соответственно, если говорить о всех барьерных методах контрацепции (будь то презерватив либо женские колпачки, либо сюда же, кстати, мы относим и спермицидные средства), врачебная общественность голосует за презерватив.

Идеальный метод контрацепции конкретно для вашей пары вы сможете подобрать на приеме у врача акушера-гинеколога. Потому что существуют важные индивидуальные моменты. Например, уровень эстрогенов у женщины понижается настолько, что она испытывает сухость влагалища. И в таком случае прекрасно подойдут спермицидные средства. Они действуют так: женщина вводит во влагалище специальную свечку либо крем за 10-15 минут до полового контакта, и это средство делает сперматозоид, который попадает туда же, неспособным к оплодотворению. Но при этом оно само по себе имеет такую кремовидную маслянистую основу и увлажняет половые пути. И у нас закрывается два вопроса: мы убираем сухость и тем самым используем барьерный метод контрацепции.

Поэтому главный вывод такой: если вы хотите использовать какой-либо метод контрацепции, то вместе с врачом акушером-гинекологом выберите, что нужно конкретно вам: например, увлажнение слизистой влагалища или профилактика заболеваний, передаваемых половым путем.

Гормональная контрацепция
Бывают разные виды гормональной контрацепции. Бывают комбинированные оральные контрацептивы (так называемые КОКи), в составе которых несколько гормонов. А есть препараты, в составе которых только один гормональный элемент. Какой подходит конкретно вам – вы сможете определить на приеме со своим врачом акушером-гинекологом. 

Также гормональные препараты могут быть в виде спирали (мы называем это внутриматочная спираль, ВМС), которая ставится в полость матки, фиксируется там специальным способом и выделяет определенное количество гормонов. 

Также гормоны могут быть введены подкожно. Существует множество способов, как сделать так, чтобы не наступала беременность. И эти гормоны могут действовать даже по-разному. Комбинированные – они больше влияют на «дверь», которая не выпускает яйцеклетку. Они эту дверь закрывают – и у женщины нет овуляции. А те, которые содержат один гормон, больше влияют на шейку матки и на плотность слизи, которая находится в цервикальном канале. И за счет повышения этой плотности сперматозоид просто не может через нее проникнуть. 

Всемирная организация здравоохранения и ряд научных исследований отмечают, что использование гормональной контрацепции на этапе восстановления после родов и грудного вскармливания может уменьшать лактацию (т.е. выработку молока). У кого-то это может быть в незначительном количестве, а у кого-то в очень даже значительном. 

Поэтому, дорогие мамы, очень важно не заниматься самоназначением контрацептивных средств в домашних условиях. Только на приеме с врачом, и только комплексно сканируя индивидуальную ситуацию, мы можем подобрать для вас гормональный контрацептив, который подходит конкретно вам. 

Надеюсь, что наши советы были вам действительно очень полезны. Желаем вам легкого восстановления!


    `,
};
