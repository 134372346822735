export const recursivelyReplaceStringNullToNull = (item) => {
  if (typeof item !== 'object' || item === null) return item;

  const newObject = {};

  for (const key in item) {
    if (typeof item[key] === 'object') newObject[key] = recursivelyReplaceStringNullToNull(item[key]);
    else if (item[key] === 'Null') newObject[key] = null;
    else newObject[key] = item[key];
  }

  return newObject;
};
