import { useCallback, useRef } from 'react';
import { dbRealtime } from 'api/constants';
import { useAppDispatch } from 'store/store';
import { recursivelyReplaceStringNullToNull } from 'utils/recursivelyReplaceStringNullToNull';
import { resetUser, updateUser } from 'features/User/store/UserSlice';
import { DataSnapshot, DatabaseReference, onValue, ref, off } from 'firebase/database';


export const useUserDbDataListener = () => {
  const dbRef = useRef<DatabaseReference>();
  const dispatch = useAppDispatch();

  const processUserData = useCallback(
    (snapshot: DataSnapshot) => {
      const value = snapshot.val();
      !!value && dispatch(updateUser(recursivelyReplaceStringNullToNull(value)));
    },
    [dispatch]
  );

  const startListening = useCallback(
    (uid: string) => {
      if (!uid) return;
      if (dbRef.current) return;

      dbRef.current = ref(dbRealtime, `/users/${uid}`);
      onValue(dbRef.current, processUserData);
    },
    [processUserData]
  );

  const stopListening = useCallback(
    () => {
      if (!dbRef.current) return;

      off(dbRef.current);

      dispatch(resetUser());
      dbRef.current = undefined;
    },
    [dispatch]
  );

  return {
    startListening,
    stopListening,
  };
};
