import FavoriteButtons from 'components/FavoriteButtons/FavoriteButtons';
import styles from './CategoryTitle.module.css';
import { CourseData } from 'types';
import { useTranslate } from 'hooks/useTranslate';

type CategoryVideoProps = {
  title: string;
  item: CourseData;
  order: string;
};
function CategoryTitle({ title, item, order }: CategoryVideoProps) {
  const t = useTranslate();
  return (
    <div className={styles.titleContainer}>
      <h2 className={styles.title}>
        {`${t(Number(order) > 0 ? 'lesson' : 'introduction')}${
          Number(order) > 0 ? ` ${order}:` : ':'
        } ${title}`}
      </h2>
    </div>
  );
}

export default CategoryTitle;
