import React from 'react';

import styles from './CourseEmptyWrapper.module.css';
import Header from 'components/Header/Header';
import { CoursesHeader } from 'components/CoursesHeader/CoursesHeader';
import Footer from 'components/Footer/Footer';
import { CourseEmpty } from './CourseEmpty';

type CourseEmptyWrapperProps = {
  isTablet: boolean;
  isMobile: boolean;
  title: string;
  withoutButton?: boolean;
};

const CourseEmptyWrapper: React.FC<CourseEmptyWrapperProps> = ({
  title,
  withoutButton,
}) => {
  return (
    <div className={styles.background}>
      <Header styled />
      <div className={styles.container}>
        <CoursesHeader title={title} withoutButton={withoutButton} />
        <div className={styles.wrapper}>
          <CourseEmpty />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CourseEmptyWrapper;
