import { MainLampIcon } from 'assets/icons';
import PrimaryButton from 'components/Buttons/PrimaryButton/PrimaryButton';
import { TitleH1 } from 'components/Text/TitleH1/TitleH1';
import { IconButton } from 'features/Profile/components/IconButton/IconButton';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { useTranslate } from 'hooks/useTranslate';
import { Shtick } from 'pages/MainPage/store/HomeSlice';
import { Link } from 'react-router-dom';
import { optional } from 'utils/other';
import styles from './TodaysShtick.module.css';

type TodaysShtickProps = {
  shtick: Shtick | undefined;
};

const TodaysShtick: React.FC<TodaysShtickProps> = ({ shtick }) => {
  const t = useTranslate();
  const { text, url, img } = optional<Shtick>(shtick);
  const { isMobile } = useAppWindowSize();
  const svgIconUrl = img?.endsWith('.svg') ? img : undefined;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <TitleH1 text={t('main-shtick')} highlight={t('shtick')} />
        <MainLampIcon
          width={isMobile ? 50 : 100}
          height={isMobile ? 50 : 100}
        />
      </div>
      <div className={styles.descriptionContainer}>
        {!!text && <p className={styles.description}>{text}</p>}

        {!!img && !svgIconUrl && (
          <div className={styles.imageContainer}>
            <img className={styles.image} src={img} />
          </div>
        )}
        {svgIconUrl && <IconButton icon={svgIconUrl} />}
        {!!url && (
          <Link to={url}>
            <PrimaryButton
              title={t('detailed')}
              onClick={() => undefined}
              styleContainer={{
                boxShadow: 'none',
                marginTop: !shtick?.img ? 0 : isMobile ? 20 : 30,
              }}
            />
          </Link>
        )}
      </div>
    </div>
  );
};

export default TodaysShtick;
