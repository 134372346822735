import LinkStatusPopup from 'components/LinkStatusPopup/LinkStatusPopup';
import { CSSProperties, FC, useState } from 'react';
import styles from './ChipWithIcon.module.css';
import { useAppWindowSize } from 'hooks/useAppWindowSize';

interface ChipWithIconProps {
  icon: any;
  text: string;
  styleContainer?: CSSProperties;
  textStyle?: CSSProperties;
  withoutStyled?: boolean;
  share?: boolean;
  handleButtonClick?: () => void;
  handleClick?: () => void;
}

const ChipWithIcon: FC<ChipWithIconProps> = ({
  icon: Icon,
  text,
  styleContainer,
  withoutStyled,
  handleButtonClick,
  textStyle,
  handleClick,
  share,
}: ChipWithIconProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile, isTablet } = useAppWindowSize();

  const handleChipClick = () => {
    if (share) {
      setIsLoading(true);
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 2000);
          setIsLoading(false);
          setError(false);
        })
        .catch((error) => {
          setIsCopied(false);
          setIsLoading(false);
          setError(true);
        });
    }
  };

  return (
    <>
      <div
        className={withoutStyled ? styles.withoutText : styles.chips}
        style={styleContainer}
        onClick={!!handleButtonClick ? handleButtonClick : handleChipClick}
      >
        <div className={styles.chipsWrapper}>
          {Icon && <Icon />}
          {text && (
            <p
              className={
                isMobile || isTablet
                  ? styles.chipsTitle
                  : styles.chipsTitleUpper
              }
              style={textStyle}
            >
              {text}
            </p>
          )}
        </div>
      </div>
      {isCopied && (
        <LinkStatusPopup
          isCopied={isCopied}
          error={error}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default ChipWithIcon;
