import { separator, tb7c1_1, tb7c1_2, tb7c1_3, tb7c1_4 } from 'assets/images';

export const b7_c21_text = {
  b7_c1_title: `Курс «Путешествие в материнство: эмоции и отношения во время беременности» `,
  b7_c1_description: `Спикер: Елена Ласая, врач-психотерапевт высшей квалификационной категории, кандидат медицинских наук, директор Минского гештальт института`,
  b7_c21_text: `
    ${tb7c1_1}
    
    Урок 1. Становление материнства: радость и сомнения

Поговорим о том, как возникает материнство, как оно зарождается и как появляются первые материнские чувства. 

Беременные женщины часто воспринимают свое состояние как болезнь, что, конечно, абсолютно не соответствует действительности. Потому что беременность – это физиологическое состояние. И отличным вариантом будет найти какие-то опоры, чтобы пройти этот период полными сил, энергии. Конечно, учитывая какие-то сложности и тревоги, но в целом пройти этот период позитивно.

С чего хочется начать? Это задать вопрос: зачем нам нужен период беременности? На первый взгляд, ответ очевиден – это время для того, чтобы ребенок развился и подготовился к рождению, родился. Но есть менее очевидный ответ, на который стоит обратить отдельное внимание, – это время для рождения матери. Это время для того, чтобы сформировались материнские чувства, чтобы женщина могла приспособиться к новой для себя роли и почувствовать новые возможности и новые отношения.

Период беременности считается кризисным периодом в психологии, так как это время, связанное с переменами. Меняется все: способ жизни, тело, отношения в семье. Этот период всегда сопряжен с перестройкой не только гормональной, но и эмоциональный. Как правило, это время используется для подготовки к рождению ребенка. Но важно, чтобы эта подготовка имела не только технический характер (т.е. как можно научиться правильно дышать или как подготовиться к уходу за новорожденным), но и чтобы это время было использовано как опора к изменению в отношениях, как опора к изменению эмоциональности перед родами и выявлению готовности к новым отношениям.

Дело в том, что не каждая женщина одинаково готова к материнству и готова принять свою новую роль. Беременность – это только этап в развитии материнских чувств. Изначально готовность к материнству закладывается и формируется значительно раньше и уходит в историю и отношений со своей матерью. Мы знаем о материнстве из нашего прошлого опыта, когда мы сами были ребенком или даже когда мы сами находились в утробе своей матери.

Затем влияют на формирование материнских чувств различные жизненные этапы: как маленькая девочка начинает играть в «дочки-матери», как потом она обходится со своими братьями и сестрами (особенно младшими), которые появляются в семье, был ли у нее опыт нянчить своих младших братьев или сестер.

Затем по мере развития, в период полового созревания способность или склонность к материнству тоже претерпевает определенные изменения. И только потом, когда наступает беременность, материнские чувства выходят на первый план.

Поэтому все зависит от истории, от жизненных событий, которые сопровождали женщину в процессе ее развития. И иногда материнство и материнские чувства начинают формироваться гораздо позже – иногда даже после рождения ребенка. Этого тоже не стоит пугаться. Просто стоит быть внимательным к этому процессу и понимать, что влияет на ваше состояние и отношение к ребенку.

В нашей культуре беременность воспринимается как однозначно позитивное явление и предполагается, что все обязательно должны радоваться. И мало остается места для сомнений, переживаний, а иногда даже нежелания, которое тоже вполне нормально, когда женщина узнает о своем состоянии.

Важно помнить, что не стоит бояться негативных переживаний. Если вдруг вам кажется, что вы сталкиваетесь с сомнениями и определенного рода нежеланием, – это тоже нормально, потому что беременность меняет жизнь женщины и семьи в целом и является чем-то новым. А новизна может вызывать тревогу и беспокойство.

Что бы с вами ни происходило – лучше говорить о своих переживаниях, делиться ими, не прятать их. То, что остается невысказанным, непроработанным, может уходить в тело и влиять на физическое состояние и нежелательные явления во время беременности. Но самое главное  – это помнить, что все, что с вами происходит, не является болезнью, а несет определенную цель и смысл. Скоро вы станете мамой, и это безусловно радость, которую принесете вы в свою семью и в свой новый жизненный опыт.

${separator}

Урок 2. Эмоциональное состояние женщины во время беременности

Давайте сейчас поговорим об эмоциональном состоянии во время беременности, о различных чувствах, которые возникают в этот период. И сделаем особенный акцент на том, что тревога во время беременности – это абсолютно нормальное состояние женщины.

Во время беременности возникает так называемая доминанта в нервной системе. Это некоторый очаг возбуждения, который будет отвечать за протекание беременности. Поэтому все остальные раздражители скорее уходят на задний план. И то, что остается в центре внимания, – это сама беременность. Поэтому, естественно, эмоциональное состояние будет меняться, равно как и физиологическое состояние. Важно понимать, что эти изменения могут быть как универсальными (т.е. характерными в той или иной степени для всех женщин), так и уникальными, когда каждая из женщин, в соответствии со своей предрасположенностью и историей, может переживать различные эмоциональные состояния. 

Особенности эмоционального состояния беременной

Лабильность нервной системы
настроение меняется от слез к радости
повышается утомляемость
повышается чувствительность
женщина более ярко реагирует на какие-то события и ситуации
Переживание тревоги
Тревога – это некоторый защитный механизм нашей психики, который реагирует на что-то неожиданное, непредсказуемое и неподконтрольное. Беременность как раз и относится к таким состояниям, когда мы не можем полностью контролировать происходящее. Конечно, частично женщина это делает, посещая врача, меняя ритм жизни, занимаясь своим питанием или отдыхом. Тем не менее процессы, происходящие внутри организма, целиком контролировать мы не можем. Поэтому тревога неизбежно возникает во время беременности. Конечно, степень ее может отличаться, но легкий уровень всегда будет иметь место. 

Не стоит пугаться своей тревожности и одновременно не стоит переживать по поводу некоторых собственных волнений. Уже стала стереотипом такая расхожая фраза, что беременным нельзя волноваться. И в этом смысле иногда она может скорее использоваться во вред. Наша жизнь полна различных событий, вряд ли можно представить себе жизнь в отсутствии всяческих волнений. И получается, что порой возникает замкнутый круг или снежный ком: вам нельзя волноваться – «ах, я поволновалась, и что же теперь будет» – и вы волнуетесь еще больше.

В связи с этим я обычно рассказываю беременным женщинам историю, основанную на исследованиях, которые проводились на беременных самках шимпанзе. Были взяты 2 группы беременных самок шимпанзе, и одну из групп стрессировали током, а другая группа развивалась обычным путем. Оказалось, что потомство у стрессированных самок было более жизнестойкое, чем в группе обычного развития. Поэтому был сделан вывод, что оптимальный уровень стресса скорее может позитивно влиять на потомство, подготавливая потомство к воздействиям окружающей среды. Конечно, этот уровень стресса должен быть не запредельным, а оптимальным – таким, каким являются наши бытовые стрессы.

Самое главное – это устойчивое позитивное отношение к плоду. Вы можете и волноваться, и испытывать различные переживания. В этот момент выбрасываются гормоны (адреналин и норадреналин, так называемые гормоны стресса) – но если потом вы опять возвращаетесь к устойчивому положительному отношению, к нежности и любви к своему ребенку, то все становится на свои места.

${tb7c1_2}

Урок 3. Что влияет на отношение женщины к беременности?

Сейчас мы с вами поговорим о том влиянии, которое оказывают история развития женщины и ситуация на отношение к беременности и, соответственно, на варианты протекания беременности.

На отношение и протекание беременности будут влиять отношения с партнером (или отцом ребенка по-другому). Исследовано,  что отношения с отцом ребенка, если они осуществляются в максимально миролюбивом партнерском отношении, это, естественно, будет влиять на хорошее отношение матери к будущему ребенку и на физиологическое протекание беременности. Конфликты, стрессы, неудовлетворенность семейными отношениями тоже, естественно, будут отражаться и на вынашивании ребенка.

Здесь дело в том, что женщина всегда воспринимает ребенка как часть себя, но и как часть партнера, поэтому иногда получается, что негативное отношение к партнеру или некоторая неудовлетворенность могут переноситься на ребенка, что, соответственно, будет влиять и на процесс вынашивания. Если вы обнаруживаете недовольство семейными отношениями или обнаруживаете какие-то конфликты с партнером, то лучше стараться проговаривать это недовольство между собой, стараясь решить сложные для себя вопросы. Не замалчивайте, не утаивайте, не уходите в обиды. Лучше сделать явными, а не прятать внутри себя свои переживания. Опять же если это становится сложным делать самостоятельно, то всегда можно прибегнуть к помощи семейного психолога.

Это то, что касалось более очевидных вещей, но есть менее очевидные – так называемые бессознательные процессы, которые будут влиять на протекание беременности и отношение мамы к своему будущему ребенку. Эти бессознательные процессы касаются раннего детского опыта самой мамы. Я уже говорила, что становление материнства начинается далеко задолго до самого факта беременности и уходит корнями в отношения с собственной матерью. Поэтому отношение к своему ребенку беременной женщины напрямую будет связано с теми бессознательными конфликтами или процессами, относящимися к ее собственной маме.

Что это значит? Если беременная женщина несет в себе много обиды или агрессии в адрес своей мамы, это может также влиять на непринятие материнской роли или неготовность самой становиться матерью. Иногда это может происходить вопреки, можно услышать такое выражение: «но я точно не буду как моя мама». Но, как правило, такие утверждения приводят к тому, что рано или поздно женщина именно так и начинает себя вести, как своя мама. Это более сложные вещи, скрыты в психической нашей деятельности, поэтому если вам кажется, что ваши отношения со своей мамой в большей степени позитивные или миролюбивые, – это значит, что принятие материнства для вас более благоприятно. Если вы все равно начинаете ощущать некоторые конфликты, недовольство, то, может быть, более подробно разобраться с этими конфликтами вам поможет специалист, потому что эта сфера лежит скорее в зоне бессознательного.

${separator}

Урок 4. Психосоматика беременности

Поговорим о соотношении физического и эмоционального, физического и психологического, о так называемой психосоматике.

То, что приходит на ум, отражается в теле и наоборот. Наши переживания не происходят изолированно. Если мы волнуемся – наше тело напрягается. Если мы радуемся, отдыхаем – наше тело расслабляется. То же самое происходит и с рядом других эмоций и переживаний. И беременность не является исключением.

Существуют некоторые так называемые патологические состояния при беременности, которые скорее проявляются телесно, но во многом могут являться отражениями эмоционального состояния, отражениями психологических конфликтов. К таким состоянием относят: 

угрозу невынашивания беременности
токсикозы первой и второй половины беременности
Предполагается, что эти состояния связаны с противоречивыми переживаниями по отношению к беременности. И как мы уже отмечали, противоречивые переживания – это вполне естественный нормальный процесс. Потому что изменения, перед которыми оказывается женщина, всегда будут вызывать противоречивые чувства, даже если это запланированная и желанная беременность. 

Вторая сторона, которая противоположна радости и принятию, переживаемая женщиной, может лежать в зоне сомнения, недовольства, в какой-то степени нежелания. И иногда тело реагирует на эту вторую сторону, скорее предъявляя свои симптомы в виде мышечного, маточного напряжения (маточного тонуса), которое проявляется в угрозе невынашивания или в виде токсикоза (тошноты, рвоты).

Если мы попытаемся проинтерпретировать эти состояния на психологическом уровне, мы можем сказать, что это телесный процесс реализации психологического нежелания иметь ребенка, скорее в фантазии о том, что можно избавиться от ребенка. В этом нет ничего страшного, и иногда женщина на сознательном уровне будет как раз против такой формулировки, говоря о том, что, конечно же, она очень хочет и ждет ребенка. Здесь нет единственно правильного ответа: и то, и другое будет верно. Беременность – это состояние, которое предполагает противоречивые чувства. Вполне возможно, что как раз эта вторая, скрытая, так называемая «темная» часть будет прятаться из-за страха поговорить об этом.

Поэтому хорошо бы в эти моменты дать возможность себе еще раз обратиться к своим мотивам, к своим сомнениям и, возможно, к своему нежеланию иметь ребенка. Ведь, на самом деле, действительно, беременность полностью поменяла вашу жизнь. Посмотрите, что происходит в вашей семье или что происходит с вашей карьерой – может быть, вы рассчитывали на одно, а приходится останавливаться и менять свои планы. Все это очень важные составляющие, которые могут проявляться вот таким телесным эквивалентом.

Если удается эту сторону сделать более явной, «подсветить» – вполне возможно, что симптомы будут также уменьшаться. Но иногда бывает так, что в полной мере все равно это остается в тайне, потому что точно неизвестны причины формирования этих состояний. И понятно, что психологические мотивы являются не единственными. Конечно же, есть еще ряд гормональных, наследственных изменений. 

Хорошая новость заключается в том, что рано или поздно – как правило, к третьему триместру – физическое состояние нормализуется. Но можно сказать одновременно, что к этому времени уже и происходит адаптация женщины к новой своей роли. И вот эта новизна уже не становится столь тревожной или опасной для нее. То есть в результате мы приходим к благоприятному адаптивному процессу.


${tb7c1_3}

Урок 5. Психологические задачи по триместрам

Психологические задачи также можно разделить в соответствии с физиологическим процессом на триместры беременности. Давайте поговорим, что необходимо в эмоциональной и в психологической сфере сделать в каждом из них.

1 триместр

1 триместр, прежде всего, характеризуется неожиданностью, самим фактом наступления беременности и необходимостью принять решение о сохранении беременности. И каждый раз это своего рода стресс для женщины, даже если беременность желанная, долгожданная, и вы испытываете радость по этому поводу. Тем не менее вам нужно принять ответственность за свое решение и сделать выбор.

Чем характеризуется этот период? Интересно, что женщина узнает о беременности, прежде всего, в результате телесных изменений. Прекращается менструация, а также и остальные физиологические изменения будут налицо: повышается чувствительность, изменяются вкусы и запахи, женщина начинает больше уставать и т.д. То есть все эти телесные изменения могут напоминать некоторые необычные состояния – не то, что привычно связывать со здоровьем. И поэтому все эти симптомы иногда могут трактоваться как болезнь. 

Иногда получается, что ощущение себя «больной» может сохраняться в течение всей беременности. И это не просто случайная симптоматика, и это не заболевание. То, что вас ждет – это рождение ребенка, новые отношения и новая жизнь. И ваше телесное состояние приобретет определенное значение, определенный смысл.

Еще раз напомню, что задачей всего периода беременности является формирование материнства, возможности стать матерью. В этом основная цель и задача. Поэтому иногда мы говорим, что в процессе беременности и уже на первом триместре происходит превращение так называемой «больной» в мать.

2 триместр

2 триместр начинается с момента шевелений. И если в 1 триместре женщина понимает о своей беременности только исключительно по своему физическому состоянию, а сама идея или фантазия о существовании ребенка внутри является абстракцией, то во 2 триместре ребенок уже принимает конкретные формы. Женщина начинает ощущать шевеления, и можно почувствовать внутри организма новую жизнь. 

И это первый период, первый момент, когда можно уже ребенка фантазировать как отдельное существо, хотя и находящееся еще внутри организма. Поэтому задача этого периода заключается в том, чтобы наладить первичную связь, первичную коммуникацию с еще не родившимся ребенком. И фантазировать о ребенке как уже об отдельном существе, с которым скоро предстоит встретиться.

3 триместр

Задачи этого триместра будут более практического характера. Дело в том, что 3 триместр – уже время к приближающимся родам и непосредственно встрече с ребенком. Поэтому в 3 триместре может повышаться тревога, связанная с родами или со встречей с ребенком. 

Чтобы понизить или справиться с тревогой в этот момент, хорошо переориентироваться на выполнение конкретных задач. Что, собственно, чаще всего и происходит: семья начинает покупать вещи, готовиться к приезду в роддом, выбирать роддом, врача. Может быть, больше внимание сосредоточить на навыках расслабления, дыхания, то есть на непосредственной подготовке к родам.

${separator}

Урок 6. Начало отношений с ребенком

Как начинаются отношения с ребенком и как перестраиваются отношения в семейной системе?

Не секрет, что ранние отношения в нашей жизни будут влиять на всю нашу последующую жизнь. Как мы формируемся, как формируется наша психика, наши стереотипы эмоциональных реакций, как мы выбираем себе друзей, партнеров – все это уходит своими корнями в ранние детские отношения.

Давайте расширим это представление и поговорим о том, как эти ранние детские отношения начинаются не только с момента рождения, но и уже во внутриутробном периоде. Поэтому то, как мама будет строить отношения со своим малышом, будет очевидно влиять на всю последующую жизнь. Этот момент начала отношений можно считать с момента появления шевелений, когда мама уже может реально ощущать наличие ребенка внутри и строить с ним так называемые ранние диалоги. Поэтому советуем маме начать коммуникативный процесс.

Иногда молодые мамы стесняются или смущаются разговаривать с ребенком, когда он находится еще внутри. Говорят: «Вот когда он родится, тогда и посмотрим». На самом деле, у ребенка уже внутриутробно формируется психика. А это происходит, когда внутриутробно формируется нервная трубка. Поэтому здесь можно говорить о том, что отношения уже могут формироваться в этом совсем раннем периоде. И внутриутробный ребенок реагирует на эмоции матери – строит гримасы или сосет палец. Его движения тоже будут являться реакцией на эмоциональное состояние через выброс гормонов в материнском организме. Поэтому общаться и говорить с ребенком важно, нужно. 

можно рассказывать ему все, что вы захотите
читать книжки
ставить музыкальные произведения
Были проведены исследования, когда дети после рождения уже хорошо реагируют на музыку, которую ставили им до рождения, или на прочтение детских книжек, которые им читали в внутриутробном периоде.

Есть очень хорошая рекомендация,  которую мы часто советуем семьям – она поможет укрепить отношения внутри семьи, а также позволит включить ребенка в семейную систему. Когда мы предлагаем папе поговорить с внутриутробным ребенком, причем не просто поговорить, а, положив руки на живот мамы, ощущая шевеления, рассказать еще не родившемуся ребенку о том, какая у него будет мама. Ведь малыш еще не видел свою маму. Поэтому обычно такой стиль помогает сформировать теплые, близкие отношения папы и мамы. Папа начинает говорить, какие мамы глаза, какие волосы – и все это приносит очень приятную и теплую атмосферу в контакт.

${tb7c1_4}

Урок 7. Роль партнера и семьи в период беременности

Поговорим о роли всей семейной системы в целом и о роли отца ребенка в этот сложный период беременности и подготовки к рождению.

Долгое время в нашей культуре роль отца скорее была отстраненной, дистантной. Казалось, что воспитание ребенка – это исключительно дело женщины. Иногда в силу того, что мужская часть населения оказывалась на войне. И даже в более благоприятных условиях скорее мужчина уходил или отстранялся от воспитания детей. 

Но, к счастью, в последнее время ситуация меняется. И все больше молодых пап с вниманием, любовью, интересом включены как в процесс вынашивания, подготовки к родам, к сопровождению во время родов, так и включены в воспитание ребенка. И, безусловно, это очень нужный и важный процесс, опять же который влияет на отношения с ребенком и влияет на всю последующую жизнь человека.

В чем основная роль отца ребенка? 

На разных периодах это могут быть разные аспекты. В период беременности:

снижать тревогу матери, скорее успокаивать ее
давать ей возможность почувствовать опору и защиту со стороны мужа
Именно тогда, когда женщина может успокоиться, когда перепады настроения при помощи мужа (отца ребенка) могут регулироваться, и состояние может быть более равномерным, адаптивным – безусловно, это все позитивно будет отражаться на ребенке.

После рождения:

отец продолжает выполнять ту же функцию, скорее успокаивая мать и выполняя функцию защиты
также он одновременно эмоционально включается в заботу о ребенке
И уже несколько позже, когда ребенок подрастает (в возрасте 2-3 лет), роль отца тоже сложно переоценить. Именно благодаря отцу ребенок потихоньку начинает отделяться от матери и выходить в социум, во внешний мир. И если ребенок будет чувствовать поддержку и опору со стороны отца – это тоже будет влиять на его дальнейшую жизнь. 

В связи с этим все больше и больше отцов включаются в этот процесс сопровождения, защиты и эмоциональной включенности, что не может не радовать. И всегда участие отца в родах, как правило, всегда позитивно сказывается на эмоциональном состоянии как матери, так и малыша.

Интересно, что отец также эмоционально реагирует на процесс беременности. В частности, проводились исследования, которые показывали снижение уровня тестерона у молодых отцов и повышение уровня окситоцина, что позволяло отцу становиться более чувствительным, более эмоционально включенным в отношения с ребенком. Более того, в этот период, как правило, у отцов снижается такая импульсивность, реактивность (но это наблюдалось на спортсменах), но одновременно увеличивалась выносливость. Это говорит о возможности более стойко выносить жизненные трудности в процессе рождения и воспитания малыша в течение первого года.

Роль семьи

Роль матери беременной женщины будет очень важна, так как эти отношения будут влиять и на новые отношения, так как мама идентифицируется со своей собственной матерью (сознательно или бессознательно). Поэтому условно роль бабушки (как и дедушки), собственно, также будет очевидна в процессе вынашивания и потом рождения ребенка.

Что здесь важно помнить? Разные семейные системы работают по-разному, но важно простраивать границы семьи, чтобы молодые родители могли сами решать и делать выбор как в плане рождения ребенка, так и его воспитания. 

Важно, чтобы какие-то стереотипы старших не оказывали слишком сильного или подавляющего воздействия на молодую семью. Одновременно важно не оказываться в противоположной позиции и не отстранять более старших родителей от малыша. Постарайтесь приглашать их к участию, иногда просить о помощи (особенно после рождения малыша), потому что в любом случае бабушки и дедушки только могут расширить возможности восприятия окружающего мира и дать еще больше любви и тепла новорожденному.

И в заключении скажу: важно помнить, что беременность – это время, которое дается женщине, ее партнеру (отцу ребенка) и всей семье в целом подготовиться к этому новому, радостному событию. И в течение этого времени могут возникать различные переживания, не всегда приятные, иногда тревожные. Не стоит этого бояться, потому что в этом и заключается наша жизнь. Это совокупность и радости, и сомнений, и тревог. 

Все, что происходит с вами в этот период жизни, – нормально. В целом все равно все заканчивается радостно и позитивно. И ни одно событие в жизни не является настолько ярким, настолько меняющим жизнь, как рождение ребенка! Поэтому хочется пожелать всем изучающим эмоциональное состояние во время беременности, всем готовящимся к беременности мамам, папам и членам их семей, удачи, силы и радостных переживаний на этом пути.

    `,
};
