import styles from './2-PeriodScreen.module.css';
import { FC, SetStateAction, useCallback, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useFormik } from 'formik';
import { useTranslate } from 'hooks/useTranslate';
import { useAppDispatch } from 'store/store';
import { updateDateOfChildBirth } from './store/OnboardingSlice';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'navigation/constants';
import { TitleH3 } from 'components/Text/TitleH3/TitleH3';
import classNames from 'classnames';
import { NumberInput } from './components/NumberInput/NumberInput';
import { FormFields } from './validations/constants';
import { getDateOfChildBirthValidationSchema } from './validations/validationSchema'
import NextButton from './components/NextButton/NextButton';
import ErrorMessage from 'components/Messages/ErrorMessage/ErrorMessage';



dayjs.extend(customParseFormat);

const { DAYS_1, DAYS_10, MONTH_1, MONTH_10, YEAR_1, YEAR_10, YEAR_100, YEAR_1000 } = FormFields;

const ALLOWED_SYMBOLS = Array.from({ length: 10 }, (_, i) => `${i}`);

export const DateOfChildBirthScreen: FC = () => {
  const t = useTranslate();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [selected, setSelected] = useState<FormFields | null>(DAYS_10);

  const formik = useFormik({
    initialValues: {
      [DAYS_10]: '',
      [DAYS_1]: '',
      [MONTH_10]: '',
      [MONTH_1]: '',
      [YEAR_1000]: '2',
      [YEAR_100]: '0',
      [YEAR_10]: '',
      [YEAR_1]: '',
    },
    validationSchema: getDateOfChildBirthValidationSchema(),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const date = `${values[YEAR_1000]}${values[YEAR_100]}${values[YEAR_10]}${values[YEAR_1]}-${values[MONTH_10]}${values[MONTH_1]}-${values[DAYS_10]}${values[DAYS_1]}`;
      const dateMls = Date.parse(date);
      const isValid = dayjs(date, 'YYYY-MM-DD', true).isValid();

      // NOTE: check the date is correct
      if (!isNaN(dateMls) && isValid && (Date.now() - dateMls) > 0) {
        dispatch(updateDateOfChildBirth(dateMls));
        navigate(AppRoutes.INTERESTS_SCREEN);
      } else {
        formik.setErrors({
          [YEAR_1]: 'error_DateOfChildBirth',
        });
      }
    },
  });

  const handleChangeFocus = (isFocused: boolean) => {
    if (isFocused) {
      formik.setErrors({});
      setIsTouched(true);
    }
  };

  const handleNext = useCallback(
    () => {
      formik.handleSubmit();
    },
    [formik]
  );

  const handleChange = useCallback(
    (value: { nativeEvent: { data: any; }; }, currentField: FormFields, nextField: SetStateAction<FormFields | null>) => {
      const keyValue = value.nativeEvent.data;
      if (!keyValue) {
        formik.setFieldValue(currentField, '');
      }

      if (ALLOWED_SYMBOLS.includes(keyValue)) {
        formik.setFieldValue(currentField, keyValue);
        setSelected(nextField);
      }
    },
    [formik]
  );

  return (
    <>
      <div className='auth-section-title'>
        <TitleH3
          text={ t('onboarding_PeriodScreen_section_title_childbirth') }
        /> 
      </div>
      <div className={ classNames(
        styles.bloksContainer,
        !formik.isValid && styles.bloksErrorContainer
      ) }>
        <div className={ styles.block }>
          <div className={ styles.blockRow } >
            <NumberInput 
              id={ DAYS_10 }
              selected={ selected === DAYS_10 }
              onChange={ (value) => {
                handleChange(value, DAYS_10, DAYS_1);
              } }
              onChangeFocus={ handleChangeFocus }
              error={ !formik.isValid }
              value={ formik.values[DAYS_10] }
            />
            <NumberInput
              id={ DAYS_1 }
              selected={ selected === DAYS_1 }
              onChange={ (value) => {
                handleChange(value, DAYS_1, MONTH_10);
              } }
              onChangeFocus={ handleChangeFocus }
              error={ !formik.isValid }
              value={ formik.values[DAYS_1] }
            />
          </div>
          <div className={ styles.blockLabel }>
            { t('common-day') }
          </div>
        </div>
        <div className={ styles.block }>
          <div className={ styles.blockRow } >
            <NumberInput 
              id={ MONTH_10 }
              selected={ selected === MONTH_10 }
              onChange={ (value) => {
                handleChange(value, MONTH_10, MONTH_1);
              } }
              onChangeFocus={ handleChangeFocus }
              error={ !formik.isValid }
              value={ formik.values[MONTH_10] }
            />
            <NumberInput
              id={ MONTH_1 }
              selected={ selected === MONTH_1 }
              onChange={ (value) => {
                handleChange(value, MONTH_1, YEAR_10);
              } }
              onChangeFocus={ handleChangeFocus }
              error={ !formik.isValid }
              value={ formik.values[MONTH_1] }
            />
          </div>
          <div className={ styles.blockLabel }>
            { t('common-month') }
          </div>
        </div>
        <div className={ styles.block }>
          <div className={ styles.blockRow } >
            <NumberInput 
              id={ YEAR_1000 }
              selected={ selected === YEAR_1000 }
              onChange={ (value) => {
                handleChange(value, YEAR_1000, YEAR_100);
              } }
              onChangeFocus={ handleChangeFocus }
              error={ !formik.isValid }
              value={ formik.values[YEAR_1000] }
            />
            <NumberInput
              id={ YEAR_100 }
              selected={ selected === YEAR_100 }
              onChange={ (value) => {
                handleChange(value, YEAR_100, YEAR_10);
              } }
              onChangeFocus={ handleChangeFocus }
              error={ !formik.isValid }
              value={ formik.values[YEAR_100] }
            />
            <NumberInput
              id={ YEAR_10 }
              selected={ selected === YEAR_10 }
              onChange={ (value) => {
                handleChange(value, YEAR_10, YEAR_1);
              } }
              onChangeFocus={ handleChangeFocus }
              error={ !formik.isValid }
              value={ formik.values[YEAR_10] }
            />
            <NumberInput
              id={ YEAR_1 }
              selected={ selected === YEAR_1 }
              onChange={ (value) => {
                handleChange(value, YEAR_1, null);
              } }
              onChangeFocus={ handleChangeFocus }
              error={ !formik.isValid }
              value={ formik.values[YEAR_1] }
            />
          </div>
          <div className={ styles.blockLabel }>
            { t('common-year') }
          </div>
        </div>
      </div>
      <div className={ styles.error }>
        {
          !formik.isValid && (
            <ErrorMessage
              message={
                formik.errors[DAYS_1] === 'error_fill_fields' ||
                formik.errors[DAYS_10] === 'error_fill_fields' ||
                formik.errors[MONTH_1] === 'error_fill_fields' ||
                formik.errors[MONTH_10] === 'error_fill_fields' ||
                formik.errors[YEAR_1] === 'error_fill_fields' ||
                formik.errors[YEAR_10] === 'error_fill_fields' ||
                formik.errors[YEAR_100] === 'error_fill_fields' ||
                formik.errors[YEAR_1000] === 'error_fill_fields'
                  ? t('error_fill_fields')
                  : t('error_DateOfChildBirth')
              }
            />
          )
        }
      </div>
      <div className='auth-next-container'>
        <NextButton
          title={ t('common-next') }
          onClick={ handleNext }
          disabled={ !isTouched || !formik.isValid }
        />
      </div>
    </>
  );
};
