import { FC } from 'react';
import styles from './CheckProgress.module.css';
import checkIcon from 'assets/icons/check.svg';
import classNames from 'classnames';



export const CheckProgress: FC<{
  title: string,
  check: boolean,
  checking: boolean,
}> = ({
  title,
  check,
  checking,
}) => (
  <div className={ styles.container }>
    <img
      src={ checkIcon }
      className={ classNames(
        styles.icon,
        check && styles.show,
      ) }
      alt='check icon'
    />
    <div className={ classNames(
      styles.text,
      (checking || check) && styles.show,
    ) }>
      {
        title
      }
      {
        checking && '.....'
      }
    </div>
  </div>   
);

