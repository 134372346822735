import { b6c20q1 } from 'assets/images';

export const b6 = {
  c20: {
    title: 'b6_c20_title',
    author: 'b6_c20_author',
    description: 'b6_c20_description',
    last: true,
    endAllTest: true,
    lessonsLength: 7,
    video: 'https://vimeo.com/showcase/9263587/embed',
    tests: [
      {
        title: 'b6_c20_title',
        question: 'b6_c20_test1_question',
        answers: {
          a1: 'b6_c20_test1_answer_a1',
          a2: 'b6_c20_test1_answer_a2',
          a3: 'b6_c20_test1_answer_a3',
        },
        correctAnswerId: [1, 2, 3],
        correctAll: true,
        wrongAnswer: '',
        correctAnswer: '',
        image: b6c20q1,
      },
    ],
  },
};
