import { FC } from "react";
import styles from './CardList.module.css';
import { Card, TCard } from "./Card/Card";
import { CertificateIcon, ExpertIcon, FatherMatherIcon } from "assets/icons";


const cards: TCard[] = [
  {
    name: 'FatherMather',
    icon: FatherMatherIcon,
    title: 'home_mzhr_card_1_title',
    text: 'home_mzhr_card_1_text',
  },
  {
    name: 'Certificate',
    icon: CertificateIcon,
    title: 'home_mzhr_card_2_title',
    text: 'home_mzhr_card_2_text',
  },
  {
    name: 'Expert',
    icon: ExpertIcon,
    title: 'home_mzhr_card_3_title',
    text: 'home_mzhr_card_3_text',
  }
]


export const CardList: FC = () => (
  <div className={ styles.container }>
    {
      cards.map(card => <Card key={ card.name } data={ card }/>)
    }
  </div>
);
