import noop from 'lodash/noop';
import { recursivelyReplaceStringNullToNull } from 'utils/recursivelyReplaceStringNullToNull';
import { recursivelyReplaceNullToStringNull } from 'utils/recursivelyReplaceNullToStringNull';

import { auth, dbRealtime } from './constants';
import { User } from 'features/User/type';
import { child, get, ref, remove, set, update } from 'firebase/database';

const getUserRef = (uid: string) => ref(dbRealtime, `/users/${uid}`);

export const getDBUser = async (uid: string): Promise<User | undefined> => {
  try {
    const snapshot = await get(child(ref(dbRealtime), `/users/${uid}`));
    const item = snapshot.val();
    return recursivelyReplaceStringNullToNull(item);
  } catch {
    return;
  }
};

export const setDBUser = async (uid: string, data: Partial<User>) => {
  set(getUserRef(uid), recursivelyReplaceNullToStringNull(data)).catch(noop);
};

export const updateDbUser = async (data: Partial<User>) => {
  const currentUser = auth.currentUser;
  if (!currentUser) return;

  update(
    getUserRef(currentUser.uid),
    recursivelyReplaceNullToStringNull({ ...data })
  ).catch(noop);
};

export const updateDbUserUid = async (data: Partial<User>, uid: string) => {
  update(
    getUserRef(uid),
    recursivelyReplaceNullToStringNull({ ...data })
  ).catch(noop);
};

export const removeDbUser = async (uid: string) => {
  await remove(ref(dbRealtime, `/users/${uid}`)).catch(noop);
};
