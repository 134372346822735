export const notFoundSearchConfig = [
  { keyword: 'беременнность' },
  {
    keyword: 'роды',
  },
  {
    keyword: 'токсикоз',
  },
  {
    keyword: 'женское здоровье',
  },
  {
    keyword: 'ПДР',
  },
  {
    keyword: 'гинеколог',
  },
  {
    keyword: 'грудное вскармливание',
  },
  {
    keyword: 'симптомы',
  },
];
