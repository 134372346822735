import { useTranslate } from 'hooks/useTranslate';
import styles from './EmptyFavorite.module.css';
import {
  BookMarkFavorite,
  BookMarkFavoriteWhite,
  EmptyStudy,
  WinkSmile,
  WinkingBooked,
} from 'assets/icons';
import NextButton from 'features/Auth/Onboarding/components/NextButton/NextButton';
import { IconLabel } from 'components/IconLabel/IconLabel';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'navigation/constants';
import { useUser } from 'hooks/useUser';
import { RecommendationType } from 'types';

type EmptyFavoriteProps = {
  type: any;
};
export function EmptyFavorite({ type }: EmptyFavoriteProps) {
  const t = useTranslate();
  const navigate = useNavigate();
  const handleButton = () => {
    navigate(AppRoutes.STUDY_SCREEN);
  };

  return (
    <div className={styles.emptyWrapper}>
      <div className={styles.container}>
        <div className={styles.icon}>
          <WinkingBooked />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.infoContainer}>
            <p className={styles.title}>
              {type
                ? t(`empty-screen-favorite${type ? '-' + type : ''}`)
                : t('favorite_empty_main_title')}
            </p>

            <p className={styles.subtitle}>{t('favorite_empty_description')}</p>
            <IconLabel
              icon={BookMarkFavorite}
              text={t('in_favorite_button')}
              textStyle={{ paddingLeft: 10 }}
            />
            <p className={styles.subtitle}>{t('favorite_empty_title')}</p>
            <IconLabel icon={EmptyStudy} text={t('nav_link_study')} />
            <p className={styles.extraInfo}>{t('favorite_empty_extra')}</p>
          </div>

          <NextButton title={t('empty_go_to_section')} onClick={handleButton} />
        </div>
      </div>
    </div>
  );
}
