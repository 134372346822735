import {
  BookMarkSmall,
  BookMarkSmallSelected,
  GrayHeartIcon,
  HeartIconSelected,
} from 'assets/icons';
import styles from './CourseBlockFavouriteButtons.module.css';

type CourseInfoBlockProps = {
  handleBookClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  handleHeartClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  isBookClicked: boolean | undefined;
  isHeartClicked: boolean | undefined;
};

export const CourseBlockFavouriteButtons = ({
  handleBookClick,
  handleHeartClick,
  isBookClicked,
  isHeartClicked,
}: CourseInfoBlockProps) => {
  return (
    <div className={styles.containerSvg}>
      <button
        onClick={(e) => handleBookClick(e)}
        className={styles.blockFavoriteButton}
      >
        {isBookClicked ? (
          <BookMarkSmallSelected width={36} height={36} />
        ) : (
          <BookMarkSmall color={'#F3F3F5'} width={36} height={36} />
        )}
      </button>
      <button
        onClick={(e) => handleHeartClick(e)}
        className={styles.blockFavoriteButton}
      >
        {isHeartClicked ? (
          <HeartIconSelected width={36} height={36} />
        ) : (
          <GrayHeartIcon width={36} height={36} />
        )}
      </button>
    </div>
  );
};
