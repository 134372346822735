import { FC } from 'react';
import styles from './CommandSection.module.css';
import { useTranslate } from 'hooks/useTranslate';
import { Title } from 'components/Text/Title/Title';
import {
  CBDO,
  CMO,
  CTO,
  EventManager,
  SMM,
  SMM_2,
  developer_1,
  editor,
  founder,
  legal,
} from 'assets/command';
import { Carousel } from 'components/Carousel/Carousel';
import { TCarouselItem } from 'components/Carousel/constants';

const members: TCarouselItem[] = [
  {
    displayName: 'Диана Мардас',
    position: 'Founder / Основатель, CEO',
    image: founder,
  },
  {
    displayName: 'Алина Бондарович',
    position: 'CMO / Медицинский директор',
    image: CMO,
  },
  {
    displayName: 'Алексей Гаринович',
    position: 'CBDO / Менеджер по развитию коммерческой деятельности',
    image: CBDO,
  },
  {
    displayName: 'Дарья Водчиц',
    position: 'Event-manager / Организатор',
    image: EventManager,
  },
  {
    displayName: 'Дмитрий Мардас',
    position: 'CTO / Технический директор',
    image: CTO,
  },
  {
    displayName: 'Екатерина Шубёнкина',
    position: 'Legal / Юрист',
    image: legal,
  },
  {
    displayName: 'Марина Лукашкина',
    position: 'Developer / Разработчик',
    image: developer_1,
  },
  {
    displayName: 'Алеся Добыш',
    position: 'Editor / Редактор, журналист',
    image: editor,
  },
  {
    displayName: 'Елена Путрич',
    position: 'SMM / Соцсети',
    image: SMM_2,
  },
  {
    displayName: 'Наталья Гончарик',
    position: 'Moderator / Модератор',
    image: SMM,
  },
];

export const CommandSection: FC = () => {
  const t = useTranslate();

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <Title text={t('Наша команда')} highlight={t('команда')} center />
        <Carousel data={members} />
      </div>
    </section>
  );
};
