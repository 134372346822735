import { useState } from 'react';
import styles from './CategoryAdviceVideo.module.css';
import { PlayIconVideo } from 'assets/icons';
import VideoComponent from 'components/VideoComponent/VideoComponent';
import { useUser } from 'hooks/useUser';
import { LockedContent } from 'components/LockedContent/LockedContent';
import { useAppWindowSize } from 'hooks/useAppWindowSize';

interface FavoriteContainerProps {
  link: string;
  id: string;
  linkImage: string;
}

const CategoryAdviceVideo: React.FC<FavoriteContainerProps> = ({
  link,
  id,
  linkImage,
}) => {
  const { isTablet, isMobile } = useAppWindowSize();
  const size = isMobile ? 80 : 56;
  const [started, setStarted] = useState<boolean>(false);
  const [pressed, setPressed] = useState<boolean>(false);
  const handleStart = () => {
    setStarted(!!user?.id);
    setPressed(true);
  };
  const locked = pressed && !started;
  const { user } = useUser();
  const maxHeight = 675;
  const maxWidth = 293;

  return (
    <div className={styles.videoWrapper} style={{ maxWidth }}>
      {locked && (
        <LockedContent open={locked} onClose={() => setPressed(false)} />
      )}
      <VideoComponent link={link} maxHeight={maxHeight} maxWidth={maxWidth} />
      {!started && !user.id && (
        <>
          <div
            className={styles.videoImageContainer}
            style={{
              maxWidth,
              height: maxHeight,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <div>
              <img
                className={styles.videoImage}
                src={linkImage}
                style={{ maxWidth, maxHeight }}
              />
            </div>
          </div>
          <button className={styles.startButton} onClick={handleStart}>
            <PlayIconVideo width={size} height={size} />
          </button>
        </>
      )}
    </div>
  );
};

export default CategoryAdviceVideo;
