import { auth } from 'api/constants';
import { removeDbUser } from 'api/userApi';
import { hideLoader } from 'features/Settings/store/OverlaySlice';
import {
  GoogleAuthProvider,
  OAuthProvider,
  createUserWithEmailAndPassword,
  reauthenticateWithPopup,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { get, getDatabase, ref } from 'firebase/database';
import { useTranslate } from 'hooks/useTranslate';
import { isFunction, noop } from 'lodash';
import { AppRoutes } from 'navigation/constants';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { StorageKeys } from 'storage/constants';
import { removeFromStorage } from 'storage/storage';
import { useAppDispatch } from 'store/store';
import { resetOnboarding } from '../Onboarding/store/OnboardingSlice';
import { AuthErrorCode, ProviderRemoval } from '../constants/constants';
import {
  updateAuthFirstName,
  updateAuthLastName,
  updateUid,
  updateiIsShowSuccessLabel,
} from '../store/AuthSlice';

export const useAuth = () => {
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const googleLogin = useCallback(() => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then(({ user }) => {
        // send name to store to push to database
        const displayNameArr = user.displayName?.split(' ');

        const firstName = displayNameArr ? displayNameArr[0] : '';
        const lastName = displayNameArr ? displayNameArr[1] : '';
        dispatch(updateAuthFirstName(firstName));
        dispatch(updateAuthLastName(lastName));

        checkUserExists(user.uid);
      })
      .catch(noop);
  }, [dispatch]);

  const appleLogin = useCallback(() => {
    const provider = new OAuthProvider('apple.com');

    provider.setCustomParameters({
      // Localize the Apple authentication screen.
      locale: 'ru',
    });

    signInWithPopup(auth, provider)
      .then(({ user }) => {
        // send name to store to push to database

        const displayNameArr = user.displayName?.split(' ');
        const firstName = displayNameArr ? displayNameArr[0] : '';
        const lastName = displayNameArr ? displayNameArr[1] : '';
        dispatch(updateAuthFirstName(firstName));
        dispatch(updateAuthLastName(lastName));

        checkUserExists(user.uid);
      })
      .catch(noop);
  }, [dispatch]);

  const checkUserExists = (uid: string) => {
    const db = getDatabase();

    const userRef = ref(db, `users/${uid}`);

    get(userRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          navigate(AppRoutes.MAIN);
        } else {
          navigate(AppRoutes.SIGN_UP_SUCCESS);
        }
      })
      .catch((error) => {
        console.error('Ошибка проверки существования пользователя:', error);
      });
  };

  const emailRegistration = useCallback(
    (
      name: string,
      email: string,
      password: string,
      cb?: (error: AuthErrorCode) => void
    ) => {
      // dispatch(showLoader());

      // send name to store to push to database
      dispatch(updateAuthFirstName(name));

      createUserWithEmailAndPassword(auth, email, password)
        .then(() => navigate(AppRoutes.SIGN_UP_SUCCESS))
        .catch((error) => {
          dispatch(updateAuthFirstName(null));
          isFunction(cb) && cb(error.code);
        });
      // .finally(() => dispatch(hideLoader()));
    },
    [dispatch, navigate]
  );

  const emailLogin = useCallback(
    (email: string, password: string, cb?: (error: AuthErrorCode) => void) => {
      // dispatch(showLoader());

      signInWithEmailAndPassword(auth, email, password)
        .then(() => navigate(AppRoutes.HOME))
        .catch((error) => isFunction(cb) && cb(error.code));
      // .finally(() => dispatch(hideLoader()));
    },
    [navigate]
  );

  const emailResetPassword = useCallback(
    (email: string, cb?: (error: AuthErrorCode) => void) => {
      // dispatch(showLoader());

      auth.languageCode = 'ru';

      sendPasswordResetEmail(auth, email).catch(
        (error) => isFunction(cb) && cb(error.code)
      );
      // .finally(() => dispatch(hideLoader()));
    },
    [navigate]
  );

  const logout = useCallback(async () => {
    // dispatch(showLoader());
    dispatch(resetOnboarding());
    dispatch(updateUid(''));

    signOut(auth)
      .then(async () => {
        dispatch(updateAuthFirstName(null));
        dispatch(updateAuthLastName(null));
        navigate(AppRoutes.HOME);
      })
      .catch(noop);
    //   .finally(() => dispatch(hideLoader()));
  }, [dispatch, navigate]);

  type deleteAccountProps = {
    provider: string;
    email?: string;
    password?: string;
    setError?: (error: AuthErrorCode) => void;
    hideModal?: () => void;
  };
  async function deleteAccount({
    provider,
    email,
    password,
    setError,
    hideModal,
  }: deleteAccountProps) {
    const user = auth.currentUser;

    const handleDelete = async () => {
      await user?.delete();

      const uid = user?.uid;
      await Promise.all([
        removeDbUser(uid || '').catch(() => alert(t('error_db_remove'))),
        removeFromStorage(StorageKeys.MAMAPRO_LAST_LAUNCH_DATE).catch(noop),
        removeDbUser(uid || '').catch(() => alert(t('error_db_remove'))),
      ]);
      dispatch(updateiIsShowSuccessLabel(true));
    };

    if (!user) {
      dispatch(hideLoader());
      return;
    }

    try {
      if (provider === ProviderRemoval.GOOGLE) {
        const googleProvider = new GoogleAuthProvider();
        await reauthenticateWithPopup(user, googleProvider);
      } else if (provider === ProviderRemoval.APPLE) {
        const appleProvider = new OAuthProvider('apple.com');
        await reauthenticateWithPopup(user, appleProvider);
      } else if (
        provider === ProviderRemoval.EMAIL_PASSWORD &&
        email &&
        password
      ) {
        await signInWithEmailAndPassword(auth, email, password);
      }

      if (isFunction(hideModal)) {
        hideModal();
      }

      await handleDelete();
    } catch (error: any) {
      if (isFunction(setError)) {
        setError(error.code);
      }
      console.error('Ошибка удаления пользователя:', error);
    } finally {
      dispatch(hideLoader());
    }
  }

  return {
    googleLogin,
    appleLogin,
    emailRegistration,
    emailLogin,
    emailResetPassword,
    logout,
    deleteAccount,
  };
};
