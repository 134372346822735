import { CSSProperties, FC, useEffect, useState } from 'react';
import styles from './Avatar.module.css';
import classNames from 'classnames';
import { UserIcon } from 'assets/icons';

export const Avatar: FC<{
  url: string | null | undefined;
  big?: boolean;
  containerStyle?: CSSProperties;
}> = ({ url, big = false, containerStyle }) => {
  const [isLoading, setIsLoading] = useState(true);

  const fetchImage = (src: string) => {
    const loadingImage = new Image();
    loadingImage.src = src;
    loadingImage.onload = () => {
      setIsLoading(false);
    };
  };

  useEffect(() => {
    url && fetchImage(url);
  }, [url]);

  return (
    <div
      style={containerStyle}
      className={classNames(styles.container, big && styles.bigContainer)}
    >
      {/* // TODO implement spinner */}
      {url && !isLoading && <img src={url!} className={styles.image} alt="" />}
      {(!url || isLoading) && (
        <img src={UserIcon} className={styles.imageUser} alt="" />
      )}
    </div>
  );
};
