import {
  separator,
  tb3c2_1,
  tb3c2_2,
  tb3c2_3,
  tb3c2_4,
  tb3c2_5,
} from 'assets/images';

export const b3_c9_text = {
  b3_c2_title: `Курс «Тактика действий в каждом периоде родов»`,
  b3_c2_description: `Спикеры: 

    Василий Шостак, врач акушер-гинеколог, кандидат медицинских наук
    
    Элеонора Федотова, врач акушер-гинеколог, заместитель главного врача по родовспоможению 1-ой городской клинической больницы г.Минска
    
    Алексей Дубовик, врач анестезиолог-реаниматолог
    
    Татьяна Шуманина, старшая акушерка родового отделения 5-ой городской клинической больницы г.Минска`,
  b3_c9_text: `
    ${tb3c2_1}

    Урок 1. Будущая мама приехала в роддом: поэтапная инструкция

В этом курсе мы подробно расскажем вам о вариантах начала родовой деятельности, периодах родов, тактики действий роженицы, тактики действий акушерки и врача в разные периоды родов.

Периоды родов

Выделяют 3 периода родов. 

Первый период раскрытия шейки матки. Он начинается с момента установления регулярных схваток до полного раскрытия шейки матки. Выделяют латентную и активную фазу в первом периоде родов.
В латентную фазу схватки постепенно приобретают регулярный характер, их частота – две за 10 минут. Интервалы между схватками могут быть различными по продолжительности. Происходит укорочение, размягчение и сглаживание шейки матки.

Продолжительность активной фазы индивидуальна у каждой роженицы. У первородящих активная фаза длится от 7 до 20 часов. У повторнородящих – от 4 до 14 часов. В активной фазе первого периода родов схватки становятся чаще, они более болезненные, частота схваток 3-5 за 10 минут. Интервалы между схватками становятся короче, усиливается интенсивность болевых ощущений во время схватки. 

В активной фазе скорость раскрытия шейки матки увеличивается. Заканчивается первый период родов полным раскрытием шейки матки. Продолжительность активной фазы родов тоже значительно варьирует: у первородящих – 7-19 часов, у повторнородящих – 4-13 часов. В этом периоде родов чаще используют различные виды обезболивания.

Кстати, в этом курсе врач-анестезиолог подготовил для вас отдельный урок про анестезию в родах. Почему именно на этом этапе? Важно, чтобы у будущей мамы остались силы на второй период родов. 

Второй период родов – это период изгнания плода, или потужной период. Это момент рождения вашего малыша. Рождение ребенка происходит в результате суммарного действия схваток и потуг.
Третий период родов называется последовым. Он начинается с момента рождения ребенка до отделения последа. Термин «послед» обозначает все то, что рождается после плода: плацента, оболочки, пуповина.
Мама приехала в роддом: что ждет ее? 

Для начала маме необходимо обратиться в приемное отделение родильного дома. Вас попросят передать документы, необходимые для госпитализации:

паспорт
обменная карта
направление на госпитализацию в случае плановой госпитализации
Если вы поступаете на партнерские роды, не забывайте следующие документы: 

сертификат о подготовке к партнерским родам
справку терапевта для партнера об отсутствии инфекционных заболеваний из поликлиники по месту жительства или других медицинских учреждений (срок действия такой справки 10-12 дней)
результат флюорографии органов грудной клетки партнера не позднее 6 месяцев
В том случае, если нет срочности, вам предлагают заполнить анкету в приемном отделении, где указываются паспортные данные, место проживания, информация контактных телефонов и другое.

Женщина, находящаяся в родах или доставленная машиной скорой медицинской помощи, принимается в первую очередь. В порядке очередности вас пригласят пройти в отделение. Не нужно волноваться, если в приемном отделении находится много женщин в ожидании госпитализации. Прием идет быстро, и, как правило, ожидание не затягивается свыше одного часа. Если у вас частые и болезненные схватки, если у вас излились околоплодные воды, не следует дожидаться своей очереди, смело обращайтесь к врачу или акушерке в кабинет приема и сообщайте о своих волнениях. Профессионалы всегда сориентируется в ситуации и окажут своевременную помощь.

Итак, пришла ваша очередь. Что вас ждет? Для начала врач в приемном отделении поговорит с вами. Он расспросит у вас о течении беременности, осложнениях беременности, перенесенных заболеваниях, операциях, уточнит, есть ли аллергические реакции на препараты, какие госпитализации были во время беременности. Он обязательно изучит обменную карту, в которой может быть важная информация. Все данные вносятся в медицинскую карту или историю родов. 

Далее акушерка предлагает измерить температуру, артериальное давление, проведет осмотр на педикулез. Далее она измеряет высоту стояния дна матки, окружность живота, определяет размеры таза. Также осуществляется запись сердцебиения плода – кардиотокограмма. Обычно продолжительность записи не превышает 20 минут. Если возникают какие-либо тревожные или патологические типы КТГ, в экстренном порядке женщине выполняется ультразвуковое исследование плода с допплерометрией, чтобы убедиться, что с малышом все в порядке. 

В редких случаях запись сердцебиения может продолжаться до 1 часа. Этого времени достаточно, чтобы провести диагностику патологического состояния плода. Врач акушер-гинеколог проведет наружный осмотр, осмотр молочных желез, влагалищное исследование. Его цель понять, в родах вы или нет, на каком вы этапе, какое раскрытие шейки матки. После того как врач провел обследование, он решает, необходима ли госпитализация в стационар.

Если угрозы для мамы и малыша нет, роды еще не начались, то и пребывание в стационаре необязательно. В таких случаях маму могут отпустить домой. В документах, которые предоставят женщине, будут указаны результаты всех обследований, проведенных в приемном отделении, и дальнейшие рекомендации. Будущей маме важно, находясь дома, внимательно относиться к своему состоянию, следить за характером шевеления плода и в ближайшие дни посетить врача женской консультации.

Если женщину госпитализируют на роды, то ей предлагают переодеться в сорочку, халат, надеть моющиеся тапочки. Свое белье (бюстгальтер, трусики) вам также нужно снять. Если какие-либо вещи вы забыли дома, не стоит волноваться – вам предложат больничную сорочку и халат. Домашнюю одежду можно отдать мужу или сдать в гардероб. Если пациентка находится в родах, ей предлагают выполнить очистительную клизму.

Очистительная клизма

Зачем нужна очистительная клизма и чем она может помочь в родах? Мы, конечно, можем прислушаться к желанию женщины и не выполнять очистительную клизму, но в этих случаях вместе с рождением головки плода часто происходит опорожнение прямой кишки и выход каловых масс. Кроме того, некоторые мамы, опасаясь выхода каловых масс при потугах, не могут расслабиться и сдерживают потуги – это удлиняет период изгнания плода и вызывает состояние психологического дискомфорта, поэтому я всегда рекомендую женщинам делать очистительную клизму. После процедуры будущая мама принимает душ, переодевается в сорочку, и санитарка сопровождает женщину в предродовую палату или индивидуальный родильный зал.

Партнерские роды

Если у вас запланированы партнерские роды, то партнер ожидает окончания осмотра женщины в холле приемного отделения. Если во время осмотра врач подтверждает, что женщина находится в родах, муж или ваш партнер по родам переодевается в хирургический костюм, сменную моющуюся обувь. Женщина с партнером в сопровождении санитарки направляется в предродовую палату родильного отделения. 

Персонал родового отделения примет эстафету заботы и доброжелательного отношения к будущей мамочке и партнеру. При возникновении вопросов не стесняйтесь их задавать. Знайте, что все готовы оказать вам поддержку, чтобы роды закончились благополучно. Поздравляю, у вас начался первый период родов. В следующем уроке подробно обсудим тактику действий мамы в этот период родов.


${separator}

Урок 2. Первый период родов

Родовая деятельность может начинаться в нескольких вариантах.

Первый, традиционный вариант – когда схватки начинаются сразу регулярные (чаще чем через 10 минут). Это повод для того, чтобы собираться и ехать в родильный дом. 
Второй вариант, который бывает реже, – когда схватки сначала носят нерегулярный характер и потом становятся регулярными. Промежуток времени этих нерегулярных схваток может быть совершенно разным. Это может быть несколько часов, а может быть практически сутки. Если женщина в этот промежуток времени не устает, может спать, не чувствует себя уставшей – это не является чем-то патологическим.
Есть так называемый перемежающийся характер развития родовой деятельности, когда сначала начинаются подготовительные схватки, потом наступает пауза (когда женщина может поспать, отдохнуть), и через какой-то промежуток времени начинаются регулярные схватки.

Начались схватки: что делать? 

Роды начинаются от момента появления регулярных схваток чаще чем через 10 минут. Пора ехать в роддом! 

Если до этого схватки носят регулярный характер (например, через 13-15-20 минут), это не считается началом родов.

Считается нормой, когда первый период родов продолжается от 10 до 18 часов. Если роды повторные, то первый период родов укорачивается.

Когда заканчивается первый период родов? Первый период родов заканчивается тогда, когда наступает полное раскрытие шейки матки. И время раскрытия шейки матки – это время терпения. Развиваются схватки – они болезненные, разной степени интенсивности.

В конце первого периода родов должен разорваться плодный пузырь плодной оболочки – и изливаются околоплодные воды. Не всегда плодный пузырь рвется в самом конце, иногда он разрывается до начала схваток или в начале первого периода родов. Это не является существенной патологией. Если воды излились дома до начала родовых схваток – необходимо приехать в роддом. Это состояние требует интенсивного наблюдения медицинского персонала.

Обезболивание родов

Нужно ли роды обезболивать? Этот вопрос является дискутабельным. Всемирная организация здравоохранения, которая опубликовала несколько нормативных документов по ведению родов, считает, что родовая боль является физиологической, и если роды протекают нормально, обезболивания эти родовые схватки не требуют. 

Обезболивание бывает разным. Способы могут быть как медикаментозные, так и немедикаментозные. 

Немедикаментозные способы обезболивания родов:

дыхательная гимнастика
йога
ароматерапия
принятие душа
теплые компрессы на болевые точки (теплая грелка, поливание теплой водой, душ)
положение на фитболе
свободное поведение, положение в родах
Женщина может свободно перемещаться по палате, параллельному залу, двигаться, выбирать то положение, в котором ей удобно. Можно использовать фитбол, гимнастический коврик. Можно находиться традиционно в кровати в любом положении (на боку или на спине) – в том, в котором женщине удобно.

Медикаментозное обезболивание может использоваться для родов как внутримышечное, так и внутривенное введение анестетиков (обезболивающих лекарственных средств) ненаркотических или наркотических. Их действие, как правило, кратковременное.

Считается целесообразным обезболивать роды тогда, когда схватки становятся интенсивными. Как правило, это в активной фазе родов, после раскрытия шейки матки на 5-6 см. 

Отклонения от физиологического течения родов

Во время первого периода родов могут наблюдаться различные отклонения от физиологического течения родов. 

аномалии родовой деятельности
слабость, неправильно развиваются схватки
со стороны плода – нарушение его состояния в связи с повышенной нагрузкой во время схваток (изменение записи кардиотокограммы)
преждевременный или несвоевременный разрыв плодных оболочек, когда изливаются воды. Считается, что плодный пузырь должен выполнять определенную функцию до самого конца первого периода.
Чаще всего встречается слабость родовой деятельности. Она возникает примерно в 20% случаев всех родов. И у 20% женщин, у которых наблюдается слабость, роды заканчиваются разрешением путем операции кесарево сечение. Не обязательно, если возникла слабость, нужно делать операцию, поскольку в настоящее время в современном акушерстве есть достаточное количество лекарственных средств, которые способствуют нормализации родовой деятельности и ее усилению. Женщины не всегда позитивно относятся к введению окситоцина. Но отметим, что окситоцин является физиологическим гормоном, абсолютно идентичным натуральному, который способствует развитию родовой деятельности. Использование его в процессе первого периода родов является обоснованным.

Еда и питье во время родов

Пить можно однозначно, без всяких опасений. Это может быть минеральная вода без газа или просто питьевая вода без газа. Маленькими глотками на протяжении всех родов, если хочется пить.

Обсудим еду. Использовать печенье, вафли не рекомендуется. Отлично утоляет голод и дает энергию шоколад (молочный, горький, белый). Единственное пожелание: шоколад должен быть без орехов и изюма, то есть чистый шоколад. Его можно есть мелкими порциями на протяжении всех родов. Использование соков, особенно сладких, мы не рекомендуем.

${tb3c2_2}

Урок 3. Анестезия в родах

Сегодня существует множество способов помочь будущей маме справиться с болью и дискомфортом во время родов. Чтобы уменьшить родовой стресс и боль, самое важное – это уметь расслабляться. В этом вам поможет массаж, растирание спины, ароматерапия, теплые ванны. Любимая музыка ослабит напряжение и поможет отвлечься. Несомненно, огромную поддержку и заботу на всех этапах родов вам окажет ваш партнер, если роды партнерские.

Но если эти способы не работают, то на помощь вам приходит врач-анестезиолог. 

Методы обезболивания родов 

спинальная анестезия
эпидуральная анестезия
На современном этапе самым распространенным методом анестезии является эпидуральная анестезия.

Эпидуральная анестезия

При эпидуральной анестезии лекарственный препарат вводится через специальный катетер. Обезболивающий препарат поступает через этот катетер постоянно и дозированно, с определенной скоростью. Скорость и объем препарата можно менять. То есть процесс анестезии можно делать безопасным, и его можно контролировать.

Анестезия блокирует болевые ощущения, и мама не чувствует боли, не испытывает родовой стресс во время схваток. Схватки продолжают идти, но они абсолютно безболезненны. При правильном дозировании анестетика будущая мама может самостоятельно передвигаться по палате.

Спинальная анестезия

При спинальной анестезии обезболивающий препарат вводится в спинальное пространство с помощью иглы. Врач-анестезиолог за пару минут делает один укол в спину, через который вводится анестетик, и анестезия сразу работает. Манипуляция длится около 3-5 минут, а эффект сохраняется около 2 часов.

На каком этапе можно выполнять анестезию в родах?

Эпидуральную анестезию можно начинать в любой период родов, катетер можно установить даже до начала родов. Оптимальный вариант для эпидуральной анестезии – это раскрытие шейки матки не менее 2-3 см и наличие установившейся родовой деятельности.

Чтобы решить, возможна ли спинальная анестезия, врач учитывает много факторов. Прежде всего, это раскрытие шейки матки и является ли мама первородящей или повторнородящей. Чаще всего спинальную анестезию применяют при раскрытии шейки матки не менее 5 см. В каждом конкретном случае решение принимается индивидуально.

Этапы анестезии

Будущая мама принимает решение делать анестезию и сообщает об этом врачу-анестезиологу. Что же происходит дальше? Как будет проходить процедура и что будет чувствовать мама?

Первый этап – это осмотр и беседа с будущей мамой. 
Врач-анестезиолог изучит медицинскую документацию, обменную карту, историю болезни, ваши анализы. Все лабораторные показатели берутся накануне родов либо непосредственно при поступлении в родзал. Если все необходимые показатели в норме, врач-анестезиолог приходит к будущей маме в родзал и проводит беседу. Он спросит, есть ли у вас какие-то хронические заболевания, аллергии, поинтересуется, как протекала беременность. Затем посмотрит вас, послушает сердцебиение, измерит артериальное давление, частоту сердечных сокращений, посмотрит показатели насыщения крови кислородом. Затем врач расскажет о том, какой метод анестезии вам рекомендуется, как он проходит, и обязательно предупредит о возможных осложнениях. Затем у будущей мамы возьмут письменное согласие на проведение манипуляции – непосредственно обезболивание родов.

С того момента, как получено письменное согласие мамы на проведение анестезии, начинается второй этап.

Второй этап – подготовка к анестезии. 
Сначала женщине устанавливают периферический венозный катетер в вену на руке. Через него будущей маме будет поступать специальный безопасный раствор, чтобы в процессе манипуляции резко не снизилось артериальное давление.

Третий этап – это выбор правильной позиции. 
Врач попросит вас принять удобное для манипуляции положение – это сидя или лежа на боку. Наиболее удобным положением будет сидя. Нужно сесть непосредственно на край кровати так, чтобы колени уперлись в матрас, затем широко раздвинуть ноги и удобно расположить живот между ног. Руки положить на колени, плечи расслабить, а локти стараться прижать к телу. Спину нужно будет выгнуть в сторону врача-анестезиолога в поясничном отделе приблизительно, как у злой кошки, либо в виде буквы «с».

${tb3c2_3}

Затем врач-анестезиолог обезболивает область, которую будет прокалывать. Это не очень приятно, но длится всего несколько секунд. Затем кожа в этом месте будет обезболена, и последующие манипуляции будут абсолютно безболезненные и комфортные. 

Четвертый этап – обезболивание родов.
Для начала давайте обсудим эпидуральную анестезию. Врач с помощью углы проведет тонкий катетер в эпидуральное пространство. После того как катетер введен, непосредственно иглу удаляют. Сначала нам введут тестовую дозу анестетика. Доктор попросит сказать, появились ли какие-нибудь новые или неприятные ощущения в ногах или руках. Например, потеплели, потяжелели, появились ли мурашки, либо возникло новое неприятное ощущение, либо вы не можете двигаться. Если появились эти симптомы (звон в ушах, металлический привкус, головная боль, а также чувство перебоев в работе сердца), немедленно сообщите об этом врачу-анестезиологу. 

Если ничего необычного вы не заметили в своих ощущениях, место пункции обклеивается пластырем, накладываются стерильные салфетки и катетер приклеивается к спине. В течение 2-3 часов после эпидуральной анестезии врач порекомендует вам оставаться в положении лежа и стараться не вставать с постели. 

При проведении эпидуральной анестезии ваши ощущения будут растянуты во времени. Боль уходит постепенно, в течение 20-30 минут. На первых этапах постановки катетера вы можете почувствовать прострелы в области поясницы или ногах, затем, после установки катетера, врач-анестезиолог начнет водить в него непосредственно местный анестетик. В этот момент вы почувствуете, как по спине побежал холодок. Первое время вы будете испытывать небольшой дискомфорт от стояния катетера в спине, но через 15 минут этот дискомфорт пройдет, а через 20-30 минут после введения препарата схватки станут менее болезненными, и вы можете почувствовать онемение, чувство тепла в области живота, ног, поясницы.

Таким образом, катетер все время остается в эпидуральном пространстве на всем протяжении родов. Через этот катетер доктор дозированно, с разной скоростью (в зависимости от болевой чувствительности) будет вводить местный анестетик. То есть если мама чувствует, что ей все-таки больно и некомфортно, врач-анестезиолог может увеличить скорость поступления анестетика в катетер – и боль уходит. 

После прекращения поступления анестетика действие анестезии заканчивается не сразу, а через 15-30 минут, и роды продолжаются в полном объеме. Как только вы родите малыша и потребности в эпидуральном катетере больше не будет, его удалят. Также эпидуральный катетер можно оставить, если после родов потребуется провести ушивание, например, разрывов промежности, шейки матки под обезболиванием.

Теперь давайте поговорим о том, как проводится спинальная анестезия. При спинальной анестезии будущей маме с помощью специальной иглы-проводника проведут спинальную иглу. Затем врач введет обезболивающий препарат.

Что же вы в это время почувствуете? Практически сразу во время введения анестетика вы почувствуете новые необычные для вас ощущения тепла в ногах или у вас может появиться чувство, как будто мурашки побежали по ногам. Если перед родами вам поставили мочевой катетер, который доставлял неудобство и дискомфорт, то одновременно с началом действия анестезии неприятные ощущения уходят, как будто катетера и вовсе не было. Некоторые мамы говорят, что ощущения в ногах становятся похожими на те, как будто вы отсидели ноги. После этих первоначальных ощущений будущая мама начинает терять болевую чувствительность. Это означает, что схватки продолжаются, роды идут своим ходом, но боли вы не чувствуете.

При спинальной анестезии эффект длится не более 2 часов, затем анестезия постепенно уходит, а у вас наступают потуги и возвращается чувствительность. Роды продолжаются, как и без анестезии. Во время анестезии вы будете находиться в ясном сознании. Это значит, что во время того, как работает анестезия, вы можете общаться с родными, выполнять рекомендации медперсонала, а также задавать интересующие вас вопросы врачам (врачу-анестезиологу, акушеру-гинекологу). 

Страхи будущих мам

Многие будущие мамы переживают, что в схватках не смогут сосредоточиться и выполнить рекомендации врача. Не волнуйтесь, все манипуляции производятся между схватками, чтобы не было каких-то лишних движений. Врач-анестезиолог попросит вас сообщать ему, когда начинаются и когда заканчиваются схватки. Также будущие мамы волнуются, что из-за анестезии не смогут тужиться. Да, анестезия вызывает ощущение заморозки, онемения и слабости в ногах. Но способность к потугам зависит от дозы введенного препарата. Сравнительно низкая доза снимает боль, но не мешает тужиться, а очень высокие дозы, полностью лишающие вас чувствительности и обездвиживающие нижнюю половину тела, применяются только при операции кесарева сечения, когда тужиться нет необходимости.

И эпидуральная, и спинальная анестезии имеют ряд преимуществ и недостатков. О них вам сможет рассказать врач-анестезиолог. Он обязательно сообщит вам о том, какой способ анестезии рекомендуется непосредственно вам, как будет проходить манипуляция, какие осложнения могут возникнуть. И, конечно, после родов он обязательно будет рядом и поможет вам, если вдруг что-то пойдет не по плану. Например, у будущей мамы может сильно болеть голова, она может чувствовать дискомфорт в области прокола.

Еще один вопрос, который волнует всех будущих мам: не влияет ли ее анестезия на малыша. К счастью, анестезия не оказывает никакого токсического действия на организм мамы и будущего ребенка. Препарат не попадает в кровь мамы и, соответственно, никаким образом не влияет на малыша.

${tb3c2_4}

Урок 4. Второй период родов

С момента полного раскрытия начинается второй период родов. Второй период родов имеет две фазы: пассивную и активную.

Пассивная фаза – от момента, когда диагностировано полное раскрытие шейки матки, до начала активных потуг. Активные потуги женщина ощущает с появлением желания тужиться, давлением на задний проход, давлением на низ живота, когда появляется ощущение продвижения чего-то внутри себя.

Второй период родов может продолжаться по-разному. Он является физиологическим до 2 часов. Появление потуг говорит о том, что ребенок начинает продвигаться по родовым путям, и в это время его движение регулируется в том числе маточными сокращениями, в основном с участием сокращения мышц брюшного пресса тазового дна.

В первом периоде родов женщина может быть в свободном положении. Женщина имеет право выбора того положения, в котором ей удобно и менее болезненно. То же самое относится к пассивной фазе второго периода родов. Женщина может выбрать себе то положение, в котором удобно. 

В это время продолжают развиваться схватки, они усиливаются, и на каком-то этапе присоединяются потуги. Женщина может тужиться:

на фитболе
в постели
стоя
у шведской стенки, поручней
опираясь на партнера
Свободное положение женщины в родах (ее вертикализация), безусловно, улучшает и ускоряет процесс изгнания. Но тем не менее в самом конце периода изгнания, когда начинает прорезываться головка плода (выходить из половой щели) наиболее оптимальное положение женщины – в кровати (либо на спине, либо на боку). 

Современное акушерство гуманно относится к выбору женщины. И женщина может заканчивать второй период родов с рождением ребенка в том положении, в котором ей удобно. Это может быть положение на спине или на боку, в положении стоя или на коленях.

Обсудим оказание акушерского пособия. Акушерское пособие было придумано для того, чтобы избежать больших разрывов промежности с повреждением мышц промежности тазового дна, прямой кишки и ануса, поскольку эти разрывы приносят значительный дискомфорт в послеродовом периоде, в дальнейшей жизни женщины. 

Акушерское пособие оказывает акушерка. Акушерское пособие может быть оказано в положении женщины на спине или на боку. 

Роль врача акушера-гинеколога во время второго периода родов заключается в мониторинге за состоянием плода и роженицы. Состояние плода контролируется выслушиванием сердцебиения или записью на специальном аппарате (КТГ). Что касается контроля за состоянием женщины – это контроль ее общего состояния. В настоящее время потуги ничем не управляются и не регулируются. Женщина тужится так, как ей удобно. Акушеры-гинекологи здесь выполняют роль наблюдателя. А акушерка продолжает оказывать акушерское пособие.

После того как родился ребенок, ребенка выкладывают сразу на живот мамы. Очень важен первый контакт малыша с родителями. В случае кесарева сечения, если нет возможности выложить ребенка на грудь маме, то ребенок отдается папе, если он присутствует на родах.

Итак, ребенок выкладывается на живот. Основная функция мамы в этот момент – его согревание. Происходит первый контакт, а также обсеменение материнской флорой, которая находится на коже – микроорганизмами, которые позволяют ребенку приспособиться к условиям жизни в семье.

Пуповина пересекается через 1-2 минуты после рождения ребенка, это называется отсроченное пересечение пуповины. В это время происходит трансфер крови от плаценты, которая продолжает функционировать, к ребенку, дополнительно давая объем крови для обеспечения функционирования легочного круга кровообращения, потому что во время беременности плод не дышит, и легкие кровоснабжаются только на 20-30% своей мощности. Эта дотация необходима для полноценного протекания периода ранней адаптации. 

После того как ребенок родился, мы дожидаемся прекращения пульсации пуповины, и тогда пуповина пережимается, пересекается, накладывается одноразовый стерильный зажим. От момента рождения ребенка до рождения последа мы наблюдаем третий период родов, или период последовый.

${separator}

Урок 5. Третий период родов

Третий период родов называется последовым периодом. В этот период времени происходит отделение и выделение последа. 

В это время ребенок находится на животе у мамы, и если позволяет состояние мамы и состояние малыша, его можно попробовать приложить к груди. Это не всегда получается, но тем не менее это следует сделать для обеспечения первого контакта.

Отделение последа происходит в течение 10-30 минут. В течение этого времени могут наблюдаться незначительные кровянистые выделения. Как правило, обычно кровь выделяется после того, как послед родится. В этот промежуток времени акушер-гинеколог наблюдает за состоянием женщины и малыша.

После рождения ребенка ребенок находится на животе у мамы, а с рождения последа ребенка осматривает врач-неонатолог. Состояние оценивается по шкале Апгар. Акушерка измеряет массу при рождении, окружность головы и длину.

После того как рождается послед, наступает ранний послеродовой период. В этот момент принято осматривать родовые пути. Это важно, потому что разрывы могут быть не диагностированы, разрывы шейки матки могут не кровить. 

Используется для осмотра родовых путей  родовое кресло. Именно поэтому идеально, если роды заканчиваются на родовом кресле. Но в то же время, если женщина хочет, роды могут заканчиваться рождением ребенка в том месте, которое она сама выбрала. Например, на кровати, стоя, опираясь на спинку кровати либо на специальные поручни.

Отказ от осмотра родовых путей может привести к тому, что остаются нераспознанными гематомы влагалища, которые вызывают скопление большого количество крови, и их ушивание требует достаточного профессионализма и присутствия опытного врача.

${separator}

Урок 6. Ранний послеродовый период

Итак, роды закончились, осмотрены родовые пути, ребенок готов к продолжению контакта с мамой. Маму перекладывают на обычную кровать в родильном зале, выкладывают ей на живот ребенка, контакт «кожа к коже» продолжается. Первое прикладывание груди (если до этого не удалось приложить ребенка к груди) осуществляется именно в этот промежуток времени. 

В течение этого периода времени, который называется ранний послеродовой период (это 2 часа сразу после родов), женщина находится в родильном зале под наблюдением акушерки, которая участвовала в родах, и врача акушера-гинеколога. 

За самочувствием женщины наблюдают с помощью специальной медицинской техники. Оцениваются: 

пульс
артериальное давление
температура
концентрация кислорода в крови
Если специальной медицинский техники в роддоме нет, то осуществляется измерение давления, измерение пульса в течение всех 2 часов, пока женщина находится в родильном зале вместе с ребенком.

Идеально, если женщина будет голой, чтобы контактировать с ребенком. Малыш тоже может быть голым. Малыша накрывают одеялом для новорожденного, а сверху еще одеялом для женщины. 

Если контакт ребенка с мамой невозможен по различным причинам, то отлично, если есть возможность контакта с отцом. Если мама находится после операции кесарево сечение или в родах использовалась внутривенная анестезия с отключением сознания, тогда ребенок может находиться на груди или на животе у отца с точно такой же целью. Роль отца в данной ситуации – согревать ребенка и обеспечить контакт «кожа к коже» с обсеменением микробами, с которыми ребенок столкнется в домашних условиях.

Осложнения в раннем послеродовом периоде

В течение этого времени могут развиваться послеродовые кровотечения. Они могут быть из неушитых разрывов, если родовые пути не осматривались (это распространено для зарубежной практики). Это может быть кровотечение из матки из-за ее недостаточной сократительной функции. Именно поэтому за состоянием женщины осуществляется непрерывное наблюдение персонала и специальной медицинской мониторирующей техники. 

Акушерское кровотечение требует оказания неотложной медицинской помощи. Именно поэтому в этот промежуток времени наличие квалифицированного врача и акушерки обеспечивает возможность сохранения матки (потому что иногда требуется ее удаление для лечения маточного кровотечения) и сохранения жизни женщины условиях, когда это кровотечение начинается.

Здесь кратко затронем тему родов на дому. К большому сожалению, акушерские кровотечения настолько стремительны, что скорость оказания медицинской помощи очень важна. И лишь квалифицированная медицинская помощь обеспечивает сохранение жизни. Если женщина рожала в домашних условиях и даже если это были роды достаточно легкие, быстрые и женщина находится в удовлетворительном состоянии какой-то промежуток времени – кровотечения могут развиться.

${tb3c2_5}

Урок 7. Роль акушерки в родах

Роды – это такой момент, благодаря которому мама и малыш встречаются спустя 9 месяцев заветного ожидания. Здесь важен вклад каждого специалиста. Но одной из главных помощниц для женщины в эти непростые часы была, есть и остается акушерка.

Акушерка – это специалист со средним медицинским образованием. Дословно акушерка переводится как та, которая стоит у ложа, та, которая помогает в родах. 

Роли акушерки 

Женщина встречается с акушеркой, как только поступает в приемный покой. Акушерка знакомится с будущей мамой, смотрит имеющиеся документы, оформляет их, измеряет таз, выслушивает сердцебиение, измеряет давление, по желанию женщины проводится очистка кишечника. С эстетической точки зрения она нужна, но Всемирная организация здравоохранения этого не требует. 

Уже после этого доктор проводит дополнительные исследования, возможно, назначает дополнительные анализы. Из приемного покоя будущая мама попадает в родовое отделение. Там находятся как предродовые палаты, так и родильный зал. Здесь она снова встречается с акушеркой, которая ее принимает, помогает расположиться, рассказывает, куда и зачем можно обратиться, контролирует сердцебиение, вызывает доктора по необходимости, регистрирует забор анализов. Все эти процедуры в буквальном смысле также в руках акушерки, как и все 3 периода в родах: раскрытие (момент от начала схваток до потуг), сами роды, последовый период. Все это время акушерка находится непосредственно с будущей мамой.

В некоторых родильных домах республики внедряется практика одна акушерка – одна женщина, когда акушерка ведет будущую маму от начала до конца родов. Но, дорогие мамы, не переживайте, если в предродовой будет одна акушерка, а в родовом зале – другая. Такой вариант также встречается довольно часто.

Помощь акушерки в каждом периоде родов

Первый период в родах – активно выжидательный. Схватки идут, становятся чаще, болезненные. В этот момент акушерка находится рядом. Записывает сердцебиение, измеряет давление, температуру, смотрит, насколько болезненно происходят схватки, считает, с какой продолжительности они идут. В большинстве случаев современные родильные дома оборудованы специальной системой наблюдения, благодаря которой нажатием одной кнопки подается звуковой сигнал, загорается лампа для акушерки, если ее вдруг не оказалось рядом. Также в палатах имеется видеонаблюдение. Монитор позволяет увидеть, что происходит с женщиной. Каждые 5, 10, 15 минут акушерка заходит к каждой из рожениц, проверяет, все ли в порядке. Это тот человек, который всегда на месте и всегда рядом. Акушерка контролирует происходящее, оценивает ситуацию и вызывает доктора в нужные моменты. 

Второй период – непосредственно период акушерки. Конечно, на родах присутствует и доктор, и неонатолог, но главная роль остается все же за акушеркой. Именно она принимает роды. Как говорится, и нет профессии прекрасней на всех шести материках, когда лежит все человечество у акушерки на руках. Именно акушерка первая принимает ребенка и уже затем передает его маме. Акушерка принимает роды, говорит, когда женщине тужиться, когда не тужиться, оказывает пособие в родах, чтобы было меньше травм, и, приняв ребенка, сразу выкладывает его маме на грудь. После прекращения пульсации пуповины мы ее пересекаем. 

Дорогие мамы, хотелось бы сказать, что мы делаем все шаги, чтобы организовать совместное пребывание матери и ребенка. Родили – и вместе вас уже переводят в послеродовое отделение, чтобы вы находились вместе с малышом.

Третий – последовый период, когда отделяется и выделяется послед. Это тоже делает акушерка. Смотрит признаки отделения-выделения последа, дальше ассистирует доктору в случае ушивания разрывов и наблюдает за тем, чтобы у женщины не было больше запланированной кровопотери. 

В последующие 2 часа, когда она находится в родильном зале, ранний послеродовый период ведет тоже акушерка. Смотрит за выделениями, за маткой, за давлением, за температурой и пульсом, также наблюдает за ребенком. Первое прикладывание к груди производит тоже акушерка. Смотрит, сосет или нет, измеряет температуру малыша. Через несколько часов мама и малыш переводятся в послеродовое отделение. Наблюдение за состоянием и самочувствием мамы, за ее выделениями продолжается и в позднем послеродовом периоде. Также возникают вопросы с грудью, начинает прибывать молоко. Помощь акушерки на данном этапе сложно переоценить, она помогает правильно и эффективно приложить малыша. Здесь я всегда даю совет своим родильницам: прикладывайте ребенка почаще к одной и ко второй груди по 15-20 минут.

Часто будущие мамы спрашивают, можно ли выбрать акушерку на роды. Наша система здравоохранения пока не позволяет такую практику, когда можно выбрать акушерку себе на роды заранее. 

Дорогие мамы, хотелось бы сказать, что акушерка сопровождает вас от приемного покоя до выписки. И помните, если вы подготовлены, то и вам, и акушерке будет проще. Рекомендую проходить курсы, смотреть обучающие видео. Конечно, в стрессе что-то можно забыть – именно поэтому так важно разговаривать с акушеркой. Да, в родах боль постепенно нарастает, это все естественно. В крайнем случае есть анестезия. 

Несмотря на то, что сейчас большое количество литературы, информации и будущие мамы активно готовятся к родам, все равно вопросы «когда конец?» и «когда я рожу?» – самые популярные. Постарайтесь отвлечься, пройдитесь по палате, подумайте о хорошем. Каждой женщине я желаю терпения. Это все закончится чудом, и вы к этому идете пусть мелкими шажками, но все это произойдет.


    `,
};
