import { AfterChildBirthIcon, ChildIcon, ClockIcon, ManagingPregnancyIcon, PlanningPregnancyIcon, PregnancyIcon } from "assets/icons";
import { UsageOptions } from "constants/constants";


export const STATUSES = [
  {
    status: UsageOptions.PLANNING_PREGNANCY,
    title: 'status_planning_pregnancy_title',
    icon: ClockIcon,
    phoneIcon: PlanningPregnancyIcon,
    text: 'status_planning_pregnancy_text',
  },
  {
    status: UsageOptions.MANAGING_PREGNANCY,
    title: 'status_managing_pregnancy_title',
    icon: PregnancyIcon, 
    phoneIcon: ManagingPregnancyIcon,
    text: 'status_managing_pregnancy_text',
  },
  {
    status: UsageOptions.AFTER_CHILDBIRTH,
    title: 'status_after_child_birth_title',
    icon: ChildIcon,
    phoneIcon: AfterChildBirthIcon,
    text: 'status_after_child_birth_text',
  }
];
