import { separator, tb3c1_1, tb3c1_2, tb3c1_3, tb3c1_4 } from 'assets/images';

export const b3_c8_text = {
  b3_c1_title: `Накануне родов: инструкция для мам`,
  b3_c1_description: `Спикеры: 
    Василий Шостак, врач акушер-гинеколог, кандидат медицинских наук
    
    Элеонора Федотова, врач акушер-гинеколог, заместитель главного врача по родовспоможению 1-ой городской клинической больницы г.Минска`,
  b3_c8_text: `
   ${tb3c1_1}
    Урок 1. Дата родов: как высчитать?

Срок родов высчитывается следующим образом: от даты первого дня последних месячных прибавляем 7 дней и отнимаем 3 месяца. Например, если 1-й день последних месячных начался 20-го июня, плюс 7 дней к 20 – будет 27, и отнимаем 3 месяца от июня – будет март. Значит, женщине надо рожать 27-го марта. 

Разумеется, что все родить в срок 40 недель беременности (а это будет именно 40 недель беременности) не должны. Роды происходят приблизительно около этого срока. Считается, что 80 % женщин рожают до 40 недель беременности, а в сроки к 41-й недели рожают 90% женщин, у которых есть беременность.

Роды в срок (срочные роды) – роды, которые произошли от 37 полных до 42 неполных недель беременности. Таким образом, целых 5 недель дается маленькому человеку, чтобы он появился на свет.
Преждевременные роды – роды до 37 полных недель беременности. Таких родов в мире от 4% до 8%. В нашей стране последние десятилетия эта цифра держится на уровне 4-5%. 
Соответственно, после 42 недель рождается всего 1% детей. Желательно, чтобы роды произошли до 41-й недели беременности. 

С чем это связано? Большинство женщин полагают, что родить ребенка раньше срока – это  плохо, а родить после срока – это  нормально. На самом деле считается, что после 41-й недели беременности начинают зарастать швы и роднички, соответственно, так называемая конфигурация головки плода (когда одна косточка заходит за другую) практически невозможна. Соответственно, объем головы плода к 41-й недели практически уже изменить невозможно. И ребенку тяжело родиться на свет, и маме не очень комфортно.  Поэтому до 41-й недели беременности должны быть закончены практически все роды. Если этого не произошло, то рекомендуется в 41 неделю приехать в родильный дом, чтобы вызывать роды.

${separator}

Урок 2. Собираем сумки в роддом

К родам, как к одному их самых важных событий в жизни, желательно подготовиться. Немаловажное значение имеет и то, с каким багажом вы поедете в роддом. Сборы сумок – это всегда очень волнительное и приятное занятие для мам.

Когда начинать собирать сумки? 

Если беременность протекает без осложнений и планируются роды в срок, то к 36-37-й недели беременности. 
Если беременность протекает с угрозой прерывания и есть вероятность преждевременных родов, то собрать сумки желательно в 30 недель.
Где найти список вещей в роддом?

Вы можете запросить его в том роддоме, где планируете рожать. А также часто списки можно найти на сайтах роддомов. А можете изучить этот урок.

Вещи рекомендуется упаковать в пакеты или сумки из синтетического материала (например, целлофан или силикон). Именно такие материалы легко обрабатываются дезинфицирующими средствами, что требуется по нормам и правилам санитарно-эпидемиологического режима в роддоме. Не рекомендуется брать с собой в роддом чемоданы, рюкзаки, спортивные сумки.

Вам предстоит собрать 3 сумки. 

1-ая сумка понадобится маме. В ней будут находиться вещи, которые нужно взять на роды. Ее рекомендуется взять с собой в роддом обязательно сразу. 
2-ая сумка нужна для вещей и принадлежностей для уже состоявшейся мамочки и малыша, которые потребуются в послеродовом периоде. 
3-яя сумка – это вещи на выписку из роддома для уже состоявшейся мамы и малыша. Второй и третий пакеты желательно сразу не брать. Их могут привезти родственники уже по факту рождения ребенка или к выписке.
Документы, необходимые при госпитализации

Документ, удостоверяющий личность (паспорт, вид на жительство или удостоверение беженца). 
Обменная карта. Этот документ будущей маме выдается в женской консультации. 
Ксерокопии обследований, проведенные до или во время беременности, которые отсутствуют в обменной карте (например, УЗИ плода, УЗИ вен нижних конечностей, ультразвуковое исследование сердца, заключение окулиста и т.д.).
Если вы поступаете на партнерские роды, дополнительно необходимо взять следующие документы:

сертификат о подготовке к партнерским родам;
справка терапевта  для партнера об отсутствии инфекционных заболеваний  из поликлиники по месту жительства или других медицинских учреждений (срок действия такой справки 10-12 дней);
и обязательно результат флюорографии органов грудной клетки партнера (не позднее 6 месяцев). Флюорография важна, чтобы исключить туберкулез.
Если вы заключили договор с роддомом на платные роды, то также не забудьте взять с собой договор оказания услуги и чек об оплате услуги. Вы можете приобрести специальную папку для документов, чтобы все было под рукой и в одном месте в нужный момент.

1-ая сумка

Наступил день родов. После осмотра в приемном отделении вас проводят в предродовую палату или индивидуальный родильный зал. Там и понадобится первая сумка. С содержимым этой сумки женщина будет переживать схватки, рожать и встречать первые часы с малышом.

Вещи для мамы

Телефон и зарядное устройство, наушники  (по желанию).
Халат, желательно из натуральных волокон (вискоза, хлопок).
Ночная сорочка, которую вы наденете уже в приемном отделении после принятия душа. Если будет необходимость, вам могу выдать одноразовую сорочку и в роддоме.
Моющиеся тапочки (прорезиненные или силиконовые, чтобы вы легко могли ходить в душ и далее передвигаться по всему роддому), а также носочки. Компрессионные чулки  рекомендуются по показаниям.
Полотенце для тела, полотенце для интимной гигиены. 
Туалетная бумага (1 рулон), гигиенические принадлежности (жидкое мыло). 
Многие будущие мамы спрашивают, нужно ли брать с собой станок для бритья. Сейчас в роддомах не обязательно осуществлять эпиляцию или бритье интимной зоны. Если есть желание, это можно выполнить незадолго до родов.

Гигиеническая помада (по желанию будущей мамы). В родах женщины часто активно дышат, поэтому сильно сохнут губы. А справиться с жарой вам помогут пульверизатор с водой или термальная водичка. 
Питьевая вода (2 бутылочки по 0,5 литра). Пить в родах разрешается небольшими глотками. 
Шоколадка, сухое печенье (1 упаковка около 200 г), мюслевый батончик. В родах вы теряете много энергии, и будет здорово, если под рукой будет быстрый перекус.
 Чашка и ложка. Они могут пригодиться в первые 2 часа после родов, когда вы будете находиться в палате наблюдения. Вам принесут порцию завтрака, обеда или ужина.
 Пакеты. В них вы сложите свою верхнюю одежду, обувь, в которой приехали.
Не забывайте, что в этой палате вы будете не одна. И речь не о партнере на партнерских родах, а о малыше, который появится на свет. Для него в первые часы жизни нужно взять несколько вещей.

Вещи для малыша

1 шапочку  и 1 пару носочков для новорожденного;
 одноразовый подгузник для ребенка  (не более 2-3 штук от 2 до 5 кг).
Вещи для партнера

В случае партнерских родов дополнительно нужно взять несколько вещей для партнера. 

моющиеся тапочки, носки (1 пара)
спортивные брюки и майка из хлопка (в некоторых учреждениях на партнерские роды выдается хирургический костюм)
полотенце для рук
небольшой продуктовый набор из нескоропортящихся продуктов (чай или кофе в термосе, перекус)
а также пакеты для одежды и обуви
2-ая сумка

Через 2 часа после родов маму переводят в послеродовое отделение. Там понадобится второй пакет. Обычно его привозят родственники после родов. 

Для мамы

Халат (отлично, если он будет на запах – это очень удобно).
Сорочка, удобная для кормления (2 штуки).
Носочки хлопчатобумажные (2 пары). 
Гигиенические  прокладки послеродовые (1 упаковка), а также гигиенические  прокладки с мягким верхним слоем 3-5 капель (тоже 1 упаковка).
Одноразовые трусики или трусы из хлопка (3 штуки). Желательно, чтобы трусики были свободными, с высокой резинкой из сетчатой структуры. 
Бюстгальтеры для кормления (2-3 штуки.). 
Прокладки для груди одноразовые (достаточно 1-й упаковки).
Косметичка для ухода и гигиены (шампунь, гель для душа, крем – то, что необходимо и подходит вам). 
Крем для профилактики и лечения трещин на сосках. 
 Послеродовый бандаж по показаниям.
Для малыша 

Подгузники (на вес 2-5 кг из расчета 7-10 подгузников в день). Лучше взять с собой небольшую упаковку, а дома оставить запасные, чтобы в случае необходимости вам привезли еще. 
Детские влажные салфетки (1 упаковка), одноразовые пеленки. Они используются для ежедневных уходовых процедур при уходе за малышом. Не стоит брать их в большом количестве, достаточно 2-3 пеленки. 
Одежда для малыша. Это ползунки, боди, пеленка-кокон, кофточки. Можно брать из расчета один комплект на день, то есть минимум 3 сменных комплекта (чтобы не стирать). Также возьмите носочки (2-3 пары), шапочки с завязками.
Жидкое детское мыло (удобнее брать с дозатором), крем под подгузник для защиты от опрелостей, детский крем для новорожденных.
3-яя сумка

Этот пакет привезут вам непосредственно перед выпиской.

Для мамы

Нижнее белье, колготы или носки
Свободная одежда, обувь и верхняя одежда по сезону
Декоративная косметика (по желанию мамы).
Для малыша

Одеяло в пододеяльнике или конверт для новорожденного по времени года.
2 подгузника.
Комплект одежды – боди или маечка, ползунки, кофточка, шапочки (2 штуки, хлопчатобумажная и теплая), носочки.
Комбинезон по сезону.
И напоследок несколько важных лайфхаков: 

в прозрачной сумке вы легче найдете то, что необходимо;
резиновые тапочки лучше брать на один размер больше (это связано с отеком ног накануне родов); 
не забудьте взять телефон и зарядное устройство, чтобы вы всегда были на связи с близкими людьми;
любите музыку – возьмите наушники;
не перегружайте свои сумки, но не забывайте, что вы всегда можете положить в сумку те вещи, которые помогут именно вам. Например, это может быть термальная вода, которой вы сможете освежить себя во время схваток, или, например, резинка для волос;
будет здорово, если вещи будут приятны и привычны вам и малышу. Захватите с собой любимую кружку, красивую сорочку. Но лучше выбирайте вещи из натуральных материалов;
в случае, если женщина постоянно принимает какие-либо лекарства в связи с сопутствующими заболеваниями, необходимо их взять в стационар. К примеру, специальные лекарственные средства при ВИЧ-инфекции, сахарном диабете, артериальной гипертензии.  

Нет оснований волноваться, даже если вы что-то забыли. В роддоме вам помогут разобраться с возникшей проблемой. Самое главное, что следует брать в роддом, – это спокойствие и уверенность, что все пройдет благополучно!

${tb3c1_2}

Урок 3. Как выбрать роддом?

Родильные дома в нашей стране отличаются по уровню оказания перинатальной помощи. Это помощь, которая оказывается не только женщинам, но и малышам, потому что есть ситуации, когда предполагаются более тяжелые роды. Например, ребенок может родиться преждевременно или у мамы есть тяжелое осложнение беременности, какое-то заболевание, связанное, например, с нарушением сердечно-сосудистой системы, сахарный диабет, неврологическая патология, когда ребенок должен родиться преждевременно или в сроки неполной доношенной беременности. Тогда для него требуются определенные условия выхаживания. Это определяет уровень готовности родильных домов для оказания помощи и маме с определенными заболеваниями, и, соответственно, ее маленькому ребенку.

Родильный дом в нашей стране закреплен за женской консультацией, где вы наблюдаетесь. Можно ли поменять родильный дом? Вы можете обратиться в любой родильный дом, где вам предложат оказание платной медицинской услуги «индивидуальное ведение родов». Вы можете выбрать тот родильный дом, который нравится вам. Очень важный критерий – это доступность, близкое расположение роддома.

В последнее время появилась возможность провести экскурсию в том месте, где вы собираетесь рожать. Если мы отвлечемся от неблагоприятной эпидемиологической ситуации в стране, то раньше эти экскурсии были возможны и проводились практически в каждом родильном доме, чтобы не только сама женщина, но и ее близкий человек (отец будущего ребенка) мог ознакомиться с тем местом, где произойдут роды. В настоящее время в связи с неблагоприятной эпидемиологической ситуацией, такие экскурсионные туры по родильным домам невозможны. 

Возможности родильных домов 

Партнерские роды возможны в тех местах, где можно изолировать одну семейную пару от другой семейной пары. Такие условия есть, к сожалению, пока не во всех родильных домах нашей страны, но в крупных родильных учреждениях это возможно.

Индивидуальное ведение родов – это платная медицинская услуга, которая предполагает участие более квалифицированного персонала в родовспоможении. Эта услуга также используется  как возможность поменять родильный дом, то есть выбрать другой, не относящийся к территории обслуживания вашей женской консультации. 

Можно ли выбрать врача на роды? К сожалению, пока такая услуга не может быть широко доступна во всех родильных домах. Это связано с ограниченным числом персонала, который может принимать участие в индивидуальном ведении родов.  Врача можно выбрать в некоторых родильных домах нашей страны (как правило, это крупные города). 

Обсудим платные роды и бесплатные роды. Объем оказания медицинской помощи не зависит от того, роды платные или роды бесплатные. Весь полный объем медицинской услуги, трактуемой как родовспоможение, оказывается и в том, и в другом случае. 

При оказании платной медицинской услуги на первом месте идет выбор комфортных условий пребывания как до родов, в процессе родов, так и послеродовое пребывание в палате с малышом. И  очень часто эта услуга определяет присутствие и участие в родах высококвалифицированного персонала (врача, акушерки, врача-неонатолога, врача-реаниматолога, возможно) – все зависит от конкретного предложения в родильных домах. 

Очень интересный аспект, который обсуждается современным обществом, – это возможность нетрадиционных родов. Например, вертикальные роды, роды в воде. В нашей стране роды в воде возможны только в единичных случаях и ничем, к сожалению, не регламентированы, поэтому практически они не проводятся. Акушеры-гинекологи нашей страны пока не имеют широкого опыта по ведению таких родов.

Вертикальные роды рассматриваются в нашей стране как нечто, имеющее отношение к естественному родовспоможению, хотя на самом деле это совершенно не так. Чаще всего женщины говорят о том, что мы хотим вертикальные роды, подразумевая под этим возможность переждать второй период родов в вертикальном положении (т.е. тужиться стоя).  Но здесь есть важные аспекты и нюансы, которые каждой семейной паре важно знать. 

К большому сожалению, при вертикальных родах оказание акушерского пособия (то есть того пособия, которое требует профилактики защиты промежности) невозможно. Это пособие оказывает акушерка. И акушерка, разумеется, должна иметь возможность доступа к промежности. Поэтому пособие по защите промежности оказывается только в трех положениях: в положении на спине (это  традиционное положение для родов) и в положении на боку. Также возможна защита промежности в положении на четвереньках.

В вертикальном положении (например, стоя) акушерское пособие не оказывается, и вероятность разрыва промежности увеличивается в 4-6 раз. О вертикальном положении Всемирная организация здравоохранения, Международное общество акушеров-гинекологов стали говорить давно и именно как о свободном положении женщины в родах. То есть женщина сама выбирает то положение, в котором ей удобно находиться как во время первого периода родов, так и во время второго (то есть во время периода изгнания плода, его рождения). 

Период изгнания имеет 2 фазы: так называемую пассивную и активную. Именно в активной фазе начинаются активные потуги. Соответственно, до начала активной фазы женщина может находиться в любом положении. Когда она начинает тужиться, она может начинать тужиться:

на фитболе (на мяче)
лежа в кровати на боку, на спине
на четвереньках
То есть в любом положении – если это ей комфортно и комфортно выслушивание сердцебиения плода (оно должно быть выслушано у каждой женщины во втором периоде родов). Выбор положения зависит только от предпочтений женщины. 

Вертикальное положение в самом конце второго периода (т.е. в период изгнания и активных потуг), как правило, женщины не очень активно используют и рожают в традиционном положении – то есть в положении на спине.

Как выбрать роддом?

На первом месте стоит доступность родильного дома, чтобы вы могли легко и быстро приехать из дома, когда начинаются схватки, изливаются воды. Родильный дом должен быть доступен в течение 10-20 минут.

Второй аспект, который очень часто женщин интересует, – это квалификация персонала. Как правило, на сегодня все акушеры-гинекологи, акушерки достаточно квалифицированы. Поэтому вы можете выбрать родильный дом, который вас больше устраивает по отзывам ваших подруг, в интернете, в СМИ, и тот, который вам понравился, когда вы были на экскурсии.

Возможность комфортных условий также имеет значение для вашего выбора. Некоторые женщины предпочитают находиться в палате одной. Некоторые женщины, наоборот, хотят лежать вдвоем с какой-то другой родильницей, чтобы иметь возможность отойти, не оставляя ребенка без присмотра. 

Выбор родильного дома определяется не только условиями, которые вы хотите в нем получить, но и вашим настроением. Желаем, чтобы ваше настроение всегда было хорошим, а роды были легкими!

${separator}

Урок 4. Образ жизни мамы накануне родов

О том, что к родам нужно готовиться заранее, все чаще говорят врачи акушеры-гинекологи, психологи да и просто женщины, уже имеющие опыт родов. Обсудим важные советы и рекомендации для будущих мам накануне родов.

Теоретическая подготовка

«Предупрежден – значит вооружен», – гласит народная мудрость. С полученными знаниями родовой процесс уже не будет чем-то неизвестным. Важно не потеряться в информационном потоке и получить нужную информацию о родах, которая будет реально полезна.

Отличным вариантом будет получить знания в процессе живого общения со специалистами: акушерами-гинекологами, неонатологами, педиатрами, психологами. Сделать это можно на курсах в женских консультациях, роддомах, медицинских центрах. На этих занятиях будущая мама подготовится не только теоретически, но и практически – научится расслабляться в промежутках между схватками, делать обезболивающий массаж, выбирать оптимальное положение тела. 

И, конечно, сегодня можно проходить обучение у специалистов в удаленном доступе. В этом поможет вам в том числе и наша Программа подготовки к родам.  

Психологическая подготовка

Это гарантия успешных родов. Чтение форумов, негативных отзывов, историй сложных и трагических родов значительно ухудшает психоэмоциональное состояние накануне родов, вселяет тревогу и страх. В случае каких-то опасений лучше посоветоваться со своим врачом. Если все-таки вас что-то пугает, не держите свои эмоции в себе, расскажите о своих чувствах кому-то из близких, кто сможет вас поддержать. Можно обратиться на прием или на занятия к психологу. Этот специалист поможет справиться с волнениями, вернет душевное равновесие. .

Питание

За месяц до родов старайтесь употреблять простую и легкоусвояемую пищу.

Можете есть 5-6 раз в день небольшими порциями. 
Рекомендуется включить в дневное меню 2-3 приема фруктов и более 4 приемов овощей. 
Приветствуются в рационе овощи, приготовленные на пару, печеные фрукты и овощи (брокколи, шпинат, зеленая фасоль, зеленые листовые овощи, морковь, зеленые сорта яблок, сухофрукты), а также орехи, свежевыжатые соки. 
Добавляйте в свой рацион каши из цельнозерновых злаков, цельнозерновой хлеб – это источник энергии, клетчатки, железа, витаминов группы В. 
Не забывайте про воду, рекомендуется выпивать не менее 1 литра в день.
Физическая подготовка 

Роды можно сравнить с марафоном. А для забега на длинную дистанцию нужно много физических сил и выносливости. Накануне родов вы можете:

Заниматься растяжкой.
Учиться правильно дышать и расслабляться.
Приветствуются ходьба пешком, занятия йогой. 
Можете выполнять упражнения Кегеля, чтобы мышцы малого таза и промежности были в тонусе.
Все занятия не должны требовать чрезмерных усилий, а быть комфортными для организма будущей мамы.

Что способствует своевременному началу родов? 

Прогулки

Это улучшает кровоснабжение, насыщение кислородом. Как мы говорили ранее, не забывайте, чтобы физическая нагрузка была дозированной. Более интенсивная физическая нагрузка рекомендуется только тренированным спортсменам и только после консультации с врачом. А прогулки – это всегда прекрасный вариант для всех будущих мам.

Сексуальная жизнь

Занятия сексом даже рекомендуются накануне родов. Самый главный совет – при половом акте следует выбирать удобное положение для беременной женщины. В сперме содержатся простагландины, которые способствуют «созреванию» шейки матки. Она становится мягче, эластичнее, что способствует вступлению в роды. Важным является и положительная эмоциональная составляющая интимной близости. Обратите внимание: сексуальная жизнь приветствуется, если у вас нет противопоказаний, о которых вас предупредит ваш лечащий врач.

Теплая ванна или душ

Водные процедуры помогут вам расслабиться, облегчат чувство тяжести, болезненность при тренировочных схватках, стимулируют кровоток. Обратите внимание: ванна должна быть не горячая, а теплая, комфортной температуры.

Ежедневное опорожнение кишечника 

Исключает чувство тяжести в нижних отделах живота. Конечно, нередко будущие мамы сталкиваются с такой проблемой, как запоры. Вам поможет клетчатка, рекомендуется есть овощи, зелень, фрукты. А вот применение слабительных средств необходимо согласовать с врачом.

Также можно использовать и различные альтернативные методики, которые помогут расслабиться и настроиться на позитивный лад перед родами:

массаж активных точек; 
ароматерапия (можете использовать масло примулы, жасмин – при отсутствии аллергии);
травяные чаи; 
медитации. 
Все это поможет расслабиться и улучшить кровообращение.

Нет оснований для беспокойства, если беременность протекает гладко и роды не начинаются до 41-й недели. Если у вас все же есть опасения, то можно обсудить их с врачом акушером-гинекологом и получить совет профессионала.

Накануне родов не рекомендуется 

Путешествовать за 3-4 недели до предполагаемой даты родов. События могут развиваться непредсказуемо, а вы можете оказаться далеко от доступной медицинской помощи. 
Игнорировать тренировочные схватки (предвестники родов). Эти боли не должны вызывать дискомфорта, не должен нарушаться ваш сон, период засыпания. Если эти боли вызывают изменение в характере шевелений плода, сопровождаются усталостью, излишним беспокойством, нарушают сон – лучше обратиться к врачу.
Спать на спине после 30 недели. В положении лежа на спине на нижнюю полую вену давит увеличенная беременная матка, в результате чего приток крови к правому предсердию замедляется. Симптомы сдавления нижней полой вены проявляются снижением артериального давления, беременная женщина чувствует нехватку воздуха, головокружение, у нее темнеет в глазах, учащается дыхание, в ушах слышится шум, возможна даже потеря сознания. Может возникнуть угрожающее жизни плода и женщины осложнение – преждевременная отслойка плаценты и кровотечение. Это требует немедленного обращения к врачу, поэтому рекомендуется спать на боку.
Сдерживать позывы к мочеиспусканию. Лучше чаще опорожнять мочевой пузырь – это способствует профилактике инфекций мочевыводящих путей.
Поднимать тяжести. Это может вызвать угрозу преждевременных родов, спровоцировать возникновение болей в поясничном отделе позвоночника. Рекомендуется  поднимать не более 5 кг.
Сцеживать грудь до родов. Попытки сцеживания молока могут вызвать преждевременные роды – при стимуляции сосков начинает вырабатываться окситоцин и активизируется сократительная деятельность матки.
Регулярное наблюдение врачом, оценка шевелений плода, запись сердцебиения плода, взвешивание, измерение артериального давления, а также выполнение рекомендаций врача – вот залог благополучного исхода родов.

${separator}

Урок 5. Предвестники родов

Современное акушерство считает классическими предвестниками появление схваткообразных болей (нерегулярных, через разные промежутки времени), которые обычно появляются под вечер, в  первую половину ночи и заканчиваются обычно под утро и проходят бесследно. В это время могут быть слизистые, слизисто-кровянистые выделения, их может и не быть. 

Эти схваткообразные боли называются схватками-предвестниками.  Очень часто предвестниковые схватки возникают на фоне так называемого предменструального дискомфорта: тянет, ноет живот, поясница, и в это время появляются какие-то схваткообразные боли (внизу живота, в пояснице).

Для чего нужны предвестниковые схватки? Они готовят шейку матки к родам. Она начинает раскрываться, укорачиваться, пропускать какое-то количество пальцев. Это называется «созреванием», или готовностью шейки матки. Чем больше шейка матки подготовится к родам (т.е. чем больше она откроется), чем больше она станет мягче, тем короче будут роды, потому что какая-то часть работы уже будет выполнена до того, как начнутся родовые схватки. 

Во время того как созревает шейка (т.е. она начинает раскрываться),  слизистая часть, которая заполняет цервикальный канал (канал шейки матки),  не находит возможности удержаться в этом канале и вываливается сначала во влагалище, а потом, соответственно, и наружу. Это отхождение слизи  называется отхождением слизистой пробки. 

Многие женщины думают, что, если пробка отошла, значит, должны начаться роды. На самом деле если пробка отошла – это не значит, что начинаются роды. Это говорит о том, что созревает шейка матки. Шейка может созревать несколько дней подряд (может быть, даже несколько недель), но, как правило, это в среднем 3-4 дня. Тянущие, ноющие боли приводят к раскрытию шейки матки, ее созреванию и отхождению слизистой пробки. Таким образом, отхождение слизистой пробки также является составной частью классических предвестников родов.

${tb3c1_3}

Урок 6. Когда ехать в роддом?

Как понять, что роды начались? Запомните три главных признака.

Появление регулярных родовых схваток
Иногда роды начинаются постепенно – сначала начинаются нерегулярные схватки (через разные промежутки времени). Например, через 3, через 5, через 8, через 10, через 22 минуты. Потом они либо прекращаются на какой-то промежуток времени (например, начались ночью и к утру закончились, а к вечеру начинаются регулярные), либо они переходят в регулярные родовые схватки. 

Родовыми схватками называются те схватки, которые не только регулярные, но и чаще чем через 10 минут. Таким образом, схватки сначала начинаются через 15-20 минут, потом становятся через 12, потом через 10 и через 9. Как только они стали чаще чем через 10 минут – это и есть регулярные родовые схватки. Это определяется как начало первого периода родов. 

Излитие околоплодных вод
Как правило, многие женщины описывают это ощущение как «произошел щелчок – и потом что-то потекло». Иногда женщина не может разобраться, это воды или это какие-то слизистые жидкие выделения из влагалища. Если у вас есть малейшие сомнения в том, воды это или не воды, нужно обратиться в родильный дом.

Появление обильных кровянистых  выделений. И это очень важный признак, который может угрожать жизни вашего малыша. 
Мы уже говорили с вами о том, что появляется слизистая пробка. Как правило, она окрашивается кровью, поскольку, когда раскрывается шейка, мелкие сосуды в ней лопаются, эта кровь попадает в слизь, окисляется в воздухе, который содержится во влагалище в небольшом количестве. И поэтому слизистая пробка окрашивается либо в коричневый, либо в темно-вишневый цвет. Появление обильных кровянистых выделений (их количество можно сравнить со вторым днем месячных, или выделения могут быть еще более интенсивные) – это признак, который требует обращения в родильный дом. 

Если вы думаете, что схватки еще не настоящие (т.е. вы сомневаетесь), вам не надо ехать в родильный дом, можно побыть дома. Роды, как правило, не протекают быстро. Первые роды в норме продолжаются от 12 до 18 часов. Поэтому у вас есть время спокойно разобраться, правильные схватки это или неправильные. И помните, что у каждой женщины роды начинаются и протекают по-разному. 

Подытожим: в родильный дом нужно ехать тогда, когда схватки становятся регулярными, когда изливаются воды или когда начинаются обильные кровянистые выделения. Все эти 3 повода являются основанием для вызова бригады скорой медицинской помощи. 

Можно ли отвезти женщину в родильный дом на собственном автомобиле? Как врачу акушеру-гинекологу, мне кажется это нецелесообразным, поскольку вы можете попасть в час пик, может быть плохая дорога, плохая видимость. Скорая помощь сделает это гораздо качественнее, и, кроме того, в случае необходимости возможно оказание помощи при родовспоможении непосредственно в самой машине скорой медицинской помощи.

Когда начинаются активные схватки, изливаются воды, кровянистые выделения, следует сразу вызвать машину скорой медицинской помощи по телефону 103. Когда приедет бригада скорой медицинской помощи, нужно показать обменную карту, для того чтобы врач скорой медицинской помощи понимал, в какой родильный стационар нужно вас везти и как быстро.

Не забудьте, когда вы едете на скорой помощи непосредственно в родильный дом,  взять с собой обменную карту, паспорт, а также анализы, исследования, которые могут быть приложением к обменной карте. И не забудьте дома хорошее настроение!

Если вам предстоит плановое кесарево сечение, то не стоит ожидать развития активной родовой деятельности. Как только начинаются схватки и изливаются воды (даже если эти схватки нерегулярные), нужно ехать в родильный дом, чтобы у акушерской бригады была возможность подготовить вас к операции, которая будет уже не плановой, а экстренной.

Иногда возникает необходимость так называемой дородовой госпитализации. Как правило, дородовая госпитализация нужна для оценки состояния плода, выяснения компенсаторных возможностей плацентарного комплекса, изучения функции почек, сердечно-сосудистой системы у самой беременной. То есть для того, чтобы судить о ее готовности к повышенному стрессу во время родов. 

Эти показания могут возникнуть как со стороны мамы (например, повышенное артериальное давление или появление белка в анализах мочи), или со стороны плода, когда записывается тревожная, трудно интерпретируемая кардиотокограмма. В этих ситуациях врач акушер-гинеколог женской консультации направляет женщину для дородовой  госпитализации.

Дородовая госпитализация осуществляется как в плановом, так и в экстренном порядке. 

Плановая госпитализация в родильные дома осуществляется с 9 до 12 часов утра. Как правило, на следующий день после приема врача акушера-гинеколога. Также плановая госпитализация проводится в случаях, когда планируется родоразрешение путем планового кесарева сечения, чтобы подготовить женщину к операции.

${tb3c1_4}

Урок 7. Обзор роддома: отделения, палаты 

Устроим виртуальную экскурсию по роддому. Обсудим, какие отделения там существуют, в каких побывает мама и разберем важные советы. Этот урок будет полезен и тем будущим родителям, кто выбирает роддом.

Родильный дом – медицинское учреждение, в котором оказывается медицинская помощь женщинам на разных этапах беременности (в родах, в послеродовом периоде) и новорожденным детям в период новорожденности.

Приемное отделение

Это место первой встречи беременной с медицинским персоналом роддома. В приемном отделении будущая мама заполнит специальную анкету-опросник. Далее ей измеряют температуру, артериальное давление, осуществляют запись сердцебиения плода (КТГ). Также измеряют высоту стояния дна матки, окружность живота, размеры таза. 

Подробно спрашивают беременную о течении беременности, осложнениях беременности, перенесенных заболеваниях. Все данные вносятся в историю родов. В приемном отделении будущую маму осматривает врач акушер-гинеколог. На этапе поступления врач решает, в какое же отделение будет осуществляться госпитализация беременной, определит предварительный план обследования и лечения. В случае если женщина находится в родах, на этапе приемного отделения ей предлагают выполнение очистительной клизмы.

Если у будущей мамы были какие-либо проблемы во время беременности (сердечно-сосудистые, эндокринные заболевания, проблемы в состоянии плода, требующие дополнительного обследования), то беременную госпитализируют в отделение патологии беременности.

Если же у беременной есть некоторые хронические заболевания (например, артериальная гипертензия, сахарный диабет, хронический гломерулонефрит и прочие), то ей необходима плановая госпитализация для комплексного обследования, консультаций узких специалистов и коррекции лечения. В таких ситуациях беременную тоже направляют в отделение патологии беременности. 

В сложных клинических ситуациях в отделении проводят консилиум. Это совместный осмотр специалистов, где врачи определяют дальнейший план ведения беременности и родов. 

Пациенток в различные отделения роддома сопровождает санитарка. 

Родовое отделение

Это отделение, где происходят роды. В этом отделении обычно есть следующие помещения:

отдельные предродовые палаты – в них женщина находится в 1-м периоде родов;
родильные залы – это помещение, в котором проходит 2-й и 3-й периоды родов, где непосредственно  рождается ребенок;
а также палаты послеродового наблюдения – в них родильница проводит 2 часа после родов под наблюдением медперсонала. 
В современных родильных домах предродовая палата и родильный зал объединены в общий индивидуальный родильный зал.

Предродовая палата

В ней есть кровати с удобными матрасами, индивидуальные аппараты для записи сердцебиения плода, фитболы, специальные стульчики для вертикального положения в родах. Также будущая мама может принять душ. Постельное белье выдается в отделении.

Также в палате есть различная медицинская мебель, процедурные столики, все необходимое для выполнения врачебных назначений. 

В течение родов акушерки и врачи родового отделения осуществляют непрерывную поддержку роженицы, рассказывают ей об изменениях в родах. Сегодня в роддомах поощряется активность и свободное положение во время родов.

Родзал

Самое главное там – это кресло-трансформер. Мы называем его родовое кресло. Это специальное кресло для приема родов, в котором можно изменять высоту кресла, положение спинки, изголовья, в зависимости от предпочтений женщины. Самое главное, чтобы мамочке было комфортно тужиться, а акушерке удобно принимать малыша.

Палата послеродового наблюдения 

Здесь в течение 2-х часов после родов  родильница находится под контролем акушерки и врача. С помощью аппаратов врачи наблюдают за пульсом, артериальным давлением женщины, насыщением крови кислородом. Также оценивается размер и консистенция матки, характер выделений из половых путей. Родильницу осматривают каждые 30 минут.

Послеродовое отделение

После родов маму переводят в палату в послеродовом отделении. Чаще это палаты двухместные. Там мама будет находиться вместе с ребенком до момента выписки из роддома. 

Сегодня роддома предлагают большой выбор палат в послеродовом отделении. Это могут быть обычные палаты, палаты с индивидуальным уходом. Это могут быть одно- и двухместные, сервисные палаты, в которых есть холодильник, телевизор, микроволновая печь. Палаты семейного типа, в которых может круглосуточно находиться папа или кто-либо из близких родственников. Каждый роддом имеет свои дополнительные сервисные услуги, которыми можно воспользоваться по желанию на платной основе.

В послеродовом отделении мамочкам помогают наладить грудное вскармливание, обучают правилам ухода за новорожденным, правилам гигиены, проводят лечебную физкультуру, физиотерапевтическое лечение. Каждая палата имеет отдельный санузел (туалет и душевую комнату).

Самый главный совет для мам в роддоме – не оставаться тет-а-тет со своими проблемами и переживаниями. Вы можете обратиться по любому вопросу к акушерке, врачу. В некоторых роддомах в послеродовых палатах есть кнопки экстренного вызова персонала или указаны телефоны, по которым можно связаться с акушеркой, детской медсестрой и пригласить специалиста для решения возникших проблем.

Во всех роддомах Беларуси приветствуется и практикуется совместное пребывание мамочки с ребенком, оказывается содействие грудному вскармливанию в соответствии с принципами Всемирной организации здравоохранения. Перечислим их:

максимально раннее прикладывание новорожденного к груди; 
контакт «кожа к коже»;
кормление ребеночка по требованию;
своевременная поддержка мамы в вопросах грудного вскармливания;
использование заменителей грудного молока исключительно по медицинским показаниям. 
Обсервационное отделение

Это мини–роддом в роддоме. Отделение совмещает в себе палаты для беременных, родильный зал, послеродовые палаты. Как правило, в это отделение госпитализируются беременные в период обострений хронических болезней, с симптомами респираторных инфекций во время беременности и накануне родов. Как правило, эти заболевания не представляют эпидемиологической опасности для окружающих. 

Роддома и COVID-19 

Госпитализация беременных с подтвержденной инфекцией COVID-19 осуществляется в отдельные роддома или в специально выделенные отделения. Плановая госпитализация беременных в другие роддома осуществляется только после предварительного выполнения анализа – проводится мазок на патогенный вирус из носа и зева. При получении отрицательного результата возможна госпитализация в обычный роддом.  Тем самым минимизируется вероятность заболевания беременных инфекцией COVID-19 при пребывании в роддоме.

Отделение новорожденных

Там осуществляется наблюдение, оказание медицинской помощи, вакцинация детей. Проводится аудиологический скрининг, запись электрокардиограммы каждому малышу, выполняются ультразвуковые исследования при наличии показаний. При выявлении патологии назначается лечение. Если ребенок может находиться на совместном пребывании, его сразу привозят к мамочке. Неонатологи и детские медсестры ежедневно проводят обходы, осматривают ребенка, подробно рассказывают мамам о состоянии малыша, обучают уходу за малышом, правилам грудного вскармливания.

В сложных случаях, когда ребенок рождается в тяжелом состоянии или глубоко недоношенным, лечение проводится в отделении детской реанимации. Мамам разрешается посещать и общаться со своим малышом в отделении реанимации в строго отведенное время. Врачи детские реаниматологи подробно рассказывают маме о состоянии ребенка, обследованиях и лечении. 

В педиатрическом отделении для выхаживания новорожденных детей находятся малыши, которым требуется больше времени для адаптации во внеутробной жизни. Это недоношенные, маловесные дети; дети с преходящими нарушениями неврологического статуса; дети с перенесенной инфекцией, специфичной для периода новорожденности; а также при врожденных пороках сердца, когда требуется более длительное наблюдение за ребенком. Здесь продолжается лечение у неонатолога, а также детей обследует невролог, окулист, специалист отделения реабилитации проводит лечебную физкультуру. Как правило, в этом отделении малыши находятся вместе с мамами, если нет медицинских противопоказаний.

Весь персонал роддома осуществляет непрерывный уход за женщиной и новорожденным, оказывает поддержку в вопросах грудного вскармливания, ухода за новорожденным. Важный принцип взаимодействия – это эффективное общение пациента и персонала. Обращайтесь без стеснения по самым разным вопросам, вам обязательно помогут. Ведь время пребывания в роддоме – это прекрасная возможность получить профессиональную рекомендацию и совет. Нам очень важно, чтобы у вас осталось только хорошее впечатление о пребывании в роддоме.


    `,
};
