import { useTranslate } from 'hooks/useTranslate';
import { Link } from 'react-router-dom';
import styles from './RecommendationButton.module.css';

interface MyComponentProps {
  key: number;
  icon: any;
  type: string;
}

const RecommendationButton: React.FC<MyComponentProps> = ({
  icon: Icon,
  type,
}) => {
  const t = useTranslate();

  return (
    <Link className={styles.container} to={`/main/${type}`}>
      <div className={styles.chevron}>
        <Icon />
      </div>
      <p className={styles.title}>{t(type)}</p>
    </Link>
  );
};

export default RecommendationButton;
