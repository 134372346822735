import { Lessons, UntypedObject } from './baseTypes';
import { Expert } from './items/Expert';

export enum RecommendationType {
  ADVICE = 'section_title_advices',
  COURSE = 'section_title_courses',
  POST = 'content_type_posts',
  LESSON = 'courses_lessons',
  MEDITATION = 'meditation',
  LULLABIES = 'lullabies',
  EXPERT = 'content_type_expert',
}

export enum NoDataFormat {
  QQ_D_QQ_W,
  QQ_QQ_QQQQ,
}

export enum UsageOptions {
  PLANNING_PREGNANCY = 'planningPregnancyAndWomenHealth',
  MANAGING_PREGNANCY = 'pregnancyAndChildbirth',
  AFTER_CHILDBIRTH = 'afterChildbirth',
}

export type CourseData = {
  createdDate: any;
  video: string;
  thumb: string;
  vimeo: any;
  content: any;
  id: string;
  title: string;
  description: string;
  type: RecommendationType;
  image: string;
  expert: Expert;
  lessons?: Lessons;
  analytics?: { likes: Record<string, boolean> };
  booked?: Record<string, boolean>;
  duration?: number;
  data?: UntypedObject;
  approximateTime?: number;
  enable?: boolean;
  category?: string;
};

export type CoursesDTO = Record<
  string,
  Record<string, { image: string; title: string }>
>;

export enum Interests {
  WOMEN_HEALTH = 'womenHealth',
  MEN_HEALTH = 'menHealth',
  ANALYZES_AND_EXAMINATIONS = 'analyzesAndExaminations',
  NUTRITION_AND_LIFESTYLE = 'nutritionAndLifestyle',
  PSYCHOLOGY = 'psychology',
  RISK_FACTORS = 'riskFactors',
  CHILD_DEVELOPMENT_BY_WEEKS = 'childDevelopmentByWeeks',
  CHILDBIRTH_AND_RECOVERY = 'childbirthAndRecovery',
  CHILD_HEALTH = 'childHealh',
  CHILD_DEVELOPMENT = 'childDevelopment',
  SEX_AND_CONTRACEPTION = 'sexAndContraception',
}

export type CertificateWAEB = {
  id: string;
  firstName: string;
  lastName: string;
  createdAt: number | null; // timestamp
  country: string;
  town: string;
};

export type ProgressWAEB = {
  block: string;
  course: string;
};
