import { useTranslate } from 'hooks/useTranslate';
import styles from './SearchValidationMessage.module.css';

export const SearchValidationMessage = () => {
  const t = useTranslate();

  return (
    <div className={styles.messageContainer}>
      <p className={styles.quantity}>{t('add_more_symbols')}</p>
    </div>
  );
};
