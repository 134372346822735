import classNames from 'classnames';
import { useAuth } from 'features/Auth/hooks/useAuth';
import { useTranslate } from 'hooks/useTranslate';
import { useUser } from 'hooks/useUser';
import { AppRoutes } from 'navigation/constants';
import { CSSProperties, FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './JoinButton.module.css';

export const JoinButton: FC<{
  isScrolled: boolean;
  containerStyle?: CSSProperties;
}> = ({ isScrolled, containerStyle }) => {
  const t = useTranslate();
  const navigate = useNavigate();

  const { logout } = useAuth();
  const { user } = useUser();

  const handleClick = useCallback(() => {
    if (user.id) {
      navigate(AppRoutes.PROFILE_SCREEN);
    } else {
      navigate(AppRoutes.LOGIN);
    }
  }, [logout, navigate]);

  return (
    <button
      className={classNames(styles.container, isScrolled && styles.scrolled)}
      onClick={handleClick}
      style={containerStyle}
    >
      {!!user.id ? t('profile_title_page') : t('common-join')}
    </button>
  );
};
