import { CourseInfoBlocks } from 'components/CommonCoursesCategory/components/CourseInfoBlocks/CourseInfoBlocks';
import { CoursesHeader } from 'components/CoursesHeader/CoursesHeader';
import { EmptyCourses } from 'components/EmptyCourses/EmptyCourses';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import { getWeeksAndDays } from 'features/Profile/utils';
import { useCoursesApi } from 'hooks/useCoursesApi';
import { useTranslate } from 'hooks/useTranslate';
import { useUser } from 'hooks/useUser';
import { AppRoutes } from 'navigation/constants';
import {
  getChildAge,
  getChildMonth,
  getItemsByChildMonth,
  getItemsByPregnancyWeek,
  getItemsByTopic,
} from 'pages/MainPage/MainPage.utils';

import { RecommendationType, UsageOptions } from 'types';
import { getType, optional } from 'utils/other';
import styles from './MainPageAdvice.module.css';
import CourseEmptyWrapper from 'components/CommonCoursesCategory/components/CourseEmpty/CourseEmptyWrapper';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import {
  watchHomePostEvent,
  watchHomeAdviceEvent,
  watchHomeCourseEvent,
} from 'analytic/trackEvents';
import { useEffect } from 'react';

function MainPageAdvice() {
  const type = getType();
  const t = useTranslate();

  // @ts-ignore
  const { loading, courses } = useCoursesApi(type) || {};

  useEffect(() => {
    if (type === RecommendationType.POST) {
      watchHomePostEvent();
    }
    if (type === RecommendationType.ADVICE) {
      watchHomeAdviceEvent();
    }
    if (type === RecommendationType.COURSE) {
      watchHomeCourseEvent();
    }
  }, [courses]);

  const { user } = useUser();
  const { dateOfChildBirth, dateOfPregnancy, usageOption } = optional<{
    usageOption: UsageOptions;
    dateOfPregnancy: number;
    dateOfChildBirth: number;
  }>(user);

  const [_, pregnancyWeek] = getWeeksAndDays(dateOfPregnancy);

  const childAge = getChildAge(dateOfChildBirth!);
  const childMonth = getChildMonth(dateOfChildBirth);
  const isPregnant = usageOption === UsageOptions.MANAGING_PREGNANCY;
  const isPlanningPregnancy = usageOption === UsageOptions.PLANNING_PREGNANCY;
  const isChildLessYear =
    usageOption === UsageOptions.AFTER_CHILDBIRTH && childAge < 1;
  const isChildOverYear =
    usageOption === UsageOptions.AFTER_CHILDBIRTH && childAge >= 1;
  const { isMobile, isTablet } = useAppWindowSize();
  const filtered = isPlanningPregnancy
    ? getItemsByTopic(courses, 'planning-pregnancy')
    : isPregnant
    ? // @ts-ignore
      getItemsByPregnancyWeek(courses, pregnancyWeek)
    : isChildLessYear
    ? getItemsByChildMonth(courses, childMonth)
    : isChildOverYear
    ? getItemsByTopic(courses, 'child-month-age-12-and-over')
    : [];
  // @ts-ignore
  const filteredWithEnabled = filtered.filter((item) => item?.enable);

  if (!user?.id) {
    return (
      <>
        <CourseEmptyWrapper
          isTablet={isTablet}
          isMobile={isMobile}
          title={t(type || '')}
        />
      </>
    );
  }
  return (
    <div className={styles.background}>
      <Header styled />

      <div className={styles.container}>
        <CoursesHeader title={t(type || '')} to={AppRoutes.MAIN} />
        {!!filteredWithEnabled.length ? (
          <CourseInfoBlocks content={filteredWithEnabled as any} />
        ) : (
          <EmptyCourses />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default MainPageAdvice;
