import { FC } from "react";
import styles from './InterestsPicker.module.css';
import { INTERESTS, Interests, UsageOptions } from "constants/constants";
import { useTranslate } from "hooks/useTranslate";
import { CheckIcon } from 'assets/icons';
import classNames from "classnames";


export const InterestsPicker: FC<{
  usageOption: UsageOptions,
  selectedInterests?: Interests[];
  onChange: (value: Interests) => () => void,
}> = ({
  usageOption,
  selectedInterests = [],
  onChange,
}) => {
  const t = useTranslate();

  return (
    <div className={ styles.container }>
      {
        INTERESTS[usageOption].map(({ title, icon: Icon }) => (
          <div
            key={ title }
            className={ classNames(
              styles.wrapper,
              selectedInterests.includes(title) && styles.selected
            ) } 
            onClick={ onChange(title) }
          >
            <div>
              { t(`${title}`) }
            </div>
            <div className={ styles.icon }>
              <Icon width={ '100%' } height={ '100%' }/>
            </div>
            <div className={ styles.check }>
              <CheckIcon /> 
            </div>
          </div>
        ))
      }
    </div>
  );
};
