import { ArrowBottom } from 'assets/icons';
import classNames from 'classnames';
import { useTranslate } from 'hooks/useTranslate';
import { COUNTRIES } from 'localization/countries/countries';
import React, { useRef, useState } from 'react';
import styles from './SelectCountry.module.css';

interface CountrySelectProps {
  value: string;
  error: boolean;
  onChange: (value: string) => void;
}

const SelectCountry: React.FC<CountrySelectProps> = ({
  value,
  onChange,
  error,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const t = useTranslate();
  const selectRef = useRef(null);

  return (
    <div
      className={classNames(
        styles.container,
        error && styles.containerError,
        (isFocused || value) && styles.focused
      )}
    >
      <select
        value={value}
        onChange={(event) => onChange(event.target.value)}
        className={!value ? styles.customSelectEmpty : styles.customSelect}
        ref={selectRef}
      >
        <option value="">{t('settings_user_info_country')}</option>
        {COUNTRIES.map((country) => (
          <option key={country.localLabel} value={country.localLabel}>
            {country.localLabel}
          </option>
        ))}
        <ArrowBottom color={!value ? 'red' : 'black'} />
      </select>

      <div className={styles.icon}></div>
    </div>
  );
};

export default SelectCountry;
