import { CommonCoursesCategory } from 'components/CommonCoursesCategory/CommonCoursesCategory';
import CourseEmptyWrapper from 'components/CommonCoursesCategory/components/CourseEmpty/CourseEmptyWrapper';
import { CoursesHeader } from 'components/CoursesHeader/CoursesHeader';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import { useAllBookedCourses } from 'hooks/useAllBookedCourses';
import { useAllCourses } from 'hooks/useAllCourses';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { useTranslate } from 'hooks/useTranslate';
import { useUser } from 'hooks/useUser';
import { AppRoutes } from 'navigation/constants';
import { FC, useEffect, useState } from 'react';
import styles from './FavoritePage.module.css';

export const FavoritePage: FC = () => {
  const t = useTranslate();

  const { userId } = useUser();
  const { bookedAllCourses } = useAllBookedCourses(userId);
  const { loading } = useAllCourses();

  const [loadingNotStarted, setLoadingNotStarted] = useState(
    !bookedAllCourses?.length
  );

  const { isTablet, isMobile } = useAppWindowSize();

  useEffect(() => {
    setLoadingNotStarted(false);
  }, [loading]);
  if (loading || loadingNotStarted) {
    return (
      <CourseEmptyWrapper
        isTablet={isTablet}
        isMobile={isMobile}
        title={t('profile_favorites')}
      />
    );
  }

  return (
    <div className={styles.background}>
      <Header styled />
      <div className={styles.container}>
        <CoursesHeader
          title={t('profile_favorites')}
          to={AppRoutes.PROFILE_SCREEN}
        />
        <CommonCoursesCategory
          content={bookedAllCourses}
          isFavorite
          title={t('profile_favorites')}
        />
      </div>
      <Footer />
    </div>
  );
};
