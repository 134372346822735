import { separator, tb2c5_1, tb2c5_2, tb2c5_3, tb2c5_4 } from 'assets/images';

export const b2_c7_text = {
  b2_c5_title: `Курс « Беременность и спорт: комплекс физических упражнений»`,
  b2_c5_description: `Спикер: Елена Цыбулька, фитнес-тренер`,
  b2_c7_text: `
    ${tb2c5_1}

    Урок 1. Физические нагрузки во время беременности: можно и нельзя

    Раньше беременных часто сравнивали с хрустальной вазой и советовали ни в коем случае мы не совершать лишних движений, не поднимать руки вверх, беречь себя, ведь нагрузка на организм и так повышается. И сейчас многие врачи перестраховываются и говорят, что никакой физической активности кроме прогулок будущим мамам не нужно. Но!
    
    Всемирная Организация Здравоохранения заявляет: «Регулярные физические упражнения  — основной компонент мероприятий по изменению образа жизни, которые рекомендуются беременным для профилактики избыточной прибавки в весе». 
    
    Физическая нагрузка:
    
    снижает риск гестационного диабета, преждевременных родов
    способствует более легкому протеканию беременности
    поможет чувствовать себя комфортно, уверенно физически и психологически
    поможет уменьшить проявления возможных спутников беременных женщин, таких как отечность, запоры, геморрой, бессонница, варикоз, лишний вес и другие. 
    На каких сроках можно заниматься физической активностью?
    
    Когда женщина только планирует стать мамой, ей рекомендуется заняться укреплением мышц брюшного пресса, спины и тазового дна. На протяжении всей беременности очень важно регулярное пребывание на свежем воздухе. Заниматься фитнесом можно практически до самых родов, корректируя нагрузку в зависимости от срока.
    
    1 триместр
    
    Выбирайте щадящие занятия – это зарядка, прогулки. Не стоит заставлять себя делать то, чего не хочется. В первый триместр могут быть явления токсикоза, рвоты, тошноты. В организме беременной идут очень сильные изменения гормонального фона и других процессов. Повторюсь, если у вас нет желания тренироваться, то можно вполне ограничиться прогулками и легкой зарядкой.
    
    2 триместр
    
    Можно приступать к более интенсивным тренировкам. Добавляются упражнения, которые увеличивают силу и выносливость всех мышечных групп, а также подвижность тазобедренных суставов. 
    
    3 триместр
    
    Особое внимание уделяется различным дыхательным практикам, а также щадящим упражнениям.
    
    Если вы занимались фитнесом до беременности, то не стоит резко снижать активность. В разных странах есть разные протоколы и рекомендации. Основное – это снижение физической нагрузки к третьему триместру до 20-30% от той, что была до беременности (то есть уменьшать в 3-5 раз).
    
    Если же вы не занимались спортом до беременности, то начинать нужно постепенно. Конечно, это не период для освоения новых серьезных физических навыков.
    
    Хочется подчеркнуть, что физическая активность в этот прекрасный период –  это не просто хороший тонус мышц – это и хорошее настроение и самооценка. Что крайне важно для любого периода жизни. Хорошее настроение – это эндорфины, гормоны счастья, которые вырабатываются при занятиях фитнесом. 
    
    Повышая свою физическую активность, мы повышаем и улучшаем исход всей беременности. Конечно, сейчас речь идет о здоровых женщинах о тех, у кого нет противопоказаний к физическим нагрузкам.
    
    Обратите внимание: если женщина ВИЧ-позитивна, принимает какие-либо сильнодействующие препараты, то для решения вопроса о занятиях спортом необходимо проконсультироваться с врачом. Обсудите с ним ваше желание заниматься и найдите те виды активности, которые допустимы для вас, может быть это будет пилатес, йога или плавание под руководством специализированного тренера.
    
    Противопоказания к физической активности во время беременности
    
    Обратите внимание: прежде чем заниматься любой активностью, рекомендуем вам проконсультироваться с врачом. 
    
    Важно удостовериться, что вам можно заниматься определенным видом спорта или активности. Ответственность за ваше здоровье и здоровье вашего ребенка полностью лежит на вас.
    
    Крайне не рекомендуется:
    
    выполнять упражнения с чрезмерным натуживанием
    упражнения, от которых у вас кружится голова
    упражнения в быстром темпе
    Хочу обратить ваше внимание на то, что чрезмерная нагрузка во время физической активности может способствовать появлению различных проблем (отслоение плаценты, тонус матки, угроза выкидыша). Об этом нужно помнить, поддерживать легкий темп, отдыхать, пить побольше водички, проветривать помещение. Одним словом, делать так, чтобы ваша тренировка была комфортна и приятна для вас. 
    
    Тренировки во время беременности противопоказаны, если у вас:
    
    Угроза выкидыша. Это то, о чем вам будет говорить доктор и чем вам не стоит пренебрегать. 
    Гинекологические заболевания, которые вы обсуждаете со своим доктором. Если он вам объективно не разрешает, значит, вам не стоит выполнять тренировки. 
    Перечислю основные случаи, когда особенно важно слушать рекомендации врача: 
    
    Отслойка плаценты или предлежание плаценты.
    Кровотечения на протяжении беременности.
    Слабость шейки матки.
    Замедленный рост плода.
    Маловодие.
    Гипертония (в том числе возникшая во время беременности).
    Признаки преждевременных родов или подтекания околоплодных вод.
    А также многоплодная беременность.
    Не рекомендуется нагружать свой организм очень сильно. Фитнес во время беременности направлен не на то, чтобы вы не набирали вес, а на то, чтобы вы поддерживали себя в тонусе. Наша цель – чтобы вам было легче выносить ребенка, чтобы у вас было меньше болевых ощущений и чтобы вы легко справились с процессом родов.  
    
    Запрещенные виды спорта во время беременности
    
    виды спорта, при занятиях которыми можно получить удар в область живота. И это не только боевые искусства! Случайный толчок от партнера при игре в волейбол, неудачная попытка отразить летящий мяч на теннисном корте — это все факторы риска.
    дисциплины, связанные с повышенным риском травм. Переломы, вывихи, разрывы связок могут потребовать лечения с применением анестезии и антибиотиков, что крайне нежелательно для будущей мамы. Катание на коньках (и на роликовых в том числе) придется на время отложить.
    физические нагрузки, при которых вы можете испытывать кислородное голодание. Про прыжки с парашютом, дайвинг, катание на лыжах на высокогорных курортах пока придется забыть.
    сильная тряска и ударные нагрузки на внутренние органы. Ответ на вопрос, можно ли беременным заниматься конным спортом, однозначен. Можно приходить в клуб и ухаживать за своим любимцем, но конные прогулки советуем прекратить. То же самое относится и к езде на велосипеде по пересеченной местности.
    бег, ударные нагрузки, осевые нагрузки, различные силовые тренировки с большими весами. 
    
    Рекомендуемые виды спорта
    
    Плавание. Если у вас есть возможность посещать во время беременности бассейн – это очень прекрасно, в воде разгружаются мышцы спины и позвоночник. В воде вы не будете чувствовать тяжести своего тела, вам будет комфортно и очень приятно. Недаром есть роды в воде, ведь это естественно для нашего организма. 
    Йога. Есть специальная йога для беременных, есть стретчинг. Советуем заниматься этим вместе с инструктором, потому что многие позы из йоги противопоказаны беременным, особенно перевернутые. А также примерно с середины срока беременности в нашем организме активно вырабатывается гормон релаксин, который способствует расслаблению мышц и связок. Если вы будете сильно растягивать мышцы, это может привести к тому, что они могут травмироваться. Поэтому будьте очень аккуратны с растяжками и йогой в этот период, не стоит ставить целью сесть на шпагат.
    
    Фитнес. Он объединяет в себе много направлений, но стоит обратить внимание на домашний фитнес.

    ${separator}

    Урок 2. Основные советы и правила для беременной во время тренировки

Независимо от вашего режима физических нагрузок до беременности, советую вам соблюдать определенные правила. Они помогут сохранить ваше здоровье и здоровье вашего малыша

Правило 1. Регулярность 

Регулярные умеренные тренировки полезнее, чем интенсивные и нерегулярные. Будет здорово составить график ваших занятий. Например, 2-3 раза в неделю, чтобы для вашего организма физическая активность не была стрессом. 

Нужно понимать, что, если вы проводите тренировку 1 раз в неделю или 1 тренировку в 2 недели, вы оказываете своему организму медвежью услугу – он получает стресс. А нам во время беременности это абсолютно не нужно. Поэтому задаем себе тренировочный график, стараемся его придерживаться.

Если вы занимались спортом до беременности, можно продолжать обычные тренировки, снижая нагрузку и ориентируясь на самочувствие и рекомендации вашего врача. Однако, если до беременности вы не занимались спортом и работа у вас была преимущественно сидячая, начинайте занятия не резко, а очень плавно, и постепенно приучайте себя к тренировочному процессу.

Правило 2. Самоконтроль 

Прислушивайтесь к себе и старайтесь понимать, как вы себя чувствуете. Ведь вы делаете это для себя и для своего ребенка. 

если у вас появились неприятные ощущения внутри живота
если живот стал твердым
если вы чувствуете  тянущие боли, головокружение, покраснения или побледнения
если вы чувствуете себя не очень хорошо
Обязательно отложите тренировку, откройте окно, подышите свежим воздухом!

Долгих сидений или стояний лучше избегать. Нужно пройтись, подышать свежим воздухом, постоять возле открытого окна. Я думаю, вам это обязательно поможет.

Если вы и дальше будете чувствовать себя не очень хорошо, обратитесь к врачу. И советуем прекратить тренировочный процесс. 

Правило 3. Тренировочная форма 

Старайтесь выбирать максимально комфортную. Ведь в это время нам не столько нужны красивые фитнес-силуэты, сколько нам нужен комфорт и удобство. Обязательно советуем вам использовать в 1-м и 2-м триместре обувь, если вы делаете упражнения, стоя на полу. Ваша стопа обязательно должна быть хорошо зафиксирована. Уже в 3-м триместре проходят более щадящие тренировки и можно заниматься в носочках. Это более простые растягивающие упражнения. 

Правило 4. Режим отдыха

Советуем вам обязательно восстанавливаться. Во время беременности женщины и так испытывают колоссальную нагрузку на организм, и дополнительная нагрузка нам совершенно не нужна. Тренировки должны быть в радость.  Поэтому не забываем, восстанавливаемся, отдыхаем, не забываем про наш сон. После того как вы выполнили тренировку, хорошо немножко полежать (минут 5-10), порелаксировать, расслабиться, восстановить ваше дыхание. Это будет очень и очень полезно для вас и для вашего малыша. 

Правило 5. Обязательно делайте разминку

Говорят, что лучше сделать плохую тренировку, но хорошую разминку.  Мы разминаем наши связки, сухожилия, гоняем кровь, обогащаем себя кислородом. 

Во время беременности движения зачастую становятся более скованными, неуклюжими. Наша растяжка теряет свою эластичность, мышцы становятся другими. Но именно физическая активность и тонус помогут вам чувствовать себя хорошо, чувствовать свои мышцы, что необходимо во время родового процесса. 

Беременность – это период, когда нужно заботиться о своем весе, но не пытаться его удерживать. Часто женщины переживают из-за того, что набирают лишние килограммы. Однако, по данным Всемирной организации здравоохранения, нормальная прибавка в весе – от 7 до 15 кг, и эта цифра варьируется в зависимости от вашего веса ДО беременности. То есть чем больше ваш вес, тем меньше должна быть прибавка в весе. В среднем женщины с нормальным индексом массы тела набирают от 12 до 15 кг.

Большую часть набранного веса составляет ребенок, околоплодные воды. Организм наш перестраховывается, и он не может не увеличиваться в весе.

Правило 6. Дыхание

Дыхание должно быть ровным. Не задерживайте его во время выполнения упражнений, как бы тяжело вам ни было. Старайтесь сосредоточиться на вдохе и на выдохе. 

Правило 7. Пейте воду

Во время беременности женщины часто ограничивают себя в питье, чтобы не получить отеки. Но пить воду важно. Частое дробное питье поможет избежать обезвоживания. 

Единственная рекомендация – если вы проводите тренировку в вечернее время в конце дня, не увлекайтесь водой, чтобы не получить те самые отеки утром. 

Правило 8. Наблюдайте, как бьется ваше сердце, следите за пульсом 

Он не должен превышать 140 ударов в минуту. Как это понять, если вы не носите пульсометр? Вы будете чувствовать одышку, кровь прильет к лицу, вы почувствуете жар. В это время советую остановиться, открыть окно, вдохнуть свежий воздух, успокоить свое дыхание и сердцебиение. Помните о том, что во время тренировки хорошо заниматься при открытом окне, с притоком свежего воздуха. 

Очень часто женщины во время беременности испытывают нехватку воздуха, нехватку кислорода. Во время беременности кислород очень важен. Следите обязательно за этим. 

Также еще несколько простых и важных правил:

Избегайте перегрева. У беременных женщин нарушается механизм терморегуляции. Перегрев может навредить малышу и также вызвать у вас  плохое самочувствие. 
Избегайте упражнений, которые выполняются лежа на спине в течение длительного времени. Ближе к середине 2-го триместра матка увеличивается в размерах и в положении лежа сдавливает полую вену, перекрывая циркуляцию крови и снабжение ею плода.
Не старайтесь пересилить себя, если обычная, казалось бы, вам нагрузка становится утомительной. Прислушивайтесь к себе и к своему телу, измените режим занятий и нагрузки так, чтобы чувствовать себя комфортно.

Настоятельно рекомендую прекратить тренировку и по возможности связаться с вашим врачом, если вы отмечаете любой из следующих симптомов:

если у вас сильно затрудненное дыхание;
вагинальное кровотечение;
ваш пульс больше 160 ударов в минуту;
сильное головокружение и внезапная слабость;
острая боль в любой локализации.

${tb2c5_2}

Урок 3. Разминка/зарядка для будущей мамы

Комплекс упражнений для разминки

Повороты головы
Ставим ноги на ширину плеч. Лучше всего использовать обувь, чтобы свод вашей стопы, голеностопный сустав был зафиксирован. Ноги немного присогнуты в коленях, чтобы снять напряжение с коленных суставов. Руки ставим на пояс и делаем простейшие движения для разминки нашей шеи, поворачиваем влево и вправо. Поворот влево и вправо, резких движений не делаем – мышцы шеи этого не любят.

Теперь пытаемся дотянуться ухом до плеча, до правого и до левого. Следите за вашим дыханием, не задерживайте его, пускай оно будет плавным и ровным. 

Разминка мышц плечевого пояса
Разминаем с вами плечевой пояс, делаем вращательные движения назад. Такие движения вы можете делать на протяжении всего дня. Когда чувствуете, что у вас начинается затекание мышц плечей, мышц шеи, когда вы долго сидите либо, может быть, читаете, смотрите в телефон. Они снимают напряжение, разгоняют кровь – делайте их часто. Тем более, когда вы уже станете молодыми мамами, эта часть нашего тела получает максимальную нагрузку от ношения ребеночка. 

Наклоны
Дальше поднимаем ручку вверх и делаем наклоны в одну сторону и в другую. Такие упражнения важно делать для растяжения боковой поверхности нашего туловища. Тут у вас будет животик в зависимости от вашего срока беременности, но это никак не повредит вашему малышу.

Далее ставим с вами ручки на талию и делаем вращательные движения туловищем. Можно руки вывести вперед, для того чтобы немножечко тоже укреплять боковую поверхность нашего туловища, боковые мышцы пресса.

Если вы чувствуете головокружение либо ваше дыхание как-то изменилось, делайте более плавно, не задерживайте дыхание. 

Вращательные движения
Поднимаем по очереди ноги, сгибаем в колене и делаем вращательные движения. Нам очень важно, чтобы был прилив крови к нашим тазобедренным суставам, к органам малого таза. Если вам сложно удерживать равновесие, вы можете опереться о стенку или, например, спинку стула.

Вращательные движения тазом
В зависимости от срока беременности, у вас будут немного различаться движения, но в принципе мы с вами вполне способны двигать тазом на любом сроке. Помните, что подвижность тазобедренных суставов, а также кровоснабжение органов малого таза – очень-очень важно.

Разминка коленей
Колени получают тоже достаточно сильную нагрузку во время беременности, поэтому их нужно разминать. Делаем вращательные движения наружу и внутрь. Когда мы с вами двигаем нашими суставами, они обогащаются  кровью. Соответственно туда поступают кислород и питательные вещества. И очень важно не лежать, а больше двигаться во время беременности. 

Разминка голеностоп
Очень важно во время беременности прорабатывать свод стопы. Это уже можно делать без обуви. Разворачивайте стопы (будто поза «косолапости») и «пружиньте» в таком состоянии. Далее встаете на пятки – ходите на пятках, встаете на носочки – ходите на носочках. 

Упражнения для снятия нагрузки с мышц спины

Упражнение «Кошка»

Становимся на колени. Следим, чтобы между суставами (тазобедренным и коленным) был угол 90 градусов. Опускаемся и занимаем такую позицию, чтобы все четыре точки опоры были у вас хорошо зафиксированы. Теперь выгибаем спину вверх, имитируем кошку. Обычно беременные женщины очень любят это упражнение, так как оно действительно помогает снять напряжение и расслабить мышцы. 

Далее выгибаем спину обратно. Не старайтесь очень сильно, чрезмерно сделать прогиб в спине – настолько, насколько вам комфортно, насколько будет позволять вам ваш увеличивающийся живот. Делайте это упражнение медленно, голову вниз не опускайте, старайтесь смотреть либо вперед, либо по диагонали перед собой.

Баланс. Поднятие противоположных руки и ноги

Упражнение будет помогать снимать напряжение со спины. И также в статической нагрузке будет немного подгружать мышцы, чтобы в дальнейшем спина не беспокоила нас при увеличении размера животика.

4 точки опоры. Поднимаем сначала руку, чувствуем равновесие и теперь нам нужно поднять разноименную ногу. 

Расслабление

Усаживаемся удобно на пятки. Можно шире расставить ноги либо сесть на пятки, сделать вращательные движения плечами, поднять руки вверх, потянуться, опустить руки сзади и немного прогнуться. Побудьте в такой позе, подышите, расслабьтесь.

Планка на предплечьях

Можно делать планку и на прямых руках (подходит для тех, кто занимался до беременности).

Становимся на предплечья. Смотрим, чтобы везде были углы 90 градусов (в плечевом суставе относительно пола). Если живот уже достаточно большой, можно немножко приподнять таз вверх. Если пока животик не перетягивает, можно держать ровную классическую планку. Стоим недолго – примерно на 3-5-7 дыхательных циклов. 

После сделайте классическое расслабление из йоги в позе ребенка. Опускаемся настолько, насколько будет позволять животик. Ноги можно немного расставить шире, опустить таз, руки вытянуть вперед и опустить голову. В таком положении либо женщинам рекомендуется расслабляться несколько раз в день. Потому что чем больше у вас срок беременности, тем больше у вас будут напрягаться мышцы спины.

Дыхательные упражнения 

Их польза состоит в том, что у беременных:

Улучшается кровообращение. А это способствует тому, что малыш получает больше кислорода, снижается риск гипоксии, т.е. нехватки кислорода.
Снимается психологическое напряжение мамы.
Улучшается и стимулируется работа органов и систем организма.
Во время схваток и родов акушеры будут сосредотачивать ваше внимание на дыхании. И будет очень здорово, если вы будете знать, как контролировать и регулировать этот процесс. 

Рекомендуется уделять дыхательным техникам примерно 10 минут в день.

Упражнение 1. Дыхание животом

Садимся в удобную позу. Если живот начинает немного перевешивать, можно под ягодицы положить подушку. Одну руку кладем вот на грудь, а вторую – на солнечное сплетение. И начинаем дышать животом, не поднимая грудную клетку. Вдох – и медленный выдох. Делаем 5-6-7 дыхательных циклов.

Упражнение 2. Разведение ребер

Укладываем ручки на ребра и при дыхании представляем, как будто наши ребра расходятся как гармошка. Делаем вдох, надувая живот (представляя, как он увеличивается), и выдох максимально сжимаем, выдыхаем. Нужно выполнить тоже примерно 5-7 дыхательных циклов.

Упражнение 3. Полное дыхание

Это дыхание будет помогать вам дышать полной грудью, раскрывать ваши легкие. Такое дыхание хорошо использовать, когда вы находитесь на природе,  когда вам хочется надышаться свежим воздухом. Его можно выполнять либо сидя, либо стоя. Это называется полное дыхание (либо еще называют его «подсолнух»).

Итак, поднимаем ручки, делаем вдох. Делаем глубокий вдох, чувствуя, как раскрывается наша грудная клетка и легкие наполняются воздухом, и делаем выдох. Выдыхаем через губы, сложенные трубочкой, как будто вы хотите задуть свечу примерно в метре от вас.

Такие дыхательные практики хорошо делать в конце вашей тренировки, чтобы восстановить дыхание. Но и также, когда вы испытываете какой-либо психологический стресс или нагрузку и вам хочется немножечко стряхнуть это состояние, используйте обязательно эти практики.

Обратите внимание: если у вас есть астма или другие серьезные заболевания органов дыхания, обязательно обратитесь за консультацией к своему лечащему врачу, прежде чем выполнять любой курс дыхательных упражнений.

${separator}

Урок 4. Тренировка в первом триместре 

В период первого триместра в организме женщины начинают происходить колоссальные изменения. Начинает меняться гормональный фон, организм начинает подстраиваться под те или иные процессы, которые происходят внутри. И девушки очень часто испытывают усталость, утомляемость. Обычно в этот период чаще всего проявляется токсикоз – тошнота по утрам, головокружение, плохое настроение. И, конечно, в таком состоянии заниматься физической активностью достаточно сложно. Но если вы хорошо себя чувствуете, если у вас нет обморочных состояний и вам хочется провести  немного времени с пользой для своего тела, то эта тренировка для вас. 

Важно прислушиваться к своему организму. Помните: если вы чувствуете какое-то недомогание, если у вас появились неприятные ощущения в теле, то тренировку стоит закончить.

Длительность тренировки – примерно 30-40 минут. 
Дыхание должно быть ровным. 
Желательно открыть окно, чтобы был приток свежего воздуха. 
Не забывайте пить воду.
Приседания плие

Желательно для выполнения упражнения взять какую-нибудь опору (например, гимнастическую палку, палку от швабры, это может быть спинка стула, стена). 

Ставим ноги шире плеч, носки разворачиваем под углом 45 градусов. При приседании стараемся разводить ваши колени так, чтобы они смотрели ровно в одной плоскости за вашими носками. У вас будет напрягаться внутренняя поверхность бедра. 

Приседаем на вдохе и делаем выдох. Делаем вдох плавно, опускаемся и чуть быстрее встаем. Когда вы выполняете упражнения, давите пятками в пол, не переносите вес тела на ваши носки. Сделать 10-12 таких повторений. 

«Кошка» стоя

Для этого упражнения нужно встать и найти опору. Немного отойдите от нее и округлите максимально спину. Если это будет стена, упирайтесь ладонями.

На выдохе скруглили спинку – на вдохе прогнули ее.

Молитвенная поза

Это упражнение для укрепления мышц груди. Во время беременности грудь у женщин начинает расти, и зачастую это приносит дискомфорт. 

Ставим ноги на ширину плеч, колени немного сгибаем и делаем молитвенную позу. Это очень простое упражнение работы с собственным весом. Сложили руки – и начинаем давить ладошкой на ладошку. Сильно надавливаем на вдохе, на выдохе – расслабляемся.

Наклоны в сторону

Ставим ноги на ширине плеч, одну руку поднимаем вверх и наклоняемся к противоположной ноге. Вы будете чувствовать, как растягивается боковая поверхность туловища, напрягаются мышцы пресса. Делайте все движения плавно.

Подъем ноги лежа на боку

Это упражнение на укрепление бедер и ягодиц. Ложимся на бок. Нижнюю ногу сгибаем в колене и выводим ее вперед. Опора – на локоть и на свободную ручку. Наша задача – поднимать ногу (примерно на 45 градусов), чтобы максимально напрягалась внешняя поверхность нашего бедра (зона галифе). Ногу выворачиваем так, чтобы пятка смотрела вверх. 

На выдохе – поднимаем, на вдохе – опускаем. Сделайте 15-20 повторений.

Подъем ноги лежа на боку

Это проработка зоны внешней поверхности бедра. Приподнимаем ножку и представляем, что стопой толкаем невидимую стену (делаем это на выдохе). Можно делать от 10 до 20 таких повторений.

Отведение ноги назад на четвереньках

Это упражнение для укрепления ягодиц. Становимся на 4 точки опоры, отводим ногу назад и делаем пружинящие движения на 3 счета. 

«Поза ребенка» 

Это упражнение для расслабления. В таком положении вы полностью расслабляетесь, релаксируете, восстанавливаете дыхание.

Этот комплекс упражнений вы можете повторять по кругу (3-4 цикла), ориентируясь по собственному самочувствию.

${separator}

Урок 5. 2 триместр: комплекс упражнений

Врачи говорят, что 2-й триместр – самый благоприятный период для физической активности.

После 20-й недели начинается активный рост плода, увеличивается живот. Этот комплекс упражнений можно выполнять и в начале 2-го триместра, и в конце. Он направлен на проработку всего тела, укрепит ваши мышцы. Во 2-м триместре очень важно нам особое внимание уделить мышцам рук, ног и спины. 

Почему именно этим мышцам? Уже в 3-м триместре наша спина начнет испытывать серьезную нагрузку, и нам нужно к этому подготовиться. Наши ноги, а в частности мышцы ягодиц, будут задействованы в детородном процессе. Плюс тонус мышц будет препятствовать отеку ног. А наши руки очень скоро почувствуют вес новорожденного малыша и тоже получат нагрузку. 

Вращение рук

Вы занимаете удобную для вас позицию. Расставляем руки на уровне плеч и начинаем вращать, заворачивая ладони сначала внутрь. Вы почувствуете, как при этом у вас выворачиваются лопатки и плечи уходят вперед. Старайтесь не сутулиться, не вжимать шею в плечи, а именно выворачивать лопатки.

Можно взять гантели либо заменить их пол-литровыми бутылками с водой. 

Упражнение на трицепс: разгибание рук

Берем гантель в руку, руку заводим назад и разгибаем. Второй рукой придерживаем рабочую руку. На выдохе разгибаем, гантель заводим за голову. Следим за тем, чтобы она опускалась плавно и именно за головой. На вдохе – опускаем, на выдохе – разгибаем. Локоть до конца не разгибаем,  чтобы снять напряжение с локтевого сустава. 

Если вам тяжело либо у вас тяжелая гантель, можно взять ее в две руки, одновременно завести руки за голову. Тогда в этой позиции нужно прижать локти к ушам, не разводить их в стороны во время выполнения упражнения.

Подъем ноги лежа на боку

Занимаем позицию лежа на боку. Опорная нижняя нога согнута, верхняя нога – рабочая. Поднимаем ее таким образом, как будто у нас находится какая-то возвышенность, горочка, что-то лежит и мешает. На выдохе – подняли, на вдохе – опустили. Не заваливайтесь вперед-назад – тело должно быть в ровной линии.

Вторым упражнением мы выпрямляем обе ноги, чуть их приподнимаем и делаем режущие движения в воздухе. Вы почувствуете, как работает внешняя поверхность бедра, как начинает напрягаться пресс. Следите за тем, чтобы пресс не перенапрягался, чтобы вы не чувствовали сильных тянущих болей. Если есть такие ощущения – упражнение останавливаем.

Делаем 10-15 повторений. 

Наклоны сидя с широко разведенными ногами

Для выполнения следующего упражнения мы широко разводим ноги – настолько, насколько позволяет ваша растяжка, но без болевых ощущений. Ручку заводим вверх и наклоняемся к противоположной ноге.

Не забывайте выполнять и в другую сторону (на выдохе). Дыхание свободное, спокойное. Выполняем 15-20 наклонов в каждую сторону.

Упражнение «Кошка» 

Становимся на 4 точки опоры. Ноги расставляем достаточно широко, чтобы вам было комфортно стоять. Выгибаем спину на выдохе и делаем прогиб на вдохе. Сильный прогиб выполнять не нужно. Также помните, что животик будет тянуть вас вниз. Держите его, старайтесь немного придерживать мышцами пресса, но не перенапрягать. Делаем 5-7 дыхательных циклов.

«Поза ребенка» 

Расслабляемся в знакомой нам позе ребенка. Дышим ровно, восстанавливаем дыхание.  

Не рекомендуется 

выполнять упражнения в положении лежа на спине
отдыхать на спине

Ребенок уже достаточно большой, его вес будет давить на нижнюю полую вену. Вы будете плохо себя чувствовать, получать плохой приток кислорода к тканям и, соответственно, к мозгу. У вас может нарушиться дыхание, и вы можете плохо себя чувствовать.

${tb2c5_3}

Урок 6. 3 триместр: комплекс упражнений

В этот период по-прежнему очень важно внимательно следить за своим самочувствием. При выполнении упражнений:

открывайте окно
ставьте рядом с собой бутылочку с водой
отдыхайте между упражнениями
Помните, что в этот период беременности очень активно вырабатывается гормон релаксин. Он способствует тому, чтобы мышцы и связки стали более мягкими, и это, в свою очередь, способствует их перерастяжению. Резкие движения могут привести к тому, что вы их растянете и травмируете. Поэтому все упражнения мы с вами делаем плавно и в спокойном темпе.

В некоторых упражнениях можно использовать фитбол. Это надежный помощник как в период беременности, а также и после родов. Он будет вашим лучшим другом, потому что на нем очень удобно укачивать малыша. Размер фитбола нужно выбирать с учетом вашего роста. 

Сейчас фитбол пригодится нам для того, чтобы подготовить к родовой деятельности мышцы малого таза.

Вращательные движения на мяче

Садимся поудобнее, ноги ставим пошире и начинаем делать вращательные движения. По кругу в одну сторону (плавно) и в другую. Вы почувствуете расслабление в области органов малого таза, в области крестца, в области тазобедренных суставов

Делаем небольшую паузу, отдыхаем. Теперь качаем мяч вперед и назад. Двигаем на выдохе вперед, на вдохе – назад. Старайтесь, чтобы пресс не перенапрягался. Делайте примерно 5-10 дыхательных циклов и вращательных движений.

Плие на мяче

Будем перекатывать наш мяч, чтобы укреплять внутреннюю поверхность бедра. 

Итак, делаем перекаты в одну сторону, а затем – в другую. Ножка при этом становится на носок. У вас будет напрягаться внутренняя поверхность бедра, растягиваться, вы будете чувствовать мышцы вашей спины и, конечно, будут задействоваться мышцы пресса, чтобы удерживать наш корпус прямо. Делаем медленно, плавно.

Упражнение «бабочка» 

Садимся поудобнее на пол, складываем ноги. Ноги как можно ближе придвиньте к вашему животику (насколько вам это позволяет ваш животик). Складываем стопы вместе, удерживаем руками и делаем плавные движения, напоминающие нам крылья бабочки. Опускаем вниз, насколько можем, и делаем пружинящие движения. Здесь у нас с вами работает внутренняя поверхность бедра, здесь раскрываются и включаются в работу максимально тазобедренные суставы, что нам понадобится в детородном процессе.

Можно опустить ноги максимально вниз, зафиксировать, немножко подержать в статике. Избегайте болевых ощущений в тазобедренном суставе.

Молитвенная поза

Ставим ручки в молитвенной позе и давим на них. На выдохе – давим, на вдохе – расслабляемся. 

Помните о том, что увеличивающаяся в размере грудь может давать вам определенный дискомфорт и давать дополнительную нагрузку на мышцы спины, поэтому важно, чтобы мышцы груди тоже были в тонусе.

Упражнение на баланс. Поднятие противоположных руки и ноги

Становимся на 4 точки опоры, проверяем наш баланс. Выводим сначала одну ручку, потом выводим противоположную ножку. Затем делаем это одновременно (на выдохе). Следим за тем, чтобы взгляд был направлен вперед либо по диагонали.

«Кошка» на фитболе 

Упираемся локтями в фитбол. Можно положить голову вниз, можно держать ее наверху. На выдохе – скругляем спину, на вдохе – прогибаем спинку и немножко подкатываем фитбол к себе. Выполняем примерно 3-7 таких дыхательных циклов.

Расслабление на мяче

Рекомендую вам его делать как можно чаще на 3-м триместре беременности. Ложимся, занимаем такую позу, какая вам будет наиболее комфортна, и отдыхаем. Помните, что на 3-м триместре нужно как можно больше отдыхать, во время физических упражнений следить за собственным дыханием, следить за самочувствием и ориентироваться только на него. 

контролируйте самочувствие
не перенапрягайтесь 
следите за реакцией, ответом малыша

${separator}

Урок 7. Комплекс упражнений для ног

Ноги будущей мамы испытывают колоссальную нагрузку во время беременности. Первое, на что я хочу обратить ваше внимание, – это варикоз. Ни для кого не секрет, что варикозное расширение вен очень часто беспокоит будущих мамочек во время беременности.

Профилактика варикозного расширения вен:

Консультация врача-флеболога, сосудистого хирурга или лечащего врача.
Компрессионный трикотаж.
Контрастный душ. Когда вы приходите с прогулки, ополосните область ног сначала теплой, а потом обязательно холодной водой.  
Когда ложитесь отдыхать, старайтесь приподнимать ноги выше уровня сердца. Конечно, на 3-м триместре это делать достаточно тяжело, но все-таки на 2-3 минутки дайте своим ногам отдых. 
Ношение компрессионного трикотажа, приподнятое положение ног и ножные ванны рекомендует для будущих мам и Всемирная организация здравоохранения. Эти средства будущая мама может выбирать в зависимости от доступности и предпочтений.

Упражнения для оттока жидкости от нижних конечностей

Упражнения можно выполнять лежа, сидя или на фитболе без обуви.

Вращательные движения стоп

Мы натягиваем носочки от себя – на себя, от себя – на себя. Выполняем вращательные движения в одну сторону, в другую сторону. Вы можете делать это как поочередно одной ножкой и второй, либо вместе. Сильно старайтесь не перерастягивать мышцу, чтобы у вас не было таких спазматических ощущений, темп должен быть комфортным.

Ваша задача – сделать как можно больше различных вращательных движений. Например, натягивание, разведение в стороны и вовнутрь.

Массаж стопы

Вы можете делать это на начальном сроке самостоятельно, пока животик вам не мешает. Берете свою стопу и прорабатываете буквально каждый миллиметр, начиная от ваших пальчиков и заканчивая каждым миллиметром на вашей стопе. Это будет укреплять свод стопы. 

Почему это очень важно? Потому что у беременных достаточно частое явление – это плоскостопие. При выработке релаксина у нас связки и мышцы размягчаются, соответственно, частое явление – увеличение и утолщение нашей стопы. Также рекомендую приобретать специальные ортопедические стельки, которые тоже вам поможет подобрать врач.

Массаж стопы при помощи мяча

Берете любой мячик и катаете его стопой. Прорабатывается буквально каждая точечка на стопе. 
Можно брать теннисный мячик и более надавливающим движением прокатывать всю стопу. 
Можно обхватывать мячик пальчиками и пытаться как будто сжать его.
Можно положить карандаши на пол и пытаться пальцами ног схватить и приподнять этот карандаш.

${tb2c5_4}

Урок 8. Упражнения Кегеля

Мышцы малого таза и промежности являются главными действующими героями в родовых процессах. Чем ближе мы подходим к концу 3-го триместра, тем больше задумываемся о том, что будет происходить во время родов. Очень многое зависит от того, насколько вы чувствуете свои мышцы, которые будут помогать ребенку во время его движения.

Есть мышцы родового канала и мышцы промежности – и их тоже необходимо тренировать, чтобы они не были дряблыми, рыхлыми, а были эластичными и находились в тонусе. 

Упражнения Кегеля – это работа с внутренними мышцами, которые никто, кроме вас, не может ни видеть, ни чувствовать. Эти упражнения можно  выполнять в любое время в любом месте.

Противопоказаний как таковых у упражнений нет. Но если у вас:

есть угроза выкидыша или прерывания беременности 
серьезные гинекологические отклонения, 
Рекомендуется обратиться к врачу и проконсультироваться с ним!

Сжатие мышц промежности 

Как почувствовать эти мышцы? Вам нужно представить, как вы сдерживаете мочеиспускание. Запомните эти ощущения и повторите. Делайте 6-10 повторений. Сжали – отпустили, сжали – отпустили.

Упражнение «Лифт»

Представьте, что внутри вас есть маленькая кабинка лифта. И вы по этажам поднимаете эту кабинку, сжимая мышцы промежности. Поднимаете до «верхнего этажа» (насколько сможете), задерживаете и потом плавно отпускаете. В этот момент вы будете понимать, как мышцы сжимаются и расслабляются. Делаем 5-20 повторений. 

Во время родов вам врач будет говорить задержать дыхание, тужиться или расслабиться. Когда вы будете ощущать и понимать работу ваших мышц, вам будет намного проще в родовом процессе. Вы научитесь регулировать, сокращать, расслаблять эти мышцы.  

Упражнение «Волна»

Сначала вы напрягаете мышцы промежности, затем подключаете мышцы ануса. Когда вы начнете делать это достаточно быстро, вы почувствуете волну всему телу.

Во время родов идет очень сильное перерастяжение мышц и связок. А в послеродовый период эти упражнения помогут восстановлению тканей, родовых путей.

Советуем выполнять эти упражнения каждый день. Количество повторений можно увеличивать, доводя их до 100 раз. Это отличная профилактика разрывов и других осложнений во время родов.
${separator}

Урок 9. Массаж лица против отеков

Начнем с дыхания, для того чтобы обогатить наш организм кислородом. 

Дыхательные упражнения

Укладываем ручки на живот – одну руку на солнечное сплетение, другую – чуть ниже, и делаем дыхательные упражнения. 

Обратите внимание: дыхательные упражнения на любом сроке выполнять нужно только после консультации с врачом.

Делаем вдох, раздувая животик, и выдох, сдувая его. Смотрим, чтобы наша грудная клетка не приподнималась, а работали только мышцы живота. Неважно, какого размера у вас животик, – почувствуйте, как он поднимается и опускается.

Во время дыхания идет хорошее обогащение крови кислородом, к нашему лицу приливает кровь, соответственно, доходит кислород и выполнение массажа будет наиболее эффективным. 

Обратите внимание: если у вас есть бронхиальная астма или любые другие заболевания, связанные с органами дыхания, обязательно необходимо, прежде чем выполнять дыхательные упражнения, обратиться к врачу и проконсультироваться с ним.

Наш массаж с вами не будет глубоким – это скорее будут легкие скользящие движения. Мы глубоко не прорабатываем ткани, а только помогаем выходу лишней жидкости.

Массаж лба

Ставим ладошки в молитвенной позе на лоб и скользящими движениями опускаем через виски за ушки и вниз, к шее. Проглаживаем шею. Дальше ставим пальчики возле пазух носа и также проводим к ушкам и вниз. Делаем скользящие движения к ушам и вниз. Массируем пазухи носа до самых висков. И еще раз – и от висков спускаем вниз.

Массаж ушей

Полезно массировать наши ушки. Ушки содержат очень много акупунктурных точек. Сильно растираем ушки так, чтобы кровь в этом месте не застаивалась. 

Массаж бровей

Промассируем брови щипковыми движениями – крутим, как будто пинцетиками. Это упражнение будет способствовать тому, что вы проснетесь. Если где-то в каком-то месте вас вдруг застало неожиданно желание поспать – промассируйте себе брови, и вы проснетесь.

Массаж висков

Найдите на висках ямочки, впадинки – поставьте туда пальцы и массируйте. Можно выполнять это одним или двумя пальцами. Эта зона – достаточно обиженная в плане внимания, поэтому здесь часто бывают спазмы. Помассируйте их по всей поверхности, можно даже уйти ближе к затылку, к макушке.

Массаж носа

Наш носовой хрящ растет практически всю жизнь. Поэтому если мы ему не уделяем внимание, он может стать не очень некрасивым. Поэтому обязательно его массируйте, укрепляя мышцы, которые прилегают к носовому хрящу. Также сгоняем лишнюю жидкость. Обязательно «сбрасываем» руками по лимфотоку, по шее вниз.

Массаж точки над верхней губой

Находим ямочку над верхней губой. Это акупунктурная точка из китайского массажа. Она также способствует оттоку жидкости от нашего лица, плюс она помогает выделению ферментов. Прежде чем приступать к еде (к обеду, к завтраку, например), вы можете промассировать эту точку – и ваше пищеварение будет гораздо лучше. Далее массируем 2 точки над клыками.

Расслабление жевательных мышц

Ставим ребром ладонь и простукиваем, немножко приоткрыв рот, по щеке. Этим самым мы расслабляем жевательные мышцы и сбрасываем нагрузку с челюстного нерва. Можно делать это одной ладошкой, можно сразу двумя. 

Помним о том, что делаем это примерно от середины щеки до уха и далее все «сбрасываем» вниз, то есть проглаживаем в сторону шеи вниз. 

Массаж подмышечных впадин

Ставим пальцы подмышки и делаем небольшие пульсирующие движения. Это будет способствовать оттоку лимфы от нашего лица, от нашей груди и поможет лимфатической системе как можно быстрее избавиться от лишней жидкости.

Финал комплекса: делаем молитвенную позу, проглаживаем лицо, сбрасываем проглаживающими движениями 3 раза – со лба, за ушком и вниз.
    `,
};
