import './../Auth.css';
import { FC, useEffect, useState } from "react";
import lottie, { BMEnterFrameEvent } from 'lottie-web';
import { useTranslate } from "hooks/useTranslate";
import { AppRoutes } from "navigation/constants";
import StepsLabel from '../components/StepsLabel/StepsLabel';
import { STEPS_PROCESSING } from '../constants/steps';
import { Title } from 'components/Text/Title/Title';
import { Text } from 'components/Text/Text/Text';
import Footer from 'components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import animationData from 'assets/lottie/heart.json';
import { CheckProgress } from './components/CheckProgress/CheckProgress';


export const ProcessingScreen: FC = () => {
  const t = useTranslate();

  const navigate = useNavigate();

  const [progress, setProgress] = useState<number>(0);

  useEffect(
    () => {
      const animationContainer = document.getElementById('onboarding_animation');

      if (!animationContainer) return;

      const animation = lottie.loadAnimation({
        container: animationContainer,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin slice', // Supports the same options as the svg element's preserveAspectRatio property
          progressiveLoad: false, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
          hideOnTransparent: true, //Boolean, only svg renderer, hides elements when opacity reaches 0 (defaults to true)
        }
      });

      const handleNavigate = () => {
        navigate(AppRoutes.SIGN_UP);
      };

      const handleProgress = (e: BMEnterFrameEvent ) => {
        setProgress(e.currentTime)
      }

      animation.addEventListener('enterFrame', handleProgress)
      animation.addEventListener('complete', handleNavigate)

      return () => {
        animation.removeEventListener('complete', handleNavigate);
        animation.removeEventListener('enterFrame', handleProgress);

        lottie.destroy();
      }
    },
    [navigate]
  );

  return (
    <>
      <header className='auth-header'>
        <div className='auth-container auth-header-container'>
          <StepsLabel
            step={ STEPS_PROCESSING }
          />
        </div>
      </header> 
      <main className='auth-main'>
        <div className='auth-container flex-column-center'>
          <Title 
            text={ t('onboarding_ProcessingScreeen_title') }
            highlight={ t('onboarding_ProcessingScreeen_title_highlight') }
            center
          />
          <div className='auth-description'>
            <Text 
              text={ t('onboarding_ProcessingScreeen_description') }
            />
          </div>
          <div id='onboarding_animation'></div>
          <div className='auth-checkList'>
            <CheckProgress
              title={t('onboarding_ProcessingScreeen_check_progress_1')}
              check={progress > 65}
              checking={progress > 0 && progress < 65}
            />
            <CheckProgress
              title={t('onboarding_ProcessingScreeen_check_progress_2')}
              check={progress > 125}
              checking={progress > 65 && progress < 125}
            />
            <CheckProgress
              title={t('onboarding_ProcessingScreeen_check_progress_3')}
              check={progress > 220}
              checking={progress > 125 && progress < 220}
            />
            <CheckProgress
              title={t('onboarding_ProcessingScreeen_check_progress_4')}
              check={progress > 325}
              checking={progress > 220 && progress < 325}
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
};
