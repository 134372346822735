import { analyticsLogEvent } from './analytics';

export const watchPostDetailsEvent = (post_id: string) =>
  analyticsLogEvent('handle_watch_post_details', { post_id });

export const watchAdviceDetailsEvent = (advice_id: string) =>
  analyticsLogEvent('handle_watch_advice_details', { advice_id });

export const watchCourseDetailsEvent = (course_id: string) =>
  analyticsLogEvent('handle_watch_course_details', { course_id });

export const watchCategoryDetailsEvent = (category_id: string) =>
  analyticsLogEvent('handle_watch_category_details', { category_id });

export const watchExpertDetailsEvent = (expert_id: string) =>
  analyticsLogEvent('handle_watch_expert_details', { expert_id });

export const watchStudyEvent = () => analyticsLogEvent('handle_watch_study');

export const watchHomeEvent = () => analyticsLogEvent('handle_watch_home');

export const watchSearchEvent = () => analyticsLogEvent('handle_watch_search');

export const watchHomePostEvent = () => analyticsLogEvent('handle_home_post');

export const watchHomeAdviceEvent = () =>
  analyticsLogEvent('handle_home_advice');

export const watchHomeCourseEvent = () =>
  analyticsLogEvent('handle_home_course');
