import { FC, useCallback } from "react";
import styles from './ListButton.module.css';
import { isFunction, noop } from "lodash";
import classNames from "classnames";


export const ListButton: FC<{
  iconLeft?: string;
  iconRight?: string;
  big?: boolean;
  title: string;
  selected?: boolean,
  unselected?: boolean;
  onPress?: () => void;
}> = ({
  iconLeft,
  iconRight,
  big = false,
  title,
  selected = false,
  unselected = false,
  onPress = noop,
}) => {
  const handleClick = useCallback(
    () => {
      isFunction(onPress) && onPress();
    },
    [onPress]
  );

  return (
    <button
      className={ classNames(
        styles.container,
        big && styles.bigContainer,
        selected && styles.selected,
        unselected && styles.unselected
      ) }
      onClick={ handleClick }
    >
      {
        !!iconLeft && 
          <img 
            src={ iconLeft }
            className={ classNames(
              styles.iconLeft,
              big && styles.bigIconLeft
            ) }
            alt={ 'icon' }
          />
      }
      <div className={ classNames(
        styles.title,
        unselected && styles.unselectedTitle
      ) }>
        {
          title
        }
      </div>
      {
        !!iconRight && 
        <div
          className={ styles.containerIconRight }
          onClick={ handleClick }
        >
          <img src={ iconRight } className={ styles.iconRight }  alt={ 'icon' } />
        </div>
      }
    </button>
  );
};
