import { FamilyIcon } from 'assets/icons';
import { Title } from 'components/Text/Title/Title';
import { useTranslate } from 'hooks/useTranslate';
import { useUser } from 'hooks/useUser';
import { calculateStudyProgress } from 'pages/StudyPage/StudyScreen.utils';
import { useMemo } from 'react';
import StudyButton from '../StudyButton/StudyButton';
import { StudyProgressScale } from './StudyProgressScale';
import styles from './StudyWaiting.module.css';
import { getNextCourse } from 'pages/TestPage/TestPage.utils';
import { NUMBER_OF_LAST_BLOCK_WITH_TESTS } from 'utils/other';

export const StudyWaiting = () => {
  const { user } = useUser();
  const started = !!user?.waeb?.startedAt;
  const progress = user?.waeb?.progress || null;

  const t = useTranslate();
  const percents = useMemo(
    // @ts-ignore
    () => calculateStudyProgress(progress, user?.waeb?.startedAt),
    [progress]
  );
  const block = user.waeb?.progress?.block;
  const course = user.waeb?.progress?.course;

  const { block: nextBlock, course: nextCourse } = getNextCourse(
    user?.waeb?.progress?.block || 'b1',
    user?.waeb?.progress?.course || 'c0'
  );

  const getLinkTo = () => {
    if (block === `b${NUMBER_OF_LAST_BLOCK_WITH_TESTS}`)
      return '/final-test-page';
    if (started && !!block && !!course) {
      return `/test-page/${nextBlock}/${nextCourse}`;
    } else {
      return `/waiting-page`;
    }
  };

  return (
    <div className={styles.studyScreenWaitingContainer}>
      <div className={styles.wrapper}>
        <div className={styles.waitingContainerTextContainer}>
          <div className={styles.title}>
            <Title text={t('study_progress_block')} highlight="ребёнка" />
            <div className={styles.familyIconSmall}>
              <FamilyIcon />
            </div>
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.containerText}>
              <p className={styles.subtitle}>{t('study_program')}</p>
              <p className={styles.subtitle}>{t('study_program_experts')}</p>
            </div>
            {started && <StudyProgressScale percents={percents} />}
          </div>

          <StudyButton
            title={started ? t('continue_training') : t('detailed')}
            to={getLinkTo()}
          />
        </div>
        <div className={styles.familyIcon}>
          <FamilyIcon />
        </div>
      </div>
    </div>
  );
};
