import styles from './CourseEmpty.module.css';
import { useTranslate } from 'hooks/useTranslate';

import animationData from 'assets/lottie/heartSmall.json';
import Lottie from 'lottie-web';
import { useEffect } from 'react';

export const CourseEmpty = () => {
  const t = useTranslate();

  useEffect(() => {
    const animationContainer = document.getElementById(
      'animation-container'
    ) as HTMLDivElement;

    if (!animationContainer) {
      return;
    }

    const animation = Lottie.loadAnimation({
      container: animationContainer,
      animationData: animationData,
    });

    return () => {
      animation.destroy();
    };
  }, []);

  return (
    <div className={styles.container}>
      <div id="animation-container" className={styles.animationContainer}></div>
      <p className={styles.loading}>{t('course_empty_loading')}</p>
    </div>
  );
};
