const DAYS = 7;
const HOURS = 24;
const MINUTES = 60;
const SECONDS = 60;
const MILLISECONDS = 1000;

export const daysToMls = (days: number) => {
  return days * HOURS * MINUTES * SECONDS * MILLISECONDS;
};

export const weeksToMls = (weeks: number) => {
  return weeks * DAYS * HOURS * MINUTES * SECONDS * MILLISECONDS;
};

export const maxPregnancyPeriodMls = weeksToMls(45) + daysToMls(6);
