import ExpertInfo from 'components/ExpertInfo/ExpertInfo';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import { useContent } from 'hooks/useContent';
import { useTranslate } from 'hooks/useTranslate';
import { selectExpertsById } from 'pages/ExpertScreen/store/selectors';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/store';
import { getType, optional } from 'utils/other';
import styles from './CategoryPost.module.css';

import ClockContainer from 'components/ClockContainer/ClockContainer';
import FavoriteContainer from 'components/FavoriteContainer/FavoriteContainer';
import { LockedContent } from 'components/LockedContent/LockedContent';
import { useUser } from 'hooks/useUser';
import { durationConfig } from 'utils/time';
import DescriptionContainer from './DescriptionContainer/DescriptionContainer';
import { CoursesHeader } from 'components/CoursesHeader/CoursesHeader';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { RecommendationType } from 'types';
import CourseEmptyWrapper from 'components/CommonCoursesCategory/components/CourseEmpty/CourseEmptyWrapper';
import { watchPostDetailsEvent } from 'analytic/trackEvents';
import { useExpertsApi } from 'hooks/useExpertsApi';

function CategoryPost() {
  const [active, setActive] = useState(false);
  const { contentId } = useParams();
  const type = getType();
  //@ts-ignore
  const { content, loading } = useContent(contentId!, type);
  useExpertsApi();
  useEffect(() => {
    if (!!contentId) {
      watchPostDetailsEvent(contentId);
    }
  }, [contentId]);

  const navigate = useNavigate();
  const t = useTranslate();
  const experts = useAppSelector(
    selectExpertsById(Object.keys(optional(content?.expert)))
  );
  const expertExist = !!experts?.length;
  const { isMobile, isTablet } = useAppWindowSize();

  const handleReadMoreClick = () => {
    setActive((active) => !active);
  };
  const size = isMobile ? 20 : 30;
  const { user } = useUser();

  if (loading) {
    return (
      <CourseEmptyWrapper
        isTablet={isTablet}
        isMobile={isMobile}
        title={t('пост')}
      />
    );
  }
  if (!content?.enable) {
    navigate('*');
    return null;
  }
  const navigateBack = () => {
    navigate(-1);
  };
  return (
    <div className={styles.background}>
      <Header styled />
      <div className={styles.container}>
        <CoursesHeader
          title={'пост'}
          isTablet={isTablet}
          isMobile={isMobile}
          cb={navigateBack}
        />

        <div className={styles.wrapper}>
          {active && (
            <LockedContent open={active} onClose={() => setActive(false)} />
          )}

          <div>
            <img src={content?.image} className={styles.image} />
          </div>

          <div className={styles.infoContainer}>
            <div className={styles.header}>
              <div>
                <h1 className={styles.title}>{content?.title}</h1>
              </div>
            </div>
            <div className={styles.clockContent}>
              <ClockContainer
                duration={durationConfig[type as RecommendationType](content)}
                size={size}
              />
              <FavoriteContainer item={content} />
            </div>
            <div className={styles.favoriteContainer}>
              {isTablet &&
                expertExist &&
                experts?.map((expert) => <ExpertInfo item={expert} />)}
            </div>

            {!isTablet &&
              expertExist &&
              experts?.map((expert) => <ExpertInfo item={expert} />)}
          </div>
        </div>
        <DescriptionContainer
          userId={user?.id}
          description={content?.data}
          active={active}
          handleReadMoreClick={handleReadMoreClick}
          content={content?.content}
        />
      </div>
      <Footer />
    </div>
  );
}

export default CategoryPost;
