import { FC, useCallback } from "react";
import styles from './MenuItemLogout.module.css';
import { ListMenuButton } from "features/Profile/components/ListMenuButton/ListMenuButton";
import { LogoutProfileIcon } from "assets/icons";
import { useTranslate } from "hooks/useTranslate";
import { Modal } from "components/Modal/Modal";
import { useBinarySwitcher }from "hooks/useBinarySwitcher"
import { useAuth } from "features/Auth/hooks/useAuth";
import { noop } from "lodash";


export const MenuItemLogout: FC = () => {
  const t = useTranslate();

  const [isOpenModal, openModal, closeModal] = useBinarySwitcher(false);

  const { logout } = useAuth();

  const handleConfirm = useCallback(
    () => {
      closeModal();
      logout().catch(noop);
    },
    [closeModal, logout]
  );

  return (
    <>
      <ListMenuButton
        iconLeft={ LogoutProfileIcon }
        title={ t('profile_menu_logout') }
        onPress={ openModal }
        isRed />
        
      <Modal
        title={ t('profile_menu_logout_module_title') }
        description={ t('profile_menu_logout_module_description') }
        isOpen={ isOpenModal }
        onCancel={ closeModal }
        cancelTitle={ t('profile_menu_logout_module_cancel') }
        onConfirm={ handleConfirm }
        confirmTitle={ t('profile_menu_logout_module_confirm') }
      >
      </Modal>
    </>
  )
};
