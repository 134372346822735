import { RecommendationType } from 'types';
import { Expert } from 'types/items';

export const as = <T>(value: unknown): T => value as T;
export const optional = <T>(value: unknown): T => as<T>(value || {});
export const optionalArray = <T>(value: T[]): T[] => as<T[]>(value || []);
export const NUMBER_OF_LAST_BLOCK_WITH_TESTS = 6;
export const LAST_BLOCK = 'b8';
export const LAST_BLOCK_COURSE = 'c26';

export const checkIsWebView = () => !!localStorage.getItem('idToken');

export const getExpertFullName = ({
  firstName,
  middleName,
  lastName,
}: Expert): string =>
  `${firstName ? firstName : ''} ${
    middleName && middleName !== 'Null' ? middleName + ' ' : ''
  }${lastName ? lastName : ''}`.trim();

export const checkIsMobile = (width: number) => width <= 550;
export const checkIsTablet = (width: number) => width > 550 && width <= 860;

const mapType = (type: string) => {
  if (type.includes('/advices')) return RecommendationType.ADVICE;
  if (type.includes('/courses')) return RecommendationType.COURSE;
  if (type.includes('/posts')) return RecommendationType.POST;
  if (type.includes('/meditation')) return RecommendationType.MEDITATION;
  if (type.includes('/lullabies')) return RecommendationType.LULLABIES;
};

//@ts-ignore
export const getType = () => mapType(window.location.pathname)!;

export const urlTypeConfig: Partial<Record<RecommendationType, string>> = {
  [RecommendationType.ADVICE]: 'advices',
  [RecommendationType.COURSE]: 'courses',
  [RecommendationType.POST]: 'posts',
  [RecommendationType.MEDITATION]: 'meditation',
  [RecommendationType.LULLABIES]: 'lullabies',
};

export const getUrlType = (type: Partial<RecommendationType>) => {
  return urlTypeConfig[type];
};

export const getCategoryId = (categoryId: string | undefined | null) =>
  categoryId ? categoryId + '/' : '';
