export const recursivelyReplaceNullToStringNull = (item) => {
  if (item === null) return 'Null';
  if (typeof item !== 'object') return item;

  const newObject = {};

  for (const key in item) {
    if (item[key] === null) newObject[key] = 'Null';
    else if (typeof item[key] === 'object') newObject[key] = recursivelyReplaceNullToStringNull(item[key]);
    else newObject[key] = item[key];
  }

  return newObject;
};
