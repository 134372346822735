import { GoogleIcon } from 'assets/icons';
import { ProviderRemoval } from 'features/Auth/constants/constants';
import { useTranslate } from 'hooks/useTranslate';
import { ComeInButton } from '../../Buttons/ComeInButton/ComeInButton';
import ErrorMessage from 'components/Messages/ErrorMessage/ErrorMessage';
import { useAppWindowSize } from 'hooks/useAppWindowSize';

type ContentModalNoticeProps = {
  handleDeleteAccount: () => void;
  handleCancel: () => void;
  error: string;
};

export const ContentModalGoogle = ({
  handleDeleteAccount,
  handleCancel,
  error,
}: ContentModalNoticeProps) => {
  const t = useTranslate();
  const { isMobile } = useAppWindowSize();
  return (
    <>
      {!!error && !isMobile && (
        <>
          <ErrorMessage message={t(error)} container={{ marginTop: 30 }} />{' '}
        </>
      )}
      <ComeInButton
        onPress={handleDeleteAccount}
        handleCancel={handleCancel}
        title={t('delete_acc_with_google')}
        icon={GoogleIcon}
        provider={ProviderRemoval.GOOGLE}
        isError={error}
      />
    </>
  );
};
