import { useUser } from 'hooks/useUser';
import { Navigate } from 'react-router-dom';
import { AppRoutes } from './constants';

type ProtectedRouteProps = {
  redirectPath?: AppRoutes;
  children: JSX.Element;
};

export const ProtectedRoute = ({
  redirectPath = AppRoutes.LOGIN,
  children,
}: ProtectedRouteProps) => {
  const { user } = useUser();
  return (
    <>
      {!user?.id && <Navigate to={redirectPath} replace />}
      {user?.id && children}
    </>
  );
};
