import { SearchIcon } from 'assets/icons';
import { useTranslate } from 'hooks/useTranslate';
import { FC, useEffect, useRef, useState } from 'react';
import styles from './StudySearch.module.css';
import { useSearch } from 'hooks/useSearch';
import { StudySearchModal } from '../StudySearchModal/StudySearchModal';
import { SearchNotFound } from '../SearchNotFound/SearchNotFound';
import { SearchValidationMessage } from '../SearchValidationMessage/SearchValidationMessage';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { watchSearchEvent } from 'analytic/trackEvents';

export const StudySearch: FC<{ onSearchOpen?: (value: boolean) => void }> = ({
  onSearchOpen,
}) => {
  const t = useTranslate();
  const { searchResult, isValid, query, updateQuery } = useSearch();
  const [showModal, setShowModal] = useState(false);
  const isSearch = window.location.href.includes('edu/search');
  const inputRef = useRef<HTMLInputElement>(null);
  const handleSearch = (value: string) => {
    updateQuery(value);
  };
  useEffect(() => {
    if (showModal) {
      watchSearchEvent();
    }
  }, [showModal]);

  useEffect(() => {
    inputRef.current?.focus();
    isSearch && setShowModal(true);
  }, [isSearch, inputRef]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const isInsideInput = target.closest(`.${styles.studyScreenSearch}`);
      if (!isInsideInput) {
        setShowModal(false);
      }
    };

    if (showModal) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      onSearchOpen?.(showModal);
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [showModal]);

  const { isMobile, isTablet } = useAppWindowSize();
  const size = isMobile ? 16 : isTablet ? 24 : 30;
  return (
    <div className={styles.searchContainer}>
      {showModal && (
        <div className={styles.overlay} onClick={() => setShowModal(false)} />
      )}
      <div
        style={{ zIndex: showModal ? 3000 : 'inherit' }}
        className={`${styles.searchWrapper} ${showModal ? styles.clicked : ''}`}
      >
        <div className={styles.search}>
          <div className={styles.searchIconContainer}>
            <SearchIcon
              width={size}
              height={size}
              color={showModal ? '#9D8DF1' : '#000'}
            />
          </div>

          <input
            type="text"
            ref={inputRef}
            className={styles.studyScreenSearch}
            onChange={(e) => {
              e.stopPropagation();
              return handleSearch(e.target.value);
            }}
            value={query}
            onKeyUp={() => setShowModal(true)}
            onClick={() => setShowModal(true)}
            placeholder={t('startTyping')}
          />

          {showModal && (
            <button
              className={styles.commonButton}
              onClick={(e) => {
                e.stopPropagation();
                handleSearch('');
              }}
            >
              {t('common-cancel')}
            </button>
          )}
        </div>
        {showModal && !isValid && <SearchValidationMessage />}
        {showModal && isValid && !!searchResult?.length && (
          <StudySearchModal searchResult={searchResult} query={query} />
        )}
        {showModal && isValid && !searchResult?.length && (
          <SearchNotFound onKeywordSelect={handleSearch} />
        )}
      </div>
    </div>
  );
};
