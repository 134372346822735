import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import styles from './Sitemap.module.css';
import { useTranslate } from 'hooks/useTranslate';
import { useExpertsApi } from 'hooks/useExpertsApi';
import { useAllCourses } from 'hooks/useAllCourses';
import { SitemapItems } from './SitemapItems';
import {
  contentConfig,
  getExperts,
  pages,
  studyContent,
} from './Sitemap.utils';
import { SitemapBlob } from './SitemapBlob';

const Sitemap = () => {
  const { experts } = useExpertsApi();
  const { allCourses, loading } = useAllCourses();
  const t = useTranslate();

  if (loading) {
    return <>Loading... </>;
  }

  return (
    <div className={styles.background}>
      <Header styled />
      <div className={styles.container}>
        <p>
          <h1>Страницы</h1>
          <ul>
            {Object.entries(pages).map(([text, url], index) => (
              <li key={index}>
                <a href={url} className={styles.links}>
                  {text}
                </a>
              </li>
            ))}
          </ul>
        </p>
        <p>
          <h1>Категории обучения</h1>
          <ul>
            {studyContent.map(({ url, title }, index) => {
              return (
                <li key={index}>
                  <a href={url as string} className={styles.links}>
                    {t(title)}
                  </a>
                </li>
              );
            })}
          </ul>
        </p>
        <p>
          <h1>Эксперты</h1>
          <ul>
            {Object.entries(getExperts(experts)).map(([text, url], index) => (
              <li key={index}>
                <a href={url as string} className={styles.links}>
                  {t(text)}
                </a>
              </li>
            ))}
          </ul>
        </p>
        <p>
          {contentConfig?.map(({ name, type }, i) => (
            <p key={`${name}--${i}`}>
              <h1>{name}</h1>
              <ul>
                {studyContent.map(({ url, title, id }, index) => (
                  <li key={index}>
                    <p className={styles.links}>{t(title)}</p>
                    <SitemapItems
                      allCourses={allCourses}
                      categoryId={id}
                      type={type}
                    />
                  </li>
                ))}
              </ul>
            </p>
          ))}
        </p>
      </div>
      <Footer />
      {/* <SitemapBlob /> */}
    </div>
  );
};

export default Sitemap;
