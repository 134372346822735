import { getExpertFullName, optional } from 'utils/other';
import { Expert } from 'types/items';

export const MIN_QUERY_LENGTH = 3;

export const searchForExperts =
  (query: string) =>
  (item: Expert): boolean => {
    const { firstName, lastName, middleName, field } = optional<Expert>(item);
    if (firstName || lastName || middleName) {
      return `${getExpertFullName(item)} ${field}`
        .toLowerCase()
        .includes(query.toLowerCase());
    }
    return false;
  };
