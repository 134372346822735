import React, { memo } from 'react';
import { ClockIconMono } from 'assets/icons';
import { durationConfig } from 'utils/time';
import { CourseData, RecommendationType } from 'types';
import { coursesConfig } from 'utils/courses';
import { useTranslate } from 'hooks/useTranslate';
import { Label } from 'components/Label/Label';
import styles from './CourseInfoLabels.module.css';
import { useAppWindowSize } from 'hooks/useAppWindowSize';

type CourseInfoLabelsProps = {
  item: CourseData;
  type: RecommendationType;
};

export const CourseInfoLabels: React.FC<CourseInfoLabelsProps> = memo(
  ({ item, type }) => {
    const t = useTranslate();
    const { icon: Icon, tKey } = coursesConfig?.[type];
    const { isMobile } = useAppWindowSize();

    const iconSize = isMobile ? 20 : 30;
    return (
      <div className={styles.container}>
        <Label
          for={<Icon height={iconSize} width={iconSize} color={'#C2C2C2'} />}
          text={tKey ? t(tKey || '').toLowerCase() : ''}
          containerStyle={styles.labelContainer}
        />
        <Label
          for={
            <ClockIconMono
              height={iconSize}
              width={iconSize}
              color={'#C2C2C2'}
            />
          }
          text={durationConfig[type](item)}
        />
      </div>
    );
  }
);
