import { ProgressWAEB, CertificateWAEB } from 'constants/constants';
import { User } from 'features/User/type';
import { coursesData } from 'pages/StudyingPage/Blocks.utils';
import {
  LAST_BLOCK,
  NUMBER_OF_LAST_BLOCK_WITH_TESTS,
  optional,
} from 'utils/other';
import { LAST_BLOCK_COURSE } from '../../utils/other';

export const testVariantNameConfig: Record<string, string> = {
  a1: 'A',
  a2: 'B',
  a3: 'C',
};

const isFinalTestPassed = (
  block: string | undefined,
  course: string | undefined
): boolean => {
  return block === LAST_BLOCK && course === LAST_BLOCK_COURSE;
};

export const checkIsCourseHasTests = (
  block: string | undefined,
  course: string | undefined
) => {
  if (!block || !course) return false;
  return !!coursesData?.[block]?.[course]?.tests?.length;
};

export const parseToNumber = (itemName: string): number =>
  Number(itemName?.slice(1) || 0);

export const checkIsLastCourse = (block: string, course: string): boolean => {
  const length = Object.keys(coursesData?.[block] || {})?.length;
  const courseNumber = parseToNumber(course);
  if (courseNumber > length) {
    return !!coursesData?.[block]?.[`c${length + 2}`]?.last;
  }
  return !!coursesData?.[block]?.[course]?.last;
};

export const courseToBlockMap: Record<string, string> = {
  c1: 'b1',
  c2: 'b1',
  c3: 'b2',
  c4: 'b2',
  c5: 'b2',
  c6: 'b2',
  c7: 'b2',
  c8: 'b3',
  c9: 'b3',
  c10: 'b3',
  c11: 'b3',
  c12: 'b3',
  c13: 'b3',
  c14: 'b4',
  c15: 'b4',
  c16: 'b5',
  c17: 'b5',
  c18: 'b5',
  c19: 'b5',
  c20: 'b6',
  c21: 'b7',
  c22: 'b7',
  c23: 'b7',
  c24: 'b7',
  c25: 'b7',
  c26: 'b8',
};

export const getNextCourse = (block: string, course: string) => {
  const isLast = checkIsLastCourse(block, course);
  const next = {
    block: isLast
      ? `b${parseToNumber(block) + 1}`
      : courseToBlockMap[`c${parseToNumber(course) + 1}`],
    course: isLast
      ? `c${parseToNumber(course) + 2}`
      : `c${parseToNumber(course) + 1}`,
  };
  const nextCourse = coursesData[next.block]?.[next.course];
  return nextCourse?.next ? nextCourse.next : next;
};

export const getNextCourseLink = (
  currentBlock: string,
  currentCourse: string
) => {
  const isLast = checkIsLastCourse(currentBlock, currentCourse);
  const nextBlock = isLast
    ? `b${parseToNumber(currentBlock) + 1}`
    : courseToBlockMap[`c${parseToNumber(currentCourse) + 1}`];
  if (currentBlock === `b${NUMBER_OF_LAST_BLOCK_WITH_TESTS}`)
    return '/final-test-page';
  const nextCourse = isLast
    ? `c${parseToNumber(currentCourse) + 2}`
    : `c${parseToNumber(currentCourse) + 1}`;
  return `/videos-page/${nextBlock}/${nextCourse}`;
};

const parseProgress = (block: string, course: string): number =>
  parseToNumber(block) * 1000 + parseToNumber(course);

export const checkProgress = (
  user: User,
  currentBlock: string,
  currentCourse: string
): {
  passed?: boolean;
  available?: boolean;
  empty?: boolean;
  finished?: boolean;
  certificate?: boolean;
} => {
  const { waeb } = user;
  const { progress, certificate } = optional<{
    progress: ProgressWAEB;
    certificate: CertificateWAEB;
  }>(waeb);
  const { course = 'c0', block = 'b1' } = optional<ProgressWAEB>(progress);
  if (isFinalTestPassed(block, course) && !certificate?.createdAt) {
    return {
      available: true,
      passed: true,
      finished: true,
      certificate: false,
    };
  }

  const empty = !checkIsCourseHasTests(block, course);

  if (certificate?.createdAt) {
    return {
      available: true,
      passed: true,
      empty,
      finished: true,
      certificate: true,
    };
  }

  const currentHash = parseProgress(currentBlock, currentCourse);
  const { block: nextBlock, course: nextCourse } = getNextCourse(block, course);
  const passedHash = parseProgress(block, course);
  const availableHash = parseProgress(nextBlock, nextCourse);

  const passed = passedHash >= currentHash;
  const available =
    passedHash >= NUMBER_OF_LAST_BLOCK_WITH_TESTS * 1000 + 1 ||
    availableHash >= currentHash ||
    passed;

  return {
    passed,
    available,
    empty,
  };
};
