export const b7 = {
  c21: {
    title: 'b7_c21_title',
    author: 'b7_c21_author',
    description: 'b7_c21_description',
    lessonsLength: 7,
    video: 'https://vimeo.com/showcase/9263595/embed',
  },
  c22: {
    title: 'b7_c22_title',
    author: 'b7_c22_author',
    description: 'b7_c22_description',
    lessonsLength: 6,
    video: 'https://vimeo.com/showcase/9263606/embed',
  },

  c23: {
    title: 'b7_c23_title',
    author: 'b7_c23_author',
    description: 'b7_c23_description',
    lessonsLength: 5,
    video: 'https://vimeo.com/showcase/9263615/embed',
  },

  c24: {
    title: 'b7_c24_title',
    author: 'b7_c24_author',
    description: 'b7_c24_description',
    lessonsLength: 5,
    video: 'https://vimeo.com/showcase/9263624/embed',
  },
  c25: {
    title: 'b7_c25_title',
    author: 'b7_c25_author',
    description: 'b7_c25_description',
    lessonsLength: 7,
    last: true,
    video: 'https://vimeo.com/showcase/9263630/embed',
  },
};
