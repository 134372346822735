
import { FC, useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import styles from './NumberInput.module.css';
import { noop } from "lodash";

export const NumberInput: FC<{
    id: any;
    error: boolean;
    onChangeFocus: (focus: boolean) => void;
    selected?: boolean;
    onChange: (value: any) => void;
    value?: string | number;
    desabled?: boolean;
  }> = ({
    id,
    error,
    onChangeFocus,
    selected,
    onChange = noop,
    value,
    desabled = false,
  }) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const ref = useRef<HTMLInputElement>(null);

    useEffect(
      () => {
        if (selected && ref.current) {
          ref.current.focus();
        }
      },
      [selected]
    );
  
    const handleOnFocus = useCallback(
      () => {
        setIsFocused(true);
        onChangeFocus(true);
      },
      [onChangeFocus]
    );
  
    const handleOnBlur = useCallback(
      () => {
        setIsFocused(false);
        onChangeFocus(false);
      },
      [onChangeFocus]
    );
  

    return (
      <input
        id={ id }
        ref={ ref }
        className={ classNames(
          styles.container,
          error && styles.containerError,
          isFocused && styles.focused,
        ) }
        onFocus={ handleOnFocus }
        onBlur={ handleOnBlur }
        type='text'
        inputMode="numeric"
        placeholder='X'
        onChange={ onChange }
        value={ value }
        disabled={ desabled }
      />
    );
  };
  