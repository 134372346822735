import { useTranslate } from 'hooks/useTranslate';

import ErrorMessage from 'components/Messages/ErrorMessage/ErrorMessage';
import Input from 'features/Auth/components/Input/Input';
import { ButtonsContainer } from '../../Buttons/ButtonsContainer/ButtonsContainer';
import styles from './ContentModalEmail.module.css';

type ContentModalEmailProps = {
  handleOpenModal: (value: any) => void;
  handleChangeFocus: (isFocused: boolean) => void;
  valueEmail: string;
  onChange: any;
  isValid: boolean;
  valuePassword: string;
  error: string;
  hideModal: () => void;
  onSubmit: () => void;
  password: string;
};

export const ContentModalEmail = ({
  handleOpenModal,
  handleChangeFocus,
  valueEmail,
  onChange,
  isValid,
  valuePassword,
  error,
  hideModal,
  onSubmit,
  password,
}: ContentModalEmailProps) => {
  const t = useTranslate();

  return (
    <>
      <div className={styles.inputWrapper}>
        <Input
          className={styles.inputContainer}
          full
          label={t('sing_in_placeholder_email')}
          onChange={handleOpenModal}
          onChangeFocus={handleChangeFocus}
          error={!isValid}
          inputMode={'email'}
          value={valueEmail}
        />
        <Input
          className={styles.inputContainer}
          full
          label={t('sing_in_placeholder_password')}
          onChange={onChange}
          onChangeFocus={handleChangeFocus}
          error={!isValid}
          value={valuePassword}
          id={password}
          secret
        />
      </div>
      {!error && !isValid && (
        <>
          <ErrorMessage
            message={t('sing_in_error')}
            container={{ marginTop: 30 }}
          />{' '}
        </>
      )}
      {error && (
        <>
          <ErrorMessage message={t(error)} container={{ marginTop: 30 }} />{' '}
        </>
      )}
      <ButtonsContainer hideModal={hideModal} onPress={onSubmit} />
    </>
  );
};
