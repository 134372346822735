import { useCoursesApi } from 'hooks/useCoursesApi';
import { useMemo } from 'react';
import { CourseData, RecommendationType } from 'types';

export const useContent = (
  id: string,
  type: RecommendationType
): { content: CourseData; loading: boolean } => {
  const { courses, loading } = useCoursesApi(type);
  const content = useMemo(
    () => courses.find((course) => course.id === id),
    [courses]
  );
  return {
    loading,
    //@ts-ignore
    content,
  };
};
