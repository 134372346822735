import './../Auth.css';
import { FC, useCallback, useMemo } from "react";
import ArrowNavButton from "components/Buttons/ArrowNavButton/ArrowNavButton";
import { useTranslate } from "hooks/useTranslate";
import { AppRoutes } from "navigation/constants";
import StepsLabel from '../components/StepsLabel/StepsLabel';
import { STEPS_SELECT_USAGE_OPTIONS } from '../constants/steps';
import { Title } from 'components/Text/Title/Title';
import { Text } from 'components/Text/Text/Text';
import Footer from 'components/Footer/Footer';
import { TitleH2 } from 'components/Text/TitleH2/TitleH2';
import NextButton from './components/NextButton/NextButton';
import { TitleH3 } from 'components/Text/TitleH3/TitleH3';
import { useNavigate } from 'react-router-dom';
import { StatusPicker } from 'components/Pickers/StatusPicker/StatusPicker';
import { useAppDispatch, useAppSelector } from 'store/store';
import { selectUsageOption } from './store/selectors';
import { UsageOptions } from 'constants/constants';
import { resetInterests, resetOnboarding, updateUsageOption } from './store/OnboardingSlice';


export const StatusScreen: FC = () => {
  const t = useTranslate();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const usageOption = useAppSelector(selectUsageOption);

  const nextRoute = useMemo(
    () => usageOption === UsageOptions.PLANNING_PREGNANCY
      ? AppRoutes.INTERESTS_SCREEN
      : AppRoutes.PERIOD_SCREEN,
    [usageOption]
  );

  const handleNext = useCallback(
    () => {
      navigate(nextRoute)
    },
    [navigate, nextRoute]
  );

  const handleSelect = useCallback(
    (value: UsageOptions) => () => {
      dispatch(resetInterests());
      dispatch(updateUsageOption(value));
    },
    [dispatch]
  );

  return (
    <>
      <header className='auth-header'>
        <div className='auth-container auth-header-container'>
          <ArrowNavButton 
            title={ t('sing_up_come_in') }
            to={ AppRoutes.LOGIN }
            cb={ () => dispatch(resetOnboarding()) }
          />
          <StepsLabel
            step={ STEPS_SELECT_USAGE_OPTIONS }
          />
        </div>
      </header> 
      <main className='auth-main'>
        <div className='auth-container flex-column-center'>
          <Title 
            text={ t('onboarding_StatusScreen_title') }
            highlight={ t('onboarding_StatusScreen_title_highlight') }
            center
          />
          <div className="auth-subtitle-container">
            <TitleH2
              text={ t('onboarding_StatusScreen_subtitle') }
            />
          </div>
          <div className='auth-description'>
            <Text 
              text={ t('onboarding_StatusScreen_description') }
            />
          </div>
          <div className='auth-section-title'>
            <TitleH3
              text={ t('onboarding_StatusScreen_section_title') }
            /> 
          </div>
          <StatusPicker
            onChange={ handleSelect }
            selectedStatus={ usageOption }
          />
          <div className='auth-next-container'>
            <NextButton
              title={ t('common-next') }
              onClick={ handleNext }
              disabled={ !usageOption }
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
};
