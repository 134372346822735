import { CSSProperties, FC, memo } from 'react';
import styles from './PrimaryButton.module.css';

const PrimaryButton: FC<{
  title: string;
  onClick: () => void;
  disabled?: boolean;
  styleContainer?: CSSProperties;
}> = memo(({ title, onClick, disabled, styleContainer }) => {
  return (
    <button
      type="button"
      className={!disabled ? styles.container : styles.containerDisabled}
      onClick={onClick}
      disabled={disabled}
      style={styleContainer}
    >
      {title}
    </button>
  );
});

export default PrimaryButton;
