import { useMemo, useState } from 'react';
import { MIN_QUERY_LENGTH, searchForExperts } from 'utils/search';
import { useAllCourses } from './useAllCourses';
import { useExpertsApi } from './useExpertsApi';
import { adaptExpertsToSearch } from 'utils/experts';
import { useTranslate } from 'hooks/useTranslate';
import { defineLessons } from 'utils/courses';

export const useSearch = (initialQuery = '') => {
  const t = useTranslate();
  const [query, setQuery] = useState(initialQuery);

  const updateQuery = (value: string) => {
    setQuery(value);
  };
  const { allCourses } = useAllCourses();
  const { experts } = useExpertsApi();

  //@ts-ignore
  const lessons = useMemo(() => defineLessons(allCourses), [allCourses]);
  const isValid = query.length >= MIN_QUERY_LENGTH;

  if (!isValid) {
    return {
      searchResult: null,
      isValid,
      query,
      updateQuery,
    };
  }
  //@ts-ignore
  const allContent = [...allCourses, ...lessons];
  const searchedCourses = allContent.filter((item) => {
    return item.title?.toLowerCase().includes(query.toLowerCase());
  });
  const searchedExperts = experts?.filter(searchForExperts(query));

  return {
    searchResult: [
      ...searchedCourses,
      ...adaptExpertsToSearch(searchedExperts, t('screen_title_expertContent')),
    ],
    isValid,
    query,
    updateQuery,
  };
};
