import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import { FC, useEffect } from 'react';

import { StudyBlocks } from './components/StudyBlocks/StudyBlocks';
import { StudyHeader } from './components/StudyHeader/StudyHeader';
import { StudySearch } from './components/StudySearch/components/StudySearch/StudySearch';
import { StudyWaiting } from './components/StudyWaiting/StudyWaiting';
import styles from './StudyPage.module.css';
import { useUser } from 'hooks/useUser';
import { watchStudyEvent } from 'analytic/trackEvents';
import { HelmetComponent } from 'components/HelmetComponent/HelmetComponent';
import SwipeableModal from 'features/SwipeableModal/SwipeableModal';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { checkIsWebView } from 'utils/other';
export const StudyPage: FC = () => {
  const { hasCertificate } = useUser();
  useEffect(() => {
    watchStudyEvent();
  }, []);

  const { isMobile } = useAppWindowSize();
  const isWebView = checkIsWebView();

  return (
    <div className={styles.container}>
      <HelmetComponent
        title={
          '«Мы Ждём Ребёнка» - бесплатная образовательная программа подготовки к родам'
        }
        description={
          'Программа подготовки к родам и уходу за ребёнком - курсы, советы, статьи, 18 экспертов, 25 видеокурсов, сертификат, конспекты, чек-листы'
        }
      />
      {isMobile && !isWebView && <SwipeableModal />}
      <Header styled />
      <div className={styles.studyScreen}>
        <StudyHeader />
        <div className={styles.studyScreenWrapper}>
          <StudySearch />
          {!hasCertificate && <StudyWaiting />}
          <StudyBlocks />
        </div>
      </div>
      <Footer />
    </div>
  );
};
