import { FC } from 'react';
import styles from './Texts.module.css';
import classNames from 'classnames';
import { UsageOptions } from 'constants/constants';
import { STATUSES } from '../../constants';
import { useTranslate } from 'hooks/useTranslate';


export const Texts: FC<{
  currentStatus: UsageOptions;
}> = ({
  currentStatus,
}) => {
  const t = useTranslate();

  return (
    <>
      {
        STATUSES.map(({ text, status }) => (
          <div 
            className={ classNames(
              styles.text,
              status === currentStatus && styles.show,
            ) }
            key={ `text-${status}` }
          >
            { t(text)}
          </div>
        ))
      }
    </>
  )
};
