import { ReactElement, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginScreen from 'features/Auth/LoginScreen';
import { AppRoutes } from './constants';
import { SignUpByEmailScreen } from 'features/Auth/SignUpByEmailScreen';
import { SignUpSucessScreen } from 'features/Auth/SignUpSuccessScreen';
import { ForgotPasswordScreen } from 'features/Auth/ForgotPasswordScreen';
import { StatusScreen } from 'features/Auth/Onboarding/1-StatusScreen';
import { PeriodScreen } from 'features/Auth/Onboarding/2-PeriodScreen';
import { InterestsScreen } from 'features/Auth/Onboarding/3-InterestsScreen';
import { ProcessingScreen } from 'features/Auth/Onboarding/4-ProcessingScreen';
import { useAuthChangeListener } from 'features/Auth/hooks/useAuthChangeListener';
import { useExtToken } from 'hooks/useExtToken';
import FinalTestPageExt from 'pages/FinalTestPage/FinalTestPageExt';
import CertificatePageMobile from 'pages/CertificatePage/CertificatePageMobile';
import useScrollToTop from 'hooks/useScrollToTop';
import HomeScreen from 'features/Home/HomeScreen';
import { PrivacyScreen } from 'features/Privacy/PrivacyScreen';
import { TermsScreen } from 'features/Terms/TermsScreen';
import { useDeepLink } from 'hooks/useDeepLink';
import { ProfileScreen } from 'features/Profile/ProfileScreen';
import { ProtectedRoute } from './ProtectedRoute';

import { StudyPage } from 'pages/StudyPage/StudyPage';
import { CoursesCategory } from 'pages/CoursesCategory/CoursesCategory';
import CategoryPost from 'pages/CategoryPost/CategoryPost';
import CategoryAdvice from 'pages/CategoryAdvice/CategoryAdvice';
import CategoryCourse from 'pages/CategoryCourse/CategoryCourse';
import { ExpertPage } from 'pages/ExpertPage/ExpertPage';
import { SettingsScreen } from 'features/Settings/SettingsScreen';
import MainPage from 'pages/MainPage/MainPage';
import MainPageAdvice from 'pages/MainPageAdvice/MainPageAdvice';
import { FavoritePage } from 'features/Profile/FavoritePage/FavoritePage';
import Sitemap from 'features/Sitemap/Sitemap';
import MainPageInfo from 'pages/MainPageInfo/MainPageInfo';
import { ExpertsPage } from 'pages/ExpertsPage/ExpertsPage';
import MeditationScreen from 'pages/MeditationScreen/MeditationScreen';

import WaitingBabyPageFD from 'pages/WaitingBabyPage/WaitingBabyPageFD';
import WaitingBabyPageFM from 'pages/WaitingBabyPage/WaitingBabyPageFM';
import TextCoursePageExt from 'pages/TextCoursePageExt/TextCoursePageExt';

const TestPassedPage = lazy(
  () => import('pages/TestPassedPage/TestPassedPage')
);
const WaitingBabyPage = lazy(
  () => import('pages/WaitingBabyPage/WaitingBabyPage')
);
const StudyingPage = lazy(() => import('pages/StudyingPage/StudyingPage'));
const CoursesPage = lazy(() => import('pages/CoursesPage/CoursesPage'));
const TextCoursePage = lazy(
  () => import('pages/TextCoursePage/TextCoursePage')
);
const ChecklistPage = lazy(() => import('pages/ChecklistPage/ChecklistPage'));
const VideoPage = lazy(() => import('pages/VideoPage/VideoPage'));
const VideoPageExt = lazy(() => import('pages/VideoPage/VideoPageExt'));
const TestPage = lazy(() => import('pages/TestPage/TestPage'));
const FinalTestPage = lazy(() => import('pages/FinalTestPage/FinalTestPage'));
const CertificatePage = lazy(
  () => import('pages/CertificatePage/CertificatePage')
);
const ErrorPage = lazy(() => import('pages/ErrorPage/ErrorPage'));
const CertificateConfirm = lazy(
  () => import('pages/CertificateConfirm/CertificateConfirm')
);

// const checkIsProfileDisabled = ()
const Switch = (): ReactElement => {
  useAuthChangeListener();
  useScrollToTop();
  useDeepLink();
  useExtToken();
  return (
    <Routes>
      <Route path={AppRoutes.HOME} element={<HomeScreen />} />

      <Route path={AppRoutes.LOGIN} element={<LoginScreen />} />
      <Route path={AppRoutes.SIGN_UP} element={<SignUpByEmailScreen />} />
      <Route
        path={AppRoutes.SIGN_UP_SUCCESS}
        element={<SignUpSucessScreen />}
      />
      <Route
        path={AppRoutes.FORGOT_PASSWORD}
        element={<ForgotPasswordScreen />}
      />

      <Route path={AppRoutes.STATUS_SCREEN} element={<StatusScreen />} />
      <Route path={AppRoutes.PERIOD_SCREEN} element={<PeriodScreen />} />
      <Route path={AppRoutes.INTERESTS_SCREEN} element={<InterestsScreen />} />
      <Route
        path={AppRoutes.PROCESSING_SCREEN}
        element={<ProcessingScreen />}
      />
      <Route path={'/edu/search'} element={<StudyPage />} />
      <Route path={'/edu/:categoryId'} element={<CoursesCategory />} />
      <Route path={'/edu/:categoryId/advices'} element={<CoursesCategory />} />
      <Route path={'/edu/:categoryId/posts'} element={<CoursesCategory />} />
      <Route path={'/edu/:categoryId/courses'} element={<CoursesCategory />} />
      <Route path={'/final-test-page'} element={<FinalTestPage />} />
      <Route path={'/certificate-mobile'} element={<CertificatePageMobile />} />
      <Route path={'/waiting-page'} element={<WaitingBabyPage />} />
      <Route path={'/study-page'} element={<StudyingPage />} />
      <Route path={'/login'} element={<LoginScreen />} />
      <Route path={'/courses-page/:block'} element={<CoursesPage />} />
      <Route path={'/test-page/:block/:course'} element={<TestPage />} />
      <Route path={'/videos-page/:block/:course'} element={<VideoPage />} />
      <Route path={'/check-page/:block/:course'} element={<ChecklistPage />} />
      <Route path={'/certificate'} element={<CertificatePage />} />
      <Route path={'/certificate-confirm'} element={<CertificateConfirm />} />
      <Route path={'/edu/:categoryId'} element={<CoursesCategory />} />
      <Route path={'/final-test-page'} element={<FinalTestPage />} />
      <Route path={'/certificate-mobile'} element={<CertificatePageMobile />} />
      <Route path={'/waiting-page'} element={<WaitingBabyPage />} />
      <Route path={'/web-fm'} element={<WaitingBabyPageFM />} />
      <Route path={'/web-fd'} element={<WaitingBabyPageFD />} />

      <Route path={'/study-page'} element={<StudyingPage />} />
      <Route path={'/login'} element={<LoginScreen />} />
      <Route path={'/courses-page/:block'} element={<CoursesPage />} />
      <Route path={'/test-page/:block/:course'} element={<TestPage />} />
      <Route path={'/videos-page/:block/:course'} element={<VideoPage />} />
      <Route path={'/check-page/:block/:course'} element={<ChecklistPage />} />
      <Route path={'/certificate'} element={<CertificatePage />} />
      <Route path={'/certificate-confirm'} element={<CertificateConfirm />} />
      <Route
        path={'/edu/:categoryId/:simpleType/:contentId/expert/:expertId'}
        element={<ExpertPage />}
      />
      <Route
        path={'/edu/:categoryId/:simpleType/:contentId/expert'}
        element={<ExpertPage />}
      />
      <Route
        path={'/edu/:simpleType/:contentId/expert/:expertId'}
        element={<ExpertPage />}
      />
      <Route
        path={'/edu/:simpleType/:contentId/expert'}
        element={<ExpertPage />}
      />
      <Route path={'/experts/:expertId'} element={<ExpertPage />} />
      <Route path={'/experts/:expertId/:course'} element={<ExpertPage />} />
      <Route path={'/experts/:expertId/advices'} element={<ExpertPage />} />
      <Route path={'/experts/:expertId/courses'} element={<ExpertPage />} />
      <Route path={'/experts/:expertId/posts'} element={<ExpertPage />} />
      <Route
        path={'/test-passed/:block/:course'}
        element={<TestPassedPage />}
      />
      <Route
        path={'/pregnancy-page/:block/:course'}
        element={<TextCoursePage />}
      />
      <Route
        path={'/pregnancy-page-ext/:block/:course'}
        element={<TextCoursePageExt />}
      />

      <Route path={'/edu/advices'} element={<StudyPage />} />
      <Route path={'/edu/posts'} element={<StudyPage />} />
      <Route path={'/edu/courses'} element={<StudyPage />} />
      <Route path={'/edu/advices/:contentId'} element={<CategoryAdvice />} />
      <Route path={'/edu/posts/:contentId'} element={<CategoryPost />} />
      <Route path={'/edu/courses/:contentId'} element={<CategoryCourse />} />
      <Route path="*" element={<ErrorPage />} />
      <Route path={'/web-page'} element={<VideoPageExt />} />
      <Route path={'/video-page/:passed'} element={<VideoPageExt />} />
      <Route path={'/final-test'} element={<FinalTestPageExt />} />

      <Route path={AppRoutes.PRIVACY_SCREEN} element={<PrivacyScreen />} />
      <Route path={AppRoutes.TERMS_SCREEN} element={<TermsScreen />} />
      <Route path={AppRoutes.FAVORITE_PAGE} element={<FavoritePage />} />
      <Route
        path={AppRoutes.PROFILE_SCREEN}
        element={
          <ProtectedRoute>
            <ProfileScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path={AppRoutes.SETTINGS_SCREEN}
        element={
          <ProtectedRoute>
            <SettingsScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path={'/edu/:categoryId/posts/:contentId'}
        element={<CategoryPost />}
      />
      <Route
        path={'/edu/:categoryId/advices/:contentId'}
        element={<CategoryAdvice />}
      />
      <Route
        path={'/edu/:categoryId/courses/:contentId'}
        element={<CategoryCourse />}
      />
      <Route
        path={'/edu/:categoryId/courses/:contentId/:lessonId'}
        element={<CategoryCourse />}
      />
      <Route
        path={'/edu/courses/:contentId/:lessonId'}
        element={<CategoryCourse />}
      />
      <Route path={AppRoutes.PRIVACY_SCREEN} element={<PrivacyScreen />} />
      <Route path={AppRoutes.EXPERTS_SCREEN} element={<ExpertsPage />} />
      <Route path={AppRoutes.TERMS_SCREEN} element={<TermsScreen />} />
      <Route path={AppRoutes.STUDY_SCREEN} element={<StudyPage />} />
      <Route path={AppRoutes.MAIN} element={<MainPage />} />
      <Route path={'/main/:type'} element={<MainPageInfo />} />
      <Route
        path={'/main/meditation/:contentId'}
        element={<MeditationScreen />}
      />
      <Route
        path={'/main/lullabies/:contentId'}
        element={<MeditationScreen />}
      />
      <Route path={'/main-page-advice/:type'} element={<MainPageAdvice />} />
      <Route path={'/sitemap'} element={<Sitemap />} />
    </Routes>
  );
};

export default Switch;
