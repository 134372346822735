import {  separator, tb1c1_1, tb1c1_2, tb1c1_3, tb1c1_4, tb1c1_5 } from "assets/images";

export const b1_c1_text = {
    b1_c1_title: `Курс «План наблюдения и ведения беременности»`,
    b1_c1_description: `Спикер: Светлана Лучко, врач акушер-гинеколог, заведующий женской консультацией 4-ой городской поликлиники г. Минска`,
    b1_c1_text: `
${tb1c1_1}
Урок 1. Положительный тест на беременность: наши действия 

Беременность – важный и ответственный этап, как в жизни самой женщины, так и ее семьи и близких. Большинство женщин чувствуют себя хорошо в это волнующее время, доверяя естественной перестройке организма под чутким руководством природы. Однако чувствовать себя более уверенно помогут наши занятия, на которых мы расскажем о переменах происходящих в организме на фоне беременности, о том, как реагировать на возможные осложнения во время беременности, как наблюдаться в женской консультации. Это позволит вам получить удовольствие от беременности и сосредоточиться на главном – вынашивании и рождении здорового малыша. 

Итак, у вас задержка менструации, вы делаете тест на беременность и получаете две заветные полоски. «Я беременна!» – эта новость всегда вызывает противоречивые эмоции. С одной стороны – это огромное счастье. С другой стороны – это чувство страха, перемен в жизни. 

В душе всегда возникают разные вопросы: «Как будет протекать моя беременность? Смогу ли я выносить малыша? Что измениться в моем организме во время беременности?». Безусловно, это связано с чувством страха чего-то нового, незнакомого вам и вашему организму. 

Когда женщина узнает о беременности, рекомендуем ей в ближайшее время записаться на прием к врачу акушеру-гинекологу. 

Почему этот прием так важен? Положительный тест на беременность, к сожалению, еще не свидетельствует  о том, что беременность находится в матке.

Первый визит к врачу необходим, чтобы установить, где локализуется беременность. Это очень важно для определения дальнейшей тактики наблюдения. 

Помимо маточной беременности, существует и внематочная беременность. Это ситуация, когда плодное яйцо находится вне полости матки. Это может быть:

трубная беременность – встречается чаще всего. Плодное яйцо локализуется в одной из маточных труб;
яичниковая беременность, когда плодное яйцо расположено в яичнике);
брюшная беременность, когда плодное яйцо может прикрепиться к сальнику, кишке или брюшине.
${tb1c1_2}

И независимо от того, где располагается плодное яйцо, тест на беременность всегда будет положительным. 

Итак, вы записались на первый прием к врачу акушеру-гинекологу. Что же будет происходить в кабинете врача?

Врач акушер-гинеколог выполняет осмотр на кресле. Он проведет  бимануальное исследование, т.е влагалищное исследование двумя руками. И уже при бимануальном исследовании врач может определить  увеличена матка или нет. Но также всегда используют и вспомогательные методы диагностики – УЗИ органов малого таза (ультразвуковое исследование). 

Если в полости матки на УЗИ врач не видит плодное яйцо, то всегда высока вероятность, что у женщины внематочная беременность. 

Чем опасна внематочная беременность? Наблюдается массивное внутрибрюшное кровотечение, резкий болевой синдром. Ведь кроме матки ни один орган в жизни женщины не предназначен для вынашивания беременности. Если плодное яйцо не визуализируется в маточной трубе, нет жидкости в брюшной полости, то врач акушер-гинеколог предлагает женщине прийти через пару дней для УЗИ контроля. 

Женщине обязательно назначают исследование крови на гормон беременности. Вы наверняка о нем слышали – сокращенно он называется ХГЧ, а расшифровывается как хорионический гонадотропин. При этом нужно контролировать его уровень в динамике. То есть женщина сдает кровь на ХГЧ несколько раз. Если беременность развивается, то уровень ХГЧ будет быстро увеличиваться в разы.

Также врач акушер-гинеколог даст некоторые рекомендации пациентке. В обязательном порядке необходимо вызвать бригаду скорой помощи, если у женщины:

возникнут боли внизу живота
появятся кровянистые выделение
появится предобморочное состояние

В этом случае показана госпитализация в стационар. 

Как мы говорили ранее, врач акушер-гинеколог может предложить женщине прийти через пару дней для УЗИ контроля. Если при повторном посещении врач-акушер-гинеколог на УЗИ не находит в полости матки плодного яйца, также есть высока вероятность внематочной беременности. В этом случае выдается направление и в дальнейшем женщине рекомендуют наблюдаться  в стационаре. 

Если плодное яйцо находится в полости матки, то врач акушер-гинеколог назначает следующий визит уже для постановки на учет по беременности. 

Также женщине назначают прием фолиевой кислоты (0,4 мг в сутки). Прием фолиевой кислоты предотвращает формирование пороков нервной трубки у плода. В Республике Беларусь согласно клиническим протоколам показан обязательный прием фолиевой кислоты до 12 недель беременности. Так как именно в этом сроке у плода происходит закладка  нервной трубки, которая в дальнейшем является «основателем» всей центральной нервной системы в организме. 

Согласно Клиническому протоколу «Медицинское наблюдение и оказание медицинской помощи женщинам в акушерстве и гинекологии»  №17 от 19.02.2018 г.

Прием фолиевой кислоты рекомендует также Всемирная организация здравоохранения. 0,4 мг ежедневно для профилактики анемии, послеродового сепсиса, рождения маловесного ребенка и преждевременных родов.

Источник: Рекомендации ВОЗ по оказанию дородовой помощи для формирования положительного опыта беременности

Подведем итог: когда женщина узнала о беременности, ей рекомендуется в ближайшее время посетить врача акушера-гинеколога. Первый прием важен для того, чтобы удостовериться, что плодное яйцо находится в полости матки. Также женщине рекомендуется начать прием фолиевой кислоты и принимать до 12 недель беременности включительно.
${separator}

Урок 2. Почему важно посещать врача акушера-гинеколога и ЖК во время беременности?

Вы узнали, что беременны и уже сходили на первый прием к акушеру-гинекологу. Как мы обсуждали в прошлом уроке, в случае, когда плодное яйцо находится в полости матки, врач акушер-гинеколог назначает следующий визит уже для постановки на учет по беременности. Что такое постановка на учет и почему это важно для будущей маме? Нужно ли посещать врачей, как часто и зачем? Разберемся по этапам, что ждет будущую маму после чудесной новости о беременности. 

Беременность – это физиологическое состояние женщины. Но все же наблюдать за ее течением – очень важно.

Мониторинг беременности женщины выполняет врач акушер-гинеколог женской консультации, которая закреплена по территориальному принципу. Также это может быть врач акушер-гинеколог любого медицинского центра, который имеет лицензию на ведение беременности. Кого выбрать? Это решать в первую очередь вам, но наблюдаться советуем всем женщинам. Почему это так важно?

Врач акушер-гинеколог может профессионально оценить все изменения, которые происходят в организме, а также оценить внутриутробное развитие плода. 
Врач акушер-гинеколог может своевременно скорректировать все осложнения, которые могут возникнуть в период беременности. 
Если у вас есть хронические заболевания, в том числе тяжелая патология, инвалидность, либо заболевание возникло во время беременности, то посещения врача особенно важны и необходимы. Беременность – это колоссальная нагрузка на здоровый организм женщины. А в сочетании с хронической патологией, это всегда риск развития осложнений как со стороны матери, так и со стороны малыша. Поэтому врач акушер-гинеколог,  а также иногда и врачи смежных профильных специальностей, могут оценить степень риска возникновения осложнений, провести профилактические мероприятия для того, чтобы вы ощутили радость материнства.  

Разберемся, как часто женщине рекомендуется посещать врача акушера-гинеколога.  

Согласно действующим в Республике Беларусь Клиническим протоколам предусмотрено 9 плановых посещений врача акушера-гинеколога для оценки состояния здоровья во время беременности самой женщины. Обсудим, в какие сроки рекомендуют выполнить эти 9 плановых посещений.

до 12 недель; 
в 14 недель;
В 20 недель;
В 26 недель;
В 30 недель; 
В 34 недели;
В 36 недель;
В 38 недель;
А также на 40-41 неделе беременности.  

Согласно Клиническому протоколу «Медицинское наблюдение и оказание медицинской помощи женщинам в акушерстве и гинекологии»  №17 от 19.02.2018 г.

В следующих уроках мы обязательно обсудим с вами подробно, что вас ждет на каждом из этих этапов, каких врачей нужно будет пройти и какие анализы сдать.

Теперь поговорим о женской консультации и постановке на учет.

В Беларуси медицинское наблюдение женщин во время беременности осуществляет врач акушер-гинеколог и акушерка  женской консультации.

В соответствии с Клиническим протоколом «Медицинское наблюдение и оказание медицинской помощи женщинам в акушерстве и гинекологии».

Женская консультация является структурным подразделением территориальной поликлиники. Она может располагаться или в самом здании поликлиники на отдельном этаже, а может располагаться в отдельном здании на территории поликлиники. Также во многих районных центрах женская консультации может располагаться на территории роддомов. 

На учет по беременности женщину берет участковый врач акушер-гинеколог, закрепленный по вашему району обслуживания. В случае, если в силу определенных обстоятельств, вы не хотите наблюдаться у данного врача, вы можете поменять врача акушера-гинеколога. Для этого нужно написать заявление, а далее его подпишет заведующий женской консультацией. 

Что же значит «встать на учет»? Диспансерный учет по беременности – это наблюдение за течением беременности. Т.е. после постановки на учет вам рекомендуется посещать врача акушера-гинеколога согласно установленному графику явок. 

При первой явке в женскую консультацию врач акушер–гинеколог заполняет индивидуальную карту беременной и родильницы. Для всех беременных страны она одинакова.

В течение всего периода беременности для вас это основной медицинский документ. Туда вклеиваются результаты лабораторных исследований, инструментальных обследований (все протоколы УЗИ, т.е. расшифровка результатов УЗИ). Также в этот документ вносятся все заключения врачей смежных специальностей и все выписные эпикризы из стационара (в случае если была госпитализация). Эпикризы – это медицинские документы, где содержится информация о состоянии здоровья человека, диагнозе, проведенном лечении, а также рекомендации врача. Простыми словами, это заключение врача. 

Индивидуальная карта беременной и родильницы хранится в кабинете врача акушера-гинеколога, у которого вы состоите на учете.  

Также акушеркой женской консультации заполняется еще один документ по беременности – это обменная карта. Она является дубликатом индивидуальной карты беременной и родильницы. Обменная карта выдается вам на руки, заполняется на каждом визите и будет здорово, если она всегда будет у вас под рукой. Ведь в ней содержится вся информация о течении вашей беременности.

Переходим к следующим урокам, в котором подробно обсудим объем всех диагностических обследований по триместрам беременности.

${tb1c1_3}

Урок 3. Этапы беременности

Какие этапы беременности  существуют? Как считают те самые недели беременности? Почему принято выделять триместры беременности и каковы сроки каждого?

Срок беременности определяется от 1 дня последней менструации (если они регулярные) и считается в неделях. Срок беременности определяет врач акушер-гинеколог на первом приеме. 

Если месячные нерегулярные, либо вы не помните дату начала последней  менструации, срок беременности всегда устанавливается по УЗИ, либо по генетическому УЗИ – это наиболее достоверный срок. 

Многие женщины зачастую сами узнают срок беременности – например, с помощью различных мобильных приложений.

Весь период беременности составляет 42 недели. Предполагаемую дату родов подсчитать тоже очень просто. К первому дню менструации прибавляется число 7, а от месяца последних месячных отсчитывается назад 3 месяца. Например, первый день последних месячных 20 февраля, значит срок родов – 40 недель – 27 ноября. Но помните, что роды могут начаться и в 38 недель и в 41 неделю. Таким образом, разбежка в дате родов составляет 2 недели до 27 ноября и 2 недели после 27 ноября. 

Если роды происходят до 37 недель – это преждевременные роды.
Если после 42 недель – это запоздалые роды.
В сроках 38-42 недели – это срочные роды.

К сожалению, не всегда беременность заканчивается родами в срок с рождением здорового доношенного малыша. Специально для вас совместно с группой психологов и психотерапевтов мы разработали большой видеокурс, где мы поговорим о психологической поддержке и реабилитации в таких ситуациях. 

Весь период беременности принято разделять на 3 триместра. 

1 триместр – от 0 до 13 недель беременности
2 триместр – от 14 до 27 недель
3 триместр – от 28 недель до 42 недель

Переходим к следующим большим и очень важным урокам, где вместе пройдем по пути женщины в каждом триместре. Вы узнаете, каких врачей вам рекомендуется посетить и зачем, какие анализы сдать и что вы будете чувствовать в эти прекрасные периоды.
${separator}

Урок 4. 1 триместр беременности. Изменения в самочувствии, первый визит к гинекологу

В следующих уроках обсудим каждый триместр беременности. Разберемся, какие обследования, анализы и визиты к врачам ждут будущую маму и на каких неделях беременности. Забегая вперед, скажу, что специально для вас мы подготовили полезный чек-лист к курсу, где мы собрали все обследования и анализы по неделям, чтобы этот план всегда был у вас под рукой в понятной и простой форме.

1 триместр беременности

В акушерстве принято, что срок беременности определяется от 1 дня последней менструации, при условии, что менструальный цикл регулярный

1 триместр беременности – это срок беременности от 0 до 13 недель. 

Это, пожалуй, самый значимый период беременности. В этот промежуток времени плодное яйцо прикрепляется в полости матки и далее развивается эмбрион – наш будущий малыш.

Обсудим возможные неприятные ощущения в этот период.

Усталость, сонливость – чувства, обусловленные происходящими гормональными изменениями в организме. Рекомендуем чаще проветривать помещение, а также гулять на свежем воздухе.    

Головокружение, обмороки – это происходит обычно при резкой перемене положения тела, чаще при перемене из горизонтального положения в вертикальное. Оно обусловлено либо недостаточным кровоснабжением мозга, низким артериальным давлением, либо низким содержанием кислорода в крови. Старайтесь как можно медленнее вставать утром с кровати, стула и т.д. Если вдруг вы почувствовали головокружение, шум в ушах,  необходимо как можно быстрее сесть, опустить голову к коленям. По возможности – открыть окно, обеспечить доступ свежего воздуха.  

Тошнота, рвота – это связано с реакцией вашего организма на условно инородное тело (то есть беременность) внутри вас. Рекомендуется есть часто и небольшими порциями, тщательно пережевывать пищу. Между приемами пищи рекомендуется пить воду маленькими глотками. В случае  утренней рвоты или тошноты за 15-30 минут до подъема с постели можно скушать сухой крекер, рассосать мятный леденец либо выпить маленькими глотками полстакана щелочной негазированной минеральной воды. Будет здорово, если в составе она будет содержать такие микроэлементы, как натрий, калий, магний, хлор. 

Обильное выделение слюны может беспокоить как в течение всего дня, так и ночью. В таких случаях рекомендуется регулярное полоскание рта мятными растворами, рассасывание мятных леденцов.    

Обследования и визиты к врачам

При физиологическом течении беременности в 1 триместре женщине рекомендуется посетить врача акушера-гинеколога женской консультации 2 раза. 

Для начала давайте разберемся, почему важна первая явка – явка до 12 недель беременности.

При первой явке врач акушер акушер-гинеколог оценивает уже имеющиеся у вас хронические заболевания. Например, пороки сердца, сахарный диабет, артериальную гипертензию, цирроз печени, заболевания почек, онкологические заболевания, бронхиальная астма и другие патологии. И в зависимости от этого врач назначит вам дополнительные обследования, а также дополнительные консультации врачей профильных специальностей. Это может быть кардиолог, ревматолог, онколог и т.д.

Таким образом, врач акушер-гинеколог оценивает, какие возможные осложнения могут возникнуть у вас во время беременности, и составляет индивидуальный план ведения беременности. С учетом тех факторов риска, что выявил врач на приеме, определяется степень риска осложнений течения беременности. От этого будет зависеть, как часто вы будете посещать врачей и каких именно.

К сожалению, есть перечень заболеваний, при которых беременность противопоказана. Врачи обязательно информируют вас об этом. Но это не значит, что вам нужно будет прервать долгожданную беременность. Вам обязательно расскажут, какие возможные осложнения во время беременности могут возникнуть и сообщат, что возможно ухудшение течения заболевания на фоне беременности.  

Как показывает опыт, зачастую приходиться разговаривать не только с самой беременной, но и ее мужем и другими членами семьи. Порой решение принять весьма сложно. Однако врачи на протяжении беременности не оставят вас без медицинской помощи, они всегда будут рядом, всегда помогут и  поддержат.

Первое посещение также необходимо для постановки на диспансерный учет по беременности. С собой необходимо взять паспорт. Врач акушер-гинеколог (или акушерка) заполняет индивидуальную карту беременной и родильницы только при наличии паспорта.  

В индивидуальной карте беременной и родильницы собирается информация о всех ваших предыдущих беременностях и их исходах, далее о всех ваших перенесенных заболеваниях, операциях до наступления настоящей беременности, а также заболеваниях и вредных привычках у вас и вашего супруга. 

При 1-ом визите также определяется индекс массы тела (ИМТ), оцениваются размеры таза, окружность голени. 

Разберемся, для чего нужно определение индекса массы тела.

Чтобы определить, нет ли у вас дефицита веса либо избыточного веса.
Чтобы отслеживать прибавку веса в течение беременности. Это так называемый «нулевой» отсчет веса. 

Окружности голени измеряются, чтобы исключить отеки во время беременности.

Также на 1 визите врач акушер-гинеколог производит бимануальное влагалищное исследование. Осуществляется забор мазков. 

1. Мазок на онкоцитологию. Он важен, чтобы исключить предраковые заболевания – дисплазии.
2. Мазок на флору из влагалища и бактериологическое исследование из влагалища. Наличие инфекции во влагалище – равно высокий риск инфицирования плода.
3. Также проводится обследование на гонорею, обследование на инфекции передаваемые преимущественно половым путем (ИППП): хламидии, микоплазмы и уреаплазмы, на вирус папилломы человека и вирус герпеса 1-2 типа. Если что-либо из этих инфекций выявляется у женщины, то ей рекомендуется пройти курс терапии, чтобы избежать инфицирования плода. Так по литературным данным около 80% самопроизвольных выкидышей (неразвивающихся, замерших беременностей) связано с наличием той или иной  инфекции, в том числе и во влагалище.

Также в первом триместре вас ждет 1-ый УЗИ скрининг плода. Оно выполняется в сроках 11-13 недель. На приеме акушер-гинеколог выдаст вам направление в закрепленный перинатальный центр для выполнения УЗИ. Либо по желанию пациентка может выполнить его в частном медицинском центре. 

Основная  цель первого УЗИ – исключить врожденные пороки развития плода (т.е. генетическую патологию). Также обязательно в этом сроке исследуется венозная кровь беременной на биохимический скрининг генетической патологии у плода. Этот анализ рекомендуется проводить всем беременным. В крови определяют так называемые маркеры различных генетических пороков. Результат этого УЗИ вам нужно будет обязательно принести на следующий визит к врачу акушеру-гинекологу.

Также врач акушер-гинеколог на приеме расскажет вам о пособии. Согласно официальным документам, в случае постановки на учет по беременности до 12 недель, а также при условии регулярных посещений врача акушера-гинеколога и выполнений всех предписаний врачей женщинам выдается поощрительное пособие.

Согласно выписке из постановления Совета Министров Республики Беларусь  от 2012 г «Об утверждении положения о порядке назначения и выплаты государственных пособий семьям, воспитывающим детей».

Специально для вас, дорогие будущие родители, мы разработали отдельный урок, где подробно расскажем о всех пособиях для родителей, как и где их можно получить.

Переходим ко второй части урока про первый триместр, где обсудим лабораторные исследования и то, каких врачей и зачем вам нужно будет посетить в этот период.

${separator}

Урок 5. 1 триместр беременности. Лабораторные исследования, посещения специалистов

Для начала обсудим лабораторные обследования, которые рекомендуется сдать женщине до 12 недель.

Общий анализ крови. Важно определить уровень гемоглобина – это источник кислорода, необходимый для развития плода. А также врач смотрит на ряд других показателей, чтобы оценить свертываемость крови, исключить инфекцию во время беременности.
Анализ мочи. По анализу можно заподозрить патологию почек, а также наличие инфекций мочевыводящих путей или пиелонефрита, то есть воспалительного заболевания почек. Такие проблемы довольно часто встречаются у беременных женщин. 
Биохимический анализ крови. По нему оценивается целый ряд показателей, в том числе важные для беременной сывороточное железо и ферритин. Благодаря этим показателям можно выявить анемию. Как отмечает Всемирная организация здравоохранения, более 40% беременных женщин в мире имеют анемию. И даже при нормальном уровне гемоглобина крови, снижение этих показателей требует назначения препаратов железа. Это так называемый латентный (то есть скрытый) дефицит железа, который часто встречается у будущих мам.
Кровь на свертываемость (или коагулограмма). Этот анализ нужен, чтобы оценить, есть ли риск тромбозов или риск кровотечений.
Кровь на сахар. По этому анализу мы можем диагностировать гестационный сахарный диабет.
Анализ на групповую принадлежность крови по системе АВО и определение резус-фактора. Обязательно определяется также группа крови и резус фактор мужа. Почему это важно? Если у вас и у вашего супруга (у обоих) положительный или отрицательный резус фактор крови, это благоприятный вариант для малыша. И тогда особого дополнительного наблюдения не требуется. Если у вас отрицательный резус фактор, а у мужа – положительный, высока вероятность развития гемолитической болезни у плода. Это когда плод наследует положительный резус фактор мужа. У беременной (особенно у повторнобеременных женщин) в крови вырабатываются специальные антирезусные антитела. Проще говоря, иммунитет женщины принимает эритроциты ребенка за чужеродные. В таком случае необходим дополнительный контроль за уровнем антител.          
Кровь на сифилис, ВИЧ, маркеры вирусных гепатитов В и С и обследование на комплекс TORCH-инфекций (токсоплазмоз, цитомегаловирус, краснуху и хламидиоз). Эти анализы помогают определить, является ли женщина носителем этих инфекций. При выявлении маркеров на сифилис – беременных направляют для консультации и повторного забора крови в кожно-венерологический диспансер. При выявлении маркеров вирусных гепатитов, беременной рекомендуется консультация врача-инфекциониста.  

Также на приеме врач акушер-гинеколог информирует вас о том, каких специалистов нужно пройти. Где проходить этих специалистов – решать вам. Можно записаться самостоятельно и пройти всех специалистов в поликлинике, либо в любом частном медицинском центре. 

Если вы проходите узких специалистов в своей поликлинике, то на прием не забудьте взять обменную карту. 

Если проходите в частном порядке, то затем принесите консультативные заключения и ваш врач вклеит их в индивидуальную карту беременной и родильницы.

До 12 недель беременности женщине рекомендуется пройти следующих врачей специалистов: 

Терапевта. Зачастую мы вступаем в беременность с накопленным багажом перенесенных заболеваний или имеющейся патологии. Терапевт оценивает степень тяжести хронической патологии либо впервые выявляет патологию. Также терапевт определяет риск возникновения различных осложнений во время беременности.    
Офтальмолога. Он определяет, если у вас патология зрения. Патологические изменения на сетчатке всегда требуют дополнительного контроля в течение беременности и влияют на метод родоразрешения (то есть будут это естественные роды или кесарево сечение). Иногда даже выполняется хирургическая лазерная коррекция на сетчатке во время беременности.  
Отоларинголога. Он определяет состояние органов уха, горла, носа. Патология слуха может носить и наследственный характер и передаваться малышу. Более того, воспалительные изменения в полости носа, горла, уха – могут быть причиной передачи инфекции от матери к плоду. 
Эндокринолога. Он выявляет патологию щитовидной железы, поджелудочной железы, гипофиза, а также болезни обмена веществ. Если у женщины есть какие-либо патологии, то высок риск неблагоприятного исхода беременности. Если выявить эти патологии своевременно и проводить мониторинг в команде с врачом, то этого можно избежать и родить здорового доношенного малыша.    
Стоматолога. Он оценивает состояние вашей ротовой полости. Иногда кариес, который своевременно не пролечен, может быть причиной инфицирования. Как следствие, малыш может родиться преждевременно или с наличием внутриутробной инфекции.


Если у будущей мамы есть патология щитовидной железы, либо вырабатывается много или мало гормона щитовидной железы, ей обязательно выдадут талон на УЗИ щитовидной железы. 

Если есть заболевания печени, почек, кишечника – женщину направят на УЗИ органов брюшной полости.

Беременным с пороками сердца (врожденными или приобретенными), а также оперированными на сердце кардиологи назначают дополнительно (помимо ЭКГ) УЗИ сердца.    

Беременным с артериальной гипертензией терапевты дополнительно назначают суточное мониторирование артериального давления.

Анализы и обследования для супруга

1. Обследование на ВИЧ-инфекцию
2. Флюорография
3. Анализ на групповую принадлежность крови по системе АВО и резус принадлежность при отрицательном резус факторе у беременной

Второе посещение врача ждет женщину после 12 недель беременности (это может быть 13 или 14 недель). Принесите на этот прием протокол УЗИ плода. А также к этому визиту рекомендуется сдать все лабораторные анализы и желательно пройти всех врачей узких специальностей.

Также сдается общий анализ мочи. Хочу пояснить, что во время беременности практически при каждой явке сдается анализ мочи. Контроль мочи необходим, чтобы исключить инфекции мочевыводящих путей, которые нередко встречаются у будущих мам.

На этом визите акушерка выпишет вам обменную карту. Обменная карта выдается вам на руки и ее рекомендуется приносить с собой на каждый последующий прием к врачу акушеру-гинекологу.

Подводя итог вышесказанному, отмечу, что безусловно, никто не может вас заставить посещать врача акушера-гинеколога или сдавать те или иные анализы. Конечно, выбор всегда за вами. Но помните: если вы хотите знать, как реагирует организм на беременность, какие изменения в нем происходят в течение этих важных 9 месяцев, то рекомендуем выполнять лабораторные исследования и получать консультации специалистов. Также важен мониторинг внутриутробного развития малыша. Выполнить это возможно только посещая врача акушера-гинеколога. Это даст вам состояние внутренней защищенности и уверенности в благоприятном завершении беременности – рождении здорового малыша!
${separator}

Урок 6. 2 триместр беременности

2 триместр беременности начинается с 14 недель и длится до 27 недель беременности. 

Это наиболее благоприятный период течения физиологической беременности. Организм постепенно адаптируется к беременности, неприятные ощущения в виде тошноты, рвоты, головокружения, сонливости проходят. У плода полностью сформированы и функционируют практически все органы и системы (за исключением легких). 

Плод начинает переворачиваться в полости матки, и вы с 18-20 недели начинаете ощущать шевеления плода.  Вначале это единичные, слабые толчки. Затем все чаще и отчетливее вы понимаете, как шевелится малыш. 

В течение 2-го триместра рекомендуется посетить врача акушера-гинеколога 3 раза: 
14 недель
20 недель
24-26 недель 

Явка в 14 недель. Как мы говорили в прошлом уроке, после 12 недель женщина второй раз приходит к врачу акушеру-гинекологу – это может быть визит на сроке 13 или 14 недель. На него женщина приносит протокол первого УЗИ, также сдает общий анализ мочи. Напомню, к этому визиту рекомендуется сдать все лабораторные анализы и желательно пройти всех врачей узких специальностей.

Явка в 18-20 недель беременности. К визиту женщине необходимо сдать общий анализ мочи. Также, если у женщины отсутствуют антитела к токсоплазме и цитомегаловирусу, сдается повторный анализ крови на TORCH-инфекции. 

На этом приеме вам выдадут талон на 2-ой УЗИ скрининг плода. Он выполняется в сроках 18-21 неделю беременности. 

Его цель – исключить врожденные пороки сердца. Также дополнительно выполняется УЗИ сердца у плода. Диагностируются такие пороки, как расщелина твердого и мягкого неба (в народе это называется «заячья губа» и  «волчья пасть»). Отдельно смотрят структуры головного и спинного мозга,  органы брюшной полости, конечности плода. Также в этом сроке можно определить пол будущего малыша. На аппаратах УЗИ экспертного класса можно сделать  фотографии малыша  внутриутробно, даже в 5D формате. Можно зафиксировать двигательную активность, пересчитать пальчики на ручках и ножках.

Явка в 24-26  недель. На этот прием необходимо принести на прием протокол 2-го УЗИ скрининга плода. Из лабораторных анализов выполняется общий анализ крови, общий анализ мочи и глюкозотолерантный тест с 75 г. глюкозы (ГТТ). Тест выполняется для исключения (выявления) сахарного диабета во время беременности.

Глюкозотолерантный тест проводится в поликлинике. Накануне проведения теста необходимо в аптеке купить 5 пакетиков глюкозы по 15 грамм и взять с собой 200 мл питьевой воды. 

Вы приходите в поликлинику натощак. Важно, чтобы последний прием пищи был за 12 часов до выполнения теста. У вас берут кровь и сразу определяют уровень сахара в крови. Если уровень сахара не более 5 млмоль/л, то вам предлагают выпить раствор глюкозы. Для этого вы берете 5 пакетиков глюкозы, растворяете их в 200 мл воды и выпиваете. Далее определяют уровень сахара через 1 час после приема глюкозы и затем – еще через 1 час.

Таким образом, готовясь к тесту, вам необходимо понимать, что в поликлинике вы проведете около 2-ух часов. Если вы предполагаете, что вам будет трудно выпить такой насыщенный раствор сахара, то разрешается выдавить ½ лимона в раствор. 

Многие женщины не хотят выполнять этот тест, аргументируя тем,  что уровень гликемии натощак у них всегда в норме. Однако выявить или исключить сахарный диабет во время беременности можно только выполнив этот тест. Именно когда дается нагрузка на поджелудочную железу (ведь вы принимаете раствор глюкозы), тогда мы можем оценить выработку гормона инсулина, которого вырабатывается либо достаточно, либо мало. После этого анализа вам могут назначить дополнительно  консультацию эндокринолога.

Учитывая, что частота выявления гестационного сахарного диабета достаточно велика, мы подготовили для вас отдельный урок, чтобы обсудить особенности ведения беременности при этом диагнозе.   

${tb1c1_4}

Урок 7. 3 триместр беременности 

Мы подошли к важному и очень волнительному периоду – заключительному, третьему триместру беременности. Совсем скоро ваш малыш появится на свет! А пока наша задача – сделать все возможное, чтобы сохранить свое здоровье и здоровье малыша. Обсудим, что вас ждет в этот период.

3 триместр беременности начинается с 28 недели и длится до 42 недели беременности. 

Пожалуй, это самый ответственный и нелегкий период беременности. У вас уже виден животик, он округляется и с каждым днем становится все заметнее и заметнее. Вместе с этим может появляться одышка при длительной ходьбе либо при подъеме по лестнице. 

Могут появиться позывы к частому мочеиспусканию. Это связано с тем, что опускается головка плода. Поэтому хочу посоветовать вам приобрести дородовый бандаж. Надевать его нужно в положении лежа и носить в течение дня, на ночь – снимать. Вы можете проконсультироваться с врачом. Он посоветует, какой бандаж выбрать.

Также у вас могут появиться отеки либо могут усилиться сосудистые звездочки на ногах. Для этого можно приобрести компрессионный трикотаж.  Ношение компрессионного трикотажа как помощь при варикозном расширении вен и отеках рекомендует и Всемирная организация здравоохранения.

Источник: Рекомендации ВОЗ по оказанию дородовой помощи для формирования положительного опыта беременности

Он имеет 2 вида компрессии:

Первую. Ее рекомендую беременным, у кого нет варикозного расширения вен.
Вторую. Ее рекомендую, когда есть варикозная болезнь и необходим лечебный эффект. 

Эластичный трикотаж нужно надевать утром, лежа, не вставая с постели. Если вы уже встали, необходимо 1-2 часа полежать, а после надеть чулки. Как правильно выбрать его? Конечно, лучше посетить врача-флеболога, чтобы получить полную информацию о необходимой коррекции. Также врач обязательно поможет вам определиться, какой тип компрессионного трикотажа выбрать.

В третьем триместре вы уже активно общаетесь с малышом, отчетливо понимаете его шевеления. И здесь я хочу рассказать о тесте движений плода «считай до 10».

Начиная с 28 недель беременности, беременная может проводить такой тест. В течение дня вы должны ощущать 10 полноценных шевелений плода и отмечать их. Можно делать это в блокноте или телефоне. Если двигательная активность снижена или наоборот повышена – рекомендуем посетить врача женской консультации для записи КТГ плода.  

Начиная с 35 недель у вас могут появиться тренировочные схватки. Это нерегулярные, быстро проходящие маточные сокращения, иногда болезненные. Часто они начинаются по вечерам либо под утро. Подготовка к родам начинается ориентировочно за месяц до предполагаемой даты родов. И именно тренировочные схватки – это своеобразная подготовка организма женщины и плода к предстоящим родам. 

Женскую консультацию в третьем триместре необходимо посещать чаще. Всего определено 5-8 явок. Увеличение частоты явок может быть связано с особенностями протекания беременности (например, чаще посещают женскую консультацию будущие мамы при резус-конфликте, многоплодной беременности).

В 30 недель беременности вы сдаете повторный большой блок лабораторных анализов, который сдавали при постановке на диспансерный учет. Давайте еще раз перечислим их:

общий анализ крови;
общий анализ мочи;
биохимический анализ крови;
кровь на свертываемость (коагулограмма);
кровь на сахар;
кровь на сифилис, ВИЧ, маркеры вирусных гепатитов В и С;
также при резус отрицательной принадлежности крови обязательно кровь на титры антител. В прошлых уроках мы с вами уже обсуждали тему резус-конфликта и важности контроля за уровнем антител.

Врач акушер-гинеколог обязательно осматривает вас на кресле, осуществляет забор мазков на флору. Также проводится бактериологическое исследование на чувствительность к антибиотикам. Давайте разберемся, зачем оно нужно.

Влагалище – это нестерильная среда, в нем «живут» разные микроорганизмы. Для того, чтобы узнать какие они –  патогенные (то есть плохие) или условно-патогенные (то есть которые в норме могут присутствовать) и необходимо выполнить этот анализ. Если мы мы получим рост патогенных микробов или повышенный уровень непатогенных микробов – необходима санация влагалища. Простыми словами – антибактериальная обработка. Чувствительность к антибиотикам в этом случае определяет, каким препаратом лучше лечить этот микроб.  Лечить нужно всегда, потому что можно внутриутробно «заразить» своего малыша. Как следствие малыш может родиться с врожденной внутриутробной пневмонией.  Обратите внимание: лечение назначает только врач акушер-гинеколог.        

Также в этом сроке рекомендуется повторно посетить врача терапевта. Терапевт осмотрит вас, оценит, как протекают ваши хронические болезни (если они есть) на фоне беременности. Также именно в этом сроке увеличивается объем циркулирующей крови, резко возрастает нагрузка на организм матери. Именно поэтому и важен осмотр терапевта, чтобы он оценил здоровье беременной на фоне увеличения нагрузки. 

На каждом приеме врач акушер-гинеколог (или акушерка) обязательно измеряет окружность живота и высоту стояния дна матки. Измерения проводят сантиметровой лентой. Высота стояния дна матки измеряется от верхнего краешка лонного сочленения до дна матки (дно матки – самая высокая часть вашего беременного животика). 

Высота стояния дна матки должна соответствовать сроку беременности. Считается она просто: сколько недель – столько сантиметров. Если высота стояния больше срока беременности – можно заподозрить многоводие или вероятность крупного плода. Если высота стояния дна матки меньше – наоборот, маловодие или задержку массы тела плода.

Также врач измеряет окружность голени в сантиметрах. Цель все та же – исключить отеки. На каждом последующем приеме объем голени сравнивается с предыдущим. Резкое увеличение объема голени – маркер отеков во время беременности.   

Также с этого срока беременности вы начинаете записывать тоны сердца плода (или сокращенно КТГ). КТГ пишется, начиная с 30 недель и при каждой последующей явке. Благодаря КТГ врачи могут контролировать и наблюдать за внутриутробным состоянием плода.

В 30 недель беременности, если вы работаете, то вам выдается лист нетрудоспособности по беременности и родам сроком на 126 календарных дней. Если вы не работаете, то выдается справка сроком на 126 календарных дней по беременности и родам.

Я советую вам сделать ксерокопию листа нетрудоспособности, так как оригинал вы отдаете по месту работы и вам начисляют деньги. А ксерокопию вы вкладываете в свою обменную карту. Если у вас будут роды будут путем кесарева сечения или будет применяться извлечение плода вакуум–экстрактором, то данный лист нетрудоспособности продлиться еще на 14 дней. И врачи акушеры гинекологи в стационаре при выписке продлят вам предыдущий ваш лист нетрудоспособности по беременности и родам.     

Следующее посещение ждет вас в 34 недели. На этом сроке необходимо сдать общий анализ мочи. Также во время этого посещения берется бактериологическое исследование из прямой кишки на определение гемолитического стрептококка группы B.  Это очень опасный микроб. Он может приводить к массивному микробному поражению плода внутриутробно. Если его выявить вовремя, то врачи смогут грамотно подготовить малыша к рождению. В родах родильнице всегда вводится внутривенно антибиотик, чтобы избежать проблем у малыша после рождения. Когда рождается ребенок, у него также берут посев на гемолитический стрептококк из ушка. 

Также в этот срок на приеме врач определяет показания для дородовой госпитализации. В 36 недель врач также расскажет вам о подготовке к родам, симптомах начала родов, чтобы вы были готовы к главному событию.

Как мы отмечали ранее, на приеме врач акушер-гинеколог обязательно измеряет окружность живота и высоту стояния дна матки. Измеряется окружность голени для исключения отеков. Также врач оценит запись КТГ плода. 

В сроках 33-35 недель проводится третье УЗИ. На нем исключают пороки кишечника, заболевания печени, различные опухоли. Определяется количество околоплодных вод (то есть врач смотрит, достаточно ли их плоду). Измеряется вес плода, предполагаемый вес к сроку родов. 

Также выполняется исследование с весьма нелегким названием – допплерометрическое исследование. Это контроль скорости кровотока в системе плацента-пуповина–плод. Иными словами, это исследование помогает определить, хватает ли малышу питательных веществ, которые поступают из плаценты (то есть от матери) по пуповине к вашему малышу. 

В 36, 38, 40, 41 недели женщине рекомендуется сдавать общий анализ мочи. Врачам важно по результатам этих анализов исключить отеков, потерю белка в организме матери, появление ацетона, бактерий. Все это может стать причиной дородовой госпитализации. 

Также на приеме врач акушер-гинеколог обязательно измеряет окружность живота, голени и высоту стояния дна матки и обязательно оценивает запись КТГ плода.   

Мы разобрали с вами большой блок информации про обследования и анализы по каждому триместру беременности. В следующих двух уроках мы обсудим с вами не менее важные темы – тревожные звоночки в самочувствии и осложнения. Ведь будущей маме очень важно быть в курсе того, как меняется ее организм, с какими сложностями она может столкнуться и чем она может помочь себе и ребенку. Ведь все мы хотим самого главного – рождения здорового, крепкого малыша!

${separator}

Урок 8. Тревожные звоночки в самочувствии будущей мамы

В этом уроке я хотела бы вместе с вами рассмотреть возможные тревожные звоночки и осложнения в каждом из триместров беременности. Хочу рассказать вам об этом, чтобы вы понимали что и такие сценарии могут случиться в жизни, чтобы вы были готовы и сделали все возможное для вашего здоровья и здоровья малыша.

На ранних сроках беременности (1 триместр) у женщины может случиться самопроизвольный выкидыш и замершая беременность. Это одно из частых осложнений беременности в 1 триместре.

Самопроизвольный выкидыш – это самопроизвольное прерывание беременности в сроках до 22 недель беременности. Чаще всего это происходит в первые три-четыре месяца беременности (то есть 1-2 триместр беременности). Проявляется это следующим образом:

кровянистые выделения от мажущих до обильных
схваткообразные боли внизу живота
боли в области поясницы
также могут появиться обильные жидкие выделений из половых путей (подтекание околоплодных вод). 

Обратите внимание: если у вас появились вышеперечисленные симптомы, то немедленно вызывайте скорую помощь. В данном случае необходима консультация врача акушера-гинеколога.  

Второе осложнение – это неразвивающаяся (или замершая) беременность. Зачастую при отсутствии жалоб, на приеме врач акушер гинеколог при обследовании определяет, что размер матки не соответствует сроку беременности. То есть матка меньше предполагаемого срока беременности. По результатам УЗИ органов малого таза отсутствует сердцебиение плода, размеры плода (плодного яйца) не соответствуют предполагаемому сроку беременности.

В этом случае врач акушер-гинеколог выдает женщине направление в гинекологический стационар. В настоящее время в стационарах отдается предпочтение медикаментозному прерыванию беременности, то есть удалению плодного яйца с помощью таблеток. Это наиболее щадящий метод, который максимально бережно относится к матке.    

К сожалению, и такие сценарии развития событий возможны в жизни. Специально для вас совместно с психологами и психотерапевтами мы разработали большой видеокурс, где мы поговорим о психологической поддержке в таких ситуациях.

Осложнения 2-3 триместра

Одна из распространенных ситуаций – это рождение ребенка до срока родов, т.е. до 38 недель.

Преждевременные роды – это роды с 22 недель до 37 недель беременности. При этом с 22 недель до 28 недель – это преждевременные роды с рождением новорожденных с чрезвычайно низкой массой тела. При этом вес детей составляет до 1000 г.

Причины преждевременных родов до сих пор не ясны. В основном это инфекционные, гормональные, генетические и соматические заболевания и другие причины. 

На что женщине необходимо обратить внимание: 

Матка в течение дня часто приходит в тонус (может быть даже каждый час).
Тонус матки сохраняется в течение 2-х часов, при этом боли не проходят, даже если вы приняли спазмолитик.
Появились слизистые выделения из влагалища.
Появились жидкие выделения из половых путей.
Появились кровянистые выделения от мажущих до обильных.   
В таких случаях необходимо срочно вызвать бригаду скорой помощи. Женщине нужна консультация врача акушера-гинеколога. Все вышеперечисленные симптомы – это признаки угрозы преждевременных родов. 

Еще одно осложнение беременности, характерное для 3 триместра – это гибель плода внутриутробно.

Обращайте внимание на шевеления плода, на его двигательную активность. В среднем в течение часа плод 20 минут бодрствует, а остальные 40 минут – спит. В течение дня вы должны ощущать 10 полноценных шевелений плода. Это значит, что плод совершает полноценные двигательные движения, то есть «передвигается» (плавает) внутриутробно. 

В течение дня таких движений должно быть 10 и более, но не менее 10 раз. Если двигательная активность снижена, или наоборот повышена – необходимо срочно посетить врача женской консультации для записи КТГ плода.  

Также в 3 триместре у беременной женщины может:

повышаться артериальное давление, которое сопровождается головной болью; 
к вечеру появляться отеки голеней, с утра – рук и лица.
Чтобы это не пропустить, рекомендую вам приобрести тонометр и в течение дня 3 раза измерять артериальное давление. Артериальное давление измеряется в покое (обычно в положении сидя), рука располагается горизонтально, по возможности на уровне сердца. Измеряют артериальное давление 3 раза с интервалом в 2-3 минуты и окончательное давление – это средний результат полученных цифр.    

Чтобы не пропустить отеки, советую приобрести напольные весы и ежедневно утром натощак, после утреннего туалета, взвешиваться. Во- первых, так вы сможете контролировать свой вес, а во-вторых, резкая прибавка в весе (2 кг и более) в течение недели позволяет заподозрить наличие скрытых отеков. Это весьма характерно для беременных в 3 триместре. 

Если вдруг вы заболели, появилась температура, кашель, насморк, слабость, общее недомогание – необходимо вызвать врача на дом. Рекомендую не заниматься самолечением, а довериться специалистам. В команде с врачами вы обязательно добьетесь лучшего результата и сохраните здоровье.

И в заключении урока перечислю список симптомов, при которых Вам необходимо немедленно обратиться в медицинское учреждение:

при кровянистых выделениях из половых путей;
при схваткообразных болях внизу живота и болях в поясничной области;
при появлении жидких обильных выделениях из половых путей;
при сильной головной боли, резкой потери зрения, при выпадении полей зрения; 
если шевеления плода стали редкими, либо отсутствуют вообще в течение 6-12 часов.
при повышении артериального давления (140/90 и выше); 
если у вас судороги, потеря сознания; 
при повышении температуры тела 38 градусов и выше.    
Будьте здоровы, дорогие мамы! Следите за своим самочувствием, прислушивайтесь к себе и знайте: все обязательно будет хорошо!

${tb1c1_5}

Урок 9. Сахарный диабет. Резус-конфликт. Рекомендации по ведению беременности

Обсудим, как проходит течение беременности у женщин с сахарным диабетом, а также подробно остановимся на таком понятии как резус-конфликт.

Гестационный сахарный диабет (ГСД) и беременность

Гестационный сахарный диабет – это диабет, который возник впервые во время беременности, иными словами, это нарушение толерантности к глюкозе.   

Распространенность гестационного сахарного диабета во всем мире неуклонно растет. И в общей  популяции разных стран варьирует от 1 до 14%, составляя в среднем 7%.

Почему проблема сахарного диабета так значима для беременных? Это обусловлено тем, что у таких беременных женщин высока вероятность развития осложнений течения беременности  и родов. На фоне сахарного диабета беременных могут возникнуть:

преждевременные роды;
умеренное (а иногда  и выраженное) многоводие; 
недостаточность маточно-плацентарного кровотока, из-за чего нарушается внутриутробное питание плода и как результат – гипоксия плода, т.е. нехватка кислорода;
также у женщин с сахарным диабетом малыши при рождении бывают больше 4 кг, а иногда и более 5 кг. При таком весе физиологические роды весьма проблематичны.
Выявляется гестационный сахарный диабет только после проведения глюкозотолерантного теста. Сокращенно он называется ГТТ.

Обратите внимание: в связи с тем, что сахарный диабет – это весьма распространенное заболевание, в Беларуси с 2018 года глюкозо-толерантный тест введен как обязательно проводимый тест. 

Выполняется этот тест в сроке беременности 22-24 недели. Если у вас двойня (или тройня), или предыдущие дети родились с массой 4 кг и более, или кто-то из ближайших родственников (мама, папа, бабушка, дедушка) болеет сахарным диабетом, можно выполнить тест и до 12 недель беременности. 

Выполнение теста рекомендуется всем беременным женщинам. Тем более в нем нет никакой опасности. И самое главное – вы сможете точно установить (или исключить) сахарный диабет и вместе с врачом грамотно построить план ведения беременности.

Если у вас впервые во время беременности выявлен сахарный диабет, то врач акушер-гинеколог обязательно направит на консультацию к эндокринологу. 

Врач эндокринолог расскажет вам  про диету, которая назначается при данной патологии. Что это за диета?

Исключить прием простых углеводов (белый хлеб, выпечка, мороженое, всевозможные сладости, сухофрукты, сладкие фрукты). Можно есть зефир, пастилу. Из фруктов можно яблоки, киви, сливы, желтые и зеленые груши. 
Ограничить животные жиры (сливочное масло, твердые сыры)
Ограничить прием картофеля, макаронных изделий. 
Помимо диеты, мы рекомендуем приобрести глюкометр  с тест-полосками. С его помощью вы в любое время сами сможете определить уровень сахара в крови. Для чего это нужно? Чтобы убедиться, что диета подобрана правильно и назначение инсулина вам не нужно. Иногда во время беременности назначают инсулинотерапию.

Определяется уровень инсулина сначала натощак, а затем через 1 час после приема пищи. При этом рекомендуем вам вести пищевой дневник. Это тетрадь, куда вы вносите, что ели на завтрак, обед и ужин, т.е. в основные приемы пищи.  И рядом записываете уровень сахара через 1 час после приема пищи. Если диета подобрана правильно, то уровень сахара не будет превышать значений нормы. Постепенно вы сами научитесь определять продукты, после приема которых у вас будет повышаться уровень сахара в крови, и будете ограничивать их прием. 

Параллельно с определением уровня сахара в крови, нужно будет сдавать анализ мочи и следить, чтобы в моче не было ацетона (это продукты полураспада углеводов).  Они появляются в моче либо когда вы голодаете, либо при неправильно подобранной диете. В моче может определяться и глюкоза (так называемая глюкозурия). 

Соблюдение диеты, контроль гликемии позволит снизить риск возникновения осложнений во время беременности, получить удовольствие от беременности и в дальнейшем от родов. И конечно, позволит жить и радоваться жизни вместе со здоровым малышом!

Беременность и отрицательный резус фактор

Женщинам с отрицательным резус-фактором во время беременности следует знать о таком понятии, как резус-конфликт. Возникает он в том случае, если мама является резус-отрицательной, а плод – резус-положительным. 

Иными словами, если вы резус–отрицательная, а ваш супруг – резус положительный, то вероятность развития резус-конфликта во время беременности высока. Если вы и ваш супруг резус отрицательные, то проблем с резус конфликтом у вас не будет. Чтобы это знать заранее, рекомендуется сдавать кровь на определение резус принадлежности и вам, и мужу. 

Все резус-отрицательные беременные находятся под особым наблюдением у врача акушера-гинеколога. 

Что такое резус-конфликт?

Если в кровь резус-отрицательной женщины попадают чужие эритроциты (в данном случае плода), которые имеют белок системы резус – а это только при беременности резус-положительным плодом, они будут восприниматься, как чужие.  Организм матери начнет вырабатывать антитела для их уничтожения. Возникает резус-конфликт– состояние, при котором материнские антитела пытаются уничтожить клетки крови (эритроциты) плода.   

Что происходит, если возник резус-конфликт?  

Антитела матери проникают через плаценту и атакуют эритроциты плода. В результате распада эритроцитов в крови плода накапливается большое количество токсичного вещества, называемого билирубином. Билирубин  окрашивает кожу ребенка в желтый цвет (возникает так называемая «желтуха»). Также плод испытывает выраженное кислородное голодание, так как количество эритроцитов (клеток переносчиков кислорода) в крови резко падает. Возникает внутриутробная анемия у плода.     

Для того чтобы резус-конфликт не развился, беременной женщине вводят специальный препарат – антирезусный иммуноглобулин. Во всем мире это стандартная процедура.    

Расскажу схему плановой профилактики:

Первое введение проводят на сроках 28-30 недель беременности (только если нет титра антител) в поликлинике.
Второе введение происходит сразу после родов (72 часа), если ребенок резус-положительный, в роддоме. 
Антирезусный иммуноглобулин вводят внутримышечно. Это обычный укол. Такую процедуру выполняют только по назначению врача. Проводить профилактику в домашних условиях запрещено.


    `
};