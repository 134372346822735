import { FC, createRef, useEffect, useState } from 'react';
import styles from './HomeScreen.module.css';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import { WelcomeSection } from './sections/1-WelcomeSection/WelcomeSection';
import { MZHRSection } from './sections/2-MZHRSection/MZHRSection';
import { LearningSection } from './sections/3-LearningSection/LearningSection';
import { ProcessSection } from './sections/5-ProcessSection/ProcessSection';
import { StatusSection } from './sections/4-StatusSection/StatusSection';
import { DownloadAppSection } from './sections/6-DownloadAppSection/DownloadAppSection';
import { CommandSection } from './sections/10-CommandSection/CommandSection';
import { WorkWithUsSection } from './sections/8-WorkWithUsSection/WorkWithUsSection';
import { FounderSection } from './sections/9-FounderSection/FounderSection';
import SwipeableModal from 'features/SwipeableModal/SwipeableModal';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { checkIsWebView } from 'utils/other';

const HomeScreen: FC = () => {
  const [hash, setHash] = useState<string>();
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      setHash(hash);
    };

    handleHashChange();

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [window.location.hash]);

  const contactRef = createRef<null | HTMLDivElement>();
  const aboutRef = createRef<null | HTMLDivElement>();
  const partnersRef = createRef<null | HTMLDivElement>();

  useEffect(() => {
    if (hash === 'contact') {
      contactRef.current?.scrollIntoView(true);
    }

    if (hash === 'about') {
      aboutRef.current?.scrollIntoView(true);
    }

    if (hash === 'partners') {
      partnersRef.current?.scrollIntoView(true);
    }
  }, [aboutRef, contactRef, hash, partnersRef]);

  const { isMobile } = useAppWindowSize();
  const isWebView = checkIsWebView();

  return (
    <>
      <Header />
      <main className={styles.main}>
        {isMobile && !isWebView && <SwipeableModal />}
        <WelcomeSection />
        <MZHRSection />
        <LearningSection />
        <StatusSection />
        <ProcessSection />
        <DownloadAppSection />
        {/* <ExpertsSection /> */}
        <WorkWithUsSection partnersRef={partnersRef} />
        <FounderSection aboutRef={aboutRef} />
        <CommandSection />
      </main>
      <Footer contactRef={contactRef} />
    </>
  );
};

export default HomeScreen;
