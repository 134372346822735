import './../Auth.css';
import { FC, useCallback, useMemo } from "react";
import ArrowNavButton from "components/Buttons/ArrowNavButton/ArrowNavButton";
import { useTranslate } from "hooks/useTranslate";
import { AppRoutes } from "navigation/constants";
import StepsLabel from '../components/StepsLabel/StepsLabel';
import { STEPS_SELECT_INTERESTS, STEPS_SELECT_PERIOD, STEPS_SELECT_USAGE_OPTIONS } from '../constants/steps';
import { Title } from 'components/Text/Title/Title';
import { Text } from 'components/Text/Text/Text';
import Footer from 'components/Footer/Footer';
import NextButton from './components/NextButton/NextButton';
import { TitleH3 } from 'components/Text/TitleH3/TitleH3';
import { useNavigate } from 'react-router-dom';
import SkipButton from '../components/SkipButton/SkipButton';
import { useAppDispatch, useAppSelector } from 'store/store';
import { selectInterests, selectUsageOption } from './store/selectors';
import { Interests, UsageOptions } from 'constants/constants';
import { InterestsPicker } from 'components/Pickers/InterestsPicker/InterestsPicker';
import { resetInterests, updateInterests } from './store/OnboardingSlice';


export const InterestsScreen: FC = () => {
  const t = useTranslate();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const interests = useAppSelector(selectInterests);
  const usageOption = useAppSelector(selectUsageOption);

  const handleNext = useCallback(
    () => {
      navigate(AppRoutes.PROCESSING_SCREEN);
    },
    [navigate]
  );

  const backStep = useMemo(
    () => usageOption === UsageOptions.PLANNING_PREGNANCY
      ? STEPS_SELECT_USAGE_OPTIONS
      : STEPS_SELECT_PERIOD,
    [usageOption]
  );

  const backRoute = useMemo(
    () => usageOption === UsageOptions.PLANNING_PREGNANCY 
      ? AppRoutes.STATUS_SCREEN
      : AppRoutes.PERIOD_SCREEN,
    [usageOption]
  );

  const handleSelect = useCallback(
    (value: Interests) => () => {
      dispatch(updateInterests(value));
    },
    [dispatch]
  );

  if (!usageOption) return null;

  return (
    <>
      <header className='auth-header'>
        <div className='auth-container auth-header-container'>
          <ArrowNavButton 
            title={ `${t('common-step')} ${ backStep }` }
            to={ backRoute }
            cb={ () => dispatch(resetInterests()) }
          />
          <StepsLabel
            step={ STEPS_SELECT_INTERESTS }
          />
          <SkipButton title={ t('common-skip') } />
        </div>
      </header> 
      <main className='auth-main'>
        <div className='auth-container flex-column-center'>
          <Title 
            text={ t('onboarding_InterestingsScreeen_title') }
            highlight={ t('onboarding_InterestingsScreeen_title_highlight') }
            center
          />
          <div className='auth-description'>
            <Text 
              text={ t('onboarding_InterestingsScreeen_description') }
            />
          </div>
          <div className='auth-section-title'>
            <TitleH3
              text={ t('onboarding_InterestsScreen_section_title') }
            /> 
          </div>
          <InterestsPicker
            onChange={ handleSelect }
            usageOption={ usageOption }
            selectedInterests={ interests }
          />
          <div className='auth-next-container'>
            <NextButton
              title={ t('common-next') }
              onClick={ handleNext }
              disabled={ !interests.length }
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
};
