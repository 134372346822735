import { ChevronAdvice, ChevronCourse, ChevronPost } from 'assets/icons';
import dayjs from 'dayjs';
import { isString } from 'lodash';
import { CourseData, RecommendationType } from 'types';

export const WEEK_RECOMMENDATIONS = [
  {
    id: 1,
    type: RecommendationType.ADVICE,
    icon: ChevronAdvice,
  },
  {
    id: 2,
    type: RecommendationType.COURSE,
    icon: ChevronCourse,
  },
  {
    id: 3,
    type: RecommendationType.POST,
    icon: ChevronPost,
  },
];

export const getItemsByTopic = (items: CourseData[], key: string) =>
  items
    ?.filter(
      // @ts-ignore
      ({ topic }) => !!topic && (isString(topic) ? topic === key : topic[key])
    )
    .reverse();

export const getItemsByPregnancyWeek = (
  items: CourseData[],
  pregnancyWeek: number
) => {
  const result = {};

  items?.forEach((item) => {
    // @ts-ignore
    const { topic } = item;
    if (!topic) return [];
    const topicKeys = Object.keys(topic);
    if (
      !!topicKeys &&
      topicKeys?.length &&
      topicKeys.some(
        (topicKey) =>
          topicKey.includes('pregnancy-week-') &&
          parseInt(topicKey.replace('pregnancy-week-', '')) === pregnancyWeek
      )
    ) {
      // @ts-ignore
      result[item.id] = item;
    }
  });
  return Object.values(result);
};

export const getItemsByChildMonth = (
  items: CourseData[],
  childMonth: number
) => {
  const result = {};
  items?.forEach((item) => {
    // @ts-ignore
    const { topic } = item;
    if (!topic) return [];
    const topicKeys = Object.keys(topic);
    if (
      !!topicKeys &&
      topicKeys?.length &&
      topicKeys.some(
        (topicKey) =>
          topicKey.includes('child-month-age-') &&
          parseInt(topicKey.replace('child-month-age-', '')) === childMonth
      )
    ) {
      // @ts-ignore
      result[item.id] = item;
    }
  });
  return Object.values(result);
};

export const getChildAge = (childDateOfBirth: number) =>
  dayjs().diff(dayjs(childDateOfBirth), 'year');

export const getChildMonth = (childDateOfBirth: number) => {
  const diff = dayjs().diff(dayjs(childDateOfBirth), 'month');
  return diff < 0 ? 0 : diff;
};

export const getChildDay = (childDateOfBirth: number, childMonth: number) => {
  const diff = dayjs()
    .subtract(childMonth, 'month')
    .diff(dayjs(childDateOfBirth), 'day');
  return diff < 0 ? 0 : diff; // Lower than 0 if childDateOfBirth is in the future.
};
