import { ArrowBottom } from 'assets/icons';
import classNames from 'classnames';
import { useTranslate } from 'hooks/useTranslate';
import React from 'react';
import styles from './SelectLanguage.module.css';

const SelectLanguage: React.FC = () => {
  const t = useTranslate();
  return (
    <div className={classNames(styles.container)}>
      <span className={styles.customSelect}>
        {t('settings_app_language_russian')}
      </span>

      <div className={styles.icon}>
        <ArrowBottom />
      </div>
    </div>
  );
};

export default SelectLanguage;
