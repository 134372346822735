import './Auth.css';
import { FC, useCallback } from "react";
import { useFormik } from 'formik';
import Footer from "components/Footer/Footer";
import ArrowNavButton from 'components/Buttons/ArrowNavButton/ArrowNavButton';
import { AppRoutes } from 'navigation/constants';
import { useTranslate } from 'hooks/useTranslate';
import { Title } from 'components/Text/Title/Title';
import Input from './components/Input/Input';
import { FormFields } from './validations/constants';
import { getSingInValidationSchema } from './validations/AuthValidationSchems';
import { useNavigate } from 'react-router-dom';
import TextButton from 'components/Buttons/TextButton/TextButton';
import GoogleSignInButton from './components/GoogleSignInButton/GoogleSignInButton';
import AppleSignInButton from './components/AppleSignInButton/AppleSignInButton';
import ErrorMessage from 'components/Messages/ErrorMessage/ErrorMessage';
import SubmitButton from 'features/Auth/components/SubmitButton/SubmitButton';
import { useAuth } from './hooks/useAuth';
import { AuthErrorCode } from './constants/constants';


const { EMAIL, PASSWORD } = FormFields;


const LoginScreen: FC = () => {
  const t = useTranslate();

  const navigate = useNavigate();

  const { emailLogin } = useAuth();

  const formik = useFormik({
    initialValues: {
      [EMAIL]: '',
      [PASSWORD]: '',
    },
    validationSchema: getSingInValidationSchema(),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values, { setStatus, resetForm }) => {
      // resetForm();

      emailLogin(
        values[EMAIL].trim(), values[PASSWORD], (code: AuthErrorCode) => setStatus({ error: code })
      );
    },
  });

  const handleSingIn = useCallback(
    () => {
      formik.handleSubmit();
    },
    [formik]
  );

  const navigateToRegistration = useCallback(
    () => {
      navigate(AppRoutes.STATUS_SCREEN);
    },
    [navigate]
  );

  const navigateToRestorePassword = useCallback(
    () => {
      navigate(AppRoutes.FORGOT_PASSWORD);
    },
    [navigate]
  );

  const handleChangeFocus = (isFocused: boolean) => {
    if (isFocused) {
      formik.setStatus({});
      formik.setErrors({});
    };
  };

  return (
    <>
      <header className='auth-header'>
        <div className='auth-container'>
          <ArrowNavButton 
            title={ t('common-to-main') }
            to={ AppRoutes.HOME }
          />
        </div>
      </header>
      <main className='auth-main'>
        <div className='auth-container flex-column-center'>
          <Title 
            text={ t('login_title') }
            highlight={ t('login_title_highlight') }
            center
          />
          <div className='auth-inputs-container'>
            <Input
              id={ EMAIL }
              label={ t('sing_in_placeholder_email') }
              value={ formik.values[EMAIL] }
              onChange={ formik.handleChange }
              onChangeFocus={ handleChangeFocus }
              error={ !formik.isValid }
            />
            <Input
              id={ PASSWORD }
              label={ t('sing_in_placeholder_password') }
              value={ formik.values[PASSWORD] }
              onChange={ formik.handleChange }
              onChangeFocus={ handleChangeFocus }
              error={ !formik.isValid }
              secret
            />
            {
              !formik.isValid && (
                <ErrorMessage message={ t('sing_in_error') } />
              )
            }
            {
              formik.status?.error && (
                <ErrorMessage message={ t(formik.status.error) } />
              )
            }
          </div>
          <div className='auth-row-buttons-container'>
            <SubmitButton
              title={ t('sing_in_come_in') }
              onClick={ handleSingIn }
            />
            <TextButton 
              title={ t('sing_in_forgot_password') }
              onClick={ navigateToRestorePassword }
            />
          </div>
          <div className='auth-OR'>
            { t('common-or') }
          </div>
          <div className='auth-column-buttons-container'>
            <AppleSignInButton />
            <GoogleSignInButton />
            <div className='flex-row-center'>
              <div className='auth-text' >
                { t('sing_in_registration_label') }
              </div>
              <TextButton
                title={ t('login_registration') }
                onClick={ navigateToRegistration  }
              />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default LoginScreen;
