import { AppleIcon } from 'assets/icons';
import ErrorMessage from 'components/Messages/ErrorMessage/ErrorMessage';
import { ProviderRemoval } from 'features/Auth/constants/constants';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { useTranslate } from 'hooks/useTranslate';
import { ComeInButton } from '../../Buttons/ComeInButton/ComeInButton';

type ContentModalAppleProps = {
  handleDeleteAccount: () => void;
  handleCancel: () => void;
  error: string;
};

export const ContentModalApple = ({
  handleDeleteAccount,
  handleCancel,
  error,
}: ContentModalAppleProps) => {
  const t = useTranslate();
  const { isMobile } = useAppWindowSize();

  return (
    <>
      {error && !isMobile && (
        <>
          <ErrorMessage message={t(error)} container={{ marginTop: 30 }} />{' '}
        </>
      )}
      <ComeInButton
        onPress={handleDeleteAccount}
        title={t('delete_acc_with__apple')}
        handleCancel={handleCancel}
        icon={AppleIcon}
        provider={ProviderRemoval.APPLE}
        isError={error}
      />
    </>
  );
};
