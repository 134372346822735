import dayjs from 'dayjs';
import { daysToMls } from '../../utils/converters';



export function getWeeksAndDays(date: number | null | undefined) {
  if (date) {
    const currentDate = dayjs(Date.now());
    const startPregnancyDate = dayjs(date);

    const week = currentDate.diff(startPregnancyDate, 'week');

    const day = currentDate.subtract(week, 'weeks').diff(startPregnancyDate, 'days');

    return [day, week];
  }

  return [null, null];
}


export const getTrimester = (weeks: number) => {
  if (weeks <= 13) {
    return 1;
  }
  if (weeks >= 14 && weeks <= 27) {
    return 2;
  }
  if (weeks > 28) {
    return 3;
  }
};

export function getEstimatedDateOfBirth(date: number | null | undefined) {
  if (!date) return [null];

  // TODO implement estimated date /// TODO CHECK
  const estimatedDate = date + daysToMls(280);

  return [new Date(estimatedDate).toLocaleDateString(
    'ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }
  )];
};

export function getDayMonthYears(date: number | null | undefined) {
  if (date) {
    const processDate = new Date(date);
    const day = processDate.getDate();
    const month = processDate.getMonth() + 1;
    const year = processDate.getFullYear();

    return [day, month, year];
  }

  return [null, null, null];
}

export function getNumbersDateOfPregnancy(date: number | null | undefined) {
  if (date) {
    const [day, week] = getWeeksAndDays(date);

    const WEEKS_1 = week! % 10;

    const WEEKS_10 = (week! - WEEKS_1) / 10;

    const DAYS_1 = day;

    return [WEEKS_10 || '0', WEEKS_1 || '0', DAYS_1 || '0'];

  }

  return [null, null, null];
};

export function getNumbersDateOfChildBirth(date: number | null | undefined) {
  if (date) {
    const [day, month, year] = getDayMonthYears(date);

    if (day && month && year) {
      const [DAYS_10, DAYS_1] = String(day).padStart(2, '0').split('');

      const [MONTH_10, MONTH_1] = String(month).padStart(2, '0').split('');

      const [YEAR_1000, YEAR_100, YEAR_10, YEAR_1] = String(year).split('');

      return [DAYS_10 || '0', DAYS_1 || '0', MONTH_10 || '0', MONTH_1 || '0', YEAR_1000 || '0', YEAR_100 || '0', YEAR_10 || '0', YEAR_1 || '0'];
    };
  }

  return [null, null, null, null, null, null, null];
};
