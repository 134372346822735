import { FC } from 'react';
import styles from './Label.module.css';

type LabelType = {
  for: any;
  text: string;
  containerStyle?: any;
  textStyle?: any;
  textContainerStyle?: any;
};
export const Label: FC<LabelType> = ({ for: element, text }) => {
  return (
    <div className={styles.container}>
      <div>{element}</div>
      <div>{text && <p className={styles.text}>{text}</p>}</div>
    </div>
  );
};
