import { FC, memo, useCallback } from "react";
import styles from './AppleSignInButton.module.css';
import { useTranslate } from "hooks/useTranslate";
import { useAuth } from "features/Auth/hooks/useAuth";


const AppleSignInButton: FC = memo(() => {
  const t = useTranslate();
  
  const { appleLogin } = useAuth();
  
  const handleSignIn = useCallback(
    () => {
      appleLogin();
    },
    [appleLogin]
  );

  return (
    <button 
      className={ styles.container }
      onClick={ handleSignIn }
    >
      <div className={ styles.icon }></div>
      { t('login_apple') }
    </button>
  )
});

export default AppleSignInButton;
