import { FC } from "react";
import styles from './LearningSection.module.css';
import { CardList } from "./components/CardList/CardList";
import { Title } from "components/Text/Title/Title";
import { useTranslate } from "hooks/useTranslate";

export const LearningSection: FC = () => {
  const t = useTranslate();

  return (
    <section className={ styles.section }>
      <div className={ styles.container }>
        <div className={ styles.wrapper }>
          <Title 
            text={ t('home_learning_title') }
            highlight={ t('home_learning_title_highlight') }
          />
          <div className={ styles.descriptionContainer }>
            { t('home_learning_description') }
          </div>
        </div>
        <CardList />
      </div>
    </section>
  );
};
