import { FC } from "react";
import styles from './Modal.module.css';
import ReactModal from 'react-modal';
import classNames from "classnames";
import { isFunction } from "lodash";

const overrideStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    backgroundColor: '#FFFFFF',
    borderRadius: '30px',
    overflow: 'hidden',
    borderColor: 'transparent',
    boxShadow: '0px 4px 25px rgba(157, 141, 241, 0.2)',
  },
  overlay: {
    zIndex: '100000000000000000',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  }
};


export const Modal: FC<{
  title: string;
  description?: string;
  isAlignTextCenter?: boolean; 
  isOpen: boolean;
  onConfirm?: () => void;
  confirmTitle?: string; 
  onCancel?: () => void;
  cancelTitle?: string;
  children?: any;
  isCalendar?: boolean;
}> = ({
  title,
  description,
  isAlignTextCenter = true,
  isOpen,
  onConfirm,
  confirmTitle,
  onCancel,
  cancelTitle,
  children,
  isCalendar,
}) => (
  <ReactModal
    isOpen={ isOpen }
    onRequestClose={ onCancel }
    shouldCloseOnOverlayClick={ true }
    style={ overrideStyles }
  >
    <div className={ classNames(
        styles.container,
        isCalendar && styles.containerCalendar
      ) }>
      {
        !isCalendar &&
          <div className={ styles.title }>
          { title }
          </div>
      }
      {  
        !!description && !isCalendar &&
          <div className={ classNames(
            styles.description,
            isAlignTextCenter && styles.textCenter
          )  }>
            { description }
          </div>
      }
      {
        !!children && children
      }
      { 
        !isCalendar && 
          <div className={ styles.controlContainer }>
            {
              isFunction(onCancel) && cancelTitle &&
                <button onClick={ onCancel } className={ styles.cancelBtn }>
                  { cancelTitle }
                </button>
            }
            {
              isFunction(onConfirm) && confirmTitle &&
                <button onClick={ onConfirm } className={ styles.confirmBtn } type="button">
                  { confirmTitle }
                </button>
              }
          </div>}
    </div>
  </ReactModal>
);
