import { ProviderRemoval } from 'features/Auth/constants/constants';

export enum ModalState {
  NOTICE = 'notice',
  ACTION = 'action',
}
export const actionDescription: {
  [key: string]: string;
} = {
  [ProviderRemoval.GOOGLE]: 'settings_delete_account_module_description_google',
  [ProviderRemoval.APPLE]: 'settings_delete_account_module_description_apple',
  [ProviderRemoval.EMAIL_PASSWORD]:
    'settings_delete_account_module_description_email_password',
};
