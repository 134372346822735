import { CSSProperties, FC } from 'react';
import styles from './IconLabel.module.css';

type IconLabelProps = {
  icon: any;
  text: string;
  props?: any;
  textStyle?: CSSProperties;
};
export const IconLabel: FC<IconLabelProps> = ({
  icon: Icon,
  text,
  props,
  textStyle,
}) => {
  return (
    <div className={styles.container}>
      {Icon && <Icon />}
      {text && (
        <p className={styles.text} style={textStyle}>
          {text}
        </p>
      )}
    </div>
  );
};
