import { b1 } from './blocks/block1';
import { b2 } from './blocks/block2';
import { b3 } from './blocks/block3';
import { b4 } from './blocks/block4';
import { b5 } from './blocks/block5';
import { b6 } from './blocks/block6';
import { b7 } from './blocks/block7';
import { b8 } from './blocks/block8';

export type Test = {
  title: string;
  question: string;
  answers: {
    [key: string]: string;
  };
  correctAnswerId: number | number[];
  correctAnswer: string;
  image: string;
  correctAll?: boolean;
};

export interface CourseBlock {
  title: string;
  author: string;
  description: string;
  video: string;
  tests?: Test[];
  lessonsLength?: number;
  text?: string;
  last?: boolean;
  endAllTests?: boolean;
  correctAll?: boolean;
  next?: { block: string; course: string };
}

interface CourseBlocks {
  [key: string]: {
    [key: string]: CourseBlock;
  };
}

export const coursesData: CourseBlocks = {
  b1: b1,
  b2: b2,
  b3: b3,
  b4: b4,
  b5: b5,
  b6: b6,
  b7: b7,
  //@ts-ignore
  b8: b8,
};
