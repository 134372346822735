import { FC } from 'react';
import styles from './Card.module.css';
import { useTranslate } from 'hooks/useTranslate';
import { Link } from 'react-router-dom';

export type TCard = {
  name: string;
  icon: string;
  title: string;
  text: string;
  link: string;
};

export const Card: FC<{
  data: TCard;
}> = ({ data }) => {
  const t = useTranslate();

  const { name, icon, title, text, link } = data;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t(title)}</h2>
        <div className={styles.text}>{t(text)}</div>
        <Link to={link} className={styles.link}>
          {t('learning_link_title')}
        </Link>
      </div>
      <img src={icon} alt={name} className={styles.icon} />
    </div>
  );
};
