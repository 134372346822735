import { FC, useCallback } from "react";
import { ListMenuButton } from "features/Profile/components/ListMenuButton/ListMenuButton";
import { SettingsProfileIcon } from "assets/icons";
import { useTranslate } from "hooks/useTranslate";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "navigation/constants";


export const MenuItemSettings: FC = () => {
  const t = useTranslate();
  const navigate = useNavigate();

  const handlePress = useCallback(
    () => {
      navigate(AppRoutes.SETTINGS_SCREEN);
    },
    [navigate]
  );

  return (
    <ListMenuButton
      iconLeft={ SettingsProfileIcon }
      title={ t('profile_menu_settings') }
      onPress={ handlePress }
    />
  )
};
