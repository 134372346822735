import { useTranslate } from 'hooks/useTranslate';
import { Link } from 'react-router-dom';
import { RecommendationType } from 'types';
import styles from './StudySearchModal.module.css';
import LinesEllipsis from 'react-lines-ellipsis';
import Highlighter from 'react-highlight-words';
import { getUrlType } from 'utils/other';

type SearchProps = {
  name: string;
  title: string;
  subtitle: string;
  type?: RecommendationType;
  id: string;
};

type StudySearchModalProps = {
  searchResult: SearchProps[];
  query: string;
};

export const StudySearchModal = ({
  searchResult,
  query,
}: StudySearchModalProps) => {
  const t = useTranslate();
  const handleSelect = (item: SearchProps) => {
    //@ts-ignore
    const { id, type, contentId } = item;
    if (type === RecommendationType.LESSON) {
      return `/edu/courses/${contentId}/${id}`;
    }
    if (type === RecommendationType.ADVICE) {
      return `/edu/${getUrlType(type)}/${id}`;
    }
    if (type === RecommendationType.COURSE) {
      return `/edu/${getUrlType(type)}/${id}`;
    }
    if (type === RecommendationType.POST) {
      return `/edu/${getUrlType(type)}/${id}`;
    }

    if (type === RecommendationType.EXPERT) {
      return `/edu/expert/${id}`;
    }
    return '';
  };

  const filteredWithEnabled = searchResult.filter(
    //@ts-ignore
    (item) => item.type === RecommendationType.EXPERT || item?.enable
  );
  return (
    <div className={styles.wrapper}>
      <p className={styles.quantity}>
        {t('search_result_quantity')} {filteredWithEnabled.length}
      </p>
      {filteredWithEnabled.map((item) => {
        const { name, title, subtitle, type, id } = item;
        return (
          <div
            key={id}
            className={styles.lesson}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Link to={handleSelect(item)} className={styles.lesson}>
              <p className={styles.name}>{name}</p>
              <p className={styles.title}>
                <Highlighter
                  searchWords={[query]}
                  textToHighlight={title}
                  style={{
                    fontWeight: '500',
                    color: '#5F5F5F',
                  }}
                  highlightStyle={{
                    backgroundColor: 'transparent',
                    fontWeight: '700',
                    color: '#5F5F5F',
                  }}
                  numberOfLines={2}
                />
              </p>
              <p className={styles.subtitle}>
                <LinesEllipsis
                  text={subtitle}
                  maxLine="2"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </p>
            </Link>
          </div>
        );
      })}
    </div>
  );
};
