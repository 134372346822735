import { useRef, useState, useEffect } from 'react';

export const useMobileScroll = (): [
  boolean,
  React.RefObject<HTMLDivElement>
] => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const element = scrollRef.current;
    if (element && isMobile) {
      const handleScroll = () => {
        const scrollTop = element.scrollTop;
        const scrollHeight = element.scrollHeight;
        const clientHeight = element.clientHeight;

        // Прокручиваем к середине элемента с анимацией
        const scrollTo = (scrollHeight - clientHeight) / 2;

        smoothScrollTo(element, scrollTop, scrollTo);
      };

      element.addEventListener('scroll', handleScroll);

      return () => {
        element.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMobile]);

  return [isMobile, scrollRef];
};

const smoothScrollTo = (
  element: HTMLElement,
  from: number,
  to: number,
  duration = 300
) => {
  const start = performance.now();

  const animateScroll = (timestamp: number) => {
    const elapsed = timestamp - start;
    const progress = Math.min(elapsed / duration, 1);
    const scrollTop = from + (to - from) * easeInOutCubic(progress);

    element.scrollTop = scrollTop;

    if (elapsed < duration) {
      requestAnimationFrame(animateScroll);
    }
  };

  requestAnimationFrame(animateScroll);
};

const easeInOutCubic = (t: number) => {
  if (t < 0.5) {
    return 4 * t * t * t;
  } else {
    const f = 2 * t - 2;
    return 0.5 * f * f * f + 1;
  }
};
