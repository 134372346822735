import { InstagramIcon, tiktokExpert } from 'assets/icons';
import { CSSProperties, FC } from 'react';
import styles from './ExpertSocial.module.css';

import classNames from 'classnames';
import { ExpertSocial } from './component/ExpertSocialIcon';

export const ExpertSocialList: FC<{
  isScrolled: boolean;
  big?: boolean;
  containerStyle?: CSSProperties;
  socialIconsListStyle?: CSSProperties;
  iconExt?: [];
  instagram?: string;
  tiktok?: string;
}> = ({
  tiktok,
  instagram,
  isScrolled,
  big,
  containerStyle,
  socialIconsListStyle,
}) => {
  return (
    <div
      style={socialIconsListStyle}
      className={classNames(styles.container, big && styles.bigContainer)}
    >
      {instagram !== 'Null' && (
        <ExpertSocial
          icon={InstagramIcon}
          isScrolled={isScrolled}
          big={big}
          link={instagram}
          containerStyle={containerStyle}
        />
      )}

      {tiktok !== 'Null' && (
        <ExpertSocial
          icon={tiktokExpert}
          isScrolled={isScrolled}
          big={big}
          link={tiktok}
          containerStyle={containerStyle}
        />
      )}
    </div>
  );
};
