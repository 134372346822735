import { ShareIcon } from 'assets/icons';
import ChipWithIcon from 'components/ChipWithIcon/ChipWithIcon';
import FavoriteButtons from 'components/FavoriteButtons/FavoriteButtons';
import styles from './FavoriteContainer.module.css';
import { CourseData } from 'types';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { useUser } from 'hooks/useUser';

interface FavoriteContainerProps {
  item: CourseData;
}

const FavoriteContainer: React.FC<FavoriteContainerProps> = ({ item }) => {
  const { isTablet, isMobile } = useAppWindowSize();
  const { user } = useUser();
  return (
    <div className={styles.favoriteContainer}>
      {!!user.id && <FavoriteButtons isMobile={isMobile} item={item} />}

      {!isTablet && !isMobile && (
        <ChipWithIcon icon={ShareIcon} text="Поделиться" share />
      )}
    </div>
  );
};

export default FavoriteContainer;
