import {
  separator,
  tb7c5_1,
  tb7c5_2,
  tb7c5_3,
  tb7c5_4,
  tb7c5_5,
} from 'assets/images';

export const b7_c25_text = {
  b7_c5_title: `Курс «Психология репродуктивных проблем» `,
  b7_c5_description: `Спикер: Мария Вашкевич-Василевская, репродуктивный психолог`,
  b7_c25_text: `
    ${tb7c5_1}

    Урок 1. Бесплодие

Бесплодием считается невозможность зачатия ребенка в течение года минимум. И если вы не можете зачать в течение полугода и вам до 35 лет, то о бесплодии речь не идет – вы планируете семью. Если же вам более 35 лет, то тогда для постановки диагноза нужно меньше времени, достаточно полугода.

Изначально проблема бесплодия считалась биологической. Ей занимаются в основном медики (репродуктологи, гинекологи). Но не всегда бесплодие связано с какими-то заболеваниями. Существует вид бесплодия, который называется идиопатическим, когда врачи не видят конкретных причин, почему пара не может забеременеть. Оба партнера здоровы, но у них не получается зачать ребенка. И тогда возникает вопрос, как же пара (как женщины и как мужчины) воспринимает это состояние, эту невозможность.

Если говорим про то, что происходит с психикой, то, по разным данным исследований, от 15% до 70% женщин, у которых выявлено бесплодие или которые проходят лечение бесплодия, отмечаются элементы депрессивных и тревожных расстройств. Это означает, что есть психологический дискомфорт, который влияет на качество их жизни. Именно поэтому при бесплодии (например, на Западе) желательна консультация психолога, которая облегчает это психологическое неблагополучие и помогает женщине (и паре) быстрее справиться с кризисом.

Если мы говорим про женщину, то женщина находится в некотором цикле (естественном цикле) между надеждой и отчаянием. Начало цикла – когда женщина надеется, что она сможет зачать ребенка. Проходит время, проходит месяц, у нее появляются месячные – и она находится в отчаянии. Этот цикл надежды и отчаяния усиливает психологический дискомфорт.

Женщина начинает замыкаться, секс с партнером становится некоторой обязательной программой и теряет вкус, остроту. Это воспринимается как работа по зачатию ребенка, и партнеры постепенно эмоционально могут отдаляться друг от друга. 

Если же женщина, например, начала лечить бесплодие (у нее есть какие-то эндокринные нарушения, например) или она решила проходить протокол вспомогательных репродуктивных технологий – то само лечение также может являться стрессовым фактором, потому что многие процедуры являются болезненными или стрессовыми. И тогда тем более необходима психологическая поддержка, чтобы женщина лучше с этим справлялась. Потому что иногда лечение бесплодия занимает длительное время. И это длительное стрессовое воздействие, с которым не всегда легко справиться. Именно поэтому процент депрессивных и тревожных расстройств достаточно высок. 

Если мы говорим про распространенность самого бесплодия, то примерно каждая 6-я пара может сталкиваться с этой проблемой. По данным Всемирной организации здравоохранения, около 15-17% всех пар сталкиваются с бесплодием. 

Что происходит тогда с парой? Могут быть 2 эффекта. 

Первый сценарий – это сплочение. Когда пара вместе переживает вот эту невозможность, друг друга поддерживает. И это более благополучный сценарий, потому что пара тогда может ценить друг друга и оставаться партнерами. 
Второй сценарий – это взаимные претензии и попытки каким-то образом обвинять друг друга. Либо напряжение настолько высокое в связи с бесплодием, что пара может развалиться.
Что будет происходить с каждой конкретной парой, сложно сказать. Это зависит от уровня близости, который был изначально, до решения планирования ребенка.

Что еще влияет на восприятие бесплодия? Влияет социальная поддержка. И здесь тоже могут быть несколько вариантов.

С одной стороны, это может быть репродуктивное давление, когда бабушки, дедушки говорят: «Ну когда же вы родите?». Это не всегда корректно, это может быть очень болезненно, и не улучшает психологическую обстановку в паре. 

В то же время не всегда есть возможность у женщины и у мужчины поделиться своими эмоциями, теми переживаниями, которые есть по поводу бесплодия. Более того, не все готовы даже разговаривать друг с другом, и это также создает напряжение в паре. Женщина не готова делиться своими переживаниями цикла «надежда и отчаяние», мужчина также не готов делиться своими переживаниями по поводу этого. 

Иногда проблема не воспринимается как психологическая, а воспринимается как чисто биологическая, и тогда все внимание только на врачей, и мы теряем близость в паре. 

Именно поэтому, поскольку влияние факта бесплодия может быть многогранным, оно может оказывать влияние на женщину, на мужчину, на их взаимоотношения, а также на социальное взаимодействие. Зачастую пара, которая долго не имеет детей, может прекращать общаться со своими друзьями, у которых дети есть. Иногда пары, в которых нет детей, начинают общаться только с парами, у которых нет детей, которые также не могут зачать. Конечно, они получают от этого поддержку, но это сужает возможности и сужает круг социального взаимодействия. Не всегда это является позитивным. 

Поэтому стоит обращаться к психологам, чтобы улучшить психологическую атмосферу, снять тревожность, которая связана с ожиданием возможного зачатия, либо с депрессивными состояниями, которые выражаются в некотором ощущении бракованности. Помощь психолога будет полезна как во время лечения, так и при планировании беременности.

${separator}

Урок 2. Порочный круг женского бесплодия

Порочный круг женского бесплодия – это созданный учеными на основе многочисленных исследований порочный круг (или поддерживающий цикл), который содержит определенные элементы, показывающие, как женщина психологически поддерживает свое бесплодие. То есть показывает и демонстрирует связь ее биологического состояния (т.е. невозможности зачатия) с ее психологическим состоянием (тем, что происходит у нее в голове, и тем, как она воспринимает свои эмоции).

Существуют 3 проблемные точки, которые обязательно содержат этот цикл:

стиль жизни
высокий уровень стресса
само бесплодие
В какую из этих точек войдет женщина (в поддерживающий цикл, либо в порочный круг), мы не знаем. Она может войти в любую точку и если она туда попала, то этот цикл начинает поддерживать сам себя. 

Как это происходит? Например, женщина оказывается в ситуации бесплодия. И она начинает воспринимать бесплодие как проблему. Ключевой момент в этом – это восприятие того, что так получается. Это восприятие связано с некоторым обесцениванием себя. Например, я считаю, что я никчемная, я неудачница или я бракованная, и поэтому у меня что-то не получается. Или бракованные наши отношения, и женщина начинает испытывать негативные эмоции по этому поводу, и это, в свою очередь, начинает влиять на качество ее жизни.

Женщина начинает меньше общаться с друзьями, начинает хуже общаться со своим партнером, может начать страдать сексуальная сфера. Далее женщина начинает страдать из-за образа себя, потому что, она «никчемная». Это начинает распространяться на другие сферы жизни и приводит к стрессу. Стресс, в свою очередь, формирует определенный стиль жизни, который стресс поддерживает, и это влияет также на то, сможет женщина зачать или не сможет зачать. 

Почему стресс на это влияет? Дело в том, что наш стресс – это, конечно, эмоции, наши переживания, но с биологической точки зрения – это гормоны. И гормоны стресса в какой-то степени противодействуют половым гормонам. Чем выше уровень стресса у женщины – тем хуже работает ее репродуктивная система. 

Таким образом, даже если женщина проходит лечение от бесплодия или проходит лечение вспомогательными репродуктивными технологиями, вероятность успешного лечения тем меньше, чем выше уровень стресса. И, таким образом, женщина начинает бегать между восприятием бесплодия, уровнем стресса и тем стилем жизни, который у нее сложился, который приводит ее к неудовлетворению этой жизнью. И выйти из этого порочного круга достаточно сложно. 

Есть разные направления психотерапии, которые, так или иначе, связаны с лечением бесплодия:

Психодинамическая терапия, которая рассматривает внутренние конфликты женщины и помогает ей справляться со стрессом через это.
Семейная системная терапия, которая выясняет какие-то конфликты или убеждения, связанные с родительскими установками родительской системы, и таким образом помогает бороться со стрессом.
Когнитивно-поведенческая терапия. Тогда мы работаем исключительно со стрессом, уменьшаем количество стресса и развиваем больший поведенческий репертуар, то есть улучшаем качество жизни. 
И, таким образом, мы влияем на то, получится у женщины зачать или нет. 

Другими словами, если мы говорим про психолога, который будет работать с женщиной (либо с парой), которая находится в репродуктивном кризисе и не может зачать ребенка, – в первую очередь, главная работа связана с уменьшением количества стресса. Это обучение эмоциональной регуляции, чтобы не усиливать тот стресс, который мы имеем. Это обучение релаксации, то есть снятие стресса. Это рекомендации по изменению качества жизни, стиля жизни, который был бы менее стрессовым.

Иногда женщинам необходимо сменить работу, чтобы чувствовать себя более комфортно, если работа вызывает сильный стресс. Если у женщины уже есть нарушение идентичности (т.е. нарушение ее восприятия себя, она воспринимает себя как бракованную), то психолог может помочь справиться с этим и улучшить качество жизни, улучшить отношения в паре, улучшить близость в паре.

Психолог помогает справиться с этим, вернуть радость жизни, справиться с тревогой и с ощущением собственной бракованности, если они присутствуют. 

Тревожные звоночки

если вы сталкиваетесь с ощущением того, что вы «не такая»  
вы думаете, что у вас в жизни плохо
ваша жизнь не имеет смысла
если вы думаете, что ваши отношения не имеют смысла, если вы не сможете родить ребенка
вам кажется, что ваш муж вас не понимает и ваши отношения рушатся из-за того, что у вас нет ребенка
Во всех этих случаях психологическая помощь может быть полезна и облегчить ваше эмоциональное состояние.

${tb7c5_2}

Урок 3. Роль убеждений в бесплодии

Поговорим про роль убеждений в цикле женского бесплодия. Разберем те моменты, которые могут на мыслительном уровне влиять на восприятие проблемы бесплодия. 

Во многом все зависит от того, воспринимает женщина бесплодие как проблему или нет. 

Что же такое восприятие? Это некоторые убеждения в отношении репродуктивной функции и жизни. Например: «я могу быть нормальной женщиной, только если у меня есть ребенок». Или «каждая женщина должна иметь минимум 2 детей до 35 лет». Или «у меня должно получиться с первого раза». Или «если у пары не получается зачать ребенка – значит, это плохая пара и этот мужчина мне не подходит».

Все это некоторые убеждения, которые могут быть в голове у женщины. И под их влиянием бесплодие становится либо большой проблемой (настолько глобальной, что женщина теряет устойчивость и теряет веру в себя), либо она не воспринимает это как проблему. И тогда, даже если у нее не получается родить ребенка, она живет счастливо и не входит в цикл (поддерживающий цикл бесплодия).

Помимо убеждений в отношении деторождения, есть еще убеждения, связанные с семейной историей. Например, мама могла рассказывать своей дочери о том, что рожать – это ужасно сложно. Может быть, в семье есть истории про потери детей. И тогда юная девочка воспринимает деторождение как некоторую опасность для своей жизни. И тогда психологически женщина затем как будто сопротивляется зачатию. Она будет думать: «Мне не надо зачинать – это опасно». 

Также могут влиять семейные отношения. Например, если женщина в плохих отношениях со своей матерью и ее мать каким-то образом негативно на нее повлияла – то затем женщина будет сопротивляться стать такой, как мама (т.е. стать мамой). И также она будет сопротивляться иметь детей, потому что будет бояться стать такой же, как и ее мать. 

Это восприятие, эти идеи, которые есть в голове, будут влиять на то, как мы воспринимаем деторождение и как мы воспринимаем проблему зачатия либо незачатия ребенка. 

Немаловажная вещь – это репродуктивное давление. Представьте: у женщины есть много убеждений, а есть еще дедушки и бабушки, которые говорят, что надо рожать. Есть коллеги, которые говорят: «Если с этим партнером не получается, может быть, ты найдешь себе кого-нибудь другого?». Есть подруги, которые говорят: «Вы не можете нормально заняться сексом?». И все это создает ощущение еще большей ущербности пары либо женщины самой по себе из-за факта бесплодия. И это создает ощущение неблагополучия (я неблагополучна, наша семья или наша пара неблагополучна). Тогда женщина буквально входит полностью в поддерживающий цикл бесплодия, и ее состояние ухудшается. 

Еще немаловажный момент – это цель зачатия. Женщина может хотеть иметь детей только потому, что она хочет удержать партнера. Или если женщина хочет иметь ребенка, чтобы чувствовать себя хорошо (например, при эндокринном бесплодии). Или если у женщины было много сложностей в гинекологической сфере (до зачатия), для нее сам факт рождения ребенка может быть как подтверждение, что она нормальная. И тогда она может не хотеть самого ребенка, а она может хотеть подтверждения, что она нормальная. И это также может влиять на зачатие, потому что создает дополнительное напряжение.

Чем больше напряжения в репродуктивной сфере, тем больше вероятность того, что пара может иметь сложности с зачатием. Потому что стресс влияет на вероятность деторождения. 

Не забываем про то, что вам может помочь психолог. Роль психолога при работе с бесплодием в том, чтобы снять напряжение, разобраться с теми убеждениями, которые есть у женщины, и снизить уровень напряжения на этих убеждениях либо развеять их, как мифы. И тогда, когда меньше напряжения будет в этой сфере, с большей вероятностью произойдет зачатие как при использовании репродуктивных технологий, так и в естественном цикле естественным путем.

${separator}

Урок 4. Мужское бесплодие 

Порядка 40% ситуаций бесплодия связаны с мужским фактором. И врачи обязательно расскажут вам о том, что могло привести к бесплодию. Но сегодня мы обсудим те особенности стиля жизни, которые могут влиять на мужское бесплодие. 

Ббольшая часть исследований, которые есть на данный момент по мужскому бесплодию, связана не с психологией (хотя стресс, безусловно, влияет, и мы об этом тоже можем поговорить), а связана с особенностями питания и поведения. 

Почему именно так? Потому что качество спермы во многом зависит от того, какой образ жизни живет мужчина. И если мы говорим про это качество, то нужно сказать про такое понятие, как оксидативный стресс.

${tb7c5_3}

Обсудим особенности питания. Например, повышенное содержание жира в пище и повышенное содержание красного мяса повышают оксидативный стресс в организме, то есть увеличивают количество окислительных реакций. 

Следующий фактор – это количество кофе, который пьет этот мужчина. Влияет и физическая активность. Если у мужчины низкая физическая активность, то оксидативный стресс у него будет выше.

Еще два фактора – это высокая температура и стресс. Что значит высокая температура? Это злоупотребление саунами, очень теплой одеждой, очень узким бельем. Стресс тоже является фактором, повышающим наш оксидативный стресс. Большая часть рекомендаций в отношении мужского бесплодия связана с тем, чтобы: 

уменьшить количество красного мяса 
уменьшить жирность пищи
увеличить количество овощей, фруктов
меньше кофе
больше физической активности
меньше стресса
Конечно, это значительное изменение стиля жизни. И не все на это готовы. Поэтому иногда при мужском бесплодии можно обратиться к психологу, чтобы получить помощь в изменении либо получить помощь в борьбе с сопротивлением к изменению стиля жизни. 

Мужской стресс 

У женщин есть прямая связь стресса и качества половой системы (т.е. половых гормонов). У мужчин такой прямой взаимосвязи нет, есть связь за счет окислительных реакций. И для мужчины хуже хронический стресс. Когда есть, например, хронический недосып, либо мужчина очень много работает и не расслабляется. В этих случаях необходима консультация психолога. Важно понять: почему я не могу расслабиться? Почему я не умею расслабляться, не принимая алкоголь, например, или не куря сигарету? Почему я не умею расслабляться какими-то другими способами? Почему я не умею расслабляться через спорт? Почему я вообще могу не знать про свои эмоции, но периодически находиться в своем каком-то тяжелом, напряженном состоянии и не знать, что это эмоции? В этих случаях психолог может помочь разобраться, назвать вещи своими именами и назвать стресс стрессом и каким-то образом научить мужчину справляться с этим. 

Сам факт бесплодия также может являться стрессовым фактором для мужчины. Потому что это некоторая недостаточность его мужественности (т.е. он недостаточно мужчина). Так же, как женщина может воспринимать себя недостаточно женщиной, если она не может зачать, мужчина также может из-за мужского фактора воспринимать некоторую свою ущербность либо неполноценность. И психолог также может помочь справиться с этой темой.

Влияние мужского бесплодия на жизнь пары

Есть исследования, которые показывают, что несмотря на мужской фактор бесплодия, женщина все равно воспринимает это как стресс, как свою проблему. И ее стрессовые реакции также могут запускаться. Поэтому хорошо работать с психологом в паре. Важно уметь паре справляться со стрессом. И если есть такая возможность, то здорово, чтобы вы могли научиться справляться со своими эмоциями, расслабляться и получать удовольствие от жизни вне зависимости от репродуктивного итога ваших попыток.

Подведем итоги. При мужском факторе бесплодия основное психологическое консультирование направлено на изменение жизни, изменение стиля жизни мужчины, который предполагает определенные рекомендации по питанию и по активности. И также психологическое консультирование будет включать возможность снятия стресса либо работы со своей тревогой. И если мужской фактор бесплодия присутствует в вашей паре – здорово, если вы можете общаться парой либо приходить на консультацию вместе, потому что на женщину мужское бесплодие также оказывает стрессовое влияние.

${tb7c5_4}

Урок 5. Вспомогательные репродуктивные технологии

Первое, с чем сталкивается женщина либо пара при прохождении лечения методами репродуктивных технологий, – это стресс от лечения либо контакта с врачами. Почему это важно? 

По данным европейского общества эмбриологов и репродуктологов,  хорошие отношения с врачами и хорошее позитивное взаимодействие при прохождении лечения могут увеличить вероятность зачатия на 15%. Но если у вас нет выбора врачей либо у вас все равно есть стресс от лечения – вы можете обратиться к психологу за поддержкой и для уменьшения стрессов от лечения.

Из чего же состоит этот стресс от лечения?

Страх от самих процедур (регулярные уколы, регулярные обследования). Страх может быть без сознания. Например, если процедура делается под общим наркозом и женщина боится этого, это может вызывать стресс. 
Также мы можем чувствовать стыд, когда говорим о каких-то особенностях нашей сексуальной жизни, о том, что происходит с нами в этом процессе. 
Это может отражаться на эффективности лечения и на его результативности в том числе. 

Психологическая помощь во время лечения бесплодия

На каждом этапе она своя, потому что на каждом этапе у женщины есть свои потребности. 

Если это самое начало вашего лечения, то психолог может помочь разобраться с какими-то убеждениями в отношении лечения либо в отношении своего бесплодия и помочь сделать репродуктивный выбор: вступать в лечение или нет. Также будет полезно некоторое психообразование, когда психолог, либо врач, либо они совместно (врач с психологом) расскажут о том, как взаимосвязаны результативность лечения и психология, как взаимосвязан стресс и возможность зачатия, возможность вынашивания. 

Если мы говорим про то, что чувствует женщина – то ей зачастую нужна поддержка. Например, ее круг поддержки достаточно узкий, и этой поддержки не хватает. В этом случае психолог может помочь, даст женщине эмоционально разгрузиться, выслушает ее.

Цикл лечения может занимать время. И так же, как женщина при любом бесплодии (при любом из его вариантов), каждый месяц находится между отчаянием и надеждой, то же самое происходит и во время лечения. Например, сейчас она начинает новый цикл ЭКО и надеется, что все получится. Но так получается, что не приживается ни один эмбрион, и тогда она чувствует горе. Психолог может помочь справиться с горем и с отчаянием, когда лечение нерезультативно либо не приносит результата.

Также психологическая помощь может быть полезна при проблемах в отношениях между партнерами. Партнеры по-разному воспринимают проблему бесплодия и по-разному относятся к лечению в том числе. То, что для мужчины может быть нормально, для женщины может являться большим стрессовым фактором. И психолог может помочь научиться разговаривать об этом, принимая эмоции партнера и оказывая поддержку.

Еще одна большая часть работы психолога, который помогает женщине пройти через период лечения, через вспомогательные репродуктивные технологии, – это тревога. Тревога по поводу того, получится ли забеременеть. Также это тревога о здоровье, потому что лечения есть показания, противопоказания и некоторые риски. И иногда эти риски могут пугать женщину. В этом случае психолог также может помочь, обучить технологиям снятия тревоги, технологиям переживания тревоги. 

Также часто при использовании вспомогательных репродуктивных технологий встает вопрос о том, какой это ребенок. Некоторые женщины, несмотря на то что они очень хотят быть мамами, в какой-то момент могут начать думать, будто ребенок «ненастоящий», из пробирки. И это убеждение может мешать проходить лечение. Здесь для уменьшения напряжения также может помочь психолог.

${separator}

Урок 6. Репродуктивный выбор

Репродуктивный выбор – это наше решение по поводу деторождения или недеторождения. И часто у женщины есть убеждения по поводу того, зачем нужны дети или что ребенок может сделать с ее жизнью. 

Например, он сделает ее счастливой, или радостной, или полноценной. И тогда я могу выбирать рожать ребенка по каким-то мотивам. Это нормально, что у нас есть определенные мотивы. Но если не получается зачать естественным путем, то самый первый выбор, который женщина делает, – это вступать или не вступать в лечение.

У каждого выбора есть свои плюсы и минусы. Если женщина их осознает–  тогда все хорошо. Если женщина их не осознает – то часто она делает выбор на каких-то эмоциональных всплесках, импульсах. И через время может его менять, потом менять снова. Задача консультанта по репродуктивному выбору либо психолога – помочь сделать этот выбор однозначным (по крайней мере, на какой-то период).

Представим ситуацию: женщина находится в ситуации бесплодия, и она с партнером не может зачать в течение года. Женщине 22 года. В этом случае женщина может принять осознанное решение, что не будет думать об этом еще пару лет. И это нормально, что они с партнером решают побыть в некоторой неопределенности какое-то количество времени.

Что нужно учитывать в процессе принятия решения о лечении? 

Возраст – как свой, так и партнера. 
Заболевания, которые у вас есть.
То, что происходит с парой.
Помните: если у вас есть серьезные конфликты в паре, но по какой-то причине вы хотите зачать ребенка (возможно, вы видите в ребенке решение), с точки зрения психологии, гораздо более эффективно сначала разрешить конфликты в паре, а потом приступать к лечению. 

Конечный выбор все равно принимает пара. И очень здорово, если это решение именно пары. Иногда бывает, что у партнеров разный взгляд на бесплодие. И они не могут договориться, это приводит к конфликтам. Например, один партнер хочет лечиться, а второй говорит: «Меня это не волнует, у меня все хорошо». Тогда это проблемы в паре, а не проблемы бесплодия. Это проблемы коммуникации, проблемы конфликтов – и их необходимо разбирать в первую очередь.

Выбор вспомогательных репродуктивных технологий

Консервация гамет (половых клеток)
Это может быть консервация как собственных гамет, так и эмбрионов (если у вас получились эмбрионы и вы могли их законсервировать). Это важный выбор. И женщина, и мужчина должны взвешивать то, что происходит с их парой, с восприятием друг друга, как долго они могут держать эти гаметы законсервированными.

У каждого есть свои убеждения в отношении собственных клеток, и кому-то хочется их консервировать, кому-то не хочется. Эти моменты нужно уточнить и проговорить с партнером. 

Важно проговорить:

как долго вы будете хранить клетки
сколько попыток у вас будет
насколько устойчива ваша пара
есть ли риски по здоровью у партнеров (если кто-то из партнеров заболеет, что будет с клетками)
Если это продумывается заранее, тогда ваше решение более взвешенное и более долговременное. Оно не создает у вас дополнительные тревоги.

Донорство
Все репродуктивные решения сложны, потому что мы одновременно решаем не только за себя, но и за человека, который еще не рожден. И если речь идет о донорстве, то важно продумать отношения либо проговорить отношения партнеров к донорству, отношения партнеров к будущему ребенку и что вы будете говорить ребенку после того, как он родится, о донорстве и о том, как он был зачат. Это решение принимает пара самостоятельно. 

Часто бесплодие связано с сопротивлением людей бездетности. Мы сопротивляемся тому, что у нас не будет своих детей. Тем не менее это некоторое решение быть childfree (сознательное желание не иметь детей). Либо быть childfree, но с детьми – то есть, мы усыновляем детей, но сами не рожаем. Это важные решения, о которых тоже нужно будет потом говорить детям (если вы их усыновили). Это важно, ведь любая тайна создает лишнее напряжение в семье, которое сказывается на эмоциональной атмосфере в паре, и имеет негативные последствия в долгосрочной перспективе.

Поэтому любое решение содержит несколько этапов. 

Вы проясняете все факты и факторы, которые влияют на это решение.
Вы проговариваете все убеждения в отношении выбора как свои собственные, так и партнера. 
Вы смотрите, какие последствия (краткосрочные и долгосрочные) могут быть у вашего выбора. 
И далее на основе этого собранного материала вы принимаете решение. 

Никто не знает, что для вас будет лучше, никто не может принять это решение за вас. При этом, если оно принимается совместно с партнером и учитываются все факторы, включая вашу эмоциональную близость и контакт с партнером, большая вероятность того, что вы впоследствии будете довольны своим выбором и никогда не будете о нем жалеть. Это самое важное для хорошего качества жизни на протяжении всей нашей жизни.

${tb7c5_5}

Урок 7. Беременность – это только начало

Беременность является результатом лечения, позитивным результатом. Когда мы находимся в кризисе бесплодия, беременность становится самоцелью. Мы не думаем о том, что будет дальше. Самое важное – забеременеть. И если я забеременею, тогда все будет хорошо. 

Часто это приводит к некоторым рискам разочарования или даже депрессивным симптомам. Представим: все случилось, мы прошли лечение, эмбрион прижился. И что дальше?

А дальше оказывается, что 9 месяцев ребенка нужно носить, нужно ждать, нужно мириться с каким-то нюансами, потому что не всегда беременность проходит спокойно. И это отдельный вызов, отдельные трудности. 

Если женщина была в бесплодии достаточно долго и она уже тревожна, у нее уже есть депрессивные симптомы – тогда, когда она забеременеет, эти психологические вопросы никуда не исчезают. Женщина по-прежнему тревожная и по-прежнему испытывает некоторое ощущение того, что она не такая, что она какая-то плохая. Может быть, она думает, что станет плохой матерью. 

При подготовке к беременности, при лечении бесплодия важно для женщины понять, что беременностью ее жизнь не заканчивается. Есть еще долгая жизнь после того, как ребенок родится. Но об этом чаще всего в бесплодии мы не думаем. Важно спланировать свою жизнь после. Ответить себе на вопросы: как я буду жить после, что я буду делать в беременности, что я буду делать после рождения ребенка, как я справлюсь с теми стрессами, которые для меня предложит беременность и которые для меня предложит рождение ребенка. 

Если мы заранее это планируем (даже с учетом того, в каком стрессе находится женщина) – то с меньшей вероятностью у нас потом будет послеродовая депрессия. И наша адаптация к рождению ребенка также пройдет лучше, если мы готовимся к этому заранее.

Есть исследования, которые говорят то, что тревога влияет на протекание беременности. Соответственно, чем выше уровень тревоги, тем большее количество стрессовых гормонов в крови и тем больше рисков для течения беременности. 

Поэтому важно при подготовке к беременности и родам (особенно если у вас было бесплодие либо вы столкнулись с процессом бесплодия) научиться снижать свою тревогу до беременности, обучиться навыкам эмоциональной регуляции до беременности.

Второй момент, который тоже следует учитывать, когда женщина находится в бесплодии. Часто у женщины нет ощущения будущего (например, женщина думает: «я не буду увольняться с работы, пока не забеременею» , «я не буду начинать какое-то хобби, пока не забеременею»). То есть будто нет будущего вне контекста деторождения вообще. Нет жизни вне контекста деторождения. И чтобы у женщины (у пары) было больше ресурсов на то, чтобы справиться с репродуктивным кризисом – необходимо научиться снижать свою эмоциональную уязвимость в контексте жизни в целом. То есть научиться жить вне темы деторождения. 

Что значит снижение эмоциональной уязвимости? Наша эмоциональная уязвимость связана с несколькими вещами – биологическими и количеством позитивного в нашей жизни.

Что значит биологические факторы? Это сколько мы спим, как хорошо мы питаемся, есть ли у нас какая-то минимальная физическая активность, есть ли у нас достаточное количество ресурсов в жизни.

Если мы говорим про эмоциональную уязвимость в контексте получения удовольствия, то здесь есть 2 момента. 

Постарайтесь целенаправленно получать удовольствия, либо добавлять какую-то позитивную активность каждый день. Приучите себя получать удовольствие каждый день по чуть-чуть.
Постарайтесь определить вещи, которые для вас важны в жизни (здоровье, хобби, профессиональная реализация, межличностные отношения). И выстраивайте свою жизнь по направлению к этим ценностям. 
Если наша жизнь соответствует нашим ценностям – она приносит удовольствие. Если мы живем не так, как хотим – то, соответственно, мы увеличиваем количество стресса в нашей жизни. И это будет негативно влиять на наше психологическое состояние.

Чем может помочь психолог? 

Если у вас не получается жить такой жизнью, то психолог может помочь вам выстроить вашу жизнь в соответствии с вашими ценностями. Возможно, вам не хватает каких-то навыков, уверенности. 

Это все направление для работы. И это то, что может обогатить вашу жизнь позитивными эмоциями. А если у вас все получится и вы забеременеете, родите прекрасного ребенка – это уменьшит вашу эмоциональную уязвимость перед детскими кризисами, перед кризисом адаптации и перед теми сложностями, которые могут возникнуть в течение беременности.

Я желаю вам удачи. И я желаю, чтобы ваш психологический кризис, если он есть, в отношении бесплодия разрешился лучшим образом. Если вам нужна будет психологическая помощь, пожалуйста, найдите возможность обратиться к соответствующим специалистам.

    `,
};
