import './../Auth.css';
import { FC } from "react";
import ArrowNavButton from "components/Buttons/ArrowNavButton/ArrowNavButton";
import { useTranslate } from "hooks/useTranslate";
import { AppRoutes } from "navigation/constants";
import StepsLabel from '../components/StepsLabel/StepsLabel';
import { STEPS_SELECT_PERIOD, STEPS_SELECT_USAGE_OPTIONS } from '../constants/steps';
import { Title } from 'components/Text/Title/Title';
import { Text } from 'components/Text/Text/Text';
import Footer from 'components/Footer/Footer';
import SkipButton from '../components/SkipButton/SkipButton';
import { useAppDispatch, useAppSelector } from 'store/store';
import { selectUsageOption } from './store/selectors';
import { UsageOptions } from 'constants/constants';
import { DateOfPregnancyScreen } from './2-2-DateOfPregnancyScreen';
import { DateOfChildBirthScreen } from './2-1-DateOfChildBirthScreen';
import { resetOnboarding } from './store/OnboardingSlice';


export const PeriodScreen: FC = () => {
  const t = useTranslate();

  const dispatch = useAppDispatch();

  const usageOption = useAppSelector(selectUsageOption);

  if (!usageOption) return null;

  return (
    <>
      <header className='auth-header'>
        <div className='auth-container auth-header-container'>
          <ArrowNavButton 
            title={ `${t('common-step')} ${STEPS_SELECT_USAGE_OPTIONS}` }
            to={ AppRoutes.STATUS_SCREEN }
            cb={ () => dispatch(resetOnboarding()) }
          />
          <StepsLabel
            step={ STEPS_SELECT_PERIOD }
          />
          <SkipButton title={ t('common-skip') } />
        </div>
      </header> 
      <main className='auth-main'>
        <div className='auth-container flex-column-center'>
          <Title 
            text={ t('onboarding_PeriodScreen_title') }
            highlight={ t('onboarding_PeriodScreen_title_highlight') }
            center
          />
          <div className='auth-description'>
            <Text 
              text={ t('onboarding_PeriodScreen_description') }
            />
          </div>
          <section className='auth-section-container'>
            {
              UsageOptions.MANAGING_PREGNANCY === usageOption && (
                <DateOfPregnancyScreen />
              )
            }
            {
              UsageOptions.AFTER_CHILDBIRTH === usageOption && (
                <DateOfChildBirthScreen />
              )
            }
        </section>
        </div>
      </main>
      <Footer />
    </>
  )
};
