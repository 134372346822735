import { useContentUpdateApi } from 'hooks/useContentUpdateApi';
import { useUser } from 'hooks/useUser';
import { Link, useParams } from 'react-router-dom';
import { CourseData, RecommendationType } from 'types';
import { CourseBlockFavouriteButtons } from '../CourseBlockFavouriteButtons/CourseBlockFavouriteButtons';
import { CourseInfoLabels } from '../CourseInfoLabels/CourseInfoLabels';
import styles from './CourseInfoBlock.module.css';
import LinesEllipsis from 'react-lines-ellipsis';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { getCategoryId } from 'utils/other';

type CourseInfoBlockProps = {
  item: CourseData;
  image: string;
  type: RecommendationType;
  description: string;
  title: string;
  id: string;
};

export const CourseInfoBlock = ({
  item,
  image,
  type,
  title,
  id,
}: CourseInfoBlockProps) => {
  const { updateBooked, updateLikes, isBooked, isLike, loading } =
    useContentUpdateApi(type, item);
  const { categoryId } = useParams();
  const handleBookClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    updateBooked();
  };
  const user = useUser();

  const handleHeartClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    updateLikes();
  };
  const { isMobile } = useAppWindowSize();

  const handleBlockClick = () => {
    if (type === RecommendationType.ADVICE) {
      return `/edu/${getCategoryId(categoryId)}advices/${id}`;
    }
    if (type === RecommendationType.COURSE) {
      return `/edu/${getCategoryId(categoryId)}courses/${id}`;
    }
    if (type === RecommendationType.POST) {
      return `/edu/${getCategoryId(categoryId)}posts/${id}`;
    }
    if (type === RecommendationType.MEDITATION) {
      return `/main/meditation/${getCategoryId(categoryId)}${id}`;
    }
    if (type === RecommendationType.LULLABIES) {
      return `/main/lullabies/${getCategoryId(categoryId)}${id}`;
    }
    return '';
  };
  const { userId } = useUser();

  return (
    <>
      <Link className={styles.container} to={handleBlockClick()}>
        {!isMobile && !!userId && (
          <CourseBlockFavouriteButtons
            handleBookClick={handleBookClick}
            handleHeartClick={handleHeartClick}
            isBookClicked={isBooked}
            isHeartClicked={isLike}
          />
        )}
        <div>
          <img src={image} className={styles.imageContainer} alt="course" />
        </div>

        <div className={styles.description}>
          <h2 className={styles.subTitle}>{title}</h2>
          <div className={styles.labels}>
            <CourseInfoLabels item={item} type={type} />
          </div>
        </div>
      </Link>
    </>
  );
};
