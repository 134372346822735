import { ExpertSocialList } from 'components/ExpertSocial/ExpertSocial';
import LinesEllipsis from 'react-lines-ellipsis';
import { Link } from 'react-router-dom';
import { Expert } from 'types/items';
import { getExpertFullName, getType } from 'utils/other';
import styles from './ExpertInfo.module.css';

type ExpertInfoProps = {
  item: Expert;
};

function ExpertInfo({
  item: { field, avatar, social, work, id, ...rest },
}: ExpertInfoProps) {
  const type = getType();
  const link = `/experts/${id}`;
  return (
    <div className={styles.expertWrapper}>
      <Link to={link}>
        <img src={avatar} className={styles.imgExpert} />
      </Link>
      <div className={styles.expertContainer}>
        <Link to={link}>
          <p className={styles.name}>
            <LinesEllipsis
              //  @ts-ignore
              text={getExpertFullName(rest)}
              maxLine="1"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </p>
          <p className={styles.job}>
            <LinesEllipsis
              text={field}
              maxLine="1"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </p>
          <p className={styles.workPlace}>
            {/* @ts-ignore */}
            {work?.ru?.content?.n02?.value}
          </p>
        </Link>
        <div>
          {social && (
            <ExpertSocialList
              isScrolled={true}
              instagram={social?.instagram}
              tiktok={social?.tiktok}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ExpertInfo;
