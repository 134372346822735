import { FC, useMemo } from "react";
import styles from './PrivacyScreen.module.css';
import ArrowNavButton from "components/Buttons/ArrowNavButton/ArrowNavButton";
import { AppRoutes } from "navigation/constants";
import { useTranslate } from "hooks/useTranslate";
import { useWindowSize } from "hooks/useWindowSize";


const PRIVACY_LINK = "https://docs.google.com/document/d/e/2PACX-1vTY7xi3VJOF13AbW-okT7qkxDilkGQXonoQpCQue3TVmMUSRZgg7d_GPkEWOS2PRZkNRq_3XD0sIGT_/pub?embedded=true"


export const PrivacyScreen:FC = () => {
  const t = useTranslate();
  const { height, width } = useWindowSize();

  const isMobile = width <= 320;

  const currentMainHeight = useMemo(
    () => {
      if (isMobile ) return height - 60 - 25; // 60 - arrow nav button height for mobile, 25 - padding
      return height - 95 - 25; // 95 - arrow nav button height, 25 - padding
    },
    [height, isMobile]
  );


  return (
    <>
      <div className={styles.header}>
        <div className={ styles.container }>
          <ArrowNavButton 
            title={ t('common-to-main') }
            to={ AppRoutes.HOME }
          />
        </div>
      </div>
      <div className={styles.main} style={ {height: currentMainHeight} }>
        <div className={ styles.container }>
          <iframe
            id="privacyFrame"
            className={ styles.iframe }
            title="Политика конфиденциальности"
            height={ currentMainHeight }
            src={ PRIVACY_LINK }
          >
            <p> 
              <a href={ PRIVACY_LINK }>
                Политика конфиденциальности
              </a> 
            </p>
          </iframe>
        </div>
      </div>
    </>
  )
};
