import {
  separator,
  tb2c4_1,
  tb2c4_2,
  tb2c4_3,
  tb2c4_4,
  tb2c4_5,
} from 'assets/images';

export const b2_c6_text = {
  b2_c4_title: `Курс «Образ жизни беременной женщины»`,
  b2_c4_description: `Спикер: Диана Мардас, врач акушер-гинеколог`,
  b2_c6_text: `
    ${tb2c4_1}

    Урок 1. Как и почему меняется образ жизни женщины с наступлением беременности?

Вы только что узнали, что вы ждете малыша. Вот они – те самые заветные две полоски, тест на беременность и самые волнительные моменты! Впереди у вас – 40 счастливых недель беременности. Что меняется и что в своей жизни нужно поменять?

Дорогие мамы, ничего глобально менять не надо! Беременность – это не состояние болезни. Беременность – это состояние особого комфорта, ожидания, приятных изменений в организме женщины. Время, когда важно не паниковать, не впадать в стресс, тогда весь период беременности пройдет спокойно. Советуем вам относиться к беременности спокойно, с радостью, с состоянием принятия, а не с настроением «Мне же теперь ничего нельзя!». 

Если нас смотрят пары, планирующие беременность, или родители девушек-подростков, юных девушек – хочу донести до вас важный совет.  Старайтесь жить таким образом всегда. Старайтесь следить за состоянием своего организма, за своим женским здоровьем так, чтобы, если вдруг наступит беременность, у вас не было переживаний и паники. Конечно, стоит помнить, что больше 35% беременности являются незапланированными. И сегодня в курсе мы поговорим о том, что же будет полезно поменять в своем образе жизни.

Когда мы узнаем, что мы в состоянии беременности, у нас запускается определенный процесс, меняется гормональная система. И эти изменения автоматически влекут некие поведенческие изменения – это нормально. Это своего рода природный защитный механизм для вашей беременности.

Почему все говорят, что с наступлением беременности мы становимся спокойнее? У будущей мамы могут появиться легкое торможение, сонливость. Это вас не должно пугать, и это пройдет с течением времени. Это действие гормона прогестерона – от него вам хочется спать, отдыхать, больше кушать, никуда далеко от дома не отходить. Это наш защитный механизм, чтобы в вашем организме хорошо укрепилась беременность.

Рекомендую вам быть со своим организмом быть в гармонии. Если организм говорит вам, что хочет спать – отдохните. Если вы видите, что вам хочется, например, зеленых продуктов – кушайте их. Если видите, что вас что-то беспокоит, сильно нарушается ваш привычный образ жизни – приходите к врачам. Мы вместе разберемся, что же пошло не так и выработаем определенную тактику действий.
${tb2c4_2}

Урок 2. Физическая активность во время беременности

Помним о том, что беременность – это не болезнь, поэтому не забываем о спорте и физической активности. Конечно, есть состояния, при которых спортом заниматься нельзя – об этом вам скажет ваш лечащий врач. А пока обсудим в целом акушерско-гинекологические рекомендации будущим мамам по занятию спортом. 

Физическая активность 

Почему не надо вести так называемый постельный режим во время беременности? Существуют ситуации, когда будущим мамам по врачебным показаниям, рекомендовано вести такой режим. Например, когда у женщины центральное предлежание плаценты (когда плацента перекрывает выход из родовых путей). И тогда во время физической активности у женщины тут же начинаются тут же выделения. Если вам в женской консультации не сказали каких-то конкретных особенностей (что вы попали в какую-то группу риска либо у вас есть какие-то индивидуальные особенности, которые нужно учитывать), знайте – вы относитесь к категории здоровых будущих мам. 

Почему физическая активность хороша и полезна? У будущих мам есть некоторые защитные механизмы организма. Например, наш организм очень боится кровотечения. Поэтому он заранее делает нашу кровь чуть более густой. И если будущая мама ведет очень пассивный образ жизни, то это может приводить к образованию так называемых тромбов. И далее с током крови они могут разноситься по нашему организму и закупоривать некоторые сосуды, в том числе плацентарный. Поэтому я рекомендую вам очень серьезно относиться к вашему физическому статусу, к вашей физической активности во время беременности. 

Есть другая крайность. Некоторые будущие мамы, боясь прибавки веса, начинают вести патологически активный образ жизни (активные посещения тренажерного зала, серьезные и длительные тренировки). Так тоже делать не рекомендуется. Во всем есть мера. 

Прибавка веса – это нормальная физиологическая ситуация. Она происходит за счет того, что у нас увеличивается объем циркулирующей крови, появляется плацента, пуповина, увеличивается грудь (там начинаются подготовительные процессы к лактации), у нас растет малыш. Важно понимать, какие параметры женщины были до беременности, чтобы понять, какая в норме может быть прибавка в весе. Ведь важно следить за весом и не пропустить отеки. Если вы будете грамотно питаться во время беременности, а потом будете правильно восстанавливаться после родов – от ваших лишних килограммов не останется и следа!

Спорт

Рекомендуется:

Бассейн, плавание 
Во время плавания у нас нет осевой нагрузки на позвоночник. Помним, что во время беременности много кальция уходит к малышу, и поэтому серьезную осевую нагрузку давать на организм не нужно. Также во время плавания активно тонизируются сосуды, сосудистая стенка приходит в тонус. А это здорово, потому что на сосудистую стенку во время беременности идет нагрузка. Также плавание успокаивающе действует на любого человека. А для будущей маме это особенно важно.

Кому не рекомендуется плавание: 

Людям, которые боятся воды. Да, мы рекомендуем плавание всем, но помните, что ваша беременность уникальна и ваш организм уникален. 
Когда есть риск преждевременных родов.
Если приоткрыта шейка матки.
Если есть центральное предлежание плаценты. 
Если есть риски инфекционного генеза, о которых вам скажет врач акушер-гинеколог. 
Рекомендуется:

Ходьба
Аэробные нагрузки для беременных – самые лучшие. Это ритмичное действие, которое дает нормальную нагрузку на весь организм. И конечно, во время прогулок к нам правильно поступает кислород. Вы можете гулять по парку, в районе водоема, где воздух максимально чистый – и в ваш организм поступает кислород, который так необходим вашему малышу. 

На что нужно обратить внимание? Когда вы гуляете долго (особенно во втором и третьем триместре), вы долго находитесь в вертикальном положении. Можно использовать бандаж. Он рекомендован не всем женщинам, но если единственная физическая нагрузка, которую вы по состоянию здоровья можете себе позволить, – это ходьба, и вы ходите часа 2-3 часа каждый день, то бандаж может быть вашим хорошим другом. Ведь головка малыша не давит на шейку матки, а также происходит перераспределение нагрузки на позвоночник (а это помощь нашему позвоночнику).

Во время беременности за счет того, что животик большой, у нас происходит перераспределение нагрузки на различные отделы позвоночника. У многих будущих мам, особенно во втором и третьем триместре беременности, могут появляться тянущие боли в районе поясницы. В таком случае бандаж – ваш явный друг. Это могут быть даже бандажи со специальными усилителями для спины. Помните, что его нужно использовать правильно – надевать в положении лежа. 

Рекомендуется:

Йога для беременных
Фитнес для беременных
Занятия на велотренажере
Домашняя утренняя растяжка, гимнастика.
Не рекомендуется:

Горячая сауна, баня
Советуем быть с этими местами осторожными. Потому что в разных триместрах беременности тепловое воздействие по-разному сказывается на нашем организме. В первом триместре у нас пока нет плаценты, которая точно укрепилась в стенки матки (она только укрепляется на этом этапе), у нас есть активно растущий организм ребенка. В это время тепловые воздействия на наш организм могут отражаться на том, что у малыша могут появляться аномалии развития. Это доказанный факт. В втором и третьем триместре плацента уже укрепилась в стенке матки, а матка расслабляется. И есть риск того, что плацента может отклеиться в каком-то участке.

В некоторых заведениях есть специальные комнаты релакса, где включена расслабляющая музыка и температура чуть выше комнатной. Это может быть солевая пещера. Такие места посещать можно.

Не рекомендуется:

Спорт с подъемом тяжестей, с риском упасть с высоты, с риском ударов, езда на велосипеде.
Обратите внимание: перед началом любых физических активностей советуем проконсультироваться с врачом. 

Также обращу внимание на ситуации, когда особенно важна консультация специалиста перед началом активности:

Риск преждевременных родов
Беременность двойней, тройней
Если предыдущие роды закончились операцией кесарево сечение 
Если есть ИЦН – истмико-цервикальная недостаточность
Если есть мажущие кровянистые выделения
Еще одна важная рекомендация – вести дневник беременности. Его можно вести как в письменном варианте, так, например, в электронном варианте (в мобильном приложении). Там можно помечать себе достижения дня, количество пройденных шагов, съеденное за день. Например, если у вас возникнет аллергическая реакция – то вы сможете посмотреть, какой продукт вы добавляли в свой рацион.

${separator}

Урок 3. Красота и косметологические процедуры

Если у вас во время беременности все протекает хорошо, то вам не запрещено пользоваться уходовой и декоративной косметикой. Большинство косметических средств тоже вам разрешены. В этом уроке обсудим некоторые нюансы, которые важно знать.

Косметические средства

Совет 1. Старайтесь продолжать использовать проверенные и любимые вами средства. Одна из самых опасных ситуаций, которая может случиться во время беременности – это аллергическая реакция.

Аллергическая реакция – не всегда контролируемый процесс и это системный процесс, оказывающий влияние на весь организм. А у нас внутри живет малыш, и есть определенные риски. Второй важный момент относительно аллергических реакций у будущих мам  – это невозможность лечения. Практически ни один препарат для лечения аллергии не находится в категории абсолютно безопасных, рекомендованных на любом триместре беременности. Если у вас есть возможность избежать аллергических реакций во время беременности – старайтесь это делать. Это касается и косметических процедур, и различных уходовых и декоративных средств. Конечно, это касается и питания, и новых напитков, которые вы ранее не употребляли.

Совет 2. Старайтесь изучать состав.

Помимо того, что желательно не менять состав средств, также важно обращать внимание на ряд компонентов, которые во время беременности не рекомендуется использовать. Это:

Ретиноиды. Есть ряд исследований, в большой концентрации они способны влиять на развитие ребенка, на возникновение пороков развитие. 
Салициловая кислота. Кстати, часто у женщин, кто до беременности страдал от высыпаний, с наступлением беременности проблемы с кожей исчезают. 
Также будьте осторожными со средствами автозагара, с отбеливающими средствами для кожи. В их составе тоже есть ряд компонентов, на которые мы просим вас обратить внимание. 
Эфирные масла. Именно эфирные масла очень часто вызывают аллергические реакции у будущих мам. Конечно, здесь все очень индивидуально. Если вы ранее использовали какие-либо из масел, то можете аккуратно продолжать использовать. Если во время беременности вы решили попробовать что-то новое – то так лучше не делать.
Косметологические процедуры

Не рекомендуется:

Глубокие пилинги
Инъекционные процедуры
Перманентный макияж. Если у вас до беременности был сделан перманентный макияж – ничего страшного. Во время беременности не рекомендую вам это делать по двум причинам. Причина первая – вас может ждать неожиданный результат. Гормональная система будущей мамы иногда ведет себя очень странно, и наш организм начинает реагировать на разные ситуации по-особенному. Могут быть какие-то вопросы с цветом, которые проявятся иначе, макияж может неравномерно укладываться. К тому же это не очень приятная процедура. Все, что вызывает болевой эффект, во время беременности желательно убрать. Во время беременности боль запускает в организме стресс, тонус, которые могут негативно сказываться на состоянии здоровья будущей мамы через спазмы сосудов плаценты.
Уходовые средства

Рекомендуется:

Кремы и масла от растяжек. Конечно, не стоит забывать, что во многом риск возникновения растяжек зависит от генетических особенностей организма. Но если у вас были периоды, когда вы теряли/набирали вес, у вас уже есть растяжки, если кожа склонна к сухости – вероятность того, что растяжки будут, высока. И поэтому не нужно дожидаться, пока они возникнут во время беременности.
Зоны, наиболее подверженные растяжению, – это живот, грудь, внутренняя поверхность плеча, ягодицы и бедра. Поэтому, начиная с самого старта вашей беременности, вы можете использовать крем (в составе которого есть увлажняющие компоненты) либо масла. Чем более кожа увлажнена, тем меньше она подвергается растяжению. 

Если вы ждете двойню/тройню, если у вас крупный малыш, многоводие – то на помощь может прийти также бандаж. Благодаря ему не будет чрезмерного растяжения кожи на животике.

Не запрещается:

Маникюр (в том числе с использованием гель-лака). Важно использовать одноразовые, стерильные инструменты. Рекомендуется ходить в проверенные места, к проверенным мастерам. Сам гель-лак во время беременности не запрещен.
Покраска волос. Конечно, не стоит забывать, что в средствах, которые мы используем для разведения краски, есть вещества, которые испаряются и могут влиять на будущую маму. Нужно быть осторожнее, если вы работаете в сфере красоты и ваша работа связана с ежедневной покраской. Тогда есть риски. Если же беременная женщина раз в несколько месяцев посещает покраску волос – то риска в этом нет.
Советуем быть осторожными:

Депиляция, восковая депиляция, шугаринг
Если вы ранее делали эти процедуры и для вас они привычны, болевой эффект терпим – то это не запрещается. Если вы решили во время беременности сделать это впервые – то вспоминаем, что все процедуры, которые являются болезненными, во время беременности делать не рекомендуется. 

Не рекомендуется:

Лазерная эпиляция. Это нужно исключить и подождать, пока беременность закончится. 
Массаж. Здесь очень важно понимать, в каком вы пребываете сейчас состоянии. Если у вас в целом состояние физического комфорта сейчас стабильное, вы можете на определенных точечных зонах делать массаж. Но стоит помнить важный нюанс. Во время беременности кровоток меняется, появляются отеки (иногда скрытые), поэтому массажем можно усугубить ситуацию, которая вызвана отеками. За счет увеличенных размеров матки и малыша есть давление на сосуды. Поэтому вывод один: желательно все согласовывать с врачом, который ведет вашу беременность. Если вы направляетесь на массаж, хотите пройти курс массажа либо вас что-то беспокоит, рекомендую поговорить об этом со своим врачом акушером-гинекологом.

${tb2c4_3}

Урок 4. Интимная жизнь будущей мамы

Сексуальная жизнь с наступлением беременности не заканчивается. Но важно знать некоторые особенности организма. Обсудим их.

В 1-ом триместре беременности сексуальное влечение может уменьшаться.
Это результат действия гормона прогестерона, который отвечает за то, чтобы все было стабильно, спокойно, хорошо. И он будто немного уменьшает сексуальное влечение, ведь сейчас его главная задача – сохранить беременность. Он будто видит в сексуальной жизни какие-то риски.

Во 2-ом и 3-ем многие мамы отмечают, что есть повышенное сексуальное возбуждение (особенно в 3-ем триместре). 
Есть несколько интересных исследований на эту тему. Такую особенность связывают с тем, что во 2-ом и 3-ем триместрах женские ткани немного отекают. И за счет отечности тканей некоторые нервные окончания, которые в этих тканях спрятаны, начинают дополнительно раздражаться. И таким образом возникает волна возбуждения.

Поэтому знайте, что и состояние падения либидо, и состояние увеличения сексуального влечения – это нормально. Это связано с физиологическими изменениями в организме женщины. 

Часто будущие мамы спрашивают, не опасно ли состояние, когда женщина достигает пика возбуждения во время беременности, ведь от этого может повышаться маточный тонус. На самом деле, здесь работает простой принцип – если вам хорошо, то и малышу тоже. Потому что в это время в вашем организме вырабатывается гормональный каскад, который положительно влияет на женский организм и сердечно-сосудистую систему в том числе. Поэтому бояться оргазма во время беременности не стоит.

Противопоказания к сексуальной жизни во время беременности

риск преждевременных родов ( укорочение шейки матки) 
истмико-цервикальная недостаточность (ИЦН)
пролабирование плодного пузыря
плохие мазки во время беременности (в таком случае во время полового контакта есть риск того, что влагалищные выделения попадут выше к плодному пузырю)
центральное либо краевое предлежание плаценты (т.е. когда плацента располагается максимально близко к шейке матки) 
а также другие особенности, о которых вас предупредит врач акушер-гинеколог и отметит, что половая жизнь не рекомендована. 
Также мы часто говорим о том, что в мужской сперме содержатся простагландины (т.е. гормоноподобные вещества), и поэтому нельзя жить половой жизнью во время беременности, потому что есть риск преждевременных родов. Это не совсем достоверные данные. С одной стороны, действительно, в сперме мужчины есть похожие гормоноподобные вещества, которые оказывают похожее действие. Но если у вашего организма нет готовности к родам, если ваша шейка матки закрыта – то никакого риска нет. 

Делаем вывод: если вы и ваш партнер хотите полового контакта, если у вас все хорошо с влечением, если у вас нет противопоказаний – половая жизнь разрешена и рекомендуется.

На что стоит обратить внимание в самом процессе? 

не должно быть резких движений
не должно быть поз, в которых есть давление на живот
вам и вашему партнеру должно быть комфортно
частота половых актов также должна быть комфортной для обоих партнеров
Не стесняйтесь задавать вопросы об интимной жизни своему врачу. Из-за многочисленных предрассудков, которые до сих пор есть вокруг темы беременности, мы не живем полной спокойной, расслабленной жизнью – а это нужно и важно делать.

${separator}

Урок 5. Будущая мама на работе

Обсудим, как вести себя на работе, когда женщина узнала о состоянии беременности.

Если вы хорошо себя чувствуете, если ваша работа не связана с факторами риска – спокойно ходите на работу, как это было раньше. Уведомить работодателя о том, что вы в состоянии беременности вы можете в любой комфортный для вас момент. Нет никакого нормативно-правового акта, который говорит вам о сроках и обязательствах на этот счет.

Конечно же, рекомендуем вам сделать это в такой срок, чтобы ваш работодатель мог планировать штатное расписание, искать на ваше место другого человека.

Если вы узнали о беременности, в первую очередь рекомендуем вам оценить, есть ли у вас работе какие-либо факторы риска. Есть определенный перечень вопросов, который юридически утвержден Трудовым кодексом РБ, когда будущая мама имеет право обсудить с начальством изменения в своей деятельности (или в своем графике). В таком случае нужно максимально быстро сообщить ему о своей беременности. Что это за факторы? 

подъем тяжестей
ионизирующее излучение
лакокрасочные производства и пары
работа с ненормированным графиком
работа в ночные смены
частые перелеты
работа, связанная с эмоциональной нагрузкой
постоянный контакт с монитором компьютера
и многое другое.
Эти вопросы вы можете обсудить с работодателями и юристом вашей компании и принять решение, показан ли вам перевод на более легкий труд либо вы можете продолжать работать в том же режиме. 

Есть ряд вещей, которые, даже если будущая мама хочет, Трудовой кодекс не рекомендует. Например, будущим мамам не рекомендуется работать ночью, а также в праздничные дни. Согласно нашему трудовому законодательству, эв эти дни беременные женщины должны быть освобождены от работы.

Также согласно нашим юридическим нормам, беременной женщине показано в 30 недель идти в декретный отпуск – готовиться к родам, собирать сумку в роддом, смотреть обучающие курсы. Это золотое время, когда ваш мозг нацелен на самое главное – рождение здорового малыша. 

Конечно, есть много будущих мам, которые как до беременности, так и во время всей беременности до самых родов абсолютно хорошо себя чувствуют.  У некоторых женщин работоспособность даже повышается. И это тоже нормально, потому что каждая беременность уникальна.

Иногда у будущих мам во время беременности открываются различные таланты и способности. Поэтому советуем вам в 30 недель беременности спокойно покинуть рабочее место и в удовольствие заниматься домашними делами, хобби, развитием. Если вы понимаете, что вы без любимой работы не можете найти себе места – вы можете вести аккаунт в Instagram или создать YouTube-канал. Живите в удовольствие и развивайтесь!

Главный вывод: не делаем ничего себе во вред. Если вы понимаете, что на вашем рабочем месте вам дискомфортно, либо вы знаете, что у вас есть факторы риска, которые позволяют вам перейти на более легкий труд, – об этом нужно максимально быстро поговорить со своими работодателями. Помните, что никто не имеет права уволить вас по причине того, что вы беременны. Женщина защищена трудовым законодательством. И также помните о том, что, если мы чувствуем себя прекрасно, то можно спокойно работать до 30 недель.

${tb2c4_4}

Урок 6. Будущая мама в дороге

Автомобиль

Беременным женщинам водить автомобиль не запрещено на любом сроке. Самый главный совет – оцените свое состояние и принимайте решение, можно вам сегодня садиться за руль или нет. 

И это касается не только будущих мам, это касается в принципе всех людей. Иногда нам очень болит живот, потому что критические дни, иногда мы можем быть немного рассредоточены в своем внимании, потому что мигрень. В таких случаях, конечно, машиной лучше не управлять. Во время беременности таких ситуаций может быть еще больше. Это может быть:

токсикоз
сонливость
состояние тревожности
мысли о предстоящих родах
В таком случае лучше не садиться за руль.

С 2019 года в Беларуси будущих мам больше не ограничивают в управлении автомобилем. Раньше считалось, что после 30 недель будущей маме не рекомендовано управлять автотранспортным средством. И в связи с этим возникало много сложностей – например, сотрудник ГАИ не всегда мог определить, на каком сроке беременности находится женщина. Также было очень много обращений от будущих мам. И были внесены поправки. Конечно, есть ряд нюансов, при которых будущая мама имеет некоторые запреты. Так,  будущим мамам после 30 недель нельзя управлять мотоциклом, грузовиком и другими транспортными средствами, которые не относятся к обычным автомобилям. 

Советы для будущих мам в автомобиле

Находясь в машине как водитель или как пассажир, адаптируйте автомобиль под состояние беременности. Положите в машину следующие важные вещи:

мятные леденцы на случай токсикоза
вода
мини-аптечка (помимо основной автомобильной, где будут находиться лекарственные средства, которые вы принимаете постоянно либо в случае экстренной необходимости)
легкий перекус: батончик-мюсли, горький шоколад, сухофрукты
Популярный вопрос: отключать ли подушки безопасности.  Тут мнения специалистов разных стран расходятся. Мы иногда рекомендуем отключать, ведь риск от того, что она взорвется и произойдет определенное давление на животик будущей мамы, достаточно высокий. Рекомендуем этот вопрос отдельно обсудить со специалистами, с супругом, чтобы принять решение, как нужно поступить конкретно вам. 

Одно из основных правил – оценивайте свое состояние в пути. Если вы видите, что вы устали, у вас рассредоточено внимание, вам хочется кушать, пить – вы останавливаетесь, делаете это действие и продолжаете движение. Помните, что вы отвечаете за свое состояние и за состояние малыша.

Также вы можете приобрести специальный ремень (адаптер ремня для беременных). Когда вы используете обычный ремень безопасности, одна его часть проходит через весь животик. И во время беременности (особенно когда вы долго находитесь в автомобиле или когда вы резко тормозите) действительно может быть нагрузка на матку и на плодный пузырь. Поэтому существуют такие специальные ремни, которые фиксируют стандартный ремень, оттягивая его немного вниз. Тем самым мы уберем давление на низ живота. Если вы часто водите автомобиль во время беременности, такой адаптер может вам очень помочь.

Еще один важный совет – по возможности не приезжать во время схваток самостоятельно на автомобиле на роды. Постарайтесь поручить процесс вождения супругу, партнеру или машине скорой помощи.

Перелеты

Перелеты и переезды в железнодорожном транспорте также не запрещены. Если вы чувствуете себя хорошо и врач не говорил вам о противопоказаниях, то можете отправляться в путешествие. 

Особенно позитивно я отношусь к путешествиям, которые планируются после после 10-12 недель, когда беременность в вашем организме уже «заявила о своих правах», а жизненно важные органы и системы малыша сформировались. 

Советы для беременных путешественниц

Придите на прием к врачу акушеру-гинекологу. Его консультация очень важна. Рекомендуем согласовать с ним, можно ли вам лететь (ехать) в конкретную страну. Если у вас такие ситуации, как ИЦН, угроза преждевременных родов, излитие околоплодных вод, избыточный маточный тонус, центральное предлежание плаценты, мажущие выделения кровянистые, преэклампсия – долгое время находиться в пути или лететь на самолете вам не рекомендуется.
Выберите страну с подходящим, комфортным климатом.
Оформите страховку. Она должна быть расширенной. Скажите страховой компании, что вы в состоянии беременности. 
Заранее изучите то место, куда планируете ехать, чтобы медицинская помощь там была развита, доступна.
Подготовьте аптечку, в которой будут лекарственные средства, которые вы принимаете постоянно, а также стандартные лекарственные средства (например, от пищевого отравления, аллергических реакций, высокой температуры).
Согласуйте с авиакомпанией-перевозчиком вопрос о том, на каком сроке они могут пропустить будущую маму на борт самолета. Все компании разные, и у каждой компании свои ограничения. Накануне родов, конечно, мы не рекомендуем вам перелеты (если только вы летите не на роды). Также уточните у компании-авиаперевозчика, что вам нужно при себе иметь (например, это может быть какая-то специальная справка).
Во время перелета

Старайтесь не сидеть долго, делайте перерывы. Можете ходить по салону самолета (когда это доступно).
Рекомендуем пить жидкость.
Рекомендуем быть в компрессионных чулках.
Можете брать с собой шейную подушку, какие-то тренажеры для рук, делать легкую гимнастику – все это очень показано. Пассивный образ жизни связан с риском тромбообразования, о котором мы с вами говорили в первом уроке.

${tb2c4_5}

Урок 7. Здоровье и прием лекарственных препаратов во время беременности

Если будущая мама рационально и правильно питается в течение всей беременности, то с высокой долей вероятности организм именно из пищи возьмет все необходимые витамины и микроэлементы.

Если же возникают какие-то нюансы (например, токсикоз, вопросы с ростом и развитием малыша, индивидуальные особенности и предпочтения в рационе), то советуем обсудить с вашим врачом на приеме прием препаратов. Врач оценит ваши анализы, сделает скрининг вашего состояния здоровья и посоветует, какие витамины и какие микроэлементы вам необходимы. 

Фолиевая кислота

Существует один витамин, который рекомендован для беременных врачебной общественностью любой страны – это фолиевая кислота (витамин B9). Она профилактирует пороки развития нервной системы ребенка (часто говорят «пороки развития нервной трубки»).

Фолиевая кислота является водорастворимым витамином, а значит, она не очень может долго накапливаться нашим организмом. И она не синтезируется у нас в организме. Она поступает к нам с пищей либо с препаратами.

Есть два вида так называемой фолиевой кислоты: есть фолаты и есть фолиевая кислота. Фолаты – это натуральная фолиевая кислота, которую наш организм берет из продуктов питания. Это все зеленое – шпинат, руккола, листья салата, бобовые, а также молочная продукция, мясо. 

Принимать фолиевую кислоту можно всем женщинам репродуктивного возраста в принципе, выбирая определенные дозировки (в зависимости от состояния организма). Потому что определенный процент беременностей является незапланированным. И если вдруг у женщин репродуктивного возраста возникает незапланированная беременность, то фолиевой кислоты в организме уже будет достаточно.

Планирующим беременность женщинам рекомендуется прием ФК как минимум за три месяца (мужчине – тоже). И с наступлением беременности женщина продолжает прием фолиевой кислоты, мужчина может его закончить. В среднем ФК советуют принимать до 12 недель беременности.  Стандартная дозировка – 400 мкг/сутки.

Но все зависит от состояния вашего организма. Если у вас много экстрагенитальной патологии, если у вас исходное состояние организма вызывает вопросы, если у вас очень серьезный токсикоз – возможно, фолиевую кислоту нужно принимать дольше. 

Более того, нужно понимать, как она усваивается. Врач может назначить прием фолиевой кислоты в комплексе с другими витаминами. 

Прием фолиевой кислоты в больших количествах (в избыточных) может навредить состоянию ребенка, влиять на иммунитет, на умственные способности после родов. 

Витамин D

Рекомендуем будущим мамам прийти на прием к врачу и оценить анализы, нет ли у вас дефицита витамина D. Витамин D влияет на то, как усваивается кальций и фосфор. А кальций и фосфор – это кости нашего малыша. На приеме с врачом вы сможете выбрать конкретную форму препарата, дозировку, которая подходит конкретно вам. 

Витамин Е

Раньше витамин E входил в обязательно рекомендуемый комплекс для будущих мам. То есть при планировании беременности женщинам рекомендовали принимать фолиевую кислоту и витамин E. Сейчас такого нет. Прием этого витамина нужно обсуждать с вашим врачом акушером-гинекологом.

Йод

Большинство источников ВОЗ и различных исследований говорят о том, что беременным рекомендован прием йода. Средняя дозировка – 250 мкг. 

В большинстве стран СНГ (и в большинстве стран мира) наблюдается йододефицит. Не зря у нас в стране есть массовое внедрение добавок йода в продукты питания. Например, есть йодированная соль и другие йодированные продукты. 

Обратите внимание: есть ситуации, при которых такая добавка ни в коем случае не должна использоваться! О вопросе приема йода рекомендую проконсультироваться с эндокринологом. Возможно, вам нужно принимать какой-то комплексный препарат, в составе которого есть йод). А возможно, вам нужно будет выбирать препарат без йода.

Железо

Еще важный микроэлемент, на который я обращаю ваше внимание, – это препараты железа. Почему он важнейший? Обратите внимание на цепочку железо → гемоглобин → кислород.

От количества железа в нашем организме у нас зависит насыщаемость ребенка кислородом. Сывороточное железо и гемоглобин в анализе крови – это те показатели, которые важно контролировать. Врач может рекомендовать вам прием пищи, в составе которой содержится много железа, а также прием препаратов.

В пище железо может быть гемовое и негемовое. Кроме того, что будущая мама может есть растительную продукцию (яблоки, гречку), важно добавлять в рацион мясо, чтобы восполнять баланс.

Главный вывод: если вы хорошо питаетесь, если вас ничего не беспокоит, то надо попытаться восполнить необходимый баланс витаминов и микроэлементов из пищи. Если вас что-то беспокоит, если у вас есть состояния, которые вызывают вопросы, – вы вместе с врачом подбираете препарат либо витаминно-минеральный комплекс, который подходит конкретно вам. 

Идеальный организм не требует вообще ни одного лекарственного средства. Природой заложено, что вы хорошо кушаете, отдыхаете, а не принимаете 15 препаратов одновременно.

Помните, что все, что вы намазали на кожу, приняли, выпили, имеет свое влияние. Все, что вы используете во время беременности, употребляете – все попадает внутрь. Поэтому прошу вас очень осознанно относиться к приему витаминов, микроэлементов, к питанию во время беременности, тем более к приему лекарственных средств. Принимать все только по согласованию с врачом акушером-гинекологом. 

Обязательно расспросите у своего врача, что за препараты он вам назначил, какие могут быть побочные эффекты, неожиданные последствия. Это поможет вам следить за состоянием здоровья, ведь мы не находимся с врачом 24 часа вместе. Советуем вам быть вовлеченными и образованными!

${separator}

Урок 8. Подведение итогов курса

Если вы чувствуете себя хорошо – наслаждайтесь своим состоянием! Старайтесь не искать проблем и не думать, что что-то может пойти не так. Как мы говорили ранее, беременность – это не болезнь. И ваш организм уникален!

Есть интересное исследование о том, как протекают беременности у сестер-близняшек. Ведь они одинаковые, родились и развивались вместе. А осложнения во время их беременностей (либо их отсутствие) и то, как протекают роды, – абсолютно разные. Это говорит о том, что многое зависит от нашего восприятия, состояния здоровья, с которым мы подошли к моменту беременности, от того, как мы ведем себя в этот период.

Старайтесь не сравнивать свое течение беременности с течением беременности у подруги, соседки или знакомой. Лучше сравнивайте свое состояние сегодня со своим вчерашним состоянием. Идеальный сценарий – когда вам не хуже, чем вчера, у вас не появилось никаких новых, неприятных или непонятных вам симптомов. А если они появились – обсудите это с врачами. И мы вместе разберемся, норма ли это, а также при необходимости назначим препараты.

Старайтесь, чтобы ваша беременность приносила вам удовольствие. Общайтесь с другими будущими мамами на позитивные темы. Контактируйте со своими близкими и родными. Во время беременности вам может казаться, что вокруг вас много внимания и советов. Старайтесь в этих советах не дезориентироваться. Но при этом старайтесь не блокировать человеку возможность уделить вам внимание и попереживать, посочувствовать, чем-то поделиться, побыть для вас в чем-то экспертом. 

Всегда помните про осознанность. Всегда важно понимать, что и зачем вы делаете. И тогда у вас не будет никаких страхов и переживаний, потому что вы будете понимать, к чему и как вы идете.

Если вы замечаете, что во время беременности у вас появились какие-то патологические страхи (страхи о предстоящих родах, страхи о состоянии малыша и так далее), и этот вопрос вы не можете закрыть на приеме с врачом акушером-гинекологом – я рекомендую вам обратиться за помощью к психологу.

Ваша беременность уникальна! Вы – здоровый человек в состоянии особого женского счастья!
    `,
};
