import { AdvicesIcon, CoursesIcon, PostsIcon } from 'assets/icons';
import { FC } from 'react';
import { Card, TCard } from './Card/Card';

const cards: TCard[] = [
  {
    name: 'Course',
    icon: CoursesIcon,
    title: 'learning_course_title',
    text: 'learning_course_text',
    link: '/edu',
  },
  {
    name: 'Advice',
    icon: AdvicesIcon,
    title: 'learning_advice_title',
    text: 'learning_advice_text',
    link: '/edu',
  },
  {
    name: 'Post',
    icon: PostsIcon,
    title: 'learning_post_title',
    text: 'learning_post_text',
    link: '/edu',
  },
];

export const CardList: FC = () => (
  <>
    {cards.map((card) => (
      <Card key={card.name} data={card} />
    ))}
  </>
);
