import ReactDOM from 'react-dom/client';
import "react-datepicker/dist/react-datepicker.css";
import './styles/main.css';
import './styles/colors.css';
import './styles/datePicker.css'
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { StrictMode } from 'react';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <StrictMode>
      <App />
    </StrictMode>
  </BrowserRouter>
);
