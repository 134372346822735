import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { User } from '../type';


const initialState: User = {
  dateOfChildBirth: null,
  dateOfPregnancy: null,
  interests: [],
  id: null,
  username: null,
  firstName: null,
  lastName: null,
  photoURL: null,
  email: null,
  country: null,
  town: null,
  locale: 'ru',
  platform: null,
  registrationDate: null,
  lastLoggedInDate: null,
  usageOption: null,
  waeb: null,
};


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<Partial<User>>) => (
      {
        ...state,
        ...action.payload,
      }
    ),
    resetUser: () => initialState,
  },
});


const { reducer, actions } = userSlice;

export const {
  updateUser, resetUser,
} = actions;

export default reducer;
