import { FC, useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { FormFields } from './validations/constants';
import { getForgotPasswordValidationSchema } from './validations/AuthValidationSchems';
import { useTranslate } from 'hooks/useTranslate';
import ArrowNavButton from 'components/Buttons/ArrowNavButton/ArrowNavButton';
import { AppRoutes } from 'navigation/constants';
import { Title } from 'components/Text/Title/Title';
import { Text } from 'components/Text/Text/Text';
import Input from './components/Input/Input';
import ErrorMessage from 'components/Messages/ErrorMessage/ErrorMessage';
import SubmitButton from './components/SubmitButton/SubmitButton';
import Footer from 'components/Footer/Footer';
import { AuthErrorCode } from './constants/constants';
import { useAuth } from './hooks/useAuth';
import { PasswordResetNotification } from './components/PasswordResetNotification/PasswordResetNotification';

const { EMAIL } = FormFields;

const LIMIT = 49;

export const ForgotPasswordScreen: FC = () => {
  const t = useTranslate();
  const { emailResetPassword } = useAuth();
  const [disabled, setDisabled] = useState(false);
  const [time, setTime] = useState(LIMIT);

  useEffect(() => {
    let interval: any;
    if (disabled) {
      !!interval && clearInterval(interval);
      interval = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime === 0) {
            !!interval && clearInterval(interval);
            setDisabled(false);
            return LIMIT;
          } else {
            return prevTime - 1;
          }
        });
      }, 1000);
    }
    return () => {
      !!interval && clearInterval(interval);
    };
  }, [setDisabled, setTime, disabled]);
  const formattedTime =
    time >= 0 ? `0:${String(time).padStart(2, '0')}` : '0:00';

  const formik = useFormik({
    initialValues: {
      [EMAIL]: '',
    },
    validationSchema: getForgotPasswordValidationSchema(),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setStatus, resetForm }) => {
      resetForm();

      emailResetPassword(values[EMAIL], (code: AuthErrorCode) =>
        setStatus({ error: code }),
      );
    },
  });

  const handleChangeFocus = (isFocused: boolean) => {
    if (isFocused) {
      formik.setStatus({});
      formik.setErrors({});
    }
  };

  const handleResetPassword = () => {
    if (formik.values[EMAIL] && formik.isValid) {
      setDisabled(true);
      formik.handleSubmit();
    }
  };

  return (
    <>
      <header className="auth-header">
        <div className="auth-container auth-header-container">
          <ArrowNavButton
            title={t('forgot_password_come_in')}
            to={AppRoutes.LOGIN}
          />
        </div>
      </header>
      <main className="auth-main">
        <div className="auth-container flex-column-center">
          <Title
            text={t('forgot_password_title')}
            highlight={t('forgot_password_title_highlight')}
            center
          />
          <div className="auth-description">
            <Text text={t('forgot_password_description')} />
          </div>
          <div className="auth-inputs-container">
            <Input
              id={EMAIL}
              label={t('forgot_password_placeholder_email')}
              value={formik.values[EMAIL]}
              onChange={formik.handleChange}
              onChangeFocus={handleChangeFocus}
              error={!formik.isValid}
            />
            {!formik.isValid && (
              <ErrorMessage message={t('forgot_password_error')} />
            )}
            {formik.status?.error && (
              <ErrorMessage message={t(formik.status.error)} />
            )}
            {disabled &&
              !formik.status?.error &&
              formik.isValid &&
              formattedTime !== '0:00' && (
                <PasswordResetNotification formattedTime={formattedTime} />
              )}

            <SubmitButton
              title={t('forgot_password_submit')}
              onClick={handleResetPassword}
              disabled={disabled && formik.isValid && formattedTime !== '0:00'}
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
