export const b1_c2_text = {
    b1_c2_title: `Урок «Социальная поддержка семьи: пособия по беременности и родам»`,
    b1_c2_text: `
    Как только женщина узнает, что она беременна, ей рекомендуется стать на учет по беременности в организации здравоохранения. Это может быть: 

женская консультация
или частный медицинский центр, который осуществляет ведение беременности
В женской консультации или в частном центре в 30-ть недель беременности женщина получит первый важный документ – это лист нетрудоспособности по беременности и родам. Лист нетрудоспособности по беременности и родам выдается единовременно на 126 календарных дней (70 дней до родов и 56 дней после родов). 

С этого момента у женщины начинается отпуск по беременности и родам – или декретный отпуск. 

Женщина, которая выходит в декретный отпуск, получает оплату больничного листа. Это называется пособие по беременности и родам. Чтобы получить выплату и оформить декретный отпуск, необходимо предоставить лист нетрудоспособности в бухгалтерию по месту работы или учебы сразу после его получения. 

Такая выплата предоставляется единовременно, как правило, в стандартный день получения зарплаты. Размер выплаты составляет 100% от среднедневной зарплаты в течение последнего полугода, которые умножаются на количество дней декрета.

Если женщина не работает, то пособие по беременности и родам не выплачивается.

Уже после родов, в случае, если у женщины были осложненные роды, операция кесарево сечение либо рождение двойни, тройни и более детей – родильный дом выдаст женщине дополнительный лист нетрудоспособности на 14 календарных дней в связи с осложненными родами. 

После рождения малыша и завершения декретного отпуска у мамы начинается отпуск по уходу за ребенком до 3-х лет. Женщине нужно принести в бухгалтерию по месту работы:

Дополнительный листок нетрудоспособности (если он был выдан)
Свидетельство о рождении ребенка
И заявление о том, что она уходит в отпуск по уходу за ребенком до 3-х лет. 
Также после родов необходимо написать заявление на получение остальных пособий (это нужно сделать в течение 6 месяцев). Вместе с заявлением необходимо подать пакет документов. Его вы можете заранее уточнить на работе.

После родов мама получает следующие пособия:

Пособие женщинам, которые обратились (стали на учет) в женскую консультацию до наступления 12 недель беременности
Единовременное пособие в связи с рождением ребенка
Ежемесячное пособие по уходу за ребенком в возрасте до 3-х лет 

Обсудим их.

Пособие женщинам, которые обратились (стали на учет) в женскую консультацию до наступления 12 недель беременности
Если вы стали на учет в женскую консультацию до наступления 12 недель беременности, после рождения малыша вам полагается разовое пособие.

Как получить пособие?

После родов в течение 6-ти месяцев необходимо обратиться в женскую консультацию для получения справки. Эту справку необходимо отнести в бухгалтерию по месту работы, учебы или в орган по труду, занятости и социальной защиты вместе со следующими документами:
заявление
паспорт или документ, удостоверяющий личность
выписка из трудовой книжки заявителя или документ, подтверждающий занятость человека
свидетельство о заключении брака или свидетельство о расторжении брака

Размер пособия составляет 1 бюджет прожиточного минимума, который действует на дату рождения ребенка. Пособие выплачивается в день зарплаты.

Единовременное пособие в связи с рождением ребенка

При выписке из роддома каждая женщина получает справку о рождении ребенка. С этой справкой она идет в ЗАГС для регистрации малыша. С собой, кроме справки, необходимо взять:

паспорта обоих родителей
свидетельства о рождении обоих родителей
свидетельство о заключении брака
Ребенок регистрируется, и в течение нескольких дней родителям выдается свидетельство о рождении ребенка. С этим документом женщина идет на работу для написания заявления и получения пособия. К свидетельству о рождении нужно будет приложить пакет других документов, обязательно уточните его заранее на работе.

Пособие выплачивается единовременно, то есть один раз на каждого ребенка. Размер пособия зависит от того, первый это ребенок или второй и последующие дети: 

если это первый ребенок, то размер пособия составляет 10 бюджетов прожиточного минимума, действующего на дату рождения ребенка.
если это пособие по второму и последующим детям, оно составляет 14 бюджетов прожиточного минимума.
Обратиться за пособием нужно в течение 6-ти месяцев после рождения ребенка. Пособие обычно выплачивается в течение 10 дней со дня подачи всех документов.

В случае рождения двойни и более детей предусмотрено дополнительное пособие – единовременная выплата на приобретение детских вещей первой необходимости. Для этого нужно обратиться в Орган по труду, занятости и социальной защите, написать заявление и приложить необходимые документы. Пособие можно получить в течение 6-ти месяцев со дня рождения. Размер выплаты равен 2-ум бюджетам прожиточного минимума на каждого ребенка.

Пособие по уходу за ребенком в возрасте до 3-х лет 
После рождения ребенка и после окончания отпуска по беременности и родам, женщине выплачивается ежемесячное государственное пособие по уходу за ребенком в возрасте до 3-х лет.

Пособие выплачивается по день достижения ребенком возраста 3-х лет включительно. Размер пособия:

на первого ребенка – 35% среднемесячной заработной платы за квартал;
на второго и последующих – 40% среднемесячной заработной платы;

Выплата происходит по месту работы. В случае, если женщина не работает, то в органах труда, занятости и социальной защиты. 

Дополнительно существует пособие на детей от 3-х до 18-ти лет в период воспитания ребенка в возрасте до 3-х лет. Его выплата происходит ежемесячно в размере 50% бюджета прожиточного минимума. На семью выплачивается одно пособие, даже если воспитывается еще несколько детей в возрасте от 3-х до 18-ти лет. Пособие выплачивается вместе с пособием по уходу за ребенком до 3-х лет.

Список документов на каждое детское пособие индивидуален. Вы можете заранее уточнить список необходимых документов в бухгалтерии по месту работы, учебы или в органах труда, занятости и социальной защиты.
    `
 }