import {
  b3c10q1,
  b3c10q2,
  b3c10q3,
  b3c11q1,
  b3c11q2,
  b3c11q3,
  b3c12q1,
  b3c12q2,
  b3c12q3,
  b3c13q1,
  b3c13q2,
  b3c13q3,
  b3c8q1,
  b3c8q2,
  b3c8q3,
  b3c9q1,
  b3c9q2,
  b3c9q3,
} from 'assets/images';

export const b3 = {
  c8: {
    title: 'b3_c8_title',
    author: 'b3_c8_author',
    description: 'b3_c8_description',
    lessonsLength: 7,
    video: 'https://vimeo.com/showcase/9263444/embed',
    tests: [
      {
        title: 'b3_c8_tests_title',
        question: 'b3_c8_test1_question',
        answers: {
          a1: 'b3_c8_test1_answer_a1',
          a2: 'b3_c8_test1_answer_a2',
          a3: 'b3_c8_test1_answer_a3',
        },
        correctAnswerId: 3,
        wrongAnswer: 'b3_c8_test1_wrongAnswer',
        correctAnswer: 'b3_c8_test1_correctAnswer',
        image: b3c8q1,
      },
      {
        title: 'b3_c8_tests_title',
        question: 'b3_c8_test2_question',
        answers: {
          a1: 'b3_c8_test2_answer_a1',
          a2: 'b3_c8_test2_answer_a2',
          a3: 'b3_c8_test2_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b3_c8_test2_wrongAnswer',
        correctAnswer: 'b3_c8_test2_correctAnswer',
        image: b3c8q2,
      },
      {
        title: 'b3_c8_tests_title',
        question: 'b3_c8_test3_question',
        answers: {
          a1: 'b3_c8_test3_answer_a1',
          a2: 'b3_c8_test3_answer_a2',
          a3: 'b3_c8_test3_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b3_c8_test3_wrongAnswer',
        correctAnswer: 'b3_c8_test3_correctAnswer',
        image: b3c8q3,
      },
    ],
  },

  c9: {
    title: 'b3_c9_title',
    author: 'b3_c9_author',
    description: 'b3_c9_description',
    lessonsLength: 7,
    video: 'https://vimeo.com/showcase/9263462/embed',
    tests: [
      {
        title: 'b3_c9_tests_title',
        question: 'b3_c9_test1_question',
        answers: {
          a1: 'b3_c9_test1_answer_a1',
          a2: 'b3_c9_test1_answer_a2',
          a3: 'b3_c9_test1_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b3_c9_test1_wrongAnswer',
        correctAnswer: 'b3_c9_test1_correctAnswer',
        image: b3c9q1,
      },
      {
        title: 'b3_c9_tests_title',
        question: 'b3_c9_test2_question',
        answers: {
          a1: 'b3_c9_test2_answer_a1',
          a2: 'b3_c9_test2_answer_a2',
          a3: 'b3_c9_test2_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b3_c9_test2_wrongAnswer',
        correctAnswer: 'b3_c9_test2_correctAnswer',
        image: b3c9q2,
      },
      {
        title: 'b3_c9_tests_title',
        question: 'b3_c9_test3_question',
        answers: {
          a1: 'b3_c9_test3_answer_a1',
          a2: 'b3_c9_test3_answer_a2',
          a3: 'b3_c9_test3_answer_a3',
        },
        correctAnswerId: 3,
        wrongAnswer: 'b3_c9_test3_wrongAnswer',
        correctAnswer: 'b3_c9_test3_correctAnswer',
        image: b3c9q3,
      },
    ],
  },

  c10: {
    title: 'b3_c10_title',
    author: 'b3_c10_author',
    description: 'b3_c10_description',
    lessonsLength: 8,
    video: 'https://vimeo.com/showcase/9263476/embed',
    tests: [
      {
        title: 'b3_c10_tests_title',
        question: 'b3_c10_test1_question',
        answers: {
          a1: 'b3_c10_test1_answer_a1',
          a2: 'b3_c10_test1_answer_a2',
          a3: 'b3_c10_test1_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b3_c10_test1_wrongAnswer',
        correctAnswer: 'b3_c10_test1_correctAnswer',
        image: b3c10q1,
      },
      {
        title: 'b3_c10_tests_title',
        question: 'b3_c10_test2_question',
        answers: {
          a1: 'b3_c10_test2_answer_a1',
          a2: 'b3_c10_test2_answer_a2',
          a3: 'b3_c10_test2_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b3_c10_test2_wrongAnswer',
        correctAnswer: 'b3_c10_test2_correctAnswer',
        image: b3c10q2,
      },
      {
        title: 'b3_c10_tests_title',
        question: 'b3_c10_test3_question',
        answers: {
          a1: 'b3_c10_test3_answer_a1',
          a2: 'b3_c10_test3_answer_a2',
          a3: 'b3_c10_test3_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b3_c10_test3_wrongAnswer',
        correctAnswer: 'b3_c10_test3_correctAnswer',
        image: b3c10q3,
      },
    ],
  },

  c11: {
    title: 'b3_c11_title',
    author: 'b3_c11_author',
    description: 'b3_c11_description',
    lessonsLength: 8,
    video: 'https://vimeo.com/showcase/9263506/embed',
    tests: [
      {
        title: 'b3_c11_tests_title',
        question: 'b3_c11_test1_question',
        answers: {
          a1: 'b3_c11_test1_answer_a1',
          a2: 'b3_c11_test1_answer_a2',
          a3: 'b3_c11_test1_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b3_c11_test1_wrongAnswer',
        correctAnswer: 'b3_c11_test1_correctAnswer',
        image: b3c11q1,
      },
      {
        title: 'b3_c11_tests_title',
        question: 'b3_c11_test2_question',
        answers: {
          a1: 'b3_c11_test2_answer_a1',
          a2: 'b3_c11_test2_answer_a2',
          a3: 'b3_c11_test2_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b3_c11_test2_wrongAnswer',
        correctAnswer: 'b3_c11_test2_correctAnswer',
        image: b3c11q2,
      },
      {
        title: 'b3_c11_tests_title',
        question: 'b3_c11_test3_question',
        answers: {
          a1: 'b3_c11_test3_answer_a1',
          a2: 'b3_c11_test3_answer_a2',
          a3: 'b3_c11_test3_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b3_c11_test3_wrongAnswer',
        correctAnswer: 'b3_c11_test3_correctAnswer',
        image: b3c11q3,
      },
    ],
  },

  c12: {
    title: 'b3_c12_title',
    author: 'b3_c12_author',
    description: 'b3_c12_description',
    lessonsLength: 7,
    video: 'https://vimeo.com/showcase/8175303/embed',
    tests: [
      {
        title: 'b3_c12_tests_title',
        question: 'b3_c12_test1_question',
        answers: {
          a1: 'b3_c12_test1_answer_a1',
          a2: 'b3_c12_test1_answer_a2',
          a3: 'b3_c12_test1_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b3_c12_test1_wrongAnswer',
        correctAnswer: 'b3_c12_test1_correctAnswer',
        image: b3c12q1,
      },
      {
        title: 'b3_c12_tests_title',
        question: 'b3_c12_test2_question',
        answers: {
          a1: 'b3_c12_test2_answer_a1',
          a2: 'b3_c12_test2_answer_a2',
          a3: 'b3_c12_test2_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b3_c12_test2_wrongAnswer',
        correctAnswer: 'b3_c12_test2_correctAnswer',
        image: b3c12q2,
      },
      {
        title: 'b3_c12_tests_title',
        question: 'b3_c12_test3_question',
        answers: {
          a1: 'b3_c12_test3_answer_a1',
          a2: 'b3_c12_test3_answer_a2',
          a3: 'b3_c12_test3_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b3_c12_test3_wrongAnswer',
        correctAnswer: 'b3_c12_test3_correctAnswer',
        image: b3c12q3,
      },
    ],
  },

  c13: {
    title: 'b3_c13_title',
    author: 'b3_c13_author',
    description: 'b3_c13_description',
    last: true,
    lessonsLength: 7,
    video: 'https://vimeo.com/showcase/9263530/embed',
    tests: [
      {
        title: 'b3_c13_tests_title',
        question: 'b3_c13_test1_question',
        answers: {
          a1: 'b3_c13_test1_answer_a1',
          a2: 'b3_c13_test1_answer_a2',
          a3: 'b3_c13_test1_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b3_c13_test1_wrongAnswer',
        correctAnswer: 'b3_c13_test1_correctAnswer',
        image: b3c13q1,
      },
      {
        title: 'b3_c13_tests_title',
        question: 'b3_c13_test2_question',
        answers: {
          a1: 'b3_c13_test2_answer_a1',
          a2: 'b3_c13_test2_answer_a2',
          a3: 'b3_c13_test2_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b3_c13_test2_wrongAnswer',
        correctAnswer: 'b3_c13_test2_correctAnswer',
        image: b3c13q2,
      },
      {
        title: 'b3_c13_tests_title',
        question: 'b3_c13_test3_question',
        answers: {
          a1: 'b3_c13_test3_answer_a1',
          a2: 'b3_c13_test3_answer_a2',
          a3: 'b3_c13_test3_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b3_c13_test3_wrongAnswer',
        correctAnswer: 'b3_c13_test3_correctAnswer',
        image: b3c13q3,
      },
    ],
  },
};
