import {
  separator,
  tb5c4_1,
  tb5c4_10,
  tb5c4_2,
  tb5c4_3,
  tb5c4_4,
  tb5c4_5,
  tb5c4_6,
  tb5c4_7,
  tb5c4_8,
  tb5c4_9,
} from 'assets/images';

export const b5_c19_text = {
  b5_c4_title: `Курс «Развитие ребенка в первый год жизни»`,
  b5_c4_description: `Спикер: Надежда Дубатовка, врач-педиатр`,
  b5_c19_text: `
    ${tb5c4_1}

    Урок 1. Физическое развитие ребенка. Нормы роста и веса

Часто, задавая вопросы педиатрам, родители начинают свой вопрос с того, что ребенок должен сделать в определенном возрасте. Очень рекомендую в таких вопросах менять формулировку. Важно понимать: ребенок никому ничего не должен. Развитие каждого ребенка индивидуально. 

Физическое развитие оценивается по специальным таблицам Всемирной организации здравоохранения. Согласно этому графику можно примерно определить, на каком же уровне развития физического находится ваш ребенок.

ГРАФИКИ РОСТА

${tb5c4_2}
${tb5c4_3}
ГРАФИКИ ВЕСА

${tb5c4_4}
${tb5c4_5}

Этот график состоит из пяти линеек. Все они являются нормой. И если ваш ребенок не в серединке – это совершенно не значит, что с ним что-то не так. Все эти особенности связаны с генетикой ребенка (особенностями веса, ростовых показателей родителей), а также того, что ребенок кушает, какие особенности здоровья он имеет, с каким весом он родился и как развивается в процессе. 

Наша с вами задача – оценить не безростовые показатели в каком-то одном моменте. Наша задача – это определение динамики роста и веса ребенка.

Именно поэтому мы просим родителей регулярно взвешивать и измерять своих детей, чтобы увидеть минимальные изменения и сразу предпринять меры, если это необходимо.

Вес ребенка не может быть оценен как должное. Например, мы не можем сказать, что в первый месяц жизни ребенок должен набрать столько-то граммов. Каждый ребенок набирает вес совершенно по-разному. Это индивидуальные особенности каждого ребенка.

Тревожным звоночком будет ситуация, когда ребенок колеблется между различными «коридорами». Если график показывает нам резкую потерю веса или остановку в росте – на это важно обратить внимание. Например, если ребенок постоянно рос по третьему «коридору» и вдруг резко перешел на второй – это не норма.

${tb5c4_6}

То же самое касается и роста детей. Дети могут расти некоторыми скачками. И именно в динамике мы – врачи – оцениваем рост ребенка.

Наряду с оценкой темпов набора веса и роста у ребенка чаще всего педиатры еще оценивают темпы прироста размера головы и окружности грудной клетки.

Резюмируем: для гармоничного развития ребенка и оценки развития мы используем такие показатели, как темпы набора веса и роста. Для того чтобы оценить эти темпы, есть специальные таблицы (таблицы ВОЗ). Они есть в электронном виде. Также есть много специальных приложений, при помощи которых можно следить за темпами роста и веса ребенка, оценивать соотношение веса по отношению к росту, оценивать вес по отношению к определенному возрасту и даже учитывать и корректировать вес по отношению к возрасту скорректированному, если вдруг малыш недоношенный. И повторюсь еще раз: оцениваем мы именно динамику веса и роста.

${separator}

Урок 2. Нервно-психическое развитие ребенка: обзор

Как оценивается нервно-психическое развитие малыша? Для этого придумано несколько линий оценки состояния нервно-психического здоровья малыша.

Зрение 

В зависимости от возраста, у каждого ребенка будет изменяться возможность его зрительного анализатора. И если в самом начале малыш может реагировать только на свет или какие-то яркие контрастные картинки, то к концу годика он уже отличает формы, размеры и может показать на какую-то картинку и даже назвать, что изображено на картинке. За целый год ребенок учится пользоваться своими глазами. Это очень интересный процесс.

Слух

Слух малыша тоже развивается невероятными темпами. И если в начале жизни малыш слышит только громкие звуки и реагирует на них, то ближе к году он уже отличает даже музыку, которая ему может нравиться или не нравиться.

Моторные навыки

Когда ребенок может сесть или начать ходить – это все очень интересно. И дети делают это индивидуально. Подробнее я расскажу в уроке про моторные навыки и об этих особенностях, и окнах, в которых появляются эти навыки.

Моторные навыки с более тонкими предметами

Уже начиная примерно с 3-4 месяцев, ребенок может удерживать игрушку в руках, ближе к 6 – перекладывать ее из рук в руки, а в 9 месяцев может следить за брошенной игрушкой. 

Обращенная речь

Мы смотрим, как ребенок реагирует на речь, слышит ли он и понимает ли обращенную речь.

Подготовка к будущей речи

Это его возможность «агукать», произносить какие-то звуки, первые слова. Об этом тоже будет чуть позже подробнее.

Уровень эмоций ребенка

Если в самом начале жизни малыш проявляет свои эмоции только криком и плачем, то в процессе роста (уже в годик) мы можем увидеть, что ребенок проявляет уже особенности своего характера и умеет даже руководить своими родителями. 

Первый год жизни – это самый невероятный по темпам прироста этап в жизни малыша. Советую вам, дорогие родители, беречь каждый момент и стараться не торопить события.

Потому что темпы роста вашего ребенка на данный момент (до года) – это просто космическая работа, очень сложная, и ребенок получает очень много нагрузки. Психоэмоциональная нагрузка может сказываться на его сне и самочувствии, состоянии, естественно, на развитии в том числе. Поэтому старайтесь не торопить события и быть терпеливыми родителями.

${tb5c4_7}

Урок 3. Зрение и слух малыша 

Зрение

Только что рожденный малыш только отличает свет от темноты. И лучше всего реагирует на яркий белый свет. 

Уже на 5-6-й день после начала жизни он будет отличать контрастные белые и черные цвета. Ближе к 1-му месяцу ребенок может различать уже основные цвета, такие, как красный, синий, зеленый и желтый. Однако это не значит, что ребенку нужно часто показывать яркие картинки. Старайтесь не напрягать слишком часто малыша яркими изображениями, лучше старайтесь показывать ему как можно чаще контрастные белые и черные картинки и не перенапрягать кроху, потому что перевозбуждение сильно влияет на его самочувствие, состояние и сон.

Уже на 1-м месяце жизни малыш может начать фиксировать свой взгляд на лице мамы и даже потихонечку начинать ее узнавать и отличать от других людей, которые осуществляют уход за малышом.

В 1,5-2 месяца малыш может следить за движущимся предметом, поэтому ребенка старше месяца мы уже потихонечку учим наблюдать за погремушками. Погремушки должны быть яркие и контрастные, потому что ребенок в этом возрасте видит именно такие цвета.

Ближе к 6 месяцам ребенок уже замечательно контролирует свои глаза, видит как вблизи, так и далеко, и потихонечку учится следить за предметами. И если до 6 месяцев он просто перекладывает игрушку из рук в руки и рассматривает ее, то затем ему становится интересно, что же будет с игрушкой, если ее бросить.

К 9 месяцам ребенок может проследить за предметом, который передвигается довольно быстро. Однако не стоит в этом возрасте показывать детям мультфильмы и телевизор, потому что это перевозбуждает их головной мозг и мешает развитию правильному его нервно-психической системы. Дети в этом возрасте (около 9 месяцев) очень любят следить за домашними животными и могут потихонечку даже показывать вам, на что нравится смотреть, а на что – нет.

Кстати, отличать своих от чужих дети учатся примерно в 6-7 месяцев. Это самый проблемный возраст для педиатров, потому что именно в этом возрасте дети начинают бояться докторов. 

Некоторые дети могут отличать своих от чужих уже и в 5 месяцев. Поэтому если бабушка и дедушка видят малыша довольно редко, бабушка и дедушка уже будут приравниваться к «чужим». Это является очень важным этапом в развитии ребенка, его эмоционального интеллекта, потому что если ребенок идет на руки к кому угодно и не отличает своих от чужих, то может быть довольно серьезным звоночком о том, что что-то идет не так.

В 1 год обязательно рекомендуется показать ребенка окулисту. Он оценит, как сформировался глазик и что физиологически происходит в нем. Осматривает глазное дно у ребенка, оценивает возможность видеть и контролировать аккомодацию, то есть видеть предметы вдали и вблизи.

Детки имеют физиологическую гиперметропию (это так называемая дальнозоркость). Это связано с тем, что их глазки чуть больше по размеру по отношению к головному мозгу, и, в принципе, читать или смотреть на мелкие предметы, что-то наблюдать вблизи детям намного сложнее, чем рассматривать что-то вдали. Это является физиологией, однако у некоторых деток могут быть выявлены проблемы, которые решаются при помощи врача-офтальмолога.

Слух

Ухо новорожденного слышит только резкие звуки. И малыш может не слышать тихие какие-то тональности, однако отличает очень хорошо мамин голос от других. И начинает уже прислушиваться к тому, какая эмоциональная окраска у того, что ребенку говорят, уже примерно к месяцу. То есть он не понимает обращенной речи, но очень хорошо воспринимает эмоции, с которыми вы с ним разговариваете. Наверное, поэтому каждый человек, который общается с малышом, начинает говорить как-то более мягко, певуче и стараться всеми силами показать свою доброжелательность и желание понравиться малышу. Малыши чаще всего на это реагируют эмоционально радостно, и когда слышат окрашенный положительно голос, они могут улыбаться в ответ уже примерно в 1,5-2 месяца.

Если же ребенок слышит резкие звуки – он может расстроиться и расплакаться. Это не является какой-то проблемой, но замечайте, что ребенок эмоционально реагирует на окраску речи. Это один из этапов развития эмоционального интеллекта ребенка.

Признаки опасности, которые могут насторожить родителя

Если ваш ребенок:

не реагирует на громкие звуки
не отличает голос взрослого и не реагирует на него
Это повод обратиться к педиатру или ЛОРу для определения качества слуха. Несмотря на то, что в роддомах на данный момент проводят аудиоскрининг и стараются выявлять тугоухость у детей на самых ранних этапах, случаются ситуации, когда врачи обнаруживают несовершенство слуха ребенка на поздних этапах. Тогда ему намного сложнее помочь.

Также обратитесь к врачу, если ребенок:

не следит за игрушкой или не реагирует эмоциями на ваше лицо 
не отличает форму и цвет
никак не реагирует на движущиеся предметы, не следит за ними
Это тоже может быть поводом для беспокойства и направления к узким специалистам для обследования и коррекции при необходимости. 

Однако и впечатляться, если ребенок пару раз пропустил что-то, не нужно, бить тревогу не нужно. Может, у ребенка просто нет настроения или он обиделся. Здесь важна регулярность. Если вы видите, что проблема постоянна – с ней надо что-то делать.

${separator}

Урок 4. Моторное развитие ребенка

Общие движения малыша новорожденного являются нескоординированными, резкими и совершенно хаотичными. В процессе роста и развития ребенок научится «пользоваться» своими руками и ногами и управлять телом. Однако требовать от него это как можно раньше не следует.

Примерно в 1 месяц ребенок может удерживать свою голову. Уже к 3 месяцам он может центрировать голову и даже опираться на локотки, лежа на животе. 

Это важные этапы развития, на которые постоянно обращают внимание педиатры и неврологи, поэтому родители очень переживают, если ребенок как-то не так держит голову или не тогда держит голову. Задача родителей, для того чтобы научить это делать ребенка, – это выкладывать его на живот. Выкладывать малыша на живот можно уже сразу, как только отвалился пупочный остаток и зажила пупочная ранка.

Сколько можно находиться на животе – зависит от вашего ребенка. Столько, сколько малыш может, и максимально часто, насколько это возможно. Старайтесь перед каждым кормлением выкладывать малыша на живот, даже если ему это не нравится. Подбирайте более жесткое место. Например, диван совершенно не подойдет для этой роли, потому что ребенок будет постоянно куда-то скатываться, и мягкая поверхность не способствует тому, чтобы ребенок старался поднять голову вверх.

Общие движения малыша становятся более мягкими и скоординированными уже ближе к 3 месяцам. В это время малыш уже учится держать в руках предметы и даже манипулировать ими. При этом иногда получается, что он может себя ударить. Страшного в этом ничего нет, травму получить при этом нельзя. Однако когда у малыша посторонние предметы в руках, с ним все время должен быть взрослый и следить за тем, что происходит.

Примерно в возрасте от 3 до 6 месяцев малыши учатся переворачиваться с живота на спину и со спины на живот. Именно поэтому тут я хочу напомнить о травмоопасности этих движений и по возможности попросить вас использовать безопасные места, чтобы оставлять ребенка.

Уже с 3 месяцев дети желательно ведут так называемый половой образ жизни, то есть живут на полу. Для них используются мягкие игровые коврики или резиновые игровые коврики. Задача – сделать это пространство максимально безопасным, чтобы ребенок как можно больше находился на животе, развивая свои моторные навыки, учась передвигаться в пространстве.

Уже с 6 месяцев некоторые детки делают первые попытки ползать. Следите за безопасностью малыша, потому что каждый день новый навык может принести риск получить травму.

Обсудим также окна возможностей у детей. Можно узнать интервалы нормального появления определенных навыков – таких, как сидеть, стоять и ходить.

${tb5c4_8}

Обратите внимание: не нужно сажать детей в подушки, чтобы ускорить процесс их развития.

На самом деле, такими методами вы только вредите детям, можете нарушить осевую нагрузку на позвоночник, искривить их тазовые кости. Если вы хотите помочь ребенку как можно быстрее сесть, ваша задача – стараться развивать его мышцы. Например, делать массаж и регулярное ЛФК, а также помогать ребенку, когда он хочет присесть. 

Чаще всего это желание появляется у детей уже в 3-4 месяца, они стараются, тянут голову вперед, и видно, что очень хочется им сесть. Задача взрослого – помогать ему за ручки немного приподниматься, но не сажать самостоятельно. Важно не тянуть его за две ручки сразу, а только лишь за одну, чтобы вторая по возможности была его опорой на полу или другой жесткой поверхности. 

Сесть ребенку из положения лежа физиологически невозможно самостоятельно без помощи взрослого. Физиологически правильно садиться из положения «на четвереньках». Ребенок укладывает свой таз на бочок, при этом руки являются опорой (и таз тоже является опорой), и одну руку при этом ребенок может использовать для игры, например. Это называется сидеть с опорой. 

Ставить ли ребенка на ножки? Ответ будет зависеть от того, насколько ребенок готов это сделать самостоятельно. Если ребенок научился становиться на ножки сам в кроватке или в манеже, то остановить этот процесс уже невозможно. Даже если это произошло в 5 месяцев (ребенок подтянулся на ручках и стал у опоры самостоятельно) – это не страшно. А вот ставить на ножки, если ребенок сам к этому не готов, – нежелательное действие. Поэтому просто наберитесь терпения и стимулируйте ребенка для того, чтобы он начал становиться на ножки. Это все произойдет вовремя, в свое время, индивидуально для каждого ребенка. Старайтесь не сравнивать своих детей с другими детьми, потому что это неправильно и приводит только к стрессу родителей.

${tb5c4_9}

Урок 5. Развитие мелкой моторики

Развитие мелкой моторики имеет под собой целью тонкую манипуляцию пальчиков ребенка и возможность развития речи. Поэтому, уже начиная с возраста 1 месяц, мы начинаем общение ребенка с его собственным телом и обучение его манипуляциям с игрушками. 

Каждый новорожденный малыш имеет гипертонус в кистях рук. Это значит, что пальчики его сомкнуты. Задача родителей – постоянно аккуратненько их открывать, массировать ладошку и каждый пальчик отдельно. 

Начиная уже с месяца, вы можете вкладывать в ладошку ребенка игрушку, и он даже будет ее удерживать. При этом иногда он, конечно, может нанести себе некоторую травму, будьте осторожны и следите за малышом.

С 3 месяцев ребенок, лежа на животе, уже может потихонечку начинать двигаться в сторону игрушки, оценивая расстояние между игрушкой и своей рукой для того, чтобы дотянуться. Это первая возможность развить способность малыша к ползанию, поэтому, уже начиная с 3 месяцев, старайтесь класть ребенка на пол и вокруг раскладывать погремушки и игрушки, которые будут увлекать его зрение, иногда привлекать звуком его внимание и стимулировать ползание.

Примерно к 6 месяцам малыш научится перекладывать из руки в руку погремушку, при этом как бы пользуясь уже не целой ладонью, а используя только пальчики. Это первые попытки научиться пользоваться пинцетным захватом. 

В этот момент очень важно для родителей использовать небольшое количество игрушек и отмечать, имеется у ребенка какая-то привилегия к какой-то определенной игрушке. Нравится ему одна или другая игрушка, отличает ли он цвета. Будьте внимательны при этом и замечайте все особенности. Если вас что-то будет смущать, обязательно расскажите об этом своему педиатру.

Большинство детей в 9 месяцев уже уверенно сидят и манипулируют игрушками, могут определить расстояние до игрушки и даже доползти до нее при необходимости, если она заинтересовала его внимание. А еще они учатся интересному трюку, когда начинают бросать игрушку и следить за тем, куда же она упадет, и командовать мамой, чтобы она вернула эту игрушку ему на место. Это первые звоночки социализации ребенка и его начала общения с мамой или другими взрослыми. 

Итак, примерно в 8-9 месяцев появляется у детей интересный навык – пинцетный захват. Что это такое? Многие родители отмечают: «Моему ребенку 8 месяцев, а он ползает по ковру, собирает все нитки, все волосы, маленькие крошечки и обязательно отправляет себе в рот. Это нормально или нет?». Это просто замечательно! Это нормальный этап развития, который должен быть у каждого ребенка. 

Без пинцетного захвата ребенок в будущем не будет самостоятельно кушать, отправлять себе в рот ложку, вилку. То есть все самостоятельные будущие телодвижения с руками и пальцами начинаются именно с пинцетного захвата. Им же он будет пользоваться в будущем, когда будет собирать пазл, или строить пирамидку, или пользоваться конструктором. 

Поэтому родителям нужно быть еще более аккуратными и мыть полы как можно чаще. Потому что все маленькие сориночки, замеченные зрительным анализатором вашего ребенка, будут замечены и обязательно помещены в рот.

Как выбрать игрушки?

Игрушек у ребенка не должно быть много. Погремушки разных цветов, форм и размеров, которые отвлекают и развеивают внимание ребенка, – не совсем правильная идея. Максимум, который может быть у ребенка старше 6 месяцев в поле зрения, – это 2-3 игрушки. Из них он чаще всего выбирает одну любимую. 

Если же вокруг ребенка будет 10 игрушек, то он не сможет выбрать одну из-за особенностей своего психического развития на данном этапе. Старайтесь не покупать игрушки, которые не соответствуют возрасту ребенка. Не нужно крошке в 6-7 месяцев покупать машинку или куклу. Он не будет знать, что делать с этой игрушкой. Ребенок потеряет к ней интерес и не будет акцентировать на ней внимание в будущем.

Погремушки развивают не только зрительный и слуховой анализатор, но и мелкую и крупную моторику, учат ребенка стимулировать ползания и сидения. 

Но все равно каждый взрослый должен помнить о том, что самая лучшая игрушка для малыша – это его родители. Разговаривайте с малышом, читайте стихи или сказки, пойте песни, при этом постоянно общаясь и вкладывая в ребенка свою любовь и время.

Примерно в 9 месяцев у малыша появляется больший интерес к игрушкам. Родители начинают в этом возрасте покупать различные сортеры или специальные игрушки, которые можно открывать и закрывать, пользуясь пальчиками. Это стимулирует мелкую моторику. И это прекрасно, но постарайтесь, чтобы ваш ребенок не постоянно видел эти игрушки, а они появлялись и пропадали из его поля зрения, чтобы сохранять постоянно к ним интерес. Новые предметы всегда интересуют детей намного больше, чем привычные.

До года ребенок не способен научиться складывать пирамидку или ставить друг на друга кубики самостоятельно – он может только пародировать взрослому, при этом используя навыки имитации, движения человека, которому он доверяет. Поэтому мама и папа учат ребенка манипулировать своим телом при помощи игры, постоянно находясь рядом с ребенком. 

Нужны ли ребенку гаджеты?

Взрослые очень много проводят времени, находясь с гаджетами, все меньше внимания обращая на ребенка. Ребенок нередко предоставлен сам себе. Иногда ребенку предлагают замену взрослого – например, мультиками, телевизором. Этого делать не нужно. 

Потому что, во-первых, ребенок практически не слышит те звуки, которые исходят из телевизора. А если слышит, то они не так стимулируют его слуховой анализатор, как настоящий голос родного человека, который находится рядом. Картинки в телевизоре чаще всего очень яркие и действительно привлекают внимание ребенка, но это не является хорошим методом развития зрительного анализатора ребенка.

Во-первых, потому что часто сменяющиеся картинки сильно перевозбуждают нервную систему ребенка. Во-вторых, потому что в телевизоре плоское изображение, и ребенок не ощущает объема. Это может нарушать его способность научиться анализировать расстояние между предметами. То есть дети, которые смотрят телевизор вместо того, чтобы видеть настоящую природу, не всегда умеют правильно реагировать на расстояние и оценивать его адекватно.

Единственное, что может быть полезным, это музыка из гаджетов. Конечно, речь идет не о постоянной фоновой музыке. Это может перевозбуждать детей, нарушать сон и даже аппетит. Старайтесь не перевозбуждать деток, не давать им много игрушек сразу, не нагружать их зрительный анализатор большим количеством картинок и ярких изображений, старайтесь не перезагружать его слуховой анализатор постоянным фоновым шумом. Игрушек должно быть немного, они должны быть качественные, безопасные и по возрасту.

${separator}

Урок 6. Речь и социализация ребенка

Новорожденный малыш совершенно не понимает то, что вы ему говорите. Ему нужно научиться это делать. Он узнает голос мамы, может на него каким-то образом реагировать, но самые первые эмоции ребенка и его возможности социального общения  – это крик.

Крик может быть эмоционально окрашенным. Многие мамы рассказывают, что они отличают крик малыша. Они понимают, что он хочет кушать, или просит поменять подгузник, или ему что-то болит.

Будьте внимательны к эмоциям своего малыша, они действительно могут проявлять их самого рождения, даже при помощи крика. Он может быть очень разный, и наша с вами задача – понять, что необходимо ребенку. 

Понимание речи ребенка начинается с того, что малыш начинает понимать только эмоциональную окраску. Если взрослый говорит и злится – ребенок будет реагировать какими-то негативными эмоциями, например, плакать или хмуриться. Если же с ребенком разговаривать доброжелательно, он будет мимикрировать, аналогично показывать такие же эмоции и улыбаться в ответ. Это уже можно заметить у большинства детей в 2-3 месяца. Они воспринимают эмоциональную окраску человека, видят эмоции на его лице и это все отражают на собственном лице.

В 3 месяца у малышей появляется так называемый комплекс оживления. Если ребенок видит что-то знакомое (например, лицо взрослого, которого он раньше видел, или какую-то комнату, с которой у него есть положительные воспоминания), он начинает всем телом показывать что помнит это и двигать ногами-руками в разные стороны.

В это время можно начать уже вводить в жизнь ребенка ритуалы. Что такое ритуалы? Например, когда вы укладываете малыша спать, вы повторяете определенные действия каждый день. Для начала вы зашториваете окна, включаете тихую музыку или, например, читаете сказку. Ребенок к этому привыкает, ему это все очень нравится, он начинает понимать, что с ним происходит. И с каждым разом при использовании ритуалов малыш будет быстрее и быстрее засыпать.

Это первый этап социализации малыша. Вообще развитие речи – это очень интересный момент, потому что первым делом ребенок учится понимать и стараться имитировать звуки, которые произносит взрослый. В 2 месяца он может петь певучими гласными (такими, как «а», «у», «и»), уже в 4-5 месяцев они умеют гулить и говорить «бабаба», «мамама». Но при этом нельзя сказать, что это первые слова, потому что они не относятся к какому-то определенному предмету.

Чаще всего уже четкая корреляция между предметом и звуком, который произносит ребенок, появляется приблизительно в 8-9 месяцев. Ребенок, увидев какой-либо предмет, будет произносить определенный звук. Например, едет машина – ребенок говорит «биби». Но он сам этого не придумает – ему нужно подсказать. Когда едет машина, мама говорит «биби». И так нужно сделать примерно 100 раз, чтобы ребенок потом повторил.

Делаем вывод: чтобы ребенку развить свою речь, ему нужно слышать речь взрослого. 

Следующим этапом развития речи будет являться понимание обращенной речи. Начиная с 3-4 месяцев, вы можете рассказывать малышу обо всем, что находится вокруг. Например: «Это двери, это шкаф, вот этот кухня, а сейчас мы с тобой ляжем на ковер, а вот это наша собака, а это кошка». И примерно в 8-9 месяцев, когда вы спросите, где шкаф, ребенок указательным жестом укажет вам на него. Или будет отличать взрослых, показывая, где бабушка, где дедушка, где мама и папа. Естественно, этого происходить не будет, если родители будут забывать ребенка обучать этому. 

Развитие малыша будет зависеть от того, как часто и с каким интересом мама и папа занимаются с ребенком, учат его определенным навыкам. И чтобы развивать речь ребенка, с ребенком нужно общаться постоянно, денно и нощно, не лениться говорить, называть предметы. 

Конечным итогом социально-эмоционального развития малыша мы хотим получить общение со сверстниками и со взрослыми на правильном социально допустимом уровне и самообслуживание ребенка при помощи речи. Хотя ребенок чаще всего при помощи манипуляции взрослыми может научиться и без речи добиваться того, что он хочет. 

Рекомендуем учиться общаться и понимать ребенка, но не показывать ему то, что вы понимаете его и без обращенной речи. Например, ребенок вам указательным жестом показывает, что он хочет на кухню. Но важно, чтобы он все-таки сказал: «Пошли на кухню» или что-то вроде этой фразы. То есть не просто показывал, а выражал все при помощи речи. Вы делаете вид, что вы не понимаете, и ребенок начинает вас тащить. А мама может в это время повторять: «Мы с тобой идем на кухню». Затем малыш сможет сам легко вам объяснить словами, что ему нужно.

В 9-10 месяцев для оценки социализации можно использовать такой тест. Мама говорит ребенку: «Пошли гулять». Если ребенок знает и понимает маму, то он берет свои ботинки, начинает потихонечку уже самостоятельно даже одеваться. Самообслуживание может появиться уже в 9 месяцев. Ближе к годику большинство детей уже прекрасно умеют показать, что им хочется от взрослого. 

Симптомы опасности

Ребенок не смотрит в глаза взрослому или кажется, что он не узнает взрослого. 
Ребенок не хочет выполнять простые требования взрослого
В семье заметить эти проблемы намного легче. Ведь врач-педиатр общается с ребенком значительно меньшее количество времени по сравнению с мамой и папой. И если педиатр скажет ребенку: «Дай мне мячик» или «Покажи, как ты играешь в мячик», малыш может его не послушаться, потому что он не знает этого взрослого. 

Мама и папа в данном вопросе имеют главенствующую роль и рассказать доктору, что дома малыш играет и все хорошо.

Когда вы приводите малыша к педиатру в 1 год, чаще всего малыш пугается нового места, не понимает, что с ним происходит. И родителям важно рассказать педиатру, чему же он научился делать за этот год дома.  Расскажите, умеет ли он отличать предметы, показывать их, понимает ли обращенную речь, слышит ли он обращенную речь.

${tb5c4_10}

Урок 7. Питание и развитие желудочно-кишечного тракта малыша

Важность питания на первом году жизни малыша нельзя недооценить. Благодаря полноценному питанию малыш растет. И крайне важно на первом году жизни постараться кормить ребенка грудью. 

Переоценить грудное молоко, его состав и его функции для развития ребенка просто невозможно, потому что ничто не сравнится с качеством грудного молока от мамы. Поэтому рекомендации Всемирной организации здравоохранения говорят о том, что, помимо грудного молока, примерно до 6 месяцев детям больше ничего не требуется. Его не надо допаивать, не надо раньше времени вводить прикорм, стараться дать какую-то новую еду.

И уже в 6 месяцев для дальнейшего развития ребенка требуются дополнительные источники питания – прикорм.

С чего же начать прикорм, что дать для того, чтобы ребенок правильно развивался, рос? Рекомендации ВОЗ звучат примерно так: начинаем давать прикорм ребенку не ранее 6 месяцев, а что же выбрать первым продуктом прикорма – зависит от желания родителей. 

Не так уж важно: выберете вы для этого овощи, кашу, фрукты или даже мясо. Самое главное, чтобы вы начинали давать прикорм не по календарю, а вследствие того, что ребенок физически готов принимать пищу. 

Малыш уже умеет вертикально держать тело в положении сидя, чтобы принимать более густую пищу.
Он проявляет интерес к еде, которую видит на тарелке у мамы. Это называется пищевой интерес. Его на данном этапе родителям рекомендуется развивать у детей. 
При этом ВОЗ делает акцент на том, что все равно основой еды младенца является грудное молоко. А прикорм – это дополнительное питание, чтобы оптимально и рационально продолжить рост и развитие ребенка.

Еда играет очень важную роль в жизни детей. Очень часто родители спрашивают, что же делать с животиком маленького ребенка, который нередко беспокоит. Малыш рождается со стерильным кишечником, и в процессе его роста и развития кишечник заполняется определенными бактериями, которые называются микробиом. Оценить этот микробиом довольно сложно, потому что там множество бактерий. И в процессе роста, развития ребенка они постоянно заселяются все новые и новые, формируя уникальную жизнь внутри кишечника ребенка.

Чтобы правильно развивать кишечник и чтобы он правильно работал, необходимо хорошо кормить ребенка. Самое главное – это грудное молоко. Если вы по каким-либо причинам не кормите грудью, то не переживайте: современные адаптированные смеси хотя бы частично удовлетворяют потребности детей и не нарушают их рост и развитие.

Прикорм – это особый этап в жизни ребенка, и длится этот этап примерно 4-6 месяцев. Начиная с 6 месяцев (по рекомендации ВОЗ), медленно, но верно мы наращиваем количество продуктов, которые пробует ребенок. При этом не заменяя грудное молоко, а дополняя его при помощи этих продуктов.

Ближе к годику малыш переходит на общий стол. Вы можете кормить ребенка продуктами, которые употребляет вся семья с общего стола. 

Витамин D 

Об этом витамине сегодня ведется много разговоров. Он играет роль в развитии костной системы, в росте и развитии ребенка, в развитии иммунной системы. Он влияет полностью на весь организм.

Витамин D, безусловно, необходим для ребенка. Всемирная организация здравоохранения не дает четких рекомендаций о том, сколько и в каких количествах необходимо давать малышу этот витамин. К тому же каждая страна имеет свои рекомендации. 

Белорусские рекомендации говорят о том, что ребенку для профилактики дефицита витамина D достаточно одной капли в сутки, начиная с самого рождения и вплоть до 18 лет. Именно эта дозировка является самой безопасной для того, чтобы не навредить малышу. Но если вы не проводили профилактику дефицита витамина D в какое-то определенное время и есть основание думать, что у ребенка уже есть дефицит витамина D, то лечить его нужно при помощи лечебных доз, которые назначаются на основании лабораторных исследований. 

Комплексная оценка состояния здоровья ребенка, его нервно-психического здоровья и физического развития проводится при помощи врачей-педиатров, неврологов, логопедов, ортопедов и окулистов. Поэтому в 1 год рекомендуется прийти с малышом на осмотр к врачам.
    `,
};
