import { CrossLock, Lock } from 'assets/icons';
import PrimaryButton from 'components/Buttons/PrimaryButton/PrimaryButton';
import { Title } from 'components/Text/Title/Title';
import { useTranslate } from 'hooks/useTranslate';
import { AppRoutes } from 'navigation/constants';
import { useNavigate } from 'react-router-dom';
import styles from './LockedContent.module.css';

export function LockedContent({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const t = useTranslate();
  const navigate = useNavigate();

  const handleClicked = () => {
    navigate(AppRoutes.LOGIN);
  };

  if (!open) {
    return <></>;
  }

  return (
    <div className={styles.overlay}>
      <div className={styles.lockedContent}>
        <Title
          text={t('login_and_get_access')}
          highlight={t('login_and_get_highlight')}
        />
        <div className={styles.crossLock} onClick={onClose}>
          <CrossLock />
        </div>
        <div className={styles.lockWrapper}>
          <div className={styles.buttonsContainer}>
            <div className={styles.primaryButton}>
              <PrimaryButton
                title={t('locked_login')}
                onClick={handleClicked}
              />
            </div>
            <div className={styles.lock}>
              <Lock />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
