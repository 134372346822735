import { FC } from "react";
import styles from './Carousel.module.css';
import { TCarouselItem } from "./constants";


export const CarouselItem: FC<{
  data: TCarouselItem
}> = ({
  data,
}) => {
  return (
    <div className={ styles.item }>
      <img 
        src={ data.image }
        className={ styles.image }
        alt={ data.displayName }
      />
      <div className={ styles.displayName }>
        { data.displayName }
      </div>
      <div className={ styles.position }>
        { data.position }
      </div>
    </div>
  )
};
