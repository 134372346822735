import React from 'react';
import { UntypedObject } from 'types/baseTypes';
import { getImageSize, THEME_STYLES, handleBtnAction } from 'utils/experts';
import { optional } from 'utils/other';
import { byOrder } from 'utils/sorting';
//@ts-ignore
const removeObsoleteFontFamily = (item) => {
  // const stylesData = { ...(item?.styles || {}) };
  // if (stylesData.itemStyles?.fontFamily) {
  //   stylesData.itemStyles.fontFamily = stylesData.itemStyles.fontFamily
  //     .replace("nunito", "Raleway")
  //     .replace("Nunito", "Raleway");
  // }
  return item;
};

const RichTextDescription = (data: {
  description: UntypedObject | undefined;
}) => {
  //@ts-ignore
  const { description } = optional(data);
  if (!description) return <></>;
  return (
    <div>
      {Object.keys(description)
        .map((key) => ({ ...description[key] }))
        .sort(byOrder)
        .map((item, index) => {
          const stylesData = removeObsoleteFontFamily(item);
          let itemStyles = stylesData.itemStyles || {};
          const subItems = item.data;

          if (item.type === 'view') {
            return (
              <div key={String(index)} style={itemStyles}>
                {subItems &&
                  Object.keys(subItems)
                    .map((key) => ({ ...subItems[key] }))
                    .sort(byOrder)
                    .map((subItem, index) => {
                      const stylesData = removeObsoleteFontFamily(subItem);
                      let subItemStyles = stylesData.itemStyles || {};
                      if (subItem.type === 'text' && subItem.data) {
                        return (
                          <p key={String(index)} style={subItemStyles}>
                            {Object.keys(subItem.data)
                              .map((key) => ({ ...subItem.data[key] }))
                              .sort(byOrder)
                              .map((textItem, index) => {
                                const stylesData =
                                  removeObsoleteFontFamily(textItem);
                                let textItemStyles =
                                  stylesData.itemStyles || {};
                                const textSubItem = textItem.data;

                                return (
                                  <span
                                    key={String(index)}
                                    style={textItemStyles}
                                  >
                                    {String(textSubItem)}
                                  </span>
                                );
                              })}
                          </p>
                        );
                      } else if (subItem.type === 'image' && subItem.data) {
                        if (
                          subItem.isFullscreen &&
                          subItem.wRatio &&
                          subItem.hRatio
                        ) {
                          const wWidth = window.innerWidth;
                          const imgSize = getImageSize(
                            wWidth,
                            subItem.wRatio * 1,
                            subItem.hRatio * 1
                          );
                          subItemStyles = {
                            ...subItemStyles,
                            maxWidth: '100%',
                            width: imgSize.width,
                          };
                        }

                        return (
                          <img
                            key={String(index)}
                            style={subItemStyles}
                            src={subItem.data}
                            alt=""
                          />
                        );
                      } else if (subItem.type === 'button' && subItem.data) {
                        if (subItem.data.type === 'primary') {
                          return (
                            <button
                              key={String('btn_primary' + index)}
                              //@ts-ignore
                              styles={THEME_STYLES.BUTTON_STYLES.BTN_PRIMARY}
                              onClick={() =>
                                //@ts-ignore
                                handleBtnAction(navigation, subItem.data.goTo)
                              }
                            >
                              <span
                                //@ts-ignore
                                styles={
                                  THEME_STYLES.BUTTON_STYLES.BTN_TITLE_PRIMARY
                                }
                              >
                                {subItem.data.title}
                              </span>
                            </button>
                          );
                        } else if (subItem.data.type === 'secondary') {
                          return (
                            <button
                              key={String('btn_secondary' + index)}
                              //@ts-ignore
                              styles={THEME_STYLES.BUTTON_STYLES.BTN_SECONDARY}
                              onClick={() =>
                                //@ts-ignore
                                handleBtnAction(navigation, subItem.data.goTo)
                              }
                            >
                              <span
                                //@ts-ignore
                                styles={
                                  THEME_STYLES.BUTTON_STYLES.BTN_TITLE_SECONDARY
                                }
                              >
                                {subItem.data.title}
                              </span>
                            </button>
                          );
                        }
                      }
                      return null;
                    })}
              </div>
            );
          }
          return null;
        })}
    </div>
  );
};

export default RichTextDescription;
