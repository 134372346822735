import { FC } from 'react';
import styles from './ProcessSection.module.css';
import { useTranslate } from 'hooks/useTranslate';
import {
  AdvicePhoneIcon,
  CheckIcon,
  InterestsPhoneIcon,
  StatusPhoneIcon,
} from 'assets/icons';
import { TitleHomeH3 } from 'features/Home/Title/TitleHomeH3/TitleHomeH3';
import { USAGE_OPTIONS } from 'constants/constants';

export const ProcessSection: FC = () => {
  const t = useTranslate();

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <article className={styles.article}>
          <img
            src={AdvicePhoneIcon}
            className={styles.iconAdvice}
            alt="advice phone"
          />
          <div className={styles.wapper}>
            <TitleHomeH3
              text={t('process_advice_title')}
              highlight={t('process_advice_title_highlight')}
            />
            <div className={styles.descriptionContainer}>
              {t('process_advice_description')}
            </div>
          </div>
        </article>
        <article className={styles.article}>
          <div className={styles.wapper}>
            <TitleHomeH3
              text={t('process_interests_title')}
              highlight={t('process_interests_title_highlight')}
            />
            <div className={styles.descriptionContainer}>
              {t('process_interests_description')}
            </div>
          </div>
          <img
            src={InterestsPhoneIcon}
            className={styles.iconInterests}
            alt="interests phone"
          />
        </article>
        <article className={styles.article}>
          <img
            src={StatusPhoneIcon}
            className={styles.iconStatus}
            alt="status phone"
          />
          <div className={styles.wapper}>
            <TitleHomeH3
              text={t('process_status_title')}
              highlight={t('process_status_title_highlight')}
            />
            <div className={styles.descriptionContainer}>
              {t('process_status_description')}
            </div>
            <div>
              <div className={styles.blockStatuses}>
                {USAGE_OPTIONS.map(({ title, icon: Icon }) => (
                  <div className={styles.containerStatus} key={title}>
                    <CheckIcon className={styles.checkIcon} />
                    <div key={title} className={styles.wrapperStatus}>
                      <div className={styles.containerStatusIcon}>
                        <Icon className={styles.statusIcon} />
                      </div>
                      <div>{t(`${title}`)}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </article>
      </div>
    </section>
  );
};
