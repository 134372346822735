import { FC, useCallback } from "react";
import { ListMenuButton } from "features/Profile/components/ListMenuButton/ListMenuButton";
import { SupportProfileIcon } from "assets/icons";
import { useTranslate } from "hooks/useTranslate";


export const MenuItemSupport: FC = () => {
  const t = useTranslate();

  const openTelegtam = useCallback(
    () => {
      window.open('https://telegram.me/mamaproAdmin', '_blank');
    },
    []
  );

  return (
    <ListMenuButton
      iconLeft={ SupportProfileIcon }
      title={ t('profile_menu_support') }
      onPress={ openTelegtam }
    />
  )
};
