import Introduction from './components/Introduction/Introduction';
import { MzrFirstButtons } from './components/MzrFirstButtons/MzrFirstButtons';
import { MzrLogoBlock } from './components/MzrLogoBlock/MzrLogoBlock';
import StepByStepBanner from './components/StepByStepBanner/StepByStepBanner';
import './WaitingBabyPage.style.css';
import { useTranslate } from 'hooks/useTranslate';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import { checkIsWebView } from 'utils/other';
import { useUser } from 'hooks/useUser';
import { HelmetComponent } from 'components/HelmetComponent/HelmetComponent';
import SwipeableModal from 'features/SwipeableModal/SwipeableModal';
import { useAppWindowSize } from 'hooks/useAppWindowSize';

const WaitingBabyPageFM = () => {
  const t = useTranslate();
  const { user } = useUser();
  const isWebView = checkIsWebView();
  const { isMobile } = useAppWindowSize();
  return (
    <div style={{ marginTop: 0, backgroundColor: '#F3F3F5' }}>
      <HelmetComponent
        title={'Регистрация в программе подготовки к родам «Мы Ждём Ребёнка»'}
        description={
          'Видеокурсы от врачей о беременности, родах, послеродовом периоде и здоровье ребёнка'
        }
      />
      <main
        className="mzr_first"
        style={{ marginTop: 0, backgroundColor: '#F3F3F5' }}
      >
        <article>
          <section className="mzr_first-container ">
            <Introduction />
            <StepByStepBanner />
            <div className="h_one_desc">{t('congrats')}</div>
            <div>
              <MzrLogoBlock />
            </div>

            {!!user?.id && (
              <div className="h_one_desc_s">{t('signInPromptFM')}</div>
            )}
          </section>
        </article>
      </main>
    </div>
  );
};

export default WaitingBabyPageFM;
