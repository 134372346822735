export enum AppRoutes {
  HOME = '/',

  LOGIN = '/login',
  SIGN_UP = '/singup',
  SIGN_UP_SUCCESS = '/signup_success',
  FORGOT_PASSWORD = '/forgot_password',

  STATUS_SCREEN = '/status',
  PERIOD_SCREEN = '/period',
  INTERESTS_SCREEN = '/interests',
  PROCESSING_SCREEN = '/processing',
  TEST_PASSED_SCREEN = '/test-passed',
  STUDY_SCREEN = '/edu',
  PARTNERS_SCREEN = '/partners',
  ABOUT_SCREEN = '/about',
  PROFILE_SCREEN = '/profile',
  SETTINGS_SCREEN = '/settings',
  EXPERTS_SCREEN = '/experts',
  PRIVACY_SCREEN = '/privacy',
  FAVORITE_PAGE = '/favorite',
  TERMS_SCREEN = '/terms',
  MAIN = '/main',
}
