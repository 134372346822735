import { useAppDispatch } from "store/store";
import { updateIsLoggedIn } from "../store/AuthSlice";
import { updateUid } from "features/Auth/store/AuthSlice";
import "firebase/auth";

export const useSilentLogin = () => {
  const dispatch = useAppDispatch();
  const processAuthState = async (uid: string) => {
    if (uid) {
      dispatch(updateIsLoggedIn(true));
      dispatch(updateUid(uid));
    }
  };

  return { processAuthState };
};
