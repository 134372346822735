import { FC, useCallback } from "react";
import styles from './PersonalInfo.module.css';
import { User } from "features/User/type";
import { Avatar } from "components/Avatar/Avatar";
import { IconButton } from "../IconButton/IconButton";
import { EditIcon } from "assets/icons";
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "navigation/constants";

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)


export const PersonalInfo: FC<{
  user: User;
}> = ({
  user,
}) => {
  const navigate = useNavigate();

  const { photoURL, firstName, lastName, email } = user;
  const name = `${!!firstName ? firstName : ''} ${!!lastName ? lastName : ''} `;

  const navigateToSettings = useCallback(
    () => {
      navigate(AppRoutes.SETTINGS_SCREEN);
    },
    [navigate]
  );

  return (
    <div className={ styles.container }>
      <Avatar url={ photoURL } big/>
      <div className={ styles.wrapper }>
        <div className={ styles.nameContainer }>
          <ResponsiveEllipsis
            text={ name }
            maxLine='2'
            basedOn='letters'
            className={ styles.name }
          />
        </div>
        <div className={ styles.emailContainer }>
          <ResponsiveEllipsis
            text={ email ? email : '' }
            maxLine='1'
            basedOn='letters'
            className={ styles.email }
          />
        </div>
      </div>
      <IconButton icon={ EditIcon } cb={ navigateToSettings }/>
    </div>
  )
};
