import { combineReducers } from '@reduxjs/toolkit';
import authReducer from 'features/Auth/store/AuthSlice';
import onboardingReducer from 'features/Auth/Onboarding/store/OnboardingSlice';
import userReducer from 'features/User/store/UserSlice';
import categoriesReducer from 'pages/StudyPage/store/CategoriesSlice';
import coursesReducer from 'pages/CoursesCategory/store/CourseSlice';
import expertsReducer from 'pages/ExpertScreen/store/ExpertsSlice';
import shticksReducer from 'pages/MainPage/store/HomeSlice';
import overlayReducer from 'features/Settings/store/OverlaySlice';

const rootReducer = combineReducers({
  auth: authReducer,
  onboarding: onboardingReducer,
  categories: categoriesReducer,
  courses: coursesReducer,
  user: userReducer,
  overlay: overlayReducer,
  experts: expertsReducer,
  shticks: shticksReducer,
});

export default rootReducer;
