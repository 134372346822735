import { AppState } from "store/store";

export const selectOnboardingData = (state: AppState) => state.onboarding;

export const selectUsageOption = (state: AppState) => state.onboarding.usageOption;

export const selectDateOfPregnancy = (state: AppState) => state.onboarding.dateOfPregnancy;

export const selectDateOfChildBirth = (state: AppState) => state.onboarding.dateOfChildBirth;

export const selectInterests = (state: AppState) => state.onboarding.interests;
