import { CSSProperties, FC, memo } from 'react';
import styles from './ErrorMessage.module.css';

const ErrorMessage: FC<{
  message: string;
  container?: CSSProperties;
}> = memo(({ message, container }) => (
  <div style={container} className={styles.container}>
    <div className={styles.icon}></div>
    {!!message && message}
  </div>
));

export default ErrorMessage;
