import { Title } from 'components/Text/Title/Title';
import { useTranslate } from 'hooks/useTranslate';
import { studyContentConfig } from 'pages/StudyPage/StudyScreen.utils';
import styles from './StudyBlocks.module.css';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { useUser } from 'hooks/useUser';
import { useAppWindowSize } from 'hooks/useAppWindowSize';

export const StudyBlocks = () => {
  const t = useTranslate();
  const { isMobile } = useAppWindowSize();
  const size = isMobile ? 40 : 80;
  const { user, hasCertificate } = useUser();
  const filler = (
    <div
      className={styles.studyScreenBox}
      style={{
        height: 0,
        visibility: 'hidden',
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <div className={styles.titleContainer}>
        <p className={styles.title}></p>
      </div>
      <div className={styles.iconContainer}></div>
    </div>
  );

  const studyProgramCategories = useMemo(
    () =>
      studyContentConfig.filter((category) =>
        category.certificateNeeded ? hasCertificate : true
      ),
    [hasCertificate]
  );
  return (
    <div className={styles.studyContainer}>
      <Title text={t('screen_subtitle_study')} highlight="знаний" />
      <div className={styles.studyScreenBoxesContainer}>
        {studyProgramCategories.map((item, index) => {
          const IconComponent = item.icon;
          return (
            <Link
              className={styles.studyScreenBox}
              key={index}
              to={item.id === 'waiting' ? '/waiting-page' : `/edu/${item.id}`}
            >
              <div className={styles.titleContainer}>
                <h2 className={styles.title}>{t(item.title)}</h2>
              </div>
              <div className={styles.iconContainer}>
                {IconComponent && <IconComponent width={size} height={size} />}
              </div>
            </Link>
          );
        })}
        {filler}
        {filler}
        {filler}
      </div>
    </div>
  );
};
