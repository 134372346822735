import { NavLink, useLocation } from 'react-router-dom';
import { FC, memo } from 'react';
import styles from './Navbar.module.css';
import { AppRoutes } from 'navigation/constants';
import { useTranslate } from 'hooks/useTranslate';
import classNames from 'classnames';
import { useUser } from 'hooks/useUser';
import { useAppWindowSize } from 'hooks/useAppWindowSize';

const links = [
  { path: AppRoutes.MAIN, text: 'главная', needAuthorize: true },
  { path: AppRoutes.STUDY_SCREEN, text: 'nav_link_study' },
  { path: `${AppRoutes.HOME}#partners`, text: 'Партнёры', id: '#partners' },
  { path: `${AppRoutes.HOME}#about`, text: 'О нас', id: '#about' },

  // { path: AppRoutes.EXPERTS_SCREEN, text: 'nav_link_experts' },
  // { path: AppRoutes.PARTNERS_SCREEN, text: 'nav_link_partners' },
  // { path: AppRoutes.ABOUT_SCREEN, text: 'nav_link_about' },
  // { path: AppRoutes.PROFILE_SCREEN, text: 'nav_link_profile' },
];

const Navbar: FC<{
  isScrolled: boolean;
  close?: () => void;
}> = ({ isScrolled, close }) => {
  const t = useTranslate();
  const { user } = useUser();
  const { isTablet, isMobile } = useAppWindowSize();
  const location = useLocation();
  const currentHash = location.hash;
  return (
    <nav className={styles.container}>
      {links.map((link) => (
        <NavLink
          key={link.path}
          to={link.path}
          className={(props) =>
            classNames(
              styles.link,
              isScrolled && styles.scrolled,
              link.needAuthorize && !user?.id && styles.needAuthorize,

              props.isActive &&
                !isMobile &&
                !isTablet &&
                !link?.id &&
                styles.active,
              !!link?.id && currentHash === link?.id && styles.active
            )
          }
          onClick={close}
        >
          {!link.needAuthorize && <span>{t(link.text)}</span>}
          {link.needAuthorize && user?.id && <span>{t(link.text)}</span>}
        </NavLink>
      ))}
    </nav>
  );
};

export default memo(Navbar);
