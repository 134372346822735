import { FC, useCallback } from "react";
import styles from './IconButton.module.css';
import { isFunction, noop } from "lodash";

export const IconButton: FC<{
  icon: string;
  cb?: () => void;
}> = ({
  icon,
  cb = noop,
}) => {
  const handleClick = useCallback(
    () => {
      isFunction(cb) && cb();
    },
    [cb]
  );

  return (
    <button
      className={ styles.container }
      onClick={ handleClick }
    >
      <img src={ icon } className={ styles.icon }  alt={ 'icon' } />
    </button>
  );
};
