import { checkBooked, checkLikes, coursesConfig } from 'utils/courses';
import { CourseData, RecommendationType } from 'types';
import { useAppDispatch } from 'store/store';

import { useUser } from './useUser';
import { optional } from 'utils/other';
import { noop } from 'lodash';
import { useState } from 'react';
import {
  updateContentBooked,
  updateContentLikes,
} from 'pages/CoursesCategory/store/CourseSlice';

export const useContentUpdateApi = (
  type: RecommendationType,
  content: CourseData
) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const {
    updateBooked: updBooked,
    updateLikes: updLikes,
    key,
  } = coursesConfig[type];
  const { userId } = useUser();

  const isLike = checkLikes(userId, content);
  const isBooked = checkBooked(userId, content);
  //@ts-ignore
  const { id: contentId } = optional(content);

  const updateBooked = () => {
    if (!userId || !contentId || !content?.title) return;
    setLoading(true);
    updBooked(contentId, { [userId]: !isBooked })
      .then(() => {
        dispatch(
          updateContentBooked({
            id: contentId,
            userId,
            isBooked: !isBooked,
            key,
          })
        );
      })
      .catch(noop)
      .finally(() => setLoading(false));
  };

  const updateLikes = () => {
    if (!userId || !contentId || !content?.title) return;
    setLoading(true);
    updLikes(contentId, { [userId]: !isLike })
      .then(() => {
        dispatch(
          updateContentLikes({ id: contentId, userId, isLike: !isLike, key })
        );
      })
      .catch(noop)
      .finally(() => setLoading(false));
  };

  return {
    updateBooked,
    updateLikes,
    isLike,
    isBooked,
    loading,
  };
};
