import { watchExpertDetailsEvent } from 'analytic/trackEvents';
import { Instagram, TikTok } from 'assets/icons';
import ArrowNavButton from 'components/Buttons/ArrowNavButton/ArrowNavButton';
import PrimaryButton from 'components/Buttons/PrimaryButton/PrimaryButton';
import ChipWithIcon from 'components/ChipWithIcon/ChipWithIcon';
import { CommonCoursesCategory } from 'components/CommonCoursesCategory/CommonCoursesCategory';
import Header from 'components/Header/Header';
import RichTextDescription from 'components/RichTextDescription';
import { useAllCourses } from 'hooks/useAllCourses';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { useExpertsApi } from 'hooks/useExpertsApi';
import { useTranslate } from 'hooks/useTranslate';
import { selectExpertsById } from 'pages/ExpertScreen/store/selectors';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/store';
import { getExpertFullName, optional } from 'utils/other';
import styles from './ExpertPage.module.css';

export function ExpertPage() {
  const t = useTranslate();
  const navigate = useNavigate();

  const { expertId: extExpertId, contentId } = useParams();
  const { allCourses } = useAllCourses();
  useExpertsApi();
  const course = !extExpertId
    ? allCourses?.find((course) => course.id === contentId)
    : null;
  const experts = useAppSelector(
    selectExpertsById(Object.keys(optional(course?.expert)))
  );
  const expertId = extExpertId || experts[0]?.id;

  const expert =
    useAppSelector(selectExpertsById([expertId as string]))?.[0] || {};

  const courses =
    allCourses?.filter((e) =>
      Object.keys(e?.expert || {}).includes(expertId as string)
    ) || [];

  useEffect(() => {
    if (!!expertId) {
      watchExpertDetailsEvent(expertId);
    }
  }, [expertId]);

  const { isMobile } = useAppWindowSize();

  const targetNodeRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = () => {
    if (targetNodeRef.current) {
      targetNodeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  const handleInstaClick = (e: string | undefined) => {
    window.open(e, '_blank');
  };

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.background}>
      <Header styled />
      <div className={styles.container}>
        <ArrowNavButton title={`${t('back_button')}`} cb={navigateBack} />
        <div className={styles.wrapper}>
          <img
            className={styles.image}
            src={expert?.avatar ? expert?.avatar : expert?.image}
          />
          <div className={styles.expertInfoContainer}>
            <div className={styles.nameContainer}>
              <p className={styles.fullName}>{getExpertFullName(expert)}</p>
              <p className={styles.field}>{expert?.field}</p>
            </div>
            {expert?.social && (
              <div className={styles.containerIcons}>
                {expert?.social?.instagram !== 'Null' && (
                  <ChipWithIcon
                    icon={Instagram}
                    text={'INSTAGRAM'}
                    styleContainer={{
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 13,
                      paddingBottom: 13,
                    }}
                    handleButtonClick={() =>
                      handleInstaClick(expert?.social?.instagram)
                    }
                  />
                )}
                {expert?.social?.tiktok !== 'Null' && (
                  <ChipWithIcon
                    icon={TikTok}
                    text={'TIKTOK'}
                    handleButtonClick={() =>
                      handleInstaClick(expert?.social?.tiktok)
                    }
                  />
                )}
              </div>
            )}
            <PrimaryButton
              title={'Смотреть контент эксперта'}
              onClick={handleButtonClick}
              styleContainer={isMobile ? { width: '100%' } : {}}
            />
          </div>
        </div>
        {!!expert?.description != Boolean('Null') && (
          <div className={styles.expertDescriptionWrapper}>
            <div className={styles.expertDescriptionContainer}>
              <p className={styles.expertTitle}>
                {t('section_title_expertDescription_description')}
              </p>
              <RichTextDescription description={expert?.description || {}} />
            </div>
          </div>
        )}

        <h1 className={styles.contentTitle} ref={targetNodeRef}>
          {t('expert_content')}
        </h1>
        <CommonCoursesCategory
          content={courses}
          title={''}
          withoutButton
          isCourseList
          expertId={expertId}
        />
      </div>
    </div>
  );
}
