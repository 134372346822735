export const getHelmetTitle = (categoryId: string | undefined) => {
  if (categoryId === 'planning') {
    return 'Планирование беременности';
  }
  if (categoryId === 'pregnancy') {
    return 'Беременность';
  }
  if (categoryId === 'childbirth') {
    return 'Роды';
  }
  if (categoryId === 'postpartum-recovery') {
    return 'Восстановление после родов';
  }
  if (categoryId === 'child-health') {
    return 'Здоровье и уход за ребёнком';
  }
  if (categoryId === 'child-development') {
    return 'Развитие малыша';
  } else return '';
};

export const getHelmetDescription = (categoryId: string | undefined) => {
  if (categoryId === 'planning') {
    return 'Правильное планирование беременности. Состояние здоровья. Анализы. Скрытые заболевания. Репродуктивнуая система. Возможные противопоказания';
  }
  if (categoryId === 'pregnancy') {
    return 'Изменения в организме женщины. Диагностика состояния плода. Отеки, повышение АД, рвота и другие состояния у беременных. Собираем сумки в роддом';
  }
  if (categoryId === 'childbirth') {
    return 'Дыхание в родах. Безопасные роды. Анестезия. Кесаревао. Опасные состояния во время беременности. Разбор неидеальных сценариев.';
  }
  if (categoryId === 'postpartum-recovery') {
    return 'Эмоциональное выгорание молодых родителей. Методы контрацепции. О менструальном цикле. Осложнения после родов. Послеродовая депрессия.';
  }
  if (categoryId === 'breastfeeding') {
    return 'Грудное вскармливание Первое прикладывание малыша к груди и кормление в роддоме. Грудное вскармливание от А до Я: важные советы. Питание при грудном вскармливании. Как завершить ГВ?';
  }
  if (categoryId === 'child-health') {
    return 'Здоровье и уход за ребёнком Детская кожа. Правила гигиены малышей. Гармоничное развитие малыша. Экстренные ситуации: первая помощь ребенку.';
  }
  if (categoryId === 'child-development') {
    return 'Грудное вскармливание и детский сад совместимы? Что нужно уметь малышу, который идёт в детский сад? Адаптация к детскому саду: чего не стоит делать';
  } else return '';
};
