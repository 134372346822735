import { auth } from 'api/constants';
import { Modal } from 'components/Modal/Modal';
import {
  AuthErrorCode,
  ProviderRemoval,
} from 'features/Auth/constants/constants';
import { useAuth } from 'features/Auth/hooks/useAuth';
import { getDeleteAccountValidationSchema } from 'features/Auth/validations/AuthValidationSchems';
import { FormFields } from 'features/Auth/validations/constants';
import { useFormik } from 'formik';
import { useTranslate } from 'hooks/useTranslate';
import { useUser } from 'hooks/useUser';
import { useCallback, useState } from 'react';
import { ModalButton } from '../Buttons/ModalButton/ModalButton';
import { ContentModalApple } from '../ContentModals/ContentModalApple/ContentModalApple';
import { ContentModalEmail } from '../ContentModals/ContentModalEmail/ContentModalEmail';
import { ContentModalGoogle } from '../ContentModals/ContentModalGoogle/ContentModalGoogle';
import { ContentModalNotice } from '../ContentModals/ContentModalNotice/ContentModalNotice';
import styles from './ModalWrapper.module.css';
import { ModalState, actionDescription } from './ModalWrapper.utils';

type ModalWrapperProps = {
  isVisible: boolean;
  showModal: () => void;
  hideModal: () => void;
};
export const ModalWrapper = ({
  isVisible,
  showModal,
  hideModal,
}: ModalWrapperProps) => {
  const t = useTranslate();
  const { EMAIL, PASSWORD } = FormFields;
  const { user } = useUser();

  const { deleteAccount } = useAuth();

  const provider = auth.currentUser?.providerData[0].providerId;

  const [modalState, setModalState] = useState<ModalState>(ModalState.NOTICE);

  const formik = useFormik({
    initialValues: {
      [EMAIL]: '',
      [PASSWORD]: '',
    },
    validationSchema: getDeleteAccountValidationSchema(),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values, { setStatus }) => {
      const email = values[EMAIL]?.trim();
      const password = values[PASSWORD];

      deleteAccount({
        provider: ProviderRemoval.EMAIL_PASSWORD,
        email: email,
        password: password,
        setError: (code: AuthErrorCode) => setStatus({ error: code }),
        hideModal,
      });
    },
  });

  const handleCancel = useCallback(() => {
    hideModal();

    if (modalState === ModalState.ACTION) {
      formik.resetForm({});
      formik.setStatus({});
      formik.setErrors({});

      setTimeout(() => setModalState(ModalState.NOTICE), 1000);
    }
  }, [formik, hideModal, modalState]);

  const handleChangeFocus = (isFocused: boolean) => {
    if (isFocused) {
      formik.setStatus({});
      formik.setErrors({});
    }
  };

  const handleConfirm = useCallback(() => {
    formik.handleSubmit();
  }, [formik]);
  if (!provider) return <></>;
  const handleChangeAction = () => setModalState(ModalState.ACTION);

  const handleOpenModal = (value: any) => {
    formik.setFieldValue(EMAIL, value?.target.value);
  };

  const handleDeleteAccount = (provider: ProviderRemoval) => {
    deleteAccount({
      provider: provider,
      setError: (code: AuthErrorCode) => formik.setStatus({ error: code }),
      hideModal,
    });
    formik.setStatus({});
  };

  return (
    <Modal
      title={t('settings_delete_account_module_title')}
      description={t(
        `settings_delete_account_module_description_${modalState}`
      )}
      isOpen={isVisible}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    >
      {modalState === ModalState.NOTICE && (
        <ContentModalNotice
          handleChangeAction={handleChangeAction}
          handleModal={handleCancel}
        />
      )}
      {modalState === ModalState.ACTION && (
        <>
          {false ? (
            <p>loadinggg.....</p>
          ) : (
            <p className={styles.removalDescription}>
              {t(actionDescription[provider || ''])}
            </p>
          )}

          {provider === ProviderRemoval.EMAIL_PASSWORD && (
            <>
              <ContentModalEmail
                handleOpenModal={handleOpenModal}
                handleChangeFocus={handleChangeFocus}
                valueEmail={formik.values[EMAIL]}
                onChange={(value: any) =>
                  formik.setFieldValue(PASSWORD, value?.target.value)
                }
                isValid={formik.isValid}
                valuePassword={formik.values[PASSWORD]}
                error={formik.status?.error}
                hideModal={hideModal}
                onSubmit={() => {
                  formik.handleSubmit();
                }}
                password={PASSWORD}
              />
            </>
          )}
          {provider === ProviderRemoval.GOOGLE && (
            <ContentModalGoogle
              handleDeleteAccount={() =>
                handleDeleteAccount(ProviderRemoval.GOOGLE)
              }
              handleCancel={handleCancel}
              error={formik.status?.error}
            />
          )}
          {provider === ProviderRemoval.APPLE && (
            <ContentModalApple
              handleDeleteAccount={() =>
                handleDeleteAccount(ProviderRemoval.APPLE)
              }
              handleCancel={handleCancel}
              error={formik.status?.error}
            />
          )}
          {modalState !== ModalState.ACTION && (
            <ModalButton
              title={t('settings_delete_account_module_cancel_action')}
              flat
              onPress={hideModal}
            />
          )}
        </>
      )}
    </Modal>
  );
};
