import dayjs from 'dayjs';
import { isNumber, isString } from 'lodash';

import { CourseData, RecommendationType } from 'types';
import { optional } from './other';

export const daysInYear = (year: number): number =>
  (year % 4 === 0 && year % 100 > 0) || year % 400 === 0 ? 366 : 365;

export const getTimeAfter = (
  dateBefore: number | null | undefined
): {
  years: number;
  months: number;
  weeks?: number;
  days: number;
  daysAfter: number;
} => {
  const yearsFloat = dayjs().diff(dateBefore, 'year', true);
  const years = Math.trunc(yearsFloat);
  const daysAfter = years
    ? (yearsFloat - years) * daysInYear(dayjs().year())
    : dayjs().diff(dateBefore, 'day');
  const monthsFloat = daysAfter / 30.25;
  const months = Math.trunc(monthsFloat);
  return {
    years,
    months,
    days: Math.trunc((monthsFloat - months) * 30.25),
    daysAfter,
  };
};

const minutesConfigRU: Record<string, string> = {
  '0': 'минут',
  '1': 'минута',
  '2': 'минуты',
  '3': 'минуты',
  '4': 'минуты',
  '5': 'минут',
  '6': 'минут',
  '7': 'минут',
  '8': 'минут',
  '9': 'минут',
};

const weekConfigRU: Record<string, string> = {
  '0': 'недель',
  '1': 'неделя',
  '2': 'недели',
  '3': 'недели',
  '4': 'недели',
  '5': 'недель',
  '6': 'недель',
  '7': 'недель',
  '8': 'недель',
  '9': 'недель',
};

const daysConfigRU: Record<string, string> = {
  '0': 'дней',
  '1': 'день',
  '2': 'дня',
  '3': 'дня',
  '4': 'дня',
  '5': 'дней',
  '6': 'дней',
  '7': 'дней',
  '8': 'дней',
  '9': 'дней',
};

const monthConfigRU: Record<string, string> = {
  '0': 'месяцев',
  '1': 'месяц',
  '2': 'месяца',
  '3': 'месяца',
  '4': 'месяца',
  '5': 'месяцев',
  '6': 'месяцев',
  '7': 'месяцев',
  '8': 'месяцев',
  '9': 'месяцев',
  '10': 'месяцев',
  '11': 'месяцев',
  '12': 'месяцев',
};

const yearsConfigRU: Record<string, string> = {
  '0': 'лет',
  '1': 'год',
  '2': 'года',
  '3': 'года',
  '4': 'года',
  '5': 'лет',
  '6': 'лет',
  '7': 'лет',
  '8': 'лет',
  '9': 'лет',
};

const lessonsConfigRU: Record<string, string> = {
  '0': 'уроков',
  '1': 'урок',
  '2': 'урока',
  '3': 'урока',
  '4': 'урока',
  '5': 'уроков',
  '6': 'уроков',
  '7': 'уроков',
  '8': 'уроков',
  '9': 'уроков',
};

const getKeyToTimeCaptionConfig = (value: number): string =>
  String(value > 10 && value < 20 ? 0 : value)
    .split('')
    .reverse()[0];

export const getMinutesCaption = (minutes: number): string =>
  minutesConfigRU[getKeyToTimeCaptionConfig(minutes)];
export const getWeeksCaption = (weeks: number): string =>
  weekConfigRU[getKeyToTimeCaptionConfig(weeks)];

export const getDaysCaption = (days: number): string =>
  daysConfigRU[getKeyToTimeCaptionConfig(days)];

export const getMonthCaption = (days: number): string =>
  monthConfigRU[getKeyToTimeCaptionConfig(days)];

export const getYearsCaption = (days: number): string =>
  yearsConfigRU[getKeyToTimeCaptionConfig(days)];

export const getLessonsCaption = (lessons: number): string =>
  lessonsConfigRU[getKeyToTimeCaptionConfig(lessons)];

// export const getStringDuration = (duration: number): string => {
//   const hoursFloat = duration / 3600;
//   const hours = Math.trunc(hoursFloat);
//   const minutes = Math.ceil((hoursFloat - hours) * 60);
//   if (hours && minutes) return `~${hours} ч ${minutes} мин`;
//   if (hours && !minutes) return `~${hours} ч`;
//   if (minutes && !hours) return `~${minutes} мин`;
//   return '-';
// };

export const getStringDuration = (
  duration: string | number | undefined
): string => {
  if (!duration) return '~';
  if (isNumber(duration)) return `~ ${Math.ceil(duration)} мин`;
  if (isString(duration) && duration.endsWith('мин')) {
    return `~ ${duration}`;
  }
  if (duration === '~') return duration;
  return `~ ${Math.ceil(Number(duration))} мин`;
};

export const durationConfig: Record<
  string,
  (course: Partial<CourseData>) => string
> = {
  [RecommendationType.ADVICE]: (data) => {
    const { approximateTime } = optional<CourseData>(data);
    return getStringDuration(approximateTime);
  },
  [RecommendationType.MEDITATION]: (data) => {
    const { approximateTime } = optional<CourseData>(data);
    return getStringDuration(approximateTime);
  },
  [RecommendationType.LULLABIES]: (data) => {
    const { approximateTime } = optional<CourseData>(data);
    return getStringDuration(approximateTime);
  },

  [RecommendationType.LESSON]: (data) => {
    const { approximateTime } = optional<CourseData>(data);
    return getStringDuration(approximateTime);
  },

  [RecommendationType.COURSE]: (data) => {
    const { lessons } = optional<CourseData>(data);
    return lessons?.video
      ? `${Object.keys(lessons?.video).length} ${getLessonsCaption(
          Object.keys(lessons?.video).length
        )}`
      : '';
  },
  [RecommendationType.POST]: (data) => {
    const { approximateTime } = optional<CourseData>(data);
    return getStringDuration(approximateTime);
  },
};

export const checkIsWaitingChildWillStop = (
  dateOfPregnancy: number | null | undefined
): boolean => {
  //@ts-ignore
  const [days, weeks] = getWeeksAndDays(dateOfPregnancy);
  return (weeks || 0) + (days || 0) / 7 > 42;
};
