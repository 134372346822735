import { selectUser } from 'features/User/store/selectors';
import { useAppSelector } from 'store/store';
import { UsageOptions } from 'types';
import { LAST_BLOCK_COURSE } from 'utils/other';

export const useUser = () => {
  const user = useAppSelector(selectUser);

  const { id: userId, waeb } = user;

  const hasCertificate = waeb?.progress?.course === LAST_BLOCK_COURSE;

  return {
    user,
    userId: userId || '',
    hasCertificate,
    isEmptyDate:
      user?.usageOption === UsageOptions.AFTER_CHILDBIRTH
        ? !user?.dateOfChildBirth
        : user?.usageOption === UsageOptions.MANAGING_PREGNANCY
        ? !user?.dateOfPregnancy
        : false,
  };
};
