import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import styles from './StudyHeader.module.css';

export const StudyHeader: FC = () => {
  const t = useTranslate();
  return (
    <>
      <div className={styles.studyContainer}>
        <div className={styles.studyTitleContainer}>
          <h1 className={styles.studyTitle}>{t('nav_link_study')}</h1>
        </div>
      </div>
    </>
  );
};
