import { CheckMark, StudyError } from 'assets/icons';
import ClipLoader from 'react-spinners/ClipLoader';

export const linkStatus = {
  copied: {
    icon: <CheckMark />,
    text: 'Ссылка скопирована',
  },
  loading: {
    icon: <ClipLoader color="#9D8DF1" size={20} />,
    text: 'Копируем....',
  },
  error: {
    icon: <StudyError />,
    text: 'Возникла ошибка',
  },
};
