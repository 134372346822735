import { FC, TouchEvent, useCallback, useMemo, useState } from "react";
import styles from "./Carousel.module.css";
import { ArrowLeftIcon, ArrowRightIcon } from "assets/icons";
import { CarouselItem } from "./CarouselItem";
import { TCarouselItem } from "./constants";
import useWindowDimensions from "hooks/useWindowDimensions";
import classNames from "classnames";

export const Carousel: FC<{
  data: TCarouselItem[];
}> = ({ data }) => {
  const minIndex = 0;
  const maxIndex = data.length - 1;
  const startIndex = 0;

  const { width } = useWindowDimensions();
  const [currentIndex, setCurrentIndex] = useState<number>(startIndex);
  const [touchPosition, setTouchPosition] = useState<number | null>(null);

  const firstVisibleIndex = minIndex;
  const lastVisibleIndex = useMemo(() => {
    if (!width) return data.length - 3;
    if (width <= 1140 && width > 750) return data.length - 2;
    if (width <= 750) return data.length - 1;
    return data.length - 3;
  }, [data.length, width]);

  const updateCurrentIndex = useCallback(
    (newIndex: number) => {
      if (newIndex < firstVisibleIndex || newIndex > lastVisibleIndex!) return;

      setCurrentIndex(newIndex);
    },
    [firstVisibleIndex, lastVisibleIndex]
  );

  const handleLeftClick = useCallback(() => {
    updateCurrentIndex(currentIndex - 1);
  }, [currentIndex, updateCurrentIndex]);

  const handleRightClick = useCallback(() => {
    updateCurrentIndex(currentIndex + 1);
  }, [currentIndex, updateCurrentIndex]);

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      updateCurrentIndex(currentIndex + 1);
    }

    if (diff < -5) {
      updateCurrentIndex(currentIndex - 1);
    }

    setTouchPosition(null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div
          className={classNames(
            styles.arrowContainer,
            currentIndex === firstVisibleIndex && styles.disableArrowContainer
          )}
          onClick={handleLeftClick}
        >
          <ArrowLeftIcon className={styles.arrow} />
        </div>
        <div className={styles.visibleContainer}>
          <div
            className={styles.list}
            style={{
              transform: `translateX(-${
                (currentIndex * 100) / (maxIndex + 1)
              }%)`,
            }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            {data.map((item, i) => (
              <CarouselItem data={item} key={`${i}-${item.displayName}`} />
            ))}
          </div>
        </div>
        <div
          className={classNames(
            styles.arrowContainer,
            currentIndex === lastVisibleIndex && styles.disableArrowContainer
          )}
          onClick={handleRightClick}
        >
          <ArrowRightIcon className={styles.arrow} />
        </div>
      </div>
    </div>
  );
};
