import { FC, memo, useCallback } from "react";
import styles from './GoogleSignInButton.module.css';
import { useTranslate } from "hooks/useTranslate";
import { useAuth } from "features/Auth/hooks/useAuth";


const GoogleSignInButton: FC = memo(() => {
  const t = useTranslate();
  
  const { googleLogin } = useAuth();

  const handleSignIn = useCallback(
    () => {
      googleLogin();
    },
    [googleLogin]
  );

  return (
    <button 
      className={ styles.container }
      onClick={ handleSignIn }
    > 
      <div className={ styles.icon }></div>
      { t('login_google') }
    </button>
  )
});

export default GoogleSignInButton;
