import { stepsConfig } from 'pages/WaitingBabyPage/WaitingBabyPage.utils';
import './StepByStepBanner.style.css';
import { useTranslate } from 'hooks/useTranslate';

const StepByStepBanner = () => {
  const t = useTranslate();
  return (
    <div
      className="mzr_first_banner"
      style={{
        marginLeft: '27px',
        marginRight: '28px',
      }}
    >
      {stepsConfig.map((step) => (
        <div className="mzr_first_step" key={step.id}>
          <div className="mzr_first_step_one">Шаг {step.id}</div>
          <div className="mzr_first_step_two">
            <div className="mzr_first_step_title ">{t(step.title)}</div>
            <div className="mzr_first_step_text">
              {t(step.description)}
              {step.blocks && (
                <div>
                  {step.blocks.map((block) => (
                    <div key={block.id}>
                      <strong>БЛОК {block.id}.</strong> {t(block.title)}
                    </div>
                  ))}
                  <p>{t(step.description)}</p>
                </div>
              )}
              {step.note && <span>{t(step.note)}</span>}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StepByStepBanner;
