import { PlayIcon } from 'assets/icons';
import { useTranslate } from 'hooks/useTranslate';
import { RecommendationType } from 'types';
import { Lesson } from 'types/baseTypes';
import styles from './ContentLesson.module.css';
import LinesEllipsis from 'react-lines-ellipsis';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { Link } from 'react-router-dom';

type LessonsProps = {
  item: Lesson;
  type?: RecommendationType;
  contentId?: string;
  activeId: string | undefined;
  handleSelect: (id: string) => string;
  courseTitle?: string;
};

const ContentLesson = ({ item, activeId, handleSelect }: LessonsProps) => {
  const { order, title, id: lessonId } = item;
  const t = useTranslate();
  const { isMobile } = useAppWindowSize();
  return (
    <div className={styles.contentLesson}>
      <div className={styles.lessonIcon}>
        {activeId === item.id && (
          <PlayIcon width={isMobile ? 8 : 16} height={isMobile ? 8 : 16} />
        )}
      </div>
      <Link
        className={
          activeId === item.id ? styles.containerActive : styles.container
        }
        to={handleSelect(item.id)}
      >
        <img
          className={styles.image}
          src={`https://vumbnail.com/${item.vimeo.id}.jpg`}
        />
        <div className={styles.descriptionContainer}>
          <p className={styles.title}>
            {Number(order) <= 0 ? t('introduction') : `${t('lesson')} ${order}`}
          </p>
          <div className={styles.description}>
            <LinesEllipsis
              text={title}
              maxLine="2"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ContentLesson;
