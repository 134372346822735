import { FC, useState } from "react";
import styles from './EstimatedDateOfBirth.module.css';
import { updateDbUser } from "api/userApi";
import TextButton from "components/Buttons/TextButton/TextButton";
import { Modal } from "components/Modal/Modal";
import { getWeeksAndDays } from "features/Profile/utils";
import { useBinarySwitcher } from "hooks/useBinarySwitcher";
import { useTranslate } from "hooks/useTranslate";
import { noop } from "lodash";
import DatePicker from "react-datepicker";
import ru from 'date-fns/locale/ru';
import { subDays, addDays } from 'date-fns';
import { ArrowDownProfileIcon, ArrowNextIcon, ArrowPrevIcon } from "assets/icons";
import { SupposeddDateOfBirth } from "../SupposedDateOfBirth/SupposedDateOfBirth";


export const EstimatedDateOfBirth : FC<{
  dateOfPregnancy: number | null | undefined
}> = ({
  dateOfPregnancy,
}) => {
  const t = useTranslate();
 
  const [date, setDate] = useState<number | null | undefined>(null);

  const [isOpenModal, openModal, closeModal] = useBinarySwitcher(false);
  const [isOpenCalendar, openCalendar, closeCalendar] = useBinarySwitcher(false);

  const [days, weeks] = getWeeksAndDays(date);
  const isDataValid = !!weeks || !!days;
  const processDate = (value: number | null | undefined) =>
    String(value).padStart(2, '0');

  const handleOnChange = (value: Date | null) => {
    setDate(value?.getTime());

    closeCalendar();
  };

  const handleCancel = () => {
    setDate(null);

    closeModal();
  }

  const handleConfirm = () => {
    updateDbUser({ dateOfPregnancy: date })
      .catch(noop);

    setDate(null);
    closeModal();
  };

  return (
    <>
      <TextButton
        title={ t('profile_estimated_date_of_birth_title') }
        onClick={ openModal }
      />
      <div className={ styles.gap }></div>
      <Modal
        title={ t('profile_estimated_date_of_birth_modal_title') }
        description={ t('profile_estimated_date_of_birth_modal_description') }
        isOpen={ isOpenModal }
        onCancel={ handleCancel }
        cancelTitle={ t('common-cancel-2') }
        onConfirm={ handleConfirm }
        confirmTitle={ t('common-apply') }
        isCalendar={ isOpenCalendar }
      >
        {
          !isOpenCalendar &&
            <div onClick={ openCalendar } className={ styles.datePickerInput }>
              {
                !!date 
                  ? new Date(date).toLocaleString('ru-RU', { day:'numeric', month: 'long', year: 'numeric' }).replace(' г.', '') 
                  : t('common-not_indicated')
              }
              <img src={ ArrowDownProfileIcon } className={ styles.icon } alt='arrow down'/>
            </div>
        }
        {
          !isOpenCalendar && isDataValid &&
            <div className={ styles.periodPregnancy }>
              <div className={ styles.title }>
                { t('profile_period_pregnancy_modal_title') }
              </div>
              <div className={ styles.displayPeriodPregnancy }>
                <div className={ styles.number }>
                  { processDate(weeks)}
                </div>
                <div className={ styles.text }>{ t('week', { count: weeks || 0 }) }</div>
                <div className={ styles.number}>
                  { processDate(days) }
                </div>
                <div className={ styles.text }>{ t('day', { count: days || 0 }) }</div>
              </div>
            </div>
        }
        {
          !isOpenCalendar && !!date &&
            <SupposeddDateOfBirth dateOfPregnancy={ date } />
        }
        {
          isOpenCalendar && 
            <DatePicker 
              selected={ date ? new Date(date) : null }
              onChange={ (date) => handleOnChange(date) }
              inline
              locale={ ru }
              excludeDateIntervals={[
                { start: subDays(new Date(), 0), end: addDays(new Date(), (364 * 30)) },
              ]}
              renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                <div className='react-datepicker__nav-container'>
                  <button onClick={ decreaseMonth } className='react-datepicker__nav-container--button'>
                    <div className='react-datepicker__nav-container--icon'>
                      <ArrowPrevIcon />
                    </div>
                  </button>
                  <span className='react-datepicker__current-month'>
                    { (date.toLocaleString('ru-RU', { month: 'long', year: 'numeric' })).replace(' г.', '') }
                  </span>
                  <button onClick={ increaseMonth } className='react-datepicker__nav-container--button'>
                    <div className='react-datepicker__nav-container--icon'>
                      <ArrowNextIcon />
                    </div>
                  </button>
                </div>
              )}
            />
        }
      </Modal>
    </>
  )
}
