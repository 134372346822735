import { FC } from 'react';
import styles from './StatusPicker.module.css';
import classNames from 'classnames';
import { useTranslate } from 'hooks/useTranslate';
import { UsageOptions } from 'constants/constants';
import { CheckIcon } from 'assets/icons';
import { STATUSES } from '../Status/constants';


export const StatusPicker: FC<{
  selectedStatus?: UsageOptions | null;
  onChange: (value: UsageOptions) => () => void,
}> = ({
  selectedStatus,
  onChange,
}) => {
  const t = useTranslate();

  return (
    <div className={ styles.container }>
      {
        STATUSES.map(({ title, icon }) => (
            <div
              key={ title }
              className={ classNames(
                styles.wrapper,
                title === selectedStatus && styles.selected
              ) } 
              onClick={ onChange(title) }
            >
              <img src={ icon } className={ styles.icon } alt='status'/>
              <div>
                { t(`${title}`) }
              </div>
              <div className={ styles.check }>
                <CheckIcon /> 
              </div>
            </div>
          )
        )
      }
    </div>
  )
};
