import { CourseData, RecommendationType } from 'types';
import { urlTypeConfig } from 'utils/other';
import styles from './SitemapItems.module.css';
import { getCoursesByCategoryIdAndType } from './Sitemap.utils';

type SitemapItemProps = {
  categoryId: string;
  type: RecommendationType;
  allCourses: CourseData[];
};

export const SitemapItems = ({
  categoryId,
  type,
  allCourses,
}: SitemapItemProps) => {
  const courses = getCoursesByCategoryIdAndType(allCourses, categoryId, type);
  const urlType = urlTypeConfig[type];
  return (
    <ul>
      {courses.map(({ id, title }) => (
        <li>
          <a
            href={`edu/${categoryId}/${urlType}/${id}`}
            className={styles.links}
          >
            {title}
          </a>
        </li>
      ))}
    </ul>
  );
};
