import {
  separator,
  tb5c3_1,
  tb5c3_2,
  tb5c3_3,
  tb5c3_4,
  tb5c3_5,
  tb5c3_6,
  tb5c3_7,
  tb5c3_8,
  tb5c3_9,
} from 'assets/images';

export const b5_c18_text = {
  b5_c3_title: `Курс «Гигиена ребенка»`,
  b5_c3_description: `Спикеры: 

    Надежда Дубатовка, врач-педиатр
    
    Ольга Сердюченко, врач-реаниматолог отделения выхаживания недоношенных новорожденных`,
  b5_c18_text: `
    ${tb5c3_1}

    Урок 1. Режим температуры и влажности

Обсудим, какие условия температуры и влажности воздуха являются оптимальными для малыша.

Для того чтобы их создать, вам необходим градусник и гигрометр. Гигрометр – это прибор, который измеряет влажность воздуха. 

Оптимальная температура для малыша – 18-22 °С
Оптимальный уровень влажности – 40-60% 
Температура

Как создать оптимальную температуру в условиях, когда наступает отопительный сезон и батареи греют очень сильно?

Батареи могут быть как регулируемые, так и нерегулируемые. В случае, если вам повезло и у вас регулируемые батареи, то постарайтесь отрегулировать их таким образом, чтобы они грели воздух в квартире до температуры 18-22°С, оптимально – 19-20°С. 

В случае, если у вас нерегулируемые батареи, вы можете, например, закрыть батарею чем-либо. Например, влажным полотенцем, или каким-нибудь одеялом, или, возможно, специальными экранами для батарей, которые помогут уменьшить температуру. 

Влажность

Если говорить про влажность воздуха, то тут необходимо помнить о том, что влажность воздуха зимой очень сильно зависит от того, как часто вы проветриваете. Проветривание уменьшает влажность в комнате. Для увеличения влажности можно пользоваться специальными увлажнителями воздуха или мойками воздуха. Мойка воздуха – это специальный прибор, который при помощи определенных механизмов может распылять воду, воздух, создавать комфортные условия для пребывания ребенка. Измерять эту влажность можно при помощи специального прибора – гигрометр.

Как одевать малыша?

Очень часто молодые родители спрашивают, как нужно одевать маленького малыша, которого только что принесли из роддома. И основная ваша задача – научиться понимать, как отличить: ребенку холодно или жарко.

Помним, что детки имеют несовершенный термогенез. Это значит, что их очень легко как перегреть, так и переохладить. Именно поэтому мы рекомендуем оптимальные условия комнатной температуры. Старайтесь не кутать ребенка, а одевать таким образом, чтобы ему было комфортно, в зависимости от того, какая температура воздуха у вас дома. 

Приведу пример для оптимального температурного режима 19°С. Ваш малыш может находиться в подгузнике и слипе. Совершенно не обязательно носить дома шапочку. И даже после ванночки достаточно просто высушить голову при помощи промакивающих движений полотенцем, и шапочка совершенно не обязательна.

Слипы – эта самая удобная одежда для малышей, потому что она закрывает полностью все тело, не сковывает движение. В слипе нет никаких натирающих частей – и ребенок чувствует себя комфортно. Вниз, если очень холодно, можете одеть боди.

${tb5c3_2}

Прятать ли ножки в носочки и ручки и антицарапки или варежки – это ваш выбор. Но по большому счету в этом тоже нет нужды. У малыша могут быть холодные ручки и ножки – но это не значит, что он замерз.

Как узнать, холодно вашему малышу или нет? Необходимо смотреть и ориентироваться на заднюю часть шейки. Если вы чувствуете, что там температура комфортная – значит, малышу ни жарко, ни холодно.

${separator}

Урок 2. Признаки перегрева/переохлаждения

В большинстве случаев ваш родительский инстинкт не даст вашему ребенку замерзнуть, потому что любая мама и любой папа всеми силами старается погреть малыша, сделать так, чтобы ему было тепло и комфортно. Однако иногда так бывает, что ребенку действительно холодно. 

Напоминаю, что холодные ручки и ножки – не показатель. На что же следует ориентироваться?

Цвет конечностей
Если ручки и ножки посинели либо стали мраморного цвета – это, скорее всего, будет свидетельствовать о том, что ребенку холодно. 

Дрожь ( во всем теле либо в конечностях)
Если ребенку холодно – значит, что нужно как можно скорее постараться его одеть теплее. 

Громкий плач
Чаще всего дети, которые замерзли, очень громко кричат. Кричат навзрыд, будто им что-то болит. 

Ребенок сонный
Ребенок может замерзнуть и таким образом, что станет сонливым и не будет издавать никаких звуков. На это тоже следует обращать внимание, это крайне важно. Однако такие ситуации встречаются крайне редко. И чаще всего это происходит на улице (зимой), когда вы не совсем правильно выбрали одежду для прогулки либо не до конца оценили ситуацию, когда выходили из дома. Что с этим делать? Нужно быстрее отправляться домой и греть малыша. А также постараться в следующий раз брать с собой дополнительное одеяло.

Как помочь малышу, если он замерз? 

Вы обязательно одеваете его, накрываете сверху одеялом и стараетесь покормить. Берете его на ручки и согреваете собственным теплом.

Признаки перегрева

Кожа у малыша покраснела. Значит, скорее всего, вы перестарались с его одеждой. 
Холодный липкий пот.
Ребенок кричит. Громкий плач может быть еще одним признаком, что малышу просто жарко. Постарайтесь переодеть его, если это случилось, и не допустить появления потницы или опрелости.
Как помочь малышу, если вы перегрели его?

Проще всего обтереть его влажным полотенцем либо пойти в душ и помыть его. Старайтесь избегать перегрева, потому что это самая большая проблема, которая может привести к обезвоживанию малыша, а также к появлению сыпи, которая называется потница. Чаще всего она появляется в области шеи, в области складок, иногда на спине, потому что спина потеет больше всего. С потницей сражаться довольно сложно, потому что основной причиной ее является перегрев. И потница пропадает, если ребенка не перегревать. А лечить ее каким-то другим методом практически невозможно.

Опрелости

Опрелости появляются в складках кожи – и часто на фоне этих опрелостей может быть присоединение бактериальных или грибковых инфекций, которые приходится дополнительно лечить. Особенно неприятная ситуация, когда ребенку жарко и у него появляется пеленочный (так называемый пеленочный) дерматит в области паховых складок и подгузника.

Чтобы избежать всех этих проблем, нужно стараться не перегревать малыша. И для этого важно постоянно контролировать температуру воздуха и ориентироваться на свои ощущения.

${separator}

Урок 3. Как держать малыша на руках?

Первые 3 месяца малышу обязательно нужно поддерживать голову. Когда вы его берете на ручки, ваша одна из рук должна удерживать шейку и голову сзади. Не нужно давить на шею. Но при этом важно делать так, чтобы не допустить переразгибания головы в шейном отделе. 

В поддержках, где ребенок находится на спинке, очень желательно удерживать голову примерно до возраста 3 месяцев. Когда ребенок сможет удерживать голову самостоятельно, можно уже не помогать ему и не поддерживать голову.

Как же сделать этот помогающий захват? Вы кладете ровную ладонь на заднюю часть шейки и поддерживаете голову. При этом вы можете поддерживать под таз второй рукой ребенка.

${tb5c3_3}

Рассмотрим удобные поддержки для ребенка.

Поддержка на локте

Довольно удобная для мам и для пап. Шея находится в ямке локтевой, все предплечье находится под телом малыша и ладонь – под тазом ребенка. Вторая рука может быть свободной.

${tb5c3_4}

Позиция на груди лицом к себе

При помощи одной из рук, которая помогает держать шею малыша, вы прислоняете ребенка к себе и фиксируете второй рукой таз малыша.

${tb5c3_5}

Позиция лицом к миру

Вы прислоняете ребенка всем телом к себе при помощи одной руки, а второй рукой не под попу держите, а за бедрышки, делая провисание (провисание попы вниз).

${tb5c3_6}

Малышей до месяца долго носить так не рекомендуется, потому что у них может случиться перевозбуждение от огромного количества информации, которую он получает, находясь лицом к миру.

Поддержка на одной руке при помощи захвата ребенка за грудную клетку

Эта интересная поддержка от американских педиатров, которые рекомендуют таким образом успокаивать малышей. Таким образом, вы, поддерживая голову малыша, кладете его на руку, которая находится на грудной клетке, и, похлопывая немного по спинке, укачиваете.

${tb5c3_7}

Практически каждый малыш за несколько секунд такого укачивания будет успокаиваться. Некоторым деткам, чтобы успокоиться, желательно еще присогнуть ножки. И вот легкие маятникообразные движения помогают им успокоиться и справиться с болью в животике.

Важные аспекты в поддержке ребенка

Только что рожденный малыш не умеет держать голову, и поэтому мы обязательно фиксируем шейку, пока он не научится держать голову сам.
Ни в коем случае не сажайте малыша, пока он не научится делать это сам. 
Старайтесь равномерно распределять нагрузку на малыша при поддержках. Нежелательно нагружать одну ногу при поддержке ребенка или тянуть его сильно или резко за руки или за ноги. Нежелательно искривлять позвоночник, когда вы его держите в любом отделе. Старайтесь ровно держать и равномерно распределять всю нагрузку на все тело.
Как правильно брать малыша на руки, если он лежит?

Мы укладываем свою руку под шею ребенка, чтобы не допустить переразгибания в шейном отделе, и, немножко поворачивая тело, поднимаем к себе на ручки через бочок.

${separator}

Урок 4. Ежедневный туалет кожи

Каждое утро малыша начинается с утренней гигиены кожи. И первым делом я хочу напомнить о том, что делать это нужно чистыми руками. Мама должна помыть руки и использовать по возможности первое время антисептик.

На пеленальном столике поставьте стаканчик с кипяченой охлажденной водой, подготовьте ватные диски, вату, при необходимости ватные палочки.

Вы раздеваете малыша полностью, для того чтобы осмотреть каждую складочку. Смотрите за каждым ушком, нет ли там опрелости. Затем берете ватный диск, смачиваете его обычной кипяченой водой без каких-либо добавок и протираете.

Теперь смотрим кожу под подбородком. Берем отдельный ватный диск, смачиваем водичкой, протираем. Если там вас ничего не беспокоит, то каких-либо кремов использовать не нужно. 

Далее нажмите на подбородок ребенка – откройте ротик и посмотрите, не смущает ли там что-то, нет ли каких-то элементов стоматита, налетов, белых выделений.

Смотрим под ручками, подмышки. Если все хорошо – протираем ватным диском, смоченным в воде, одну подмышку, вторую подмышку. Аналогично поступаем с паховыми складочками. При необходимости, если есть какие-то опрелости, используем специальные кремы.

Не забываем осматривать глазки. Глазки смотрим и обязательно протираем отдельным ватным диском каждый глазик, начиная с наружного угла глаза, двигаясь медленно аккуратно без резких движений к внутреннему углу глаза, который находится у носика. То же самое делаем второй стороной ватного диска с другим глазиком.

Осмотрите носик ребенка. Там вы можете обнаружить какие-то корочки или небольшие выделения. Чтобы их убрать, возьмите небольшой кусочек ваты, сделайте фитилек или турундочку (длинный жгутик из ваты). Опустите его в водичку, а затем круговыми движениями прочистите носик малыша. Если не получается очистить просто водичкой – используйте вазелиновое масло либо любое другое масло, которое есть дома.

Чистить носик, если он чистый, не нужно. То же самое касается ушек. Если они чистые – там ватными палочками ничего не очищаем. Если вы видите, что там скопилось некоторое количество серы, специальной ватной палочкой со стопором (ограничителем) вы протираете этот кусочек серы, убираете, не вводя ватную палочку глубоко в слуховой проход и не заталкивая туда внутрь этот кусочек серы.

Каждый день обязательно проводите такой утренний туалет кожи. Профилактика проблем кожи новорожденного – это основа педиатрии.

${separator}

Урок 5. Уход за пупочной ранкой

Для начала я хотела бы вам рассказать, что такое пуповина. Пуповина – это три сосуда, которые окружены специальным студенистым веществом (очень эластичным), который во время родов пережимают и, перерезая пуповину, отделяют ребенка от плаценты. И у малыша остается пупочный остаток. 

На данный момент малыши приезжают с этим пупочным остатком домой. Хочу сделать акцент на том, что пуповину не перевязывают. И нельзя сказать, что у ребенка некрасивый пупок из-за того, что ему неправильно что-то сделали в роддоме. Пуповину только пережимают, отделяя малыша от плаценты. И форма пупочной ранки будущего пупка не будет зависеть от того, как происходило вот это перерезание, пережимание сосудов.

Итак, ребенок приезжает домой с пупочным остатком. Главная задача родителей в этот период – просто подождать, пока пупочный остаток усохнет и самостоятельно отвалится. После чего останется пупочная ранка, за которой нужно немножко поухаживать.

В чем заключается уход? Современные рекомендации Всемирной организации здравоохранения говорят о том, что в пупочную ранку лучше вести ее так называемым открытым методом. Что это значит? 

не заклеивать
не замазывать
и в целом не трогать
Ранка заживает самостоятельно. И вмешиваться в этот процесс стоит только в случае, если что-то пошло не так. Например:

пупочная ранка покраснела
из нее выделяется какая-то жидкость, похожая на гной
она может иметь какой-то запах или быть желто-зеленого цвета
вы видите подкравливание пупочной ранки (это тоже может быть признаком инфицирования) 
В этих случаях, когда что-то пошло не так, вам необходим дополнительный уход за пупочной ранкой. При этом используются специальные антисептические вещества. Один из них – это перекись водорода. Можно использовать местные средства с антибиотиками при необходимости.

Что говорит современная медицина по поводу использования зеленки для пупка? А современная медицина говорит о том, что анилиновые красители не рекомендуется использовать по той простой причине, что вы можете пропустить покраснения кожи вокруг пупочной ранки из-за того, что она будет темно-зеленого цвета. Еще минусом зеленки является то, что большинство бактерий на данный момент устойчивы к анилиновым красителям. Поэтому постарайтесь использовать современные антисептики, если в этом будет реальная необходимость. 

Если же пупок зарастает самостоятельно и заживает без каких-либо проблем (нет выделений из пупочной ранки, нет покраснений), ребенок хорошо себя чувствует, активно сосет, не сонлив и у него нет температуры – то в пупочную ранку лезть не следует.

${tb5c3_8}

Урок 6. Косметика для малыша

Каждая мама хочет купить ребенку все самое лучшее, чтобы у него была хорошая кожа и не было с ней проблем. Однако в современном мире уж слишком развит маркетинг и реклама, и это очень влияет на беременных мам и молодых родителей. 

Современные педиатры не рекомендуют покупать заранее какую-либо косметику для малышей, особенно специальные кремы для ухода, потому что вполне возможно такое, что они вам не понадобятся. Здоровый малыш, у которого нет никаких проблем с кожей, не нуждается ни в какой косметике, кроме мыла и шампуня раз в неделю. А если возникнут какие-то проблемы – то косметика или специальные кремы подбираются малышу индивидуально.

Как выбрать детское мыло? 

Рекомендую купить жидкое мыло либо пенку с нейтральным pН для детской кожи – это 5,5. Желательно при использовании мыла стараться максимально смывать его с кожи малыша, потому что остатки мыла могут сильно раздражать кожу. Рекомендуем использовать мыло как можно реже, то есть не при каждой смене подгузника. Можно использовать только проточную воду, и легкими промакивающими движениями протирать кожу малыша.

Пользоваться ли мылом каждый раз, когда ребенок покакал? Ведь новорожденный может какать до 7-10 раз в день, и это является нормой. Совершенно необязательно каждый раз использовать мыло, вы можете использовать только проточную воду – просто, возможно, потратите чуть больше времени. Однако в случае, если вам все-таки понадобилось мыло, постарайтесь смывать его до ощущения чистой кожи.

Необходимо ли пользоваться мылом при ежедневных ваннах малыша? Это совершенно необязательно. Мыло рекомендуем использовать примерно 1-2 раза в неделю. Малыш не пачкается сильно, максимум потеет, а пот прекрасно смывается при помощи обычной проточной воды.

А что насчет ванночек с отварами трав? Например, часто мы можем услышать, что рекомендуют использовать отвар ромашки или череды, коры дуба. Все это совершенно не нужно здоровому ребенку, который не имеет проблем с кожей. А проблемы и есть, то отвары трав могут увеличить проблему (может появиться аллергическая реакция). Поэтому постарайтесь все-таки не использовать растительные продукты для нежной кожи малыша. Пользуйтесь просто обычной проточной водой – этого вполне достаточно.

Обсудим также марганцовку. Слабо розовый раствор, который ранее в часто рекомендовали использовать для купания малышей, не имеет ничего общего с антисептиком. Он не обладает антисептическими свойствами и не может убить бактерии в той концентрации, которую вы создаете. Единственное, что может сделать такой раствор, – это высушить кожу малыша, вследствие этого кожа будет шелушиться. То есть нужды в этом нет никакой, пользы – тоже никакой, один только минус – шелушащаяся сухая кожа, которую потом придется увлажнять.

Кремы под подгузник и присыпки

Современные подгузники не требуют дополнительного использования специальных кремов под подгузники или присыпок. Это совершенно не нужно и даже может навредить малышу. Как оказалось, частички порошка присыпки могут раздражать нежную кожу младенца и даже стирать верхний слой, что может увеличивать вероятность повреждения кожи, появления дерматитов. В частности, пеленочного дерматита, к которому очень часто присоединяется грибок рода Candida – и появляется молочница. Это очень неприятные ситуации, которые потом приходится долго лечить специальными кремами.

Если у малыша все-таки появился пеленочный дерматит или опрелость, вы можете использовать специальные кремы, которые вам подскажет доктор (чаще всего они продаются в аптеке). Присыпки использовать нужды нет.

Как часто мыть голову ребенку с шампунем? Достаточно использовать шампуни для малышей 1 раз в неделю, остальное время мойте ребенка в проточной воде. Если у малыша есть гнейс ( жирные корочки в области кожи головы), то используется специальный крем или шампунь от этих корочек, который продается в аптеке.

Подводя итоги, хочется сказать: не покупайте заранее косметические продукты для малыша, потому что здоровый малыш не требует каких-то дополнительных кремов или средств по уходу. А вот если у вас возникнут проблемы, то индивидуально доктор назначит вам определенные продукты, которые потребуются в данной ситуации.

${separator}

Урок 7. Интимная гигиена малыша

Интимная гигиена девочек

Основная задача родителей – не допустить попадания фекалий в область малых половых губ. Именно для этого в складке между малыми половыми губами и большими половыми губами находится специальная белая смазка, которую не рекомендуется трогать. Она обладает специальными антисептическими, антибактериальными свойствами и защищает эту нежную область от инфицирования самостоятельно, поэтому вымывание данных выделений белых не рекомендуется.

Когда вы подмываете маленькую новорожденную девочку, держать ее желательно на своем предплечье, поддерживая за таз. Нужно наловчиться сделать так, чтобы струя воды находилась в области промежности и лилась спереди назад. 

Желательно подмывать девочку в положении животиком кверху. 
Не направляйте струю воды непосредственно на промежность. Струя воды должна быть рядом. 
Мягкими движениями аккуратненько промывайте все спереди назад, используя мыло как можно реже (по возможности) и смывая его до конца.
Протирать и лезть куда-то глубоко не рекомендуется. Там все сделано таким образом, чтобы не допустить инфицирования самостоятельно. Поэтому вы можете просто осматривать эту область, а белые выделения не трогаете. Если вы видите там какие-то части фекалий, что-то воспалилось – тогда обратитесь к доктору.

Интимная гигиена мальчиков

С мальчиками намного проще. Их можно подмывать и в положении попой / спинкой кверху. И мальчика поддерживать можно тоже на предплечье и держа таз ребеночка в ладони.

Необходимо ли открывать головку полового члена ребенка? В этом нет абсолютно никакой необходимости, и это заложено природой. Половой член и крайняя плоть у маленького ребенка – это единое целое, то есть они от природы соединены друг с другом, и разрывать, помогать им разъединяться нежелательно. Это сделает сам организм, выделяя при этом процессе специальную смегму. Эти выделения белого цвета без запаха и признаков воспаления помогают ребенку избавиться от инфекций. 

Процесс отделения крайней плоти от головки полового члена физиологичен, он не требует каких-то дополнительных вмешательств. Он начинается в более старшем возрасте. Очень редко можно увидеть новорожденного малыша, у которого отделяется головка полового члена от крайней плоти. Чаще всего это происходит в год (а то и в три). До 3 лет ничего не нужно делать.

Резюмируем: никаких специфических действий ни у девочек, ни у мальчиков проводить с половой областью не следует. Вы просто внимательно следите, наблюдаете, чтобы все было хорошо. Ухаживайте за этой областью нежно и аккуратно.

${tb5c3_9}

Урок 8. Купание малыша

Температура воды

Не забывайте, что мы купаем малыша не для того, чтобы он был чистый (потому что он особо не пачкается, а лишь потеет). Купаем малыша мы для того, чтобы в том числе закаливать. Таким образом, очищаем кожу от липкого пота и закаливаем. 

Чтобы покупать малыша, старайтесь использовать поначалу температуру воды примерно 37 °С. Постепенно, медленно, в зависимости от того, насколько вы спокойные родители, вы можете снижать температуру воды, вплоть до 32 °С. Это можно делать с начала, когда ребенок еще считается новорожденным.

Как держать малыша во время купания? 

Вы поддерживаете его шейку, опускайте в ванночку, и в ванночке уже можете отпустить нижнюю часть и даже помочь малышу немножко поболтать ножками. 

Добавлять чего-либо в ванночку во время купания совершенно не нужно: ни отвары трав, ни мыльные шампуни или гели для душа. Один раз в неделю можете устраивать «банный день» и тщательно мыть ребенка с мылом. Ежедневные ванночки делайте в обычной проточной воде.

Необходимо ли кипятить воду для купания малыша, когда у него еще не зажила пупочная ранка? Делать этого не нужно, потому что стерильность вокруг ребенка – это не самая лучшая идея. В любом случае он всегда будет встречаться с вашей микрофлорой, которую вы носите на своих руках, на теле. Во всех частях вашей ванной, в том числе на столе, на пеленальном столике везде есть бактерии, и кипяченая вода не решит эту проблему (это, собственно, и не является проблемой). 

Но пока у ребенка есть пупочный остаток (т.е. не пупочная ранка, а пупочный остаток), купать малыша лучше не стоит. Потому что пупочный остаток должен высохнуть (поэтому он и отваливается, когда становится сухим). Если вы его будете постоянно мочить, то это затянет процесс высушивания, а также может возникнуть инфицирование. Подождите, пока пупочный остаток высохнет окончательно – и уже потом можно купаться.

Время купания не ограничено. Первое время многим детям не очень нравится купаться, поэтому это занимает не больше 3-5 минут. Однако если малышу нравится купаться – пожалуйста, время не ограничено, вы можете купать его и 15-20 минут, если малышу весело.

Как только малыш станет самостоятельно удерживать голову, вы можете уже использовать поддержки на животе и купать его не только на спинке, но и на животике.

Обсудим круг для купания малышей. Его использовать можно с момента, когда малыш умеет удерживать голову. Однако помните, что ребенку может быть в нем некомфортно. Круг может натирать шею у ребенка или может вызвать переразгибание в шейном отделе, если ребенок вдруг сам захочет изменить положение тела во время купания. Это может быть довольно опасно, поэтому за ребенком при купании в этом специальном круге нужно обязательно смотреть, наблюдать, следить.

Помните также о том, что если пространство в круге очень большое, ребенок может провалиться в эту дырку и уйти под воду. Будьте осторожны и используйте этот круг строго по назначению, под тщательным наблюдением взрослого.

Рекомендуется не использовать круг для малышей младше полутора месяцев. И желательно не позже 3-4 месяцев, потому что малыш, который уже подрос, будет дрыгать ножками. И круг будет его ограничивать, он может ему не нравиться. Чаще всего родители это понимают сами по поведению ребенка и уже отказываются от купания в круге. 

Подводя итоги, хочется сказать, что купать детей следует ежедневно. И купаемся мы первым делом для того, чтобы закаливаться, вторым делом, чтобы развивать физическую активность ребенка. Во время купания малыш ощущает свое тело намного легче, чем оно есть на самом деле, ему проще двигаться, развивать мышцы и суставы без нагрузки на осевой скелет. А вот как гигиеническая процедура ванна используется примерно 1 раз в неделю или при необходимости чаще.

${separator}

Урок 9. Смена подгузника

Большинство исследований, которые проводились по поводу подгузников, пришли к выводу, что подгузники абсолютно безопасны как для кожи малыша, так и для его будущей жизни. То есть никак не влияют на фертильность (способность к зачатию) ни мальчиков, ни девочек. Поэтому спокойно пользуйтесь подгузниками и не переживайте, что от этого ребенок будет иметь в будущем какие-либо проблемы.

Какие бывают подгузники? Их великое множество, и вы обязательно сможете подобрать индивидуально под вашего ребенка. Основные виды:

Подгузники с застежками – их удобно использовать для малышей примерно до 3 месяцев.
Подгузники-трусики – для более старших малышей (старше 3 месяцев). Они принимают форму тела при помощи специальных резинок, и это очень удобно.
Напоминаю, что использовать какие-либо крема или дополнительные средства перед использованием подгузников не нужно. Задача родителей – менять подгузники как можно чаще, особенно, если малыш сходил по-большому. Кал малыша имеет кислую среду и может раздражать кожу малыша, приводя к дерматитам, опрелостям и раздражениям. Поэтому как только вы почувствовали запах или увидели, что малыш покакал, старайтесь как можно быстрее поменять подгузник.

Как правильно надевать подгузник на малыша? 

Расправляем подгузник. Та часть, на которой застежки, идет к спинке.
Поворачиваем ребенка на бочок, подкладываем подгузник резинкой к спинке, аккуратно расправляем. 
Распределяем переднюю часть подгузника по животу, натягиваем одну застежку, вторую застежку. Если у ребенка еще не зажила пупочная ранка – подворачивайте сверху подгузник, чтобы он не травмировал ранку.

${separator}

Урок 10. Детский массаж

Ни для кого ни секрет о том, как важен массаж для детей первого года жизни. Существуют разные виды массажа: лечебный, общеукрепляющий, профилактический. Лечебный массаж назначают врачи-специалисты на плановых осмотрах, и он производится профессиональными массажистами.

Сегодня мы рассмотрим, как каждая мама или папа могут сделать массаж своему ребенку в домашних условиях. 

На первом месяце жизни очень важны прикосновения к малышу. Поглаживание производить можно перед кормлением малыша, перед сменой подгузника или перед купанием. Поглаживать малыша начинают с ножек. Для этого мягкими нежными движениями мы поглаживаем ножки малыша от стопы до бедра, избегая области коленного сустава. В начале – одна нога, потом – другая ножка. Затем переходим к поглаживанию ручек. Поглаживание ручек начинаем от кисти, проходим предплечья и на плечо. Поглаживание производим мягкими нежными движениями. В начале – одна рука, затем – другая ручка ребенка. Поглаживание животика производится по часовой стрелке медленными нежными движениями. 

Перед кормлением мы выкладываем малыша на животик, и это время мы можем использовать для того, чтобы погладить спинку ребенка, начиная от попки к плечу, и ножки ребенка, начиная от пяточек и до бедрышек.

1-3 месяца 

В это время у детей преобладает физиологический гипертонус конечностей. Ручки малыша согнуты в локтях, ладошки собраны в кулачки, ножки малыша приведены в коленках. Цель массажа этого периода – расслабить и стабилизировать мускулатуру малыша. Мы производим нежные поглаживающие движения от стопы к бедру одной ножки и от стопы к бедрышку другой ножки. Массируем стопы ребенка, пальчики и пяточку, делая движения «семерочкой» и «восьмерочкой». 

В это время добавляются элементы суставной гимнастики. Сгибание, разгибание ножек в коленях и сгибание, разгибание стопы одной ножки и другой ножки. Затем переходим к ручкам. Мы продолжаем поглаживать руки, потихоньку выпрямляя и разгибая пальчики. Поглаживание производим медленными движениями – одну ручку, затем переходим ко второй ручке малыша. Добавляя элементы суставной гимнастики, мы производим разведение-приведение ручек к себе (так называемые обнимашки). Обнимаем малыша – отпускаем, в другую сторону: обнимаем малыша – отпускаем. Также производим сгибание-разгибание ручек в локтевых суставах – одна ручка, затем другая ручка.

В этот период важен массаж живота ребенка. Поглаживающими движениями мы массируем по часовой стрелке, этим самым мы помогаем отхождению газиков малыша. Боковые мышцы живота массируем от боковой поверхности к центру пупка. Переворачивая ребенка на животик, мы массируем область спины и ножек. Элементами суставной гимнастики в этот возрастной период будет сгибание-разгибание ножек в коленных суставах и приведение-отведение ножек в тазобедренных суставах.

3-6 месяцев

В это время ребенок научился лежать на животе, он умеет держать голову. Следующим важным этапом в развитии является переворот со спинки на животик. Важно показать и объяснить ребенку, как это происходит. Для этого мы захватываем ручку за область лучезапястного сустава и, немножко потягивая ее, помогаем ребенку перевернуться на живот. То же самое мы производим в другую сторону. Потянув ручку, мы за ручку переворачиваем ребеночка на животик. То же самое можно производить при помощи ножек малыша. Мы сгибаем ножку, приводим ее к животику и помогаем ему перевернуться в одну и в другую сторону.

Важным упражнением суставной гимнастики является приседание малыша из положения лежа. Обхватывая лучезапястные суставы, мы подтягиваем малыша на себя, он потягивает голову и тянется, тем самым тренируя мышцы шеи и спины.

Продолжаем делать элементы суставной гимнастики для рук: выводим ручку вверх, вниз и добавляем круговые движения с одной и с другой стороны. Сгибаем-разгибаем попеременно ножки, поднимаем ножки прямые вверх, опускаем ножки прямые вниз.

6-12 месяцев

Это тоже важный этап жизни, когда ребенок учится вставать на четвереньки, ползать, садиться, вставать и учится ходить. Помочь малышу мы можем, научив его стоять на четвереньках, тем самым подкладывая валики или подушки для того, чтобы ребенок учился вставать на коленки и на ручки. Садятся малыши из положения на четвереньках. Вначале они встают на четвереньки, затем заваливают бедрышко на бок и таким образом садятся, а затем встают.

Очень важным является научить ребенка опираться. Опора на руки является важным моментом. Для этого мы подкладываем валик, когда малыш лежит на животе, и малыш учится опираться и держаться на своих ручках. Затем он подтягивает ножки. 

Массаж стоп важен в этот период для того, чтобы укрепить мышцы ножек. Массаж стоп производится от большого пальца к мизинцу и затем на пятку. Легкими массажными движениями мы массируем мышцы, тем самым стабилизируя стопу. Затем производим восьмиобразное движение, производим сгибание-разгибание стопы, отведение и приведение одной и другой ножки. Благодаря совместным усилиям родителей и малыша, ребенок в течение первого года жизни научится правильно ползать, сидеть и ходить.


    `,
};
