import {
  separator,
  tb3c5_1,
  tb3c5_2,
  tb3c5_3,
  tb3c5_4,
  tb3c5_5,
} from 'assets/images';

export const b3_c12_text = {
  b3_c5_title: `Курс «Послеродовое восстановление» `,
  b3_c5_description: `
    Спикеры:

Ирина Крастелева, кандидат медицинских наук, доцент кафедры неонатологии БелМАПО

Диана Мардас, врач акушер-гинеколог
    `,
  b3_c12_text: `
    ${tb3c5_1}

    Урок 1. Сроки выписки из роддома

Вас ждет одно из самых прекрасных событий вашей жизни – выписка из роддома. Это день, которого ждут молодые мамы и папы. Казалось бы, в вопросе выписки нет ничего сложного, но на самом деле есть много различных важных нюансов, о которых я вам расскажу в данном курсе.

Сроки выписки

Родильницы и новорожденные могут быть выписаны из акушерского стационара: 

на 3-и сутки после родов через естественные родовые пути
на 5-е сутки – после операции кесарево сечение, при определенных условиях
Почему именно такие сроки? Выписка из родильного дома проводится не ранее 3-х суток послеродового периода, потому что это связано с необходимостью обследования новорожденного (как минимум, 60 часов жизни) и соблюдения календаря прививок. Напомню, по Национальному календарю прививок ребенку в первые дни жизни рекомендуется сделать следующие прививки: в первые 12 часов жизни делается прививка от вирусного гепатита В, а на 3-й день жизни – от туберкулеза, или БЦЖ вакцина. Выписка происходит, как минимум, после 3 часов наблюдения после вакцинации.

Обратите внимание: новоиспеченная мама имеет право отказаться от вакцинации. Отказ от профилактической прививки оформляется записью в медицинских документах пациента и подписывается пациентом и лечащим врачом.

Давайте разберемся, как же считаются сутки, на которые выписывают женщину. Если женщина родила до 12 часов дня, то эти сутки считаются первыми. Если же она родила после 12 часов дня, то эти сутки считаются нулевыми, а отсчет идет со следующего дня.

Почему выписка после кесарева сечения осуществляется на 5-е сутки? Это понятно, что после кесарева сечения, конечно же, женщине требуется больше дней для восстановления. Первые дни после операции женщина чаще всего находится в реанимационном отделении под наблюдением врачей и требует обезболивания. И лишь на 3-й день она полноценно может начинать ухаживать за ребенком, вставать. За это время мама находится под наблюдением акушеров-гинекологов, ей делают УЗИ, контролируют ее выделения и послеоперационный рубец. После кесарева сечения требуется определенный контроль в отличие от естественных родов.

Всех ли мам и малышей выписывают на 3-и или 5-е сутки обязательно? Нет. Для того чтобы маму и ребенка выписали, должны быть соблюдены определенные условия и критерии. Давайте обсудим их.

Родильницы и новорожденные могут быть выписаны при следующих условиях.

Беременность и роды, первые сутки в послеоперационном периоде протекают без осложнений. 
Ребенок находится на совместном пребывании. На момент выписки с малышом все хорошо, он в удовлетворительном состоянии. Если он хорошо адаптировался к внеутробным условиям существования, нет никаких отклонений, которые требуют госпитализации,  – значит, его можно выписывать.
Родильница обучена принципам грудного вскармливания, в том числе технике правильного прикладывания и расположения новорожденного к груди, также технике сцеживания молока. Также обучена вопросам профилактики трещин сосков и гипогалактии. И, конечно, при условии, что мама обучена вопросам ухода за новорожденным. 
Всему этому вас обучат врачи, медсестры еще в роддоме за время вашего пребывания там. И вы также не стесняйтесь задавать свои вопросы, записывайте их в блокнот или на телефон, спрашивайте. Ведь пребывание в роддоме – идеальное время, чтобы узнать все от компетентных специалистов.

Родильница ознакомлена с гигиеной послеродового периода, а также с признаками возможных осложнений послеродового периода. Также важно, чтобы мама была информирована об учреждениях здравоохранения, куда необходимо обратиться в случае возникновения каких-либо осложнений. Например, у женщины может развиться мастит или имеются какие-то выделения или нагноения послеродового рубца и т.д.
Новорожденные должны быть обследованы на такие врожденные заболевания, как фенилкетонурия, гипотиреоз и муковисцидоз. Материалом для исследования является капиллярная кровь. Ее берут из пальца традиционным способом у доношенных детей на 3-и сутки жизни, у недоношенных – на 7-10-й день жизни.
Критерии готовности новорожденного к выписке из роддома: ребенок должен быть в удовлетворительном состоянии, иметь хороший сосательный  рефлекс, быть активным. Стабильность жизненно важных показателей оценивается по таким параметрам, как частота дыхания, частота сердечных сокращений. Очень важна температурная реакция ребенка. В норме температура у ребенка должна быть 36,5-37,5 °С. Также должна быть оценена  регулярность мочеиспусканий, наличие стула. Врач также смотрит рефлексы у новорожденного ребенка и оценивает его неврологический статус.

Конечно же, еще один важный критерий – это прибавка в весе. В первые дни жизни у новорожденных детей происходит физиологическая потеря массы тела и она должна быть не более 10% от массы при рождении. Также ребенку выполняется неонатальный метаболический скрининг. Это в тех случаях, если у ребенка развивается желтуха. Она может быть физиологической или патологической, поэтому врач оценивает уровень билирубина в крови, чтобы исключить патологическую гипербилирубинемию. Также проводится отоакустическая эмиссия для оценки слуха, или аудиоскрининг, и запись ЭКГ. Про эти исследования мы будем рассказывать вам в отдельном курсе более детально и подробно. 

Ранняя выписка активно приветствуется в связи с тем, что роды – это физиологический процесс. И если женщина в послеродовом периоде восстанавливается хорошо, чувствует себя удовлетворительно – длительное пребывание ее и ее ребенка в родильном доме смысла не имеет. Главное – женщине все вовремя объяснить, чтобы сама женщина была в команде с врачами и чувствовала ответственность за свое здоровье и здоровье малыша. Тем более всегда есть  риск внутрибольничного инфицирования как женщины, так и ребенка. Поэтому чем раньше маму и малыша выпишут, тем эти риски ниже. Самый лучший вариант – чтобы за ребенком ухаживала мама и как можно меньше персонал. Это и есть самая простейшая профилактика внутрибольничных инфекций.

${separator}

Урок 2. Критерии выписки ребенка

Вы находитесь в роддоме, и вот настали те самые 3-и или 5-е сутки, когда вас должны выписать. Что же произойдет в этот день? Как вы точно узнаете, что вас выпишут?

Утром в день выписки к вам приходит неонатолог, и в первую очередь он осматривает ребенка. Во время нахождения в родильном доме ребенок обследуется. Оценивается общий анализ крови, общий анализ мочи, при необходимости биохимический анализ. Если там есть отклонения, то ребенка обследуют более детально. 

Как проводится инструментальный скрининг? Как правило, он включать может УЗИ головного мозга, УЗИ брюшной полости, УЗИ сердца (если есть подозрения на порок сердца), а также проводится аудиоскрининг (проверка слуха) и ЭКГ в течение первых 3 суток жизни. 

Обязательно оценивается масса тела. Очень хорошо, когда в день выписки наблюдается уже положительная прибавка после физиологической потери. В норме малыш после рождения может потерять в весе. Не пугайтесь, потеря массы физиологическая до 10 % массы тела от рождения. И в начале ребенок теряет, а потом начинает восстанавливать свою массу. Если врач видит положительную прибавку, он делает вывод о том, что малыш получает достаточное количество молока. Врач оценивает также такие клинические показатели, как цвет кожных покровов, рефлексы новорожденного, неврологический статус, и обязательно оставляет рекомендации для врача-педиатра в ту поликлинику, куда ребенок поступит  по месту жительства под наблюдение. Он обязательно также пропишет эти рекомендации в специальном документе (сведения родильного дома о новорожденном).

Также врач дает индивидуально рекомендации маме по ребенку в случае каких-либо изменений со стороны кожи. Например, если ребенок выписывается с  желтухой, тогда маме даются рекомендации, как наблюдать за малышом, ухаживать и бить тревогу, если ребенок будет сильно желтеть. При необходимости малышу назначают препараты по снижению билирубина.   

Также бывают ситуации, когда ребенка не выписывают. Давайте разберемся, по каким причинам пребывание в роддоме может затянуться.

Если у ребенка имеются отклонения в клиническом статусе (повышение температуры, одышка и другие симптомы патологические). 
Если ребенок плохо набирает вес, происходит потеря массы тела, которая становится уже патологической.
У малыша могут иметься лабораторные отклонения. В течение пребывания в роддоме ребенок обследуется, ему делают анализы, и неонатолог смотрит за показателями, чтобы они не выходили за рамки нормальных значений.
Если ребенок родился преждевременно. В этих случаях, конечно, ребенка не будут спешить выписывать. Маме объясняют, как поступать дальше. В частности, обследования могут провести повторно с последующим ожиданием показателей нормы. При необходимости малышу могут назначить лечение, при потере массы тела могут порекомендовать использовать докорм, если это желтуха, то ребенка кладут на фототерапию и добавляют инфузионную терапию при необходимости.
Если у ребенка имеются признаки инфекции, то выписка откладывается, и ребенок переводится на второй этап выхаживания, где он получит уход и лечение. При необходимости маме могут порекомендовать взять напрокат электронные весы, чтобы наблюдать за динамикой массы тела дома в течение первого месяца. Это позволит с легкостью контролировать набор или потерю в весе ребенка. 
В случае если у вас возникли трудности или вопросы, их лучше обсудить с врачом сразу. При необходимости доктор может назначить докорм.

Пограничные состояния новорожденных

Когда ребенок адаптируется к внеутробного существованию, то первые несколько дней у него могут иметься пограничные состояния или он проходит этап адаптации органов и систем к внеутробной жизни. Эти отклонения могут испугать молодую маму, поэтому мы обсудим некоторые часто встречаемые состояния с вами, чтобы вы были готовы и спокойны, если увидите такие особенности у вашего малыша.

У ребенка может быть тахикардия, или учащенное дыхание в первые часы жизни транзиторное. Это может пройти. Также у малыша бывают повышение температуры (или транзиторная лихорадка) или понижение температуры, если он переохладился. Так как у ребенка процессы регуляции  температуры несовершенны, за температурой надо следить. Если ребенок недостаточно сосет и получает мало жидкости, то в таких случаях может развиваться транзиторная лихорадка у ребенка (повышение температуры тела).

Может быть пограничное состояние в виде гормонального криза – у ребенка имеются нагрубания  грудных желез. Это абсолютно нормальное явление, пугаться его не надо. Ситуация требует просто наблюдения. Также могут наблюдаться изменения со стороны кожи. Это могут быть шелушения кожи или акне новорожденных (белесоватые высыпания в области лица и на носике).

Не забывайте также о физиологической желтухе. Неонатолог обязательно проконтролирует биохимический анализ крови вашего ребенка для определения уровня билирубина. Если он выходит за рамки нормальных значений, то ребенку необходимо лечение. Значит, это уже не адаптация, а это уже патологическое состояние.   

Раздельная выписка мамы и малыша

Это происходит в тех случаях, когда у нас преждевременные роды, и, конечно, недоношенный ребенок остается в клинике, а маму выписывают через какое-то время домой. После чего она может госпитализироваться позже к ребенку.

В редких случаях, если мама в тяжелом состоянии, отец может забрать ребенка. Но для этого должны быть все необходимые официальные документы, подтверждающие отцовство или опекунство.

Не стоит пугаться того, что ребенка переводят из роддома в другую клинику или порой в больницу другого города. Все это направлено на то, чтобы оказать малышу наилучшую поддержку и помощь. Иногда это узкоспециализированное, технически более оснащенное учреждение. И, как правило, перевод осуществляется реанимационной бригадой.

Можно ли передать ребенку в сложившейся ситуации молозиво или молоко? Подобная практика у нас начинает только внедряться. Мамам разрешено приносить грудное молоко и сцеживать его в отделении, и передавать в отделение ребенку.

Если же ребенок находится в тяжелом состоянии в отделении реанимации или требует особой терапии, маму госпитализируют по возможности. Но как только он требует ухода, то сразу же организуется совместное пребывание и маму кладут к малышу.

Помните, что медицинский персонал не остается безучастным. И даже если вы пока находитесь не рядом со своим ребенком, можно позвонить в отделение и всегда получить необходимую информацию о состоянии и самочувствии малыша.

${tb3c5_2}

Урок 3. Критерии выписки мамы

Для того чтобы мы вас выписали домой, должны сойтись во мнениях специалисты, которые отвечают за здоровье мамы, и специалисты, которые отвечают за здоровье малыша (т.е. врачи акушеры-гинекологи и врачи-неонатологи).

На что обращает внимание врач акушер-гинеколог, когда принимает решение о выписке? На ваше состояние в целом. Насколько вы адаптировались к материнству, насколько вы стабильны психологически, насколько вы можете осуществлять уход за своим малышом. 

Если мы видим, что психологически все стабильно, мама в хорошем настроении, она хорошо ухаживает за ребенком, ей все понятно – мы углубляемся дальше. Мы оцениваем следующие вопросы:

грудное вскармливание (как мама справляется с молочной железой, есть ли у нее хорошо отлаженный навык кормления, прибыло ли молоко, нет ли каких-то уплотнений, нет ли температуры, насколько ребенок хорошо берет грудь, как ребенок набирает в весе) 
состояние матки. Мы обращаем внимание, хорошо ли она сократилась. Нам важно, чтобы матка начала возвращаться к исходному размеру. Есть такое понятие, как высота стояния дна матки. Мы оцениваем высоту стояния дна матки (она в норме должна быть определенных размеров), и в некоторых ситуациях можем назначить дополнительно УЗИ.
анализы (общий анализ крови, общий анализ мочи). Если у врача есть какие-то вопросы, то у женщины берут дополнительные анализы. Например, если у вас было кесарево сечение, это будет биохимический анализ крови, коагулограмма. 
состояние шва. Если у вас есть швы после операции кесарево сечение, либо после эпизиотомии, либо разрыва промежности, нам важно, чтобы там хорошо происходило первичное заживление.
Бывают ситуации, когда по анализам почти все хорошо, но есть некоторые показатели, требующие назначения препаратов домой. Чаще всего это связано с наличием анемии. Вам могут дать рецепты на препараты, которые вы будете принимать дома.

Не стесняйтесь задавать свои вопросы. Самое важное время и максимальное время по возможности задавать вопросы врачу акушеру-гинекологу и врачу-неонатологу – это в первые дни после родов, пока вы находитесь в роддоме.

Если с грудным вскармливанием все хорошо, с маткой все хорошо, с анализами все хорошо, с состоянием мамы, в том числе ментальным здоровьем, все хорошо – мы говорим о том, что мы можем маму отпускать домой.

Выписка в родильном доме осуществляется и в выходные дни.

${separator}

Урок 4.  День выписки: шаги, советы, лайфхаки

Итак, наступил день выписки. Врач акушер-гинеколог и врач-неонатолог дают вам «добро» на выписку, и вы можете уже приступать к сборам. 

Вы выписываетесь из роддома с вещами, которые необходимы малышу и которые необходимы вам. И, как правило, привозит это кто-то из родных накануне выписки или в день выписки. Советуем вам заранее составить список этих вещей и обсудить его с родными.

В этом уроке мы поговорим, что же из вещей должно быть у вас и когда лучше его привезти. Также разберемся, как поэтапно проходит сам процесс выписки.

В роддомах выписка происходит после обеда. Как мы обсуждали ранее в уроках, предварительно врач-неонатолог осматривает новорожденного, оценивает его готовность к выписке. У врачей есть время и возможность оформить выписку и дать рекомендации вам и вашему участковому педиатру. В некоторых случаях малышу могут назначить контрольные анализы, чтобы быть абсолютно спокойными за его состояние. Их результаты будут готовы уже к обеду. К этому времени врач приходит в палату и сообщает вам, что вас выписывают. Можно звонить своим родным и приглашать их на выписку.

Партнер, или новоиспеченный папа, или кто-либо из родственников может привезти вещи для мамы и малыша в день выписки примерно за час. Также папа может привезти вещи накануне вечером или за день до выписки, и мама может комфортно собраться. В день выписки у роддома не будет скопления большого количества новоиспеченных пап.

Одежда на выписку

Итак, какие же вещи вам привезут. Самое важное – это одежда для новорожденного, подгузники. Все вещи советуем постирать, проутюжить. Не забывайте, что вся одежда, которая соприкасается с телом ребенка должна быть из гипоаллергенных материалов. Также вещи должны легко и быстро сниматься.

При выборе одежды на выписку обязательно нужно учитывать время года. Летом лучше надеть на малыша шапочку, легкий трикотажный комбинезон или боди с коротким или длинным рукавом, а после завернуть в тонкую пеленку. Советуем защитить голову новорожденного чепчиком, даже если день кажется теплым и безветренным. 

В межсезонье отлично подойдут такие комбинации, как слип с комбинезоном, боди с коротким рукавом + слип с длинным рукавом. Наверх стоит надеть теплый флисовый или плюшевый комбинезон или конверт. Если это поздняя осень или ранняя весна – выбирайте демисезонный комбинезон. 

Также надеваем царапки для ручек, носочки и два головных убора (трикотажный чепчик и утепленная шапочка сверху). Рекомендуем выбирать шапочки из синтетических материалов, чтобы избежать возможное развитие аллергии на натуральную шерсть.

Для зимней выписки можно использовать ту же комбинацию вещей, добавив к ним конверт на овчине или комбинезон-трансформер. Также не забудьте про теплые шерстяные носочки, если комбинезон с открытыми ножками.Приветствуется в роддомах и ленточка для конверта – это своего рода традиция. 

Помните, что выписка – это не прогулка, ребенка нужно всего лишь донести до автомобиля. Важно, чтобы малышу было не жарко. Не забудьте про автокресло – оно нужно обязательно. 

Не менее актуален и вопрос, какая же одежда пригодится женщине. Что на выписку привезти ей? Дорогие мамы, помните о том, что вы вряд ли будете выписываться в том, в чем приехали в роддом. Лучше заранее показать мужу, какую одежду и косметику вам нужно будет привезти. Отмечу, что в роддоме нельзя пользоваться утюгом. Поэтому советуем заранее обсудить это со своими родными. Будет здорово, если ваши вещи и вещи малыша будут подготовлены заранее, постираны и отутюжены.

Начну с того, о чем чаще всего забывают, – это обувь. Рекомендуем брать на выписку пару обуви, которая на полразмера или размер больше, потому как после родов женщина еще не пришла в форму и комфорт в этот период превыше всего.

Еще одна очень важная вещь для выписки – это бюстгальтер, рассчитанный на кормящую женщину. Он должен удобно расстегиваться и застегиваться, чтобы была возможность без труда покормить ребенка. Желательно, чтобы он был из хлопчатобумажной ткани.

Перед тем, как женщина пойдет получать поздравления от родных и близких, ребенка желательно покормить и надеть чистый подгузник. А одежду на выписку малышу наденет медсестра в выписной комнате.

Выписная комната

Итак, к женщине приходит доктор или акушерка, отдает ей выписку и говорит, что они могут ехать домой. И женщина начинает собираться. В момент, когда за ней приезжают родственники, она сообщает об этом своей детской медсестре, которая берет документы, ребеночка и сопровождает маму в выписную комнату. К этому моменту женщина уже готова, накрашена, остается только переодеть малыша – это в буквальном смысле в руках медицинской сестры. Пакеты с вещами маме передаются заранее.

Как правило, выписные комнаты расположены на первом этаже. И это комната, в которой ребенка переодевают и отдают маме и папе, чтобы они его забрали домой.  

Даже если вам привезли вещи для выписки в последний момент, вы тоже можете переодеться в выписной комнате. В этой же комнате надевается вся верхняя одежда.

Вы можете пригласить на выписку родственников, друзей, фотографа. Более того, во многих роддомах работают профессиональные фотографы, которых можно попросить запечатлеть такой замечательный момент в вашей  жизни, как выписка.

${tb3c5_3}

Урок 5. Документы после выписки

В волнительный день выписки маме важно не растеряться, ведь вместе с заветным конвертом ей выдадут и несколько важных документов. Давайте разберемся, что получит мама на руки и что с этими документами необходимо  сделать в дальнейшем.

Итак, мама увозит с собой из роддома несколько документов. 

Сведения родильного дома о родильнице
Там подробно описан ваш диагноз; сколько длились периоды ваших родов; описаны препараты, которые вводили в родах; описано, как шел послеродовой период; а также там есть данные вашего последнего УЗИ и последних анализов. Этот документ можно даже сфотографировать себе. Он может пригодиться врачу, который будет вести ваши последующие беременность и роды. 

Что делать с этим документом после выписки? Его нужно передать в женскую консультацию по месту жительства. Вам дадут справку, чтобы вы получили специальное пособие о постановке на учет до 12 недель.

Сведения родильного дома о новорожденном (обменная карта новорожденного) 
Там прописана информация о родах, о малыше, его весе, длине при рождении, прививках. А также зафиксирована вся информация о том, как протекал послеродовый период у ребенка, анализы, лабораторно-инструментальные исследования, которые были проведены ребенку в родильном доме, а также клинический статус с диагнозом. Кроме этого, выделяется группа риска ребенка по развитию какой-либо патологии. Все это делается для участкового педиатра. Согласно этой информации он ребенка будет наблюдать и обследовать.  

Что делать с этим документом? Отдать его врачу, который придет к вам домой после выписки из поликлиники. Самим нести его в поликлинику не нужно. Педиатр должен посетить ребенка на дому в первые 3 дня после выписки. И в первые 3 дня должна также прийти участковая медсестра. Кто приходит первый, тому мама и отдает этот документ о новорожденном. А ребенку, в свою очередь, заводят уже амбулаторную карту в поликлинике. Это будет первый документ в медицинской истории вашего малыша. 

Также на 2-е сутки жизни новорожденному в роддоме сделают запись ЭКГ, проведут проверку слуха (аудиоскрининг), и в некоторых роддомах практикуется УЗИ головного мозга. Всю информацию об исследованиях в последующем также передадут в обменной картой в детскую поликлинику по месту жительства. 

Справка о рождении
Если ее вы отнесете в ЗАГС, вы обменяете ее на документ свидетельство о рождении ребенка. Зарегистрировать ребенка можно в ЗАГСе по месту его рождения или по месту жительства матери или отца. Крайний срок подачи в ЗАГС заявления на регистрацию – 3 месяца со дня рождения ребенка. Максимальный срок выдачи свидетельства о рождении – 3 дня. Также существует процедура торжественной регистрации малыша. Для этого предварительно нужно обратиться в ЗАГС. 

Также справка о рождении ребенка в оригинале пригодится вам при получении пособия в связи с рождением ребенка. Это пособие выплачивается один раз на каждого ребенка. 

Дополнительный больничный лист
Если ваши роды считаются осложненными (например, выполнялось кесарево сечение, переливание крови, сразу после родов было ручное отделение последа или вакуум-экстракция плода), то есть ряд нюансов, согласно которым выдается больничный лист. 

В случае когда во время вашего пребывания в стационаре, вы находитесь в декретном отпуске по другому ребенку, не посещающего детское дошкольное учреждение, может выписываться больничный лист на того человека, который ухаживает за малышом, пока вы были в родильном доме.

Рецепты на препараты
Например, если врачу не понравился ваш гемоглобин, тогда мы рекомендуем вам некоторые препараты железа. Большинство из них выписываются по рецепту. Вам может понадобиться антибактериальная терапия, тогда тоже выдается рецепт на препараты. 

Если говорить о малыше – как правило, из родильного дома не выписывают детей с препаратами, для которых необходимы рецепты. Ведь малыш должен быть в удовлетворительном состоянии. Если ребенок болел и находился на этапе выхаживания на стационарном лечении, а также если ребенок имеет хроническое тяжелое заболевание или пороки развития, иногда выписка может осуществляться с выпиской рецептов.

В некоторых родильных домах при выписке родильнице могут дать памятку с рекомендациями, что делать в случае осложнений, как ухаживать за ребенком, какие есть особенности по грудному вскармливанию. Такую памятку также важно изучить и хранить под рукой. Во многих роддомах такую полезную информацию можно найти на информационных стендах. И помните, что ответы на все вопросы вы можете получить в первую очередь у врачей.

Итак, все документы собраны, малыш в конверте на руках у счастливых родителей, и теперь пришло время ехать домой. В следующем уроке обсудим, что нужно подготовить к приезду малыша домой, чтобы ваши первые дни пребывания дома были максимально спокойными и счастливыми.

${tb3c5_4}

Урок 6. Встреча новорожденного дома

Первые дни вместе в стенах дома не менее волнительные, чем первые дни в роддоме. И чтобы новоиспеченным маме и папе было спокойно и комфортно, важно заранее подготовить все необходимые вещи для ребенка.

Совет 1. Проведите уборку квартиры. 

Перед тем, как в квартире появится малыш, рекомендуется сделать генеральную уборку. Но если такой возможности нет, порядок и чистоту навести в комнатах, где будут спать новорожденный ребенок и его родители. Рекомендуем стереть пыль со всех шкафов и полок, потому что она может вызывать аллергию у ребенка. Также советуем протереть влажной тряпкой мягкую мебель, очистить ковры, вымыть полы во всех комнатах. 

В доме могут быть домашние животные. Первое, на что стоит обратить внимание, – это отношение этого самого животного к ребенку. В первое время стоит быть осторожными, понять реакцию питомца на нового члена семьи, потому как иногда питомец проявляет ревность и агрессию к новому члену семьи. В таких случаях рекомендуется рассматривать ограничение общения с животным вплоть до поиска для него нового дома.

Если это собака, то она должна быть дрессирована и спокойно относиться к новому домочадцу. Лучше, чтобы питомец не лез в кроватку к ребенку, не облизывал его. Коты замечены за тем, что любят спать в переносках, кроватках, колясках. Особенно в первый период появления в доме малыша постарайтесь не допускать подобного. Лучше, чтобы у него было свое пространство, а у ребенка – свое. И, конечно, желательно, чтобы домашнее животное было привито. 

Совет 2. Организуйте спальное место для новорожденного. 

Будет здорово, если к приезду ребенка вы успеете собрать кроватку и выберете для нее идеальное место. Это не так просто, ведь в голове нужно держать ряд моментов, лучше не ставить кроватку у окна, чтобы новорожденного не продуло. Также лучше не ставить кроватку около батарей в холодное время. Дело в том, что у младенцев очень слабая система терморегуляции, и у горячей батареи он может перегреться. Лучше поставить кроватку у родительской кровати или у стены. 

Матрасы, бортики, навесные карусели – каждый покупает в меру своих желаний. Но важно помнить о том, что лучшим вариантом будет, чтобы в кроватке находился только малыш. Подушки ни большие, ни маленькие детям не нужны. Одеяло необходимо, но лучше выбирайте легкое, чтобы ребенок не перегревался. 

Совет 3. Купите и постирайте одежду и белье для ребенка. 

Одежду, постельное белье необходимо постирать с детским порошком или детским мылом и тщательно прополоскать. После стирки рекомендуется прогладить вещи утюгом. 

С учетом того, что в последнее время специалисты отходят от рекомендации пеленать детей, все более чаще незаменимыми вещами в гардеробе малыша становятся боди и слипы. Так как активно используются подгузники, большого количества вещей не требуется, ведь они будут пачкаться гораздо реже, чем это было раньше.

${tb3c5_5}

Одежда для малыша

Какая нужна и в каком количестве? Как правило, это могут быть: 

3-5 боди с разной длиной рукава; 
3 теплых и 3 тонких распашонки; 
несколько тонких ползунков, 2 пары теплых; 
1-2 теплых слипа, 2-3 тоненьких; 
2 теплых чепчика, 2-3 тонких; 
пара теплых и тонких носочков; 
2-3 слюнявчика из махровой ткани; 
пара антицарапок;
шапочка по сезону; 
комбинезон или конверт для прогулки по сезону; 
и, конечно, подгузники. Маленькой упаковки вполне хватит на первое время, чтобы проверить, подходят ли они ребенку, нет ли раздражения и аллергии на них.
Для удобства новорожденного лучше отдавать предпочтение вещам, которые могут одеваться через голову. Из застежек лучше кнопки, чем пуговицы. Одежду выбирайте из натуральных тканей.

Совет 4. Проверьте аптечку. 

С первых дней малышу могут понадобиться медикаменты для обработки пупочной ранки, если в процессе ее заживления что-то пошло не так. Пусть под рукой в аптечке будут перекись водорода, зеленка, пипетки, антисептик, вата, ватные палочки. Ватные палочки и диски также пригодятся вам во время гигиены новорожденного. Обязательно посмотрите большой курс по гигиене малыша. Там вам подробно расскажут, как осуществляется ежедневный уход.

Совет 5. Подготовьте различные аксессуары. 

Будет здорово, если вы подготовите для малыша ванночку. Можно приобрести увлажнитель воздуха (особенно это актуально в зимний период при работающих батареях). Удобным для мамы будет пеленальный столик, где можно будет поставить необходимые средства для гигиены, подгузники, которые всегда будут под рукой. Также с первых дней могут понадобиться термометр для ванны, аспиратор для очистки носа.

А напоследок совет папам – для облегчения жизни вы можете заранее купить запас продуктов на первые дни, чтобы они лежали в холодильнике. Также вы можете купить запас самых необходимых вещей (пеленки, подгузники), которые будут нужны вам каждый день для полноценного ухода за малышом в первые дни после родов. Такой небольшой запас поможет вам спокойно и комфортно проводить первые дни дома.

А как же обычно проходят те самые первые дни? Чаще всего дети едят и спят. Но иногда появляются периоды колик, и тогда родители начинают не на шутку волноваться. Первое, что стоит исключить, – нехватку молока. Это может быть одна из причин, по которой малыш может часто плакать и просить есть.

Постарайтесь выспаться, прийти в себя, не браться за уборку и готовку сразу, соблюдать правила послеродового восстановления, беречь себя, налаживать грудное кормление. 

Также в считанные дни после выписки вас ждет первый визит педиатра на дом. Он осмотрит ребенка, расскажет о дальнейшем своем приходе, даст свои рекомендации и расскажет, когда вам нужно будет впервые появиться у него на приеме уже в поликлинике. И ему вы можете задать все волнующие вас вопросы о состоянии вашего ребенка, о гигиене и уходе.
    `,
};
