import { FC } from 'react';
import styles from './ProfileTitle.module.css';
import { Title } from 'components/Text/Title/Title';
import { useTranslate } from 'hooks/useTranslate';

export const ProfileTitle: FC = () => {
  const t = useTranslate();

  return (
    <div className={styles.container}>
      <Title text={t('profile_title_page')} center />
    </div>
  );
};
