import { useMobileScroll } from 'hooks/useMobileScroll';
import { useTranslate } from 'hooks/useTranslate';
import { useUser } from 'hooks/useUser';
import { coursesData } from 'pages/StudyingPage/Blocks.utils';
import {
  checkIsCourseHasTests,
  getNextCourseLink,
} from 'pages/TestPage/TestPage.utils';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { checkIsWebView } from 'utils/other';
import './TextCoursePage.style.css';

const replaceTextWithImgSrc = (text: string, regex: RegExp) => {
  const replacedText = text.replace(regex, (match) => {
    return `<p style="display:flex;justify-content:center;"><img style="max-width:80%;" src=${match} alt=${match} loading="lazy"
    /></p>`;
  });
  return { __html: replacedText };
};

const makeBoldText = (text: string, regex: RegExp) => {
  const replacedText = text.replace(regex, (match) => {
    return `<h3>${match}</h3>`;
  });
  return replacedText;
};

function TextCoursePageExt() {
  const { course, block } = useParams();
  const t = useTranslate();

  const regex = /\/static\/[^'"\s]+\.(?:jpe?g|png)/gi;
  const courses = coursesData[block!][course!];
  const empty = !checkIsCourseHasTests(block, course);
  const [isMobile, scrollRef] = useMobileScroll();
  const nextLink = getNextCourseLink(block!, course!);
  const { user } = useUser();
  useEffect(() => {
    if (isMobile && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isMobile]);

  const isWebView = checkIsWebView();
  return (
    <>
      <div className="text_page" style={{ marginLeft: 40, marginRight: 40 }}>
        <div style={{ height: 20 }} />
        <h3 ref={scrollRef}>
          {t('course')} «{t(`${block}_${course}_title`)}»
        </h3>
        <h4>{t(`${block}_${course}_description`)}</h4>

        {t(`${block}_${course}_text`)
          .split('\n')
          .map((line, i) => {
            const replaced = replaceTextWithImgSrc(
              makeBoldText(line, /^Урок\s\d+\.\s.+/gi),
              regex
            );
            return <p key={i} dangerouslySetInnerHTML={replaced} />;
          })}
      </div>
    </>
  );
}

export default TextCoursePageExt;
