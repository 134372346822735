import { WinkSmile } from 'assets/icons';
import NextButton from 'features/Auth/Onboarding/components/NextButton/NextButton';
import { useTranslate } from 'hooks/useTranslate';
import { AppRoutes } from 'navigation/constants';
import { useNavigate } from 'react-router-dom';
import styles from './MainPageEmptyDate.module.css';

export function MainPageEmptyDate() {
  const t = useTranslate();
  const navigate = useNavigate();

  const handleGoToProfile = () => {
    navigate(AppRoutes.PROFILE_SCREEN);
  };
  return (
    <div className={styles.emptyWrapper}>
      <div className={styles.container}>
        <div className={styles.icon}>
          <WinkSmile />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.infoContainer}>
            <p className={styles.title}>{t('empty_title')}</p>
            <p className={styles.subtitle}>{t('empty_home_page')}</p>
          </div>

          <NextButton
            title={t('empty_home_go_to_profile')}
            onClick={handleGoToProfile}
          />
        </div>
      </div>
    </div>
  );
}
