import { separator, tb7c4_1, tb7c4_2 } from 'assets/images';

export const b7_c24_text = {
  b7_c4_title: `Курс «Потеря ребенка» `,
  b7_c4_description: `Спикер: Мария Вашкевич-Василевская, психолог, перинатальный психолог`,
  b7_c24_text: `
    ${tb7c4_1}
    
    Урок 1. Что такое горе и для чего оно нужно?

Этот курс посвящен травме потери ребенка и тому, как ее пережить. Поэтому я очень рекомендую всем быть аккуратными при изучении этого курса.

Кому рекомендуется изучить этот курс:

если у вас осложненная беременность
есть риски невынашивания
Кому не рекомендуется изучать этот курс:

если у вас все хорошо
если есть прогнозы, что все хорошо и закончится и будут благополучные роды
В этом уроке поговорим про горе – то есть про то, как мы переживаем потерю.

Что такое горе, либо печаль? Это эмоция. Как любая эмоция, она сопровождается определенными ощущениями. У печали есть свои функции – она помогает нам на психологическом уровне пережить потерю. Печаль, или горе, всегда связаны с потерей. Иногда мы печалимся, что не поехали в отпуск. Иногда мы печалимся, что все сложилось не так, как нам хотелось. Или мы печалимся, что что-то сломалось или мы потеряли какую-то любимую вещь. И мы горюем (это печаль более интенсивная), если мы, например, расстаемся с кем-то либо или кого-то теряем. Мы будем печалиться, если умирают наши близкие, и мы будем печалиться, если умирают наши дети. 

Если мы говорим про наших родителей, то это некоторая часть нашей реальности. Мы более подготовлены и понимаем, что человек рождается и умирает в свое время в преклонном возрасте. Но если умирают дети – наша психика справляется хуже. И поэтому нам вдвойне тяжелее, если что-то случается с нашими детьми, рожденными либо еще не рожденными.

Реакция на горе

Когда мы испытываем горе, у нас есть, как минимум, 2 способа реагировать на него.

Рациональный способ
Эти люди начинают думать, как им дальше жить, начинают заниматься какими-то бытовыми делами, переводят все на рациональный уровень – когда сделать похороны, кого пригласить, что нужно сделать. И это не означает, что они горюют меньше. Просто их способ совладания с горем рациональный. Они пытаются это переосмыслить и перевести на предметный уровень, что им нужно сделать, чтобы жить дальше.

Эмоциональный способ
Такие люди начинают плакать, горевать, говорить о своих эмоциях, страдать. И кажется, что их горе больше. Но мы не можем сравнить 2 способа переживания горя, потому что каждый выбирает тот способ, который ему на данный момент более эффективен. 

Часто именно с этими стратегиями связано непонимание. Например, если женщина использует стратегию совладания рациональную, это может порицаться окружением, будто женщина совсем не переживает. На самом деле, она может очень глубоко переживать потерю и на глубоком уровне быть травмирована событием потери ребенка. Но она старается быть рациональной, ей на данный момент это более эффективно. Или, например, мужчина начинает плакать, не находит себе места, не может работать, потому что его ребенок умер. И его начинают уверять: «Соберись, что ты плачешь и так эмоционально реагируешь!».

Есть есть некоторые стереотипные способы реагирования, что женщины реагируют более эмоционально, мужчины – более рационально, но это не так. Мы реагируем таким способом, который нам в данный момент лучше помогает справиться с теми переживаниями, которые у нас есть. И мы не можем это сравнить. Невозможно сравнить горе двух родителей, если они об этом не разговаривают. Они могут испытывать одинаковой глубины горе, но если они находятся в контакте, это позволяет им увидеть страдания друг друга и получить поддержку и сопричастность.

У любой эмоции есть 3 формы проявления: мы что-то делаем либо хотим делать, у нас есть какие-то мысли и мы переживаем что-то на физическом уровне, на уровне ощущений. 

На уровне ощущений горе, либо печаль, – это апатия. Мы чувствуем некоторую расслабленность, усталость, тяжесть. Нам сложно двигаться либо не хочется двигаться, мы можем перестать хотеть есть либо у нас может ухудшиться сон. И вот эта вялость, апатичность будет еще больше ухудшать наше состояние.

Что происходит на уровне мыслей? Мы начинаем думать о бессмысленности. О том, как это все тяжело, невыносимо. Возможно, мы начинаем думать о том, что никогда не будет, как раньше, и наше будущее становится мрачным. Мы можем думать, что мы в чем-то виноваты. Либо мы начинаем думать, что никто нас никогда не поймет. 

Что происходит на уровне действий? Нам хочется куда-то скрыться, нас никто не видел, либо мы ничего не хотим делать, либо нам все время хочется плакать, либо мы начинаем вспоминать какие-то хорошие моменты, хвататься за них и пытаться уйти от своих переживаний.

Горе может проявляться по-разному. Все зависит от той стратегии, которую вы выбрали. Но что неизменно – это тяжелое чувство, которое слегка приглушает все остальное. Как будто мы теряем краски мира, в котором мы живем. И несмотря на всю тяжесть этого переживания, оно нормально. Если мы кого-то теряем, это нормально печалиться и грустить. Нормально переживать горе, если вы потеряли ребенка.

Важный момент: эти ощущения должны менять свою интенсивность. Если проходит 1-3 месяца, а интенсивность не теряется, вы по-прежнему сильно горюете, все время плачете, вы начали видеть ночные кошмары, вам приходят какие-то воспоминания, случайные фразы заставляют вас плакать – тогда, возможно, вы находитесь в переживании осложненного горя, и тогда вам нужна помощь специалистов.

Если со временем ощущения ослабевают – это не значит, что вы перестали переживать, что вы забыли своего ребенка. Если вам становится легче, вы больше выходите на контакт с окружающими – значит, вы переживаете свое горе нормально, и оно закончится. Для вас всегда это будет вашим воспоминанием. Вы будете жить полной жизнью, но без ребенка, без тех ожиданий, которые у вас были до этого.

Советы для родных

говорить, что вы рядом
быть рядом
быть готовыми слушать, потому что горе любит сопереживания 
Мы быстрее переживаем горе, если нас слушают. Это не означает, что горюющие мама и папа, которые лишились ребенка, будут готовы говорить сразу. Возможно, они будут готовы говорить через некоторое время, но вы должны быть готовы слушать. 

Представим ситуацию: например, вы папа и вы находитесь отдельно от мамы, которая находится в роддоме и только что пережила потерю ребенка. Вас еще не допускают к маме – и вам важно находиться в контакте со своей женой по телефону, чтобы женщина чувствовала вас рядом, чтобы вы могли всегда ее выслушать и чтобы она могла выслушать вас.

Горе любит, когда о нем говорят. Тогда оно быстрее заканчивается. Нам важно выговориться о тех чувствах, которые мы испытываем. В обмене эмоциями нет ничего страшного. И помните: у каждого свой срок. Кому-то нужно месяц-два, кому-то нужен год.

${separator}

Урок 2.  Этапы горевания

Горе – это то, что нужно себе позволить прожить, иначе оно будет растянуто во времени. Если мы останавливаем свое горе, пытаемся отвлечься, пытаемся его не чувствовать – это значит, что мы будем чувствовать его гораздо дольше. Если мы сопротивляемся эмоции, она задерживается в нашей психике на более длительное время. Поэтому если случилось горе, если вы потеряли своего ребенка, позвольте себе горевание. Позвольте себе отплакаться, отгоревать, чтобы жить дальше.

Процесс горевания состоит из 5 стадий.

Отрицание
«Все нормально, ничего не произошло. Я хочу, чтобы ничего не произошло. Я хочу закрыть глаза и забыть о том, что это было. Я хочу сделать вид, что все, как раньше. Я не хочу с вами ни разговаривать, ни думать, ни делать ничего в эту сторону. Я не хочу ничего обсуждать, не хочу ничего читать. Я хочу скрыться, спрятаться на неделю, на две и не находиться ни с кем в контакте». Это период отрицания.

Что можно делать, если ваш партнер или вы находитесь в отрицании? Дать этому время. Потому что мы не можем бежать от реальности. Если все-таки смерть случилась, если вы потеряли ребенка, рано или поздно вам придется на это посмотреть. 

Задача этого этапа – это позволить вам войти в реальность и позволить пережить первый шок. Мы избегаем, начинаем прятаться, потому что мы находимся в шоке. И нам нужно какое-то время на то, чтобы осознать событие, что оно произошло. После этого, скорее всего, нам уже нужна будет поддержка и нам нужен будет контакт. Либо, если мы находимся в отрицании, нам важно знать, что рядом есть люди.

Что могут сделать родные, чтобы поддержать горюющего? 

уважать то, что он горюет, что он находится в шоке
быть рядом, оказывать поддержку
Если горюющий выйдет из этапа отрицания – важно, чтобы рядом было хорошее, доброе плечо, добрые глаза. Потому что это начало процесса горевания, и дальше интенсивность горя будет больше. На этапе отрицания мы еще почти не чувствуем. Мы не хотим контактировать с этой реальностью, а потом мы погружаемся в процесс, и нам становится больнее.

Гнев
Мы потеряли кого-то – и в этот момент начинаем искать виноватого. Мы начинаем злиться на врачей, на себя, на партнера. Нас начинает всё раздражать. Возможно, мы начинаем плакать или кричать. Этот период тоже может некоторое время продолжаться. Мы будем много внимания уделять высказыванию своей эмоции, иногда достаточно агрессивно. Мы будем искать виноватого, чтобы каким-то образом свое переживание выместить на нем. Иногда мы будем вымещать свои переживания на себе, опускаясь в самообвинение, но это часть этапа горевания, которую необходимо пройти.

Задача этого этапа – выразить эмоцию максимально. Максимально выразить ту боль, которую мы чувствуем. Возможно, агрессивно, возможно, более мягко (в плаче либо в истериках), но мы должны выплакаться и выкричаться.

Торг 
Это первая попытка переосмыслить события. Наш ребенок умер, мы попытались найти виноватого, каким-то образом поняли, что виноватого прямо сейчас нет либо мы ничего не можем сделать с этой виной, и в этот момент мы начинаем думать, влияли ли мы на события. Может быть, если бы мы что-то сделали иначе либо если что-то бы пошло по-другому, то с ребенком было бы все хорошо.

Задача этого этапа – смерть надежды. Мы надеемся, что все могло бы быть иначе, что все можно изменить – но реальность уже случилась. И наша задача – примириться с тем, что мы горюем, что мы потеряли кого-то очень важного для нас и мы ничего не могли с этим сделать. Ничего нельзя было поменять, надежды нет. Как раньше, уже никогда не будет. И когда мы принимаем это, мы переходим к следующему этапу горевания.

Депрессия
На этапе мы осознаем, что мы потеряли, и что это никогда не вернется, и что реальность такая, какая есть. Мы уходим в состояние некоторой опустошенности. 

Задача этого этапа – это переосмыслить произошедшее и понять, как жить дальше. Чтобы понять, как жить дальше, необходимо быть в состоянии меньшего эмоционального напряжения.

Несмотря на всю тяжесть, глубину, апатию, которую мы переживаем на депрессивном этапе, этот этап очень важен, чтобы максимально безэмоционально либо с тоскою проанализировать свою жизнь и придумать, как адаптироваться, как жить дальше. Важно задать себе вопросы: что я буду делать дальше? Что мне делать с моим партнером? Как мы вместе будем справляться?

Когда этот этап заканчивается, мы переходим к следующему этапу.

Принятие
Мы просто принимаем, что эта боль в нашей жизни есть и всегда будет. Эта потеря всегда будет с нами, но мы продолжаем жить. Этап принятия – это то, когда мы адаптируемся, продолжаем жить, возвращаемся к той жизни, которая была до потери, но с учетом этой потери.

Каждый этап имеет свое значение. Мы можем перескакивать с этапа на этап, это может длиться какое-то время, но все этапы мы должны пройти, для того чтобы адаптироваться и продолжать жить дальше.

${tb7c4_2}

Урок 3. Аборт

Аборт – это искусственное прерывание беременности на сроке до 20 недель.

В обществе есть очень разное отношение к абортам. Есть те, кто говорят, что у женщины есть право распоряжаться своим телом и она свободна делать, что угодно. Есть религиозная точка зрения, которая говорит, что этого делать нельзя. Есть люди, которые против абортов и за то, чтобы жизнь протекала тем способом, которым она хочет протекать.

Я предлагаю сделать шаг назад от этих стереотипов и попробовать относиться к теме абортов более нейтрально. Чтобы очень четко разобрать, что же влияет на этот процесс, и разобрать то, как это повлияет потом на женщину.

Конечно, с медицинской точки зрения есть определенные риски. Об этом вам может рассказать гинеколог. При этом есть исследования, которые показывают, что аборты влияют на психическое благополучие женщины. Может увеличиться риск суицидов, может увеличиться риск тревожных расстройств либо депрессивных.

Обсудим вопрос осознанного принятия решения об аборте. Это решение не стоит оценивать как хорошее либо плохое, потому что оно принадлежит женщине и оно принадлежит паре, которая является родителями этого ребенка. И мы выходим на такое понятие, как репродуктивное давление. Часто решение о том, чтобы рожать или не рожать, принимается против воли пары. Это происходит либо под давлением родительской семьи, либо под давлением стереотипов, либо под давлением каких-то других факторов. Когда, например, женщина боится либо не хочет иметь ребенка, но ей говорят «надо рожать». И ребенок в конечном итоге становится нежеланным. Либо женщина хочет иметь ребенка, но отец ребенка/мама/папа женщины заставляют ее сделать аборт.

Сейчас хочу рассмотреть те аспекты, которые влияют на репродуктивный выбор и на основе которых можно принимать решение. Главное – чтобы это решение было взвешенное. 

Когда мы говорим про репродуктивный выбор, есть несколько аспектов. Есть аспект моих желаний либо желаний отца ребенка: чего мы хотим. Мы хотим сохранить то состояние, в котором мы находимся, либо мы хотим избежать тревоги, что мы не справимся. Либо мы хотим избежать каких-то физиологических осложнений, либо мы готовы/не готовы к определенным осложнениям. 

Если мы оставим в стороне физиологию и моральные аспекты, есть те вещи, которые сама женщина должна учитывать. Это свою собственную готовность рожать/не рожать, отказаться от ребенка/не отказаться от ребенка. А также то, что она будет чувствовать, когда она прервет беременность, либо что она будет чувствовать, если она продолжит свою беременность. Везде есть свои плюсы и минусы. И если вы стали перед репродуктивным выбором – то сделайте упражнение «за и против».

Возьмите лист бумаги, и с одной стороны напишите плюсы того, если вы прервете свою беременность.
На другой стороне листа напишите минусы того, если вы прервете беременность. 
А также пропишите плюсы и минусы того, если вы сохраните беременность. 
Все аспекты, которые лично для вас важны и лично вы учитываете, будут у вас перед глазами. И тогда ваше решение будет более взвешенным. Потому что мы можем быть в эмоциях и упускать рациональные моменты.

Существует ряд медицинских показаний для прерывания беременности, помимо того, что женщина может хотеть или не хотеть ребенка. И эти медицинские показания сами по себе могут являться стрессовым фактором. Например, если ребенок перестал развиваться. Женщина уйдет в процесс горевания, плюс ей придется пережить процесс аборта. Либо если ей говорят, что есть какие-то серьезные генетические заболевания, и она не готова с этим справляться дальше, она не готова вынашивать этого ребенка на таких условиях. Либо, наоборот, она готова вынашивать, и тогда ей нужно подготовиться к некоторым нелегким результатам ее родов. 

Есть также аспекты, когда существует угроза выкидыша, и медики принимают взвешенное решение по прерыванию беременности, потому что ребенка спасти нельзя. Но это тоже аборт. И мы можем все это переживать болезненно, потому что это так или иначе манипуляция. И у нас будут определенные эмоциональные последствия.

И учитывая риски, учитывая то, что аборт может иметь последствия в эмоциональном плане, нам нужно прописать все «за и против» и учитывать все факторы. Если мы принимаем решение «за» – нам также нужно учитывать факторы последствий. Если вы готовы ко всем этим последствиям и работать с ними, то тогда вы совершаете свой репродуктивный выбор.

Очень важно понимать, что аборт – это потеря. Так или иначе, если даже мы по собственной воле либо по велению обстоятельств прерываем беременность, то это все равно потеря, и может запускаться процесс горевания. 

Эмоциональные последствия

Что можно отнести к эмоциональным рискам, к которым необходимо быть готовым в любом случае? 

Чувство вины. Если вы прерываете беременность и это волевой акт (т.е. это произошло не само по себе), то чувство вины может возникать. И для кого-то оно будет выносимым, для кого-то оно будет невыносимым. И необходимо быть готовым, что это может случиться.
Агрессивное состояние либо депрессивное состояние. Это тоже может быть, потому что эти две реакции являются частью переживания горя, и они могут присутствовать в вашей жизни некоторое время после аборта.
Важный момент: поскольку аборт – это вмешательство, то мы можем рассматривать процесс аборта как травму. И иногда так случается, что мы начинаем испытывать те симптомы, которые мы испытываем при травме. 

Если после совершения аборта, после того, как вы потеряли своего ребенка таким способом, у вас:

приходят внезапные воспоминания
вам тяжело
вы не можете сфокусировать свое внимание
у вас бывают приступы агрессии
вам тяжело вспоминать произошедшее
В этом случае вы можете обратиться к психологу за помощью, чтобы справиться с этим состоянием более эффективно и вернуться к нормальной полноценной жизни.

${separator}

Урок 4. Выкидыш / потеря ребенка / отказ от ребенка 

Поговорим про разные виды потери и то, как они могут на нас влиять. 

В первую очередь хочется сказать о том, что обстоятельства имеют значение, но все индивидуально. Например, если вы заранее знали, что у вас есть риски невынашивания, вы могли подготовиться к тому, что потеря может быть. И субъективно она может переживаться проще. Либо вы могли еще не чувствовать шевеление плода, это тоже может переживаться проще. Но в тоже время, если беременность очень желанная и пришла далеко не сразу, и вы теряете беременность на 8-й неделе – это может переживаться как большая трагедия.

Поэтому, с одной стороны, обстоятельства влияют, с другой стороны, они могут восприниматься как некоторые стереотипы. Позвольте себе ту величину горя, которая есть у вас. Если лично для вас потеря ребенка воспринимается трагичной и вам нужно время – позвольте себе это время. Если вам достаточно легко принять, что это случилось – значит, для вас это так. Не нужно обесценивать себя и не нужно думать, что вы слишком слабо либо слишком сильно горююте. У каждого свое горе, потому что у каждого своя картинка обстоятельств потери.

Обсудим то, как окружающие могут реагировать на вас. Например, если вы потеряли ребенка на 8-12 неделе, к вам могут подходить и говорить: «Ну подумаешь, он же был еще маленький». И тогда вы будете чувствовать себя еще хуже – ведь это будто обесценивает ваше горе. На самом деле, чаще всего люди делают это, пытаясь поддержать, но они не знают, что нужно для того, чтобы вас поддерживать. И стоит относиться к этому более спокойно. Ваше горе – это ваше горе. В том объеме, в котором вы его испытываете. И горе чаще всего связано и с потерей самого ребенка, но и с потерей тех ожиданий, которые у нас были.

Если мы ждали, что все будет хорошо, уже закупились принадлежностями, закупили кроватку – и тут на 8-м месяце мы теряем ребенка. Это страшно, тем более что купленная кроватка будет напоминать о потере. Это ваш выбор – оставлять эти предметы или не оставлять. Как вы будете переживать свое горе – это ваш выбор, как вы будете проходить по этапам горевания – это ваш выбор.

Важно помнить, что ребенка теряют двое родителей. Иногда происходит обесценивание горя мужчины. Как будто женщина вынашивала – значит, ее горе больше. В реальности мы не можем оценить величину горя другого человека. И часто, когда женщина горюет по потере ребенка, который был у нее внутри, она отдаляется от партнера. И возникают некоторые риски для отношений, они могут даже разрушаться. Женщина часто не хочет видеть своего партнера, потому что он напоминает о потере. Она боится разделить с ним горе, рассказать ему о том, что чувствует, потому что он не поймет или отвергнет. Но такая же реакция может быть и у мужчины. «Я потерял своего ребенка, и я теперь не хочу видеть свою женщину, потому что она мне напоминает. Мне очень горько и я боюсь показаться слабым, если расскажу об этом». 

Только в контакте мы переживаем горе быстрее. И если партнеры находят силы в себе говорить об этом и находиться в контакте – то у них есть шансы сохранить отношения и углубить их. 

Если же партнеры отдаляются, это приводит к определенным рискам и расставанию. Как минимум, это может привести к эмоциональной отчужденности, которая ухудшает течение горевания для обоих партнеров.

Отказ от ребенка

Также часто происходит обесценивание потери, если ребенок остался жив.

Иногда обстоятельства складываются так, что женщина оставляет ребенка в роддоме. Кажется, если ребенок жив, то и женщина не должна тосковать. А это не так – потому что это все равно потеря ребенка. Да, он будет жив, возможно, он вырастет, возможно, у него будет приемная семья, но его не будет в вашей жизни, и это все равно потеря. И вы также можете проходить все этапы горевания, как и те женщины и мужчины, которые потеряли своего ребенка, потому что он умер. Потому что и в их жизни, и в вашей жизни этого ребенка больше не будет. 

И когда мы переживаем потеряю – мы не можем оценить свою горе, его глубину, но мы хотим от этого горя избавиться. И это нормальное желание. Мы чувствуем боль, и мы хотим, чтобы она прекратилась. При этом если мы, например, начинаем принимать большое количество седативных препаратов, мы можем ухудшить течение горя, потому что мы не даем своему организму с этим справиться.

Важно позволить себе отгоревать столько, сколько необходимо. Важно позволить себе в первые 3 дня свою первичную реакцию на травму потери ребенка. И тогда с большей вероятностью вы справитесь с этим в долгосрочной перспективе. Вы позволите себе пережить это здесь и сейчас и войти в процесс горевания, проходя каждый этап свободно в той мере, в которой это необходимо вашему организму и вашей психике.

Ритуалы прощания

Вам могут помочь некоторые ритуалы. Например, если был достаточно поздний срок и вам отдают тело ребенка, вы можете сделать похороны. Прощание с ребенком (либо если есть возможность дотронуться до ребенка) – с одной стороны, это страшно, потому что вы соприкасаетесь с потерей. Но для психики это хорошо, потому что вы достаточно быстро из этапа отрицания переходите в горевание, и ваш процесс горевания происходит быстрее. 

Если возможности организовать похороны нет, то вы можете сделать себе какой-то ритуал прощания, чтобы помочь своей психике справиться. Вы можете подумать, какие воспоминания вы хотите сохранить. Будут ли храниться УЗИ либо фотографии вас с животом – или вы не будете сохранять эти вещи. Будете ли вы сохранять какие-то памятные предметы, которые вы купили при подготовке к беременности и родам, или вы не будете их сохранять. Это ваше дело.

Ваша задача – сделать так, чтобы ваше переживание горя было максимально комфортным. Следуйте своим желаниям, позволяйте себе переживать горе в той мере, в которой это возможно. Если же вы уходите в очень сильные переживания, невозможные, невыносимые, если вам снятся кошмары, если ваши симптомы со временем не становятся проще, – пожалуйста, обращайтесь к специалисту за помощью.

${separator}

Урок 5. Восстановление после потери

Как же облегчить себе переживание горя и как восстановиться? 

В первую очередь дать себе время. У каждого свой период восстановления после потери. И важно не торопить себя, а дать своему горю сделать работу в вашей психике. Дать возможность действительно подумать, как будет выглядеть жизнь дальше, без ребенка, который ушел из вашей жизни.

Существует понятие, которое называется «уменьшение эмоциональной уязвимости». Это некоторые действия, которые мы делаем, чтобы быть менее чувствительными и более ресурсными. Что же может позволить нам уменьшить свою эмоциональную уязвимость и как-то позаботиться о себе для того, чтобы быстрее восстановиться?

Позвольте себе возвращаться к жизни. 
В первую очередь через маленькие удовольствия. Это может быть что-то связанное с тем, что вам нравилось раньше и приносило позитивные эмоции. 

чашечка кофе
любимая музыка
прогулки
любые другие действия, которые вам раньше нравились и приносили удовольствие
Найдите эти ресурсные действия и начинайте их делать постепенно. Не спешите, дайте себе время и постепенно добавляйте эти события, эти маленькие действия в свою жизнь.

Подумайте о том, какой жизнью хотите жить и что приносит вам позитивные эмоции в долгосрочном плане. 
Например, это будет что-то связанное с работой, отношениями, самореализацией или хобби. Это будет что-то, что демонстрирует ваши ценности, то, что важно для вас в жизни.  

Вернитесь к вещам, которые важны для вас.
Теперь я снова начну ходить в спортзал / поеду на дачу и буду сажать свои любимые розы / снова вернусь к рисованию / снова погружусь в работу. Это будут какие-то глобальные вещи, улучшающие нашу жизнь.

При этом вспоминаем про физиологию. Для уменьшения эмоциональной уязвимости очень важно хорошо спать. Если у вас получается, то лучше спать около 8 часов. Постарайтесь хорошо и регулярно питаться. Если питание плохое, мы становимся более эмоционально уязвимы.

Следующая вещь – это умеренная физическая активность. Она необходима не только для тонуса мышц, но и для того, чтобы все процессы в организме происходили более гармонично и эффективно. 

Старайтесь не злоупотреблять стимуляторами, не пить много кофе либо не употреблять в больших количествах алкоголь, потому что любые стимуляторы влияют на протекание наших эмоций и могут сделать нас более уязвимыми. Будьте умеренны. Позвольте себе заботу о себе и своем здоровье.

Если по каким-то причинам у вас есть какие-то сложности с вашим здоровьем после родов, либо они находились с вами всегда и до родов, и до беременности – уделите им внимание сейчас. Просто практикуйте заботу о себе, чтобы быть менее уязвимыми в отношениях эмоций. И тогда это позволит переживать ваше горе быстрее. Позволит эмоциям работать и совершать работу вашей психики так, как это должно быть, без затяжного эффекта.

Что означает вернуться к жизни? Вернуться к жизни такой, какая была раньше. 

Это восстановление социальной активности, взаимодействия с другими людьми.
Это возвращение к привычным делам, приносящим удовольствие. Возвращение на работу, возвращение к хобби, возвращение к определенному режиму дня, к которому вы привыкли.
Это возвращение к социальным контактам. 
Это нормально, что первое время после потери вам будет хотеться уединения. Это нормально, что вы будете со слезами ходить мимо детских площадок. Это нормальное протекание горя, позвольте ему быть. Но в какой-то момент необходимо будет восстанавливать социальные контакты, чтобы ваша жизнь была полноценной.Нам нужны контакты, чтобы быть счастливыми, и контакты восстанавливают нас. 

Пусть сначала это будут приятные контакты с вашими близкими тогда, когда вы будете к этому готовы. И даже если вы не будете разговаривать о вашей потере – позвольте этому контакту случиться. Будьте рядом, приходите в гости, приходите на какие-то события. Позвольте этому контакту случиться для того, чтобы получить поддержку и обменяться эмоциями. Это крайне важно для восстановления.

Очень важный аспект при восстановлении – это поиск смысла и ресурсов. Если мы продумываем и придумываем смысл произошедшего (как бы  страшно это ни звучало), то нам проще пережить горе.

Психиатр Виктор Франкл говорил о том, что если мы знаем «зачем», мы можем прожить любое «как». Именно так это работает для нашей психики. Если мы находим какой-то высший смысл в происходящем (неважно, что это потеря), если мы нашли смысл, зачем это было нужно в нашей жизни, либо чему мы можем научиться в этой ситуации, либо что помогло нам пережить эту ситуацию – то в этом случае нам легче пережить горе и быстрее вернуться к своей жизни. 

Ресурсы

Что можно назвать ресурсами? Это то, что нам помогает, делает нас более сильными, уверенными. 

Это могут быть конкретные действия, это могут быть конкретные люди, которые вас воодушевляют, поддерживают и помогают вам справляться. 
Это может быть что-то, что дает вам ощущение стабильности. 
Это может быть звонок другу; поход в бассейн; прогулка; творчество.
Вы даже можете сделать список своих ресурсов. И в минуты, когда особенно тяжело, обращайтесь к этому списку и выбирайте что-то, что поможет облегчить вам ваше состояние именно в этот момент.

Тревожные звоночки

В каких случаях стоит обратиться к специалисту или, по крайней мере, задуматься о помощи специалиста? 

Симптомы горя – если тяжесть ваших переживаний со временем усиливается либо остается примерно на одном уровне. 
Вы начинаете видеть кошмарные сны, либо воспоминания приходят к вам внезапно при разных обстоятельствах, и это для вас невыносимо.
У вас длительное подавленное состояние, длительное ощущение тревоги, которое не не уменьшается со временем.
У вас суицидальные мысли. 
К вам приходят мысли о том, что ваша жизнь бессмысленна.
Необходимо обратиться к специалистам за помощью.

Будьте внимательны к себе и к своему партнеру, будьте в контакте. И если вам тяжело и есть ощущение, что вы не можете справиться, тогда обращайтесь к специалисту, и вы получите квалифицированную помощь при проживании вашего горя.
    `,
};
