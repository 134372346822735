import { FC, memo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './ArrowNavButton.module.css';
import { ArrowLeftIcon } from 'assets/icons';
import { isFunction } from 'lodash';

const ArrowNavButton: FC<{
  title: string;
  to?: string;
  cb?: () => void;
}> = memo(({ title, cb, to }) => {
  const navigate = useNavigate();

  const handleNavigate = useCallback(() => {
    isFunction(cb) && cb();
  }, [cb, navigate]);

  return (
    <Link onClick={handleNavigate} className={styles.container} to={to!}>
      <div className={styles.wrapper}>
        <div className={styles.arrowContainer}>
          <ArrowLeftIcon />
        </div>
      </div>
      <div className={styles.title}>{title}</div>
    </Link>
  );
});

export default ArrowNavButton;
