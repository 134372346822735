import {
  b5c16q1,
  b5c16q2,
  b5c16q3,
  b5c17q1,
  b5c17q2,
  b5c17q3,
  b5c18q1,
  b5c18q2,
  b5c18q3,
  b5c19q1,
  b5c19q2,
  b5c19q3,
} from 'assets/images';

export const b5 = {
  c16: {
    title: 'b5_c16_title',
    author: 'b5_c16_author',
    description: 'b5_c16_description',
    lessonsLength: 4,
    video: 'https://vimeo.com/showcase/9263549/embed',
    tests: [
      {
        title: 'b5_c16_tests_title',
        question: 'b5_c16_test1_question',
        answers: {
          a1: 'b5_c16_test1_answer_a1',
          a2: 'b5_c16_test1_answer_a2',
          a3: 'b5_c16_test1_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b5_c16_test1_wrongAnswer',
        correctAnswer: 'b5_c16_test1_correctAnswer',
        image: b5c16q1,
      },
      {
        title: 'b5_c16_tests_title',
        question: 'b5_c16_test2_question',
        answers: {
          a1: 'b5_c16_test2_answer_a1',
          a2: 'b5_c16_test2_answer_a2',
          a3: 'b5_c16_test2_answer_a3',
        },
        correctAnswerId: 3,
        wrongAnswer: 'b5_c16_test2_wrongAnswer',
        correctAnswer: 'b5_c16_test2_correctAnswer',
        image: b5c16q2,
      },
      {
        title: 'b5_c16_tests_title',
        question: 'b5_c16_test3_question',
        answers: {
          a1: 'b5_c16_test3_answer_a1',
          a2: 'b5_c16_test3_answer_a2',
          a3: 'b5_c16_test3_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b5_c16_test3_wrongAnswer',
        correctAnswer: 'b5_c16_test3_correctAnswer',
        image: b5c16q3,
      },
    ],
  },

  c17: {
    title: 'b5_c17_title',
    author: 'b5_c17_author',
    description: 'b5_c17_description',
    lessonsLength: 8,
    video: 'https://vimeo.com/showcase/9263558/embed',
    tests: [
      {
        title: 'b5_c17_tests_title',
        question: 'b5_c17_test1_question',
        answers: {
          a1: 'b5_c17_test1_answer_a1',
          a2: 'b5_c17_test1_answer_a2',
          a3: 'b5_c17_test1_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b5_c17_test1_wrongAnswer',
        correctAnswer: 'b5_c17_test1_correctAnswer',
        image: b5c17q1,
      },
      {
        title: 'b5_c17_tests_title',
        question: 'b5_c17_test2_question',
        answers: {
          a1: 'b5_c17_test2_answer_a1',
          a2: 'b5_c17_test2_answer_a2',
          a3: 'b5_c17_test2_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b5_c17_test2_wrongAnswer',
        correctAnswer: 'b5_c17_test2_correctAnswer',
        image: b5c17q2,
      },
      {
        title: 'b5_c17_tests_title',
        question: 'b5_c17_test3_question',
        answers: {
          a1: 'b5_c17_test3_answer_a1',
          a2: 'b5_c17_test3_answer_a2',
          a3: 'b5_c17_test3_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b5_c17_test3_wrongAnswer',
        correctAnswer: 'b5_c17_test3_correctAnswer',
        image: b5c17q3,
      },
    ],
  },

  c18: {
    title: 'b5_c18_title',
    author: 'b5_c18_author',
    description: 'b5_c18_description',
    lessonsLength: 10,
    video: 'https://vimeo.com/showcase/9263567/embed',
    tests: [
      {
        title: 'b5_c18_tests_title',
        question: 'b5_c18_test1_question',
        answers: {
          a1: 'b5_c18_test1_answer_a1',
          a2: 'b5_c18_test1_answer_a2',
          a3: 'b5_c18_test1_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b5_c18_test1_wrongAnswer',
        correctAnswer: 'b5_c18_test1_correctAnswer',
        image: b5c18q1,
      },
      {
        title: 'b5_c18_tests_title',
        question: 'b5_c18_test2_question',
        answers: {
          a1: 'b5_c18_test2_answer_a1',
          a2: 'b5_c18_test2_answer_a2',
          a3: 'b5_c18_test2_answer_a3',
        },
        correctAnswerId: 3,
        wrongAnswer: 'b5_c18_test2_wrongAnswer',
        correctAnswer: 'b5_c18_test2_correctAnswer',
        image: b5c18q2,
      },
      {
        title: 'b5_c18_tests_title',
        question: 'b5_c18_test3_question',
        answers: {
          a1: 'b5_c18_test3_answer_a1',
          a2: 'b5_c18_test3_answer_a2',
          a3: 'b5_c18_test3_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b5_c18_test3_wrongAnswer',
        correctAnswer: 'b5_c18_test3_correctAnswer',
        image: b5c18q3,
      },
    ],
  },

  c19: {
    title: 'b5_c19_title',
    author: 'b5_c19_author',
    description: 'b5_c19_description',
    lessonsLength: 7,
    last: true,
    video: 'https://vimeo.com/showcase/9263576/embed',
    tests: [
      {
        title: 'b5_c19_tests_title',
        question: 'b5_c19_test1_question',
        answers: {
          a1: 'b5_c19_test1_answer_a1',
          a2: 'b5_c19_test1_answer_a2',
          a3: 'b5_c19_test1_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b5_c19_test1_wrongAnswer',
        correctAnswer: 'b5_c19_test1_correctAnswer',
        image: b5c19q1,
      },
      {
        title: 'b5_c19_tests_title',
        question: 'b5_c19_test2_question',
        answers: {
          a1: 'b5_c19_test2_answer_a1',
          a2: 'b5_c19_test2_answer_a2',
          a3: 'b5_c19_test2_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b5_c19_test2_wrongAnswer',
        correctAnswer: 'b5_c19_test2_correctAnswer',
        image: b5c19q2,
      },
      {
        title: 'b5_c19_tests_title',
        question: 'b5_c19_test3_question',
        answers: {
          a1: 'b5_c19_test3_answer_a1',
          a2: 'b5_c19_test3_answer_a2',
          a3: 'b5_c19_test3_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b5_c19_test3_wrongAnswer',
        correctAnswer: 'b5_c19_test3_correctAnswer',
        image: b5c19q3,
      },
    ],
  },
};
