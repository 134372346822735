import { separator, tb7c2_1, tb7c2_2, tb7c2_3 } from 'assets/images';

export const b7_c22_text = {
  b7_c2_title: `Курс «Роды и травматичные роды» `,
  b7_c2_description: `Спикер: Мария Вашкевич-Василевская, психолог, перинатальный психолог`,
  b7_c22_text: `
    ${tb7c2_1}

    Урок 1. Эмоциональный опыт родов

В этом курсе мы поговорим о том, каким может быть эмоциональный опыт родов, и от чего он зависит. И что можно сделать, чтобы ваш опыт был позитивным. 

Что вообще думают о родах в нашем обществе? На самом деле, мы все рождались – я рождалась, вы рождались, рожали наши мамы, рожали наши бабушки. Это то, что есть в нашем мире, это то, как передается жизнь. Но опыт (то, как мы описываем это) – очень разный. И в обществе есть 2 абсолютно полярных стереотипа:

Один стереотип выглядит примерно так: «Это самое ужасное, что со мной происходило! Мне было больно, мне было страшно, там со мной проводили какие-то манипуляции, я тут долго не могла прийти в себя. Я еле это пережила!». И таких женщин достаточно много. Они будут рассказывать своим друзьям, а потом и своим детям о том, что их опыт был ужасен, и значит, это так и есть для всех.
Есть полярное мнение. Есть женщины, которые говорят: «Это был самый прекрасный опыт в моей жизни, это было единение с ребенком, это было божественно».
Каждая женщина, которая проходила процесс родов, считает, что именно так рожают все. Хотя это тоже миф. У всех женщин роды проходят индивидуально, и они зависят от множества разных факторов. И мы как раз поговорим о том, что же будет влиять на то, в каком опыте вы окажетесь.

В первую очередь нужно понять, от чего зависит наш опыт. Эмоциональный опыт родов складывается из нескольких составляющих.

Во-первых, это те истории, которые мы слышали. Это некоторые стереотипы, которые мы ожидаем увидеть или почувствовать в то время, когда будем рожать.
Во-вторых, это то, что мы слышали от своих родителей. Это опыт, который есть в нашей семье. То, что нам рассказывали мама и папа.
В-третьих, это боль и отношение к боли. Потому что процесс родов физиологически связан с ощущением боли. Наши отношения к боли, наши умения каким-то образом абстрагироваться, либо терпеть боль, либо выносить боль, либо расслабляться – все это влияет на то, как мы будем относиться к самому процессу родов. Кого-то это будет пугать, и он будет напрягаться. Кто-то понимает, что это часть естественного процесса, и сможет расслабиться в этом состоянии. И процесс родов пройдет совершенно по другому пути.
В-четвертых, это некоторые страхи и тревоги либо ожидания, которые у нас есть относительно того, как пройдут роды конкретно у вас. Например, какая-нибудь женщина может думать о том, что у нее очень узкий таз и что-то там пойдет не так, и она будет напрягаться и будет иметь свой опыт родов. А какая-то женщина будет бояться за то, что после родов что-то случится плохое с ее маткой, с ее влагалищем, и она не сможет получать удовольствие от секса. И она будет тоже напрягаться в родах. Какая-то будущая мать будет волноваться за то, что случится с ребенком. Все эти нюансы будут влиять на то, что мы переживаем, как мы переживаем и как мы будем относиться к тому, как пошел наш процесс родов.
И здесь есть 2 составляющие, на которые нужно обратить внимание 

что может сделать сама женщина
что могут сделать ее близкие
Конечно, врачи сделают все, что необходимо в данных условиях для данной женщины и для того, чтобы максимально сделать это комфортно, правильно и сохранить жизнь и здоровье и женщины, и ребенка. Они будут делать для этого все возможное. Но женщина сама по себе, находясь в родах, может влиять на этот процесс. А еще лучше, когда она готовится к родам заранее.

Также если эти роды совместные, то партнер также может влиять на этот опыт. Он может помогать женщине пройти через определенные этапы, через переживание боли, через переживание напряжения и сделать этот процесс более позитивным в долгосрочной перспективе.

Есть несколько частей, из которых состоит процесс родов, которые могут иметь разную эмоциональную окраску. 

То, что происходит до непосредственно родов
Это то, как мы готовимся, как мы собираемся, когда у нас начались схватки. То, что происходит в приемном покое, насколько мы расслаблены в этот момент, насколько мы готовы вообще рожать именно сейчас, насколько нас поддерживает медицинский персонал, насколько нас поддерживает партнер. Может быть, вы едете рожать с родителями либо с братьями или сестрами, если они прошли специальные курсы, именно они будут сопровождать вас в процессе родов. Либо вы едете туда одна, и также, насколько вы готовы, насколько вы готовы переживать этот процесс и насколько вам комфортно в приемном покое с тем персоналом, который там был.

Непосредственно роды
Эмоциональный опыт зависит от того, насколько мы погружены в сам процесс. Женщина может выпадать и думать о том, что дома недоготовленная курица. Или женщина может думать, что будет завтра, что будет в момент, когда она увидит ребенка. То есть она прямо сейчас не находиться в процессе родов. Это может ухудшать течение, потому что мы начинаем тревожиться.

Процесс родов
Что происходит сразу после родов? Как происходит отделение плаценты, насколько болезненный этот процесс для вас? Дали вам сразу ребенка или не дали? Если это было кесарево, то как вы себя чувствовали после операции? Все это будет влиять на то, как вы потом будете воспринимать свой опыт.

Но если вдруг что-то пошло не так, были какие-то осложнения в родах, случились разрывы либо какие-либо травмы – даже в этом случае мы свой опыт можем переработать и сделать его более позитивным. Не вспоминать его с ужасом, не вспоминать его как самое кошмарное событие. Самое кошмарное событие – это наша личная оценка, наше личное восприятие. И это то, с чем работают психологи. 

Если так случилось и вы получили негативный опыт, и по какой-то причине вы вспоминаете его как худшее событие либо из-за родов, из-за процесса родов вы хуже контактируете с друзьями, хуже контактируете с партнером, тогда может быть, конечно, это пройдет самостоятельно. Но очень рекомендую вам обратиться к психологу и проработать эту тему. Сделать ваш опыт более позитивным либо хотя бы нейтральным.

Что может делать психолог для женщины, которая готовится к родам? Здесь опять же 2 составляющие. Первая составляющая – это работа со всеми теми ожиданиями, со всеми теми тревогами, которые нас привели в ту точку, где мы рожаем. А второе – это помощь пережить тот опыт, который был, если он был менее позитивным, чем мы хотели, либо если он был травматичным.

${separator}

Урок 2. Как тревога влияет на процесс родов?

Сейчас предлагаю вернуться к физиологии. Процесс родов управляется гормонами, управляется нашей природой. И здесь важно учесть то, как роды происходят. Один из гормонов, влияющих на то, как происходят роды, – это окситоцин (гормон любви, привязанности). Он выделяется, когда мы занимаемся сексом, он выделяется во время родов. И он влияет на то, как происходит процесс, он влияет на тонус матки, он помогает нам рожать мягко. Он в некотором смысле обезболивает, и роды проходят спокойно, естественно и позитивно.

Есть другой гормон (его называют гормоном стресса) – это адреналин. Он вырабатывается тогда, когда мы чувствуем небезопасность, стресс, когда мы беспокоимся, волнуемся и тревожимся. Он имеет противоположное действие действию гормона окситоцина. Они начинают противостоять – и от этого противостояния портится качество процесса родов. Если мы тревожимся, процесс родов замедляется.

Как это может выглядеть? Например, у женщины начались схватки, она собирается в роддом, она ожидает родов, все прекрасно. Но тут она ссорится с мужем и начинает напрягаться. И это влияет на то, как протекает ее процесс родов. Ей становится, возможно, больнее, возможно, процесс родов замедляется, у нее медленнее происходит раскрытие. И в итоге она получает больше страданий, не может сфокусироваться на своих ощущениях.

Какие факторы могут еще влиять? Могут влиять те вещи, которые мы сами себе придумали. Например, мысли в стиле: «Это будет ужасно, я это не переживу, это будет худший опыт в моей жизни!». И тогда, только начинаются схватки, женщина начинает переживать стресс, страх и сама по себе ухудшает ситуацию. 

С одной стороны, этого нельзя избежать. Роды – это стресс, это очень тяжелый процесс. Но наше отношение к этому влияет на то, как мы его чувствуем эмоционально, и что мы будем чувствовать после – это самое важное. Потому что переживать стресс и переживать его длительное время, долго вспоминая о том, как это было, – это разные вещи. И если мы позволяем себе сфокусироваться на ощущениях, позволяем себе расслабиться – тогда мы получаем более позитивный опыт в долгосрочной перспективе. Хотя действительно может быть сложно и больно.

Что может являться сигналами небезопасности? Это сигналы, которые будут вызывать стресс и будут, возможно, влиять на процесс родов. Например:

Ваши отношения с врачами и их поведение. 
Некомфортная температура в том помещении, где вы рожаете или где вы готовитесь к родам, или некомфортное освещение. В этих случаях вы будете более напряженной.
В процессе родов, если рядом с нами есть кто-то, кому мы доверяем, то мы чувствуем себя более расслабленными. Если мы абсолютно одни, никого рядом нет – то в этот момент мы можем чувствовать большее напряжение. Хотя для некоторых женщин находиться в одиночестве – это возможность расслабиться. 

У каждого эти сигналы небезопасности индивидуальны. И будет здорово, если вы заранее подумаете о том, что может сделать для вас процесс родов более комфортным. Например: 

музыка
любимые вещи
аффирмации либо какие-то позитивные настройки, позитивные фразы, которыми вы себя сами будете подбадривать
Это будет помогать вам расслабляться.

Если вы можете попросить о чем-то при совместных родах своего партнера, для того чтобы вы лучше расслабились, вы можете это сделать. Если вы можете о чем-то попросить медицинский персонал – сделайте это. И тогда это позволит вам получить более позитивный эмоциональный опыт от родов.

При этом если мы находимся в стрессе, то, даже если все происходит относительно спокойно (с медицинской точки зрения), мы можем воспринимать любые нюансы как негативные и ухудшать свое эмоциональное состояние, тем самым влияя на то, какой опыт мы получим.

Именно поэтому одной из техник, которая будет дана сегодня в конце этого курса, будет техника безоценочного восприятия. Если мы научаемся воспринимать факты и не давать им оценку – мы можем погрузиться в сам процесс и сделать его более комфортным и более быстрым.

${tb7c2_2}

Урок 3. Травматичные роды и психологическая травма

В этом уроке поговорим, что такое травматичные роды и что означает психологическая травма, либо психологический травматизм.

Беременность – это самое неопределенное время в жизни женщины. Есть огромное количество факторов, которые влияют на этот процесс, но на который не влияем мы. Например, мы не влияем на то, получится у нас забеременеть или не получится у нас забеременеть. Мы практически не влияем на то, доносим ли мы первый триместр, или будет выкидыш. Мы совершенно не влияем на то, как будет проходить сам процесс беременности: будут у нас осложнения, или нас будет тошнить, или мы очень сильно будем много кушать и наберем большое количество веса. На это мы мало влияем, на самом деле, потому что это некоторая биология. Мы не знаем, будут ли осложнения, мы не знаем, на каком сроке родится наш ребенок. В конце концов, мы не знаем то, как именно будут проходить наши роды, и они будут для нас длительными или они будут для нас короткими. Насколько быстро будет рождаться ребенок, и будут ли необходимы какие-то медицинские манипуляции.

Таким образом, есть огромное количество вещей, которые мы не контролируем, и это создает тревогу. При этом не каждые роды являются травматичными, и есть огромное количество позитивных родов. И не каждые позитивные роды будут восприниматься женщиной как позитивные.

Что же тогда такое травма либо травматичное событие? 

Это событие либо процесс, который может угрожать здоровью, либо жизни матери, либо ребенка. И тогда все, что вызывает эту угрозу, будет восприниматься как травма. И даже если ваш процесс был именно таким, не обязательно вы будете иметь негативные эмоциональные последствия. Потому что бывает так, что женщине приходится делать кесарево сечение. Но это не обязательно ведет ее к негативным эмоциональным последствиям, но может привести.

Факторы риска, которые могут сопутствовать травматичности родов

К факторам риска можно отнести:

Негативный опыт предыдущих родов либо опыт насилия. Если женщина изначально в своей жизни, так получилось, что она пережила физическое либо сексуальное насилие, то для нее опыт родов может стать травматичным. Потому что здесь слишком много физиологии, слишком много эмоций.
Если у вас был опыт ЭКО (экстракорпорального оплодотворения), если у вас был опыт бесплодия, это также может являться риском. 
Риски, связанные с медицинскими манипуляциями. Если так получилось, что в процессе родов вы подвергаетесь медицинским манипуляциям для сохранения вашей жизни либо для сохранения жизни ребенка, это может восприниматься нами как травма, как некоторая угроза. 
Преждевременные роды тоже могут восприниматься как травма.
Любые осложнения во время беременности, хроническая невынашиваемость может сопутствовать тому, что опыт будет травматичным. 
Если после рождения ребенка, ребенок попадает в реанимацию, и вы не можете с ним контактировать либо этот контакт минимален – это также можно рассматривать как травматичные роды. Потому что ваши эмоции будут очень сильно оголены, обострены, и это очень сильный риск для здоровья вас либо ребенка.
Таким образом, факторов риска много. Мы не знаем, как пройдут наши роды, но факторы риска не означают, что вы получите травму и у вас будут последствия.

Что значит последствия? К последствиям травматичных родов можно отнести:

Нежелание иметь еще раз опыт беременности и родов.
Послеродовая депрессия либо послеродовые стрессовые расстройства.
Когда женщина не воспринимает ребенка, не хочет с ним контактировать, потому что он напоминает о том, что она пережила в процессе родов. 
Когда из-за того, что женщина пережила в процессе родов, она не хочет видеть своего партнера, своего мужчину. И не хочет с ним секса не только через 2 месяца после родов или полгода, а, в принципе, не хочет с ним секса. И это уже начинает влиять на отношение внутри пары.
В долгосрочной перспективе, если женщина постоянно напряженная, она постоянно боится за ребенка – это тоже могут быть последствия травматичного опыта, опыта родов. Для того чтобы этого избежать, необходимо отслеживать свое состояние.

Каким образом можно это сделать? Нужно все время смотреть, что с вами происходит, есть ли у вас лишняя тревога. Если:

вы стали более подавлены, чем обычно
у вас нежелание контактировать со своим мужем
вы видите кошмары
вам периодически приходят воспоминания, какие-то кусочки того негативного опыта, который с вами случился
Тогда необходима помощь специалиста. Это важно, чтобы пережить эти эмоции и оставить их в прошлом, чтобы улучшить качество своей жизни и не тревожиться, чтобы быть расслабленной со своим партнером либо быть расслабленной со своим ребенком и открыть для себя чувство любви к ребенку.

Все это может быть естественным процессом, а может требовать помощи. И если вы чувствуете себя плохо, вы пережили травматичные роды, что-то пошло не так в процессе, и спустя 2 дня вы чувствуете себя плохо – это может быть нормально, это какая-то ваша реакция на тот стресс, который вы пережили. Но если спустя 1-2 месяца ваше состояние не улучшается, тогда лучше обратиться к специалисту, чтобы получить квалифицированную помощь и улучшить свое эмоциональное состояние.

${separator}

Урок 4. Острые стрессовые реакции

Что такое острый стресс и что такое острая стрессовая реакция? Стрессовая реакция – это нормальная реакция организма на ненормальные обстоятельства.

Что такое ненормальные обстоятельства? Это те, с которыми мы не сталкиваемся каждый день. Мы не сталкиваемся каждый день с преждевременными родами или с тем, что роды длятся слишком долго. И нормальная стрессовая реакция может быть нескольких видов, в зависимости от того, как наша психика может справляться. 

Самый оптимальный, или самый простой способ – это социальное взаимодействие.

Одна из более комфортных реакций на стресс, когда мы начинаем об этом рассказывать, когда мы хотим поделиться, когда мы хотим выразить свою эмоцию достаточно легко, в более или менее спокойном состоянии (может быть, чуть-чуть поплакать), но мы не испытываем сильных эмоций. Мы разговариваем со своим партнером, разговариваем со своими близкими, мы получаем поддержку, и это помогает нам справиться. Это самый простой уровень восприятия стресса, и на таком уровне стресс проходит максимально быстро. Мы получаем поддержку, и мы быстро справляемся.

Если по какой-то причине этот уровень социального взаимодействия недоступен – мы не можем с кем-то поговорить, выразить свою эмоцию, тогда у нас включается та самая знаменитая биологическая реакция: бей, беги, замри. И тогда мы начинаем слабо контролировать то, что с нами происходит. Но, подчеркну, это нормальная реакция организма на ненормальные для нас события.

Эта биологическая реакция может быть 3 видов. 

Реакция избегания. Животные, когда они видят, на них бежит с зверь большой (больше, чем они), они не могут справиться, они пытаются убежать. И это реакция тревоги. Нам становится очень страшно, мы хотим плакать, мы хотим изолироваться, мы хотим где-то спрятаться, и чтобы нас никто не трогал. Это нормальная реакция на стрессовое событие. 
Нормальная реакция длится 1-2, максимум 3 дня, и нам становится легче. Если нам не становится легче, тогда лучше обращаться к специалисту, для того чтобы получить помощь.

Чаще всего страшнее переживания первых 3 дней – это максимально острая реакция на стрессовое событие. Нам хочется плакать, нам хочется убежать и спрятаться, и это реакция избегания, реакция убежать.

Если животное не может убежать от того, кто на него нападает – животное нападает в ответ, оно начинает защищаться. И также нормальной стрессовой реакцией является агрессия. 
Мы начинаем ненавидеть врачей, ребенка, мужа, себя, начинаем кричать. И это тоже может быть нормальной стрессовой реакцией. Необходимо понимать, что я сейчас в стрессе, и то, что со мной происходит, то, что меня все бесит вокруг, – это нормальная реакция на стресс, которая должна закончиться в ближайшее время, в ближайшие несколько суток. Если не происходит облегчения, тогда необходима помощь специалиста. Но, еще раз подчеркну, нормально чувствовать злость, если вы пережили тяжелое событие, оно для вас было стрессовым, особенно, если оно было травматичным (т.е. имело угрозу жизни для вас либо для ребенка). 

Еще одна биологическая реакция, которая, на самом деле, является одной из самых сложных, – это реакция «замри». Как она выглядит? Животное хотело убежать – не получилось, пыталось защититься – не получилось, тогда животное условно начинается готовиться к смерти, оно замирает, оно расслабляется и оно выключает абсолютно все чувства. 
И так бывает, что на очень сильный стресс какое-то время мы можем ничего не чувствовать. Не чувствовать даже людей, которые находятся рядом, находиться в каком-то своем мире и не иметь возможности контактировать, и даже не хотеть этого. 

Если в этот момент нам будут задавать вопрос, мы будем говорить: «Нормально, у меня все нормально». Но это не потому что на самом деле нормально, а потому что настолько сильный был стрессовый фактор, что женщина предпочла реакцию замирания.

На осознанном уровне мы можем не хотеть такой реакции, но наш организм выбирает именно такую, как оптимальную. Исходя из того, сколько у него на данный момент ресурсов.

И если вы находитесь в состоянии замирания, когда вы ничего не чувствуете ни к ребенку, ни к себе, ни по отношению к этому событию, первое, что вы можете сделать – попробовать контактировать с миром. Следить за своим дыханием, следить за своими ощущениями, возвращать себе чувство реальности. 

В тот момент, когда вам это удастся, возможно, начнется либо реакция «бей», либо реакция «беги», либо вам захочется плакать, либо вам захочется очень сильно кричать на кого-то и агрессировать, либо вас начнет все раздражать. В этом нет ничего страшного – это биология. Нашему организму на физиологическом уровне нужно пережить стресс, и он это делает.

Если мы не мешаем, не пытаемся остановить эту реакцию – она проходит быстрее и с наименьшими последствиями для нашей психики. Мы справляемся и потом говорим: «Я справился, теперь у меня все хорошо».

Если эта реакция длится больше 1-2-3 дней и вам не становится легче, симптомов не становится меньше, они только ухудшаются, у вас начинаются кошмары либо у вас постоянные воспоминания о том, как это все было, и вам плохо – тогда вы можете обратиться к специалисту за помощью.

Таким образом, могут быть разные вариации нормальной реакции, даже если она внешне кажется странной, нелепой или неправильной. Если вам хочется плакать – это нормально, если вам хочется кричать либо вас все раздражает – это нормально, если вы замираете, не хотите никого видеть и почти ничего не чувствуете – на какой-то момент это нормально, ваша психика пытается справиться. 

Если в этот момент ваши близкие смогут найти возможность быть рядом с вами либо хотя бы позвонить – это будет хорошо. Это может облегчить ваше состояние и позволит вам справиться гораздо быстрее. При этом вы не обязаны выходить на контакт, если вы этого не хотите. Пожалуйста, дайте себе время. Дайте себе время пережить стресс и вернуться к нормальному состоянию.

${tb7c2_3}

Урок 5. Неблагоприятные сценарии родов

Поговорим про ситуации, когда ребенок рождается каким-то сложным способом. Почему сложным? В обществе есть представление о том, что есть правильный и неправильный способ рождения. И к правильным относятся естественные физиологические роды. Еще раз подчеркну – это стереотипы.

Иногда по разным причинам получается так, что что-то идет по-другому. Ребенок рождается раньше времени, либо после рождения он попадает в реанимацию, либо вам делают экстренное кесарево сечение

Что происходит в этом случае? Самый важный фактор – это наши ожидания. Они не соответствуют реальности. И второе – мы разделены с ребенком. Мы ожидали, что мы родим и все будет хорошо, и мы сможем его взять на руки, но что-то идет не так, и ребенок находится в боксе, а мы находимся в реанимационной палате, например, или в послеродовой палате и не имеем доступа. При этом есть риски либо для нас, либо для ребенка. Это очень сильные эмоциональные переживания, которые сопоставимы с некоторой потерей либо с рисками потерять этого ребенка.

Поговорим о том, как можно облегчить это состояние для себя и как можно облегчить это состояние для партнера, если вы муж / мама / сестра / брат роженицы.

Советы для мамы

Первый момент, на который вы можете обратить внимание, – это чувство себя как матери и контакт с ребенком. 

Если есть возможность каким-то образом находиться рядом с ребенком в боксе либо если есть возможность дотронуться до него – будет здорово, если у вас это получится. 
Если по каким-то причинам это невозможно, тогда нужно сконцентрироваться на своих ощущениях, на том, как вы чувствуете ваш контакт с ребенком, даже находясь в разлуке с ним. Напоминая себе о том, что он жив, что вы уже мама.
Это важное ощущение, потому что оно помогает не потерять этот контакт, особенно если ребенок находится в реанимационном отделении достаточно длительно. Вам нужно удерживать мысли о своем ребенке, о том, что вы рядом, и по возможности, действительно, находиться рядом.

Чем может осложняться чувство разделенности с ребенком? Отсутствием контроля. Потому что мы не знаем, что будет дальше. Мы не влияем на ситуацию. Во время беременности у нас было ощущение контроля, потому что вы чувствовали ребенка внутри, чувствовали шевеление и контакт. Здесь вы разделены – и нет ощущения того, что вы влияете на события, потому что ребенок уже во внешней среде, ребенок уже не с вами. И это отсутствие контроля усиливает нашу тревогу. 

Если вы оказались в такой ситуации, что ребенок родился преждевременно, случилось экстренное кесарево сечение, вы на какой-то момент разделены с ребенком – чтобы уменьшить тревогу, необходимо вернуть себе чувство контроля. 

Возможно, это будут какие-то вещи, связанные со звонками.
Возможно, вы будете принимать препараты в определенном порядке.
Возможно, вы сделаете какие-то ритуалы по уходу за собой.
Задайте себе вопросы: я не могу сейчас ничего сделать с ребенком, я не могу сейчас ему помочь, он находится у медиков, но что я могу сделать прямо сейчас? Я не влияю полностью на события, но на что я влияю? Например, я влияю на свое эмоциональное состояние, я могу каким-то образом его изменять. Я могу медитировать, я могу слушать позитивную музыку, которая будет отвлекать меня от тяжелых переживаний, чтобы я была в ресурсе в момент контакта с ребенком.

Советы для семьи

Что в этой ситуации могут делать родственники? Исходя из того, как может протекать стрессовой процесс, родственники могут помочь роженице пережить стресс на уровне социальной поддержки. То есть могут звонить, спрашивать, как она себя чувствует, какие чувства вызывают у нее разделенность с ребенком, какие чувства у нее вызывает тот процесс, который произошел. Вы можете говорить слова поддержки; говорить, что вы рядом; говорить, что вы любите эту женщину; говорить, что вы любите ребенка и что вы тоже беспокоитесь.

Разделение эмоций – это очень важная часть. И для женщины важно помнить, что мужчина (отец ребенка), если вы в браке, он также переживает, как и вы. Возможно, он не так демонстрирует это, возможно, он более на рациональном уровне пытается справиться со своим стрессом. Но он тоже пытается вам помочь, и он тоже переживает. Чем больше вы будете находиться в контакте с партнером после стрессовой реакции, тем лучше это для пары. Потому что часто нам кажется, что мы будем отягощать своего партнера своими эмоциями, что он, наверное, чувствует что-то другое либо вообще ничего не чувствует, но это не так. Если мы позволяем разделять свои эмоции с другими людьми, если мы помогаем нашему партнеру также пережить это стрессовые событие, это приносит облегчение и нам, и нашим близким. Поэтому социальный контакт также очень важен.

Еще очень важный аспект, который может возникать в результате неправильных родов, когда роды происходят преждевременно, либо происходит экстренное кесарево сечение, либо ребенок попадает в реанимацию – это чувство вины. Как будто я либо мой партнер могли повлиять на то, как все произошло. Если бы я лучше о себе заботилась, то не было бы преждевременных родов, если бы я что-то там лучше чувствовала, то, возможно, ребенок не попал бы в реанимацию. Но это не так. Конечно, мать влияет на своего ребенка, но есть огромное количество факторов (включая биологические, включая то, где мы живем, включая то, болеем мы, не болеем мы), которые влияют на эти процессы, и ребенок рождается тем способом, которым он рождается. Мы не влияем на этот процесс.

Чувство вины возможно, и оно есть часть горевания. Иногда мы испытываем это чувство, потому что, когда ребенок попадает в реанимацию, в некотором смысле мы его теряем или мы думаем, что мы можем его потерять. И тогда может запускаться процесс горевания. 

В этот момент вы можете себе напоминать про то, что в реальности влияло на то, каким способом родился ребенок, и задавать себе вопрос: действительно ли я влияла или насколько это влияние было существенным? И чаще всего ответ: «Нет, я не влияла». Процесс происходил таким образом, каким он происходил, и влияние женщины здесь было минимальным. Нам необходимо просто принять, что все происходило именно так, и это было печально, это было неожиданно и, возможно, травматично.

В этот момент лучше сконцентрироваться на том, что мы можем делать. Помимо чувства контроля, лучше сконцентрироваться на заботе о себе, на том, чтобы вернуть себя в ресурсное состояние. Важно:

Хорошо кушать, хорошо спать, пока ребенок в реанимации. 
Начать контактировать с друзьями либо с близкими.
Заботиться о себе, делать свою жизнь комфортной. 
Если мы полностью сконцентрируемся на самом факте разделения себя с ребенком, это создаст огромное количество тревоги и ухудшит наше эмоциональное состояние, а значит, мы будем не в ресурсе для того, чтобы контактировать с ребенком, когда это будет возможно.

Итак, резюмируем. Важно: 

Позаботиться о контакте с ребенком. Если этот контакт физически невозможен, то, по крайней мере, проговорить это себе. То, что я буду думать о ребенке, я нахожусь с ним в контакте эмоциональном, я думаю о нем, и он еще жив.
Позволить себе контакт с партнером и обмен эмоциями. Сделать так, чтобы вы могли переживать вместе, разделять эмоцию и справляться со стрессом быстрее. 
Сконцентрироваться на тех вещах, которые помогают вам чувствовать контроль. Возможно, вы сконцентрируетесь на заботе о себе, и это тоже будет хорошо, потому что это улучшит ваше эмоциональное состояние и поможет вам лучше справиться с той ситуацией, с которой вы столкнулись.

${separator}

Урок 6. Техники осознанности в период беременности и родов

Как мы обсуждали ранее, на эмоциональный опыт родов влияют какие-то стереотипы и наши ожидания. И чем более мы открыты опыту (каким бы он ни был) – тем легче нашей психике его переработать и тем больше вероятность того, что мы справимся и будем воспринимать свой опыт родов как позитивный либо с большего как позитивный.

Но для этого необходимо сконцентрироваться на восприятии. В психологии есть техники, называются они «техники осознанности». Они нам помогают воспринимать ситуацию в фактах (то есть такой, какая она есть в реальности) и отказаться от оценок. Чаще всего навыки осознанности связаны с медитацией, однако мы можем практиковать осознанность и в бытовых условиях, просто моя посуду либо гуляя по парку. Чтобы использовать навыки осознанности, необходимо знать всего 3 способа быть осознанным.

Первый способ – наблюдать. 
Мы буквально наблюдаем, что происходит с нашим телом. Например, мы делаем это в медитации. Либо мы наблюдаем за тем, как шелестит листва, если мы гуляем по парку, мы наблюдаем либо рассматриваем какие-то вещи и полностью погружаемся в это. При этом мы не оцениваем, мы не думаем о том, что было «до», что будет «после», мы просто наблюдаем. Процесс наблюдения, либо осознанного внимания, очень помогает нам абстрагироваться от эмоционального состояния либо уменьшить его интенсивность, если мы уже находимся в эмоциях.

Второе направление осознанности – это описание. 
Мы не просто наблюдаем, но мы еще описываем, что происходит. Мы можем описывать свои чувства, мы можем описывать свои ощущения, мы можем описывать то, что происходит вокруг нас. Но делать это максимально подробно и без оценок. Если мы погружаемся в описание реальности, мы находимся с ней в контакте, и это очень здорово. Очень важно не оценивать, не говорить, что хорошо, что плохо, что мне нравится, что мне не нравится, а стараться делать это в фактах, в чистых фактах.

Следующий процесс (очень важный для переживания родов) – это процесс погруженности.
То есть мы можем наблюдать, мы можем каким-то образом описывать и мы можем погружаться в процесс.

Что значит погружаться в процесс? Я думаю, что вы испытывали это. Например, мы погружаемся в процесс, если смотрим фильм, или мы погружаемся в процесс, если танцуем. Иногда мы погружаемся в процесс, если едим очень-очень вкусную еду, и нам нравится, как это все происходит. Но когда мы погружаемся в процесс, мы находимся здесь и сейчас, и наши мысли находятся тоже здесь и сейчас, находятся в контакте. И это очень важно. 

Все эти 3 способа могут нам помочь пережить процесс родов максимально позитивно либо максимально безоценочно, что тоже немаловажно, если что-то начинает идти не так, а это тоже возможно.

Техника «сканирования тела» 

Предлагаю попрактиковать один из навыков безоценочного восприятия реальности, навыков медитации, навыков наблюдения. Он называется «сканирование тела».

Чем это полезно? Если женщина учится концентрироваться на своих ощущениях в течение беременности, если вы учитесь описывать их и погружаться в процесс в течение беременности, вы сможете сделать это автоматически в процессе родов, а следовательно – избежать каких-то стрессовых факторов и улучшить течение родов. Вы сможете использовать осознанно навыки осознанности в родовой деятельности для того, чтобы полностью погрузиться в то, что будет происходить, и пережить это так, как это будет происходить.

Займите комфортное для себя положение. Вы можете сесть, вы можете лечь. Сделайте то, что вам хочется, найдите ту позу, которая будет для вас максимально комфортной. 
Итак, вы заняли позу, теперь вы можете еще раз подвигаться, перепроверить, действительно ли ваша поза комфортная для вас.
Сделайте глубокий вдох и выдох, вы можете держать глаза открытыми либо закрыть их, если вам так будет комфортно.
Сконцентрируйте свое внимание на кончике носа и отследите то, как происходит ваше дыхание, как происходит вдох и выдох. Найдите максимально комфортный способ и скорость дыхания. И просто наблюдайте. Очень важно оставаться без оценок. Если вам будут приходить какие-то мысли или вы будете отвлекаться, просто допустите это, позвольте им прийти и уйти. 
Позвольте себе возвращаться к наблюдению за дыханием. И если вам будут приходить оценки о том, что вы делаете что-то хорошо либо плохо, напомните себе, что оценки – это то, что мешает вашему контакту со своим телом сейчас, и просто погружайтесь в ощущения.

И сейчас я предлагаю вам начать сканирование своего тела. Я буду называть части тела, и вы будете перемещать свое внимание именно туда. Некоторое время вы будете удерживать свое внимание на этой части тела и потом переходить к следующей. Если вы отвлекаетесь, просто продолжайте дышать и перемещать свое внимание в тот момент, когда вы себя снова осознаете. 

Сделайте глубокий вдох и выдох и сконцентрируйте свое внимание на мизинце правой ноги. На большом пальце правой ноги, на правой пятке, на правом голеностопе, на икроножной мышце правой ноги, на правой коленке, на правом бедре. Теперь переместите внимание на мизинец левой ноги, на большой палец левой ноги, на левую пятку, на левую икроножную мышцу, на левую коленку, на левое бедро.
Сконцентрируйте свое внимание в промежности, почувствуйте свою попу. Пройдитесь вниманием по всей своей спине, переместите внимание в живот, в грудную клетку. Сделайте глубокий вдох и выдох.
Переместите свое внимание в правый мизинец, в безымянный палец правой руки, в средний палец правой руки, в указательный палец правой руки, в большой палец правой руки, в правое запястье, в правое предплечье, в правый локоть, в правое плечо.
Переместите свое внимание в мизинец левой руки, в безымянный палец левой руки, в средний палец левой руки, в указательный палец левой руки, в большой палец левой руки, в левое запястье, левое предплечье, левый локоть, левое плечо.
Переместите свое внимание в шею, переднюю часть шеи и затылок. Почувствуйте, что происходит под волосами, какие ощущения у вас там есть. Переместите внимание на лоб, глаза, щеки, подбородок, губы, язык, почувствуйте все лицо целиком.
Сконцентрируйте свое внимание на кончике носа и сделайте глубокий вдох и выдох. Сделайте несколько вдохов и выдохов в комфортном для вас режиме, и постепенно можете завершить медитацию, открыть глаза, если они были закрыты, либо посмотреть на предметы, которые вас окружают для того, чтобы восстановить ощущение реальности.
И вы также можете отследить, что произошло во время этой медитации с вашим телом, как вы себя чувствуете, что происходит с вашими эмоциями, насколько вам удалось расслабиться.

Регулярные практики медитации, регулярные практики сканирования своего тела помогают нам расслабляться лучше, помогают нам улучшать контакт с реальностью и помогают нам меньше тревожиться. Потому что пока мы заняты сканированием своего тела, мы не оцениваем окружение на предмет тревоги, на предмет того, что нам может сигнализировать о небезопасности. И мы привыкаем к этому состоянию, если медитируем регулярно, мы привыкаем концентрироваться на своих ощущениях, а не на том, что может вызвать у нас тревогу.

Именно поэтому практики медитации очень полезны при беременности и также могут быть полезны в процессе родов, если вы можете быть осознанными и концентрироваться на своих ощущениях.

Подведем итог курса, посвященного родам и травматичным родам. Наш эмоциональный опыт связан не столько с тем, как проходят роды, сколько с тем, как мы к этому относимся, как мы интерпретируем это. И если так получилось, что ваш опыт был менее позитивный, чем вам хотелось бы, и это вызывает у вас дополнительное напряжение, влияет на ваше взаимодействие с другими людьми (с партнером, с мужем, с ребенком, с другими мамами, с другими детьми), в этом случае здорово, если вы найдете в себе ресурс обратиться к специалисту за помощью и улучшить свое эмоциональное состояние. 

При этом даже если вы испытали травматичные роды, вы можете контролировать свое состояние тем, что позволите себе переживать стресс и не будете его останавливать.

Если вы вернете себе контроль и будете фокусировать свое внимание на том, что вы можете контролировать, и отпустите то, что вы контролировать не можете. Будет здорово, если вы найдете возможность контактировать со своими близкими, обмениваться эмоциями, потому что это самый эффективный способ справляться со стрессом – находиться в контакте, переживать эмоции и делиться ими, получать поддержку. Если у вас есть такая возможность – это здорово. 

Тревожные звоночки

вы понимаете, что не справляетесь 1-2 месяца 
вам приходят очень страшные воспоминания
вам снятся кошмары
вы постоянно находитесь в тревоге и просыпаетесь ночью для того, чтобы посмотреть, дышит ли ребенок
Будет здорово, если вы обратитесь к специалисту за помощью и облегчите свое эмоциональное состояние.

Я надеюсь, что если вы будете регулярно практиковать навыки осознанности, навыки наблюдения, навыки участия, навыки описания и также будете стараться делать это безоценочно, то ваш опыт родов будет более позитивен и вы будете открыты к тому, как именно это пройдет. И, возможно, вы быстрее справитесь, если ваш опыт будет менее позитивным или травматичным. Позвольте своей психике справляться. Если она не справляется, то в этом случае лучше обращаться к специалистам.
    `,
};
