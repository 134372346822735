import { useEffect } from "react";
//@ts-ignore
import { KJUR, KEYUTIL } from "jsrsasign";
import { useAppSelector } from "store/store";
import { useSilentLogin } from "features/Auth/hooks/useSilentLogin";
import { selectUser } from "features/User/store/selectors";

const urlParams = new URLSearchParams(window.location.search);

export const convertKeysToPEM = (obj: any, key: number): string => {
  const keys = Object.keys(obj);
  let pem = "{";
  pem += `"${keys[key]}": "${obj[keys[key]]}",`;
  return pem + "}";
};

export const getPublicKeys = async () => {
  try {
    const response = await fetch(
      "https://www.googleapis.com/robot/v1/metadata/x509/securetoken@system.gserviceaccount.com"
    );
    const keys = await response.json();
    return keys;
  } catch (error) {
    console.error("Error while fetching public keys", error);
    throw error;
  }
};

const checkToken = (publicKeys: string, token: string) => {
  // const publicKeyObj = KEYUTIL.getKey(publicKeys);
  const decodedToken = KJUR.jws.JWS.parse(token);
  const data = decodedToken.payloadObj;
  // const isValid = KJUR.jws.JWS.verify(token, publicKeyObj);
  // if (!isValid) {
  //   localStorage.removeItem("idToken");
  //   throw new Error("Invalid token");
  // }
  const currentTime = Math.floor(Date.now() / 1000);
  if (data.exp && data.exp < currentTime) {
    localStorage.removeItem("idToken");
    throw new Error("Expired token");
  }
  if (data.iat && data.iat > currentTime) {
    localStorage.removeItem("idToken");
    throw new Error("Invalid token issuance date");
  }
  if (data.aud !== process.env.REACT_APP_PROJECT_ID) {
    localStorage.removeItem("idToken");
    throw new Error("Invalid token audience");
  }
  if (
    data.iss !==
    `https://securetoken.google.com/${process.env.REACT_APP_PROJECT_ID}`
  ) {
    localStorage.removeItem("idToken");
    throw new Error("Invalid token issuer");
  }
  if (!data.sub) {
    localStorage.removeItem("idToken");
    throw new Error("Empty token subject");
  }
  localStorage.setItem("idToken", token);
  return data;
};

export const useExtToken = () => {
  const user = useAppSelector(selectUser);
  const { processAuthState } = useSilentLogin();

  useEffect(() => {
    const idToken = urlParams.get("idToken");
    const localStoreToken = localStorage.getItem("idToken");
    const token = idToken || localStoreToken;
    const publicKeys = localStorage.getItem("publicKeys");
    if (token && !user?.id) {
      if (publicKeys) {
        const data = checkToken(publicKeys, token);
        processAuthState(data.sub);
      } else {
        getPublicKeys().then((keys) => {
          let data;
          try {
            const pem = convertKeysToPEM(keys, 0);
            data = checkToken(pem, token);
            localStorage.setItem("publicKeys", pem);
          } catch {
            const pem = convertKeysToPEM(keys, 1);
            data = checkToken(pem, token);
            localStorage.setItem("publicKeys", pem);
          }
          processAuthState(data.sub);
        });
      }
    }
  }, [user]);
};
