import styles from './2-PeriodScreen.module.css';
import { FC, SetStateAction, useCallback, useState } from "react";
import { useFormik } from "formik";
import { useTranslate } from "hooks/useTranslate";
import { useAppDispatch } from "store/store";
import { FormFields } from "./validations/constants";
import { getDateOfPregnancyValidationSchema } from './validations/validationSchema';
import { updateDateOfPregnancy } from './store/OnboardingSlice';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'navigation/constants';
import { NumberInput } from './components/NumberInput/NumberInput';
import { TitleH3 } from 'components/Text/TitleH3/TitleH3';
import classNames from 'classnames';
import { daysToMls, weeksToMls } from 'utils/converters';
import SkipStepButton from './components/SkipStepButton/SkipStepButton';
import NextButton from './components/NextButton/NextButton';
import ErrorMessage from 'components/Messages/ErrorMessage/ErrorMessage';

const { DAYS_1, DAYS_10, WEEKS_1, WEEKS_10 } = FormFields;

const ALLOWED_SYMBOLS = Array.from({ length: 10 }, (_, i) => `${i}`);


export const DateOfPregnancyScreen: FC = () => {
  const t = useTranslate();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [selected, setSelected] = useState<FormFields | null>(WEEKS_10);

  const formik = useFormik({
    initialValues: {
      [WEEKS_10]: '',
      [WEEKS_1]: '',
      [DAYS_10]: 0,
      [DAYS_1]: '',
    },
    validationSchema: getDateOfPregnancyValidationSchema(),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const periodWeeksMls = weeksToMls(
        Number(`${values[WEEKS_10]}${values[WEEKS_1]}`)
      );
      const periodDaysMls = daysToMls(
        Number(`${values[DAYS_10]}${values[DAYS_1]}`)
      );

      const starPeriodPregnancyMls =
        Date.now() - (periodWeeksMls + periodDaysMls);

      if (starPeriodPregnancyMls) {
        dispatch(updateDateOfPregnancy(starPeriodPregnancyMls));
        navigate(AppRoutes.INTERESTS_SCREEN);
      };
    },
  });

  const handleChangeFocus = (isFocused: boolean) => {
    if (isFocused) {
      formik.setErrors({});
      setIsTouched(true);
    }
  };

  const handleNext = useCallback(
    () => {
      formik.handleSubmit();
    },
    [formik]
  );

  const handleChange = useCallback(
    (value: { nativeEvent: { data: any; }; }, currentField: FormFields, nextField: SetStateAction<FormFields | null>) => {
      const keyValue = value.nativeEvent.data;
      if (!keyValue) {
        formik.setFieldValue(currentField, '');
      }

      if (ALLOWED_SYMBOLS.includes(keyValue)) {
        formik.setFieldValue(currentField, keyValue);
        setSelected(nextField);
      }
    },
    [formik]
  );


  return (
    <>
      <div className='auth-section-title'>
        <TitleH3
          text={ t('onboarding_PeriodScreen_section_title_pregnancy') }
        /> 
      </div>
      <div className={ classNames(
        styles.bloksContainer,
        !formik.isValid && styles.bloksErrorContainer
      ) }
      >
        <div className={ styles.block }>
          <div className={ styles.blockRow } >
            <NumberInput 
              id={ WEEKS_10 }
              selected={ selected === WEEKS_10 }
              onChange={ (value) => {
                handleChange(value, WEEKS_10, WEEKS_1)
              } }
              onChangeFocus={ handleChangeFocus }
              error={ !formik.isValid }
              value={ formik.values[WEEKS_10] }
            />
            <NumberInput
              id={ WEEKS_1 }
              selected={ selected === WEEKS_1 }
              onChange={ (value) => {
                handleChange(value, WEEKS_1, DAYS_1);
              } }
              onChangeFocus={ handleChangeFocus }
              error={ !formik.isValid }
              value={ formik.values[WEEKS_1] }
            />
          </div>
          <div className={ styles.blockLabel }>
            { t('common-weeks') }
          </div>
        </div>
        <div className={ styles.block }>
          <div className={ styles.blockRow } >
            <NumberInput 
              id={ DAYS_10 }
              onChange={ (value) => {
                formik.handleChange(value)
              } }
              onChangeFocus={ handleChangeFocus }
              error={ !formik.isValid }
              value={ formik.values[DAYS_10] }
              desabled={ true }
            />
            <NumberInput
              id={ DAYS_1 }
              selected={ selected === DAYS_1 }
              onChange={ (value) => {
                handleChange(value, DAYS_1, null);
              } }
              onChangeFocus={ handleChangeFocus }
              error={ !formik.isValid }
              value={ formik.values[DAYS_1] }
            />
          </div>
          <div className={ styles.blockLabel }>
            { t('common-day') }
          </div>
        </div>
      </div>
      <div className={ styles.error }>
        {
          !formik.isValid && (
            <ErrorMessage
              message={
                formik.errors[WEEKS_1] === 'error_fill_fields' ||
                formik.errors[WEEKS_10] === 'error_fill_fields' ||
                formik.errors[DAYS_1] === 'error_fill_fields' ||
                formik.errors[DAYS_10] === 'error_fill_fields'
                  ? t('error_fill_fields')
                  : t('error_PeriodPregnancy')
              }
            />
          )
        }
      </div>
      <div className='auth-next-container'>
        <SkipStepButton
          title={ t('onboarding_PeriodScreen_skipStep') }
        />
        <NextButton
          title={ t('common-next') }
          onClick={ handleNext }
          disabled={ !isTouched || !formik.isValid }
        />
      </div>
    </>
  );
};
