import { separator, tb5c2_1, tb5c2_2, tb5c2_3, tb5c2_4 } from 'assets/images';

export const b5_c17_text = {
  b5_c2_title: `Курс «Медицинское наблюдение за ребенком в первый год жизни»`,
  b5_c2_description: `Спикер: Алеся Корево, врач-педиатр, консультант по грудному вскармливанию и питанию детей`,
  b5_c17_text: `
    ${tb5c2_1}

    Урок 1. 1-2 месяца жизни. Визиты на дом и осмотры педиатра

Поговорим о первых 2 месяцах жизни ребенка. Это, пожалуй, один из самых волнительных периодов в жизни молодых родителей. Обсудим в этом уроке, когда же к вам в гости придет педиатр и когда вам нужно будет идти к врачу самостоятельно, а также ответим на популярные вопросы о том, что ждать на первом визите педиатра домой и как подготовиться к осмотру. 

Первые осмотры на дому

Согласно официальным документам в нашей стране педиатр должен посетить ребенка на дому в первые 3 дня после выписки из роддома. И в первые 3 дня к вам также придет медсестра. Обычно она приходит на следующий день после врача.

Согласно мировым рекомендациям, ребенок должен быть осмотрен в течение первых 3-5 дней после рождения. Почему важно осмотреть малыша именно в этот период? Потому что в это время доктор расскажет, как маме и папе самостоятельно осматривать ребенка, как организовать уход, поможет скорректировать питание. Если мама делает что-то не совсем грамотно, то это может привести в дальнейшем к проблемам. Например, к недобору веса, инфицированию пупочной ранки, перегреванию или переохлаждению малыша.

В нашей стране первый осмотр происходит на дому. Первым может прийти или врач, или медсестра. Кто приходит первый, тому мама отдает обменную карту. А ребенку в свою очередь заводят свою амбулаторную карту в поликлинике. Эту карточку заранее родителям покупать не надо, врач заводит ее сам (как в поликлинике, так и в частном центре). Поэтому не нужно заезжать по дороге из роддома в поликлинику и отдавать обменную карту. К вам обязательно придут на осмотр в первые 3 дня.

По сегодняшним нормам, здоровый ребенок осматривается педиатром в первые 3 дня после выписки и далее на 3-й неделе жизни. Медсестра осматривает ребенка в первые 3 дня, и дальше – 1 раз в неделю.

Как проходит осмотр в первые дни жизни малыша?

Задача медсестры – оценить общую картину, состояние ребенка. Она посмотрит, как он сосет грудь или бутылочку, как организовано его место сна, все ли есть у мамы для купания, ухода и гигиены. Как я говорила ранее, во время таких посещений вам объяснят, как правильно ухаживать за малышом, как обрабатывать пупочную ранку, как купать новорожденного, как его кормить, пеленать. В нашей программе подготовки к родам мы также обсудим этот вопрос. Обязательно смотрите большой видеокурс по гигиене ребенка.

Если у мамы есть вопросы по грудному вскармливанию, то медсестра также даст рекомендации и советы. Педиатр изучит обменную карту, оценит физическое состояние ребенка, посмотрит на то, как он себя чувствует. Если у мамы есть вопросы, советую записывать их заранее, чтобы ничего не забыть.

Врач также может задавать маме разные вопросы. Например, «Как проходили ваши роды?», «Что происходило с ребенком в роддоме?», «Принимали ли вы или ребенок какие-то лекарства в роддоме?» и т.д. Поэтому советую маме заранее просмотреть обменную карту или вспомнить, что ей говорили в роддоме. Будет хорошо, если мама сможет простыми словами объяснить, что происходило в роддоме. Тогда первая консультация врача-педиатра уже будет полной и рассчитанной конкретно на вашего малыша.

Осмотры детей с группами риска

Ребенка с риском развития патологии центральной нервной системы осматривают в первые 3 дня после выписки, а затем – на 14-й и 20-й день жизни.
Если у малыша риск реализации внутриутробной инфекции или риск развития синдрома внезапной детской смерти, педиатр осматривает его в первые 3 дня после выписки, а затем – в первый месяц жизни 1 раз в неделю, то есть всего 4 осмотра. Но 4-й осмотр в один месяц будет уже не на дому, а в поликлинике.
Если у ребенка риск развития анемии, он осматривается в первые 3 дня после выписки, и затем – еще 2 раза в месяц. Всего у него будет 3 осмотра. Если говорить про осмотры медсестры, то кратность этих осмотров определит врач-педиатр. Это может быть как 1 раз в неделю, так и чаще. Зависит это от состояния здоровья вашего малыша.
1 месяц. Поход в поликлинику

Когда вашему ребенку исполняется 1 месяц, его ждет первый поход в поликлинику на осмотр к врачу-педиатру. Лучше идти в так называемый День здорового ребенка. В нашей стране это каждый вторник. В этот день в поликлинику приходят только здоровые дети на профилактические осмотры.

Никакой особой подготовки проводить не нужно. С собой мама может взять пеленку, а также необходимые вещи для выхода с малышом. Это может быть подгузник, влажные салфетки. Если малыш на искусственном вскармливании, то мама может взять с собой запас смеси. Если вы проводили какие-либо анализы в частных центрах, то обязательно принесите с собой результаты.

На осмотре врач-педиатр измерит вес, рост, окружность грудной клетки, окружность головы ребенка, чтобы посмотреть, в норме ли прибавка в весе, и оценить, как малыш развивается. Это называется антропометрия. Врач делает это с помощью специальных весов и сантиметровой ленты.

Если все с малышом хорошо, то следующий прием врач обычно назначает в 2 месяца. Тогда ребенку будет проводиться первая профилактическая прививка.

Поговорим про детей с группой риска. 

Если у ребенка риск развития патологии центральной нервной системы, то в месяц рекомендуется прийти на осмотр к врачу-неврологу. Также в возрасте одного месяца проводится УЗИ головного мозга.
Ребенку с риском синдрома внезапной детской смерти в месяц его жизни делается ЭКГ. По показаниям может быть назначено УЗИ головного мозга, внутренних органов, вилочковой железы.
Ребенку с остальными группами риска обследования делают только по назначениям врача-педиатра. Например, это может быть осмотр кардиолога, невролога.
Анализы

Для здоровых детей в 1 месяц сдавать общий анализ мочи и общий анализ крови не требуется. Эти анализы, а также ЭКГ (если ее не проводили в роддоме) рекомендуется сдать малышу до 3-го месяца жизни, а когда именно это сделать, посоветует ваш врач-педиатр.

Ребенку с риском развития патологии ЦНС никаких дополнительных анализов сдавать не надо. До 3 месяцев, как и здоровым деткам, детям нужно сделать общий анализ крови, мочи и ЭКГ (если она не была сделана в роддоме).
Ребенку с риском реализации внутриутробной инфекции общий анализ крови и мочи нужно сдавать дважды (в 1 и в 3 месяца). 
Ребенку с риском развития анемии проводится общий анализ крови в 1 и в 3 месяца жизни. Биохимический анализ крови проводится по медицинским показаниям, если назначит врач-педиатр.
Ребенок с риском синдрома внезапной детской смерти сдает общий анализ крови и общий анализ мочи до 3 месяцев. Дополнительно в месяц такому малышу делают ЭКГ, даже если она была проведена в роддоме.
2 месяца 

В 2 месяца мама с малышом приходят на еще один осмотр педиатра. Врач проведет антропометрию, даст маме рекомендации, а также даст свое заключение – возможно проведение вакцинации или нет. 

В 2 месяца ребенку проводится вакцинация от таких заболеваний, как дифтерия, коклюш, столбняк, гемофильная палочка типа B и гепатит B, полиомиелит.


${separator}

Урок 2. 3-4 месяца жизни. Невролог, хирург, офтальмолог

Малышей до 3 месяцев или в 3 месяца рекомендуется один раз привести на осмотр к узким специалистам:

невролог
хирург
офтальмолог
Почему важны визиты к этим врачам? 

Осмотр хирурга 

Если педиатр рекомендует обратиться к хирургу пораньше (не ждать возраста 3 месяцев), то рекомендуют не откладывать визит к этому доктору. У мальчиков может быть крипторхизм (это неопущение яичек), гидроцеле (это водянка яичек), также у малышей (как у мальчиков, так и у девочек) может быть пупочная грыжа. Врач-хирург после осмотра малыша дает свое заключение и рекомендации по дальнейшему наблюдению ребенка. А также решает вопрос, необходимо ли оперативное вмешательство, и в какие сроки (это если  необходимо).

Осмотр офтальмолога

В отличие от хирурга показывать ребенка офтальмологу как можно раньше нет смысла. Только если вас не направил туда педиатр. Ведь глазки малыша почты закрыты, открываются с трудом, и хорошо посмотреть глазное дно и заподозрить патологию врачу будет нелегко. 

Обсудим, как же проходит осмотр и зачем грудничку окулист. Во время осмотра ребенок находится на руках у мамы в вертикальном положении, повернут личиком к доктору, спинкой к маме. Офтальмолог оценивает предметное зрение; смотрит, как ребенок следит глазами за игрушкой; смотрит, есть ли помутнение хрусталика или нет; оценивает размер глазного яблока. Это визуальный осмотр. 

Почему он важен? В этот период можно заподозрить такие заболевания, как косоглазие, врожденная катаракта, глаукома. Также с помощью офтальмоскопа доктор проверяет глазное дно, строение глаза (сетчатку, сосуды). Можно найти такие заболевания, как миопия, дальнозоркость, астигматизм. По показаниям доктор может пригласить вас на повторный осмотр в 6-7 месяцев, когда ребенок будет сидеть сам.

Осмотр ортопеда

В период к 3 месяцам рекомендуется прийти на первый осмотр ортопеда. По мировым рекомендациям, ортопеда можно посетить и ранее – в 2 месяца. Доктор оценит размеры и форму всех частей тела, посмотрит, как двигаются ручки и ножки малыша, внимательно обследует суставы и проверит, одинаковой ли длины ножки. 

В этом возрасте можно выявить некоторые заболевания. Например, дисплазия тазобедренных суставов, врожденный вывих бедра, врожденная мышечная кривошея, врожденная косолапость. Доктор может сделать УЗИ шеи и тазобедренных суставов. Чем быстрее начать лечить эти состояния, тем лучше будет эффект, а малыш будет здоровым и будет радовать вас.       

После 3 месяцев и всех проведенных осмотров ребенка, остается только планово ежемесячно посещать врача-педиатра. В 3 и в 4 месяца педиатр проведет антропометрию (измерение роста, веса), также педиатр оценит физическое, нервно-психическое развитие малыша. А еще в 3-4 месяца проводится вакцинация ребенку.

Нужно ли каждый месяц перед визитом к врачу сдавать анализы? Нет. Этого наши официальные протоколы не требуют. Если вы решили проводить вакцинацию малышу у доктора в частном центре, то по желанию и требованию руководства центра ребенку может понадобиться свежий анализ крови для проведения вакцинации. Ни здоровому ребенку, ни ребенку с группой риска больше ничего дополнительно в 3 и 4 месяца делать  не нужно. Обследования узких специалистов пройдены. Далее все по показаниям  назначит врач-педиатр.

Всемирная организация здравоохранения активно поддерживает сохранение грудного вскармливания детей до 2 лет. Исходя из этого, врачи будут давать рекомендации по грудному вскармливанию для мамы. Например, в 3 месяца у мамы может случиться так называемый лактационный криз. 

Маме кажется, что у нее вдруг пропадает молоко, его становится мало. На самом деле, молоко не пропадает, его вырабатывается ровно столько, сколько нужно ребенку. Но за счет того, что у ребенка может быть скачок развития даже за 2 дня и он захочет есть больше, грудь мамы не успевает адаптироваться и выработать больше молока. Нужно проводить все мероприятия по поддержанию лактации (частое прикладывание ребенка к груди, достаточный питьевой режим, полноценное питание мамы) и подождать пару дней. Очень часто мамы начинают добавлять смеси, которые на самом деле не нужны. И здесь очень важным является общение с врачом-педиатром – он даст вам рекомендации, ответит на все вопросы. И помните: если продолжать грудное вскармливание, пусть и в затруднившихся условиях, то молоко обязательно прибудет.

В 3-4 месяца обычно у ребенка наблюдается первый скачок роста, скачок развития. Малыш начинает осмысленно смотреть на игрушки, хватать и изучать их. Наблюдается значительная прибавка в росте и весе. Если в первые месяцы ребенок набирал сил, то в 3-4 месяца он резко вырастает. И может возникнуть еще одна проблема – так называемый регресс сна.

Малыш больше начинает интересоваться окружающим миром, активно наблюдать за предметами и людьми. И на фоне этого у него может произойти регресс сна. Как это проявляется? К 3 месяцам у малыша уже вырабатывается определенный режим сна и бодрствования, режим кормления, и резко все может вернуться в то состояние, когда малышу еще не было месяца. Ребенок беспорядочно ест и спит, режим сбивается.

Дорогие мамы, не стоит переживать из-за этого, все обязательно наладится. Нужно понимать, что это временная ситуация. Нужно дать ребенку возможность прожить эти дни так, как он хочет, как ему удобно. Важно не пытаться удерживать старый режим, а подстроиться под малыша. И уже через пару дней мама с малышом выйдут на новый режим дня. И, конечно, советуйтесь с вашим врачом, он обязательно поможет!

Также скажу про еще одно важное изменение. Если у вашего ребенка группа риска развития внутриутробной инфекции, то уже в 3 месяца этот риск может быть снят, если он никак не реализовался.

${tb5c2_2}

Урок 3. 5-6 месяцев жизни. Прикорм, консультации педиатра

К 5-6 месяцу жизни ребенок уже прошел все осмотры специалистов и необходимые обследования. Согласно рекомендациям в нашей стране, в этот период ребенка осматривает только педиатр один раз в месяц. В эти визиты врач как обычно проводит антропометрию, дает заключение о физическом и нервно-психическом развитии ребенка.

Особенно хочу отметить визит к врачу в 6 месяцев. Именно в этот период (в 6 месяцев) Всемирная организация здравоохранения рекомендует введение прикорма. Ребенку на искусственном вскармливании можно вводить прикорм в период 4,5-6 месяцев. И здесь в помощь маме консультации врача-педиатра.

Конечно, мы ориентируемся не только на цифры нормы и мировые рекомендации. Лучший вариант – это ориентироваться на признаки готовности ребенка к прикорму. По международным данным, обычно такие признаки могут проявиться у детей с 5,5 до 7 месяцев.

Ребенок может сидеть не заваливаясь, с поддержкой взрослого. Сразу обращаю внимание, что ребенок может еще не садиться самостоятельно. Это может произойти чуть позже. Но в положении полусидя под углом 60 °С или находясь на стульчике для кормления, ребенок может сидеть и не заваливаться назад, вперед или вбок.
У ребенка есть интерес к пище. Например, когда кто-то ест за столом, ребенок тянется ручками. И не к вилке или тарелке, а именно к продукту питания, который кушают родители.
У ребенка пропадает выталкивающий рефлекс. Если малышу предлагается ложка (например, с кашей), он не пытается вытолкнуть ее языком, а пробует губами съесть или попробовать продукт.
Если вы проверили эти признаки и малыш готов, то можно начинать предлагать продукты прикорма. Это может быть как в 5 месяцев, так и позже. Если вы проверили признаки и малыш еще не готов – подождите несколько дней и попробуйте еще раз. И на этом важном этапе маме понадобится помощь педиатра. Обычно в 5 месяцев на приеме доктор рассказывает маме, как проверить эти признаки готовности прикорма, подготавливает родителей к этому новому этапу. Также врач расскажет, с каких продуктов можно начинать прикорм, как организовать место, где ребенок будет принимать пищу. Это самый важный вопрос, который обычно интересует мам и пап в этот период.

До полугода малыш уже прошел всех узких специалистов. Если кто-то из врачей рекомендует повторный прием, то маме нужно запланировать эти визиты. В 6 месяцев рекомендуется прийти на осмотр к ортопеду. На этом этапе ортопед может выявить наличие таких обменных заболеваний, как фосфат-диабет, рахит. Также в этом возрасте выявляется готовность ребенка сидеть, проверяются другие моторные навыки, и врач дает рекомендации по их развитию.

Если у ребенка была выставлена группа риска, то уже к полугоду некоторые из них могут быть сняты. Так, ребенка с риском развития патологий ЦНС в полгода обязательно должен посмотреть врач невролог. Если все хорошо и риск не реализовался, то ребенок снимается с учета. В 6 месяцев ребенку с риском развития анемии обязательно берут общий анализ крови. 

Также в 6 месяцев ребенок может начать присаживаться. Это тоже очень важная тема для родителей. И педиатр на приеме может рассказать, как правильно показать ребенку нужное положение, какую гимнастику можно проводить в этот период.

Но основное изменение, которое происходит в этот период в жизни малыша, – это именно введение новых продуктов питания. Мозг ребенка больше работает на пищевой интерес, чем на физическое развитие. Если в этом возрасте не успеть ввести разные продукты питания и разных консистенций, то в 9-10 месяцев у ребенка начнется новый этап развития, и ему будет не так интересна еда, ввести новые продукты будет очень сложно.

${separator}

Урок 4. 7-10 месяцев жизни. Педиатр, ортопед

По официальным рекомендациям в нашей стране, в 7, 8, 9 и 10 месяцев родители как обычно раз в месяц приходят с малышом в поликлинику.

Врач проводит антропометрию, оценивает физическое и нервно-психическое развитие ребенка, отвечает на все волнующие вопросы мамы. Маме советую выписывать вопросы и задавать их на приеме. На этом этапе именно доктор даст вам ценные подсказки по всем волнующим вопросам развития и режима дня малыша. Одни из самых популярных вопросов родителей в этот период: нужны ли ребенку массажи, почему он не садится или не встает, что норма, а что патология.

Также еще один важный вопрос, который волнует мам и пап в промежуток 7-10 месяцев, – это именно развитие ребенка. В частности, моторное развитие, ведь малыш начинает много двигаться, он начинает садиться, вставать у опоры, ползать. У мам и пап возникают разного рода вопросы. Например: «А правильно ли ребенок все делает?», «Может, он уже должен ходить?».  На все эти вопросы вам сможет ответить врач-педиатр. Если врача что-то насторожило, он может дополнительно посоветовать вам визит ортопеду или неврологу.

Также в этот период мамы волнуются, как правильно сформировать режим дня ребенка и на что обратить внимание (на еду, развитие моторных навыков, игры, прогулки). Задавайте вопросы педиатру, он обязательно вам поможет.

Никакие анализы в этот промежуток жизни ребенка не нужны. Вакцинация также не проводится. Если ребенок наблюдался ранее по какой-то проблеме, то каждый доктор (например, хирург или невролог) может рекомендовать дополнительные осмотры. 

До 9 месяцев родителям рекомендуется разнообразить рацион ребенка, ввести новые консистенции – пюре, кусочки. Ввести такие продукты питания, как мясо, творог. Если малыш до 10 месяцев не научился жевать кусочки, то далее ему это будет сделать сложнее. Конечно, основным источником питания, по рекомендациям Всемирной организации здравоохранения, остается грудное молоко. Но прикорм будет дополнением по витаминам и микроэлементам, а также поможет малышу полноценно развиваться за пределами диады «мама- малыш», ведь он будет уже почти самостоятельно кушать.

К 9-10 месяцам у вас уже будет определенный сформированный пищевой рацион. И далее нужно активно заниматься развитием моторных навыков малыша.

В возрасте ребенка 9-12 месяцев, а чаще всего после того как ребенок начал ходить (самостоятельно или возле опоры), рекомендуется осмотр ортопеда. В этот период нужно точно убедиться, что тазобедренные суставы сформированы хорошо, что у ребенка есть все шансы научиться стоять возле опоры, а затем ходить. Врач подводит итог по развитию моторных навыков и готовности ребенка к ходьбе, а также способности костно-мышечной системы справляться с возрастающими нагрузками. 

Конечно, врач-ортопед дает важные рекомендации для родителей. В этом возрасте можно почти с полной уверенностью говорить о том, что у ребенка нет врожденной патологии костно-мышечной системы. А если ребенок проходил лечение у ортопеда, тогда врач оценит эффективность этого лечения и проведет коррекцию.

${tb5c2_3}

Урок 5. Год жизни. Что такое группа здоровья?

В 11 месяцев ребенка осматривает только врач-педиатр, никакие дополнительные обследования и осмотры узких специалистов не нужны.

В год жизни ребенка ждет осмотр педиатра в поликлинике. Педиатр дает оценку физическому развитию, оценивает состояние здоровья малыша и определяет группу здоровья. И в зависимости от группы здоровья дает дальнейшие рекомендации.

Если ребенок не прошел осмотр врача оториноларинголога до 12 месяцев, то обязательно нужно посетить этого доктора. ЛОР посмотрит носик, ушки, ротовую полость ребенка. Такой же осмотр проводит врач педиатр каждый месяц. 

Также в год жизни малыш отправляется к стоматологу. Стоматолог в 1 год смотрит наличие зубов, оценивает уздечку языка и губы, прикус.

В год малышу проводятся лабораторные обследования: 

общий анализ крови
общий анализ мочи
Обсудим, как правильно сдавать анализы.

Анализ крови

Его важно сдавать натощак. Если ребенок находится на грудном вскармливании, то допустимо, чтобы мама покормила ребенка. Через 3-4 часа у малыша берут анализ, а затем мама опять кормит. 

Анализ крови могут брать из пальца или из вены. Если вы сдаете анализ из пальчика в поликлинике, то уточните заранее, стоит ли вам взять ланцет (если мама хочет) или он уже есть в поликлинике. Также могут брать кровь  скарификатором (это пластиночка с иголочкой на конце). Ланцет менее травматичный и менее болезненный. Если малыша ждет забор крови из вены, то маме можно взять что-нибудь сладенькое. Детям на грудном вскармливании можно сразу после забора предложить грудь. А деткам постарше – полезную сладость (зефир, мармелад, пастилу или любую сладость), которую можно вашему малышу.

Часто мамы спрашивают, можно ли пить накануне анализа или, например, чистить зубы. Стакан воды никак не повлияет на результаты, а вот сладкие напитки пить нельзя. Чистить зубы накануне общего анализа крови можно. Ограничений в продуктах питания тоже нет – с вечера малышу можно давать все. Почти все продукты перевариваются за 6-8 часов, а с утра можно спокойно идти и сдавать анализы.

Анализ мочи

Обсудим, как правильно собрать мочу. Для общего анализа мочи диагностическое значение имеет только утренняя порция мочи.

Нужно подмыть ребенка водой без мыла и просушить салфеткой.
Собирать мочу нужно только в специальный стерильный контейнер. Его можно купить в аптеке. 
У мальчиков можно использовать стерильный мочеприемник. У девочек лучше его не использовать, потому что есть большая вероятность попадания кала, эпителиальных клеток с наружных половых органов. Если мочу собираем в мочеприемник, тогда после сбора необходимо отрезать острый кончик и перелить в стерильный контейнер. Нельзя переливать мочу через липкий край, нельзя переливать мочу из горшка, тарелки, кружки, выжимать из пеленок.

Далее маме или папе необходимо собрать среднюю порцию. Ребенок начинает мочиться в горшок или памперс, потом подставляем контейнер. И ребенок заканчивает мочиться в горшок или памперс. Собранную мочу необходимо доставить в лабораторию в течение 2 часов. Если моча в специальной пробирке со стабилизатором, то можно дольше. Но обратите внимание: замораживать мочу нельзя.

Анализ кала

Здесь рекомендуем придерживаться следующих правил:

Накануне исключить прием различных продуктов, препаратов, которые могут влиять на желудочно-кишечный тракт (прием антибиотиков, слабительные).
Кал нужно собирать не с подгузника. Если собирать с подгузника, то кал может смешиваться с мочой, и может изменяться результат анализа. В таком случае ребенку лучше наклеить мочеприемник и подождать, пока ребенок сходит в туалет по-большому. Тогда можно собрать чистый кал без мочи. С подгузника кал можно брать только при анализе на ПКФ (патогенную кишечную флору).
Кал лучше перемешать и собирать из разных мест. Набирать можно ватной палочкой или специальной лопаткой из контейнера.
Собирать кал лучше в специальный контейнер. Хранить его нужно недолго (не более 7 часов). 
Лучше всего кал доставить в лабораторию в день забора. Кал на копрологию можно хранить в холодильнике при температуре +/- 4 °С, но не больше 4-7 часов. Кал на гельминты лучше хранить в теплом состоянии. Желательно хранить при той температуре, при которой он был собран. Доставить в лабораторию нужно как можно быстрее.

Вернемся к обсуждению обследований в год жизни.

Если ребенку ЭКГ проводилась только в роддоме или есть какие-то шумы, изменения, то в год жизни проводится ЭКГ.

В обязательном порядке электрокардиограмму делают малышам, которые находились в группе риска по развитию синдрома внезапной детской смерти. Даже если ЭКГ делали в роддоме, и в месяц жизни эту процедуру рекомендуют провести обязательно. А вот УЗИ сердца делается только по медицинским показаниям, по назначению врача. Если ребенок наблюдался у невролога и врач рекомендовал повторную консультацию в год, то обязательно посещаем и невролога.

Напомню, что к году жизни снимаются уже все группы риска. Теперь врачу остается лишь определить группу здоровья. 

Группы здоровья

Первая группа здоровья – это здоровые дети без отклонений в физическом, нервно-психическом развитии, без каких-то функциональных отклонений, без хронических заболеваний. Они могут иметь какие-нибудь незначительные морфологические отклонения, которые не влияют на состояние здоровья и не требуют коррекции. Например, у ребенка гемангиома. Это врожденное заболевание, но оно не несет нарушений функций никакого органа, она не растет и не требует лечения или удаления.
Вторая группа здоровья – это дети, у которых нет хронических заболеваний, но имеются какие-то функциональные, морфо-функциональные нарушения. Например, ребенок отстает по массе и росту, но не имеет заболевания, которое явилось причиной. Просто ребенок мало и плохо кушает. Также это могут быть дети, которые часто болеют респираторными заболеваниями, дети с последствиями травм, операций, с физическими недостатками, но с сохранением функций органов.
Третья группа здоровья – это дети, которые имеют хронические заболевания с сохраненными или компенсированными функциональными возможностями. Например, у ребенка фенилкетонурия. Мы говорили  с вами о ней в первом уроке. Это заболевание не имеет никаких клинических проявлений, ведь у ребенка определенная диета. Но это заболевание никуда не исчезнет.
Четвертая группа здоровья – это дети, которые страдают хроническими заболеваниями с декомпенсацией функциональных возможностей организма. То есть когда есть какое-либо заболевание, и оно требует помощи специалистов, нахождения в стационаре, постоянной терапии. Это дети с физическими недостатками, последствиями травм и операций и нарушением функций органов, с ограничением возможности обучения и труда, патологиями центральной нервной системой и многое другое.

${tb5c2_4}

Урок 6. Прививки ребенка в первый год жизни

Вакцинация – это самый эффективный и, в отличие от естественной болезни, безопасный способ формирования иммунитета от многих инфекций. 

Всемирная организация здравоохранения подчеркивает, что вакцинация – это самый простой и безопасный способ массовой профилактики и защиты человека от вирусных и бактериальных болезней до того, как человек встретится с самим возбудителем в естественной среде. Можно сделать вывод, что многие вакцины необходимо сделать ребенку как можно быстрее, а не ждать того момента, когда ребенок встретится с этой инфекцией.

Давайте разберемся, почему. У человека существует 2 вида иммунитета – врожденный и приобретенный. Врожденный иммунитет – это тот иммунитет, с которым человек появляется на свет. А приобретенный иммунитет формируется на протяжении жизни после встречи с какими-то инфекционными агентами. И вакцинация является одним из способов приобретенного иммунитета. 

Например, после перенесенной инфекции у человека формируются антитела к этому микробу или вирусу. И эти антитела, как маленькие солдатики, сидят и ждут повторной встречи с этим микробом. При повторной встрече антител может не хватить, но пока эти солдатики атакуют микроб, организм человека вырабатывает новые антитела для защиты от инфекции. 

То же самое происходит и с вакциной. Да, иммунитет, приобретенный после инфекции, может быть более длительный. Ведь человек получает большую дозу инфекционного агента – значит, вырабатывается больше антител. В случае с вакциной доза инфекционного агента маленькая, антител вырабатывается меньше. Их может хватить лишь на определенное время. Но даже если после вакцинации антител уже нет, то организм при встрече с возбудителем все равно умеет вырабатывать их. И тогда у человека есть возможность быстрее побороть инфекцию или перенести ее в легкой форме.

Иммунитет, который формируется после введения вакцины, похож на тот, что формируется при естественной болезни. Однако важно помнить, что цена за иммунитет, который сформируется в результате болезни, может быть слишком высока. И развития многих серьезных осложнений, которые могут вызвать различные заболевания, можно избежать благодаря вакцинации.

Какие прививки рекомендуется сделать ребенку?

Для плановой вакцинации населения в каждой стране есть календарь прививок. От каких инфекций нужно проводить вакцинацию, обычно рекомендует Всемирная организация здравоохранения. Сейчас на экране вы видите Национальный календарь прививок для нашей страны. Календарь включает в себя перечень вакцинаций от 12 инфекций. Сейчас я расскажу, какие прививки рекомендуется сделать ребенку в первый год жизни.

В первые сутки жизни малыша – вакцинация против гепатита B. Многие мамы волнуются, зачем делать эту прививку так рано. Это заболевание (гепатит B) может не иметь никаких клинических проявлений. Например, им может болеть мама и даже не знать об этом. Чтобы защитить ребенка и минимизировать риск передачи инфекции, рекомендуется провести вакцинацию так рано.
На третьи сутки после вакцинации от гепатита B – прививка от туберкулеза. Однократно вводится вакцина, о которой вы уже наверняка слышали, она называется БЦЖ-М. После нее ребенок имеет длительный иммунитет.
В 2, 3 и 4 месяца – вакцины против ряда опасный инфекций: таких, как дифтерия, коклюш, столбняк, полиомиелит, гемофильная палочка типа В и гепатит B. Почему необходимо вводить эти вакцины целых 3 раза (в 2, 3 и 4 месяца)? Чтобы снизить дозу на каждое введение и чтобы сформировать хороший иммунитет.
В 1 год жизни ребенка – вакцинация против кори, краснухи и эпидемического паротита. 
На сегодня в нашей стране предлагается бесплатная вакцинация. По желанию и выбору родителей вакцина может выполняться на платной основе. По разновидностям различных вакцин вас может проконсультировать врач-педиатр.

Если вы по каким-то причинам пропустили очередную прививку своему малышу, то обратитесь за консультацией к педиатру. В такой ситуации ваш доктор скорректирует план вакцинации малыша или составит новый.

${separator}

Урок 7. Популярные вопросы о вакцинации

Вакцина в своем составе содержит непосредственно прививочный антиген, а также специальные вещества. Они придают стерильность, стабильность вакцине, а также есть вещества, которые могут усиливать иммунный ответ. Например, многих родителей волнуют такие вещества в составе, как ртуть и алюминий.

Соединения ртути

Как правило, они находятся в монодозных вакцинах (т.е. в вакцинах, которые содержат один компонент). Их функция – обеспечить стерильность, то есть профилактику микробного загрязнения вакцины при вскрытии ампулы.

Опасно ли это? Нет. Ртуть содержится во всех органах и системах организма. Доказано, что ртуть даже содержится в крови новорожденных деток еще до вакцинации. Это никак не влияет на развитие ребенка. Никаких научных доказательств вреда ртути нет, тем более в вакцине она содержится в очень маленькой дозировке.

Многие мамы связывают наличие соединений ртути в вакцине с развитием аутизма. На сегодня выводы многих авторитетных и достоверных исследований показывают, что соединения ртути в вакцинах никак не способствуют развитию аутизма. Единственно возможные побочные эффекты, связанные с ртутным консервантом, – это местное покраснение, отек и в крайне редких случаях аллергические реакции.

Соли алюминия

Соли алюминия присутствуют в вакцинах для усиления иммунного ответа и могут улучшать реакцию организма на вакцинацию. Соли алюминия так же, как и ртуть, присутствуют в организме человека. Исследования показали, что алюминий содержится даже в грудном молоке. Также соли алюминия попадают в организм человека с питьевой водой и растительной пищей. Его много в яблоках, цветной капусте, моркови, помидорах, плавленых сырочках и любых продуктах, которые контактируют с алюминиевой упаковкой. Но никакого влияния на ребенка и на развитие каких-либо тяжелых заболеваний они не оказывают. Используемые в вакцинах соли алюминия не распространяются по организму, а сохраняются только лишь в месте введения вакцины, выполняя там свою непосредственную функцию.

Также в вакцинах могут содержаться следы различных веществ, которые использовались при приготовлении вакцин. Например, следы антибиотика, яичный белок, дрожжевой белок, формальдегид. Но все это содержится в очень минимальных дозах. И нет никаких исследований о том, что они могут влиять на развитие каких-либо заболеваний.

Зачастую мамы и папы связывают вакцинацию с развитием различных заболеваний. Если ребенок имеет какое-либо наследственное заболевание, которое себя пока что не проявило, или имеет какую-либо особенность развития, то запустить процесс развития заболевания или прогрессирования заболевания может, к сожалению, что угодно – например, встреча с обычной вирусной инфекцией или даже стресс.

Не опасно ли вводить несколько прививок одновременно?

Многочисленные научные исследования доказали, что одновременное введение нескольких вакцин не оказывает никакого побочного действия на иммунную систему ребенка. Тем более у одновременного введения прививок есть ряд преимуществ. Вам реже придется посещать клинику. Это сэкономит время и снизит риск схватить инфекцию по пути. Кроме того, ребенок получит меньшее количество уколов, и это уменьшит его страх перед посещением врача. Лучше выбирать такие препараты для вакцинации, в которых в одном шприце содержатся все необходимые для вакцинации антигены.

Как подготовиться к вакцинации?

На самом деле, к вакцинации не нужно готовиться никак. Если врач разрешает проведение вакцинации здоровому ребенку, то малыш вместе с родителями отправляется на процедуру без подготовки. 

По нашим нормативным документам, а также по рекомендациям международных организаций, проходить лабораторные исследования (т.е. сдавать анализы) перед каждой прививкой не надо. Только если доктор посчитает нужным и направит вас на анализы. Давать малышу противоаллергические и жаропонижающие препараты накануне прививки не нужно.

Перед прививкой малыша нужно настроить на процедуру, особенно если это ребенок постарше. Если ребенок маленький, то в первую очередь нужно быть спокойными маме или папе, ведь малыш может считывать вашу реакцию. Старшему ребенку постарайтесь объяснить заранее, зачем проводится прививка. А младшего ребенка во время процедуры можно отвлечь любимой игрушкой. Продумайте заранее, на что ваш малыш лучше всего отвлекается.

В день прививки ребенка нужно удобно одеть, чтобы можно было быстро раздеть, а также быстро дать доступ врачу к месту вакцинации. Для совсем маленьких детей это верхняя треть передней поверхности бедра, для детей постарше – верхняя треть плеча.

После вакцинации не стоит торопиться домой. В течение 30 минут рекомендуется находится в поликлинике под наблюдением врачей. Если вдруг у ребенка случится какая-то реакция на вакцину, он сможет быстро получить профессиональную помощь. Не советую в день вакцинации планировать какие-то другие мероприятия, походы к врачам, походы в гости. Но если ребенок себя хорошо чувствует, то можно погулять при хорошей погоде, можно покупать малыша. Здесь никаких ограничений нет.

После прививки

После прививки у ребенка: 

может подняться температура
может появиться болезненность и отек в месте инъекции
Обычно эти реакции кратковременны и проходят в течение нескольких дней.

! Если у малыша после прививки поднялась температура выше 38,5°С, ее можно снизить жаропонижающими препаратами (такие, как парацетамол и ибупрофен) в возрастной дозировке.

Иногда местные реакции такие, как отек и покраснение в месте укола, могут сохраняться дольше. 

если уплотнение растет, становится больше 8-10 мм
ребенок беспокойный больше суток
Рекомендуем обратиться к врачу! Также обратитесь к врачу, если у ребенка высокая температура или она не сбивается, если ребенок долго плачет и кричит. 

Еще у вакцинации есть ряд противопоказаний. О них вам обязательно расскажет ваш врач-педиатр.

Имеют ли право родители отказаться от вакцинации? 

Да, только родители дают согласие или отказываются от проведения каких-либо медицинских вмешательств своему малышу (операция, введение лекарств, вакцинация). Перед проведением вакцинации родители заполняют согласие на вакцинацию или пишут отказ от вакцинации. Если вы думаете об отказе от вакцинации, то обязательно рекомендую вам сначала посоветоваться с вашим врачом педиатром.

 

Урок 8. Почему медицинские наблюдения нужны и важны?

Почему медицинское наблюдение – это важно для здоровья малыша?

Это профилактика заболеваний. 
На приемах врач вместе с мамой решает вопросы по питанию ребенка, его безопасности, уходу, правильно организованному месту сна. Ведь у мам и пап (особенно тех, кто впервые стал родителями) возникает очень много вопросов и даже сомнений. Казалось бы, незначительные ошибки, например, в питании могут привести к недобору веса ребенка и проблемам со здоровьем. Конечно, этот пункт включает и проведение профилактических прививок от различных заболеваний.

Это отслеживание роста и развития ребенка, а также его здоровья – и физического, и нервно-психического. 
Благодаря визитам к врачу мы можем видеть, как ребенок каждый месяц развивается, и делать какие-либо выводы. Если что-то пошло не так, врач сразу может увидеть это и вовремя назначить лечение. 

Это социальный контакт. 
Создание доверительных отношений между врачом, родителями и ребенком – это очень важный процесс. Здорово, когда ребенок привыкает к доктору, который его наблюдает. И здорово, когда доктор знает конкретную семью и ее особенности.

Доктор – это не просто человек, который лечит какое-то заболевание. Это человек, который является спутником семьи по контролю здоровья. По уставу Всемирной организации здравоохранения, здоровьем является состояние полного физического, душевного и социального благополучия, а не только отсутствие болезней и физических дефектов. И осмотры ребенка у врача, общение ребенка с врачом играют большую роль в том, чтобы ребенок был здоров во всех смыслах этого слова. Такой подход помогает полноценно развиваться ребенку физически, психически и адаптироваться социально.

Для снижения младенческой  детской смертности и повышения уровня здоровья и развития детей Всемирная организация здравоохранения разработала глобальную стратегию охраны здоровья женщин, детей и подростков. Что касается детей, самый важный год для развития ребенка и диагностики врожденных заболеваний, для улучшения качества здоровья, для улучшения жизни на перспективу – это именно первый год жизни. Для этого Всемирная организация здравоохранения, в том числе и различные международные общества педиатров (например, «Американская академия педиатрии») разрабатывают различные рекомендации по осмотру ребенка до года и скринингу на различные заболевания. Там указано количество визитов, в какой месяц ребенок должен осматриваться педиатром, какие обследования он должен проходить. 

В нашей стране тоже есть такой документ. Разработано Постановление, на основании которого дети проходят периодические осмотры на первом году жизни и далее в определенные сроки. Мы уже с вами обсудили подробно в прошлых уроках, какие визиты рекомендуются ребенку согласно этому документу. Это все нужно, чтобы как можно раньше выявить какие-либо заболевания, помочь ребенку вылечиться и восстановиться, улучшить качество его жизни.

Первое, что я хочу посоветовать мамам и папам, – это наблюдаться вместе с малышом у одного и того же врача-педиатра. В поликлинике или частной клинике. 

Почему важно иметь постоянного доктора и наблюдаться у него с рождения малыша? Врач поможет не только в вопросах профилактики, вопросах питания, полноценного развития, но также немаловажен такой вопрос, как формирование доверительных отношений между ребенком и доктором. В дальнейшем любые рекомендации врача малыш будет воспринимать серьезно, будет доброжелательно настроен к визитам.

Это хорошо и для врача. Когда врач смотрит ребенка с первых дней его жизни, постоянно наблюдает за его развитием, когда доктор общается с семьей, знает особенности семьи и привычки, тогда врачу легче давать рекомендации и наблюдать ребенка, исходя из норм конкретно взятой семьи. Ведь каждая семья индивидуальна. Например, есть семьи вегетарианцев. И я как врач знаю, что можно прекрасно компенсировать ребенка по каким-то продуктам питания в семье вегетарианцев. И буду давать советы и рекомендации, зная такую особенность семьи. Если у кого-либо из членов семьи, родителей есть серьезные заболевания, инвалидность, то врач учтет и эти особенности. Здорово, когда у врача есть возможность руководствоваться интересами семьи.

Если по каким-то причинам вы хотели бы поменять врача-педиатра на участке, вы можете обратиться к заведующему поликлиникой. Мама может прийти к руководству, привести четкие обоснования и попросить поменять доктора. Я вам посоветую, конечно, находить общий язык и способы взаимодействия, подстраиваться под режим работы вашего доктора. Так, в команде вам будет легче и проще следить за состоянием здоровья малыша. 

Если ребенок здоров, родители могут даже находиться с доктором на связи онлайн, и врач сможет ответить на вопрос, ведь он уже знает эту семью и этого ребенка, знает многое о его здоровье. Тогда можно минимизировать посещение врача. Здесь нужно смотреть по своим запросам и возможностям.

Можно ли пропустить какой-то прием, если с ребенком все хорошо?

Согласно официальным постановлениям, мама имеет право отказаться от прохождения осмотра, если она видит для себя другую альтернативу. Например, она планирует пройти осмотр у частного врача-педиатра. Такой вариант, конечно, разрешен.

Согласно нормативным документам нашей страны, родители имеют право выбрать частное или государственное наблюдение у врача. Если родители выбирают наблюдение в частном медицинском центре, то советую сообщить об этом в поликлинике. Любая частная клиника – это такая же организация здравоохранения, которая имеет лицензию на оказание медицинской деятельности и подчиняется тем же законам, что и государственные учреждения. В частном центре малышу заводят такую же амбулаторную карту. Иногда в вашей поликлинике могут попросить выписку из амбулаторной карты частного центра.

Помните, что, к сожалению, не всегда родители могут определить опасность того или иного состояния ребенка или увидеть первые симптомы заболеваний. Поэтому отказаться от осмотра педиатра или оказания медицинской помощи – это большая ответственность. Попробуйте договориться, попробуйте найти того самого доктора и то место, где вы будете готовы получать эту помощь. Не стоит отказываться от того, что вам дается бесплатно, тем более это сделано для здоровья вашего ребенка. А также не стоит экономить на здоровье своего малыша, ведь это вложение на много лет. Доктор готов разделить с вами ответственность, совместно с вами находить решение сложных и не очень проблем, и всегда остается на вашей стороне и поддерживает ваш выбор.

Берегите своих детей и будьте здоровы!


    `,
};
