import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Interests, UsageOptions } from 'constants/constants';


const initialState: {
  usageOption: UsageOptions | null;
  dateOfPregnancy: number | null;
  dateOfChildBirth: number | null;
  interests: Interests[];
} = {
  usageOption: null,
  dateOfPregnancy: null,
  dateOfChildBirth: null,
  interests: [],
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    updateUsageOption: (state, action: PayloadAction<UsageOptions | null>) => {
      state.usageOption = action.payload;
    },
    updateDateOfPregnancy: (state, action: PayloadAction<number | null>) => {
      state.dateOfPregnancy = action.payload;
    },
    updateDateOfChildBirth: (state, action: PayloadAction<number | null>) => {
      state.dateOfChildBirth = action.payload;
    },
    updateInterests: (state, action: PayloadAction<Interests>) => {
      const { interests } = state;
      const value = action.payload;

      interests.includes(value)
        ? (state.interests = [...state.interests.filter((item) => item !== action.payload)])
        : (state.interests = [...state.interests, action.payload]);
    },
    resetInterests: (state) => {
      state.interests = [];
    },
    resetOnboarding: () => initialState,
  },
});

const { reducer, actions } = onboardingSlice;

export const {
  updateUsageOption, updateInterests, updateDateOfPregnancy, updateDateOfChildBirth, resetOnboarding, resetInterests,
} = actions;

export default reducer;
