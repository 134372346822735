import ExpertInfo from 'components/ExpertInfo/ExpertInfo';
import { CourseData } from 'types';
import styles from './NotMobileIcons.module.css';

type CategoryVideoProps = {
  experts: any;
  item: CourseData;
};
function NotMobileIcons({ experts, item }: CategoryVideoProps) {
  return (
    <div className={styles.favoriteContainer}>
      {!!experts?.length &&
        //@ts-ignore
        experts?.map((expert) => <ExpertInfo key={item.id} item={expert} />)}
    </div>
  );
}

export default NotMobileIcons;
