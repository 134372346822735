import { FC, memo } from "react";
import styles from './TitleH1.module.css';
import Highlighter from "react-highlight-words";
import classNames from "classnames";


export const TitleH1: FC<{
  text: string;
  highlight?: string;
  light?: boolean;
  center?: boolean;
}> = memo(({ 
  text,
  highlight,
  light = false,
  center = false,
}) => (
    <>
      {
        !!highlight && (
          <Highlighter
            textToHighlight={ text }
            searchWords={ [highlight] }
            className={ classNames(
              styles.text,
              light && styles.text_light,
              center && styles.center,
            ) }
            highlightClassName={ classNames(
              styles.highlight,
              light && styles.highlight_light,
              center && styles.center,
            ) }
            autoEscape
          />
        )
      }
      {
        !highlight && (
          <div className={ classNames(
            styles.text,
            light && styles.text_light,
            center && styles.center,
          ) }>
            { text }
          </div>
        )
      }
    </>
));
