import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import { useContent } from 'hooks/useContent';

import ExpertInfo from 'components/ExpertInfo/ExpertInfo';
import FavoriteButtons from 'components/FavoriteButtons/FavoriteButtons';
import { selectExpertsById } from 'pages/ExpertScreen/store/selectors';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/store';
import { getType, optional } from 'utils/other';
import { getStringDuration } from 'utils/time';
import ClockContainer from '../../components/ClockContainer/ClockContainer';
import FavoriteContainer from '../../components/FavoriteContainer/FavoriteContainer';
import styles from './CategoryAdvice.module.css';
import CategoryAdviceVideo from './components/CategoryAdviceVideo/CategoryAdviceVideo';
import { CoursesHeader } from 'components/CoursesHeader/CoursesHeader';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import CourseEmptyWrapper from 'components/CommonCoursesCategory/components/CourseEmpty/CourseEmptyWrapper';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect } from 'react';
import { watchAdviceDetailsEvent } from 'analytic/trackEvents';
import { useExpertsApi } from 'hooks/useExpertsApi';
import { RecommendationType } from 'types';

function CategoryAdvice() {
  const { contentId } = useParams();
  const type = getType();
  const navigate = useNavigate();
  const t = useTranslate();
  useExpertsApi();
  // @ts-ignore
  const { content, loading } = useContent(contentId, type);

  useEffect(() => {
    if (!!contentId) {
      watchAdviceDetailsEvent(contentId);
    }
  }, [contentId]);
  useExpertsApi();
  const experts = useAppSelector(
    selectExpertsById(Object.keys(optional(content?.expert)))
  );

  const { isMobile, isTablet } = useAppWindowSize();

  const size = isMobile ? 20 : 30;
  if (loading) {
    return (
      <CourseEmptyWrapper
        isTablet={isTablet}
        isMobile={isMobile}
        title={t('совет')}
      />
    );
  }

  if (!content?.enable) {
    navigate('*');
    return null;
  }
  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.background}>
      <Header styled />
      <div className={styles.container}>
        <CoursesHeader
          title="совет"
          isTablet={isTablet}
          isMobile={isMobile}
          cb={navigateBack}
        />
        <div className={styles.wrapper}>
          <CategoryAdviceVideo
            link={content?.video}
            id={content?.vimeo?.id}
            linkImage={content?.thumb}
          />
          <div className={styles.infoContainer}>
            <div>
              <div className={styles.headerWrapper}>
                <div className={styles.titleContainer}>
                  <h1 className={styles.title}>{content?.title}</h1>
                </div>
              </div>
              <ClockContainer
                duration={getStringDuration(content?.approximateTime)}
                size={size}
              />
              <FavoriteContainer item={content} />
            </div>
            {experts &&
              experts.length > 0 &&
              !!experts &&
              experts?.map((expert) => <ExpertInfo item={expert} />)}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CategoryAdvice;
