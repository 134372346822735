import { separator, tb7c3_1, tb7c3_2, tb7c3_3 } from 'assets/images';

export const b7_c23_text = {
  b7_c3_title: `Курс «Психические расстройства во время беременности и после родов» `,
  b7_c3_description: `Спикер: Олег Айзберг, врач психиатр-нарколог, врач-психотерапевт, доцент кафедры психиатрии и наркологии Белорусской медицинской академии последипломного образования`,
  b7_c23_text: `
    ${tb7c3_1}
    
    Урок 1. Психические расстройства во время планирования беременности, беременности и после родов

Пациенток, который обращаются за помощью, можно условно разделить на две группы:

Первая группа – это те женщины, которые ранее страдали психическими расстройствами разной степени тяжести. И они обращаются часто еще до того, как возникла беременность, чтобы получить консультацию о возможных рисках в процессе беременности, после родов, возможных рисках передачи психического расстройства по наследству.
Вторая группа – это те женщины, которые никогда не страдали психическими расстройствами, и они возникли у них впервые либо во время беременности (что бывает достаточно редко), либо в послеродовом периоде.
Поговорим о женщинах, которые уже страдали психическими расстройствами и уже лечились у психотерапевта / психиатра и планируют беременность. В консультации у таких пациенток можно выделить несколько важных моментов.  Первый – это страхи, которые касаются передачи психических расстройств потомству.  Они, к слову, не очень большие: варьируются в пределах 10-13% при более тяжелых психических расстройствах (таких, как шизофрения и биполярное расстройство). И более высокая распространенность легких психических расстройствах (таких, как депрессии и тревожные расстройства).  

Раньше ошибочно считалось, что женщины с серьезными психическими заболеваниями не должны иметь детей, и врачи давали им в директивной форме совет о том, что им не стоит беременеть и заводить потомство. Сейчас в медицине мы перешли на другой стиль отношений врача и пациента.

Сейчас мы перешли к модели совместного принятия решений, при котором роль врача состоит в том, что он информирует женщину о тех рисках, которые связаны с беременностью, родами, послеродовым периодом. И женщина сама решает, планировать ли ей беременность. Это ее решение, которое она принимает, посоветовавшись со своими родственниками (с мужем).

Беременность и прием антидепрессантов

Многие женщины считают, что прием психофармакологических средств, антидепрессантов и нейролептиков категорически противопоказан при беременности.  На самом деле, это не так.  Если вы принимаете какие-то препараты (антидепрессанты, нейролептики), необходимо обратиться к врачу-психиатру, который проконсультирует вас. Есть препараты, которые практически не повышают риск каких-то осложнений. Прием большинства антидепрессантов не сопряжен с существенными рисками для ребенка. Но в любом случае это должен решать врач-психиатр. 

Нередко мы, врачи, сталкиваемся с ситуацией, что женщина принимает препараты до беременности, но, забеременев, под влиянием родных / средств массовой информации / стереотипов в страхе решает отменить препараты. И это нередко сопровождается обострением психического заболевания. В данном случае я хочу рекомендовать обратиться к врачу-психиатру, который проведет квалифицированную консультацию, объяснит, какие препараты можно принимать, а какие не рекомендуется. И, конечно, лучше всего, чтобы перед планированием беременности женщина с психическим нарушениям заранее обращалась к врачу-психиатру, чтобы все вопросы не решались уже во время наступившей беременности.

Среди людей бытует мнение, что беременность и рождение ребенка решает какие-то психологические или психиатрические проблемы. Это, к сожалению, не так. Все проблемы, которые были, будут обостряться в послеродовом периоде. 

Во время беременности распространенность психических расстройств такая же, как и у женщин в другие периоды жизни. То есть беременность сама по себе не провоцирует психические нарушения, но и сама по себе не является защитным фактором от них. А вот в послеродовом периоде частота психических нарушений у женщин значительно выше, чем в другие периоды жизни.

Если говорить о частоте психических нарушений во время беременности, то распространенность депрессии у женщин:

В первом триместре составляет в среднем около 7,5%.  Что несколько выше, чем обычные цифры популяционные. Обычная популяционная  распространенность депрессии – это 5%. 
Во втором триместре то это 12,8%, что уже явно выше, чем в популяции. Примерно такая же распространенность в третьем триместре. 
Распространенность послеродовой депрессии составляет в среднем около 15%. Такое расстройство, как послеродовой блюз, встречается примерно у половины женщин. Это кратковременное снижение настроения, которое не является болезнью и не требует какого-то лечения, длится от нескольких дней до 2 недель.

Такое расстройство, как послеродовой психоз (т.е. тяжелые нарушения психической деятельности, сопровождающиеся бредом, галлюцинациями, неадекватным поведением), составляет 0,1%. То есть на 1000 родов примерно приходится 1 случай послеродового психоза (это заболевание редкое). И, наконец, тревожные расстройства встречаются с частотой от 8 до 18% (это самые частые психические нарушения в послеродовом периоде). 

Психические расстройства у мужчин

Раньше мужчина был не очень сильно вовлечен в процесс помощи женщине при беременности, уходом за родившимися детьми. Это было связано с советской патриархальной традицией, что это женское дело и мужчина к этому не должен иметь какого-то серьезного отношения. Этим занималась, как правило, мама ребенка, его бабушка. Папы и дедушки редко имели какое-то отношение к детям.

Сейчас подход сильно поменялся – и мужчины стали гораздо больше вовлекаться в процесс ухода за родившимися детьми. Даже есть папы, которые берут декретный отпуск. И в связи с этим специалисты начали больше внимания обращать на психические расстройства, которые возникают у мужчин в послеродовом периоде. 

Исследования показывают, что распространенность депрессии у мужчин, когда у них рождается ребенок, выше, чем обычная популяционная. В частности, по данным многих исследований, частота перинатальной  депрессии у мужчин средняя составляет 8,3%.  

Чем опасна послеродовая депрессия у мужчины? 

В первую очередь так называемым негативным родительским поведением. Когда мужчина не обращает внимания на ребенка, не уделяет ему внимания, не разговаривает с ним. 
И, к сожалению, депрессия  мужчин сопровождается высоким риском и злоупотребления алкоголя, и физического насилия. Депрессивные мужчины часто проявляют агрессию, и это может быть очень рискованным и для женщины, и для ребенка.  
Хочу призвать всех обращать внимание на свое психическое состояние, а также на психическое состояние своих близких. И во время беременности, и в послеродовом периоде. И если у вас возникают какие-то сомнения в том, что психическое состояние поменялось как-то не в лучшую сторону, обращаться за помощью к специалистам (психологам, психиатрам и психотерапевтам).

${separator}

Урок 2. Послеродовой блюз. Послеродовая депрессия 

Послеродовой блюз

Большинство женщин (от 50% до 80%) переживают после родов такое расстройство, как послеродовой блюз. Это: 

Снижение настроения, которое возникает у женщин через несколько дней после родов. 
Оно продолжается от нескольких дней до нескольких недель.
Сопровождается плаксивостью, колебаниями настроения от плохого до обычного. 
Это является вариантом нормы, не является болезнью и никакого лечения не требует. Связан он с тем, что у женщины после родов очень сильно падает концентрация женских половых гормонов, и это падение концентрации гормонов сопровождается колебаниями настроения. Во всех случаях послеродовой блюз проходит в течение 2 недель. Если это снижение настроения не проходит, то, скорее всего, речь уже может идти о послеродовой депрессии.

Послеродовая депрессия

Послеродовая депрессия возникает примерно у 10-15% женщин в первый год после родов, и она сопровождается теми же симптомами, которые есть при любой другой депрессии. Но есть и признаки, специфичные именно для послеродовой депрессии.

Есть 3 явных признака, которые есть при любой депрессии. 

Патологически сниженное настроение.
Ангедония – отсутствие получения удовольствия, когда женщине не приносят удовольствия те вещи, которые обычно раньше ей приносили удовольствие.
Снижение активности обычной для этого человека в здоровом состоянии. 
Также для депрессии характерны: 

ощущение безнадежности (т.е. такие мысли, что в будущем все будет плохо, это никогда не закончится)
усталость, которая не проходит после отдыха
повышенная плаксивость
трудности в принятии решений
сниженная концентрация внимания
боли в различных частях тела (в голове, в конечностях, в спине)
нарушение сна (обычно это бессонница) или, наоборот, резкая повышенная сонливость 
снижение аппетита
ощущение снижения памяти
В целом это состояние можно характеризовать такой триадой: 

женщина считает, что она сама не справляется со своими обязанностями
что вокруг ее окружают какие-то люди с негативными чертами
и в будущем все будет плохо
И есть признаки, которые являются характерными именно для послеродовой депрессии. В первую очередь это убежденность женщины, что она является плохой матерью, недостаточно хорошей для ребенка. И, в принципе, эта убежденность человека в том, что он не имеет какой-то ценности, что он недостаточно хорош, она характерна для любой депрессии.

На чем базируется такая убежденность? Она базируется на депрессивном эмоциональном фоне, идеализированных представлениях, какая должна быть хорошая мать. И у женщин с послеродовой депрессией возникают эмоциональные проблемы, они не испытывают, как правило, каких-то теплых чувств к ребенку. Это не связано с тем, что они в действительности не любят этого ребенка, не хотели его. А  связанно это с тем, что при депрессии блокируются эмоции, возникает состояние такого эмоционального безразличия. Есть такое красивое словосочетание «скорбное бесчувствие» – когда у человека нет эмоций, и он мучительно переживает их отсутствие. И вот это отсутствие материнских чувств к ребенку, любви, ощущения радости при общении с ребенком, часто сопровождает послеродовую депрессию и усиливает чувство вины у женщины и ее представление о том, что она плохая  мама.

Дальше у женщины возникают проблемы с тем, что она не может правильно интерпретировать поведение ребенка. Ей кажется, что ребенок ее отвергает, что он на нее злой, что она может не распознавать, когда он хочет есть или спать. И эта неправильная интерпретация поведения ребенка может повлечь за собой нарушение связи матери и ребенка.

Традиционно считалось, что послеродовая депрессия – это заболевание биологически обусловленное, связанное с гормонами, с тем, что падает уровень гормонов.

В действительности все оказалось сложнее. Оказалось, что в развитии послеродовой депрессии играют роль не только гормональные факторы, но и различные социальные и психологические факторы. Например:

Перфекционистские черты личности. Перфекционизм – это стремление доводить все до совершенства. И перфекционисты считают, что они должны быть идеальными во всем. Это стремление быть идеальной матерью может провоцировать депрессию, потому что то, что в реальности происходит после рождения ребенка, и ожидания женщины могут очень сильно не совпадать.
Домашнее насилие. Это относится и к любым формам депрессии.
Низкий уровень доходов (т.е. низкий финансовый статус).
Если беременность наступает в очень молодом возрасте или зрелом возрасте. 
Если беременность не планировалась или она является нежеланной, женщина не хотела иметь ребенка.
Если женщина не может принять свое изменившееся тело во  время беременности, после родов.
Очень часто и сама женщина, и общество имеют какие-то ложные стереотипы о том, как протекает родительство. Часто это навязано глянцевыми журналами, представлениями из художественных фильмов, рекламой. И когда это не совпадает с реальностью, и у многих женщин возникают проблемы, связанные с принятием роли матери и интегрированием этой роли с другими ролями. 

Это связано с теми социальными изменениями, которые возникли сейчас в обществе. Если раньше считалось в традиционном  патриархальном обществе, что роль матери для женщины является главной, основной, а все остальные роли считаются второстепенными (т.е. хобби, какие-то увлечения, профессия), то в современном обществе так мало кто считает. 

Считается, что женщина может реализовываться не только в роли матери, но и в профессиональной сфере, реализовывать свои хобби. А когда рождается ребенок, естественно, что это находится в конфликте – потому что невозможно одинаково хорошо быть хорошей матерью, домохозяйкой, при этом прекрасно выглядеть и работать на трех работах успешно. Это – миф.

Женщины, которые успешны в профессии, спустя короткое время после рождения  ребенка, как правило, доверяют воспитание и уход за малышом няне или родным. И в силу повышенных требований к себе у женщины может возникнуть внутренний конфликт, что она не может профессионально реализоваться, если много внимания уделяет ребенку. Или, наоборот, чувство вины, что она реализуется в профессии и при этом недостаточно много внимания уделяет ребенку.

И самым важным фактором в развитии послеродовой депрессии  являются сложности в отношении с близкими (прежде всего, с партнером), конфликты с собственными родителями.

Конечно же, послеродовой депрессии подвержены женщины, у которых раньше были какие-то психические нарушения (например, тревожные расстройства) либо которые уже перенесли депрессию ранее.

Послеродовая депрессия является тем заболеванием, которое  хорошо лечится. Если вы обнаружили у себя какие-то признаки послеродовой депрессии – обратитесь к специалистам.

${tb7c3_2}

Урок 3. Методы самопомощи при послеродовой депрессии

Диагноз «послеродовая депрессия» ставится специалистом. Это может быть врач-психиатр или психотерапевт. Помощь может оказываться совместно психиатром, психотерапевтом и психологом.

Прежде всего отмечу, что очень важно преодолеть в себе барьер. Многие люди считают, что обращаться за помощью – это стыдно, что они должны со всеми проблемами справляться сами. Это распространенная точка зрения и, к сожалению, неверная. Если у вас есть проблемы с психическим состоянием, нужно обращаться к специалистам. Не нужно этого стесняться.

Поговорим о том, каким образом можно самим улучшить свое состояние при послеродовой депрессии. 

Совет 1.  Больше общаться

При депрессии человек начинает мало общаться, у него снижается энергия, иногда он стесняется своего состояния. Важно общаться с семьей, друзьями. Они являются большим источником ресурсов для человека. И они могут помочь женщине в уходе за ребенком, а также поговорить, разгрузить ее.

Совет 2. Сократить количество забот

Постарайтесь преодолеть в себе перфекционизм. Ничего страшного не случится, если уборка в квартире будет проводиться реже / какое-то время вообще не будет проводиться / будет проводиться другими людьми, а приготовление пищи на себя возьмет кто-то другой. 

Совет 3. Больше отдыхать, особенно важен ночной сон. 

Поговорим о тех предрассудках, которые нередко есть у людей, почему они боятся обращаться за помощью. 

Почему женщина может бояться обратиться к специалисту?

Первый предрассудок – это то, что обращение за помощью – это признак собственной слабости и несостоятельности. Но ведь если мы не справляемся с какой-либо проблемой – мы спокойно пользуемся помощью других людей (принимаем лекарства, пользуемся техникой). Ничего страшного ни в обращении к специалистам, ни в приеме лекарств нет.

Многие люди боятся обращаться за помощью, потому что это может быть расценено родственниками и знакомыми как какой-то негативный фактор. Среди людей распространены негативные предубеждения против психотерапии, против приема лекарств. Люди боятся негативной оценки, что если они кому-то расскажут, что обращались к психологу, психотерапевту, психиатру либо принимали антидепрессанты. Я всегда в таких случаях пациентам говорю, что абсолютно не обязательно афишировать факт приема антидепрессантов либо факт обращения к специалистам.

И еще одна проблема – это проблема с расстановкой приоритетов. В нашей культуре есть идея самопожертвования. Что женщины готовы отдать все ради других людей (детей, мужа) – и это приводит к тому, что они не заботятся о своем психическом состоянии. Помните, что для ребенка залогом его успешного развития психического и физического является успешное, хорошее состояние его мамы. И поэтому очень важно заботиться о своем психическом состоянии.

Еще одной проблемой является то, что многие люди (это касается и самих женщин, которые страдают послеродовой депрессией, и их родственников) начинают отрицать эти проблемы. Например, родственники считают, что это просто усталость, нужно просто выкинуть эти мысли из головы. Даже даются советы, что надо выпить спиртного, и все пройдет, прогуляться – и все будет отлично. 

Нередко еще одна проблема состоит в том, что нередко депрессия проявляется телесными симптомами, болями в различных частях тела, какими-то жжениями, неприятными ощущениями, сердцебиением. И вместо того, чтобы обратиться к специалисту по психическому здоровью, женщина начинает искать у себя несуществующие болезни сердца, сосудов головного мозга, позвоночника и обращаться к непрофильным специалистам, отрицать проблему с психикой. Очень часто также действуют ее родственники.

Многие не обращаются к специалисту, потому что у них уже был единичный негативный опыт взаимодействия с психологом / психиатром либо приема лекарственных препаратов. Важно понимать, что единичный негативный опыт не нужно распространять на всех специалистов, всю специальность. Вы всегда можете выбрать специалиста, с которым у вас будут доверительные отношения. Это не повод для того, чтобы полностью отказываться от помощи.

Еще одной проблемой является то, что многие родственники, к сожалению, напрямую отговаривают женщину от обращения к специалистам (к психологам, психиатрам). Считают, что никакой депрессии не существует, что прием антидепрессантов вреден, а психотерапия – это просто пустая трата времени. В этом случае помогает разъяснение ситуации. Либо нужно просто сохранить личные границы женщине и сказать, что мое здоровье – это мое личное дело, я буду обращаться к тем специалистам, к которым считаю нужным обращаться. 

Сейчас отношения врача и пациента значительно изменились по сравнению с тем, как это было раньше. Если раньше была распространена патерналистская модель, при которой врач считался главным, то сейчас принята модель совместного принятия решений. Врач информирует пациентку о заболевании, о тех рисках, которые связаны с лечением, преимуществах лечения. И женщина сама принимает решение о том, каким образом ей лечиться и где ей лечиться.

Необходимо обсуждать свое лечение со специалистом. Если непонятно что-то, задавать вопросы о том, как будет проходить психотерапия, о действии лекарств, какие есть преимущества, какие недостатки. 

Также рекомендуется при послеродовой депрессии составить список активностей, которые доставляют удовольствие, радость, и практиковать побольше этих активностей.

${separator}

Урок 4. Лечение послеродовой депрессии

Лечение послеродовой депрессии может проводиться двумя основными способами:

Психотерапия
Прием лекарственных препаратов (антидепрессанты)
Антидепрессанты

Они довольно эффективны при послеродовой депрессии (как и при любой другой депрессии). Эффективность первого назначенного антидепрессанта проявляется у примерно 70% женщин. Эффект антидепрессантов наступает в среднем через 3-4 недели лечения, и он постепенный.

Есть 2 основных ложных страха, связанных с приемом антидепрессантов.

Страх 1. Антидепрессанты вызывают зависимость. Это не так, антидепрессанты никакой зависимости не вызывают. 

Страх 2. Антидепрессанты не совместимы с грудным вскармливанием. В настоящее время большинство современных антидепрессантов вполне совместимы с грудным вскармливанием. И врач почти всегда может подобрать препарат, который не требует какой-то отмены грудного вскармливания.

Конечно, нужно ориентироваться на мнение женщины. Если вы очень боитесь продолжать естественное вскармливание – тогда мы рекомендуем прекратить вскармливание, тормозить лактацию, дальше вскармливать ребенка искусственно. На мой взгляд, в современном обществе, в современной культуре сложилась некая фетишизация грудного вскармливания, придание ему какого-то особого значения. Оно, безусловно, важно для ребенка и для женщины. Но если по каким-то причинам грудное вскармливание приносит женщине серьезные неудобства, оно очень болезненное, либо она боится, что препараты попадут в грудное молоко – то стоит задуматься. Если грудное вскармливание связано с каким-то существенным дискомфортом для женщины – она может его прекратить, потому что психическое состояние женщины, безусловно, является приоритетом. И продолжение грудного вскармливания в ущерб психического состояния женщины – это не очень рациональное решение.

Страх 3. Антидепрессанты меняют личность. Многие говорят: «Я не хочу принимать таблетки, потому что я стану другим человеком, другой личностью». Это не так. Наоборот, происходит возврат в доболезненное состояние, человеку возвращаются те самые черты, которые ему обычно присущи.

Действительно, могут быть побочные эффекты, но они при лечении современными антидепрессантами, как правило, не опасны. Если побочные эффекты трудно переносятся, не происходит адаптация к ним – тогда мы рекомендуем просто поменять препарат. Это достаточно легко решается.

Если через 4 недели лечения антидепрессантами нет эффекта, тогда антидепрессант заменяется на другой антидепрессант, который, как правило, дает эффект.

Психотерапевтические методы лечения

Основные методы лечения, эффективность которых доказана:

когнитивно-поведенческая терапия 
краткосрочная психодинамическая терапия
семейная терапия
Все эти методы оказывают положительный эффект при лечении депрессии. Наиболее выраженный эффект наблюдается при сочетании психотерапевтических методов и медикаментозных.

Большинство пациенток с послеродовой депрессией могут лечиться амбулаторно либо в дневном стационаре. Госпитализация нужна только в отдельных случаях, когда депрессия имеет тяжелую выраженность. Это один вариант. Либо же в течение нескольких месяцев не достигнут результат амбулаторного лечения, либо бывают ситуации такие социальные, когда женщине просто дома тяжело, никто ей не может помочь, и в этой ситуации лучше тогда госпитализироваться, если нет какой-то дома поддержки.

${tb7c3_3}

Урок 5. Тревожные расстройства во время беременности и в послеродовом периоде

Тревожные расстройства – это самые распространенные психические нарушения. В послеродовом периоде они возникают чаще, чем в других периодах жизни женщины. 

Все тревожные расстройства, которые встречаются в послеродовом периоде чаще всего, можно разделить на несколько категорий. 

Паническое расстройство
Генерализованное тревожное расстройство
Обсессивно-компульсивное расстройство
Паническое расстройство 

Его в старой литературе называли вегето-сосудистой дистонией, или нейроциркуляторной дистонией. Это психическое расстройство,  которое проявляется следующим образом: 

очень сильные приступы тревоги
сердцебиение, чувство жара в лице
жжение в груди, в животе
предобморочное состояние
онемение в конечностях
сильное головокружение 
Эти приступы могут возникать с различной частотой – от нескольких раз в день до одного раза в 2-3 недели. 

В чем состоит проблема у многих пациенток при возникновении таких приступов? Они начинают считать, что они больны каким-то телесным соматическим заболеванием (чаще всего заболеванием сердца), боятся умереть от него, и у них возникает очень сильная тревога о здоровье. И когда эти приступы повторяются, у пациентки возникают убеждения, что она больна каким-то серьезным заболеванием. Такие пациентки начинают повторно обследоваться у кардиологов, эндокринологов, неврологов, искать какие-то болезни. 

Нередко у женщин в послеродовом периоде эта тревога о здоровье переносится на ребенка. То есть если в обычной жизни эти женщины считают, что у них может быть какое-то заболевание, то в послеродовом периоде они могут считать, что ребенок болен каким-то серьезным заболеванием. И постоянно требовать его обследований,  проведений повторных консультации педиатра, проведений УЗИ,  требовать от врачей стопроцентной гарантии, что ребенок ничем не болен. И при выраженных формах тревожного расстройства эти разубеждения врача, убеждения в том, что ребенок здоров, действуют только на короткое время. Спустя несколько дней мать у матери опять возникает страх, что у ребенка появилось какое-то заболевание, они требуют повторных обследований. 

Обсессивно-компульсивное расстройство

По старой терминологии оно называется невроз навязчивых состояний. Это заболевание, при котором у человека возникают навязчивые мысли, сопровождающиеся сильным страхом. У молодых матерей, как правило, это 2 вида навязчивых мыслей.

Мысль о том, что они могут причинить вред своему ребенку,  сделать с ним что-то плохое (утопить, например, или выбросить из окна). 
Эти мысли очень сильно пугают молодую маму, и у них возникает страх, что они могут это сделать и в тяжелых случаях. Тогда они вообще могут не подходить к ребенку (требуют, чтобы за ним ухаживали другие люди) либо не оставаться наедине с ребенком. И надо понимать, что такие женщины (с такой формой тревожного расстройства) никогда этого не сделают, это ложный страх, но он может быть очень сильным.  

Страх, что  ребенок может чем-то заразиться или они могут его чем-то заразить
И тогда такие пациентки могут предпринимать повышенные гигиенические меры – с излишней частотой купать ребенка, обрабатывать дезинфекторами квартиру. Этот страх может усиливаться прочтением очень популярных всяких рассказов о том, что ребенок может заразиться какими-то инфекциями. Обсессивно-компульсивное расстройство нередко обостряется именно в послеродовом периоде. 

Генерализованное тревожное расстройство

У женщины возникает много несфокусированных диффузных страхов на разные темы – что что-то плохое может случиться с ребенком, что-то может случиться с мужем, с отношениями в семье. Этот страх, как правило, направлен в будущее. Страх, что женщина не справится с воспитанием ребенка, что будут какие-то материальные трудности. И возникает очень много мыслей различных  тревожных,  которые поселяются в голове и от которых трудно избавиться. Возникает такое явление, как «умственная жвачка», когда по кругу женщина думает о чем-то ее тревожащем. 

Также для этого генерализованного тревожного расстройства характерны:

напряжение мышц
неприятные ощущения в разных частях тела
могут быть боли, потому что мышцы сильно напряжены
И нередко такие пациентки тоже из-за вот этих мышечных болей напряжения идут обследоваться к различным специалистам (неврологам, эндокринологам, кардиологам).

Лечение тревожных расстройств 

Лечение осуществляется 2 способами:

Психотерапия
Прием средств (антидепрессантов)
Психотерапия может быть когнитивно-поведенческая, психодинамическая либо семейная и также лекарственными  препаратами.  В процессе проведения когнитивно-поведенческой терапии анализируются мысли и эмоции человека, предлагается ему вести дневник с анализом своих переживаний и используются различные способы коррекции негативных мыслей, отрицательных эмоций. 

В процессе психодинамической терапии, как правило, исследуются глубинные убеждения человека, анализируются его межличностные отношения,  внутренние конфликты, конфликты с окружающими людьми. А семейная терапия направлена на анализы, на действия в семье с мужем, со своими собственными родителями. 

Если речь идет о фармакологическом лечении, то используются антидепрессанты. Эффективность лечения антидепрессантами и эффективность лечения психотерапией примерно одинаковы. Но есть определенная разница – антидепрессанты действуют несколько быстрее, чем психотерапия, но зато психотерапия дает более стойкие результаты лечения. То есть если мы посмотрим на результаты лечения через несколько лет, то процент женщин, которые живут без тревожного расстройства после проведения психотерапии, выше, чем после лечения антидепрессантами.

Полезные книги

Посоветую несколько книг, в которых описано в доступной форме, как справляться с депрессивными и тревожными расстройствами во время беременности и в послеродовом периоде.  

Ксении Красильниковой «Не просто устала». 
Автор книги сама перенесла послеродовую депрессию и подробно рассказывает об этом своей книге. Также она описывает свой опыт взаимодействия с психотерапевтами, психиатрами. В эту книгу включены рассказы других женщин, перенесших послеродовую депрессию, и дается масса ценных советов женщинам, которые оказались вот в такой ситуации.

Людмила Петрановская «Мама на нуле»
Это книга, где описываются проблемы, возникающие при взаимодействии с маленькими детьми.

А также книги, где подробно описаны формы самопомощи при депрессии и тревоге. 

Дэвид Бернс «Новая терапия  настроений» (самопомощь при депрессии)
Кристин Падески «Разум рулит настроением»
Роберт Лихи «Свобода от тревоги» (тревожные расстройства)
Роберт Лихи «Лекарство от нервов» (генерализованная  тревога)
Хочу пожелать всем счастья, радости и обращать внимание не только на свое физическое состояние, но  и на свое психическое здоровье. И если у вас возникают сомнения в своем психическом состоянии, обращаться к специалистам.


    `,
};
