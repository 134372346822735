import { analytics } from 'api/constants';
import { logEvent } from 'firebase/analytics';
import { UntypedObject } from 'types/baseTypes';

export const analyticsLogEvent = (
  eventName: string,
  eventValues: UntypedObject = {}
): void => {
  try {
    logEvent(analytics, eventName, eventValues);
  } catch (error) {
    // TODO handle error
    console.error(error);
  }
};
