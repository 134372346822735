import { useTranslate } from 'hooks/useTranslate';
import styles from './EmptyCourses.module.css';
import { EmptyStudy, WinkSmile } from 'assets/icons';
import NextButton from 'features/Auth/Onboarding/components/NextButton/NextButton';
import { IconLabel } from 'components/IconLabel/IconLabel';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'navigation/constants';

export function EmptyCourses() {
  const t = useTranslate();
  const navigate = useNavigate();
  const handleButton = () => {
    navigate(AppRoutes.STUDY_SCREEN);
  };
  return (
    <div className={styles.emptyWrapper}>
      <div className={styles.container}>
        <div className={styles.icon}>
          <WinkSmile />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.infoContainer}>
            <p className={styles.title}>{t('empty_title')}</p>
            <p className={styles.subtitle}>{t('empty_subtitle')}</p>

            <IconLabel icon={EmptyStudy} text={'обучение'} />
            <p className={styles.extraInfo}>{t('empty_extra_info')}</p>
          </div>

          <NextButton title={t('empty_go_to_section')} onClick={handleButton} />
        </div>
      </div>
    </div>
  );
}
