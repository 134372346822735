export enum Provider {
  GOOGLE = 'Google',
  APPLE = 'Apple',
  EMAIL_PASSWORD = 'password',
}
export enum ProviderRemoval {
  GOOGLE = 'google.com',
  APPLE = 'apple.com',
  EMAIL_PASSWORD = 'password',
}
export type AuthErrorCode =
  | 'auth/email-already-in-use'
  | 'auth/invalid-email'
  | 'auth/weak'
  | 'auth/operation-not-allowed'
  | 'auth/user-not-found '
  | 'auth/wrong-password'
  | 'auth/invalid-email ';
