import { ArrRight } from 'assets/icons';
import { Link } from 'react-router-dom';
import { RecommendationType } from 'types';
import styles from './Meditation.module.css';

interface MeditationBoxProps {
  icon?: any;
  title?: string;
  type?: RecommendationType;
  style?: any;
  isTablet?: boolean;
}

const Meditation: React.FC<MeditationBoxProps> = ({
  icon: Icon,
  title,
  type,
  style,
  isTablet,
}) => {
  return (
    <Link className={styles.link} to={`/main/${type}`} style={style}>
      <div className={styles.container}>
        <div className={styles.iconContainer}>
          {Icon && (
            <Icon width={isTablet ? 60 : 45} height={isTablet ? 60 : 45} />
          )}
          <p className={styles.title}>{title}</p>
        </div>

        <ArrRight />
      </div>
    </Link>
  );
};

export default Meditation;
