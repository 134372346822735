import React, { useState, FC } from 'react';
import Sheet from 'react-modal-sheet';
import styles from './SwipeableModal.module.css';
import { AppIcon } from 'assets/icons';
import { useTranslate } from 'hooks/useTranslate';
import TextButton from 'components/Buttons/TextButton/TextButton';
import PrimaryButton from 'components/Buttons/PrimaryButton/PrimaryButton';

const SwipeableModal: FC = () => {
  const [isOpen, setOpen] = useState(true);

  const t = useTranslate();

  const handleOpenAppClick = () => {
    window.open('https://mama-pro.onelink.me/ccnQ/app', '_blank');
  };

  const handleStayOnWebsiteClick = () => {
    setOpen(false);
  };

  return (
    <>
      <Sheet
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        detent="content-height"
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div className={styles.container}>
              <div className={styles.wrapper}>
                <div className={styles.imageWrapper}>
                  <img
                    src={AppIcon}
                    className={styles.imageContainer}
                    alt="course"
                  />
                </div>
                <div className={styles.primaryButton}>
                  <PrimaryButton
                    title={t('open-in-app')}
                    onClick={handleOpenAppClick}
                  />
                </div>
              </div>
              <div className={styles.primaryButton}>
                <TextButton
                  title={t('stay-on-website')}
                  onClick={handleStayOnWebsiteClick}
                />
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
};

export default SwipeableModal;
