import { FC, useEffect, useRef } from 'react';
import styles from './WorkWithUsSection.module.css';
import { Title } from 'components/Text/Title/Title';
import { useTranslate } from 'hooks/useTranslate';
import {
  MTS,
  MTS_320,
  MTS_768,
  TZMO,
  TZMO_320,
  TZMO_768,
  UNFPA,
  UNFPA_320,
  UNFPA_768,
  W,
  W_320,
  W_768,
  bayer,
  bayer_320,
  bayer_768,
  bella,
  bella_320,
  bella_768,
  danone,
  danone_320,
  danone_768,
  happy,
  happy_320,
  happy_768,
  huggies,
  huggies_320,
  huggies_768,
  ku,
  ku_320,
  ku_768,
  pampers,
  pampers_320,
  pampers_768,
} from 'assets/work';
import { useSmoothScrollToHash } from 'hooks/useSmoothScrollToHash';

const logos_1600 = [
  { logo: bayer },
  { logo: UNFPA },
  { logo: W },
  { logo: huggies },
  { logo: danone },
  { logo: ku },
  { logo: TZMO },
  { logo: MTS },
  { logo: bella },
  { logo: pampers },
  { logo: happy },
];

const logos_768 = [
  { logo: bayer_768 },
  { logo: UNFPA_768 },
  { logo: W_768 },
  { logo: huggies_768 },
  { logo: danone_768 },
  { logo: ku_768 },
  { logo: TZMO_768 },
  { logo: MTS_768 },
  { logo: bella_768 },
  { logo: pampers_768 },
  { logo: happy_768 },
];

const logos_320 = [
  { logo: bayer_320 },
  { logo: UNFPA_320 },
  { logo: W_320 },
  { logo: huggies_320 },
  { logo: danone_320 },
  { logo: ku_320 },
  { logo: TZMO_320 },
  { logo: MTS_320 },
  { logo: bella_320 },
  { logo: pampers_320 },
  { logo: happy_320 },
];

export const WorkWithUsSection: FC<{
  partnersRef: any;
}> = ({ partnersRef }) => {
  const t = useTranslate();

  return (
    <section className={styles.section}>
      <div
        ref={partnersRef}
        id="partners"
        className={styles.anchor}
        style={{ scrollBehavior: 'smooth' }}></div>
      <div className={styles.container}>
        <Title text={t('С нами работают')} highlight={t('работают')} center />
        <div className={styles.wrapper}>
          <div className={styles.container_1600}>
            {logos_1600.map((item, i) => (
              <img
                src={item.logo}
                className={styles.icon}
                alt="logo icon"
                key={i}
              />
            ))}
          </div>
          <div className={styles.container_768}>
            {logos_768.map((item, i) => (
              <img
                src={item.logo}
                className={styles.icon}
                alt="logo icon"
                key={i}
              />
            ))}
          </div>
          <div className={styles.container_320}>
            {logos_320.map((item, i) => (
              <img
                src={item.logo}
                className={styles.icon}
                alt="logo icon"
                key={i}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
