import { useAppWindowSize } from 'hooks/useAppWindowSize';
import styles from './StatusContainer.module.css';

interface StatusContainerProps {
  icon?: any;
  title?: string;
}

const StatusContainer: React.FC<StatusContainerProps> = ({
  icon: Icon,
  title,
}) => {
  const { isTablet, isMobile } = useAppWindowSize();

  return (
    <>
      <div className={styles.icon}>
        {Icon && (
          <Icon
            width={isTablet ? 60 : isMobile ? 45 : 45}
            height={isTablet ? 60 : isMobile ? 45 : 45}
          />
        )}
      </div>
      <p className={styles.title}>{title}</p>
    </>
  );
};

export default StatusContainer;
