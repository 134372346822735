import { FC } from 'react';
import styles from './LinkStatusPopup.module.css';
import { linkStatus } from './LinkStatus.utils';

interface LinkStatusPopupProps {
  isCopied?: boolean;
  error?: boolean;
  isLoading?: boolean;
}

const LinkStatusPopup: FC<LinkStatusPopupProps> = ({
  isCopied,
  isLoading,
}: LinkStatusPopupProps) => {
  const status = isCopied ? 'copied' : isLoading ? 'loading' : 'error';
  const { icon, text } = linkStatus[status];

  return (
    <div className={styles.container}>
      <div
        className={
          isCopied ? styles.copied : isLoading ? styles.loading : styles.error
        }
      >
        {icon}
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  );
};

export default LinkStatusPopup;
