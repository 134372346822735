import { studyContentConfig } from 'pages/StudyPage/StudyScreen.utils';

import { CourseData, RecommendationType } from 'types';

import { Expert } from 'types/items';

export const pages = {
  Главная: 'https://mamapro.health/',
  Обучение: 'https://mamapro.health/edu',
  ['Регистрация в программе подготовки к родам «Мы Ждём Ребёнка»']:
    'https://mamapro.health/waiting-page',
  Эксперты: 'https://mamapro.health/experts',
};

export const studyContent = studyContentConfig.map(({ id, title }) => ({
  url: `https://mamapro.health/edu/${id}`,
  id,
  title,
}));

export const getExperts = (experts: Expert[]): Record<string, string> =>
  experts.reduce((acc, { firstName, lastName, id }) => {
    //@ts-ignore
    acc[`${firstName}  ${lastName}`] = `https://mamapro.health/experts/${id}`;
    return acc;
  }, {});

export const contentConfig = [
  { type: RecommendationType.ADVICE, name: 'Советы' },
  { type: RecommendationType.POST, name: 'Посты' },
  { type: RecommendationType.COURSE, name: 'Курсы' },
];

export const getCoursesByCategoryIdAndType = (
  allCourses: CourseData[],
  categoryId: string,
  type: RecommendationType
): CourseData[] =>
  allCourses.filter(
    (course) =>
      Object.keys(course.category || {}).includes(categoryId) &&
      course.type === type
  );
