import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type AuthState = {
  // NOTE isLoggedIn NULL until authentification in processing
  isLoggedIn: boolean | null;
  firstName: string | null;
  lastName: string | null;
  uid: string | null;
  isShowSuccessLabel: boolean;
};

const initialState: AuthState = {
  isLoggedIn: null,
  firstName: null,
  lastName: null,
  uid: null,
  isShowSuccessLabel: false,
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    updateAuthFirstName: (state, action: PayloadAction<string | null>) => {
      state.firstName = action.payload;
    },
    updateAuthLastName: (state, action: PayloadAction<string | null>) => {
      state.lastName = action.payload;
    },
    updateUid: (state, action: PayloadAction<string | null>) => {
      state.uid = action.payload;
    },
    updateiIsShowSuccessLabel: (state, action: PayloadAction<boolean>) => {
      state.isShowSuccessLabel = action.payload;
    },
  },
});

const { reducer, actions } = AuthSlice;

export const {
  updateAuthFirstName,
  updateAuthLastName,
  updateIsLoggedIn,
  updateUid,
  updateiIsShowSuccessLabel,
} = actions;

export default reducer;
