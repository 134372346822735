import { useTranslate } from 'hooks/useTranslate';
import { noop } from 'lodash';
import { FC } from 'react';
import styles from './ButtonsContainer.module.css';

import { ModalButton } from '../ModalButton/ModalButton';

export const ButtonsContainer: FC<{
  hideModal: () => void;
  onPress: () => void;
}> = ({ onPress = noop, hideModal }) => {
  const t = useTranslate();

  return (
    <div className={styles.buttonsContainer}>
      <ModalButton title={t('common-cancel')} onPress={hideModal} />
      <ModalButton
        title={t('settings_delete_account_module_confirm_action')}
        flat
        onPress={onPress}
      />
    </div>
  );
};
