import { FC } from "react";
import styles from './MZHRSection.module.css';
import { Title } from "components/Text/Title/Title";
import { useTranslate } from "hooks/useTranslate";
import { ArrowIcon, CertificateIcon, MZHRImage } from "assets/icons";
import { LogoList } from "./components/LogoList/LogoList";
import { CardList } from "./components/CardList/CardList";
import { DetailsButton } from "./components/DetailsButton/DetailsButton";


export const MZHRSection: FC = () => {
  const t = useTranslate();

  return (
    <section className={ styles.section }>
      <div className={ styles.container }>
        <div className={ styles.header }>
          <img src={ MZHRImage } alt={ 'MZHR logo' } className={ styles.icon } />
          <div className={ styles.titleContainer }>
            <Title 
              text={ t('home_mzhr_title') }
              highlight={ t('home_mzhr_title_highlight') }
            />
            <LogoList />
          </div>
        </div>

        <CardList />

        <div className={ styles.certificate }>
          <img 
            src={ CertificateIcon } 
            className={ styles.certificateIcon } 
            alt={ 'certificate icon' }  
          />
          <div className={ styles.certificateDescription}>
            <img src={ ArrowIcon } className={ styles.arrow } alt="arrow"/>
            {
              t('home_mzhr_certificate_text')
            }
          </div>
        </div>
        <div className={ styles.footer}>
          <DetailsButton />
        </div>
      </div>
    </section>
  )
};
