import { useCoursesApi } from './useCoursesApi';
import { RecommendationType } from 'types';
import { optionalArray } from 'utils/other';

export const useAllCourses = () => {
  const { courses: tips, loading: tipsLoading } = useCoursesApi(
    RecommendationType.ADVICE
  );
  const { courses, loading: coursesLoading } = useCoursesApi(
    RecommendationType.COURSE
  );
  const { courses: posts, loading: postsLoading } = useCoursesApi(
    RecommendationType.POST
  );

  const { courses: meditation, loading: meditationLoading } = useCoursesApi(
    RecommendationType.MEDITATION
  );

  const { courses: lullabies, loading: lullabiesLoading } = useCoursesApi(
    RecommendationType.LULLABIES
  );

  //TODO app filter for user visibility
  return {
    allCourses: [
      ...optionalArray(courses),
      ...optionalArray(tips),
      ...optionalArray(posts),
      ...optionalArray(meditation),
      ...optionalArray(lullabies),
    ],
    loading:
      tipsLoading ||
      coursesLoading ||
      postsLoading ||
      meditationLoading ||
      lullabiesLoading,
  };
};
