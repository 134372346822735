import { useState, useEffect } from 'react';
import { CourseData, RecommendationType } from 'types';
import { useAppSelector } from '../store/store';

import { useDispatch } from 'react-redux';
import { adaptCourses, coursesConfig } from 'utils/courses';
import { useTranslate } from 'hooks/useTranslate';
import { noop } from 'lodash';
import { selectAllCourses } from 'pages/CoursesCategory/store/selectors';

export const useCoursesApi = (
  type: RecommendationType
): { courses: CourseData[]; loading: boolean } => {
  //TODO app filter for user visibility
  const t = useTranslate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const courses = useAppSelector(selectAllCourses);
  const coursesByType = courses[coursesConfig[type].key];
  useEffect(() => {
    if (!coursesByType?.length) {
      setLoading(true);
      coursesConfig[type]
        .api?.()
        .then((result) => {
          dispatch(coursesConfig[type].action(adaptCourses(type, result, t)));
        })
        .catch(noop)
        .finally(() => setLoading(false));
    }
  }, [type]);

  return {
    courses: coursesByType,
    loading,
  };
};
