import PrimaryButton from 'components/Buttons/PrimaryButton/PrimaryButton';
import RichTextDescription from 'components/RichTextDescription';
import { useTranslate } from 'hooks/useTranslate';
import ReactMarkdown from 'react-markdown';
import { UntypedObject } from 'types/baseTypes';
import styles from './DescriptionContainer.module.css';
import NextButton from 'features/Auth/Onboarding/components/NextButton/NextButton';

interface DescriptionContainerProps {
  userId: string | null | undefined;
  description: UntypedObject | undefined;
  active: boolean;
  handleReadMoreClick: () => void;
  content?: any;
}

function DescriptionContainer({
  userId,
  description,
  active,
  content,
  handleReadMoreClick,
}: DescriptionContainerProps) {
  const t = useTranslate();

  const handleButtonPress = (action: string | URL | undefined) => {
    window.open(action, '_blank');
  };
  const renderContent = () => {
    const orderedKeys = Object.keys(content)
      .filter((key) => key.startsWith('n'))
      .sort();

    return orderedKeys.map((key) => {
      const item = content[key];
      if (!item) return null;

      if (item?.type === 'text') {
        const parseToMarkDown = (): any => {
          return item.value.replace(/\\n/g, '\n');
        };
        return (
          <ReactMarkdown skipHtml={false} className={styles.lineBreak}>
            {parseToMarkDown()}
          </ReactMarkdown>
        );
      } else if (item?.type === 'image') {
        return (
          <img
            key={key}
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignSelf: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 20,
              marginBottom: 20,
              borderRadius: 10,
            }}
            src={item?.value?.link}
            alt="Image"
          />
        );
      } else if (item?.type === 'button') {
        return (
          <div className={styles.nextButton}>
            <NextButton
              title={item?.text}
              onClick={() => handleButtonPress(item?.action)}
            />
          </div>
        );
      }

      return null;
    });
  };

  if (userId && !content) {
    return (
      <div className={styles.descriptionContainer}>
        <div className={styles.description}>
          <RichTextDescription description={description} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.descriptionContainer}>
      <div className={styles.description}>
        {!content && (
          <>
            <div className={userId ? styles.none : styles.partOfTextContainer}>
              <RichTextDescription description={description} />
            </div>
            <div className={!userId ? styles.blurText : ''}>
              <RichTextDescription description={description} />
            </div>
          </>
        )}
        {content && (
          <>
            <div className={userId ? styles.none : styles.partOfTextContainer}>
              <div>{renderContent()}</div>
            </div>
            <div className={!userId ? styles.blurText : ''}>
              <div>{renderContent()}</div>
            </div>
          </>
        )}
        <div className={styles.descriptionButton}>
          <div className={styles.buttonContainer}>
            {!userId && (
              <PrimaryButton
                title={t('read_more')}
                onClick={handleReadMoreClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DescriptionContainer;
