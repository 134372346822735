import { CoursesHeader } from 'components/CoursesHeader/CoursesHeader';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import { useTranslate } from 'hooks/useTranslate';
import { AppRoutes } from 'navigation/constants';
import { FC } from 'react';
import styles from './ExpertsPage.module.css';
import { useExpertsApi } from 'hooks/useExpertsApi';
import ExpertInfo from 'components/ExpertInfo/ExpertInfo';
import CourseEmptyWrapper from 'components/CommonCoursesCategory/components/CourseEmpty/CourseEmptyWrapper';
import { useAppWindowSize } from 'hooks/useAppWindowSize';

export const ExpertsPage: FC = () => {
  const t = useTranslate();
  const { isMobile, isTablet } = useAppWindowSize();

  const { experts } = useExpertsApi();
  if (!experts.length) {
    return (
      <CourseEmptyWrapper
        isTablet={isTablet}
        isMobile={isMobile}
        title={t('experts')}
      />
    );
  }

  return (
    <div className={styles.background}>
      <Header styled />
      <div className={styles.container}>
        <CoursesHeader title={'эксперты'} to={AppRoutes.STUDY_SCREEN} />
        {experts.map((expert) => (
          <ExpertInfo item={expert} key={expert.id} />
        ))}
      </div>
      <Footer />
    </div>
  );
};
