import { dbRealtime } from './constants';
import { ref, onValue, update } from 'firebase/database';
export const LOCALE_ID = 'ru';

export const getCategories = () =>
  new Promise((resolve, reject) => {
    const refCategories = ref(dbRealtime, `/categories`);
    onValue(refCategories, (snapshot) => resolve(snapshot.val()), reject);
  });

export const getWaebBlocks = () => {
  const refCategories = ref(dbRealtime, 'waeb/blocks');
  onValue(refCategories, (snapshot) => {
    const data = snapshot.val();
  });
};
export const getCourses = () =>
  new Promise((resolve, reject) => {
    const refCategories = ref(dbRealtime, `/courses`);
    onValue(refCategories, (snapshot) => resolve(snapshot.val()), reject);
  });

export const getTips = () =>
  new Promise((resolve, reject) => {
    const refCategories = ref(dbRealtime, `/advices`);
    onValue(refCategories, (snapshot) => resolve(snapshot.val()), reject);
  });

export const getMeditation = () =>
  new Promise((resolve, reject) => {
    const refCategories = ref(dbRealtime, `/meditation`);
    onValue(refCategories, (snapshot) => resolve(snapshot.val()), reject);
  });

export const getLullabies = () =>
  new Promise((resolve, reject) => {
    const refCategories = ref(dbRealtime, `/lullabies`);
    onValue(refCategories, (snapshot) => resolve(snapshot.val()), reject);
  });

export const getPosts = () =>
  new Promise((resolve, reject) => {
    const refCategories = ref(dbRealtime, `/posts`);
    onValue(refCategories, (snapshot) => resolve(snapshot.val()), reject);
  });

export const getExperts = () =>
  new Promise((resolve, reject) => {
    const refCategories = ref(dbRealtime, `/experts`);
    onValue(refCategories, (snapshot) => resolve(snapshot.val()), reject);
  });

export const getShticks = () =>
  new Promise((resolve, reject) => {
    const refCategories = ref(dbRealtime, '/shtick');
    onValue(refCategories, (snapshot) => resolve(snapshot.val()), reject);
  });

export const updateCourseLikes = (
  uid: string,
  like: Record<string, boolean>
) => {
  const query = ref(dbRealtime, `/courses/${uid}/${LOCALE_ID}/analytics/likes`);
  return update(query, like);
};

export const updateAdviceLikes = (
  uid: string,
  like: Record<string, boolean>
) => {
  const query = ref(dbRealtime, `/advices/${uid}/${LOCALE_ID}/analytics/likes`);
  return update(query, like);
};

export const updateMeditationLikes = (
  uid: string,
  like: Record<string, boolean>
) => {
  const query = ref(
    dbRealtime,
    `/meditation/${uid}/${LOCALE_ID}/analytics/likes`
  );
  return update(query, like);
};

export const updateLullabiesBooked = async (
  uid: string,
  booked: Record<string, boolean>
) => {
  const query = ref(dbRealtime, `/lullabies/${uid}/${LOCALE_ID}/booked`);
  return update(query, booked);
};

export const updateLullabiesLikes = (
  uid: string,
  like: Record<string, boolean>
) => {
  const query = ref(
    dbRealtime,
    `/lullabies/${uid}/${LOCALE_ID}/analytics/likes`
  );
  return update(query, like);
};

export const updateMeditationBooked = async (
  uid: string,
  booked: Record<string, boolean>
) => {
  const query = ref(dbRealtime, `/meditation/${uid}/${LOCALE_ID}/booked`);
  return update(query, booked);
};
export const updatePostLikes = (uid: string, like: Record<string, boolean>) => {
  const query = ref(dbRealtime, `/posts/${uid}/${LOCALE_ID}/analytics/likes`);
  return update(query, like);
};

export const updatePostBooked = async (
  uid: string,
  booked: Record<string, boolean>
) => {
  const query = ref(dbRealtime, `/posts/${uid}/${LOCALE_ID}/booked`);
  return update(query, booked);
};

export const updateCourseBooked = async (
  uid: string,
  booked: Record<string, boolean>
) => {
  const query = ref(dbRealtime, `/courses/${uid}/${LOCALE_ID}/booked`);
  return update(query, booked);
};

export const updateAdviceBooked = async (
  uid: string,
  booked: Record<string, boolean>
) => {
  const query = ref(dbRealtime, `/advices/${uid}/${LOCALE_ID}/booked`);
  return update(query, booked);
};
