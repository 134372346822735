import { RecommendationType } from 'types';

export const courseTypeSelectorConfig = [
  { id: 0, title: 'Все материалы', type: null, filter: () => true },
  {
    id: 1,
    title: 'Курсы',
    type: RecommendationType.COURSE,
    filter: () => false,
  },
  {
    id: 2,
    title: 'Советы',
    type: RecommendationType.ADVICE,
    filter: () => false,
  },
  { id: 3, title: 'Посты', type: RecommendationType.POST, filter: () => false },
];
