import { getDaysCaption, getWeeksCaption } from 'utils/time';
import styles from './StatusManaging.module.css';

interface StatusBoxProps {
  pregnancyWeek: number | null;
  pregnancyDate: number | null;
  trimester: number | undefined;
  estimatedDateOfBirth: string | null;
}

const StatusManaging: React.FC<StatusBoxProps> = ({
  pregnancyWeek,
  pregnancyDate,
  trimester,
  estimatedDateOfBirth,
}) => {
  return (
    <>
      <div className={styles.pregnancyDate}>
        <p className={styles.pregnancyTitle}>
          {/* @ts-ignore */}
          {pregnancyWeek} {getWeeksCaption(pregnancyWeek)}
        </p>
        <p className={styles.pregnancySubtitle}>
          {/* @ts-ignore */}
          {pregnancyDate} {getDaysCaption(pregnancyDate)}
        </p>
      </div>
      <div className={styles.trimesterContainer}>
        <p className={styles.pregnancyTitle}>триместр {trimester}</p>
        <p className={styles.pregnancySubtitle}>ПДР - {estimatedDateOfBirth}</p>
      </div>
    </>
  );
};

export default StatusManaging;
