import { CommonCoursesCategory } from 'components/CommonCoursesCategory/CommonCoursesCategory';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import { useAllCourses } from 'hooks/useAllCourses';
import { useTranslate } from 'hooks/useTranslate';
import { studyContentConfig } from 'pages/StudyPage/StudyScreen.utils';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './CoursesCategory.module.css';
import { CoursesHeader } from 'components/CoursesHeader/CoursesHeader';
import { AppRoutes } from 'navigation/constants';
import CourseEmptyWrapper from 'components/CommonCoursesCategory/components/CourseEmpty/CourseEmptyWrapper';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { watchCategoryDetailsEvent } from 'analytic/trackEvents';
import { HelmetComponent } from 'components/HelmetComponent/HelmetComponent';
import { getHelmetTitle, getHelmetDescription } from './CoursesCategory.utils';

export const CoursesCategory: FC = () => {
  const t = useTranslate();
  const { categoryId } = useParams();

  useEffect(() => {
    if (!!categoryId) {
      watchCategoryDetailsEvent(categoryId);
    }
  }, [categoryId]);

  const { allCourses, loading } = useAllCourses();
  const courses = allCourses.filter((e) =>
    //@ts-ignore
    Object.keys(e.category || {}).includes(categoryId)
  );
  const [loadingNotStarted, setLoadingNotStarted] = useState(!courses?.length);
  const title = t(
    studyContentConfig.find((category) => category.id === categoryId)?.tKey ||
      ''
  );
  const { isTablet, isMobile } = useAppWindowSize();

  useEffect(() => {
    setLoadingNotStarted(false);
  }, [loading]);
  if (loading || loadingNotStarted) {
    return (
      <CourseEmptyWrapper
        isTablet={isTablet}
        isMobile={isMobile}
        title={title}
      />
    );
  }

  return (
    <div className={styles.background}>
      <HelmetComponent
        title={getHelmetTitle(categoryId)}
        description={getHelmetDescription(categoryId)}
      />
      <Header styled />
      <div className={styles.container}>
        <CoursesHeader title={title} to={AppRoutes.STUDY_SCREEN} />
        <CommonCoursesCategory content={courses} title={title} />
      </div>
      <Footer />
    </div>
  );
};
