import { FC } from "react";
import styles from './MenuButton.module.css';
import classNames from "classnames";
import { MenuIcon } from "assets/icons";

export const MenuButton: FC<{
  isScrolled: boolean;
  onClick: () => void;
}>= ({
  isScrolled,
  onClick,
}) => {

  return (
    <button 
      className={ classNames(
        styles.container,
        isScrolled && styles.scrolled
      ) }
      onClick={ onClick }
    >
      <MenuIcon className={ classNames(
        styles.icon,
        isScrolled && styles.scrolled
      ) } />
    </button>
  )
};
