import { FC, useCallback } from "react";
import styles from './DeleteButton.module.css';
import { isFunction, noop } from "lodash";
import classNames from "classnames";


export const DeleteButton: FC<{
  title: string;
  onPress?: () => void;
  big?: boolean;
}> = ({
  title,
  onPress = noop,
  big,
}) => {
  const handleClick = useCallback(
    () => {
      isFunction(onPress) && onPress();
    },
    [onPress]
  );

  return (
    <div className={ classNames(
      styles.container,
      big && styles.bigContainer,
    ) }
      onClick={ handleClick }
    >
      { title }
    </div>
  );
};
