import { FC } from "react";
import styles from './DownloadAppSection.module.css';
import { Title } from "components/Text/Title/Title";
import { useTranslate } from "hooks/useTranslate";
import { StoreLink } from "components/StoreLink/StoreLink";
import { Providers } from "constants/constants";
import { LogoLightPurpleIcon } from "assets/icons";
import { useWindowSize } from "hooks/useWindowSize";
import { TitleH1 } from "components/Text/TitleH1/TitleH1";


export const DownloadAppSection: FC = () => {
  const t = useTranslate();

  const { width } = useWindowSize();
  const isMobile = width <= 440;

  return (
    <section className={ styles.section }>
      <div className={ styles.container }>
        <TitleH1  
          text={ t('download_app_title') }
          highlight={ t('download_app_title_highlight') }
          light
          center={ isMobile }
        />
        <div className={ styles.wrapper }>
          <div className={ styles.linksContainer }>
            <StoreLink provider={ Providers.APPLE } />
            <StoreLink provider={ Providers.GOGGLE } />
          </div>
          <img 
            src={ LogoLightPurpleIcon }
            className={ styles.icon }
            alt="logo icon"
          />
        </div>
      </div>
    </section>
  );
};
