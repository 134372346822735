import { separator, tb3c4_1, tb3c4_2, tb3c4_3, tb3c4_4 } from 'assets/images';

export const b3_c11_text = {
  b3_c4_title: `Курс «Пребывание в роддоме после родов»`,
  b3_c4_description: `Спикер: Диана Мардас, врач акушер-гинеколог`,
  b3_c11_text: `
    ${tb3c4_1}

    Урок 1. Первые 2 часа после родов

Представьте, что родился малыш, родилась плацента, и сейчас начинается этап «после родов». Первые 2 часа вы все еще будете находиться в родовом отделении. 

После того, как родился малыш, родилась плацента, произошел осмотр родовых путей (это время вы находились на родовом кресле), и сейчас пришло время перейти на кушетку. Эта та самая кушетка, на которой вы пребывали в схватках. Важно помнить, что вам нельзя вставать. 5 часов после родов вам нужно находиться в горизонтальном положении (2 часа пребывания в родовом отделении + 3 часа в послеродовом отделении).

Почему это важно? После родов женскому организму важно возвращаться к исходному состоянию. И это возвращение, этот путь должен быть тоже максимально естественным и спокойным, поэтому никаких резких движений –- все максимально плавно. Дайте вашему организму восстановиться.

Состояние мамы

В это время у вас могут появиться такие симптомы:

вам холодно (мамы даже иногда говорят, что их как будто потрясывает). Мы называем их состояние послеродовой озноб. 
И это нормально – это перестройка гормональной, сердечно-сосудистой системы уже состоявшейся мамы.

Можно попросить у медперсонала дополнительные одеяла, чтобы укрыться. Будет неплохо, если у вас с собой в это время будет теплый чай (даже чай с сахаром), и вы можете сделать несколько глотков. 

Кстати, в родовом отделении температура очень высокая, порядка 26-27 °С, а иногда и выше градусов. Потому что родовое отделение максимально приближено к естественным условиям рождения ребенка. И если ребенок рождается в очень прохладных условиях, ему очень тяжело адаптироваться.

Коммуникация с медперсоналом

Каждые полчаса к вам будет подходить акушерка, задавать вам вопросы, на которые советуем абсолютно честно отвечать. Если у вас есть предобморочное состояние, хочется в туалет по-большому – сообщите об этом персоналу.

Также врачи будут контролировать ваше состояние. И самое главное, что беспокоит врачей – это матка, потому что она должна быстро сократиться.

Внутри матки есть место, которое мы называем «плацентарная площадка». Туда крепилась плацента до того, пока не родилась. Когда ребенок жил внутри, плацента крепилась к этому месту, за счет чего происходило питание ребенка. 

Очень важно, чтобы матка хорошо сократилась и чтобы плацентарная площадка хорошо сократилась, чтобы не было риска кровотечения. Именно поэтому, дорогие мамы, акушерка будет аккуратно как будто бы надавливать вам на животик. Проверять, где дно матки, сокращается ли матка, плотной ли она консистенции, все ли в порядке. И вам это, конечно, немного неприятно. Но помните, что это действительно нужно и важно.

Еда и питье

Если все хорошо и вы не попали в какую-либо группу риска по развитию осложнений (об этом вам сообщит врач), то пить и есть можно. Если это завтрак, обед либо ужин в родильном доме, то вам принесут покушать. Если на завтрак, обед и ужин в родильном доме вы не попали (например, вы родили рано утром), тогда вы покушаете то, что взяли с собой. 

Позаботьтесь о том, когда вы едете в родильный дом, чтобы у вас с собой был перекус – зефир, банан, яблоко. То же самое я рекомендую и с чаем. Теплый чай после родов – это очень хорошая история, он поможет и при послеродовом ознобе. 

Часто бывают ситуации, когда маме не хочется кушать после родов. И заставлять себя не надо, слушайте свой организм. Если вам хочется покушать – кушайте, не хочется покушать – не кушайте.

Коммуникация с родственниками

В течение 2 часов вы все еще находитесь вот в той же палате, в которой вы рожали. Вы можете находится также в предродовой палате (также в некоторых роддомах Республики Беларусь мама после родов может находиться в специальной палате либо в коридоре на кушетке). И вам выдадут телефон. Позаботьтесь о том, чтобы ваш телефон, когда вы едете в родильный дом, был заряжен, либо, чтобы у вас было с собой зарядное устройство. 

В этот момент обычно медперсонал покидает пределы той палаты, в которой вы находитесь, и вы можете абсолютно спокойно доверительно пообщаться с супругом, семьей, близкими. В это время вы уже будете знать рост, вес ребенка, вы будете знать, в какое время родился малыш, и сможете полноценно рассказать все своим близким и любимым людям.

Где находится малыш?

В это время, как правило, малыш находится с вами рядом. Но здесь может быть несколько вариантов. 

Если были какие-то вопросы к ребенку после родов, доктор-неонатолог может забрать его под дополнительное наблюдение. 
Если все в порядке, малыш будет находиться с вами в той же палате. Он он может греться на пеленальном столике, отдельно от вас, в том случае, когда вам хочется немножко поспать, прийти в чувства после родов. Или вы можете сказать медперсоналу, что хотите находиться с малышом рядом.
Распеленайте малыша, приложите его к груди, говорите ему приятные слова, как вы его любите, как вы рады, что малыш появился. Запоминайте, как он выглядит, контактируйте с ним, потому что, действительно, этот момент важен как для вас, дорогие мамы, так и для малыша. Это закладка той невидимой связи, которую вы пронесете через всю жизнь.

${separator}

Урок 2. Послеродовое отделение. Устройство послеродовой палаты

Помните, что 5 часов после родов вам нельзя вставать. Поэтому в послеродовое отделение вас отвезут на кушетке вместе с вашими вещами.

Это будет момент разлуки на некоторое время с малышом, потому что малыша доставляет педиатрический персонал отдельно от вас. 

Важно предупредить медперсонал о том, что вы бы хотели максимально быстро оказаться снова вместе с малышом в послеродовом отделении. Но если вы чувствуете, что хотите поспать и отдохнуть, то также скажите медперсоналу об этом. Помните: очень опасно эти несколько часов после родов находиться вместе с малышом в одной кровати, потому что можно случайно уснуть. Поэтому оцените, как вы себя чувствуете, и сообщите о своем состоянии медперсоналу. Вместе вы договоритесь, как будете поступать и как быстро окажетесь вместе с ребенком вновь.

Итак, вы в послеродовом отделении и 5 часов после родов прошли. Делюсь важными рекомендациями.

Главное – действовать поэтапно. Не стоит быстро подниматься, бежать в душ. Вставать нужно очень постепенно, сначала присаживаясь (если вам можно сидеть). Если вам нельзя сидеть (потому что был разрыв промежности либо эпизиотомия), тогда вы делаете полунаклон, будто присаживаясь на одно бедро. Дальше, когда вы поняли, что голова не кружится, вы аккуратно встаете на ноги, но важно держаться руками за какую-нибудь опору (спинка кровати, столик, тумбочка).

Далее плавными шагами вы можете идти в душ. Обязательно оцените свое самочувствие. Ведь существует опасность того, что вы придете в душ, включите горячую воду – и под воздействием горячей воды у вас может закружиться голова, вы можете удариться о плитку. Будьте бдительны и внимательны и на этом этапе.

После душа вы можете переодеться и осмотреться, что же находится вокруг вас. 

Палата в послеродовом отделении

В послеродовом отделении созданы все условия для совместного пребывания мамы и ребенка.  Что будет в послеродовом отделении в палате для мамы? 

Кровать-кушетка (обычно у нее регулируется спинка, она может быть на колесиках). Постельное белье, конечно же, тоже прилагается, которое если вдруг так случилось, что загрязнилось, вы, конечно же, можете сказать об этом медперсоналу.
Тумбочка, на которой вы будете хранить ваши вещи. Она всегда располагается близко к кровати, чтобы, когда вы кормите малыша грудью либо отдыхаете, вы могли легко достать все необходимое.
Шкаф, где вы можете хранить свои вещи. Ваши вещи должны храниться в шкафу. Не нужно хранить ничего на открытых поверхностях. Чем больше на открытых поверхностях находится вещей, тем сложнее медперсоналу проводить гигиену, уборку в вашей палате.
Что будет в палате для малыша? 

Люлька. Она на колесиках, поэтому вы можете перемещаться с ней как по палате, так и, например, на прививки, анализы. Также у нее прозрачные стенки. И когда мама отдыхает на кровати, она может спокойно наблюдать через такую прозрачную люльку за тем, как себя чувствует малыш. 
Пеленальный стол. Пеленальный стол в большинстве родильных домов подогреваемый. Когда вы меняете малышу подгузник, когда приходит врач-неонатолог на обходы, вы включаете специальный свет (будет идти подогрев как снизу, так и сверху). В первое время после родов малышу очень важно не терять тепло, потому что у него очень тонкая кожа и процессы терморегуляции неидеальные, поэтому это очень такой удобный гаджет для малыша.
Весы. В большинстве родильных домов они присутствуют прямо в палате. Для чего? Потому что каждый день, когда будет обход врача, он будет обращать внимание на вес ребенка. Ребенка будут каждый день взвешивать на одних и тех же весах и фиксировать вес, чтобы понимать, можно уже вас выписывать домой или у ребенка очень большая потеря массы тела и еще нужно вас понаблюдать.
Также в отделении, конечно, есть санузел. В Минске в большинстве палат санузел прилегает прямо к палате либо к блоку, то есть находится где-то рядом. В некоторых региональных родильных домах еще сохранен коридорный тип обустройства отделения послеродового, когда у вас несколько палат и один санузел в коридоре.

Гигиена на этапе после родов очень важна. Важные рекомендации:

не терпеть, когда вам хочется в туалет;
после каждого похода в туалет осуществлять гигиену, потому что она залог успешного восстановления после родов, успешного сокращения матки, и, если у вас есть швы, успешного заживления швов.
Также есть ночник. Ваш привычный режим после родов может немного нарушаться. И это нормально, не стоит этого пугаться. Малыш может чуть больше спать днем, чуть меньше – ночью. В палате, в которой находятся сразу несколько мам, очень удобно не включать весь свет в палате. И вам поможет ночник.

Гигиена малыша осуществляется прямо в палате. В палате у вас всегда присутствует отдельный умывальник. Помните о том, что в этом моменте важно чувствовать уверенность. Будет здорово, если вы накануне родов немножко потренируетесь на кукле. 

! Не нужно везти в роддом подушки, одеяла, пледы для малыша. Пледы, одеяла и все наполнение люльки вам выдает роддом. 

Советую взять с собой полотенце. Потому что это индивидуальное средство, которое вы везете с собой в родильный дом, равно как и средства гигиены, одежда.

Что находится в послеродовом отделении?

Ординаторские: отдельно для врачей-неонатологов, отдельно для врачей акушеров-гинекологов.
Смотровой кабинет, где проходят осмотры после родов.
Перевязочный кабинет на случай того, если у вас есть швы и они требуют обработки.
Молочная комната. Она есть, но использовать ее нужно только в самом крайнем случае. Помним о том, что грудное вскармливание – это залог здоровья вашего малыша.
Помните, что в роддоме всегда посоветоваться, проконсультироваться, попросить дополнительной помощи. Если вы чего-то не знаете, сомневаетесь, у вас возникли какие-то опасения – не держите в себе. Обращайтесь к врачам, и они на ваши вопросы с удовольствием ответят. Рекомендую вам записывать все вопросы, чтобы на обходах врачей вы четко по списку получили ответы и пошли спокойно заниматься уходовыми процедурами мамы и малыша.

${tb3c4_2}

Урок 3. Пребывание в роддоме в первые сутки

Как считаются первые сутки после родов? Если вы родили до 12 часов дня, то эти сутки считаются уже первыми. Если вы родили после 12 дня, эти сутки будут считаться нулевыми.

Ранняя выписка после родов осуществляется на третьи сутки, а после операции кесарева сечения – на пятые. То есть если все хорошо с мамой и с малышом, если совпали мнения акушера-гинеколога и неонатолога, вы пойдете домой уже на третьи сутки.

Первые сутки в послеродовом отделении

Еще 3 часа после перевода в послеродовое отделение вы находитесь в горизонтальном положении. Потом постепенно, согласно рекомендациям из предыдущих уроков, вы поднимаетесь и направляетесь в душ на гигиенические процедуры. 

Все это время вы находились в сорочке, в которой были в схватках и рожали. После душа вы можете переодеваться в одежду, которую вы в родильный дом привезли.

Обсудим душ и гигиенические процедуры. Сейчас у вас ответственность не только за свое состояние, но и за ребенка. Для малыша и становления ГВ ничего лучше, чем запах мамы, контакт с мамой, не существует. Поэтому старайтесь использовать средства для душа без сильных ароматизаторов, не использовать туалетную воду. Помним о том, что малышу очень знаком и очень важен ваш запах. 

Когда вы находитесь в состоянии беременности, ребеночек плавает в околоплодных водах, немножко их заглатывает, нюхает, как только у него сформированы обонятельные рецепторы. И этот запах похож на запах молозива и запах тела мамы. И поэтому если вы перебьете это какими-то другими сторонними ароматами, могут возникнуть нюансы с прикладыванием ребенка к груди.

В душе рекомендуем использовать средство для интимной гигиены (специализированное). Важно соблюдать уровень pH – желательно выбирать ниже 5-5,5. Это максимально комфортный уровень для восстановления нашего организма, потому что если pH будет высоким либо будет очень много ароматизаторов, будут создаваться условия, когда патогенные микроорганизмы (которых не должно быть в это время в родовых путях) могут тут появиться. Есть риск возникновения инфекционных процессов.

Если вы не купили специальное средство для гигиены после родов, можете использовать детское мыло. Уровень pH там обычно ниже чем 5,5. Тем самым, взяв с собой один флакон детского мыла с дозатором, вы закрываете сразу несколько вопросов: гигиена малыша, гигиена мамы и просто мыло, которым вы можете обрабатывать ручки.

Одежда для мамы

Это должна быть очень удобная одежда, которая не стесняет ваши движения и которая позволяет вам быстро и комфортно кормить малыша грудью (т.е есть должен быть быстрый доступ к груди). И это должна быть одежда, которая позволяет вам беспрепятственно после каждого похода в туалет совершать гигиенические процедуры (т.е. подмываться).

Самым идеальным вариантом одежды после родов будет: 

сорочка на бретельках с глубоким вырезом
халат на запах
Не рекомендуется брать спортивные брюки, костюмы. После родов у вас будет послеродовая прокладка (сразу подклад, потом прокладка). И в таком случае брюки будут не подходящим вариантом, к тому же может повышаться температура в районе половых органов. Из-за этого плохо курсируют потоки воздуха, не осуществляется фильтрация. А это любимая среда для микроорганизмов.

На 3 дня вы можете взять с собой 3 сорочки на каждый день. Должна ли она быть обязательно с откидывающимся верхом для грудного вскармливания специально? Нет. Это может быть сорочка просто с глубоким вырезом, в которой вам действительно комфортно после родов.

Еще один важный элемент – бюстгальтер. Это важно для восстановления после родов и налаживания грудного вскармливания. В бюстгальтере важно находиться сразу после родов. Для правильного нахождения протоков в физиологической форме, для того, чтобы не было растяжек под тяжестью наполненной груди, чтобы кожа не растягивалась, чтобы не было застоев –  нужно сразу использовать бюстгальтер, подобранный специально по размеру, который вы берете с собой сразу же в родильный дом.

Уход за малышом

Если малыш находится рядом с вами (т.е. вы не разделялись на это время), то вы можете приступать к переодеванию малыша. Все это время он находился еще в пеленках, в которых его запеленали в родовом отделении. 

Если малыш какое-то время находится под наблюдением врачей, тогда вы идете за малышом, забираете его и тоже переодеваете в одежду, которую вы с собой привезли. Меняете ему подгузник, знакомитесь еще раз, учитесь. 

Советую вам не спешить. После родов не старайтесь успеть все и сразу. Постарайтесь сконцентрироваться на вашем состоянии, на том, что вы стали мамой. Вот вы, вот малыш – между вами формируется связь. Старайтесь запоминать эти этапы.

После того как вы переодели малыша, вы можете разложить вещи, которые до того были у вас пакетах. Для этого есть прикроватная тумбочка, шкаф, холодильник. Если вдруг холодильника нет в самой палате, то он скорее всего находится в отделении.

График послеродового отделения 

В послеродовом отделении есть график питания – завтрак, обед, ужин, полдник. В каждом родильном доме в послеродовом отделении существует расписание, которое вы можете заранее узнать.

В родильных домах нашей страны у женщин так называемый режим «все включено». И даже какие-то продукты, которые раньше вы не очень любили, на этом этапе кажутся особенно вкусными! И поэтому даже если вы раньше не любили запеканку, запеканка в родильном доме или манная каша в родильном доме – это одна из самых вкусных каш в жизни женщины, потому что вы стали мамой!

Если вы хотите, чтобы вам родные передали какие-то продукты, в родильном доме существует график приема передач.

Врачебные осмотры

Они будут проходить регулярно, каждый день, включая субботу и воскресенье. К вам будут приходить:

врач-неонатолог
врач акушер-гинеколог
Врач акушер-гинеколог будет задавать вопросы о вашем состоянии, будет смотреть вашу грудь, смотреть матку, швы. Врач-неонатолог – это врач, который наблюдает малыша в первые 28 дней после родов. Это очень узкоспециализированные педиатры. Вы можете задать врач-неонатологу все вопросы о малыше.

В первые сутки очень важно погрузиться в состояние «мама-малыш», сформировать тот самый важный контакт.  Вы сейчас очень нужны малышу, у вас формируется привязанность. Вы только начинаете кормить малыша грудью – и этот процесс требует большого труда. И поверьте, наш малыш работает не меньше, чем мы. Это должна быть командная работа, дорогие мамы.

Что еще важно знать про первые сутки? У вас могут быть некоторые волнения по поводу вашей формы. После родов, в первые сутки размеры животика будут примерно такие, как будто у нас сейчас 27-28 недель беременности (у большинства мам). Вам нужно не волноваться, не переживать и знать, что все нормализуется, но позже. Не спешите, не старайтесь обогнать заранее предусмотренные организмом состояния.

Нужен ли бандаж после родов? Если собрать все мнения мировых экспертов воедино, если говорить о том, что нам рекомендует Всемирная организация здравоохранения, то чем естественнее у вас будет проходить период после родов (т.е. без каких-то вспомогательных элементов), тем лучше для женского организма. То есть вы можете не использовать бандаж.

Конечно, существуют особенные ситуации, когда он вам будет показан – об этом вам скажет врач. Большинству женщин он не нужен. Когда вы садитесь, встаете, когда вы ухаживаете за малышом, мышцы переднего брюшного пресса безостановочно работают – и происходит то самое естественное восстановление после родов.

${separator}

Урок 4. Вещи и аксессуары для послеродового отделения

В этом уроке разберем вещи и аксессуары, которые понадобятся в отделении после родов. 

Итак, вы сходили в душ, разложили вещи. Главное – запомнить, что и где у вас лежит. После родов вы можете наблюдать легкое ухудшение памяти, растерянность. Это нормально. После родов у вас формируется другая доминанта, доминанта на кормление малыша, доминанта на уход за малышом. Вы можете немного выпадать из привычного, обычного ритма жизни. Все вернется, не волнуйтесь!

Если роды прошли хорошо, если у вас случился хороший правильный контакт с малышом, малыш сразу взял правильно грудь – возможно, вам никакие аксессуары не будут нужны. Потому что маме для того, чтобы осуществлять кормление, уход за малышом, чтобы восстанавливаться после родов, никакие вспомогательные элементы не нужны. 

Но давайте поговорим о тех вещах, которые вам обязательно понадобятся для грудного вскармливания. 

Бюстгальтер для кормления
Даже если вам кажется, что с грудью ничего не изменилось, помните, что процессы лактации запускаются уже на последних сроках беременности и после родов усиливаются. Гормоны, которые отвечают за выработку молока, работают интенсивно. И важно, чтобы вы использовали правильный бюстгальтер сразу после родов.

Как только вы приняли душ и переоделись, сразу используйте бюстгальтер. Важные моменты по поводу бюстгальтера:

Достаточно широкие лямки. Ко 2-3-м суткам после родов, когда у вас прибудет молоко, грудь станет тяжелая. Если лямки тонкие или очень твердые, вам может быть неудобно.
Чашка на один размер больше. После родов грудь увеличится в чашке примерно на один размер. Если раньше у вас было B, сейчас будет С. Иногда грудь увеличивается и на 2, и на 3 размера. А вот охват (например, 85 либо 70) не увеличится после родов. У вас немного уйдут отеки, вы уже будете без животика – поэтому охват не изменится.
Отсутствие косточек. Нигде грудь не должна пережиматься. 
Выбирайте бюстгальтер мягкий, комфортный, подходящий по размеру, без никаких косточек.

Лактационные вкладыши 
Между кормлениями молозиво (а в будущем молоко) постоянно будет подтекать. Вам помогут лактационные вкладыши. После каждого кормления нужно уложить прокладочку между бюстгальтером и вашей грудью (клеящимся элементом к бюстгальтеру).

Таких лактационных вкладышей нужно взять на 3 дня примерно 20 штук. Если вдруг они закончатся, помните о том, что есть комната приема передач, и родственники могут вам передать определенные вещи, которые вам понадобятся. 

Помните: если у вас сложился хороший контакт с ребенком, у вас сложилось грудное вскармливание, то молокоотсосы, другие вспомогательные элементы, которые необходимы во время грудного вскармливания, вам будут не нужны. 

Компрессионный трикотаж
Если у вас есть вопросы с венами, если у вас есть хроническая венозная недостаточность, у вас во время беременности появились сосудистые звездочки – рекомендуется носить компрессионный трикотаж и после родов некоторое время. Если у женщины все в порядке с венами, то ношение трикотажа можно исключить.

Компрессионный трикотаж особенно важен в процессе родов. Он является профилактикой тромбообразования, профилактикой венозных осложнений.

Пару слов о бандаже. Животик после родов – это нормально, пусть он сокращается естественным способом. Если вам хочется при выписке сразить всей своей фигурой, вы можете использовать трусики с завышенной талией, которые имеют компрессионный эффект. Но в остальное время старайтесь убирать все поддерживающие элементы. Пусть мышцы восстанавливаются естественным способом.

Косметика после родов
После родов жизнь мамы не заканчивается – она переходит на новый этап. И если вы до родов использовали тоник, крем, к которому вы привыкли – то берите все средства с собой в родильный дом, продолжайте использовать. Можете перелить это в маленькие баночки и взять так называемый дорожный набор.

Не рекомендую использовать совершенно новые средства. После родов все немного меняется, у вас могут возникнуть аллергические реакции. Лучше использовать проверенные средства – те, что вы использовали уже до рождения малыша. Помните, что лучше использовать средства без сильных ароматизаторов.

Декоративная косметика не запрещена. Конечно, ее можно использовать при подготовке к выписке. Ее может привезти ваш партнер в тот день, когда вы выписываетесь домой. 

Что нужно для малыша? Самый минимальный набор: 

Подгузники
Одежда
Косметические средства в очень минимальном количестве (если у малыша нет никаких проблем с кожей, если вы за ним правильно ухаживаете, то детского мыла в большинстве случаев вполне будет достаточно) 
Дополнительные аксессуары (например пустышка, бутылочка и все остальное) не обязательны. Если с малышом все хорошо, если у вас налажен контакт, если у вас хорошо все с грудным вскармливанием, все эти аксессуары вам не нужны. Равно как и игрушки, погремушки.
Если вдруг понадобятся какие-то лекарственные средства, средства по уходу за кожей ребенка, средства по уходу за швами – помните, что вы находитесь в послеродовом отделении родильного дома. А значит вы всегда можете обратиться к врачам, врачи вам помогут и предоставят. Также вам все могут передать родные.

${tb3c4_3}

Урок 5. Анализы и обследования во время пребывания в роддоме

Врачебные обходы

Каждый день утром к вам будет приходить два врача (это раздельные обходы). Врач-неонатолог будет смотреть малыша и врач акушер-гинеколог будет смотреть вас.

На что обращает внимание врач акушер-гинеколог? Он приходит всегда с историей родов, для того чтобы посмотреть, как шли ваши роды, были ли разрывы родовых путей, эпизиотомия (тогда нужно дополнительно осмотреть шов). 

Врач смотрит, не бледная ли вы, как вы себя чувствуете, насколько вы вступаете в так называемый продуктивный контакт, есть ли какие-то особенности в вашем состоянии, поведении. И здесь важно, дорогие мамы, ничего от врачей не скрывать. Если у вас появились какие-то странные ощущения в теле, жалобы или вы чувствуете, что расстроены и не очень рады, что стали мамой – говорите врачам об этом. 

Что будет происходит на обходе?

Врач смотрит состояние груди 
Врач будет напоминать вам о бюстгальтере для кормления. Также он посмотрит, есть ли у вас молозиво. Начиная со 2-3-х суток – есть ли у вас молоко. Он будет просматривать саму грудь, нет ли там уплотнений, как себя чувствует сосок, нет ли травматизации соска. 

Если что-либо из этого присутствует (уплотнение, травматизация соска), обязательно акцентируйте внимание врача на этой проблеме. Врач назначит вам специальные дополнительные аксессуары для грудного вскармливания либо средства, которые ускоряют заживление. 

Врач проверяет высоту стояния дна матки
Аккуратно рукой он будет немного надавливать вам на животик. Это не всегда приятные ощущения, но помните, дорогие мамы, что это обязательный этап. Врач проверит, все ли хорошо с маткой.

Вы можете задать вопросы
Лучше заранее запишите их и по списку каждый день задавайте свои вопросы врачу. Что можно спрашивать у врача акушера-гинеколога? Все относительно того, как протекали ваши роды, а также рекомендации на будущее. Все вопросы о малыше адресуйте врачу-неонатологу.

Врач будет назначать вам анализы и обследования
Если ваши роды прошли хорошо, если вы чувствуете себя хорошо после родов, то вагинальные осмотры на кресле и УЗИ не являются обязательными для вас. Все это врач назначает выборочно. 

Например, на УЗИ врач смотрит содержимое полости матки, не скопились ли там сгустки крови, нет ли там никаких остатков плацентарной ткани. То место, где крепилась плацента, требует особого внимания.

Врачи могут назначить вам консультации узких специалистов 
Если врачи увидели, что у вас есть какие-то вопросы с венами на ногах – могут пригласить вам хирурга. Если у вас есть состояния тревожности, вы расстроены – мы можем пригласить к вам психолога. И не стесняйтесь попросить нас об этом, не скрывайте свое истинное состояние. Но обязательными все данные консультации не являются. При необходимости у вас могут брать дополнительные анализы. Если все хорошо, обычно мамы сдают только общий анализ крови, общий анализ мочи.

В общем анализе крови врачи обращают внимание на уровень гемоглобина, который нам показывает, есть ли у вас анемия, на общий уровень лейкоцитов, чтобы понимать, есть ли у вас риск инфекции.

Все в послеродовом отделении направлено на то, чтобы у вас получился максимальный контакт с малышом. И если все хорошо – вас максимально быстро отпустят домой.

${separator}

Урок 6. Типичные ошибки мам в первые дни после родов

Ошибка 1. Брать с собой в родильный дом множество журналов, книг

Когда вы направляетесь в больницу на стационарное лечение, вы действительно можете брать книги. Но в роддоме после родов, дорогие мамы, у вас будут совершенно другие заботы.

Постарайтесь, чтобы все силы были направлены на формирование контакта «мама-малыш». Первые дни вместе очень важны – они нужны для малыша, он должен понимать, что его безопасность ничем и никем не нарушена. Ему очень важно ощущать стабильность, что рядом постоянно один и тот же человек.

Ошибка 2. Желание все держать в голове

Блокнот, ручка либо записная книжка в телефоне – это всегда понадобится вам, чтобы записывать вопросы и важные моменты для вас. Например, это может быть список вещей для мужа, что привезти вам в родильный дом, или вопросы к врачам.

Ошибка 3. Брать много вещей на запас

Берите с собой тот минимум вещей, который вам понадобится в роддоме в случае, если все будет хорошо. Настраивайтесь на лучшее – на то, что вы будете в родильном доме пребывать 3 дня. А если вдруг что-то пойдет не так, вам всегда через комнату приема передач родные смогут передать эти вещи.

Ошибка 4. Использовать одноразовые пеленки для сна

Одноразовые пеленки нужны только тогда, когда вы осуществляете ежедневные уходовые процедуры за малышом. Вы кладете их на пеленальный столик и осуществляете уходовые процедуры. 

Не стоит брать пеленки и укладывать их на кровать, чтобы лечь спать. Помните: очень важно, чтобы была фильтрация, чтобы курсировали потоки воздуха в районе половых органов. Потому что когда что-то не проветривается и поднимается высокая температура – это любимая среда для патологических микроорганизмов. 

Ошибка 5. Не соблюдать правила интимной гигиены

Здесь поговорим еще раз о плацентарной площадке. Матка должна хорошо сократиться, чтобы оттуда не началось кровотечение. И никакая инфекция через шейку матки не должна проникнуть сюда, чтобы не развились послеродовые осложнения. После каждого похода в туалет нужно совершать две важные рекомендации: 

поменять послеродовую прокладку
подмыться
Так нужно делать каждый день, пока вы находитесь на уровне родильного дома. 

К третьим суткам, когда вас будут выписывать домой, выделений станет резко меньше – и это нормально. Тогда вы можете совершать гигиену через один поход в туалет. Как часто после родов нужно менять послеродовую прокладку? При каждом походе в туалет. 

Ошибка 6. Брать много аксессуаров для грудного вскармливания, косметики

Все мы уже знаем такие слова, как лактационные вкладыши, силиконовые накладки, молокоотсос, контейнеры для хранения грудного молока. Порой мамы берут это все и сразу. То же самое касается и косметики. Иногда мамы привозят с собой целые наборы: масло для кожи, крем под подгузник, крем от ветра и непогоды.

Помните: если все хорошо, если нормально происходит этап восстановления после родов, если у вас абсолютно спокойно проходит грудное вскармливание – вам эти вещи могут не понадобиться. 

Ошибка 7. Молчать о своих переживаниях

Зачастую еще до родов у женщин в голове есть какая-то картинка «я – мама после родов». Есть некое представление, будто женщина гуляет с коляской, у них с малышом идеально нормированный день, у них все прекрасно. Женщина виртуозно меняет подгузник и подмывает ребенка одной рукой.

Но материнство весьма непредсказуемо. Особенно в первые дни и недели с малышом. Конечно, будет здорово, если вы будете настраивать себя на лучшее. Знайте, что если будут какие-то отклонения от нормы, особенности – вы с ними справитесь. 

И если что-то пошло не так – не стоит впадать в тайное, скрыто текущее расстройство. Если у вас есть околодепрессивные состояния, расстройства – сообщите об этом врачам. Если вас ничего не радует, вам не хочется осуществлять уход за ребенком – рекомендую вам сказать об этом врачу акушеру-гинекологу. Можете попросить пригласить психолога.

Ошибка 8. Отказываться от помощи

Помните: с рождением ребенка вы не перестаете быть женщиной, личностью, у которой могут быть свои интересы, у которой есть тоже желание куда-то выйти, встретиться со знакомыми, походить по магазинам.

Если у вас есть возможность получения помощи (молодой папа, ваши родители, родители мужа, подруги) – не отказывайтесь от этой помощи. Комфорт мамы и комфорт малыша – очень сопряженные вещи, и одно без другого быть не может. Поэтому, дорогие мамы, всегда обращайте на это пристальное внимание!

${tb3c4_4}

Урок 7.  Состояние мамы после родов: норма и патология

Начнем с самого распространенного состояния, которое порой пугает молодых мам. Это ощущение, будто вы заболели, у вас простуда, поднялась температура. 

На третьи сутки после родов молозиво постепенно превращается (через стадию переходного молочка) в молоко. И у большинства мам (особенно первородящих) возникают такие симптомы, будто немножко познабливает или бросает то в жар, то в холод, повышается температура.

Иногда температура во время того, когда приходит молоко, может повышаться до 38-38,5°С – и это нормально. Конечно же, грудь увеличивается в размерах. Возникает ощущение, будто грудь стала тяжелая, как камень. Так бывает, особенно в 1-е сутки, когда прибывает молоко. 

Подытожим: у первородящих мам на 3-и или 4-е сутки может в норме подниматься температура, может быть небольшой озноб. На фоне стресса и переживаний у многих мам может быть даже ощущение заложенности носа. 

Конечно же, это состояние важно отличать от реальной простуды. В норме после кормления грудью, когда грудь опустошается, она не становится такой плотной, когда у вас наступают 4-е сутки после родов, когда уже покормили малыша поочередно правой и левой грудью – вам должно стать легче. И температура падает, и озноб уходит, а никакие дополнительные симптомы не появляются. 

Если же у вас температура не падает, появляется реальный насморк, появляются другие симптомы (например, кашель) – нужно обращаться к терапевту.

Следующее состояние, которое часто пугает мам – большое количество выделений. После родов в норме этих выделений действительно много. Каждый день вам нужно менять прокладки. Более того, когда вы прикладываете ребенка к груди, эти выделения усиливаются. И многие мамы даже говорят: «У меня открылось кровотечение, когда я начала кормить ребенка грудью!». На самом деле это нормально. Когда ребенок кушает и стимулирует сосок – вырабатывается гормон окситоцин, который помогает матке сокращаться. 

Эти выделения будут немного отличаться от так называемого кровотечения – выделения будут бордового цвета, и с каждым днем их будет становиться все меньше и меньше. Заживление плацентарной площадки происходит примерно полтора месяца после родов, примерно 45 дней. Соответственно, в течение этого времени из плацентарной площадки могут появляться кровянисто-сукровичные выделения.

Также бывает ситуация, когда примерно на 10-15-е сутки выделения пропадают. А потом на 18-21-е сутки появляются снова. Это тоже норма, дорогие мамы. Конечно же, они не должны быть алыми, яркими, у них не должно быть неприятного запаха, они должны с каждым днем становиться все меньше, меньше, меньше.

Также важно обращать внимание на свое психологическое состояние. Во время беременности, накануне родов у вас может быть легкая тревожность. И это тоже является нормой накануне родов. А вот после родов после выписки, примерно на 3-и сутки, вам может стать грустно, возникают вопросы и переживания, справитесь ли вы с ребенком.

Это происходит из-за дисбаланса гормонов. И во время такого спада-всплеска наш организм реагирует, всегда нарушается психологический комфорт и баланс.

И такой легкий послеродовой «блюз», когда вы немного грустите – это нормально. Но если вы понимаете, что у вас мысли бегают по голове, вы не можете сконцентрироваться, вы не можете успокоиться – очень рекомендую вам обратиться к специалисту. 

Также вы можете заметить, что у вас исчезли гроздевидные уплотнения в виде варикозно-расширенных вен в районе половых губ. Это также абсолютная норма. Действительно, после родов некоторые ситуации могут быть для вас новыми, а некоторые осложнения, которые возникали у вас во время беременности, могут исчезать. Это и является новым особенным периодом, который мы называем периодом после родов.

${separator}

Урок 8. Осложнения после родов

Осложнения, связанные с выделениями
Для начала обсудим, что является нормой.

Выделений должно становиться с каждым днем все меньше
Они не должны приобретать ярко алую окраску
Они должны оставаться бордовыми, бордово-сукровичными с небольшим количеством слизи, а потом исчезать совсем
Не должно быть никакого неприятного запаха
Если вдруг выделений стало резко больше, у них появился какой-то странный запах либо отличается цвет выделений – обратитесь к врачу.

Врач осмотрит вас на кресле, он оценит, хорошо ли сократилась матка, насколько она плотная, не размягчена ли она, насколько она соответствует периоду после родов по своим размерам. И, скорее всего, направит вас на УЗИ.

На УЗИ врач может увидеть, каких матка размеров, сравнить ее с нормой. Если матка, например, увеличена – то врач может осмотреть внимательно полость матки, нет ли внутри полости каких-то элементов (сгустков крови либо остатков плацентарной ткани, остатков оболочек). Потому что если так случилось, что все это есть – такое состояние требует безотлагательного вмешательства, назначения специального лечения и динамического наблюдения.

Именно поэтому УЗИ могут назначить еще в роддоме после родов. Врач на обходе будет измерять высоту стояния дна матки и надавливать вам на животик. И если врач видит, что матка великовата, то может направить вас на УЗИ. И на УЗИ врач может увидеть, что внутри остались какие-то элементы плацентарной ткани, оболочки. Мы такое состояние называем лохиометра.

Лохиометра – это ситуация, когда после родов в полости матки скопились элементы плацентарной ткани. И нам нужно быстро их удалить. И в таком случае мы приглашаем анестезиологов и делаем выскабливание полости матки. Специальным инструментом мы оголяем полость матки и извлекаем оттуда все содержимое, которое скопилось и которое там сейчас находиться не должно.

То же самое может происходить, если в полости матки есть сгустки крови. Такое состояние называется гематометра. В таком случае тоже показано выскабливание полости матки. К тому же врач назначает сокращающие матку препараты, иногда антибактериальное лечение, дополнительные анализы и потом контрольное УЗИ.

Есть женщины, предрасположенные к плохому сокращению матки после родов. Это женщины, у которых матка во время беременности была перерастянута. Например, у вас была двойня, либо у вас было многоводие, либо ваш малыш родился очень крупным. Чем больше матка увеличилась в размерах во время беременности, тем сложнее ей после родов сократиться. 

Что делать, чтобы эта ситуация не возникла? Если у вас нет противопоказаний, если врач вам не говорил о запрете на активность после родов, то вы можете, начиная со 2-3-х суток, делать упражнение «велосипед». 

Также отличная профилактика послеродовых осложнений – это грудное вскармливание. Когда вы кормите ребенка грудью, у вас вырабатывается гормон окситоцин, который сокращает матку. И чем больше и лучше вы кормите ребенка грудью, тем лучше сокращается наша матка.

Как мы говорили в прошлом уроке, после родов могут возникать ситуации, когда у вас есть легкий озноб, поднимается температура. Но если у вас возникли следующие симптомы:

вы нащупали у себя в груди какое-то уплотнение, которое не уходит после кормления
оно будто бы увеличивается в размерах
у вас начинает подниматься температура
кожа над этим местом может немного покраснеть
Будьте бдительны и осторожны, эта ситуация – прямой путь к возникновению мастита. Мы не должны допускать ситуаций, чтобы уплотненные участки груди находились долго переполненными.

Что это за уплотнение? Внутри молочной железы существует протоковая система, и внутри данного протока происходит скопление молока. И когда скопление молока под воздействием температуры (которая во время грудного вскармливания может быть и 37°С) находится долго, у нас запускаются естественные процессы, ведущие нас к образованию мастита. Поэтому ни в коем случае не нужно допускать, дорогие мамы, этих ситуаций. Если вы увидели, что есть уплотнение – старайтесь с ним справиться:

начните пальчиками продвигать это уплотнение как бы вперед, ближе к соску;
старайтесь прикладывать малыша подбородком к этому уплотнению.
Теперь обсудим геморрой после родов. Если во время беременности вы уже столкнулись с данной проблемой – поверьте, дорогие мамы, после родов ситуация не улучшится. Скорее всего, ситуация немножко усугубится, потому что были потуги, которые немного усугубят ситуацию с геморроем.

Накануне поездки в роддом вы проконсультируетесь с вашим врачом акушером-гинекологом и подберете препараты, которые возьмете с собой в родильный дом. Это могут быть свечи, препараты, которые вы используете наружно, это может быть комбинация средств, таблетированные препараты.

Если после родов прошло 2-3 недели, а ситуация с геморроем не улучшается – рекомендую вам отправиться на консультацию к узкому специалисту (проктологу). И уже вместе с ним вы сможете подобрать схему лечения, которая максимально быстро приведет вас к тому, что вы попрощаетесь с геморроем.

Теперь обсудим с вами вашу форму после родов. В погоне за идеальной картинкой и фигурой, многие женщины сразу после родов идут в тренажерный зал, делают сложные упражнения с отягощениями, много носят ребенка на руках. 

И что происходит с вами, дорогие мамы? Ваши мышцы во время беременности испытывали перегрузку. Потом дальше был процесс родов, потуги – мышцы еще больше испытывали перегрузку. Мышцы тазового дна во время беременности делают большую работу – и после родов надо дать им отдохнуть, восстановиться. Если вы не будете соблюдать восстановительный период и дадите нагрузку на мышцы тазового дна максимально рано – у вас могут возникнуть проблемы с недержанием мочи, с опущением женских половых органов.

Всему свое время – дорогие мамы, помните это. И этой важной мыслью я хочу завершить этот курс. Желаем вам легкого послеродового восстановления!

    `,
};
