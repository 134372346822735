import styles from './CourseInfoBlock.module.css';

export const CourseInfoBlockFiller = () => {
  return (
    <>
      <div
        className={styles.container}
        style={{
          visibility: 'hidden',
          height: 0,
          paddingTop: 0,
          paddingBottom: 0,
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        <div>
          <div className={styles.imageContainer} />
        </div>
        <div className={styles.description}>
          <div className={styles.labels}></div>
        </div>
      </div>
    </>
  );
};
