import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import noop from 'lodash/noop';

import { getShticks } from 'api/api';
import { getTimeAfter } from 'utils/time';

import { useAppSelector } from '../store/store';

import { useUser } from './useUser';
import { selectShtick, selectShticks } from 'pages/MainPage/store/selectors';
import {
  Shtick,
  ShtickState,
  updateShticks,
} from 'pages/MainPage/store/HomeSlice';

export const useShtickApi = (): {
  shtick: Shtick | undefined;
  shticks: ShtickState;
  loading: boolean;
} => {
  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const dispatch = useDispatch();
  const { daysAfter } = getTimeAfter(
    user.dateOfChildBirth || user.dateOfPregnancy
  );

  const shtick = useAppSelector(selectShtick(user.usageOption, daysAfter));
  const shticks = useAppSelector(selectShticks);

  useEffect(() => {
    if (
      !Object.keys(shticks.baby).length &&
      !Object.keys(shticks.pregnancy).length
    ) {
      setLoading(true);
      getShticks()
        .then((result) => {
          // @ts-ignore
          dispatch(updateShticks(result));
        })
        .catch(noop)
        .finally(() => setLoading(false));
    }
  }, []);

  return {
    shticks,
    shtick,
    loading,
  };
};
