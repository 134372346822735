import * as yup from 'yup';

import { FormFields } from './constants';


const { DAYS_1, DAYS_10, WEEKS_1, WEEKS_10, MONTH_1, MONTH_10, YEAR_1, YEAR_10, YEAR_100, YEAR_1000 } = FormFields;


export const getDateOfChildBirthValidationSchema = () => yup.object().shape({
  [DAYS_10]: yup.number().required('error_fill_fields'),
  [DAYS_1]: yup.number().required('error_fill_fields'),
  [MONTH_10]: yup.number().required('error_fill_fields'),
  [MONTH_1]: yup.number().required('error_fill_fields'),
  [YEAR_1000]: yup.number().required('error_fill_fields'),
  [YEAR_100]: yup.number().required('error_fill_fields'),
  [YEAR_10]: yup.number().required('error_fill_fields'),
  [YEAR_1]: yup.number().required('error_fill_fields'),
});


export const getDateOfPregnancyValidationSchema = () => yup.object().shape({
  [WEEKS_10]: yup.number().required('error_fill_fields'),
  [WEEKS_1]: yup.number().required('error_fill_fields'),
  // NOTE commented code below can use for validation quantity weeks
  // [WEEKS_1]: yup.number().required('error_fill_fields').when(WEEKS_10, ([WEEKS_10], schema) => {
  //   return WEEKS_10 > 3 ? schema.max(5) : schema;
  // }),
  [DAYS_10]: yup.number().required('error_fill_fields').max(0),
  [DAYS_1]: yup.number().required('error_fill_fields').max(6),
});
