import { AppState } from 'store/store';
import { UsageOptions } from 'types';
import { Shtick } from './HomeSlice';

const shtickConfig: Partial<Record<UsageOptions, string>> = {
  [UsageOptions.AFTER_CHILDBIRTH]: 'baby',
  [UsageOptions.MANAGING_PREGNANCY]: 'pregnancy',
};

export const selectShtick =
  (type: UsageOptions | null | undefined, day: number) =>
  (state: AppState): Shtick | undefined => {
    if (!type) return;
    const key = shtickConfig[type];
    if (!key) return;
    return state.shticks?.[key]?.[`n${day}`];
  };

export const selectShticks = (state: AppState) => state.shticks;
