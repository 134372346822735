import { UNFPALogoIcon } from 'assets/icons';
import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import styles from './StudyButton.module.css';

interface PrimaryButtonProps {
  title: string;
  to: string;
}

const StudyButton: FC<PrimaryButtonProps> = memo(({ title, to }) => {
  return (
    <div className={styles.studyContainer}>
      <Link className={styles.container} to={to}>
        <p className={styles.title}>{title}</p>
      </Link>
      <img src={UNFPALogoIcon} alt="UNFPA Logo" className={styles.logo} />
    </div>
  );
});

export default StudyButton;
