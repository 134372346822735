import en from './en.json';
import ruApp from './ru.json';
import ruLandingMzr from './ru-landing-mzr.json';
import blocks from './ru-blocks-mzr.json';
import block1_course1 from './ru-block1/ru-b1-course1-mzr.json';
import block1_course2 from './ru-block1/ru-b1-course2-mzr.json';
import block2_course1 from './ru-block2/ru-b2-course1-mzr.json';
import block2_course2 from './ru-block2/ru-b2-course2-mzr.json';
import block2_course3 from './ru-block2/ru-b2-course3-mzr.json';
import block2_course4 from './ru-block2/ru-b2-course4-mzr.json';
import block2_course5 from './ru-block2/ru-b2-course5-mzr.json';
import block3_course1 from './ru-block3/ru-b3-course1-mzr.json';
import block3_course2 from './ru-block3/ru-b3-course2-mzr.json';
import block3_course3 from './ru-block3/ru-b3-course3-mzr.json';
import block3_course4 from './ru-block3/ru-b3-course4-mzr.json';
import block3_course5 from './ru-block3/ru-b3-course5-mzr.json';
import block3_course6 from './ru-block3/ru-b3-course6-mzr.json';
import block4_course1 from './ru-block4/ru-b4-course1-mzr.json';
import block4_course2 from './ru-block4/ru-b4-course2-mzr.json';
import block5_course1 from './ru-block5/ru-b5-course1-mzr.json';
import block5_course2 from './ru-block5/ru-b5-course2-mzr.json';
import block5_course3 from './ru-block5/ru-b5-course3-mzr.json';
import block5_course4 from './ru-block5/ru-b5-course4-mzr.json';
import block6_course1 from './ru-block6/ru-b6-course1-mzr.json';
import block7_course1 from './ru-block7/ru-b7-course1-mzr.json';
import block7_course2 from './ru-block7/ru-b7-course2-mzr.json';
import block7_course3 from './ru-block7/ru-b7-course3-mzr.json';
import block7_course4 from './ru-block7/ru-b7-course4-mzr.json';
import block7_course5 from './ru-block7/ru-b7-course5-mzr.json';
import block8_course1 from './ru-block8/ru-b8-course1-mzr.json';

import { b1_c1_text } from './ru-block-text/b1/b1-c1-text';
import { b1_c2_text } from './ru-block-text/b1/b1-c2-text';
import { b2_c3_text } from './ru-block-text/b2/b2-c1-text';
import { b2_c4_text } from './ru-block-text/b2/b2-c2-text';
import { b2_c5_text } from './ru-block-text/b2/b2-c3-text';
import { b2_c6_text } from './ru-block-text/b2/b2-c4-text';
import { b2_c7_text } from './ru-block-text/b2/b2-c5-text';
import { b3_c8_text } from './ru-block-text/b3/b3-c1-text';
import { b3_c9_text } from './ru-block-text/b3/b3-c2-text';
import { b3_c10_text } from './ru-block-text/b3/b3-c3-text';
import { b3_c11_text } from './ru-block-text/b3/b3-c4-text';
import { b3_c12_text } from './ru-block-text/b3/b3-c5-text';
import { b3_c13_text } from './ru-block-text/b3/b3-c6-text';
import { b4_c14_text } from './ru-block-text/b4/b4-c1-text';
import { b4_c15_text } from './ru-block-text/b4/b4-c2-text';
import { b5_c16_text } from './ru-block-text/b5/b5-c1-text';
import { b5_c17_text } from './ru-block-text/b5/b5-c2-text';
import { b5_c18_text } from './ru-block-text/b5/b5-c3-text';
import { b5_c19_text } from './ru-block-text/b5/b5-c4-text';
import { b6_c20_text } from './ru-block-text/b6/b6-c1-text';
import { b7_c21_text } from './ru-block-text/b7/b7-c1-text';
import { b7_c22_text } from './ru-block-text/b7/b7-c2-text';
import { b7_c23_text } from './ru-block-text/b7/b7-c3-text';
import { b7_c24_text } from './ru-block-text/b7/b7-c4-text';
import { b7_c25_text } from './ru-block-text/b7/b7-c5-text';
import { concatJSON } from './utils';

const ru = concatJSON([
  ruApp,
  ruLandingMzr,
  b1_c1_text,
  b1_c2_text,
  b2_c3_text,
  b2_c4_text,
  b2_c5_text,
  b2_c6_text,
  b2_c7_text,
  b3_c8_text,
  b3_c9_text,
  b3_c10_text,
  b3_c11_text,
  b3_c12_text,
  b3_c13_text,
  b4_c14_text,
  b4_c15_text,
  b5_c16_text,
  b5_c17_text,
  b5_c18_text,
  b5_c19_text,
  b6_c20_text,
  b7_c21_text,
  b7_c22_text,
  b7_c23_text,
  b7_c24_text,
  b7_c25_text,
  blocks,
  block1_course1,
  block1_course2,
  block2_course1,
  block2_course2,
  block2_course3,
  block2_course4,
  block2_course5,
  block3_course1,
  block3_course2,
  block3_course3,
  block3_course4,
  block3_course5,
  block3_course6,
  block4_course1,
  block4_course2,
  block5_course1,
  block5_course2,
  block5_course3,
  block5_course4,
  block6_course1,
  block7_course1,
  block7_course2,
  block7_course3,
  block7_course4,
  block7_course5,
  block8_course1,
]);

export { en, ru };
