import { useState, useEffect } from 'react';
import { useAppSelector } from '../store/store';
import { useDispatch } from 'react-redux';
import { getExperts } from 'api/api';
import { Expert } from 'types/items';
import { adaptExperts } from 'utils/experts';
import { saveExperts } from 'pages/ExpertScreen/store/ExpertsSlice';
import { selectExperts } from 'pages/ExpertScreen/store/selectors';

export const useExpertsApi = (): { experts: Expert[]; loading: boolean } => {
  //TODO app filter for user visibility
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const experts = useAppSelector(selectExperts);
  useEffect(() => {
    if (!experts?.length) {
      setLoading(true);
      getExperts()
        .then((result) => {
          //@ts-ignore
          dispatch(saveExperts(adaptExperts(result)));
        })
        .finally(() => setLoading(false));
    }
  }, []);

  return {
    experts: experts || [],
    loading,
  };
};
