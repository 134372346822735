import {
  separator,
  tb3c3_1,
  tb3c3_2,
  tb3c3_3,
  tb3c3_4,
  tb3c3_5,
  tb3c3_6,
  tb3c3_7,
} from 'assets/images';

export const b3_c10_text = {
  b3_c3_title: `Курс «Кесарево сечение» `,
  b3_c3_description: `Спикеры: 

    Василий Шостак, врач акушер-гинеколог, кандидат медицинских наук
    
    Элеонора Федотова, врач акушер-гинеколог, заместитель главного врача по родовспоможению 1-ой городской клинической больницы г.Минска`,
  b3_c10_text: `
    ${tb3c3_1}
    
    Урок 1. Показания к операции кесарево сечение

Сегодня кесарево сечение является самой часто выполняемой операцией у человека. На 2-м месте идет удаление аппендицита. Около 28-30% всех беременностей заканчивается операцией кесарево сечение (ежегодно). 

Кесарево сечение является родовспомогательной операцией, когда невозможно рождение живого плода через естественные родовые пути.  Кесарево сечение придумано достаточно давно, и очень часто можно слышать о том, что женщина имеет желание родить именно кесаревым сечением, потому что так легче,  быстрее и проще.  Отметим, что кесарево сечение по желанию женщины в нашей стране не проводится. Кесарево сечение должно быть выполнено только тогда, когда для него есть показания.

Показания к операции кесарево сечение

Можно разделить их на 2 группы. 

Операции, выполняемые по абсолютным показаниям тогда, когда ситуация угрожает жизни женщины и ее ребенку или рождение плода через естественные родовые пути живым невозможно. 
Вторая группа показаний – это так называемые относительные показания, когда либо их совокупность, либо каждое в отдельности увеличивает риски родов через естественные родовые пути.
Показания для кесарева сечения выставляет квалифицированная акушерская бригада. В плановом порядке решение о выполнении кесарева сечения проводит консилиум специалистов. Как правило, это акушеры-гинекологи, иногда привлекаются врачи-неонатологи, врачи детской реанимационной службы или врачи каких-то других специальностей, когда речь идет об осложнениях или заболеваниях у беременной женщины. 

Операция кесарева сечения бывает: 

плановая
экстренная
При плановой операции женщина поступает накануне операции или за несколько дней до нее в отделение патологии беременности родильного дома или в акушерское отделение, готовится к операции. И в плановом порядке (обычно утром) выполняется операция. 

Экстренная операция производится по внезапно возникшим показаниям. Как правило, они разделяются на:

показания со стороны матери. Это ухудшение ее общего состояния, тяжелые осложнения беременности и родов (например, эклампсия, преэклампсия, кровотечение из-за отслойки плаценты или ее предлежания);
показания со стороны плода. Как правило, экстренная операция выполняется тогда, когда плод начинает страдать в процессе родового акта. Безусловно, что в процессе родов может ухудшаться его состояние. Это связано и как с наличием аномалий родовой деятельности, и также с истощением его компенсаторных возможностей (т.е. когда он не может пережить родовые схватки).
В любом случае кесарево сечение предполагает достижение самого лучшего результата – это рождение живого, здорового ребенка. Операция выполняется под анестезией. 98% операций по статистике в мире выполняется под так называемыми регионарными методами обезболивания (как правило, это спинальная анестезия), и только 2% – с помощью так называемого общего наркоза через интубацию трахеи. При регионарной (местной) анестезии обезболивается нижняя половина тела, включая живот

На сегодня однозначно самым безопасным способом анестезии является спинномозговая анестезия, именно поэтому 98% операций в мире выполняется с помощью нее.

${separator}

Урок 2. Подготовка к операции

Конечно, физиологические роды всегда будут в приоритете. Но если вам предстоит плановое кесарево сечение по медицинским показаниям, нужно внести коррективы в список вещей, которые понадобятся вам в роддоме.

На плановую операцию кесарево сечение беременная направляется в сроке беременности 38-39 недель. Может быть срок и раньше при наличии медицинских показаний. Госпитализация осуществляется в отделение патологии беременности. Если беременность приближается к доношенной, то вам не придется долго находиться в отделении в ожидании операции. 

Будущей маме важно не забывать документы, необходимые при госпитализации:

документ, удостоверяющий личность (паспорт, вид на жительство, удостоверение беженца); 
обменная карта; 
ксерокопии обследований, проведенные до или во время беременности, которые отсутствуют в обменной карте (например, УЗИ плода, УЗИ вен нижних конечностей, УЗИ сердца, заключение окулиста, МРТ позвоночника и т.д.).
Сумки в роддом

Если вам предстоит кесарево сечение, количество сумок не меняется – собираем все те же 3 сумки.

Первая сумка понадобится маме. В ней будут находиться вещи, которые нужно взять в отделение патологии беременности, где мама будет находиться в ожидании операции. Первую сумку следует взять с собой в роддом сразу. 
Вторая сумка нужна для вещей, которые потребуются в послеродовом периоде мамочке и ребенку. 
Третья сумка – это вещи на выписку из роддома для мамы и малыша. Вторую и третью сумки могут привезти родственники уже по факту рождения ребенка или к выписке. 
В пакете вещей, который будет необходим в отделении патологии беременности, где вы будете находиться перед операцией, рекомендуется иметь: 

Телефон, зарядное устройство (по желанию можете взять наушники);
Халат, желательно из натуральных волокон (вискоза или хлопок);
Ночная сорочка, носочки;
Полотенце для тела, полотенце для интимной гигиены;
Туалетная бумага, накладки на унитаз, влажные салфетки;
Одноразовые впитывающие пеленки (2-3 штуки);
Гигиенические принадлежности (жидкое мыло, зубная щетка, зубная паста, расческа, гигиеническая помада);
Питьевая вода негазированная (2-4 бутылочки по 0,5 л). Именно в бутылочке такого объема вам будет легко поднимать ее и пить после операции;
Сухое печенье (1 упаковка), мюслевый батончик или другие полезные вкусняшки. Вас будут кормить в отделении, однако, если захочется  скушать что-либо  вкусненькое, будет чем полакомиться. Помните, что слишком сытно есть перед операцией не следует;
Понадобятся чашка, вилка, ложка;
Компрессионные чулки. Они рекомендуются всем женщинам в случае предстоящего кесарева сечения.
Полезные лайфхаки по сборам в роддом перед операцией

О приобретении компрессионных чулок вам позаботиться нужно незадолго до родов, в сроке не ранее 37 недель. К концу беременности увеличиваются размеры окружности бедра, голени, лодыжки. Нужный размер чулок вам помогут определить в специализированных магазинах, а вот необходимую степень компрессии подскажет врач-специалист.

В первые сутки после операции вы не будете есть, нужна будет только питьевая вода. Поэтому запаситесь объемом воды в 1,5-2 л. 

Не лишним будет приобрести послеоперационный бандаж, который зафиксирует перерастянутую переднюю брюшную стенку, а также уменьшит напряжение в поясничной области. Если у вас уже есть бандаж до- и послеродовый (он выглядит приблизительно таким образом), то дополнительно покупать бандаж не следует. Однако можно приобрести специальный послеоперационный бандаж. Данный послеоперационный бандаж несколько шире, он в большей мере фиксирует перерастянутую переднюю брюшную стенку, и эффективность данного бандажа несколько лучше, чем предыдущего. 

Можно взять с собой послеоперационный пластырь шириной до 5 см, длиной 12-15 см. Он очень удобен в применении, не скатывается, хорошо фиксируется на коже передней брюшной стенки и герметично закрывает послеоперационный рубец. Его можно приобрести заранее в аптеке и использовать после операции. Акушерка после обработки шва наклеит пластырь на область послеоперационного рубца.

Хорошо зарекомендовал себя стерильный жидкий медицинский клей (адгезив) для местного наружного применения. В операционной хирург наносит его на послеоперационный рубец. Клей обладает эластической  структурой, водостойким эффектом. Он позволяет маме принимать душ в послеоперационном периоде, создает противомикробный барьер. Также в таком случае не нужно менять повязку на области рубца. Такой клей можно приобрести заранее в аптеке или уточнить о его наличии и приобрести в отделении.

Обследования перед операцией

Перед плановой операцией проводится комплексное обследование согласно клиническому протоколу. Эти обследования проводят в день поступления в роддом или на утро следующего дня.

Обследование включает в себя: 

общий анализ крови
общий анализ мочи
биохимический анализ крови
коагулограмму
анализ группы крови и резус-принадлежности
мазок на флору влагалищного отделяемого
УЗИ плода с допплерометрией
При необходимости проводят дополнительные обследования.

Вас обязательно пригласят на консилиум, в котором принимают участие лечащий врач, заведующий отделением, заместитель главного врача по родовспоможению и сотрудники кафедры (профессор или доцент). Консилиум проводят после обследования в день поступления или на следующий день.

На консилиуме принимают окончательное решение о необходимости выполнения операции кесарево сечение, определяют дату операции, операционную бригаду, обсуждают необходимость проведения антибиотикопрофилактики перед операцией или назначения антибактериальной терапии. 

За сутки до операции вас осмотрит анестезиолог, который будет обеспечивать обезболивание операции кесарево сечение. Анестезиолог обсудит с вами вид анестезии при проведении операции, ответит на все интересующие вопросы. Вы подпишете информированное согласие на проведение анестезии. 

В современной акушерской анестезиологии предпочтение при обезболивании операции кесарево сечение отдается спинальной анестезии. При этом виде анестезии женщина находится в сознании. Это позволяет маме познакомиться со своим малышом уже в первые минуты его жизни, поцеловать его и даже сделать первое совместное фото. В некоторых родильных домах практикуется прикладывание ребенка к груди матери на операционном столе. 

Накануне операции лечащий врач ответит дополнительно на все интересующие вас вопросы. После этого вы подпишите стандартное информированное согласие на выполнение оперативного вмешательства.

Образ жизни накануне операции

Накануне операции не следует голодать. Пейте достаточно жидкости, в обед можно скушать суп, немного каши, питьевой йогурт, а вечером допускается легкий ужин.

Очень важно хорошо отдохнуть и выспаться перед операцией. Чтобы справиться с вполне объяснимым волнением, лучше не отказываться от успокоительного средства, которое назначит врач для приема в вечернее время. Если волнений нет, можно воздержаться от использования успокоительного лекарства.

Утро перед операцией

Рано утром вас пригласит акушерка для выполнения очистительной клизмы. Затем следует принять душ. Есть и пить перед операцией нельзя. 

После пребывания в горизонтальном положении в постели в течение не менее 30 минут необходимо надеть компрессионные чулки. Если у вас возникли трудности, можете обратиться за помощью к акушерке. Непосредственно перед операцией вас переоденут в чистую сорочку. В мочевой пузырь вводится мочевой катетер. И на каталке вас доставляют в операционную. В следующем уроке мы подробно обсудим, как проводятся эти процедуры и почему они важны.

В этот день невозможно не волноваться, но основное, что будет воодушевлять вас – это долгожданная встреча с маленьким счастьем. Настраивайтесь на хорошую волну и будьте уверены, что специалисты очень постараются, чтобы операция прошла успешно.

${tb3c3_2}


Урок 3. Этапы операции

Перед любой операцией кесарева сечения (будь то плановая или экстренная) существует определенный «ритуал». В различных акушерских школах используют либо катетеризацию мочевого пузыря, либо просто выведение мочи. 

Как правило, в современном акушерстве используется катетеризация  мочевого пузыря, для того чтобы он был пустой, для того чтобы облегчить доступ к матке и рождению плода. Остальные гигиенические процедуры (очистительная клизма, обработка влагалища спиртосодержащими растворами) используются практически во всех родильных домах. Некоторые женщины считают, что клизма накануне кесарева сечения не нужна, но кишечник должен быть пустой, для того чтобы было удобно работать при выполнении операции, а именно ушивании матки и извлечении малыша.

На операцию женщина должна с собой взять обязательно хорошее настроение. Очень рекомендуем выспаться, если это плановая операция. Если это операция экстренная, разумеется, успокоиться очень тяжело. Поэтому следует собраться, и в любом случае нужно быть готовым к тому, что эта операция будет необходима.  

! Операция кесарева сечения настолько хорошо отлажена в нашей стране и в мире, что особо волноваться при любых обстоятельствах не стоит. Акушерская бригада сделает все возможное, чтобы операция прошла с максимально хорошим результатом. 

Операционная бригада

Непосредственно во время операции в операционной присутствует операционная бригада, в которую включаются обычно: 

два врача акушера-гинеколога (один оперирующий, второй ассистирует) 
операционная сестра
акушерка (которая забирает ребенка у хирурга, взвешивает его, пеленает)
анестезиологическая бригада (в которую включается врач анестезиолог-реаниматолог и медсестра-анестезист)
Как правило, в каждом родильном доме во время кесарева сечения в операционной находятся также: 

врач-неонатолог (или врач-педиатр)
иногда детская реанимационная бригада, если речь идет о необходимости реанимации новорожденного сразу после рождения. Как правило, это бывает в случае экстренного кесарева сечения. 
У каждого из членов операционной бригады есть свои задачи, свои функции, которые они выполняют на протяжении всей операции. 

Как быстро выполняется операция кесарева сечения? Врачи акушеры-гинекологи считают этот вопрос суеверным, и мы на этот вопрос никогда не отвечаем. Разумеется, оно выполняется максимально быстро, насколько это возможно в каждой конкретной ситуации. 

Швы

Обсудим какие бывают разрезы, а уже потом – какой будет технология ушивания. В современном акушерстве чаще всего используется только один  вид разрезов, который оптимален для всех женщин, – он называется косметический. Это разрез в зоне бикини Современная технология позволяет достаточно безопасно использовать его для извлечения плода из полости матки при операции кесарево сечение. 

Как ушивается сам разрез кожи? Как правило, акушеры-гинекологи в нашей стране используют так называемую внутрикожную технологию ушивания, которая точно так же у обывателей называется косметический шов. Эти швы не снимаются после операции – как правило, они выполняются синтетическими нитями, которые быстро рассасываются и не доставляют никаких проблем для женщин. 

Последние несколько лет в современные технологии вошло использование так называемых клеев для кожи. При некоторых операциях использование этого клея возможно. Технология позволяет использовать клей при многих видах разрезов. Но иногда использование клея невозможно – например, как правило, повторные операции кесарева сечения сопровождаются кровоточивостью краев раны.

Иногда женщина может выбрать, каким способом будет ушита рана: либо обычным швом, либо клеем. Мы с удовольствием идем навстречу как и в том, так и в другом случае. 

Ушивание кожного разреза в современном акушерстве осуществляется специальными синтетическими тончайшими нитями, которые позволяют быстро затягиваться операционной ране. Рубчик образуется тонкий, практически невидимый. Многие женщины думают, что от ушивания зависит качество рубца. Качество рубца зависит не только от нити и от хирурга, качество рубца еще зависит от особенностей иммунной системы и заживления тканей у самой женщины. 

Может ли партнер присутствовать во время кесарева сечения?

В настоящее время такая технология в нашей стране практически не применяется. Для этого необходимы определенные условия в операционной.  

Но сейчас мы активно обсуждаем эту технологию, мы считаем ее иногда применимой, особенно во время планового кесарева сечения. Для этого  должны быть заключены договоры на партнерские роды. 

Если это партнерские роды и папа отсутствует в операционной, то после рождения ребенка его могут положить на живот, на грудь отцу, который находится в каком-то помещении (обычно это родовая палата или родильный зал). И  отец выполняет ту же самую функцию, которую должна выполнять и мама после родов – согреть малыша. Поэтому присутствие на родах отца, даже если это кесарево сечение, мы считаем целесообразным. 

Пока условия в наших родильных домах не позволяют это использовать во всех случаях выполнения операции кесарево сечение. Но за этим будущее. 

Как и во время родов через естественные родовые пути, во время кесарева сечения тоже используется технология отсроченного пересечения пуповины. Чаще всего эта технология используется при плановом кесаревом сечении, когда состоянию ребенка ничего не угрожает. 

Иногда во время экстренных операций кесарева сечения (когда оно выполняется по показаниям со стороны плода, когда что-то угрожает  рождению живого ребенка) нет возможности отсроченного пересечения пуповины, потому что рождение ребенка требует оказания реанимационной помощи.

${separator}

Урок 4. Экстренное кесарево сечение

Операция экстренного кесарева сечения выполняется по определенным показаниям.

Показания для экстренного кесарева сечения могут быть связаны: 

с состоянием плода – когда записывается плохая кардиотокограмма, когда мы понимаем, что плод страдает, и дальнейшее течение родов через естественные родовые пути становится опасным для малыша;
с состоянием матери – как правило, эти показания определяются наличием аномалий родовой деятельности, связанными с неправильными схватками, затянувшимися схватками, на фоне преждевременного разрыва плодных оболочек. Также показанием для экстренного кесарева сечения в родах может явиться кровотечение, связанное с преждевременной отслойкой плаценты.
В любом случае акушерская бригада готова выполнить кесарево сечение в течение нескольких минут от возникновения показаний и принятия решения. Вся работа родильного дома строится таким образом, чтобы это кесарево сечение было сделано максимально быстро и квалифицированными специалистами в сопровождении полноценного метода обезболивания кесарева сечения.  

Операция выполняется точно так же, как и плановое кесарево сечение.  Иногда при экстренном кесаревом сечении требуется участие в операции не только традиционных акушеров-гинекологов, врача-неонатолога, но и  детской реанимационной бригады для оказания квалифицированной помощи новорожденному в случае необходимости проведения реанимационных мероприятий. 

Ход самой операции при плановом и экстренном кесаревом сечении ничем не отличается друг от друга.

Кесарево сечение, выполненное без осложнений, протекающее в течение нескольких суток после родов в традиционном режиме, не требует задержки выписки. Женщина может быть точно так же, как и после планового кесарева сечения, выписана на 5-7-е сутки. 

Родильные дома сегодня практикуют раннюю выписку после операций кесарева сечения. Это связано с тем, что здоровая мама и здоровый ребенок могут находиться дома, поскольку там обеспечиваются наиболее комфортные условия для совместного пребывания и естественного грудного вскармливания.  

В любом случае, вне зависимости от того плановое это кесарево сечение или экстренное, персонал родильного дома готов сделать все возможное, чтобы мама и ребенок оказались как можно раньше дома, в естественных домашних условиях. Потому что именно там можно обеспечить полноценный режим совместного пребывания и естественного грудного вскармливания.

${tb3c3_3}

Урок 5. Восстановление после операции

После операции новоиспеченная мама первые 6-8 часов находится в палате реанимационного отделения. За ее самочувствием наблюдает врач-анестезиолог, врач акушер-гинеколог и медсестра реанимационного отделения. Они проводят мониторный контроль насыщения крови кислородом, частоты дыхания, частоты пульса, величины артериального давления, оценку цвета кожных покровов. Этот контроль проводится при использовании специальных приспособлений: манжеты для измерения артериального давления надеваются на плечо, датчик для измерения сатурации (насыщения крови кислородом) надевается на палец. На экране монитора врач может видеть все показатели и понимает, все ли с вами в порядке.

Измеряется температура тела, объем выделенной мочи. Периодически врач будет осматривать область послеоперационного рубца. Также врач может подходить и ощупывать ваш живот, чтобы определить размер, консистенцию, болезненность матки. Оценивается количество и характер выделений из половых путей. 

В реанимационном отделении обязательно проводится обезболивание. Поэтому если вас очень беспокоят боли в нижних отделах живота, обязательно скажите об этом врачу. У каждой женщины свой порог болевой чувствительности, поэтому некоторым может понадобиться дополнительное обезболивание. 

Для восстановления водного баланса организма после операции внутривенно вводятся солевые растворы. При необходимости женщине также вводят антибактериальные препараты. 

Врачи обязательно контролируют результаты лабораторных исследований, то есть женщине берут определенные анализы. Мы их обязательно обсудим в уроке про обследования после кесарева сечения.

Через 6-8 часов после операции постепенно восстанавливается чувствительность в нижних конечностях. После полного восстановления двигательной активности приветствуется раннее вставание, как правило, через 8-12 часов после операции.

Как только мама сможет чувствовать свои ноги и легко двигать ими, можно начинать делать физические упражнения, даже находясь в постели. Например, двигать стопой, сгибать ноги в коленях, приводить ноги к животу, поворачиваться на бок. Через 4-6 часов после перевода из операционной женщина может выполнять дыхательную гимнастику, лечебную физкультуру.

Когда можно вставать? 

после операции кесарево сечение. Рекомендуется вставать через 6-8 часов. Главное правило – переходить из горизонтального положения в вертикальное постепенно. Впервые женщина будет вставать с помощью медицинского персонала в палате интенсивной терапии. В этом ей поможет медсестра реанимационного отделения.

Вставать с постели обязательно нужно правильно, желательно делать это в несколько приемов. Обсудим их. 

Аккуратно поворачиваемся на бок и свешиваем ноги с кровати. 
Затем медленно переводим тело в сидячее положение. Нужно немного посидеть, чтобы не закружилась голова. 
Потом опускаем ноги на пол, встаем прямо и делаем первые небольшие шаги.
Что насчет походов в туалет? В отделении реанимации вы по-прежнему находитесь с мочевым катетером. Желания сходить в туалет по-большому у вас тоже не будет, ведь вам сделали накануне очистительную клизму.

Ранняя активность мамы поможет быстрому восстановлению после операции, ведь совсем скоро необходимо будет ухаживать не только за собой, но и за малышом. 

Где в это время находится малыш?

Во время пребывания мамы в реанимационном отделении ребенок находится в отделении новорожденных. За ним наблюдают врач-неонатолог и детская медсестра. Они осуществляют профессиональный уход, при необходимости проводят обследование, кормление ребенка. Если в состоянии ребенка происходят какие-либо изменения, то об этом обязательно информируется мама.

Послеродовое отделение 

Если операция проводилась в дневное время, то родильницу переводят в послеродовое отделение через 8-12 часов. Вопрос о переводе решают совместно врач-реаниматолог и акушер-гинеколог. А вот если с мамой что-то не так, женщина может задержаться в отделении реанимации на какое-то время. Если операция выполнялась в вечернее или ночное время, женщину переводят на следующее утро. И уже в послеродовом отделении женщине удаляют мочевой катетер. 

После перевода в послеродовое отделение вам нужно постараться активизироваться в максимально короткие сроки. Это важно, чтобы получалось справиться не только с уходом за собой, но и поскорее остаться с малышом на совместном пребывании. Помните: чем выше послеоперационная активность (в рамках допустимого, конечно), тем ниже риск возникновения спаек, тромбозов. 

Уход за швом

Специального ухода за швом не требуется. Повязка или пластырь на коже должны быть сухими и чистыми. По назначению врача шов могут обрабатывать антисептическими растворами, чтобы предотвратить развитие инфекции, а также повязку могут сменять. Как правило, это происходит 1 раз в день. Сейчас процесс заживления ран после операции происходит очень быстро. Все благодаря современному шовному материалу и отлаженной технологии проведения операции кесарева сечения. 

Введение обезболивающих препаратов проводится 2-3 раза в день в первые трое суток. Если нет необходимости в обезболивании, врач отменит уколы.

Правила интимной гигиены

Посещать туалетную комнату в послеродовом послеоперационном периоде рекомендуется часто. Желательно проводить смену прокладок не реже чем через 3 часа или чаще по мере необходимости.

Будет отлично, если стул мамы будет ежедневным. Мочиться также рекомендуется почаще, не следует сдерживать позывы к мочеиспусканию. Иногда могут возникать трудности с мочеиспусканием или опорожнением кишечника. Обязательно скажите об этом врачу. В таких случаях, возможно, вам нужно изменить свой рацион, выбрать другие продукты. Иногда могут понадобиться лекарственные средства.

Первое кормление малыша

Вскоре после перевода в послеродовую палату произойдет долгожданный момент – вам привезут на первое кормление малыша. Детская медсестра или акушерка всегда поможет приложить ребеночка к груди, расскажет, как это сделать правильно, покажет, какое положение для кормления лучше. Обязательно смотрите следующий урок, там мы также поделимся важными советами по грудному вскармливанию после операции и покажем удобные позиции для кормления.

Как только мама сможет самостоятельно ухаживать за ребенком, его отдадут на совместное пребывание. Раннее совместное пребывание положительно сказывается на становлении грудного вскармливания, ведь ребенка рекомендуется прикладывать к груди по требованию. 

Палаты, в которых находятся женщины после родов, оборудованы всем необходимым для комфортного пребывания матери и ребенка. Если вам будет трудно ухаживать за малышом, вам захочется отдохнуть – можно отдать малыша на детский пост. Очень важно, чтобы мамочка была в настроении и полна сил. 

Полезные советы 

В случае сильного болевого синдрома не стоит отказываться от обезболивания. Важно, чтобы ваше состояние было комфортным.

Чтобы облегчить боль, можно использовать послеродовый бандаж уже в 1-е сутки после операции. Бандаж поддерживает перерастянутую во время беременности переднюю брюшную стенку, уменьшает мышечное напряжение, облегчает физические нагрузки в раннем послеоперационном периоде.

Очень важно сохранять бодрость духа, задавать все интересующие вас вопросы, получать советы профессионалов. В позитиве и вместе с малышом восстановление в послеоперационном периоде пройдет гораздо быстрее.

${separator}

Урок 6.  Особенности питания и грудного вскармливания после КС 

Эксперты Всемирной организации здравоохранения рекомендуют раннее возобновление питания и раннюю активизацию женщины после операции кесарева сечения. Это способствует более быстрому восстановлению мамы.

Питье

В первые сутки после операции рекомендуется только питье. Питьевой режим – это начальный этап восстановления работы желудочно-кишечного тракта.

Сколько же пить воды? Потребность в жидкости индивидуальна. Она зависит от множества факторов, таких как масса тела женщины, наличие у нее каких-либо заболеваний (например, сахарный диабет, артериальная гипертензия, тромбофилические состояния), температуры окружающей среды и других факторов. Объем воды в 1-е сутки не должен быть меньше 1,5 л, этот объем может быть и больше. Оптимально нужно ориентироваться на собственные ощущения и не игнорировать жажду. 

Когда можно начинать пить? Питьевая негазированная вода рекомендуется уже через 2 часа после окончания операции. Пить нужно небольшими глотками.

Питание

В 1-е сутки через 4-6 часов после операции можно пить бульон, приготовленный из нежирных сортов мяса (это индюшиное филе, телятина, куриная грудка, нежирная свинина). Можно кушать в небольшом количестве сухарики, слизистые каши, питьевой йогурт без добавок. Употреблять пищу нужно небольшими порциями в 5-6 приемов. Если есть возможность, свежеприготовленный бульон могут передать родственники.

Уже на следующий день, со 2-х суток, родильница переводится на общий стол. Приветствуется достаточное количество питьевой воды (1-1,5 л). Пища должна быть калорийной с достаточным содержанием белка и растительной клетчатки. В рацион мама может вводить паровые котлеты, каши, отварное мясо (например, индейка, телятина), сыр, печеные овощи, кефир. Очень важно, чтобы продукты, которые назначаются в первые 3 дня после операции, не приводили к запорам и газообразованию. 

Основные рекомендации по питанию

 придерживайтесь частого дробного питания;
 оптимальный режим – 5-6 приемов пищи в день;
 интервалы между приемами пищи – примерно 2-3 часа; 
 старайтесь тщательно и медленно пережевывать пищу;
рекомендуем разогревать приготовленную пищу, для этого имеются микроволновые печи в столовых отделениях.
Рекомендуется исключить из рациона или значительно ограничить (особенно в первые дни после операции) газированные напитки, цитрусовые соки, кофе, чай, алкоголь, жареные продукты, свежую капусту, лук. Помогите своему желудочно-кишечному тракту восстановиться.

Овощи, зелень и фрукты помогут обеспечить организм необходимыми витаминами, минералами, клетчаткой, а также будут способствовать нормальной работе желудочно-кишечного тракта. Очень важно проводить профилактику запоров и добиваться регулярного опорожнения кишечника. 

Кормящим женщинам рекомендуют отварную морковь, сладкий картофель, запеченную тыкву, шпинат, термически обработанную зелень, кабачки, томаты, термически обработанный болгарский перец. Из фруктов: бананы, зеленое яблоко. Источниками белка и кальция служат молочные и кисломолочные продукты (молоко, йогурт, простокваша, мягкие сыры). Также очень важно включать в рацион орехи и семечки (семена подсолнуха, миндаль, лесной орех, кедровые орехи), нежирное мясо (телятина, индейка), рыбу и морепродукты (лосось, форель, минтай, сардины; при этом следует избегать сорта рыб, которые могут накапливать ртуть, – скумбрия, тунец). Еще одни важные источники клетчатки, которые нужны нам для нормальной работы кишечника, – это злаки и крупы.

Становление ГВ

В современном акушерстве плановое кесарево сечение в роддомах проводится с использованием регионарной анестезии, то есть женщина находится в полном сознании. Поэтому рекомендуется во время операции или сразу после ее завершения приложить ребенка к коже матери и к груди, если это возможно. 

Грудное вскармливание рекомендуется начинать как можно раньше. В некоторых роддомах практикуется прикладывание ребенка к груди уже на операционном столе. При удовлетворительном состоянии родильницы и новорожденного, ребенка могут приносить на кормление, даже когда женщина находится в отделении интенсивной терапии и реанимации. Но, как правило, ребеночка приносят к маме только после перевода в послеродовую палату.

После кесарева сечения вопросы становления грудного вскармливания имеют определенные трудности, так как мама меньше двигается, больше находится в положении лежа. И к тому же молоко появляется несколько позже, чем обычно.

На что нужно обратить внимание? 

Кормление необходимо начинать, когда ребенок проголодается (т.е. по требованию). Ребенка советуют кормить так часто, как это возможно, и так долго, насколько ему требуется. 
Мамочке важно найти подходящее положение для кормления, ведь после операции ей может быть сложно держать ребенка на руках. Правильные позиции может подсказать и персонал роддома (акушерка или детская медсестра), они могут помочь маме правильно расположить ребенка для кормления, если это необходимо.
После операции важно выбрать оптимальное положение для кормления грудью, при котором у вас будут меньше всего ощущаться послеоперационные боли. 

Позы для кормления ребенка после операции кесарево сечение

Положение «Лежа на боку»

${tb3c3_4}

Мама укладывается  на бок, под голову нужно положить удобную подушку, для удобства подушку можно также положить между коленей. Ребенок укладывается на бок, ротик ребенка располагается на уровне соска, таким образом мама осуществляет кормление в расслабленной и удобной позе. Под спинку ребенка можно для удобства положить валик из пеленки.

Положение «Колыбель»

${tb3c3_5}

Женщине нужно расслабиться и удобно сесть, чтобы у спины была опора. Для этого под спину можно положить подушку или специальную подушку для кормления. Ноги  желательно поставить на опору. Голова ребенка находится в локтевом сгибе, спинка ребенка лежит на протяжении всей руки, нос ребенка тянется к соску. Для удобства можно под руку маме положить подушку, и в таком расслабленном положении мама осуществляет кормление ребенка.

Положение «Футбольный мяч»

${tb3c3_6}

Оно особенно подходит для  детей с малым весом. Надо подложить подушку под плечи и твердые подушки по бокам, поднять ребенка на уровень груди. Ребенок должен быть повернут лицом к матери,  тело должно находиться под ее рукой. Головка и шейный отдел должны лежать на ладони матери на одной прямой линии, мама поддерживает рукой малыша сзади под шею, важно не сгибать голову ребенка при поднесении к груди. Шея ребенка должна быть прямой. И в таком положении продолжается кормление.

Бывают ситуации, когда кормление откладывается на более поздние сроки. Например, женщина принимает антибиотики, у нее возникли послеоперационные осложнения или ребенок слишком слаб, чтобы сосать грудь. Тогда рекомендуется своевременно начать сцеживание грудного молока. В этом вам поможет молокоотсос, который вы можете взять с собой в роддом. 

Сцеживание следует начинать, как только мама сможет собрать даже небольшой объем молока. Чтобы стимулировать выработку молока, нужно сцеживать грудь каждые 3 часа. Даже небольшой объем сцеженного грудного молока будет очень полезен новорожденному. Сцеживание грудного молока стимулирует становление и поддержание лактации до того периода, когда мама с ребеночком окажутся вместе. Если вы не знаете, как сцеживаться – вам обязательно помогут акушерки. 

Позитивный настрой женщины, уверенность в достижении поставленной цели, продуктивный контакт с профессионалами помогут справиться со всеми сложностями в послеоперационном периоде. Всегда обращайтесь за помощью к врачам, акушеркам, детским медсестрам. Мы всегда готовы поддержать вас и помочь вам в решении возникших проблем.

${tb3c3_7}

Урок 7.  Обследования после КС

После выполнения операции кесарево сечение маму переводят в палату интенсивной терапии в реанимационное отделение. 

Сразу после операции

Врач-анестезиолог оценивает общее состояние мамы после проведения анестезии и операции, цвет кожных покровов, измеряет температуру тела, пульс, артериальное давление. Врач акушер-гинеколог проводит пальпацию живота. Он определит высоту стояния дна матки, консистенцию, болезненность матки. Также он оценивает состояние послеоперационного рубца на передней брюшной стенке, характер выделений из половых путей. 

Важное значение имеет функциональная активность кишечника в раннем послеоперационном периоде – живот при пальпации должен быть мягким, не вздутым. При повышенном газообразовании маме назначают препараты, стимулирующие работу кишечника. Также врач проводит контроль функции почек. Катетер в мочевом пузыре после операции остается 6-8 часов, вся выделенная моча попадает в мочеприемник (это специальный пакет для сбора мочи). Врач оценивает количество выделенной мочи, цвет, наличие патологических примесей, крови.

1-ые сутки

В 1-е сутки у мамы берут несколько анализов:

общий анализ мочи
общий анализ крови
биохимический анализ крови,
анализ крови на свертываемость (гемостазиограмма)
В реанимационном отделении обязательно проводится инфузионная терапия. После проведенного вмешательства, кровопотери во время операции, стрессового состояния очень важно восстановить водный баланс в организме женщины. Поэтому через внутривенный катетер женщине будут вводится солевые растворы.

Также маме назначается обезболивание. Выбор лекарственных средств для обезболивания, частота введения зависят от уровня болевой чувствительности у женщины. 

Если у женщины высокий риск инфекционных осложнений, то врач назначает антибактериальные препараты, которые также будут вводить внутривенно. Обязательно используют специальные препараты для профилактики тромбозов. Повреждение сосудов при проведении операции значительно повышает риск жизнеугрожающих осложнений.

Через 8-24 часа маму переводят в послеродовую палату. Как мы обсуждали ранее в уроках, приветствуется раннее вставание, как правило, через 8-12 часов после операции. 

2-ые и 3-ие сутки

В первые 3 суток в послеродовом отделении к маме будет приходить: 

врач акушер-гинеколог (2 раза в сутки)
врач-неонатолог (1 раз в сутки) 
При необходимости акушерка или детская медсестра может вызвать врача в любое время суток. 

Что же будет происходить на этих обходах? Врач акушер-гинеколог оценивает общее состояние женщины, цвет кожных покровов, температуру тела, пульс, давление. Также осмотрит молочные железы  и оценит состояние послеоперационного рубца, то есть посмотрит, нет ли там признаков воспаления, покраснения, отека, уплотнения. 

Также доктор обязательно прощупает живот, чтобы оценить высоту стояния дна матки, консистенцию, болезненность. Врач поинтересуется у вас, как функционирует кишечник: отходят ли газы, был ли стул за прошедшие сутки. Если у мамы есть варикозное расширение вен, то он проведет осмотр и пальпацию вен на ногах мамы.

В первые 3 суток постепенно расширяется питание родильницы. Она может проводить дыхательную гимнастику, лечебную физкультуру. Также проходит физиотерапевтическое лечение (например, магнитотерапия). Физиопроцедуры назначает врач-физиотерапевт.

Приветствуется двигательная активность в пределах комфорта.  Женщина может ходить по палате, по коридору, делать простые упражнения. Также ежедневно инструктор ЛФК проводит занятия в послеродовой палате.

Если у женщины нормально и без осложнений протекает послеоперационный период, то на 3-и сутки ей проводится повторное лабораторное исследование:

общий анализ крови
гемостазиограмма
4-ые сутки

На 4-5-е сутки после операции женщине сделают УЗИ органов малого таза (именно в этот период оно наиболее информативно), ведь за этот период времени матки значительно уменьшается в размерах. При УЗИ врач оценивает размеры тела матки, структуру содержимого полости матки. Если врач видит, что есть задержка сгустков в полости матки, то он может менять лечение. Маме назначат препараты, которые будут улучшать сокращение матки.

Если у мамы есть сопутствующие сердечно-сосудистые или эндокринные заболевания, то ей рекомендуются консультации врачей специалистов (эндокринологов, кардиологов). Это нужно, чтобы откорректировать лечение основного заболевания с учетом противопоказаний при грудном вскармливании. На консультацию специалистов направляет лечащий врач акушер-гинеколог.

5-е сутки

Это день, в который мама с малышом уже может выписаться домой. Какие же обследования  ее ждут в этот день?

На 5-е сутки врач проводит влагалищный осмотр на гинекологическом кресле. Женщине снимают швы с послеоперационного рубца. И если с мамой и с малышом все в порядке, они могут быть выписаны домой. В следующем уроке мы еще подробно поговорим о теме выписки после операции и домашнем восстановлении.

Также в послеродовом отделении проводятся беседы о принципах грудного вскармливания, лекции по уходу за новорожденным, уходу за собой в послеродовом периоде, родильниц обучают сцеживанию молочных желез. Кроме того, на ежедневных обходах врачи, акушерки, детские медсестры расскажут о налаживании грудного вскармливания, уходе за новорожденным. Всегда задавайте вопросы, если что-то непонятно или вам нужно что-то уточнить.

Осложнения после операции кесарево сечение

Кровотечение во время операции и в раннем послеоперационном периоде
Чтобы избежать такого тяжелого осложнения, предпринимаются профилактические меры уже при проведении операции. Например, разрез на матке делают в малососудистой зоне. При ушивании матки врач использует специальные методики, использует длительно рассасывающийся шовный материал. Все это необходимо для полноценного и качественного формирования рубца. После того как ребенок извлекается из полости матки, уже состоявшейся маме внутривенно вводят препараты, которые улучшают сокращение матки.

В раннем послеоперационном периоде для профилактики кровотечения врач акушер-гинеколог проводит наружный осмотр, массаж матки, оценивает консистенцию, размер матки, характер и количество выделений из половых путей. В первые 2 часа такой осмотр проводится каждые 30 минут. Если необходимо, врач назначает длительное введение препаратов для улучшения сократительной функции матки. 

Гнойно-воспалительные осложнения
Такие осложнения могут быть связаны с инфицированием области рубца, а также полости матки.

Накануне операции кесарево сечение врач акушер-гинеколог оценивает инфекционный риск и решает, нужна ли женщине антибиотикопрофилактика. Именно поэтому мы, врачи, так часто говорим о том, как важно лечить хронические очаги инфекций еще при планировании беременности (а также во время беременности), лечить инфекции влагалища, мочевых путей, лечить кариес. Именно это лечение снизит риск инфекционных осложнений в дальнейшем. 

Если у женщины низкий и средний риск инфекционных осложнений, то перед операцией кесарево сечение ей назначают антибактериальный препарат. Препарат вводится внутрь за 30-60 минут до начала операции. 

Если у женщины высокий инфекционный риск, если у нее осложненное течение родов, то в послеоперационном периоде ей назначается терапия антибиотиками широкого спектра действия. А как же кормление грудью, спросите вы. Не переживайте, как правило, используются антибактериальные препараты, которые не противопоказаны при грудном вскармливании.

Чтобы предупредить инфекции послеоперационной раны, женщине во время пребывания в роддоме меняют стерильные повязки на передней брюшной стенке, обрабатывают область рубца антисептическими растворами.

Тромбоэмболические осложнения
Это угрожающие жизни женщины состояния. Поэтому перед операцией врач оценивает факторы риска возникновения тромбозов, определяет меры профилактики.

Начинается медикаментозная профилактика тромбоэмболических осложнений еще до операции. Во всех случаях при отсутствии противопоказаний женщине рекомендуется использовать компрессионный трикотаж (компрессионные чулки или эластичные бинты). Советуем приобрести их незадолго до родов и обязательно положить их в свою сумку в роддом. Если у вас были изменения в анализах после родов, вам обязательно порекомендуют проконтролировать эти показатели после выписки. 

Если послеродовой период протекает без осложнений, вам рекомендуется прийти в женскую консультацию в первый раз через 10 дней после выписки. Врачу акушеру-гинекологу вы предоставите вкладыш из обменной карты, сведения родильного отделения о родильнице, в котором подробно указано, как протекали ваши роды. Врачу, который вас наблюдал во время беременности, очень важно знать исход беременности и оценить ваше состояние через 2 недели после родов. Врач акушер-гинеколог проведет гинекологический осмотр, оценит размер матки, характер выделений из половых путей, состояние рубца на передней брюшной стенке.

${separator}

Урок 8. Домашнее восстановление после КС

Обсудим, что будет происходить дома после выписки и как будет восстанавливаться мама.

Гигиена

Маме рекомендуется 2 раза в день принимать душ, мыть молочные железы с мылом. Особое внимание следует обращать на чистоту рук. Ногти рекомендуется коротко подстригать, а руки – чаще мыть с мылом и обязательно перед каждым кормлением ребенка. 

Уход за областью послеоперационного рубца

Если у женщины внутрикожные швы с рассасывающимся материалом, то нет необходимости в снятии швов. Если же швы не рассасывающиеся, то нить удаляют на 5-е сутки после операции перед выпиской из роддома. 

Полное затягивание наружного рубца на коже завершается обычно через 3 недели после операции. Если ранний послеоперационный период протекал с осложнениями, то в таком случае вполне возможно более долгое заживление шва. Возможны осложнения в виде разрастания соединительной ткани, формирования грыж, свищей, келоидного рубца, расхождения части швов. В этом случае вопрос ухода рассматривается индивидуально. Врач может предупредить женщину о том, что ей запрещено мочить рубец. В роддоме каждой маме обязательно дадут рекомендации по уходу за швом.

Если нет проблем с заживлением рубца (что бывает в большинстве случаев), то не следует после снятия швов заклеивать его повязкой. Послеоперационный рубец должен «дышать». После душа просушите область рубца чистым мягким полотенцем. 

После полного заживления послеоперационного рубца обработка антисептическими средствами не рекомендуется. Если же появились признаки воспаления (отек, уплотнение, покраснение кожи, отделяемое из раны или расхождение краев раны), обязательно следует обратиться к врачу и получить дополнительные рекомендации. 

Принимать ванну первые 6 недель после родов крайне не рекомендуется. Следует помнить, что полное затягивание наружного рубца на коже завершается обычно через 3 недели после операции. Кроме того, послеродовые выделения из влагалища заканчиваются не раньше 3-4 недель после родов, поэтому и не рекомендуются сидячие ванны. Размокание рубца и попадание микроорганизмов, которые обитают в водопроводной воде, могут вызвать вторичное инфицирование рубца на коже и матки. Отдавайте предпочтение принятию душа.

Бандаж после кесарева сечения

Бандаж после операции помогает сократиться растянувшимся мышцам брюшной стенки,  снимает нагрузку с позвоночника. Носить бандаж можно уже со 2-х суток после операции. Надевать бандаж лучше в положении лежа, носить до 12 часов в день во время физической активности, делая перерывы примерно через каждые 3 часа на 20-30 минут. В ночное время бандаж нужно снять. Длительность ношения бандажа индивидуальна – в среднем до 6 недель после родов.

Подбирать бандаж тоже нужно индивидуально по размеру окружности талии и бедер, желательно его примерить. Ориентироваться надо на размер одежды, который женщина носила до беременности, или на 1-2 размера больше, если женщина поправилась во время беременности. 

Интимная гигиена

Носите хлопчатобумажное свободное белье, часто меняйте гигиенические прокладки. Можете делать воздушные ванны для промежности и послеоперационного рубца. При туалете интимной зоны используйте специальные гели для интимной гигиены. Так вы сможете сохранить нормальную микрофлору влагалища и будете профилактировать инфекции влагалища.

Сексуальная жизнь и контрацепция

Половую жизнь после родов можно возобновить к концу послеродового периода – спустя 6-8 недель после родов. Идеальный метод контрацепции поможет подобрать врач женской консультации по месту жительства или ваш лечащий врач акушер-гинеколог, например, в медицинском центре.

Через какой промежуток времени можно планировать следующую беременность после кесарева сечения? Оптимальный промежуток контрацепции после родов путем операции кесарево сечение – это промежуток не менее 2 лет. За этот период времени мамочка полностью восстановится после операции, произойдет формирование полноценного рубца на матке, восстановится менструальный цикл. Также малышу очень необходимо мамино грудное молоко. По статистическим данным, при промежутке между операциями кесарево сечение менее 2-х лет выше риск осложненного течения последующей беременности и родов.

Физическая активность

Упражнения важны для укрепления мышц, стабилизации психоэмоционального состояния, восстановления функциональности внутренних органов. 

Если в первые дни они представляли собой лечебную физкультуру, то уже через 3-4 недели являются полноценной зарядкой. Нужно постепенно увеличивать продолжительность занятий, расширять комплекс упражнений. Занятия должны быть комфортными для организма, не следует усердствовать в выполнении комплексов упражнений, не нужно переутомляться. Полноценные занятия спортом рекомендуется начинать не ранее 6-8 недель после операции.

Питание

Будет здорово, если ваше питание будет калорийным, сбалансированным, с должным количеством белков, жиров, углеводов, витаминов и микроэлементов. Советуем делать упор на продукты с преобладанием молочнокислых, белковых продуктов, свежих фруктов, овощей. Следует исключить из рациона «гастрономический мусор»: острые, жирные, жареные, копченые блюда, консервы, колбасы, алкоголь. Ведь вашему организму нужно дать время, чтобы восстановиться после перенесенной операции.

Отличный вариант для новоиспеченной мамы – это пяти-, шестиразовое питание. Также следует обращать внимание на регулярность стула и профилактику запоров. В этом вам поможет клетчатка, свежие овощи и фрукты.

Грудное вскармливание

В этих вопросах вы совсем скоро станете настоящими профессионалами, ведь вас ждет 2 больших курса по теме грудного вскармливания. А если что-то необходимо уточнить, обязательно обращайтесь за помощью к врачам, дружественным к грудному вскармливанию. Они с радостью вам помогут. И ваше восстановление пройдет легко и быстро.

Каким бы ни был ваш сценарий родов, мы желаем вам самого главного – здоровья и вам, и вашему малышу!


    `,
};
