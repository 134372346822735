import { useTranslate } from 'hooks/useTranslate';
import styles from './StudyProgressScale.module.css';

type StudyProgressScaleProps = {
  percents: number;
};

export const StudyProgressScale = ({ percents }: StudyProgressScaleProps) => {
  const t = useTranslate();
  return (
    <div className={styles.container}>
      <div className={styles.progressContainer}>
        <p className={styles.title}>{t('your_progress')}</p>
        <p className={styles.progress}>{percents}%</p>
      </div>
      <div className={styles.mainLine}>
        <div
          className={styles.secondLine}
          style={{
            width: `${percents}%`,
          }}
        ></div>
      </div>
    </div>
  );
};
