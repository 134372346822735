import { FC, InputHTMLAttributes, useCallback, useState } from 'react';
import classNames from 'classnames';
import styles from './Input.module.css';
import { EyeCloseIcon } from 'assets/icons';
import { EyeOpenIcon } from 'assets/icons';
import { WrongIcon } from 'assets/icons';
import { noop } from 'lodash';

const Input: FC<
  InputHTMLAttributes<HTMLInputElement> & {
    label: string;
    error: boolean;
    secret?: boolean;
    full?: boolean;
    onChangeFocus: (focus: boolean) => void;
  }
> = ({ label, error, secret, full, onChangeFocus, ...props }) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isSecret, setIsSecret] = useState(secret);

  const handleOnFocus = useCallback(() => {
    setIsFocused(true);
    onChangeFocus(true);
  }, [onChangeFocus]);

  const handleOnBlur = useCallback(() => {
    setIsFocused(false);
    onChangeFocus(false);
  }, [onChangeFocus]);

  const toggleSecret = useCallback(() => setIsSecret(!isSecret), [isSecret]);

  return (
    <div
      className={classNames(
        styles.container,
        error && styles.containerError,
        (isFocused || props.value) && styles.focused,
        full && styles.full
      )}
    >
      <label>{label}</label>
      <input
        {...props}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        type={isSecret ? 'password' : 'text'}
      />
      <div className={styles.icon} onClick={secret ? toggleSecret : noop}>
        {secret && !error && (isSecret ? <EyeCloseIcon /> : <EyeOpenIcon />)}
        {!!error && <WrongIcon />}
      </div>
    </div>
  );
};

export default Input;
