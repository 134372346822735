import { FC, memo} from "react"
import styles from './StepsLabel.module.css';
import { STEPS_QUANTITY } from "features/Auth/constants/steps";
import { useTranslate } from "hooks/useTranslate";


const StepsLabel: FC<{
  step: number,
  total?: number,
}> = memo(({
  step,
  total = STEPS_QUANTITY,
}) => {
  const t = useTranslate();

  
  return (
    <div className={ styles.container }>
      <div className={ styles.wrapperColor }>
        {
          `${t('common-step')} ${step}` 
        }
      </div>
      <div className={ styles.wrapperGrey }>
        {
          `${t('common_from')} ${total}` 
        }
      </div>
    </div>
  )
})

export default StepsLabel;
