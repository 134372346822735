import { Title } from 'components/Text/Title/Title';
import { FC, useCallback } from 'react';
import { Text } from 'components/Text/Text/Text';
import { useTranslate } from 'hooks/useTranslate';
import Footer from 'components/Footer/Footer';
import { NavLink, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'navigation/constants';
import { CheckSuccessIcon } from 'assets/icons';

export const SignUpSucessScreen: FC = () => {
  const t = useTranslate();

  const navigate = useNavigate();

  const handleStartEducation = useCallback(
    () => navigate(AppRoutes.HOME),
    [navigate]
  );

  const handleBackHome = useCallback(
    () => navigate(AppRoutes.HOME),
    [navigate]
  );

  return (
    <>
      <main className="auth-main">
        <div className="auth-container flex-column-center">
          <CheckSuccessIcon className="check-success-icon" />
          <Title
            text={t('sing_up_success_title')}
            highlight={t('sing_up_success_title_highlight')}
            center
          />
          <div className="auth-description">
            <Text text={t('sing_up_success_description')} />
          </div>
          <div className="auth-buttons-container">
            <NavLink className="button-start_education" to={AppRoutes.MAIN}>
              {t('sing_up_success_education_btn_title')}
            </NavLink>
            {/* <button 
              className='button-back_home'
              onClick={ handleBackHome }
            >
              { t('sing_up_success_home_btn_title') }
            </button> */}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
