import { FC, useCallback } from "react";
import styles from './ControlButton.module.css';
import { isFunction, noop } from "lodash";
import classNames from "classnames";


export const ControlButton: FC<{
  title: string;
  onPress?: () => void;
  light?: boolean;
}> = ({
  title,
  onPress = noop,
  light,
}) => {
  const handleClick = useCallback(
    () => {
      isFunction(onPress) && onPress();
    },
    [onPress]
  );

  return (
    <div
      className={ classNames(
        styles.container,
        light && styles.lightContainer,
      ) }
      onClick={ handleClick }
    >
      { title }
    </div>
  );
};
