import {
  separator,
  tb4c2_1,
  tb4c2_2,
  tb4c2_3,
  tb4c2_4,
  tb4c2_5,
} from 'assets/images';

export const b4_c15_text = {
  b4_c2_title: `Курс «Типичные ошибки и проблемы грудного вскармливания»`,
  b4_c2_description: `Спикер: Наталья Разахацкая, консультант по грудному вскармливанию  `,
  b4_c15_text: `
    ${tb4c2_1}

    Урок 1. Боль во время кормления

Часто приходится слышать, что грудное вскармливание – это не только интимный момент коммуникации мамы с ребенком, для многих женщин этот важный процесс сопряжен с сильными болевыми ощущениями. Практически каждая первая женщина во время беременности или от подруг (которые кормят грудью), или от своих мам и бабушек может услышать о том, что грудное вскармливание – это больно. Распространенное мнение: если хочешь кормить грудью, то придется терпеть дискомфорт и неприятные ощущения. Так ли это на самом деле? Разберемся в этом уроке.

Боль в сосках – это не норма, хотя и достаточно распространенное явление, особенно во время лактации в первые дни и недели после родов. 

Нужно ли начинать подготовку сосков до родов?

По разным исследованиям, подготовка сосков еще до родов с помощью натирания грубыми тканями либо смазывание специальными кремами для сосков достоверно не снижает риск возникновения болей и травм сосков после родов. Это связано с тем, что причиной болевых ощущений и травм сосков может быть неглубокий захват груди. 

Хорошо, когда малыш захватывает грудь так, что мамин сосок располагается во рту у ребенка в точке перехода между твердым и мягким небом, – там сосок не деформируется. После того как ребенок грудь отпускает, сосок имеет круглую форму. Когда малыш берет грудь неглубоко, сосок застревает между деснами, язык поднимается и не дает соску продвинуться глубже. И в таком случае основание соска деформируется, возникает сильная боль, трещины, а в некоторых случаях и инфекции молочной железы. Признак деформации – это скошенная форма соска, когда ребенок отпускает грудь. 

Чтобы профилактировать такие проблемы, рекомендуется попросить медицинский персонал приложить ребенка к груди в первый час после родов. Тогда, используя все свои врожденные рефлексы, есть шансы, что малыш захватит грудь глубоко и правильно и сохранит этот стиль в последующем.

Также лучше всего при возможности избегать использования других объектов для сосания, таких как пустышки и соски в первый месяц после родов. Такую рекомендацию дает Всемирная организация здравоохранения. Малыш имеет свойство сосать искусственный сосок отличным способом от того, как он сосет грудь. В это время он задействует другие мышцы во время сосания и, как правило, не открывает рот широко.

Что делать, если ребенку необходим докорм?

Если ребенку необходим докорм, его можно докормить не из сосательных предметов, например из чашки либо из ложки. Когда вы прикладываете ребенка к груди, рекомендую постараться не держать пальцы непосредственно очень близко около соска. То есть старайтесь вообще не держать грудь, а добиваться более глубокого захвата за счет более тесного прижатия малыша к своей груди. Когда вы подносите ребенка к груди, очень важно расположить его таким образом, чтобы он прижался к вам в трех точках: это подбородок, грудь и  живот.

Ручки малыша располагаются вокруг груди так, чтобы они также не мешали ему глубже взять нашу грудь. Сосок ориентируем не на ротик малыша, а на его носик, чтобы ребенок мог достаточно хорошо откинуть голову назад и сам потянуться к груди, широко открыв свой рот. Не рекомендуется держать во время прикладывания ребенка за голову. 

И главный совет – заберите грудь, если вам все-таки больно в процессе прикладывания. Вставьте свой мизинец с подстриженным ногтем ребенку в уголок ротика. Так вы разрушите вакуум и не травмируете свой сосок.

Как можно помочь соскам?

Смочить сосок последней капелькой сцеженного молока
Лать возможность соску просохнуть. 
Для лучшего эффекта не рекомендуется прятать грудь сразу в одежду и в бюстгальтер – дайте вашим соскам побыть на открытом воздухе.
! Если боль не проходит в течение нескольких дней, появились трещины – очень важно обратиться вовремя к врачу, чтобы начать их правильное лечение. 

В некоторых ситуациях предотвратить более серьезные травмы и залечить трещины помогут специальные накладки для сосков. 

Также часто женщинам советуют использовать различные кремы для сосков с такими компонентами, как ланолин или декспантенол. Однако пока исследования не доказали их достоверную эффективность в лечении трещин, но вы можете посоветоваться с врачом по этому поводу. Будет здорово, если вместе со специалистом вы подберете для себя то средство, которое вам подойдет. Главное, обращайте внимание на то, чтобы оно было разрешено на грудном вскармливании и не навредило вашему малышу. 

Если трещина достаточно глубокая, постарайтесь временно отлучить ребенка от груди на несколько суток и просто сцеживайте регулярно грудь (каждые 3 часа) в течение 15 минут. Этого будет вполне достаточно, чтобы не оставить малыша голодным и в то же время помочь зажить трещине. Также вы можете продолжать кормить малыша здоровой грудью. 

Важно сказать, что иногда поправить неглубокий захват достаточно проблематично, если не решить основную причину, которая приводит к этому самому неглубокому захвату. На эффективное прикладывание могут влиять следующие факторы: 

особенность челюстно-лицевого аппарата вашего малыша, например короткая подъязычная уздечка
особенность строения ваших сосков (например втянутые или плоские соски).
В этом случае рекомендую обратиться к специалисту по грудному вскармливанию или вашему лечащему врачу. Они помогут вам составить индивидуальный план, который поможет решить проблему в краткие сроки.

О каких еще моментах важно знать? Очень важно исключить молочницу, которая может возникнуть в ротовой полости новорожденного в виде белых вкраплений. Если заметили что-то подобное, лучше обратиться к педиатру, которая исключит молочницу или назначит лечение и вам, и малышу.

${separator}

Урок 2. Нагрубание и застой молока в молочных железах

Нагрубание, застой молока и дискомфорт в молочной железе при грудном вскармливании нередко считают нормой. Но на самом же деле эти проблемы требуют решений и внимания мамы. Важно как можно раньше выявить причины появления болевых ощущений и по возможности свести их к минимуму.

Чаще всего боль в молочной железе во время лактации связана с одной из трех причин:

Нагрубание груди во время прихода молока через несколько дней после родов.
Застой молока в определенных молочных протоках.
Инфекция молочной железы.
Нагрубание молочной железы

Оно происходит, когда приходит молоко. Обычно это случается через 2-3 дня, а у некоторых женщин чуть позже – на 4-е, 5-е и 6-е сутки после родов. Обычно это затрагивает обе груди. Они становятся более плотными, горячими на ощупь и болезненными. 

Также это ощущение возникает, когда малыш сосет грудь редко и неэффективно. Поэтому в первую очередь рекомендуется убедиться, что ребенок сосет грудь часто (примерно каждые 2 часа днем и каждые 3 часа ночью) и эффективно. Дальше можно сделать определенные шаги, чтобы облегчить отток молока из груди:

Нельзя прекращать пить воду. Пейте по жажде, но не слишком горячее питье. 
Перед кормлением или перед сцеживанием можно сделать мягкий массаж. Он выполняется круговыми движениями с помощью подушечек пальцев.
Можно сделать так называемую гимнастику груди, которую разработала Майя Больман. Взять грудь в обе ладони, и подвигать ее по грудной клетке вверх-вниз, вправо-влево и по кругу, по часовой или против часовой стрелки. Обычно это облегчает состояние и боль.
Можете использовать теплый компресс, который делается следующим образом: нужно взять чистое полотенце, смочить его теплой водой и приложить на пару минут к груди. Это усилит рефлекс окситоцина, соответственно, молоко будет выходить из груди лучше. После вы можете попробовать приложить ребенка к груди либо делать обычное сцеживание руками. 
Как сцедить грудь руками?

Если зона ареолы очень плотная на ощупь, то можно использовать специальную технику обратного давления Коттерман, которая помогает снизить напряжение на этом участке. Как это делается? 

Вы ставите подушечки пальцев на саму ареолу и мягко давите вовнутрь. То есть давите пальцами по направлению к грудной клетке. Давление должно быть мягким и безболезненным.
Нажали, подержали 10 секунд и отпустили и разгладили грудь пальцами по направлению к основанию груди. Так можно делать несколько раз, пока вы не почувствуете размягчение ареолы.
После этого вы можете поставить подушечки пальцев на границу ареолы так, чтобы большой палец, сосок и указательный палец образовывали одну линию. И далее мягко сводите большой указательный палец в месте под ареолой. Сжали-отпустили, сжали-отпустили. Это такое ритмичное сцеживающее движение, которое приведет к тому, что через некоторое время у вас сначала появятся капельки молока, а затем, когда гормон окситоцин заработает в полную силу, могут появиться даже струйки.
Не расстраивайтесь, если сразу вы не увидите струй молока. Это вполне нормально, так как груди нужно подготовиться, настроиться для того, чтобы выделять молоко более обильно. 

После того как вы покормили ребенка или сцедили грудь до состояния облегчения, у вас должно появиться чувство сокращения боли и смягчение груди. ! Обратите внимание, что до полного опорожнения грудь сцеживать не обязательно.

После этих шагов можно взять то же полотенце, смочить его уже холодной водой и опять приложить его на обе груди. При этом можно лечь на спину – это облегчит симптомы.

Застой молока в молочных протоках

В этом случае, в отличие от нагрубания молочной железы, боль и уплотнение будет только в одной из долей молочной железы. Как правило, это уплотнение прощупывается: либо ближе к подмышке (там находится самая большая молочная доля), либо можете нащупать уплотнение в нижнем сегменте молочной железы, в верхней части груди или ближе к середине груди.

Причины:

Редкие и неэффективные, неглубокие прикладывания ребенка к груди.
Редкие кормления, кормления по режиму, большой перерыв между кормлениями (более 3 часов).
Ребенок может быть слабеньким после родов, редко просить грудь. 
А также это наблюдается при докорме ребенка смесью, при использовании сосок, пустышек и при допаивании водой.
Что делать маме в таком случае? Вы также можете непосредственно перед сцеживанием или перед прикладыванием ребенка поместить теплый компресс, о котором мы говорили выше. И после чего лучше приложить ребенка в определенной позе, которая поможет опорожнить именно пораженную долю. 

Если у вас наблюдается застой молока в боковой подмышечной области, то приложите ребенка в так называемой позе из подмышки. 
Если уплотнение и боль чувствуются в нижнем сегменте или боковой доле ближе к середине груди, приложите ребенка в позе «колыбелька» или «перекрестная колыбелька».
Если уплотнена верхняя доля молочной железы, положите малыша в позе «валетом». Вам нужно лечь на бок и положить малыша таким образом, чтобы его ножки смотрели в сторону вашей головы.
Далее мы можем дополнительно делать массаж давления на эту область во время прикладывания. Очень важно не давить сильно, не усугублять образовавшийся отек давлением сверху. То есть все движения должны быть максимально мягкими и безболезненными. Это очень-очень важно. В том случае, если вы делаете все верно, застой уходит в течение одних-двух суток. 

! Если застой не ушел за два дня, необходимо срочно обратиться к врачу за дальнейшими рекомендациями. 

Инфекция молочной железы (мастит)

Каковы симптомы мастита? Покраснение определенного участка молочной железы, сильная боль, повышение температуры тела, высокая лихорадка до 40 °C. Что в этой ситуации 7 нужно делать?

Продолжать часто прикладывать ребенка к груди. Грудное молоко безопасно для ребенка, даже если у вас присутствует инфекция в молочной железе и высокая температура. Грудное молоко не инфицировано, инфицированы только ткани молочной железы вокруг молочных протоков. 
Рекомендуется делать мягкое поглаживание области воспаления во время кормления грудью, чередовать теплые и холодные компрессы.  То есть до прикладывания мы кладем теплый компресс, после – холодный, а также необходимо незамедлительно обратиться к врачу, чтобы вам назначили антибиотики, совместимые с грудным вскармливанием, а также жаропонижающее, которое безопасно принимать при кормлении грудью.
Если вы своевременно обратились за медицинской помощью, можно предупредить такое осложнение мастита, как абсцесс молочной железы (т.е. воспалительный процесс).

${tb4c2_2}

Урок 3. Малыш не берет грудь/сосет грудь неэффективно

Случается так, что малыш берет мамину грудь, чтобы через пару минут выпустить ее и расплакаться. Иногда ребенок даже активно сопротивляется попыткам накормить его маминым молоком: выгибается, отталкивает ручками грудь, даже если испытывает чувство голода. А мама знает, что в течение 3-4-5 часов он ничего не ел. Как объяснить такое парадоксальное поведение? И что делать в таком случае?

Ребенок сосет грудь неэффективно

Это явление, которое характерно для детей обычно только первого месяца жизни, пока они еще находятся в раннем послеродовом периоде. 

Как это выражается? 

Ребенок берет грудь, но при этом он делает пару сосательных движений (возможно, 1-2 глотка) и засыпает на груди. 
И дальше он сосет грудь очень вяло, неинтенсивно, с закрытыми глазками. Так он может провисеть на груди достаточно долго. 
Обычно мамы жалуются на то, что малыш сосет более получаса. И как только мама отвлекает ребенка от груди, он опять начинает возмущенно кричать, и мама вынуждена снова прикладывать его к груди. Хотя бывает и обратная ситуация – когда малыш сосет совсем немного, отпускает грудь, быстро засыпает и при этом может спать очень долго.

Такие дети, которые неэффективно сосут, не получают достаточное количество калорий, для того чтобы быть в активном состоянии. Поэтому они все время спят, чтобы экономить свою энергию. И со стороны такой ребенок может выглядеть просто идеальным – ребенок, который практически не плачет, который сосет не чаще, чем один раз в 3 часа (а иногда и реже), может спать всю ночь. Мама успевает все делать: приготовить еду, прибрать в квартире, и находится в такой иллюзии, что ей повезло с ребенком и что с малышом все хорошо.

Но, к сожалению, уже ближе к 2 неделям многим родителям становится очевидным, что с ребенком что-то не так. В этой ситуации самый достоверный показатель проблемы – это недобор веса. Обычно дети, которые сосут неэффективно, не набирают свой вес от рождения к 2 неделям либо делают это очень медленно. И, соответственно, к одному месяцу их набор веса не превышает 300-400 г от веса от рождения. Это очень мало для малыша, который должен активно набирать как минимум 600 г от веса от рождения, а в идеале – до одного килограмма. И в этом случае ему нужно помочь.

Новорожденный не берет грудь

Такая ситуация встречается в родильных домах не так уж и редко. И, конечно, она очень расстраивает кормящих мам, потому что большинство женщин настроены на то, что после родов малыш сам приложится к груди. Ведь это его естественная потребность, так как у него есть врожденный сосательный рефлекс.

Основные причины, по которым кроха может не брать грудь:

Использование в родах анестезии или некоторых лекарственных препаратов (т.е. ребенок может быть вялым и сонным после этого).
Сложные роды, когда ребенку нужно прийти в себя (когда применялись такие вмешательства, как индукция родовой деятельности, а также, если роды заканчивались кесаревым сечением, если было много стимуляций в родах).
Все это может влиять на дальнейшую сосательную активность малыша, и ребенок больше заинтересован в таком случае во сне, нежели в сосании груди. Иногда случается так, что кроха может проспать практически сутки после родов, никак не реагируя на попытки приложить его к груди.

Если так происходит в первые 6-12 часов после родов, то просто дайте малышу возможность отойти от родов. Если же и через 12 часов малыш так и не взял грудь, то рекомендуется сообщить об этом неонатологу и начать сцеживать молозиво из груди. Это лучше делать каждые 3 часа по 15 минут каждую грудь, пока малыш не начнет сам сосать грудь. 

 

Если малыша докармливать из бутылки сразу после родов (пока у него еще не сформировалась устойчивая техника сосания груди), то это может повысить риск дальнейших проблем с грудным вскармливанием. Поэтому очень важно, чтобы у малыша не было других объектов для сосания после родов, кроме груди. А докорм можно дать ему из несосательных предметов.

В группе риска находятся: 

Крупные дети, которые рождаются больше 3 кг 700 г, либо, наоборот, дети, которые рождаются с весом меньше 3 кг.
Дети у мам с гестационным диабетом или диабетом 1-го, 2-го типов могут больше страдать от гипогликемии, то есть низкого уровня сахара в крови. И это тоже приводит их к более сонному состоянию. 
Дети с неврологическими проблемами. А также, если у мамы двойня, необходимо повышенное внимание к тому ребенку, который рождается с меньшим весом. 
Дети с выраженной желтухой также могут быть подвержены более сонному состоянию.
Всем этим группам детей нужно повышенное внимание. Первое время нужно следить, чтобы такие малыши вовремя просыпались и кушали. 

Неудобная форма сосков

Это тоже может быть причиной отказа от груди. Они могут быть слишком большими, либо плоскими, либо втянутыми. И в этом случае ребенку может потребоваться некоторое время, чтобы научиться эффективно добывать молоко. 

Здесь самое главное, чем нужно вооружиться, так это терпением.

Грудь можно предлагать снова и снова из различных положений.
Можно попросить помощи у специалистов по грудному вскармливанию
Можно попробовать накладки
Ни в коем случае в такой ситуации не стоит морить ребенка голодом! Малыш должен получать какой-то объем питания каждые 3 часа. Объем питания зависит от возраста ребенка и его веса. Это лучше более подробно обсудить с вашим врачом.

Аномалии строения ротовой полости малыша

Затрудняют прикладывания расщелины нёба или губы, короткая уздечка языка. Обязательно сообщите врачу, если вы заметили, что малыш, очевидно, не может взять грудь достаточно глубоко и при попытках сосать у него раздаются цокающие звуки; либо он очень сильно травмирует грудь, либо сразу засыпает на ней. Короткую уздечку можно подрезать сразу же после выписки из роддома, обратившись в детское стоматологическое отделение. И буквально через несколько минут после подрезания уздечки ребенка уже можно прикладывать к вашей груди.

Если у ребенка врожденные расщелины нёба и верхней губы, тут дела обстоят чуть сложнее. При диагностировании расщелины нёба, скорее всего, малыш так и не сможет создавать вакуум и присасываться к груди, поэтому ему необходим будет докорм сцеженным молоком из специальных бутылок, которые созданы для таких детей.
Если у ребенка только расщелина губы, то в этом случае рекомендуется попробовать подобрать положение, в котором мама сможет создавать вакуум с помощью своей груди, закрывая эту расщелину, либо дополнительно с помощью своих пальцев.
В таких ситуациях важно сохранять как можно более тесный физический контакт с малышом. Контакт «кожа к коже», который побуждает ребенка тянуться к груди и пытаться ее захватить.

Конечно, мы не забываем и о правилах эффективного прикладывания, потому что многим детям просто не хватает опоры на мамино тело, чтобы взять грудь. Поэтому очень важно в этой ситуации обратиться к уроку про эффективное прикладывание и еще раз посмотреть, что необходимо и что важно делать, чтобы помочь ребенку эффективнее захватить грудь. 

Ребенок беспокойный у груди

Он может часто ее отпускать из-за сверхсильного потока молока (так называемого сильного рефлекса выделения молока). Это происходит в тех случаях, когда молока изначально очень много. 

Конечно, женщина в этом не виновата, это лишь особенность ее организма. И с одной стороны, очень классно, что молока много. Но бывает так, что это может влиять на комфорт прикладывания.

Что в такой ситуации можно предпринять?

Постараться кормить малыша небольшими порциями, но часто. Чтобы в груди шло постоянное движение молока. Кроме того, проголодавшийся кроха всегда набрасывается на грудь и начинает сосать ее активно, тем самым и провоцируя сильный выброс молока. То есть если малыш будет делать это чаще (но он не будет таким голодным), то, соответственно, он будет сосать более спокойно.
Можно также использовать определенные позы для кормления, которые могут уменьшить поток молока за счет сил гравитации. Это поза «лежа на боку» и позиция «расслабленного кормления», когда мама откинута назад, а ребенок лежит сверху. 
В тяжелых ситуациях иногда приходится использовать накладки, которые могут сдерживать поток. Только рекомендуется выбирать накладки для сосков с маленькими отверстиями, а также сцеживать какой-то объем молока перед кормлением, чтобы грудь стала мягкой и не такой наполненной.
Если малыш все-таки захлебнулся, рекомендуется сохранять спокойствие. Поднимите его столбиком, мягко похлопайте по спинке, помогая продышаться, и главное – не волнуйтесь сами. Ребенок часто кричит не от того, что его жизни что-то угрожает, а просто от возмущения или испугавшись вашей реакции. 

Еще одна из частых причин беспокойного поведения – это стресс, который может возникать у ребенка по разным причинам. Профилактировать его заранее практически невозможно, ведь в жизни каждой семьи есть такие события, которые могут слишком разволновать малыша. Например, переезд, поездка в гости, походы в поликлинику, болезненные медицинские процедуры, госпитализация, если маме нужно отъехать от малыша надолго и многие другие обстоятельства. В этом случае ребенок может тревожиться. И когда мама возвращается, он может быть настолько взволнован, что не находит в себе силы сразу приложиться к груди.

Как восстановить контакт с малышом после волнующего события? Просто будьте с ним рядом, часто носите на руках. Можно попробовать совместное купание с малышом, совместный сон, контакт «кожа к коже». Именно это поможет ребенку как можно скорее вернуть спокойствие и доверие к маминой груди.

Также важно сказать о таком периоде в жизни ребенка, как возраст 3-4 месяца, когда многие дети начинают сильно крутиться, отвлекаться, бросать грудь от малейшего шороха, присасываться буквально на несколько минут и тут же начинать вертеть головой по сторонам. 

Лучше всего мамам сохранять спокойствие и понимать, что ребенок проходит такую стадию своего развития, когда ему все интересно, и он легко может отвлечься. Хорошо, если в таком возрасте ребенок еще не засыпает с пустышкой, а укладывается спать с грудью. Тогда будет легче накормить именно в полусонном состоянии, предварительно затемнив комнату. Можно использовать ваши привычные ритуалы, которые успокаивают малыша. Например, ношение на руках в более тесной позе, покачивание, любой белый шум, который может быть: шум вытяжки, фена, шум дождя или моря, который можно включить на любом цифровом носителе. 

Почему это срабатывает? Потому что это возвращает малыша во внутриутробный комфорт, когда он тоже был в стесненном, затемненном месте, и при этом слышал постоянно шум крови в сосудах. Все эти действия обычно помогают ребенку быстрее успокоиться и начать сосать грудь.

Запомните, ни в коем случае нельзя морить малыша голодом или прикладывать ребенка к груди насильно, фиксируя его голову в руке и прижимая к груди сильно. Это может спровоцировать еще больший отказ и еще более беспокойное поведение. Вначале всегда малыша рекомендуется успокоить, и только после этого предложить грудь. Если малыш совсем не хочет сосать, сцедите молоко и докормите его небольшим количеством сцеженного молока из ложки или из чашки, а затем вновь попытайтесь приложить его к груди. Это самая лучшая стратегия.

Продолжительность сна ребенка

Необходимо следить за продолжительностью сна ребенка первые 4-6 недель после родов. Если малыш спит более 2-3 часов от конца предыдущего кормления, его лучше мягко разбудить.

Как можно это сделать? 

Берем малыша, начинаем его раздевать, выкладываем его к себе в контакте «кожа к коже»
Начинаем активно его выглаживать, уделяя внимание ступням, ручкам, ладошкам и голове. Обычно этого достаточно, чтобы ребенок начал активно искать грудь. 
Если это не помогает, можно снять подгузник и подмыть его. 
Если никоим  образом разбудить ребенка в течение получаса не получается (он до этого не сосал грудь и не получал никакого другого питания в течение 3-4 часов), то обратитесь, пожалуйста, к неонатологу или педиатру, чтобы исключить какие-то серьезные патологии.   
Есть такое распространенное мнение, что ребенку важно спать столько, сколько он хочет. На деле длительный сон больше касается деток первого месяца жизни. Исходя из рекомендаций Всемирной организации здравоохранения, несмотря на то сколько готов спать малыш, вы должны покормить его не менее 8 раз в сутки. Более того, должен наблюдаться хороший набор веса не менее 200 г в неделю. 

К сожалению, бывают ситуации, когда ребенок спит не потому, что он хочет спать, а потому что есть факторы, которые влияют на его продолжительность сна. И он не может сам себя разбудить, пока это не сделает его родитель.

Что еще из перечисленных причин может угнетать ребенка и почему ему тяжело просыпаться?

Жесткое пеленание, когда ребенок не может двигать руками, ногами.
Нахождение на докорме смесью – дети могут продолжительно спать, так как смесь переваривается дольше, чем грудное молоко.
Длительные прогулки на улице, продолжительностью более 2 часов.
Эти причины и те, о которых мы упомянули в самом начале урока, могут влиять на центральную нервную систему малыша. Именно поэтому ему может быть сложно подать сигнал о том, что он проголодался и хочет есть.

Если все же вы видите, дорогие мамы, что малыш сосет неэффективно (т.е. несмотря на то, что вы его часто будите и часто прикладываете к груди, он не набирает вес), в этом случае необходимо начать как можно быстрее сцеживать грудь и докармливать ребенка сцеженным молоком, пока он не начнет хорошо набирать вес.

После того как ребенок начнет получать достаточное количество калорий, он уже через несколько дней сам изменит свое поведение, и вы увидите, что малыш начал чаще просыпаться, вести себя активно около груди. Дополнительно для таких деток можно использовать технику сжатия груди во время кормления и частую смену груди.

То есть, если ребенок уже через 15 минут засыпает на груди и вы видите, что сжатие груди не помогает ему проснуться, рекомендуется переключить его на другую грудь, и таким образом дать ему высосать больше молока. Объем докорма и способ докорма вам может помочь определить педиатр, дружественный грудному вскармливанию либо консультант по грудному вскармливанию.

${separator}

Урок 4. Нехватка грудного молока

В этом уроке поговорим с вами на одну их самых частых тревог любой кормящей мамы: хватает ли моему малышу грудного молока. Эта тревога связана с тем, что, как правило, мама не может увидеть, сколько молока малыш высосал из груди, в отличие от кормления из бутылки. К счастью, есть способы, как это измерить и понять.

Всемирная организация здравоохранения выделяет два надежных признака, что ребенок получает недостаточно молока:

плохой набор веса (менее 500 г в месяц);
малое количество концентрированной мочи (менее 6 раз в сутки).
Также есть и ряд других вероятных признаков, по которым мама может оценить, что ребенок не получает достаточное количество молока. Например:

ребенок не удовлетворен после кормления грудью; 
малыш часто плачет либо наоборот много спит, не активен; 
частое кормление грудью, слишком долгое кормление; 
стул малыша редкий, скудный, сухой или зеленый.
Как понять, что молока хватает?

Показатель №1. Малыш часто опорожняется

Если мы посмотрим на стул маленького ребенка, то мы видим, что его цвет меняется.

${tb4c2_3}

Например, при рождении выделяется так называемый кал меконий (он черного цвета). Молозиво помогает ребенку быстрее опорожниться, так как первое молоко обладает слабительным эффектом. Далее к 3-4 дню стул постепенно переходит в более зеленый или коричневый цвет. И уже к 5-6 суткам он в норме становится желтым. Тревожный знак, если стул остается черным на 4-е сутки. Возможно, ребенку не хватает грудного молока.

Показатель №2. Частота мочеиспусканий

Чем чаще ребенок мочит подгузник, тем мы более уверены в том, что он получает достаточное количество грудного молока.

Диурез новорожденного соответствует обычно количеству дней жизни. То есть в первые сутки ребенок обычно мочит подгузник 1 раз, во 2-й день – 2 раза, и так до исполнения им одной недели. Уже после одной недели дети в норме ходят в туалет по-маленькому от 6 до 8 раз в сутки, некоторые – чаще.

${tb4c2_4}

Показатель №3. Глотки ребенка во время сосания

Если ребенок глотает хорошо, вы можете заметить паузу перед глотанием, а затем то, как он хорошо опускает свою нижнюю челюсть. То есть когда ребенок начинает сосать грудь, он делает много частых сосательных движений без глотков. Таким образом, он проходит стадию симуляции груди. Далее он вызывает прилив молока, и тогда его стиль сосания меняется. Он переходит в стадию экспрессии, когда малыш делает одно сосание – одно глотание. Это похоже на то, как мы пьем воду из бутылки. Вы можете проследить это со стороны. Вы делаете глоток, далее задерживаете жидкость в ротовой полости, опускаете вниз нижнюю челюсть, чтобы протолкнуть воду дальше в пищевод. Точно так же происходит и в глотании во время сосания груди у детей.

Если ребенок не показывает глотание, вы не видите и не слышите глотков в течение 5 минут после начала кормления или ребенок засыпает через несколько минут после начала кормления, это говорит о том, что он сосет неэффективно. Он не получает достаточного количества молока. 

Показатель №4. Уровень активности малыша

Как правило, дети, которые получают достаточное количество молока, ведут себя достаточно активно и сосут не менее 10 раз в сутки. Если ребенок спит более 2-3 часов, просит грудь достаточно редко и сосет ее недолго (каждый раз менее 10 минут) либо сразу засыпает на груди, и может таким образом висеть на ней очень долго, то это тревожный знак. 

Еще один повод для беспокойства – это когда ребенок вроде как сосет, вроде периодически глотает, но эти зависания на груди происходят продолжительное время (например, ребенок может спать на груди в течение 30-40 и даже 60 минут). Но как только мама пытается забрать у него грудь, он просыпается и начинает сильно плакать. Мама дает ему опять грудь, и он сразу же на ней засыпает – это такой критерий недостатка молока.

Показатель №5. Объективный набор веса

Что значит объективный набор веса? Это значит, что мы не взвешиваем ребенка до и после кормления, а смотрим его прибавку в весе в динамике. Если ребенок набирает свой вес от рождения к 10-14 дню и далее прибавляет не менее 200 г в неделю, то значит его объем питания вполне достаточный. В этой ситуации можно не обращать внимания на то, как долго и как часто ребенок сосет грудь, если только мама не испытывает болезненных ощущений.

Дорогие мамы, помните, что каждый ребенок индивидуален. Есть достаточно быстрососущие дети, которые насыщаются за 5-10 минут, а есть более вялососущие малыши, которым требуется больше времени, чтобы высосать тот же объем молока. Но это вовсе не значит, что ребенок ленится сосать. Ведь для детей это очень важный, необходимый фактор их выживания. Поэтому они ни в коем случае не ленятся – они либо могут эффективно сосать, либо нет. И только мама может им в этом вопросе помочь.

Конечно, очень важно уделить внимание правильности прикладывания, но также можно помогать ребенку следующим образом. Это называется техника сжатия груди. Что из себя представляет техника сжатия груди? Когда ребенок сосет сам активно и глотает, помогать ему не нужно. Но как только он начинает засыпать на груди, обхватите грудь ладонью достаточно далеко от ареолы и равномерно сожмите ее таким образом, провоцируя дальнейшее сосание и глотание у малыша. Сжатие должно быть достаточно безболезненным, но уверенным.

Дальше очень важно понимать, что, когда ребенок опорожняет одну грудь (но прошло уже больше получаса), его можно переключить на другую грудь, потому что, возможно, одной груди ему уже недостаточно. Это абсолютно нормальный стиль питания. Бывает, что дети насыщаются с одной груди, а бывает, что им требуется еще вторая грудь.

Очень важная профилактика недостатка молока – это исключение допаивания ребенка водой до 6 месяцев, а также подслащенной водой, любыми детскими чаями от колик и допаивания детской смесью без показаний. 

Когда нужно вводить докорм?

Единственный критерий, когда необходимо вводить докорм, – это когда у малыша нет хорошей динамики набора веса. В этом случае можно исправить это дело с помощью дополнительного сцеживания груди.

То есть после того, как ребенок пососал и одну, и вторую грудь, рекомендуется отвлечь его от груди и дополнительно сцедить обе молочные железы по 10-15 минут, а затем докормить малыша этим сцеженным молоком. Это поможет ребенку быстрее набрать вес, а значит, стать более сильным и более эффективно сосать вашу грудь в последующем.

Если же сцеженного молока не хватает, лучше использовать либо донорское молоко, либо детское адаптированное питание. Но в первую очередь важно понимать, что для стимуляции лактации нужно частое и эффективное опорожнение груди. Недаром говорят, что основной закон лактации – это закон спроса и предложения (т.е. сколько из груди взяли, столько и пришло в ответ молока). Поэтому, если вы будете копить молоко в груди и пропускать кормления, сцеживания, докармливания ребенка смесью, это лишь будет провоцировать дальнейшее угнетение лактации.

Также важно понимать, что распространенные в продаже средства повышения лактации (лактогоны в виде чаев, таблеток и каких-то биологически активных веществ) не доказали свою эффективность, то есть на них лучше не рассчитывать. Самый верный способ повысить выработку молока – это частое эффективное опорожнение груди либо за счет ребенка, либо за счет дополнительного сцеживания.

Лактационный криз

Это ситуации, когда у мамы резко начинает вырабатываться меньше молока. Этот момент очень пугает многих мам, и даже есть женщины, которые с ужасом ждут этого момента, пытаются запастись молоком впрок. Не бойтесь лактационных кризов, дорогие мамы. Если ребенок хорошо сосет грудь, то никакие лактационные кризы вам не страшны. И даже если так случилось, что в какой-то день вы проснулись и чувствуете, что грудь стала менее наполненной и пустой, ребенок больше капризничает под грудью, просто продолжайте его часто прикладывать к груди. И молоко обязательно вернется в течение суток-двое. 

Поддерживайте себя теплым питьем, вкусной едой и вниманием со стороны родственников, которые всегда готовы вас успокоить и сказать, что вы самая лучшая мама для своего ребенка, а молоко обязательно вернется.

${separator}

Урок 5. Временное разделение или отлучение от груди

В этом уроке поговорим о таких ситуациях, когда кормящая мама и грудничок оказываются не вместе, и обсудим другие причины, когда мама вынуждена временно прекратить грудное вскармливание.

Многие думают, что в этих случаях нужно забыть о грудном вскармливании и перевести ребенка на смесь. Естественно, это всегда стресс, причем не только для мамы, но и для малыша. Но не переживайте, практически в любом случае лактацию можно сохранить. И даже после разлучения реально вновь вернуть малыша на грудь.

Как правильно организовать период временного отлучения от груди?

Ситуация 1. Вы недавно родили и еще находитесь с вашим ребенком в роддоме, однако малыша забрали по показаниям в отделение интенсивной терапии. Или, наоборот, именно вы нуждаетесь в пристальном наблюдении врачей, а малыш пока ждет вас на детском посту. 

В этом случае рекомендуется начать сцеживать обе груди каждые 3 часа по 15 минут либо руками, либо хорошим электрическим молокоотсосом. И попросить медицинский медперсонал относить ваше грудное молоко вашему же ребенку. Если это не получается, советую просто оставлять молоко в специальных пакетах для сцеженного молока, маркировать датой и хранить его в холодильнике или передавать родственникам в специальной термосумке для хранения в морозилке. И как только малыша вам отдадут, вы сможете опять начать прикладывать его к груди и отказаться от сцеживания.

Ситуация 2. Вам назначили серьезные препараты, которые не совместимы с грудным вскармливанием. Либо вы попали в больницу и нуждаетесь в срочной операции. Напомню, что большинство препаратов, которые обычно назначают кормящим мамам, включая распространенные антибиотики, совместимы с грудным вскармливанием. Есть специализированные ресурсы, где можно проверить совместимость препарата с грудным вскармливанием. 

сайт испанского госпиталя – e-lactancia.org
русскоязычная версия e-lactation.com
Точно так же, как и в предыдущем описанном случае, важно поддерживать лактацию с помощью регулярного сцеживания каждые 3 часа по 15 минут каждую грудь. Это можно делать руками, но оптимально купить или взять напрокат электрический молокоотсос (желательно двойной). Ребенка в этом случае, скорее всего, будут кормить искусственной смесью, если ему еще нет 12 месяцев. Либо вы можете заранее сделать стратегический запас грудного молока в морозилке, если операция запланирована заранее. 

В сутки ребенку до 6 месяцев необходимо примерно 700-800 мл грудного молока. Если вы будете сцеживать грудь 1-2 раза в сутки между кормлениями, то за неделю вы сможете накопить порядка 2 л грудного молока, и таким образом поддержать иммунитет малыша во время разлуки. В случае если малыш откажется брать бутылку, родственники могут попробовать поить его из маленькой чашки или из ложки.

Ситуация 3. Ваш малыш уже подрос. Возможно, уже знакомится с прикормом, а у вас возникла срочная необходимость уехать от него на несколько дней. Часто кормящая мама слышит совет вообще отказаться от поездки либо заранее отлучить ребенка от груди и начать кормить смесью. Но, дорогие мамы, в этом нет никакой необходимости. Как и в предыдущей ситуации, попробуйте заранее создать некоторый запас грудного молока и поддерживать лактацию во время разлуки с малышом с помощью регулярных сцеживаний.

Ситуация 4. Мама выходит на работу.

Современный мир ставит новые задачи перед родителями, а женщины не всегда хотят оставлять свою карьеру, работу или учебу после рождения ребенка. Сейчас есть много возможностей совмещать материнство и работу или учебу. Белорусское законодательство дает женщине право на отпуск по уходу за ребенком длительностью до 3 лет. Это большая редкость, если сравнивать положение мам после рождения детей в других странах. И там женщины  вынуждены часто выходить на работу уже через 6 недель после родов. Однако не все белорусские женщины хотят или могут находиться в декрете так долго.

Как быть в таком случае с грудным вскармливанием? Опять же существует миф, что грудное вскармливание и работа/учеба несовместимы или трудно совместимы с грудным вскармливанием. На самом деле это не так. 

В каком возрасте оптимально начать думать о выходе на работу? В идеале – после 6 месяцев, когда малыш готов дольше обходиться без мамы, играть с другими людьми и есть другую пищу. Но все реально организовать и до 6 месяцев.

План подготовки с выходом на работу / учебу

Готовиться надо заранее, но без фанатизма. Вполне достаточно купить хороший электрический молокоотсос, и за 3-4 недели до выхода на работу начать создавать так называемый “банк молока”. Это приносит гораздо меньше проблем, чем многие думают. Надо всего лишь сцеживать молоко 1-2 раза в день (лучше утром, когда молока больше) и замораживать в специальных пакетах для сцеживания молока, обязательно проставляя дату.

Рекомендую постепенно начинать уходить из дома на 1-2 часа, оставив малыша с тем человеком, который будет с ним и в дальнейшем во время вашего отсутствия. Если это приходящая няня, то к этому моменту ребенок должен быть с ней знаком. Постепенно увеличивайте время своей отлучки, доводите его до реального времени отсутствия на работе или учебе. 

Работающей маме не лишним будет знать, что, по трудовому кодексу Республики Беларусь, вы можете работать неполный рабочий день или на дому. Женщине, у которой есть дети в возрасте до полутора лет, по трудовому кодексу, также предоставляются дополнительные перерывы каждые 3 часа для кормления или сцеживания. Используя эти перерывы, вы можете сцеживать грудь прямо на рабочем месте, или  вам могут подвозить малыша на кормление. Сцеживая молоко на работе, лучше не выливать его, а сохранять в специальной термосумке. Затем его можно использовать для кормления малыша в ваше отсутствие. Также вы можете воспользоваться услугами курьера, который может завести ваше бесценное молоко к вам домой.

Важный совет: когда вы возвращаетесь домой после работы или учебы, то просто начинайте кормить ребенка грудью по требованию. Не пытайтесь сцеживать молоко или кормить ребенка по тому режиму, который был во время вашего отсутствия. Это часто приводит к повышенной нервозности малыша и к снижению выработки молока. Запас молока вы всегда сможете восполнять за счет сцеживания на рабочем месте. Усилия, затраченные на сохранение грудного вскармливания, окупятся сторицей. По исследованиям, кормящая мама уходит на больничный на 27% реже, чем некормящая женщина. Кстати, об этом вы можете рассказать своему работодателю.

Как видите, в любой ситуации можно найти решение и сохранить грудное вскармливание. Усилия, затраченные на сцеживания и кормление ребенка сцеженным молоком, снижают риски того, что малыш может заболеть при стрессе от разлуки с вами, и могут предупредить воспалительные процессы в груди, а также поддержать вас психологически. Так как, даже не находясь вместе с малышом, вы будете знать, что вы делаете для него самое необходимое и даете ему то, что не может дать никто другой.

${tb4c2_5}

Урок 6. Когда нужен докорм и способы докорма

Прежде всего напомним о том, что, по рекомендациям Всемирной организации здравоохранения, до шести месяцев в норме ребенок получает только грудное молоко. Рекомендуется исключить допаивание водой и другими жидкостями до этого возраста, кроме витаминов и лекарственных препаратов. 

Показания для докорма

В документе Всемирной организации здравоохранения показаниями для докорма обозначены следующие ситуации: 

ребенок потерял более 10% от своего веса от рождения в роддоме;
ребенок плохо набирает вес после выписки; 
малыш разделен с мамой; 
мама принимает препараты, несовместимые с грудным вскармливанием;
мама является ВИЧ-положительной, имеет острую форму туберкулеза или ветряной оспы в первый месяц после рождения ребенка, а также некоторые другие серьезные состояния.
В приоритете по докорму – безусловно, сцеженное молоко. Если такая опция недоступна, то следующий источник докорма по значимости – донорское грудное молоко, и в конце списка – адаптированная молочная смесь.

Докорм и прикорм: в чем разница? 

Разница в том, что прикорм дается ребенку не как вынужденная мера, а для дополнения грудного молока в связи с достижением ребенка определенного возраста. В то время как докорм вводится с целью компенсации грудного молока в случае его нехватки. Отмечу, что назначает его не мама, а именно врач.  

Из чего докармливать малыша?

Как правило, когда маме говорят о том, что ее ребенку необходим докорм, первое, о чем она думает, что его нужно давать с помощью бутылки или соски. На самом деле существует много других альтернативных способов кормления малыша. Они представляют собой меньший риск для грудного вскармливания, чем бутылочное вскармливание. Давайте рассмотрим основные из них.

Кормление из ложки
Это простой способ, требующий минимальное количество аксессуаров, и максимально легкий в обработке. После него дети не отказываются от груди, поэтому он активно используется в больницах, доброжелательных к ребенку. Минус этого способа – большие затраты времени, если объем кормления более 30 мл. Второй минус – ребенок может проливать молоко.

Как осуществить такое кормление? Рекомендуется взять малыша на руки в полувертикальное положение под углом 45 градусов, набираем в ложку молока и кладем ее на кончик языка. Малыш, почувствовав молоко, начнет сглатывать. Если все же ребенок начинает выталкивать молоко назад, то можно залить молоко аккуратно ему за щечку, аккуратно повернув ложку боком. Помните, что кормление должно занимать не более 30 минут, иначе ребенок начнет уставать и заснет раньше, чем наестся.

Кормление из чашки
Этот метод активно поддерживается Всемирной организацией здравоохранения. Во многих родильных домах, которые поддерживают инициативу больницы, доброжелательной к ребенку, используют докорм из маленького стаканчика или специального поильника из медицинского полипропилена. Вы можете взять в качестве такого аксессуара защитный колпачок для соски от бутылки.

В чем преимущество кормления из чашки? Такое кормление снижает количество срыгиваний, колик, а также расход энергии ребенка. Стимулирует правильные движения языка и нижней челюсти. Но у этого способа есть свои противопоказания: нежелательно кормить таким образом новорождённых со склонностью к захлебыванию. Часто у таких малышей наблюдается общая вялость и слабый глотательный рефлекс.

Итак, как осуществить кормление из чашки? Опять-таки мы заполняем чашку наполовину необходимым количеством докорма, берем малыша и подносим край чашки к нижней губе ребенка. Наклоните чашку так, чтобы молоко коснулось губ малыша. Ребенок начинает тянуть или лакать молоко. Ни в коем случае не заливайте молоко в рот ребенка. Если малыш очень активно пытается помочь своими ручками вам, вы можете запеленать его, и таким образом кормление пройдет более спокойным.

Система SNS, или система докорма около груди
Это специальная емкость, куда наливается докорм (вот, смотрите, как она выглядит), и оттуда выходят две мягкие тонкие трубочки. Уникальность этого метода в том, что ребенок может получать питание непосредственно во время сосания груди, и таким образом поддерживать интерес к сосанию груди, даже если там почти нет молока. Это более удобный и физиологический способ докорма, поскольку ребенок сосет грудь, а стимуляция груди на выработку молока идет даже тогда, когда молока там уже нет.

Минус этой системы в том, что она не подходит детям со стойким отказом от груди, а также она достаточно дорогостоящая. Хотя ее можно сделать самому, используя обычный зонд питающий 4-го или 5-го размера и применяя его в комплексе с обычной бутылкой. Отмечу, что этот метод не совсем удобен, так как есть сложность в обработке трубочек. Они достаточно тонкие, как вы видите, их нельзя кипятить и разрешается лишь промывать горячей водой.

Итак, как реализовать такой докорм? Рекомендуется налить докорм в емкость, повесить ее на грудь с помощью специального регулирующего шнурка, подвести одну из трубочек к соску и закрепить его пластырем в районе ареолы. Вторая трубочка может просто свисать или быть закреплена к другой груди. Затем вы можете взять малыша и приложить его в обычной удобной позе. Такой способ докорма является очень эффективным и помог уже большому количеству мам и деток, когда имелись проблемы с продолжительной  нехваткой грудного молока. 

Как видите, существует много способов того, как можно накормить ребенка, не используя бутылку. Рекомендую выбирать тот, который больше подходит именно вам и вашему малышу. И не бойтесь пробовать – все эти способы безопасны, одобрены Всемирной организацией здравоохранения и  доказали свою эффективность на практике.

${separator}

Урок 7. Болезни матери и ребенка во время кормления грудью

Дорогие мамы и папы, в жизни бывает всякое. И, увы, иногда случается так, что кормящая мама заболевает. Часто при этом встает вопрос, как быть с грудным вскармливанием.

Давайте вначале рассмотрим те случаи, при которых лучше точно не кормить грудью. Всемирная организация здравоохранения выделила в список запрета на грудное вскармливание лишь пару заболеваний.

Открытая форма туберкулеза. В этом случае мама должна быть временно разделена с малышом. Но, обратите внимание, она может передавать ребенку свое сцеженное молоко.
Герпесные высыпания в области ареолы, которые могут быть опасны для малыша при прямом контакте.
Ветряная оспа в раннем неонатальном периоде или в первый месяц после рождения ребенка, так как это тоже может быть очень опасно для здоровья малыша.
Различные формы психоза, сепсис, онкология и другие заболевания, которые сопряжены с приемом тяжелых препаратов с радио- и химиоактивными веществами. В этом случае мы также говорим о временном или же постоянном прекращении грудного вскармливания.
Прием психоактивных веществ (наркотики, алкоголь или табак)

В этом случае Всемирная организация здравоохранения предупреждает, что их прием повышает риск попадания веществ в грудное молоко, что может негативно, к сожалению, влиять на здоровье и развитие маленького ребенка. А также повышает риск снижения выработки молока. Рекомендуется полностью отказаться от приема алкоголя и наркотиков во время беременности и грудного вскармливания. 

Все же, если произошел эпизод употребления алкоголя или никотина, важно соблюдать некоторые правила – не курить при ребенке, не кормить грудью в течение 2-3 часов после употребления, чтобы вредные вещества успели покинуть грудное молоко.

ГВ при ВИЧ-положительном статусе матери 

Всемирная организация здравоохранения не дает однозначный ответ на вопрос, можно ли сохранять ГВ. Общая рекомендация такая – если у родителей есть постоянный доступ к альтернативному источнику питания для ребенка (это либо донорское грудное молоко, либо детская адаптированная смесь), то грудное вскармливание лучше прекратить. Ведь риск передачи вируса, по исследованиям, через грудное молоко составляет 10-15%. Если же нет постоянного доступа или он затруднен к альтернативному питанию, то лучше сохранить исключительно грудное вскармливание до шести месяцев. 

Что это значит? Это означает, что до шести месяцев мама кормит только грудью, исключая допаивания водой, смесью или раннее введение прикорма. Также необходим постоянный прием матерью и, возможно, профилактически ребенком антиретровирусных препаратов, которые будут максимально снижать титры вируса в материнской крови.

Остальные инфекционные заболевания, включая ОРВИ, а также гепатиты, корь, краснуху и даже мастит, не требуют прекращения грудного вскармливания. Помните, лишая ребенка молока, вы лишаете его защиты. Ведь с грудным молоком поступают антитела, которые выделяются в материнском организме при знакомстве с вирусами.

ГВ и COVID-19

Всемирная организация здравоохранения и другие авторитетные организации рекомендуют сохранять грудное вскармливание. Случаи передачи вируса через грудное молоко не описаны. Но, конечно, маме, которая заболела, важно соблюдать меры предосторожности при контакте с малышом. Важно носить маску, а также мыть руки непосредственно перед кормлением.

Большинство антибиотиков, а также медицинских исследований, которые применяют при диагностике и лечении COVID-19, совместимы с грудным вскармливанием. В первую очередь это рентген и компьютерная томография. Доказано, что рентгеновские лучи, а также ультразвуковые волны на грудное молоко не влияют, поэтому кормить можно сразу после обследования и сцеживать молоко не нужно. Исключение – это введение радиоконтрастных веществ во время исследования. В этом случае, возможно, требуется временное прекращение грудного вскармливания для выведения этих радиоактивных веществ из организма.

Стоит упомянуть и о местной анестезии. Лечение зубов и любые другие обследования, которые требуют местной анестезии, совместимы с грудным вскармливанием.

ГВ и диабет

Кормление грудью важно и нужно при заболеваниях различными формами диабета, так как грудное вскармливание помогает маме лучше себя чувствовать. Доказано, что сохранение грудного вскармливания снижает риск диабета II-го типа, особенно для женщин с гестационной формой диабета (т.е. беременной формой диабета). Также в большинстве случаев, если маме показана ежедневная инсулиновая терапия, то доза инсулина может быть снижена до 30%. В некоторых случаях наблюдается полная ремиссия заболевания во время грудного вскармливания.

Однако есть и свои особенности. Может быть задержка прихода молока на несколько дней, важно следить за уровнем сахара (своим и малыша), так как в этом случае возрастает риск гипогликемии, или снижения концентрации глюкозы в крови. Также рекомендуется контролировать вес ребенка. И еще один важный момент: мамы с диабетом могут быть более подвержены риску возникновения трещин, молочницы сосков и мастита в молочной железе. Именно поэтому так важно уделять внимание профилактике данных осложнений, следить за эффективностью прикладываний и за чистотой опорожнения груди.

В последнее время в научном сообществе обсуждают эффективность антенатальных сцеживаний грудного молока, а точнее молозива для мам с диабетом. Антенатальных – значит до рождения ребенка, на последних неделях беременности. Действительно, уже получено большое количество положительных результатов такой процедуры, которая быстрее запускает лактацию у женщин с диабетом и дает возможность ребенку снизить объем получаемой смеси сразу после родов.

ГВ и бронхиальная астма 

Доказан положительный эффект грудного вскармливания на снижение риска развития этого заболевания в будущем у ребенка. Также мама с бронхиальной астмой может продолжать кормить грудью и принимать препараты, которые необходимы для уменьшения приступов бронхиальной астмы.

ГВ и порок сердца

В этом случае также доказан положительный эффект грудного вскармливания на протекание этого заболевания. Мамы могут принимать необходимые препараты и кормить грудью. Доказано, что ребенок с пороком сердца затрачивает меньше энергии на сосание груди, его кровь лучше насыщается кислородом, чем если бы он сосал бутылку. Естественно, что такие дети могут быстрее уставать, меньше высасывать молока, поэтому так важно следить за прибавками в весе у такого малыша и при необходимости докармливать его сцеженным молоком.

ГВ и инвалидность

Мамы с различными нарушениями опорно-двигательного аппарата, такими как ДЦП, ревматоидный артрит, рассеянный склероз, эпилепсия, и женщины с некоторыми другими сенсорными и ментальными особенностями в большинстве случаев могут кормить грудью. Но для таких мам важно получать всестороннюю поддержку со стороны врачей, родственников и общества, создавая инклюзивное пространство, где есть возможность реализовывать свое право на кормление грудью.

Дорогие мамы, можно долго говорить о полезности грудного молока. Но мне хотелось бы, чтобы вы знали: грудное вскармливание способствует не только защите вашего малыша от заболеваний и укреплению его иммунитета, но и влияет на установление тесной психоэмоциональной связи между вами. Так рождается доверие. Только с вами ребенок может чувствовать себя поистине спокойно, комфортно и в безопасности. И мы надеемся, что у вас все получится, даже несмотря на возникшие временные сложности.

${separator}

Урок 8.  ГВ и психологические проблемы

Послеродовая депрессия – достаточно частое явление, особенно в течение первого года после родов. То, что его отличает от обычного послеродового блюза, – это продолжительность. Угнетенные состояния длятся более двух недель. Могут наблюдаться эпизоды плаксивости, перепадов настроения, а также потеря аппетита, бессонница и даже суицидальные мысли. Пессимистичные настроения могут затягиваться на долгий срок и даже усугубляться.

Послеродовая депрессия и ГВ 

Исследования показали, что послеродовая депрессия может задерживать приход молока, а также угнетать иммунитет женщины. Поэтому мама может быть больше склонна к воспалительным процессам в молочной железе, а также к маститам и абсцессам, заживление трещин на сосках заметно замедляется.

Что касается взаимодействий с ребенком, то часто мы видим такое состояние женщины, как полная потеря уверенности в том, что она способна быть хорошей мамой, и в том числе кормить грудью. И это может быть как постоянное раздражение на ребенка и на членов семьи, так и полное отсутствие реакции на потребности малыша. Это самое опасное явление, потому что ребенок, на которого мама не реагирует, с которым она не коммуницирует, начинает тревожиться, плакать, и в последующем это влияет на его развитие.

Факторы риска послеродовой депрессии – это в первую очередь рождение первого ребенка, сложные роды и беременность, разделение с ребенком после родов. Также послеродовой депрессии часто подвержены мамы, которые плохо питаются, имеют проблемы со здоровьем, подвержены постоянному социальному стрессу (например мамы-одиночки); когда отсутствует взаимопонимание и поддержка со стороны партнера, когда могут быть какие-то деструктивные, агрессивные и насильственные отношения в семье. В случаях, когда у мамы уже были депрессии, эпизоды насилия в анамнезе, а также частичное лишение или полное отсутствие сна. Если у мамы отсутствует достаточное количество сна, то это может усиливать послеродовую депрессию.

Бытует мнение, что ночные кормления могут влиять на депрессивные настроения женщины, однако исследования говорят об обратном. Доказано, что именно грудное вскармливание, в отличие от смешанного вскармливания или искусственного, положительно влияет на материнский сон (т.е. кормящие мамы спят дольше и лучше). Если мама отказывается от грудного вскармливания и начинает кормить смесью, это может усилить ее расстройство сна. С чем это связано?

Это связано с тем, что ребенок, сосущий грудь, снижает уровень маминого стресса. Происходит это на уровне гормонов. Также грудное вскармливание защищает детей от маминой депрессии. Мамы, даже находясь в состоянии депрессии, чаще берут  малыша на руки и смотрят на него, взаимодействуют с ним. Исследования также доказали, что матери, которые кормят грудью до 4 месяцев, почти в 4 раза реже отвергают своих детей и почти в 3 раза меньше бьют их.

Как же помочь себе? Хороший совет – нормализовать  количество сна. Каким образом это сделать? Рекомендую спать тогда, когда спит ребенок, в том числе и днем. Если есть возможность и вы видите, что малыш засыпает на груди, то не бегите сразу варить борщ или вытирать каждую пылинку. Лучше побудьте рядом с малышом, полежите с ним и восполните это недостающее количество сна, которое, к сожалению, могло быть недоступно вам предыдущей ночью, потому что малыш часто просыпался или вы не совсем освоились с позами для кормления. Возможно, еще и болели соски, и все это не дало вам возможности выспаться.

Лучше всего отдавать часть домашней, бытовой рутины на аутсорс – можно родным, можно пользоваться услугами клининг-служб, доставок. То есть не пытаться все успеть самой, особенно в первые месяцы, когда вы находитесь в новой роли и пытаетесь научиться ухаживать за малышом, взаимодействовать с ним и понимать его. Это забирает действительно очень много сил и занимает все ваше время. Поэтому уделить внимание даже каким-то своим потребностям бывает очень трудно, не говоря уже об уборке квартиры. А также очень важно, конечно, регулярно гулять на свежем воздухе и желательно при солнечном свете. 

Что еще может особенно сильно сказываться на грудном вскармливании и настроении женщины? Многочисленные исследования говорят о том, что эмоциональное, физическое, сексуальное насилие в семье может приводить к тому, что снижается способность родителей заботиться о нуждах своих детей. Вплоть до того, что у кормящих женщин снижается желание придерживаться грудного вскармливания. 

Что происходит у женщин, у которых снижается возможность исключительного грудного вскармливания до шести месяцев? У таких мам повышается риск послеродовой депрессии, они меньше заботятся о своем здоровье, реже ищут помощь и имеют заниженную самооценку. Также очень важно понимать, что вы можете испытывать различные эмоции во время грудного вскармливания, такие как страх, злость, раздражение (как на себя, так и на ребенка). Это в норме случается. 

Что делать, если так получилось? Лучше продолжать кормить грудью. Ваше молоко не становится плохим или, как это принято называть, «нервным» оттого, что вы злитесь или плачете. Лучше продолжать прикладывать ребенка к груди. Это позволит сохранить с ним как физический, так и эмоциональный контакт, что будет полезно для вас обоих.

ГВ и домашнее насилие

Если вы столкнулись с эпизодами домашнего насилия в период беременности или после рождения ребенка, не бойтесь обратиться в специальные службы поддержки. Это поможет избежать потерю грудного вскармливания и усугубления вашего состояния.

И обязательно, если вы все равно видите, что что-то не так с вашим эмоциональным состоянием и это не проходит в течение нескольких недель, обратитесь, пожалуйста, к специалистам. Это не стыдно. Это абсолютно нормальная в современном мире практика – искать специалистов, которые могут оказать психологическую помощь. Медицина настолько шагнула вперед, что появились даже антидепрессанты, которые совместимы с грудным вскармливанием. Поэтому очень важно вовремя начать действовать, чтобы улучшить свое состояние.

И напоследок отмечу, что вместе с психологами подготовили для вас целый блок курсов о психологической поддержке в период беременности и после родов. И помните, что спокойная мама – спокойный малыш. Берегите себя!


    `,
};
