import { FC } from 'react';
import styles from './MainPageStatus.module.css';
import { UsageOptions } from 'types';
import { USAGE_OPTIONS } from 'constants/constants';
import { useTranslate } from 'hooks/useTranslate';
import { useAppWindowSize } from 'hooks/useAppWindowSize';

type MainPageStatusProps = {
  status: UsageOptions | null | undefined;
  title: string | undefined;
  showTitle: boolean;
  emptyTitle?: boolean;
};
export const MainPageStatus: FC<MainPageStatusProps> = ({
  title,
  showTitle,
}) => {
  const Icon = USAGE_OPTIONS.filter((option) => option.title === title)[0]
    ?.icon;

  const t = useTranslate();
  const { isTablet, isMobile } = useAppWindowSize();
  return (
    <div className={styles.container}>
      {Icon && !isMobile && !isTablet && <Icon />}
      {!!title && !isMobile && !isTablet && showTitle && (
        <p className={styles.title}>{t(title)}</p>
      )}
    </div>
  );
};
