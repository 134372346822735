import { FC, memo } from "react";
import styles from './TitleHomeH3.module.css';
import Highlighter from "react-highlight-words";
import classNames from "classnames";


export const TitleHomeH3: FC<{
  text: string;
  highlight?: string;
  center?: boolean;
}> = memo(({ 
  text,
  highlight,
  center = false,
}) => (
    <>
      {
        !!highlight && (
          <Highlighter
            textToHighlight={ text }
            searchWords={ [highlight] }
            className={ classNames(
              styles.text,
              center && styles.center,
            ) }
            highlightClassName={ classNames(
              styles.highlight,
              center && styles.center,
            ) }
            autoEscape
          />
        )
      }
      {
        !highlight && (
          <div className={ classNames(
            styles.text,
            center && styles.center,
          ) }>
            { text }
          </div>
        )
      }
    </>
));
