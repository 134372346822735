/* eslint-disable jsx-a11y/iframe-has-title */
import { useTranslate } from "hooks/useTranslate";
import { useUser } from "hooks/useUser";
import { checkProgress } from "pages/TestPage/TestPage.utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const FinalTestPageExt = () => {
  const t = useTranslate();
  const { user } = useUser();
  const navigate = useNavigate();
  const idToken = localStorage.getItem("idToken");

  useEffect(() => {
    if (user.id && idToken) {
      const block = user?.waeb?.progress?.block;
      const course = user?.waeb?.progress?.course;

      const { finished, certificate } = checkProgress(user, block!, course!);
      const finalTestPassed = localStorage.getItem("finalTestPassed");
      if (finalTestPassed) {
        navigate("/certificate-confirm");
        localStorage.removeItem("finalTestPassed");
      } else if (finished && !certificate) {
        navigate("/certificate-confirm");
      } else if (certificate) {
        navigate("/certificate-mobile");
      }
    }
  }, [user, idToken, navigate]);
  return <></>;
};

export default FinalTestPageExt;
