import * as yup from 'yup';

import { FormFields } from './constants';

const { NAME, EMAIL, PASSWORD, CONFIRM_PASSWORD } = FormFields;

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// password
export const CYRILLIC_REGEXP = /^[^а-яА-Я]+$/;
export const DIGITS_REGEXP = /(?=.*[0-9])/;
export const CAPITAL_LETTERS_REGEXP = /(?=.*[A-Z])/;
export const LOWER_LETTERS_REGEXP = /(?=.*[a-z])/;
export const SPECIAL_CHARACTERS_REGEXP = /(?=.*[!?/@#%^.,"'{}[\]:;&$*()-+=_])/;

export const NAME_REGEXP = /^[0-9a-zA-ZА-я]+$/;

const nameValidation = () => yup.string().matches(NAME_REGEXP).min(2).max(25);

const emailValidation = () =>
  yup
    .string()
    // .email()
    .min(5);

const passwordValidation = () => yup.string().min(6);

const confirmPasswordValidation = () =>
  yup.string().oneOf([yup.ref(PASSWORD)], 'sing_up_confirm_error_message');

export const getSingInValidationSchema = () =>
  yup.object().shape({
    [EMAIL]: emailValidation().required(),
    [PASSWORD]: passwordValidation().required(),
  });

export const getSingUpValidationSchema = () =>
  yup.object().shape({
    [NAME]: nameValidation().required(),
    [EMAIL]: emailValidation().required(),
    [PASSWORD]: passwordValidation().required(),
    [CONFIRM_PASSWORD]: confirmPasswordValidation().required(),
  });

export const getForgotPasswordValidationSchema = () =>
  yup.object().shape({
    [EMAIL]: emailValidation().required(),
  });

export const getDeleteAccountValidationSchema = () =>
  yup.object().shape({
    [EMAIL]: yup.string().required(),
    [PASSWORD]: passwordValidation().required(),
  });
