import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import ErrorMessage from 'components/Messages/ErrorMessage/ErrorMessage';
import { UsageOptions } from 'constants/constants';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { useShtickApi } from 'hooks/useShtickApi';
import { useTranslate } from 'hooks/useTranslate';
import { useUser } from 'hooks/useUser';
import styles from './LatePregnancy.module.css';
import { MainPageTitleSection } from '../MainPageTitleSection/MainPageTitleSection';
import StatusBox from '../StatusBox/StatusBox';

type LatePregnancyProps = {
  title: UsageOptions;
};
function LatePregnancy({ title }: LatePregnancyProps) {
  const { user } = useUser();
  const { shtick } = useShtickApi();
  const { isMobile } = useAppWindowSize();
  const t = useTranslate();

  return (
    <div className={styles.background}>
      <Header styled />
      <div className={styles.latePregnancy}>
        <MainPageTitleSection
          status={user.usageOption}
          showTitle={!!shtick}
          title={title}
        />
        <div className={styles.statusContainer}>
          <div className={styles.statusWrapper}>
            <StatusBox
              status={user?.usageOption}
              isShtick={!shtick}
              title={title}
              containerStyle={{ marginBottom: isMobile ? 20 : 30 }}
            />
            <ErrorMessage message={t('error_main_page')} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LatePregnancy;
