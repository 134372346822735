import { FC } from 'react';
import styles from './WelcomeSection.module.css';
import { Title } from 'components/Text/Title/Title';
import { useTranslate } from 'hooks/useTranslate';
import { WelcomeImage } from 'assets/icons';
import { StoreLink } from 'components/StoreLink/StoreLink';
import { Providers } from 'constants/constants';
import { TitleH1 } from 'components/Text/TitleH1/TitleH1';

export const WelcomeSection: FC = () => {
  const t = useTranslate();

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <TitleH1
            text={t('home_welcome_title')}
            highlight={t('home_welcome_title_highlight')}
            light
          />
          <h1 className={styles.descriptionContainer}>
            {t('home_welcome_description')}
          </h1>
          <div className={styles.linksContainer}>
            <StoreLink provider={Providers.APPLE} />
            <StoreLink provider={Providers.GOGGLE} />
          </div>
        </div>
        <div className={styles.imageContainer}>
          <WelcomeImage className={styles.image} />
        </div>
      </div>
    </section>
  );
};
