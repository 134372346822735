import { FC, useCallback, useState } from "react";
import styles from './Status.module.css';
import { UsageOptions } from "constants/constants";
import { useTranslate } from "hooks/useTranslate";
import { ListButton } from "../ListButton/ListButton";
import { EditProfileIcon } from "assets/icons";
import { useBinarySwitcher } from "hooks/useBinarySwitcher";
import { STATUSES } from "./constants";
import { Modal } from "components/Modal/Modal";
import { StatusPicker } from "../StatusPicker/StatusPicker";
import { updateDbUser } from "api/userApi";
import { noop } from "lodash";


export const Status: FC<{
  usageOption: UsageOptions | null | undefined,
}> = ({
  usageOption
}) => {
  const t = useTranslate();

  const [status, setStatus] = useState(usageOption);
  const [isOpenModal, openModal, closeModal] = useBinarySwitcher(false);

  const handleOnChangeStatus = useCallback(
    (value: UsageOptions) => () => {
      setStatus(value);
    },
    []
  );

  const handleConfirm = () => {
    updateDbUser({ usageOption: status })
    .then(() => {
      if (status === UsageOptions.PLANNING_PREGNANCY) {
        updateDbUser({
          dateOfChildBirth: null,
          dateOfPregnancy: null,
        }).catch(noop);
      }
      if (status === UsageOptions.MANAGING_PREGNANCY) {
        updateDbUser({
          dateOfChildBirth: null,
        }).catch(noop);
      }
      if (status === UsageOptions.AFTER_CHILDBIRTH) {
        updateDbUser({
          dateOfPregnancy: null,
        }).catch(noop);
      }
    })
    .catch(() => noop)
    .finally(() => closeModal());
  };

  return (
    <div className={ styles.container }>
      <div className={ styles.title }>
        { t('profile_status_title') }
      </div>
      {
        !usageOption && 
          <ListButton
            iconRight={ EditProfileIcon }
            title={ t('profile_unknown_status') }
            unselected
            onPress={ openModal }
          />
      }
      {
        usageOption && 
          <ListButton
            iconLeft={ STATUSES.filter((status) => status.title === usageOption)[0].icon }
            iconRight={ EditProfileIcon }
            title={ t(usageOption) }
            selected
            onPress={ openModal }
          />
      }
       <Modal
        title={ t('profile_status_title') }
        isOpen={ isOpenModal }
        onCancel={ closeModal }
        cancelTitle={ t('common-close') }
        onConfirm={ handleConfirm }
        confirmTitle={ t('common-apply') }
      >
        <StatusPicker
          selectedStatus={ status }
          onChange={ handleOnChangeStatus }
        />
      </Modal>
    </div>
  )
};
