type Block = {
  id: number;
  title: string;
};

type Step = {
  id: number;
  title: string;
  description: string;
  blocks?: Block[];
  note?: string;
};

type Button = {
  name: string;
  className: string;
  href: string;
  isLoggedIn: boolean;
};

export const blocksConfig: Block[] = [
  { id: 1, title: 'topics_womens_health' },
  { id: 2, title: 'topics_pregnancy' },
  { id: 3, title: 'topics_childbirth' },
  { id: 4, title: 'topics_breastfeeding' },
  { id: 5, title: 'topics_childcare' },
  { id: 6, title: 'topics_fatherhood_involvement' },
  { id: 7, title: 'topics_psychological_support' },
];

export const stepsConfig: Step[] = [
  {
    id: 1,
    title: 'registrationTitle',
    description: 'registrationDescription',
  },
  {
    id: 2,
    title: 'blockTitle',
    description: 'blockDescription',
    blocks: blocksConfig,
  },
  {
    id: 3,
    title: 'testingTitle',
    description: 'testingDescription',
  },
  {
    id: 4,
    title: 'finalTestingTitle',
    description: 'finalTestingDescription',
  },
];

export const buttonConfig: Button[] = [
  {
    name: 'startLearning',
    isLoggedIn: true,
    className: 'mzr_first_obuchenie',
    href: '/study-page',
  },
  {
    name: 'register',
    isLoggedIn: false,
    className: 'mzr_first_reg',
    href: '/status',
  },
  {
    name: 'login',
    className: 'mzr_first_login',
    isLoggedIn: false,
    href: '/login/',
  },
];
