import { Warning } from 'assets/icons';
import styles from './PasswordResetNotification.module.css';
import { useTranslate } from 'hooks/useTranslate';
import { useWindowSize } from 'hooks/useWindowSize';

export const PasswordResetNotification = ({ formattedTime }: any) => {
  const t = useTranslate();
  const { width } = useWindowSize();
  const isMobile = width <= 440;
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <Warning width={isMobile ? 16 : 24} height={isMobile ? 14 : 21} />
      </div>

      <div className={styles.textContainer}>
        <p className={styles.forgotPassword}>
          {t('forgot_password_modal_description')}
        </p>
        <p className={styles.timer}>
          {t('timer')} {formattedTime}
        </p>
      </div>
    </div>
  );
};
