import { FC } from "react";
import styles from './SupposedDateOfBirth.module.css';
import { useTranslate } from "hooks/useTranslate";
import { getEstimatedDateOfBirth } from "features/Profile/utils";


export const SupposeddDateOfBirth : FC<{
  dateOfPregnancy: number
}> = ({
  dateOfPregnancy,
}) => {
  const t = useTranslate();

  const [estimatedDateOfBirth] = getEstimatedDateOfBirth(dateOfPregnancy);

  return (
    <div className={ styles.container }>
      <div className={ styles.title }>
        { t('profile_estimated_date_of_birth') }
      </div>
      { 
        estimatedDateOfBirth && 
          <div className={ styles.data }>
            { estimatedDateOfBirth }
          </div>
      }
    </div>
  )
}
