import { FC } from 'react';
import styles from './Phones.module.css';
import classNames from 'classnames';
import { UsageOptions } from 'constants/constants';
import { STATUSES } from '../../constants';


export const Phones: FC<{
  currentStatus: UsageOptions;
}> = ({
  currentStatus,
}) => {

  return (
    <>
      {
        STATUSES.map(({ phoneIcon: Icon, status }) => (
          <img
            src={ Icon }
            className={ classNames(
              styles.phone,
              status === currentStatus  && styles.show,
            ) }
            alt="phone icon"
            key={ `icon-${status}` }
          />
        ))
      }
    </>
  )
};
