import { Expert } from 'types/items';
import { getExpertFullName, optional } from './other';
import { LOCALE_ID } from 'api/api';
import { RecommendationType } from 'types';

//@ts-ignore
export const byOrder = (a, b) => a.order - b.order;

export const adaptExperts = (experts: []): Expert[] => {
  const data: Expert[] = [];

  Object.keys(experts).forEach((id) => {
    data.push({
      //@ts-ignore
      id,
      //@ts-ignore
      ...optional<Expert>(experts[id][LOCALE_ID]),
    });
  });
  return data;
};

export const adaptExpertsToSearch = (
  experts: Expert[],
  name: string
): { title: string; name: string; subtitle: string }[] =>
  experts.map((expert) => ({
    id: expert.id,
    title: getExpertFullName(expert),
    name,
    subtitle: expert.field,
    type: RecommendationType.EXPERT,
  }));

//@ts-ignore
export const getImageSize = (windowW, wRatio, hRatio) => {
  const width = windowW;
  const height = (width * hRatio) / wRatio;

  return {
    width,
    height,
  };
};
//@ts-ignore
export const handleBtnAction = (navigation, btnData) => {
  if (btnData && btnData.screenId) {
    return navigation.navigate(btnData.screenId, {
      id: btnData.contentId,
    });
  }
};

export const THEME = {
  COLORS: {
    MAIN: '#a370b2',
    MAIN_OPACITY_09: 'rgba(163,112,178, 0.09)',
    MAIN_OPACITY_10: 'rgba(163,112,178, 0.1)',
    MAIN_OPACITY_20: 'rgba(163,112,178, 0.2)',
    MAIN_OPACITY_30: 'rgba(163,112,178, 0.3)',
    MAIN_OPACITY_40: 'rgba(163,112,178, 0.4)',
    MAIN_OPACITY_50: 'rgba(163,112,178, 0.5)',
    MAIN_OPACITY_60: 'rgba(163,112,178, 0.6)',
    MAIN_OPACITY_70: 'rgba(163,112,178, 0.7)',
    MAIN_OPACITY_80: 'rgba(163,112,178, 0.8)',
    MAIN_OPACITY_90: 'rgba(163,112,178, 0.9)',

    BLUE: '#71bbd4',

    MAIN_GRAY: '#F7F7F7',
    MAIN_BLUE: '#0070C9',

    WHITE: '#fff',
    WHITE_OPACITY_10: 'rgba(255,255,255, 0.1)',
    WHITE_OPACITY_20: 'rgba(255,255,255, 0.2)',
    WHITE_OPACITY_30: 'rgba(255,255,255, 0.3)',
    WHITE_OPACITY_40: 'rgba(255,255,255, 0.4)',
    WHITE_OPACITY_50: 'rgba(255,255,255, 0.5)',
    WHITE_OPACITY_60: 'rgba(255,255,255, 0.6)',
    WHITE_OPACITY_70: 'rgba(255,255,255, 0.7)',
    WHITE_OPACITY_80: 'rgba(255,255,255, 0.8)',
    WHITE_OPACITY_90: 'rgba(255,255,255, 0.9)',
    WHITE_OPACITY_95: 'rgba(255,255,255, 0.95)',

    GREY: '#404041',
    GREY_01: '#F2F2F2',
    GREY_OPACITY_03: 'rgba(64,64,65, 0.03)',
    GREY_OPACITY_07: 'rgba(64,64,65, 0.07)',
    GREY_OPACITY_10: 'rgba(64,64,65, 0.1)',
    GREY_OPACITY_20: 'rgba(64,64,65, 0.2)',
    GREY_OPACITY_30: 'rgba(64,64,65, 0.3)',
    GREY_OPACITY_40: 'rgba(64,64,65, 0.4)',
    GREY_OPACITY_50: 'rgba(64,64,65, 0.5)',
    GREY_OPACITY_60: 'rgba(64,64,65, 0.6)',
    GREY_OPACITY_70: 'rgba(64,64,65, 0.7)',
    GREY_OPACITY_80: 'rgba(64,64,65, 0.8)',
    GREY_OPACITY_90: 'rgba(64,64,65, 0.9)',

    BLACK: '#000000',
    BLACK_OPACITY_10: 'rgba(0,0,0, 0.1)',
    BLACK_OPACITY_15: 'rgba(0,0,0, 0.15)',
    BLACK_OPACITY_20: 'rgba(0,0,0, 0.2)',
    BLACK_OPACITY_30: 'rgba(0,0,0, 0.3)',
    BLACK_OPACITY_40: 'rgba(0,0,0, 0.4)',
    BLACK_OPACITY_50: 'rgba(0,0,0, 0.5)',
    BLACK_OPACITY_60: 'rgba(0,0,0, 0.6)',
    BLACK_OPACITY_70: 'rgba(0,0,0, 0.7)',
    BLACK_OPACITY_80: 'rgba(0,0,0, 0.8)',
    BLACK_OPACITY_90: 'rgba(0,0,0,0.9)',

    PURPLE: '#9A74AB',
    PURPLE_LIGHT_01: '#E1D5E6',
    PURPLE_LIGHT_02: '#CCB9D5',
    PURPLE_LIGHT_03: 'rgba(149,117,165,0.15)',

    RED: '#E51313',
  },
  FONTS: {
    MAIN: {
      LIGHT: 'Raleway-Light',
      REGULAR: 'Raleway-Regular',
      BOLD: 'Raleway-Bold',
      BOLD_EXTRA: 'Raleway-ExtraBold',
    },
  },
};

export const THEME_STYLES = {
  BUTTON_STYLES: {
    WRAPPER: {
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: 15,
      marginBottom: 15,
    },
    WRAPPER_ROW: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    ITEM_STYLE: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: 'transparent',
    },
    BTN_TITLE_PRIMARY: {
      color: '#FFFFFF',
      fontFamily: THEME.FONTS.MAIN.BOLD,
      fontSize: 16,
      textTransform: 'uppercase',
    },
    BTN_SUBTITLE_PRIMARY: {
      color: '#FFFFFF',
      fontFamily: THEME.FONTS.MAIN.REGULAR,
      fontSize: 12,
    },
    BTN_PRIMARY: {
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: 10,
      marginBottom: 10,
      backgroundColor: '#MAIN',
      borderWidth: 1,
      borderColor: '#MAIN',
      borderRadius: 7,
      elevation: 0,
      shadowOpacity: 0,
    },
    BTN_TITLE_SECONDARY: {
      color: '#MAIN',
      fontFamily: THEME.FONTS.MAIN.BOLD,
      fontSize: 16,
      textTransform: 'uppercase',
    },
    BTN_SECONDARY: {
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: 10,
      marginBottom: 10,
      backgroundColor: '#FFFFFF',
      borderWidth: 2,
      borderColor: '#MAIN',
      borderRadius: 7,
      elevation: 0,
      shadowOpacity: 0,
    },
    BTN_TITLE_TRANSPARENT: {
      color: '#GREY',
      fontFamily: THEME.FONTS.MAIN.BOLD,
      fontSize: 16,
      textTransform: 'uppercase',
    },
    BTN_TRANSPARENT: {
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: 10,
      marginBottom: 10,
      elevation: 0,
      shadowOpacity: 0,
    },
    BTN_TITLE_FS_18: {
      fontSize: 18,
    },
    BTN_TITLE_BOLD: {
      fontFamily: THEME.FONTS.MAIN.BOLD,
    },
  },
};
