import { separator, tb2c1_1, tb2c1_2, tb2c1_3, tb2c1_4 } from 'assets/images';

export const b2_c3_text = {
  b2_c1_title: `Курс «Как меняется организм женщины во время беременности?»`,
  b2_c1_description: `Спикер: Диана Мардас, врач акушер-гинеколог`,
  b2_c3_text: `
    ${tb2c1_1}
Урок 1. Как меняется организм женщины с наступлением беременности? Общий обзор

Наш организм важно хорошо понимать и беречь в течение всей жизни, а во время беременности – особенно. Ведь наш организм в это время делает очень большую работу. За 9 месяцев в нашем организме появляется, развивается, растет, а потом рождается отдельный человек! Поэтому важно на каждом этапе ему помогать.

К моменту, когда мы планируем беременность, организм уже привык работать в определенном режиме. И если наступление беременности планировать, то это поможет избежать нам множества проблем. Вы как будто предупреждаете свои органы системы о том, что скоро у них будет дополнительная и очень серьезная нагрузка. 

Важно:

быть в хорошей физической форме
прийти на прием к врачам, сдать ряд анализов
по возможности вылечить хронические заболевания
Одним словом – подготовить организм и прийти к состоянию беременности максимально здоровой. Потому что любое состояние (будь то кариес или какое-то простудное заболевание) во время беременности может привести к осложнениям, а лекарственные препараты могут оказать негативное влияние как на маму, так и на малыша.

С наступлением беременности у нас меняется «управление» в организме. Органы и системы начинают работать по-новому режиму. И главный орган – это матка и органы, которые появляются у нее внутри. Матка занимает лидирующую позицию. Потому что все остальные органы не претерпевают таких изменений, которые претерпевает матка и репродуктивная система женщины во время беременности. А другие органы испытывают последствия роста матки и, соответственно, они тоже меняются. Обсудим все эти изменения в следующих уроках.

${separator}

Урок 2. Как меняется матка во время беременности?

Матка, как и любой другой орган в нашем организме, к моменту беременности уже достигла определенного внутреннего гомеостаза. Говоря простым языком, гомеостаз – это внутренняя стабильность. Каждый месяц у нас есть критические дни. Матка участвует в процессе оплодотворения. Именно там начинается процесс встраивания яйцеклетки и сперматозоида в структуру матки.

Во время беременности матка увеличивается в 500-520 раз. Ни разу в нашей жизни ни один другой орган не увеличивается за 9 месяцев так масштабно!

Матка делает это за счет своей особенной структуры. В составе матки достаточно большой процент ее структуры занимает мышечная ткань. То есть матка – это мышечный орган. Именно поэтому может растягиваться, а потом сокращаться, и делать это многократно. 

И именно поэтому во время беременности матка может делать свойственные ей сократительные движения. Если у вас есть легкие тянущие боли, ощущения периодического сокращения, но они не вызывают видимого сокращения шейки матки, появления каких-либо выделений, нарушения нормального образа жизни – это нормально.

Если у вас возникает ощущение легкого тонуса – вы можете сказать об этом врачу, записать в дневник беременности. Но не переживайте! Следите за самочувствием и помните, что это вариант нормы.

Какие еще важные составные элементы есть в матке?

Тело матки
Именно за счет увеличения тела матки у нас развивается малыш. Внутри тела матки располагаются сосуды. Потому что сюда, к плаценте и к ребенку, поступают питательные вещества. И конечно, увеличивается и кровоток во время беременности, чтобы малыш мог полноценно развиваться.

Шейка матки
Шейка матки – это нижняя ее часть, которая как будто запирает нашу полость матки. Матке нужно растягиваться, увеличиваться и быть «домиком» для ребенка. А шейка матки – как будто дверь, которая закрыта. У нее немного другая структура – там меньше мышц. 

В норме шейка матки не может в течение дня становится то меньше, то больше. И на УЗИ у женщины могут зафиксировать укорочение шейки матки. И это уже повод обратиться к врачам и разбираться, почему шейка матки укорачивается. Ведь она, в отличие от тела матки, не должна этого делать.

Также внутри шейки матки находится так называемая слизистая пробка. Она не дает проникнуть патологическим микроорганизмам к малышу и служит своеобразным препятствием.

Основной вывод урока: если у вас все хорошо – никаких специальных дополнительных вспомогательных элементов/препаратов нашей матке не требуется. Не рекомендуется пить травы, отвары, которые улучшают ее работу. Не рекомендуется принимать какие-то препараты, которые помогают ей развиваться и сокращаться во время беременности.

${separator}

Урок 3. Пуповина, плацента, околоплодные воды: все, что нужно знать о них

Плацента, пуповина и околоплодные воды – новые органы, которых у женщины до беременности не было. Для чего и когда они появляются?

Плацента

Когда яйцеклетка и сперматозоид встречаются друг с другом, какое-то время им хватает запасов питательных веществ яйцеклетки. Они питаются, пока двигаются по маточной трубе в полость матки. Затем они ищут себе место, к которому смогут прикрепиться. И в это время (буквально несколько дней) для питания хватит запасов яйцеклетки. 

Что происходит дальше? Дальше нужно получать питательные вещества и кислород, чтобы все это проникало к ребенку. И в каком-то из мест (в зависимости от того, куда приземлились яйцеклетка и сперматозоид) начинается формирование плаценты. 

Плацента – это временный орган, который отвечает несколько функций. 

Питание и кислород. Через плаценту по пуповине к малышу поступают питательные вещества и кислород. 
Как они поступают? Наш женский организм дробит все на маленькие элементы – и дальше по плаценте через плацентарный барьер все это попадает к малышу и усваивается.

Защита. Есть так называемый барьер (сеточка с определенным размером делений), которая призвана фильтровать то, что нашему малышу не надо. Т.е. Плацента как бы отфильтровывает, обеззараживает, может сама бороться с различными патогенными агентами. Это не значит, что будущей маме можно неправильно питаться, употреблять алкоголь или курить. Не будем снимать с себя ответственности за то, что это здоровье нашего организма и нашего малыша. 
Также защитная функция плаценты выражается в том, что она отвечает за выработку специальных элементов, которые помогают формированию иммунитета малыша. 

Также плацента отвечает за выработку ряда гормонов, важных во время беременности.
В начале беременности плацента вклинивается в одну из стенок матки, увеличивается. Это называется процесс плацентации. Обычно он заканчивается на 15-16 неделе беременности.

Во время беременности она растет, делает свою работу, а после родов –  рождается вслед за малышом. Это происходит в третий период родов (всего их 3). В процессе родов она тоже интенсивно работает, т.к. наш малыш и его уже органы и системы в родах испытывают достаточно серьезную нагрузку. И после рождения плаценты ее функция завершается.

Где крепится плацента?

В норме плацента крепится по одной из боковых стенок (по передней, по задней, правой боковой или левой боковой). Могут быть и некоторые отклонения от нормы. Например:

Плацента может располагаться близко к шейке матки. Это происходит, когда матка чуть меньших размеров. Когда вы идете на УЗИ (особенно если это УЗИ во 2-м триместре), вам могут сказать, что плацента располагается низко. И тогда будущая мама начинает очень переживать.
Помните, что матка – это мышца, и она растягивается во время беременности. За счет этого будет увеличиваться расстояние между краешком плаценты и шейкой матки. Поэтому с ростом матки расстояние станет больше и будет абсолютной нормой. 

Может быть и сценарий, когда плацента располагается прямо в районе шейки матки, то есть мешает выходу ребенка из родовых путей. Мы называем такое расположение плаценты центральным. В таком случае вам будет показана операция кесарева сечения. 
Во всех других ситуациях – когда это будет низкое либо краевое расположении – будем смотреть по ситуации. В большинстве случаев роды возможны через естественные родовые пути. 

Пуповина

Плацента и все сосуды, которые идут внутри нее, собираются в пуповину. И далее к малышу бежит по двум сосудам чистая кровь (она несет ему все, что необходимо). И далее по одному сосуду все это выходит обратно. Тем самым пуповина вместе с плацентой продолжают питательную функцию. И также выполняет выделительную функцию. Ведь у малыша тоже идут процессы обмена веществ и тоже есть то, что нужно выделить и убрать из его организма. За это отвечает в том числе и пуповина. 

Длина пуповины обычно составляет около 55-60 см. Возникает логичный вопрос: может ли пуповина внутри матки с такой длиной лежать, не сделав ни одной петли? Она в любом случае будет немножко извиваться, петлять. И поэтому это абсолютно нормально, что на УЗИ вам могут там сказать, что в области шеи находится петля пуповины. 

Но это абсолютно не значит, что есть какая-то угроза для малыша. Угрозы для малыша нет даже тогда, когда есть реальные обвития пуповины вокруг шеи. Ведь наш малыш дышит не с помощью шеи. Все, что ему необходимо (кислород, питательные вещества, вода), поступает к малышу через пуповину.

Важно, чтобы пуповина не перерастягивалась во время беременности. Но сосуды в пуповине окружены специальной субстанцией. Это своего рода защитная среда для сосудов. Она похожа на плотное желе. И если бы на пуповину надавила ручка или ножка малыша или малыш плечом случайно прижал пуповину к стенке матки – то сосуды не пострадают.

Во время родов пуповина как веревочка следует за малышом. Малыш будет проходить по родовым путям, но длины пуповины хватит, чтобы к малышу все так же поступали необходимые питательные вещества и кислород.

Может ли быть с пуповиной и плацентой что-то не так? Да, может. И именно об этом вам всегда нужно разговаривать с врачом акушером-гинекологом. Например, на УЗИ могут предположить, что у вас короткая либо длинная пуповина. Это можно увидеть по количеству петель и завитков, которые делает пуповина. Тогда это важно сказать врачу в родах. Очень важно контактировать с врачом и передать ему такую важную информацию.

Также по УЗИ могут сказать, что у вас нарушенный кровоток в плаценте или, например, плацента стареет. Плацента во время беременности очень устает, и чем ближе мы находимся к сроку родов (40-42 недели), плацента работает хуже.

На самом деле один из механизмов запуска родовой деятельности завязан на том, что плацента сигнализирует мозгу о том, что пора рожать. Но если вы по какой-то причине в положенный срок не вступаете в роды, то рекомендую приезжать в родильный дом и вместе разбираться, что делать дальше. Потому что плацента в это время уже перестает работать на полную силу, а значит, она перестает на полную силу доставлять малышу кислород, питательные вещества и все, что ему необходимо.

Околоплодные воды

Для чего нужны околоплодные воды? На самом деле, у них, как у плаценты, очень много функций. Это:

защитная функция (чтобы малыш не ударялся о стенки матки, чтобы ему было комфортно);
иммунная функция (в околоплодных водах плавают разные элементы, которые отвечают за иммунную защиту малыша);
развитие малыша. За счет того, что малыш плавает, совершает активные движения, происходит стимуляция развития органов и систем.
Количество околоплодных вод может меняться. Их в каком-то объеме малыш может немножко заглатывать – это нормально.

Если вдруг на УЗИ вам сказали, что у вас есть тенденция к маловодию либо к многоводию, либо любые другие изменения с околоплодными водами – не спешите паниковать. Отправляйтесь на консультацию к врачу, который ведет вашу беременность, и вместе с ним определите дальнейшую тактику действий.

${separator}

Урок 4. Гормональная система будущей мамы

Когда женщина не находится в состоянии беременности, у нее существует определенный менструальный цикл. У нее ежемесячно появляются критические дни, гормональные горки, каскады. С наступлением беременности эта цикличность пропадает. 

Хорошая новость: если до беременности у вас был предменструальный синдром, отеки накануне критических дней, сильные головные боли, перемены настроения, то во время беременности они, как правило, исчезают. 

За каждое состояние в нашем организме отвечает какой-то ведущий гормон. Обсудим основные гормоны беременности. 

Прогестерон

Это основной гормон, который во время беременности отвечает за то, чтобы беременность развивалась идеально.

В начале беременности он начинает делать все, для того чтобы минимизировать риски. Он будет стараться делать так, чтобы вы не испытывали глобального желания куда-то бежать, заниматься активностями. У вас могут появиться такие симптомы, как сонливость, вялость, отсутствие настроения, пониженное давление. Это связано с тем, что прогестерон все расслабляет. Он это делает для того, чтобы беременность могла максимально укрепиться в организме. 

Чем больше срок беременности – тем интенсивнее гормон прогестерон работает. Все в организме будущей мамы расслабляется. 

Расслабляются вены – и им еще тяжелее гонять кровь. Они могут испытывать перегрузку. 
Расслабляются связки, суставы. Поэтому не рекомендуется давать серьезную нагрузку на суставы, потому что вы можете травмировать связочно-суставной аппарат. 
Может появиться тошнота, токсикоз. Если у вас было низкое давление, вам может стать немного хуже, вы можете испытывать некий дискомфорт. Некоторые будущие мамы даже отмечают, что есть будто предобморочное состояние. 

Важно знать две вещи – это норма и это пройдет. Чем больше срок беременности – тем больше наш организм адаптируется к влиянию гормона прогестерона.

Пролактин

Некоторые мамы отмечают, что в 28-30 недель появляются выделения из груди (из соска). И это часто пугает мам. Это действие гормона пролактина. Он запускает в нашем организме процессы подготовки молочной железы к кормлению. 

Если у вас возникла такая ситуация, то не нужно пугаться, а нужно сказать об этом своему врачу акушеру-гинекологу. Он даст вам рекомендации о том, что нужно носить специальный бюстгальтер, поможет выбрать размер. Также можно носить лактационные вкладыши.

Не рекомендуется:

давать дополнительную нагрузку на сосок (например, стимулировать, надавливать);
носить между грудью бюстгальтером подкладки из ткани.
Почему? Потому что вы можете (сами того не зная) запустить преждевременно так называемый «гормональный каскад». Это значит, запустить действие гормона окситоцина.

Окситоцин

Это гормон, который сокращает матку и помогает малышу родиться на свет в процессе родов. Конечно, действие окситоцина начинается и до родов. Проявляется это тем, что накануне родов (у каждой будущей мамы по-разному: у кого-то с 28 недель, у кого-то – с 32-37) могут появляться так называемые тренировочные схватки. Так он будто проверяет готовность организма к родам. 

Также гормон окситоцин связан с системой лактации. Поэтому, дорогие мамы, когда вы стимулируете сосок во время беременности, вы можете случайно дать мозгу сигнал, что кто-то ест, кто-то сосет молоко. Организм начнет вырабатывать гормон окситоцин – и процесс начала родов может запуститься преждевременно.

Главный вывод урока – если вы чувствуете себя хорошо, если вас ничего не беспокоит, ваша задача – правильно питаться, дышать свежим воздухом и получать удовольствие от состояния беременности.

${tb2c1_2}

Урок 5. Нервная система будущей мамы

С момента наступления беременности в организме начинают работать определенные гормоны. И нервная система тоже начинает работать по-особенному. 

В начале беременности уменьшается нервная возбудимость. Будущая мама становится спокойной.
Ближе к родам нервная система наоборот возбуждена. Потому что несколько механизмов запуска родовой деятельности основаны на том, что в мозг поступают некие сигналы от малыша, от матки – и мозг должен быстро среагировать и помочь нашему организму вступить в роды. У будущей мамы может появиться тревожность, волнение, ощущение предвкушения чего-то важного. 
У некоторых женщин даже может начаться диарея, рвота, может нарушаться сон. И такие последствия – не норма. Поэтому рекомендуем вас сразу обратиться к врачу и рассказать об этих симптомах. Ведь важно, чтобы к моменту родов вы были отдохнувшей, выспавшейся и настроенной на успех.

В 70-е годы прошлого столетия активно в научной среде акушерской обсуждалось наличие так называемой родовой доминанты. Сегодня не существует научно доказанного факта наличия той самой доминанты. Но тем не менее обсудим ее. Ведь действительно у женщины с наступлением беременности многое меняется, и это связано с нормальной реакцией нашей психики. Будущая мама все время думает про то, что у нее развивается малыш и скоро он появится на свет. Нормально ли это? Конечно! Это  формирование ответственности,  это закладка материнства, формирование коммуникации между вами и малышом.

Если у вас возникает желание поговорить с малышом – разговаривайте, если у вас возникает ощущение потрогать свой животик – потрогайте. Я даже рекомендую вам находить других будущих мам, которые испытывают похожие ощущения, и делиться мыслями.

Что еще нужно знать дорогие мамы про нервную систему? Она обязательно  сигнализирует нам о том, что что-то не так. Если мы видим, что начинаем плохо спать, у нас возникают тревожность – значит, есть некая проблема, о которой наша нервная система сигнализирует. Сегодня существует ряд научных исследований о том, что реакция нервной системы связана с вероятностью возникновения преэклампсии (простым языком – «аллергии» на беременность), когда начинает повышаться давление, появляются отеки, белок в моче и многих других состояний. 

Вывод: если вас что-то тревожит, беспокоит, нарушился сон – идем к врачу и углубленно изучаем организм.

Инстинкт гнездования

Примерно после 30 недель, когда нервная система переходит из состояния сниженной возбудимости к повышенной возбудимости, у женщин начинает формироваться позыв что-то сделать, подготовиться к рождению ребенка, создавать уют вокруг себя. И в это время этот инстинкт можно либо подавить, либо дать ему развернуться как одной из стадии формирования материнства, да, формировании связи между вами и малышом. Конечно, я рекомендую вам дать ему возможность проявить себя. Вы можете выбирать коляску, обустраивать комнату малышу, заниматься творчеством.

${separator}

Урок 6. Сердечно-сосудистая система будущей мамы

В этом уроке мы обсудим три важных темы:

Сердце
Сосуды
Кровь
Итак, как мы помним, гормон прогестерон в нашем организме все успокаивает и расслабляет. Как это влияет на сердечно-сосудистую систему? 

Ширина сосудистой стенки после воздействия прогестерона расслабляется. За счет этого может немного уменьшаться артериальное давление. Также по расширенной стенке, по сосуду, который немного расслаблен, по венам очень тяжело перегонять кровь. Плюс ей нужно проходить препятствие в виде увеличивающейся матки. Поэтому вены, особенно вены нижних конечностей, испытывают большую нагрузку.

Как помочь венам? Тут все зависит от того, в каком состоянии были ваши вены, когда наступила беременность. 

Если они были идеально здоровы – то  вены, конечно, справятся. 
Если у вас была, например, хроническая венозная недостаточность – то обязательно нужна консультация специалиста. 
Если вы не знаете, есть ли у вас хроническая венозная недостаточность, можете обратить внимание на два симптома:

появляющиеся к вечеру отеки
сосудистые звездочки, паутинки
Рекомендуется:

Гимнастика, водные процедуры (например, специальная аквааэробика для беременных).
Возвышенное положение ножек. Когда вы приходите домой, ложитесь и немного приподнимаете ноги (например, кладете подушку). После возвышенного положения ножек можете взять бутылочку и немного ее раскачивать, дать дополнительную физическую нагрузку, чтобы ножки размялись.
Компрессионный трикотаж. Его вы можете подобрать вместе с врачом акушером-гинекологом или в команде с врачом-флебологом, сосудистым хирургом.
Еще один важный момент – во время беременности увеличивается объем циркулирующей крови (за счет появления еще одного круга кровообращения  малыша) до 35-40%. Поэтому за счет этого венами также тяжело.

Также кровь становится гуще. А чем гуще жидкость – тем ее сложнее перегонять. И все это в комплексе дает большую нагрузку на наши вены, на наше сердце. 

Поэтому у будущей мамы могут появляться такие симптомы, как одышка, ощущение нехватки воздуха (временное, которое быстро проходит). Это нормально, но об этих симптомах нужно говорить врачу акушеру-гинекологу. 

Рекомендую вам вести дневник беременности или фиксировать такие изменения в мобильном приложении, чтобы вы не забыли сказать все доктору на приеме.

Обсудим такое состояние, как преэклампсия.  Это состояние, когда наш организм будто бьет в барабан и говорит: «Мне тяжело вынашивать беременность!». В некоторых источниках это называют даже «аллергией» на беременность. Это состояние возникает во время беременности и потом исчезает. Оно проявляется отеками, повышенным давлением, белком в анализе мочи. Если что-то из этого у вас появилось – вам нужно четко контролировать свое состояние, потому что обычно, чем больше срок беременности, тем выше вероятность усугубления этих симптомов. 

Рекомендации

Помните, что вес и отеки – это взаимосвязанные вещи.  Чем больше отеки – тем выше будет вес. Поэтому,  я рекомендую вести дневник учета веса. Вы можете по утрам фиксировать, какой у вас вес, чтобы не упустить резкую прибавку в весе, которая может сказать об отеках.
Также важно соблюдать режим, высыпаться, отдыхать, давать организму перезагрузку. Стресс, физическая усталость, нарушение сна – все эти факторы могут запустить или усугубить преэклампсию.
Старайтесь полноценно питаться. Контролируйте уровень соли, контролируйте объем жидкости, контролируйте вашу физическую активность. 
И помните: беременность – это не болезнь, а одно из самых лучших состояний в жизни!

${separator}

Урок 7. Печень: что о ней нужно знать?

Обсудим, как меняется пищеварительная система во время беременности. Разделим этот вопрос на три информационных блока. 

Желудок 
Кишечник
Печень
Печень

Печень в нашем организме – будто «обеззараживатель». То есть через нее проходят продукты питания, лекарственные средства. И она делает так, чтобы никакие составные элементы лекарственных средств, продуктов питания не влияли на другие органы и системы. 

Во время беременности печень испытывает двойную нагрузку, потому что она работает и за нас (обеззараживает все, что нужно нам), и за малыша (обеззараживает все, что не нужно малышу). Поэтому прием любых препаратов (витаминных и так далее) во время беременности должен быть строго обоснован. В норме будущей маме рекомендуют принимать лишь препараты фолиевой кислоты, иногда препараты йода и кальция. Все остальное не обязательно принимать во время беременности, если у вас все хорошо. 

Если вы сомневаетесь в своем самочувствии или хотите принимать какой-либо препарат – отправляйтесь к врачу и скажите ему: «Доктор, я вам доверяю и, если вы мне что-либо посоветуете, я буду это принимать. А если не понадобится – обещаю, что без ваших рекомендаций я не буду ничего принимать». Дорогие мамы, это очень серьезно, потому что все препараты, которые вы употребляете, отражаются на работе вашей печени в том числе. 


${tb2c1_3}

Урок 8. Изменения в работе желудка и кишечника

Желудок

Изменения в желудке зависят от триместра беременности.В 1-ом триместре беременности будущих мам часто беспокоит токсикоз. 

От чего возникает это состояние? Есть много разных гипотез. Например, что токсикоз появляется из-за влияния гормона прогестерона, который расслабляет все в нашем организме. Или из-за водно-солевого обмена и других процесс, происходящих в организме. Главное – знать, что нужно в такой ситуации делать. 

Как может проявляться токсикоз?

легкая тошнота
тошнота и рвота (раз-два в сутки)
Обратите внимание: если у вас рвота 5 и более раз в сутки – это показания для госпитализации. Вам необходимо вводить определенные препараты и объема жидкости капельно. 

Рекомендации при токсикозе:

После подъема утром, не бежите сразу куда-то, а плавно встаньте с постели. 
Съешьте что-нибудь легкое – например, печенье, сушку. Выпейте мятный чай. Это все то, что уменьшает проявления токсикоза. Кому-то помогает имбирь. 
Лучше не употреблять продукты, которые провоцируют еще большее проявление токсикоза – например, чипсы, наваристые бульоны, заправленные огромным количеством приправ мясные и не мясные блюда. Старайтесь выбирать комфортной температуры пищу, которая не будет раздражать ваш желудок. Например, некоторые будущие мамы говорят, что им помогало мороженое. Кому-то помогала минеральная вода, клюквенный морс.
Главное, помните: токсикоз – это нормально, и он обязательно пройдет.

Кроме базовых рекомендаций, есть и индивидуальные рекомендации, которые вы можете обсудить со своим врачом. Ведь не все, что может вам реально помогать, рекомендовано и разрешено во время беременности. Например, вы можете сказать: «Мне помогает бокал красного сухого вина!».  Конечно, его употреблять крайне не рекомендуется. Поэтому старайтесь согласовывать такие волнующие вас вопросы с доктором.

Во 2-ом и 3-ем триместрах беременности матка значительно увеличивается, она приподнимает органы и системы, и они начинают больше давить на желудок. Меняется объем желудка. Может возникнуть такое ощущение, как изжога.

Почему она возникает? Вы продолжаете кушать в таких же объемах, как и раньше. Но пища, которая раньше спокойно помещалась, сейчас не помещается – и как будто бы пытается вернуться обратно в пищевод. За счет этого пища уже успела перемешаться с желудочным соком (а это достаточно едкое содержимое), и она пытается подняться к пищеводу. И конечно же, она как будто бы немного обжигает пищевод. Отсюда и чувствуется такое явление, которое мы называем изжогой. И это тоже нормальное явление. 

Если она у вас уже появилась, то соблюдайте простые рекомендации:

поменяйте свой образ питания
кушайте чаще, но меньше (5-6 приемов пищи)
старайтесь пить воду в промежутках между приемами пищи
старайтесь не спать на ровной поверхности, можно сделать себе изголовье из подушек повыше, чтобы верхняя половина тела была немного приподнята
Кишечник

Гормон прогестерон расслабляет кишечник – и поэтому зачастую будущие мамы сталкиваются с такой проблемой симптом вздутия живота. Кишечник будто загазован, переполнен.

Также как в первом триместре, так и накануне родов, у будущей мамы могут появиться запоры.

Что в такой ситуации делать? Соблюдайте рекомендации:

дробное частое питание
добавляйте больше волокнистых продуктов (овощи, фрукты, каши, крупы)
если это не помогает – проконсультируйтесь с врачом и подберите лекарственные препараты
один из рецептов, который хорошо работает у будущих мам, – это оливковое масло. Каждый день перед едой можно употреблять чайную ложку оливкового масла как профилактику запоров.
Помните, что здоровье кишечника, здоровье желудка – это залог хорошего развитие в том числе маточно-плацентарной системы, а значит, и малыша внутри.
   
${separator}

Урок 9. Мочевыделительная система

В этом уроке обсудим, как меняются:

мочевой пузырь
почки
1 триместр беременности

Многие мамы отмечают, что учащается количество походов в туалет по-маленькому. Многие будущие мамы именно на этом симптоме начинают задумываться о том, что беременность наступила. Как мы помним из прошлых уроков, матка увеличивается в размерах и может давить на мочевой пузырь, поэтому у него уменьшается объем. А что происходит в первые недели беременности, когда матка еще не успела вырасти? Увеличивается, усиливается кровоток. Матка будто сигнализирует нашему организму и говорит, что ей нужен кислород. Мозг перенастраивается и начинает давать много кровотока в нижнюю часть нашего тела (т.е. к матке), а рядом с маткой расположены почки, у которых автоматически тоже усиливается кровоток. И за счет усиления почечного кровотока, который возникает у нас в первом триместре беременности, начинает вырабатываться большее количество мочи, и вы начинаете чаще ходить в туалет по-маленькому. 

Дорогие мамы, это не цистит и не какое-то заболевание. Поэтому не стоит сразу принимать какие-либо лекарственные препараты. Помните: во время беременности любой препарат рекомендуется согласовать с врачом.     

2 и 3 триместр

Кровоток усиливается, но почки к нему быстро привыкают. И в это время под воздействием усиленного кровотока у нас хорошо развивается малыш, и матка начинает увеличиваться С каждой неделей она становится все больше и влияет на те органы, которые располагаются рядом, в том числе на почки и мочевой пузырь. 

Мочевой пузырь испытывает, пожалуй, наибольший дискомфорт, потому что он граничит с маткой. И когда матка увеличивается в размерах, она давит на мочевой пузырь, и он больше не может растягиваться и занимать те объемы площади, которые занимал ранее.

Чем больше будет становиться срок беременности, тем меньшее количество мочи может одновременно вмещать в себе мочевой пузырь. И поэтому вы будете часто бегать в туалет по-маленькому. Здесь важный совет: терпеть крайне не рекомендуется. Если мочевой пузырь будет долго переполненным и матка будет на него давить, то может травмироваться стенка мочевого пузыря. И затем очень легко туда может присоединиться инфекция.

Также если будущая мама будет долго терпеть, то моча может застаиваться в мочеточниках и в почках. И за счет того, что она застаивается там долго, почки расширяются, и в тканях почек может возникнуть воспалительный процесс. Например, будущим мамам нередко ставят диагноз «гестационный пиелонефрит» (слово «гестационный» значит вызванный беременностью). Это вызванное беременностью воспаление почек. 

Как помочь мочевыделительной системе во время беременности?

Во время своего роста матка сдавливает мочеточники, которые идут от почек к мочевому пузырю, и в них может застаиваться моча. Поэтому старайтесь хотя бы несколько раз в день использовать позиционную помощь. А именно коленно-локтевое положение, когда вы наклоняясь вперед, становитесь на коленки, на локоточки. В таком положении можете посмотреть фильм, посмотреть образовательный курс, почитать книжку. То есть провести время с пользой и при этом немножко разгрузить мочевыделительную систему.

Не забывайте употреблять достаточное количество жидкости. Она оказывает очищающую, стимулирующую функцию, положительно влияет на на мочевыделительную систему. Например, вы можете пить клюквенный морс. Многие терапевты рекомендуют будущим мама употреблять клюквенный морс в ограниченных количествах. В нем содержится витамин С, а также он оказывает стимулирующую поддержку для нашего организма.

Будьте осторожны с травяными сборами. Потому что есть ряд трав, которые во время беременности могут вызвать маточный тонус, усугубить изжогу и в целом негативно сказаться на организме беременной.
${tb2c1_4}

Урок 10. Дыхательная система

Есть два важных фактора, которые дают нагрузку на дыхательную систему. Первый фактор – это матка, которая увеличивается в размерах и давит на диафрагму. А диафрагма в свою очередь давит на легкие, которые находятся как будто в ограниченном пространстве.

В связи с этим у будущей мамы может возникнуть следующие явления и особенности:

Легкая одышка. Не переживайте: организм обязательно адаптируется к этому изменению. Об этом нужно сказать врачу, но, скорее всего, разобравшись с вашим состоянием, врач вам скажет, что это абсолютная норма. Это влияние увеличенной в размерах матки. Обычно ярко это проявляется в третьем триместре. 
Увеличение частоты дыхания. Это тоже вариант нормы, если при этом у вас не нарушается ничего в общем гомеостазе, в вашем самочувствии.
Помимо давления матки на наши легкие и диафрагму, у нас происходит увеличенный запрос на количество кислорода. Поэтому обязательно рекомендуются:

прогулки
проветривание помещения
профилактика анемии через питание, через контроль количества гемоглобина и железа в анализе крови
Все это – залог хорошего транспортного обеспечения кислородом нашего организма.

Обратите внимание: если у вас возникают симптомы в виде одышки, слабости, побледнения кожи, быстрой утомляемости, у вас нарушается привычный образ жизни, сон – об этом обязательно нужно сказать врачу!

Также могут быть явления так называемого токсикоза, но только в дыхательной системе. Да, токсикоз связан не только с желудочно-кишечным трактом. Например, у вас может появиться ринит. 

Есть такое состояние, которое мы называем «ринит беременных». Женщину до беременности ничего не беспокоило, но с наступлением беременности начинают появляться слизистые выделения из носа. Первым делом будущие мамы думают, что у них аллергия. Знайте: это состояние может быть вариантом нормы. Но об этом тоже нужно сказать врачу, который поможет подобрать минимально необходимый набор лечения. 

Не рекомендуется:

принимать антибиотики
принимать противоаллергические препараты 
В большинстве случаев вам будет достаточно промывания носа солевыми растворами. 

${separator}

Урок 11. Рекомендации для будущей мамы

Первый важный вывод (пусть он пройдет красной нитью через всю вашу текущую беременность и все последующие беременности): беременность – это не болезнь.

Да, это особенное состояние, которое требует особых подходов, особенного внимания к себе, к своему телу, к своему организму. Но это чудесное состояние не является болезнью. А значит, вам можно практически все, что вы любили до беременности. 

Если вы ходили раньше в бассейн – продолжайте это делать
Если вы любили раньше пить зелёный чай – продолжать это делать
Если вы посещали раньше фитнес зал – продолжайте это делать, подобрав оптимальную физическую нагрузку
Помните, что все изменения органов и систем, которые мы сегодня обсуждали – это нормально. Важно лишь их слышать, понимать, быть в команде с врачом. 

Краткие выводы по курсу

1 триместр

У вас действует гормон прогестерон, все ваши органы и системы будут работать в расслабленном режиме. Могут появиться:

сонливость
запоры
вздутия живота
пониженное давление
Помните: если это мешает вам жить нормально, получать удовольствие от жизни, полноценно питаться и спать – обратитесь к врачу для приема лекарственных препаратов.

2 триместр

Прогестерон продолжает работу – и все расслабляется. Ваша матка активно увеличивается. У вас могут появиться:

частые походы в туалет
изжога и вопросы с желудком
запоры и другие вопросы с кишечником
3 триместр

Важно дать своему организму перегрузку. Организм очень устает.

Также именно по этой причине, кстати, мы рекомендуем интервалы между родами. То есть чтобы промежуток между родами был в районе двух-трех лет, чтобы наш организм успел восстановиться, перезапустить процессы и быть готовым к следующей беременности.

Возможно, сейчас смотрят мамы, которые только планируют беременность, или женщины, которые задумываются о рождении последующего малыша. Помните, что планирование беременности – это залог успешного ее течения.


`,
};
