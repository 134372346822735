import {
  b4c14q1,
  b4c14q2,
  b4c14q3,
  b4c15q1,
  b4c15q2,
  b4c15q3,
} from 'assets/images';

export const b4 = {
  c14: {
    title: 'b4_c14_title',
    author: 'b4_c14_author',
    description: 'b4_c14_description',
    lessonsLength: 10,
    video: 'https://vimeo.com/showcase/9263536/embed',
    tests: [
      {
        title: 'b4_c14_tests_title',
        question: 'b4_c14_test1_question',
        answers: {
          a1: 'b4_c14_test1_answer_a1',
          a2: 'b4_c14_test1_answer_a2',
          a3: 'b4_c14_test1_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b4_c14_test1_wrongAnswer',
        correctAnswer: 'b4_c14_test1_correctAnswer',
        image: b4c14q1,
      },
      {
        title: 'b4_c14_tests_title',
        question: 'b4_c14_test2_question',
        answers: {
          a1: 'b4_c14_test2_answer_a1',
          a2: 'b4_c14_test2_answer_a2',
          a3: 'b4_c14_test2_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b4_c14_test2_wrongAnswer',
        correctAnswer: 'b4_c14_test2_correctAnswer',
        image: b4c14q2,
      },
      {
        title: 'b4_c14_tests_title',
        question: 'b4_c14_test3_question',
        answers: {
          a1: 'b4_c14_test3_answer_a1',
          a2: 'b4_c14_test3_answer_a2',
          a3: 'b4_c14_test3_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b4_c14_test3_wrongAnswer',
        correctAnswer: 'b4_c14_test3_correctAnswer',
        image: b4c14q3,
      },
    ],
  },

  c15: {
    title: 'b4_c15_title',
    author: 'b4_c15_author',
    description: 'b4_c15_description',
    last: true,
    lessonsLength: 8,
    video: 'https://vimeo.com/showcase/9263544/embed',
    tests: [
      {
        title: 'b4_c15_tests_title',
        question: 'b4_c15_test1_question',
        answers: {
          a1: 'b4_c15_test1_answer_a1',
          a2: 'b4_c15_test1_answer_a2',
          a3: 'b4_c15_test1_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b4_c15_test1_wrongAnswer',
        correctAnswer: 'b4_c15_test1_correctAnswer',
        image: b4c15q1,
      },
      {
        title: 'b4_c15_tests_title',
        question: 'b4_c15_test2_question',
        answers: {
          a1: 'b4_c15_test2_answer_a1',
          a2: 'b4_c15_test2_answer_a2',
          a3: 'b4_c15_test2_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b4_c15_test2_wrongAnswer',
        correctAnswer: 'b4_c15_test2_correctAnswer',
        image: b4c15q2,
      },
      {
        title: 'b4_c15_tests_title',
        question: 'b4_c15_test3_question',
        answers: {
          a1: 'b4_c15_test3_answer_a1',
          a2: 'b4_c15_test3_answer_a2',
          a3: 'b4_c15_test3_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b4_c15_test3_wrongAnswer',
        correctAnswer: 'b4_c15_test3_correctAnswer',
        image: b4c15q3,
      },
    ],
  },
};
