import CourseEmptyWrapper from 'components/CommonCoursesCategory/components/CourseEmpty/CourseEmptyWrapper';
import { CoursesHeader } from 'components/CoursesHeader/CoursesHeader';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { useContent } from 'hooks/useContent';
import { useTranslate } from 'hooks/useTranslate';
import { useUser } from 'hooks/useUser';
import { selectExpertsById } from 'pages/ExpertScreen/store/selectors';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/store';
import { getCourseVideoLessons } from 'utils/courses';
import { getCategoryId, getType, optional } from 'utils/other';
import styles from './CategoryCourse.module.css';
import CategoryInfo from './components/CategoryInfo/CategoryInfo';
import CategoryVideo from './components/CategoryVideo/CategoryVideo';
import CategoryVideoGroup from './components/CategoryVideoGroup/CategoryVideoGroup';
import { watchCourseDetailsEvent } from 'analytic/trackEvents';
import { useExpertsApi } from 'hooks/useExpertsApi';

function CategoryCourse() {
  const { contentId, categoryId, lessonId } = useParams();
  const type = getType();
  useExpertsApi();
  useEffect(() => {
    if (!!contentId) {
      watchCourseDetailsEvent(contentId);
    }
  }, [contentId]);

  const t = useTranslate();
  const navigate = useNavigate();
  const activeId = lessonId || 'n0';
  const [clicked, setClicked] = useState<boolean>();
  const [showFullDescription, setShowFullDescription] = useState(false);
  //@ts-ignore
  const { content, loading } = useContent(contentId!, type);

  const experts = useAppSelector(
    selectExpertsById(Object.keys(optional(content?.expert)))
  );

  const videoLessons = useMemo(
    // @ts-ignore
    () => getCourseVideoLessons(content || {}),
    [content]
  );

  const handleSelect = (id: string) => {
    return `/edu/${getCategoryId(categoryId)}courses/${contentId}/${id}`;
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const { isMobile, isTablet } = useAppWindowSize();

  const id = activeId?.substring(1);
  const { user } = useUser();
  const locked = !user?.id && clicked;
  const duration = useMemo(() => {
    return videoLessons
      ?.map((lesson) => lesson.approximateTime || 0)
      ?.reduce((acc, time) => time + acc, 0);
  }, [videoLessons]);

  if (loading) {
    return (
      <CourseEmptyWrapper
        isTablet={isTablet}
        isMobile={isMobile}
        title={t('course')}
      />
    );
  }

  if (!content?.enable) {
    navigate('*');
    return null;
  }
  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.background}>
      <Header styled />
      <div className={styles.container}>
        <CoursesHeader
          title={'курс'}
          isTablet={isTablet}
          isMobile={isMobile}
          cb={navigateBack}
        />
        <h1 className={styles.title}>{content?.title}</h1>
        <div className={styles.wrapper}>
          {/* @ts-ignore */}
          <div className={styles.videoContentWrapper}>
            <div className={styles.videoContentInfoWrapper}>
              <CategoryVideo
                id={videoLessons?.[id as any]?.vimeo?.id}
                link={videoLessons?.[id as any]?.source}
                linkImage={videoLessons?.[id as any]?.thumb}
                isMobile={isMobile}
                isTablet={isTablet}
              />
              <CategoryInfo
                item={content}
                lessonLength={videoLessons?.length}
                title={videoLessons?.[id as any]?.title}
                order={videoLessons?.[id as any]?.order}
                duration={duration}
                description={content?.description}
                isMobile={isMobile}
                isTablet={isTablet}
                experts={experts}
                showFullDescription={showFullDescription}
                toggleDescription={toggleDescription}
              />
            </div>
            <CategoryVideoGroup
              isMobile={isMobile}
              videoLessons={videoLessons}
              activeId={activeId}
              handleSelect={handleSelect}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CategoryCourse;
