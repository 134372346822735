import {
  FamilySmall,
  HandWithHeart,
  PersonWithBaby,
  PregnancyAndPlus,
  StudyChild,
  StudyClock,
  StudyPregnancy,
  StudyToys,
} from 'assets/icons';

export const studyContentConfig = [
  {
    title: 'screen_study_block_program',
    icon: FamilySmall,
    id: 'waiting',
    certificateNeeded: true,
    tKey: 'screen_study_block_program',
  },
  {
    title: 'screen_study_block_planning',
    id: 'planning',
    icon: StudyClock,
    tKey: 'screen_study_block_planning',
  },
  {
    title: 'screen_study_block_child_birth',
    icon: StudyPregnancy,
    id: 'pregnancy',
    tKey: 'screen_study_block_child_birth',
  },
  {
    title: 'screen_study_block_pregnancy',
    icon: HandWithHeart,
    id: 'childbirth',
    tKey: 'screen_study_block_pregnancy',
  },
  {
    title: 'screen_study_block_recovery',
    icon: PregnancyAndPlus,
    id: 'postpartum-recovery',
    tKey: 'screen_study_block_recovery',
  },

  {
    title: 'screen_study_block_breastfeeding',
    icon: PersonWithBaby,
    id: 'breastfeeding',
    tKey: 'screen_study_block_breastfeeding',
  },
  {
    title: 'courses_category_title',
    icon: StudyChild,
    id: 'child-health',
    tKey: 'courses_category_title',
  },
  {
    title: 'screen_study_block_baby_development',
    icon: StudyToys,
    id: 'child-development',
    tKey: 'screen_study_block_baby_development',
  },
];

const studyProgressConfig = [
  'b1/c1',
  'b1/c2',
  'b2/c3',
  'b2/c4',
  'b2/c5',
  'b2/c6',
  'b2/c7',
  'b3/c8',
  'b3/c9',
  'b3/c10',
  'b3/c11',
  'b3/c12',
  'b3/c13',
  'b4/c14',
  'b4/c15',
  'b5/c16',
  'b5/c17',
  'b5/c18',
  'b5/c19',
  'b6/c20',
  'final_test',
];

export const calculateStudyProgress = (
  progress:
    | {
        block: string;
        course: string;
      }
    | undefined,
  startedAt: number | null
) => {
  if (startedAt && !progress) return 1;
  if (!progress) return 0;
  const { block, course } = progress;
  if (block === 'b8') return 98;
  const key = `${block}/${course}`;
  let index = studyProgressConfig.findIndex((item) => item === key);
  if (index === -1) {
    const courseIndex = Number(course.slice(1));

    let i = courseIndex;
    while (--i > 0 && index === -1) {
      index = studyProgressConfig.findIndex((item) => item === `${block}c${i}`);
    }
  }
  return index === -1
    ? 0
    : Math.trunc(((index + 1) / studyProgressConfig.length) * 100);
};
