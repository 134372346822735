import { CourseIcon } from 'assets/icons';
import { useTranslate } from 'hooks/useTranslate';
import ContentLesson from 'pages/CategoryCourse/ContentLesson/ContentLesson';
import { Lesson } from 'types/baseTypes';
import styles from './CategoryVideoGroup.module.css';

type CategoryVideoProps = {
  isMobile: boolean;
  videoLessons: Lesson[];
  activeId: string;
  handleSelect: (id: string) => string;
};
function CategoryVideoGroup({
  isMobile,
  videoLessons,
  activeId,
  handleSelect,
}: CategoryVideoProps) {
  const t = useTranslate();
  const size = isMobile ? 22 : 30;
  return (
    <div className={styles.videoGroupWrapper}>
      <div className={styles.lessonsTitle}>
        <CourseIcon color={'#3C3C3B'} width={size} height={size} />
        <p className={styles.lessons}>{t('lessons')}</p>
      </div>

      {!!videoLessons &&
        videoLessons.map((lesson) => (
          <ContentLesson
            key={lesson.id}
            item={lesson}
            activeId={activeId}
            handleSelect={handleSelect}
          />
        ))}
    </div>
  );
}

export default CategoryVideoGroup;
