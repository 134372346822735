import { FC } from 'react';
import styles from './ProfileScreen.module.css';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import { TitleSection } from './components/TitleSection/TitleSection';
import { selectUser } from 'features/User/store/selectors';
import { useAppSelector } from 'store/store';
import { PersonalInfo } from './components/PersonalInfo/PersonalInfo';
import { Status } from './components/Status/Status';
import { Certificate } from './components/Certificate/Certificate';
import { Menu } from './components/Menu/Menu';
import { UsageOptions } from 'constants/constants';
import { PeriodOfPregnancy } from './components/PeriodOfPregnancy/PeriodOfPregnancy';
import { DateOfChildBirth } from './components/DateOfChildBirth/DateOfChildBirth';
import { EstimatedDateOfBirth } from './components/EstimatedDateOfBirth/EstimatedDateOfBirth';
import { SupposeddDateOfBirth } from './components/SupposedDateOfBirth/SupposedDateOfBirth';
import { Favorites } from './components/Favorites/Favorites';
import CourseEmptyWrapper from 'components/CommonCoursesCategory/components/CourseEmpty/CourseEmptyWrapper';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { useTranslate } from 'hooks/useTranslate';

export const ProfileScreen: FC = () => {
  const user = useAppSelector(selectUser);
  const { isTablet, isMobile } = useAppWindowSize();
  const t = useTranslate();
  if (!user.id) {
    return (
      <CourseEmptyWrapper
        isTablet={isTablet}
        isMobile={isMobile}
        title={t('profile_title_page')}
        withoutButton
      />
    );
  }
  return (
    <>
      <Header styled />
      <main className={styles.main}>
        <div className={styles.container}>
          <TitleSection />
          <div className={styles.section}>
            <div className={styles.wrapper}>
              <div className={styles.commonInfoContainer}>
                <PersonalInfo user={user} />
                <div className={styles.commonInfoBlock}>
                  <Favorites />
                  {/* @ts-ignore */}
                  {!!user.waeb?.certificates && <Certificate />}
                </div>
              </div>
              <div className={styles.statusContainer}>
                <Status usageOption={user.usageOption} />
                {user.usageOption === UsageOptions.MANAGING_PREGNANCY && (
                  <>
                    <PeriodOfPregnancy dateOfPregnancy={user.dateOfPregnancy} />
                    <EstimatedDateOfBirth
                      dateOfPregnancy={user.dateOfPregnancy}
                    />
                    {!!user.dateOfPregnancy && (
                      <SupposeddDateOfBirth
                        dateOfPregnancy={user.dateOfPregnancy}
                      />
                    )}
                  </>
                )}
                {user.usageOption === UsageOptions.AFTER_CHILDBIRTH && (
                  <>
                    <DateOfChildBirth
                      dateOfChildBirth={user.dateOfChildBirth}
                    />
                  </>
                )}
              </div>
            </div>
            <div className={styles.menuContainer}>
              <Menu />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
