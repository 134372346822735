import { block1answer1, block1answer2, block1answer3 } from 'assets/images';

interface Test {
  title: string;
  question: string;
  answers: {
    [key: string]: string;
  };
  correctAnswerId: number;
  correctAnswer: string;
  wrongAnswer?: string;
  image: string;
}

interface Course {
  title: string;
  author: string;
  description: string;
  video: string;
  tests?: Test[];
  text?: string;
  checkList?: string;
  lessonsLength?: number;

  last?: boolean;
  next?: { block: string; course: string };
}

interface Book {
  [key: string]: Course;
}

export const b1: Book = {
  c1: {
    title: 'b1_c1_title',
    author: 'b1_c1_author',
    description: 'b1_c1_description',
    lessonsLength: 7,
    video: 'https://vimeo.com/showcase/9263254/embed',
    tests: [
      {
        title: 'b1_c1_tests_title',
        question: 'b1_c1_test1_question',
        answers: {
          a1: 'b1_c1_test1_answer_a1',
          a2: 'b1_c1_test1_answer_a2',
          a3: 'b1_c1_test1_answer_a3',
        },
        correctAnswerId: 3,
        wrongAnswer: 'b1_c1_test1_wrongAnswer',
        correctAnswer: 'b1_c1_test1_correctAnswer',
        image: block1answer1,
      },
      {
        title: 'b1_c1_tests_title',
        question: 'b1_c1_test2_question',
        answers: {
          a1: 'b1_c1_test2_answer_a1',
          a2: 'b1_c1_test2_answer_a2',
          a3: 'b1_c1_test2_answer_a3',
        },
        correctAnswerId: 2,
        wrongAnswer: 'b1_c1_test2_wrongAnswer',
        correctAnswer: 'b1_c1_test2_correctAnswer',
        image: block1answer2,
      },
      {
        title: 'b1_c1_tests_title',
        question: 'b1_c1_test3_question',
        answers: {
          a1: 'b1_c1_test3_answer_a1',
          a2: 'b1_c1_test3_answer_a2',
          a3: 'b1_c1_test3_answer_a3',
        },
        correctAnswerId: 1,
        wrongAnswer: 'b1_c1_test3_wrongAnswer',
        correctAnswer: 'b1_c1_test3_correctAnswer',
        image: block1answer3,
      },
    ],
  },
  c2: {
    title: 'b1_c2_title',
    author: 'b1_c2_author',
    next: { block: 'b2', course: 'c3' },
    last: true,
    description: 'b1_c2_description',
    lessonsLength: 1,
    video: 'https://vimeo.com/showcase/9263641/embed',
  },
};
