import { FC, SetStateAction, useMemo, useState, useCallback } from 'react';
import styles from './PeriodOfPregnancy.module.css';
import { useTranslate } from 'hooks/useTranslate';
import { EditProfileIcon, WrongProfileIcon } from 'assets/icons';
import { getNumbersDateOfPregnancy, getWeeksAndDays } from '../../utils';
import { useBinarySwitcher } from 'hooks/useBinarySwitcher';
import { Modal } from 'components/Modal/Modal';
import { UNKNOWN_DATE_10 } from 'constants/constants';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { FormFields } from 'features/Auth/Onboarding/validations/constants';
import { getDateOfPregnancyValidationSchema } from 'features/Auth/Onboarding/validations/validationSchema';
import { daysToMls, weeksToMls } from 'utils/converters';
import { updateDbUser } from 'api/userApi';
import { noop } from 'lodash';
import { NumberInput } from 'features/Auth/Onboarding/components/NumberInput/NumberInput';
import ErrorMessage from 'components/Messages/ErrorMessage/ErrorMessage';

const { DAYS_1, DAYS_10, WEEKS_1, WEEKS_10 } = FormFields;

const ALLOWED_SYMBOLS = Array.from({ length: 10 }, (_, i) => `${i}`);

// TODO: should consider refactoring to create clean, readable code
export const PeriodOfPregnancy: FC<{
  dateOfPregnancy:  number | null | undefined;
}> = ({
  dateOfPregnancy,
}) => {
  const t = useTranslate();

  const [isOpenModal, openModal, closeModal] = useBinarySwitcher(false);

  const [days, weeks] = getWeeksAndDays(dateOfPregnancy);
  const isDataValid = !!weeks || !!days;
  const processDate = (value: number | null | undefined) =>
    String(value).padStart(2, '0');

  const numberStyle = useMemo(
    () =>
      dateOfPregnancy || dateOfPregnancy === 0 ? styles.number : styles.unknown,
    [dateOfPregnancy]
  );

  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [selected, setSelected] = useState<FormFields | null>(WEEKS_10);

  const [WEEKS10, WEEKS1, DAYS1] = getNumbersDateOfPregnancy(dateOfPregnancy);

  const formik = useFormik({
    initialValues: {
      [WEEKS_10]: `${WEEKS10 || ''}`,
      [WEEKS_1]: `${WEEKS1 || ''}`,
      [DAYS_10]: '0',
      [DAYS_1]: `${DAYS1 || ''}`,
    },
    validationSchema: getDateOfPregnancyValidationSchema(),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const periodWeeksMls = weeksToMls(
        Number(`${values[WEEKS_10]}${values[WEEKS_1]}`)
      );
      const periodDaysMls = daysToMls(
        Number(`${values[DAYS_10]}${values[DAYS_1]}`)
      );

      const starPeriodPregnancyMls =
        Date.now() - (periodWeeksMls + periodDaysMls);

      if (starPeriodPregnancyMls) {
        updateDbUser({ dateOfPregnancy: starPeriodPregnancyMls }).catch(noop);

        closeModal();
      };
    },
  });

  const handleOpenModal = () => {
    const [WEEKS10, WEEKS1, DAYS1] = getNumbersDateOfPregnancy(dateOfPregnancy);
    formik.setFieldValue(WEEKS_10, WEEKS10 || '');
    formik.setFieldValue(WEEKS_1, WEEKS1 || '');
    formik.setFieldValue(DAYS_1, DAYS1 || '');

    openModal();
  }

  const handleChangeFocus = (isFocused: boolean) => {
    if (isFocused) {
      formik.setErrors({});
      setIsTouched(true);
    }
  };

  const handleChange = useCallback(
    (value: { nativeEvent: { data: any; }; }, currentField: FormFields, nextField: SetStateAction<FormFields | null>) => {
      const keyValue = value.nativeEvent.data;
      if (!keyValue) {
        formik.setFieldValue(currentField, '');
      }

      if (ALLOWED_SYMBOLS.includes(keyValue)) {
        formik.setFieldValue(currentField, keyValue);
        setSelected(nextField);
      }
    },
    [formik]
  );

  const handleConfirm = () => {
    formik.handleSubmit();
  };
  

  return (
    <div className={ styles.container }>
      <div className={ styles.titleContainer }>
        {
          !dateOfPregnancy &&
            <img src={ WrongProfileIcon } className={ styles.wrongIcon } alt='wrong'/>
        }
        <div className={ styles.title }>
          { t('profile_period_pregnancy_title') }
        </div>
      </div>
      <div className={ styles.displayContainer } onClick={ handleOpenModal }>
        <div className={ numberStyle }>
          { isDataValid ? processDate(weeks) : UNKNOWN_DATE_10 }
        </div>
        <div className={ styles.text }>{ t('week', { count: weeks || 0 }) }</div>
        <div className={ numberStyle }>
          { isDataValid ? processDate(days) : UNKNOWN_DATE_10 }
        </div>
        <div className={ styles.text }>{ t('day', { count: days || 0 }) }</div>
        <div className={ styles.iconContainer }>
          <img src={ EditProfileIcon } className={ styles.icon }  alt={ 'icon' } />
        </div>
      </div>
      <Modal
        title={ t('profile_period_pregnancy_modal_title') }
        isOpen={ isOpenModal }
        onCancel={ closeModal }
        cancelTitle={ t('common-cancel-2') }
        onConfirm={ handleConfirm }
        confirmTitle={ t('common-apply') }
      >
        <div className={ classNames(
            styles.bloksContainer,
            !formik.isValid && styles.bloksErrorContainer
          ) }
        >
          <div className={ styles.block }>
            <div className={ styles.blockRow } >
              <NumberInput 
                id={ WEEKS_10 }
                selected={ selected === WEEKS_10 }
                onChange={ (value) => {
                  handleChange(value, WEEKS_10, WEEKS_1)
                } }
                onChangeFocus={ handleChangeFocus }
                error={ !formik.isValid }
                value={ formik.values[WEEKS_10] }
              />
              <NumberInput
                id={ WEEKS_1 }
                selected={ selected === WEEKS_1 }
                onChange={ (value) => {
                  handleChange(value, WEEKS_1, DAYS_1);
                } }
                onChangeFocus={ handleChangeFocus }
                error={ !formik.isValid }
                value={ formik.values[WEEKS_1] }
              />
            </div>
            <div className={ styles.blockLabel }>
              { t('common-weeks') }
            </div>
          </div>
          <div className={ styles.block }>
            <div className={ styles.blockRow } >
              <NumberInput 
                id={ DAYS_10 }
                onChange={ (value) => {
                  formik.handleChange(value)
                } }
                onChangeFocus={ handleChangeFocus }
                error={ !formik.isValid }
                value={ formik.values[DAYS_10] }
                desabled={ true }
              />
              <NumberInput
                id={ DAYS_1 }
                selected={ selected === DAYS_1 }
                onChange={ (value) => {
                  handleChange(value, DAYS_1, null);
                } }
                onChangeFocus={ handleChangeFocus }
                error={ !formik.isValid }
                value={ formik.values[DAYS_1] }
              />
            </div>
            <div className={ styles.blockLabel }>
              { t('common-day') }
            </div>
            </div>
          </div>
          <div className={ styles.error }>
            {
              !formik.isValid && (
                <ErrorMessage
                  message={
                    formik.errors[WEEKS_1] === 'error_fill_fields' ||
                    formik.errors[WEEKS_10] === 'error_fill_fields' ||
                    formik.errors[DAYS_1] === 'error_fill_fields' ||
                    formik.errors[DAYS_10] === 'error_fill_fields'
                      ? t('error_fill_fields')
                      : t('error_PeriodPregnancy')
                  }
                />
              )
            }
          </div>
      </Modal>
    </div>
  )
};
