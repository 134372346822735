import { FC, useState } from 'react';
import styles from './StatusSection.module.css';
import { Title } from 'components/Text/Title/Title';
import { useTranslate } from 'hooks/useTranslate';
import { StatusPicker } from './components/StatusPicker/StatusPicker';
import { UsageOptions } from 'constants/constants';
import { Phones } from './components/Phones/Phones';
import { Texts } from './components/Texts/Texts';
import { useWindowSize } from 'hooks/useWindowSize';

export const StatusSection: FC = () => {
  const t = useTranslate();

  const [currentStatus, setCurrentStatus] = useState<UsageOptions>(
    UsageOptions.MANAGING_PREGNANCY,
  );
  const { width } = useWindowSize();
  const isMobile = width <= 440;

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <Title
            text={t('home_status_title')}
            highlight={t('home_status_title_highlight')}
            center={isMobile ? false : true}
          />
          <div className={styles.descriptionContainer}>
            {t('home_status_description')}
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.pickerContainer}>
            <StatusPicker
              selectedStatus={currentStatus}
              onChange={setCurrentStatus}
            />
          </div>
          <div className={styles.phoneContainer}>
            <Phones currentStatus={currentStatus} />
          </div>
          <div className={styles.textContainer}>
            <Texts currentStatus={currentStatus} />
          </div>
        </div>
      </div>
    </section>
  );
};
