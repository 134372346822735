import { FC } from "react";
import styles from './Card.module.css';
import { useTranslate } from "hooks/useTranslate";

export type TCard = {
  name: string;
  icon: string;
  title: string;
  text: string;
}

export const Card: FC<{
  data: TCard;
}> = ({
  data
}) => {
  const t = useTranslate();

  const {
    name, icon, title, text,
  } = data;

  return (
    <div className={ styles.container }>
      <img src={ icon } alt={ name } className={ styles.icon } />
      <div className={ styles.wrapper }>
        <div className={ styles.title }>
          { t(title) }
        </div>
        <div className={ styles.text }>
          { t(text) }
        </div>
      </div>
    </div>
  );
};
