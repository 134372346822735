import { StorageKeys } from './constants';

export const writeToStorage = (key: StorageKeys, data: any) =>
  localStorage.setItem(key, JSON.stringify(data || {}));

export const readFormStorage = (key: StorageKeys): any =>
  JSON.parse(localStorage.getItem(key)!);

export const removeFromStorage = (key: StorageKeys): any =>
  localStorage.removeItem(key);

export const clearStorage = () => localStorage.clear();
