import {
  separator,
  tb4c1_1,
  tb4c1_10,
  tb4c1_2,
  tb4c1_3,
  tb4c1_4,
  tb4c1_5,
  tb4c1_6,
  tb4c1_7,
  tb4c1_8,
  tb4c1_9,
} from 'assets/images';

export const b4_c14_text = {
  b4_c1_title: `Курс «Правила и секреты идеального грудного вскармливания»`,
  b4_c1_description: `Спикер: Наталья Разахацкая, консультант по грудному вскармливанию`,
  b4_c14_text: `
    ${tb4c1_1}

    Урок 1. Как молочная железа готовится к грудному вскармливанию во время беременности?

Во время беременности питание малыша происходит автоматически через пуповину. А уже после его рождения именно грудь является тем органом, который соединяет маму и ребенка. 

Всемирная организация здравоохранения говорит о том, что грудное вскармливание является оптимальным питанием для детей первых лет жизни. Именно в грудном молоке находится бесценный комплекс различных активных биологических веществ. И этот комплекс до сих пор не воспроизведен ни в одной смеси. 

Что происходит с грудью во время беременности?

Ни для кого не секрет, что именно по изменениям состояния молочной железы некоторые женщины определяют, что они беременны. Грудь первой подает звоночек о том, что наступило зарождение новой жизни: 

соски становятся более чувствительными
зона ареолы и соска темнеет
грудь нагрубает и может становиться плотнее
Это связано с разрастанием паренхимы молочной железы или железистой ткани, которая отвечает за выработку молока. Возникает периодическое чувство жжения, распирания, покалывания. И через некоторое время, ближе ко второму триместру беременности, женщина может заметить выделения желтых капелек того первого молока – молозива.

Как это происходит на гормональном уровне? На самом деле, весь процесс становления лактации заключается в 3 последовательных этапах: 1-я и 2-я стадии лактогенеза и непосредственно лактопоэз (т.е. поддержание выработки молока). 

Первая стадия лактогенеза начинается примерно с 16-й недели беременности под действием различных гормонов. Проще говоря, до беременности грудь состоит из жировой и соединительной тканей с небольшими вкраплениями железистой ткани. Она представляет собой небольшие вкрапления железистой ткани, похожих на гроздья винограда. Во время беременности под действием таких гормонов, как эстроген и прогестерон, происходит расцвет этих альвеол. Они увеличиваются в размере, развивается внутренняя поверхность альвеол, там начинают формироваться лактоциты – те самые молочные клетки, в которых в последующем будет вырабатываться грудное молоко. А пока там начинает секретировать первое молоко (молозиво). Им и будет питаться малыш в первые дни после родов.  Об этом мы обязательно поговорим чуть позже. 

Как можно помочь молочной железе? 

Как происходит изменение формы груди уже после родов? Есть два больших страха, связанных с грудным вскармливанием. 

Из-за кормления грудь теряет форму и обвисает. 
Если вы не готовите соски во время беременности, то после рождения ребенка они будут сильно болеть и там будут образовываться трещины.
Дело в том, что ни одно млекопитающее никоим образом не готовит свои молочные железы к лактации. Точно так же грудь не нужно готовить и будущим мамам. Грудь сделает это сама под действием тех гормональных процессов. Все произойдет естественным образом.

Все, что рекомендуется делать, – это носить удобное белье. Советую уделять внимание бесшовным бюстгальтерам из микрофибры. Они хорошо тянутся, не давят на грудь, не стесняют ее, не изменяют ее форму и могут растягиваться после родов. Ведь грудь растет не только во время беременности, но и в первые месяцы после родов, когда приходит молоко. 

Что касается специальных кремов от растяжек для сохранения упругости бюста, то они не доказали своей эффективности. Растяжки, как правило, – это наследственный фактор и особенности выработки коллагена. К тому же они располагаются в более глубоких слоях дермы, поэтому обычные косметические средства не помогут убрать стрии и их предупредить.

Что же касается страха, что грудь обвиснет после грудного вскармливания, отмечу следующее: грудное вскармливание никак не может на это повлиять. Грудь меняет свою форму уже на этапе беременности. Далее мамы имеют дело с последствиями этих изменений. Поэтому даже если женщина не кормит грудью ни одного дня, грудь все равно изменит свою форму после родов. Дорогие мамы, не переживайте, ведь все в ваших руках, вы обязательно найдете способ поправить эту проблему. Более того, грудь постепенно восстанавливает свой первоначальный вид в течение года после того, как молоко уходит из молочной железы. 

В противовес этому факту скажу, что грудное вскармливание –  это профилактика онкозаболеваний (рака молочной железы и рака яичников). Поэтому по многочисленным исследованиям, чем больше у женщины детей, чем дольше она кормит грудью, тем меньше риск развития этих, к сожалению, достаточно частых онкозаболеваний в современном мире. 

Подготовка сосков: нужна ли она? 

Каких только манипуляций не существует, чтобы загрубить кожу: и применение специальных кремов для сосков, и использование грубых тряпочек в качестве вкладышей в бюстгальтер. Однако исследования доказали, что дородовая подготовка сосков не помогает профилактировать боль и трещины после родов. Самая главная причина того, что женщине больно кормить, что соски травмируются – это неправильное прикладывание ребенка к груди. Когда малыш берет грудь эффективно и глубоко, тогда женщине легко. И никаких трещин не возникает, сколь  долго ребенок бы не сосал мамину грудь. 

Мазать соски ничем не рекомендуется. Тем более что вокруг них во время беременности начинают проявляться бугорки. Это железы Монтгомери, которые выделяют специальную бактерицидную смазку. Она дополнительно питает, увлажняет и защищает нежную кожу сосков.

В большем внимании после родов нуждаются женщины, у которых так называемые втянутые соски (их отличает то, что после стимуляции они не становятся более выпуклыми). Тему особенностей грудного вскармливания в этом случае я обязательно расскажу в следующих уроках. Важно помнить, что самое главное для женщины в подготовке к грудному вскармливанию – это готовить свою голову. 

Старайтесь читать специализированные ресурсы, посещать информационные страницы специалистов и консультантов по лактации. И, конечно, смотрите наши курсы, с заботой подготовленные для вас. Это поможет получить правильную информацию о том, как выглядит грудное вскармливание после родов, о том, как правильно прикладывать ребенка к груди, и какие правила грудного вскармливания рекомендуется соблюдать, чтобы оно было успешным.

${separator}

Урок 2. Основы эффективного прикладывания 

Грудное вскармливание – это естественное продолжение родов. Это умение и навык, который требует времени и внимания для усвоения. Особенно если речь идет о первом малыше. Ключ к успеху грудного вскармливания – это эффективное прикладывание. Что же это значит? Разберемся в этом уроке.

Эффективное прикладывание означает, что:

кормящей маме не больно
ребенок получает достаточное количество молока для хорошего набора веса
Если ребенок приложен к груди плотно, если он захватывает грудь глубоко, то кормление грудью протекает комфортно и мама не испытывает болезненных ощущений.

Мифы по поводу прикладывания

Ребенок должен взять сосок и сосать его, как трубочку
Женщины пытаются дать грудь, как бутылку. Женщины пытаются повторить этот маневр и пытаются дать грудь ребенку, нависая над ним, подавая грудь сверху. И в этом случае очень часто ребенок грудь берет со стороны носика, а не со стороны подбородка, и мама вынуждена держать палец, чтобы малыш мог дышать. В этом случае прикладывание выглядит достаточно болезненным и травматичным, и женщина может испытывать очень болезненные ощущения, а ребенок не наедаться, бросать грудь и травмировать сосок. 
Если мы говорим про эффективное прикладывание, эффективный захват, то ребенок изначально прикладывается подбородком к нижней части ареолы, и таким образом сосок направлен ребенку не в ротик, а больше в сторону верхней губы и носика малыша. И вот когда малыш хорошо положил свой подбородок на нижнюю часть ареолы, он знает, как широко ему открыть рот. И он тогда захватывает сосок глубоко, и сосок попадает в комфортную зону между твердым и мягким небом. И в этом случае маме не больно, а малыш хорошо сосет грудь.

Основные шаги к эффективному прикладыванию

Рекомендуем начать кормить грудью как можно раньше. В идеале – в первый час после родов. 
Если позволяет ваше состояние и самочувствие ребенка, то можно попросить медицинский персонал, чтобы ребенка положили к вам на живот и оставили его, не обмывая и не забирая от вас, хотя бы в первый час после родов. Это важно, чтобы малыш смог реализовать все свои врожденные рефлексы и с успехом приложиться к груди.

Будет отлично, если вы начнете прикладывать ребенка к груди, когда он еще спокоен, но уже показывает сигналы к тому, что хочет есть. 
Как распознать эти сигналы? Он начинает двигать губами из стороны в сторону, подносить ко рту свои кулачки, активно двигает головой. И если ребенок уже расплакался, он будет очень беспокойным под грудью, и так его будет труднее правильно приложить к груди. 

Комфортная поза
По сути, лучше начинать прикладывание с того, чтобы выбрать для себя комфортное положение. Комфортное – прежде всего означает, что стоит найти поддержку для своей спины. Ноги и руки также должны быть расслаблены. Это можно делать, сидя на диване, опираясь на подушку на кровати либо лежа на боку вместе со своим малышом, когда голова находится на комфортной высоте на удобной подушке.

При прикладывании важно не пытаться попасть ребенку грудью в рот, а максимально близко повернуть малыша к себе и хорошенько разместить его на себе, чтобы он использовал ваше тело как опору. 
Так ему будет гораздо проще взять грудь. Советуем вам не бояться прижимать ребенка к себе очень близко, вы ему ничего не повредите. Это абсолютно нормально, если малыш будет полностью повернут к вам и будет лежать на животе. Его голова и тело должны быть на одной линии. Не должно быть так, что голова повернута в одну сторону, а тело развернуто в другую. Его ручки обнимают вашу грудь с двух сторон. 

Запомните 3 основные точки опоры: подбородок ребенка плотно прижат к нижней части вашей груди, а грудная клетка и таз ребенка также прижаты к вашему животу

Голова малыша должна быть откинута назад. 
Если брать малыша за голову (за затылок) при прикладывании к груди, то это может привести лишь к тому, что ребенок зажмет свою нижнюю челюсть и упрется носом в грудь. В этом случае, когда вам важно дополнительно прижимать малыша, советую держать его в районе плечиков. 

Если вы чувствуете необходимость подать ребенку грудь, лучше убедиться, что пальцы находятся далеко от ротика малыша. Дождитесь, пока подбородок малыша хорошо прижмется к нижней части вашей ареолы, и тогда ваш сосок будет смотреть, скорее всего, в сторону носика ребенка. Только в этом случае, когда малыш почувствует, что он коснулся подбородком нижней части вашей ареолы, он сможет широко открыть ротик и  взять грудь глубже. 

Признаки того, что малыш хорошо сосет:

вы слышите, что он глотает
видите, что его нижняя челюсть хорошо опускается вниз, на секунду зависает там, прежде чем вернуться вверх
При неглубоком захвате ребенок сосет очень быстро, без глотков (т.е. делает вот такие быстрые движения). При медленном с хорошим сосанием он немножко зависает в конце, и потом только челюсть возвращается назад. 

Иногда неэффективное прикладывание может быть не связано с болью, когда дети сосут очень вяло и не создают вакуум. Еще один признак не очень эффективного прикладывания – когда малыш втягивает щеки во время сосания и цокает. 

Маме не должно быть больно. 
Да, легкий дискомфорт может присутствовать только в первую неделю, пока вы учитесь. Но неприятные ощущения, как правило, проходят в процессе кормлений. Если где-то через минуту после начала кормления боль не уходит, а только нарастает или продолжает быть на определенном уровне, лучше всего достать грудь с помощью чистого пальца. Его вы можете вставить палец малышу в уголок ротика (между его десенками) и безопасно достать грудь.

После этого попробуйте приложить малыша  снова, и используйте правила успешного прикладывания. Другой вариант – просто поменяйте позу. Также если прикладывание неэффективное, женщина может увидеть, что сосок поменял форму. Например, стал скошенный либо сплюснутый с двух сторон. Если с прикладыванием все хорошо, сосок может удлиниться, и он остается круглым.

Часто детям с рождения предлагают так называемые ортодонтические пустышки, или бутылки для сосания, и они имеют скошенную или сплющенную форму. После использования подобных девайсов дети  часто начинают брать грудь по-другому и таким образом травмировать сосок. 

Помните, что грудное вскармливание должно быть комфортным. Ребенок добывает молоко не из вашего соска, а из вашей груди.

Мама и малыш приспосабливаются к друг другу в течение первых 4-6 недель после родов. В этот период могут быть ошибки в прикладывании и не все может получаться. Если вам сложно понять, в чем дело и как помочь своему малышу эффективно взять грудь, советую вам воспользоваться специализированными ресурсами или обратиться за услугами консультанта по лактации, который поможет вам добиться успехов в прикладывании.

${tb4c1_2}

Урок 3. Удобные позы для кормления грудью

То, как мама держит ребенка во время кормления, может облегчить или, наоборот, осложнить прикладывание к груди. Не существует единственно правильного способа прикладывания ребенка к груди. Кормящие мамы пробуют разные позы, чтобы добиться максимально комфортного положения как для себя, так и для малыша. Не менее важен в этом вопросе и максимально глубокий захват груди. В этом уроке мы рассмотрим с вами важные моменты для любого прикладывания и покажем наиболее распространенные позы для кормления грудью.

Первое, о чем я хочу сказать, – все индивидуально. Почему важно это помнить? Когда вы смотрите на любое обучающее видео, вы должны понимать, что форма груди и соска, а также ребенок на демонстрационном видео могут сильно отличаться от вас и вашего малыша. Поэтому это всего лишь пример того, как можно попробовать кормить грудью. И только вам решать, в какой позе кормить своего ребенка. 

! Взаимный комфорт мамы и малыша – это ключ к успешному грудному вскармливанию. 

Основные позы для кормления грудью

«Откинутая поза», либо «расслабленное кормление»

${tb4c1_3}

Она хорошо подходит: 

сразу после родов
в первые месяцы жизни ребенка
мамам после разреза промежности (потому что вся нагрузка приходится не на промежность, а на крестец – для этого маме нужно откинуться назад).
Основные моменты этой позы – ребенок полностью развернут к маме и лежит вдоль ее тела на животе. Это помогает ему находить грудь самому. Действительно, для многих может быть удивительным, что дети могут сами найти сосок и приложиться к груди самостоятельно, без маминой помощи. Но для этого им нужно помочь занять удобное положение. Руку мама может положить вдоль тела, чтобы поддерживать голову малыша.

Часто в этой позе мамы беспокоятся за нос и за давление на живот малыша. Женщины боятся, что ребенок может задохнуться или срыгнуть. Но, на самом деле, именно для сосания поза на животе максимально удобная. И если ребенок случайно упрется носом в вашу грудь, он сам откинет голову назад. 

Мой совет – положите малыша посередине между грудями и дайте ему возможность самому двигаться в сторону одной из грудей. Когда он достиг своей цели, вы можете поддерживать его голову, положив сбоку ее на свое предплечье. Вторую руку вы можете поместить в район спины и немного прижать грудь сверху, если она слишком объемная, чтобы лучше видеть ребенка. 

Поза «колыбель»

${tb4c1_4}

Это поза более традиционная для всех женщин. Мы часто видим, как подобным образом кормят уже подросших детей. 

В этой позиции малыша переводят из вертикального положения, когда он прижат к маме животиком, на свое предплечье. То есть малыш оказывается не параллельно, а перпендикулярно телу кормящей мамы. Дальше ребенка сдвигают на руке к противоположной груди, чтобы его носик оказался напротив соска. Нижнюю ручку рекомендуется убирать, если ребенок прижал ее к себе, и подводить под свою грудь. Дальше, когда ребенок прижмется подбородком к нижней части ареолы, он сам захватит грудь. 

Как помочь ребенку? Если у вас большая грудь, вы можете взять ее в ладонь, сложив пальцы буквой «С», тогда формируется удобная складка. Напомню, что в такой позе маме крайне важно иметь очень хорошую опору для спины. Немного откинувшись назад, женщина даст возможность ребенку как следует опереться на свое тело. 

«Перекрестная колыбелька»

${tb4c1_5}

«Перекрестная колыбелька» нужна в ситуации, когда: 

ребенок не может сам найти грудь
когда он ослаблен
когда у женщины грудь слишком большая и объемная (а сосок невыраженный)
когда нужно помогать ребенку хорошо захватить грудь
В этом случае вы можете положить свою руку ему под затылок, сложив пальцы буквой «С». Очень важно не класть указательный палец малышу на затылок. То есть пальцы лучше расположить около ушей ребенка, и далее, уже лежа на руке, прижать ребенка к своему телу. А второй рукой советую помочь взять ему грудь, сложив пальцы буквой «У». Обращаю ваше внимание, что в таком положении не менее важна опора для маминой спины.

«Поза из-под мышки» (или «футбольный мяч»)

${tb4c1_6}

В этой позе удобно кормить ребенка: 

после кесарева сечения
если у женщины очень большая грудь
есть застой молока в боковом квадранте молочной железы
если речь идет о кормлении двойни
если у малыша кривошея
Как держать малыша? Точно так же ребенок выкладывается к вам на руку, вы держите его С-образным захватом в районе в шеи под затылком и прижимаете его уже к своему боку. Под ребенка можно положить свернутый валик или небольшую подушку, для того чтобы вам было удобнее держать его на весу. Грудь располагается сверху, поэтому в этом случае есть возможность усилить поток для слабого малыша, чтобы он глотал чаще и эффективнее, а также в случае, если малыш привык получать молоко из бутылки. 

«Поза лежа»
 
${tb4c1_7}

Когда мама располагается лежа, очень важно, чтобы ее голова была на комфортной высоте на подушке, а ребенок лежит рядом с мамой на боку. Будет отлично, если вы воспользуетесь правилом: «ближе и ниже». Чем ниже и ближе ребенок расположен, тем более эффективно он может захватить грудь мамы. 

Здесь очень важно не прижимать голову ребенка в районе затылка. Все, что нужно сделать, это помочь прижать его с помощью своей верхней руки, которая располагается в области между лопатками, а второю руку вы можете положить себе под голову, для более удобного и комфортного положения. Если так не получается, вы можете приподнять верхней рукой его головку и подложить свою руку ему под голову. И в таком случае вам будет удобнее подать ему грудь для более близкого и комфортного прикладывания. Если возможно, то я советую вам обходиться без руки,  то есть лучше, если малыш будет сам прикладываться к груди. Это обеспечит заветный максимально глубокий и эффективный захват. 

Помните о том, что вы подбираете позы для себя очень индивидуально, потому что очень важен в первую очередь физический и психологический комфорт мамы и ребенка.

${separator}

Урок 4. Правила успешного грудного вскармливания в роддоме

Молоко матери – самое полезное и ценное питание, которое только можно себе представить для новорожденного. Помимо этого, кормление – своего рода коммуникация между мамой и ребенком. В этом уроке я вам расскажу, какие правила применяются для успешного вскармливания в роддоме, и раскрою секреты, почему это важно.

Грудное вскармливание начинается сразу после рождения ребенка. Очень важно, чтобы малыш был сразу же выложен к маме на грудь. Это так называемый контакт кожа к коже, который широко практикуется в больницах, дружественных к матери и ребенку. 

Новорожденные выкладываются на живот маме при отсутствии противопоказаний сразу после рождения еще до пересечения пуповины. И малыш остается с мамой вплоть до перевода в послеродовое отделение. Рекомендуется, чтобы длительность контакта кожа к коже составляла не менее получаса. 

В случаях кесарева сечения ребенка прикладывают маме к груди в течение первого получаса после ее выхода из наркоза. Если была местная анестезия, то ребенка могут выложить маме на грудь сразу. При готовности малыша сосать, маме могут помочь приложить его к груди и он пытается кормиться не менее 30 минут. Обработка, взвешивание, осмотр ребенка при отсутствии противопоказаний проводят только после контакта кожа к коже и первого кормления.

Контакт кожа к коже

Польза контакта кожа к коже в том, что это приводит к хорошей стабилизации состояния малыша и мамы сразу после родов. Ребенок сразу же согревается, стабилизируется его температура, он начинает хорошо дышать, запускаются все необходимые рефлексы для адаптации к внешнему миру. У мамы также легче9

 проходит ранний послеродовый период, то есть быстрее отделяется плацента. И это является хорошей профилактикой послеродового кровотечения. 

Что делать, если контакт кожа к коже не произошел сразу после родов? Если по каким-то причинам ранний контакт кожа к коже невозможен, то рекомендую как можно раньше начать прикладывать ребенка к груди, как только он окажется вместе с вами. Будет отлично, если вы его распеленаете, переоденете в заранее подготовленную одежду и попробуете приложить его в любом из удобных для вас положений. 

Чаще всего это поза лежа на боку либо поза откинутая назад, или расслабленная. Если малыша не приносят в течение 6 часов после родов, то необходимо начать сцеживать грудь. 

Даже до прихода молока (еще на стадии молозива), очень важно начать стимулировать грудь, чтобы имитировать присутствие ребенка:

это можно сделать руками либо молокоотсосом каждые 3 часа
обе груди рекомендуют сцеживать примерно по 10-15 минут
До этого момента, пока к вам не принесли малыша, вы это делаете. Как только ребенок оказывается с вами рядом, вы можете перестать сцеживаться и начать прикладывать его к груди. В следующих уроках мы еще более подробно разберем с вами основы эффективного и безболезненного сцеживания груди. 

Все мамы после родов очень переживают за свое состояние и часто отдают малышей в детское отделение, чтобы медсестры лучше за ними ухаживали. На самом деле, ребенок не требует много внимания к себе сразу после родов и ему намного комфортнее находиться рядом с мамой, а не в детском отделении. Поэтому ключ к успеху грудного вскармливания – это совместное пребывание ребенка с мамой. Если вам необходима какая-то помощь, вы не справляетесь сами, то вы всегда можете позвать на помощь медицинский персонал, который придет и поможет вам приложить ребенка, успокоить его и понять причину его плача.

Если вы находитесь вместе с малышом, то будет очень хорошо, если вам удастся кормить его по требованию. Это значит, что прикладывание происходит: 

хотя бы каждые 2-3 часа днем
хотя бы каждые 3-4 часа ночью
Если малыш днем спит больше 2 часов от конца предыдущего кормления, то его можно разбудить, чтобы вовремя начать следующее кормление. Это важно для того, чтобы молоко вовремя пришло, и малыш получал достаточное количество питания. Дело в том, что некоторые детки после родов могут быть достаточно сонными. Это может быть связано с низким уровнем сахара в крови и другими особенностями после родов.

Как разбудить ребенка? 

Его можно раздеть до подгузника и начать активно его выглаживать, массажируя при этом  ручки, ножки, спинку. Это поможет быстрее малышу проснуться. Также помогает контакт кожа к коже.

Очень важно придерживаться исключительно грудного вскармливания, если ребенок не теряет больше 7-10% своего веса от рождения. В случае если малыш теряет больше 10% от своего веса от рождения в роддоме либо по каким-то причинам не прикладывается к груди, то можно использовать докорм в виде сцеженного молока (оно является приоритетным, исходя из документа ВОЗ «Показания для докорма») или смеси (в случае если сцеженного молока не хватает). Также в руководстве ВОЗ «10 шагов к успешном грудному вскармливанию» рекомендуется отказаться от использования бутылки, соски и пустышки для успокоения ребенка, а докармливать его с помощью ложки или чашки. Это поможет малышу впоследствии не растеряться около груди и сосать ее правильно, обеспечивая комфорт и себе, и вам.

Надеюсь, теперь вы будете спокойны, и ваше грудное вскармливание в роддоме будет успешным.

${separator}

Урок 5. Как убедиться, что малышу хватает молока?

В отличие от кормления из бутылки, когда женщина кормит грудью, у нее возникает масса вопросов. Сколько съел ребенок? Достаточно ли ему этого количества? Достаточно ли молоко питательное и жирное для моего малыша? Вопросы контроля могут очень серьезно повлиять на успех грудного вскармливания. Именно поэтому очень важно понимать объективные критерии, когда можно точно сказать, что ребенку грудного молока хватает.

Объективный критерий один – это набор веса. Все мы знаем, что дети после рождения теряют свой вес. Это связано с тем, что они проходят роды, и первые несколько дней адаптируются к раннему послеродовому периоду. Также они теряют лишнюю влагу, которая могла накопиться будучи внутриутробно. Теряют свой первородный кал (меконий), что тоже влияет на вес. И первое молоко (молозиво) поступает детям в очень небольшом объеме, именно поэтому большие прибавки в весе быть не могут.

Что же является нормой? Исходя из графиков развития Всемирной организации здравоохранения, новорожденный  ребенок должен терять не более 7-10%  от своего веса от рождения. Если он теряет больше, значит, действительно, можно говорить о том, что ему не хватает молозива и ему нужен докорм. 

Далее, начиная с 3-4-го дня жизни, ребенок начинает прибавлять вес. Мы оцениваем прибавку таким образом: ребенок должен набрать свой вес от рождения к 10-14-му дню жизни. Если этого не произошло к назначенному сроку, мы можем говорить о том, что ребенку не хватает питания. 

Почему питания может не хватать?

Его может не хватать по двум причинам:

Либо у мамы недостаточная выработка молока
Либо малыш не может эффективно добывать молоко из груди 
В любом случае ребенку необходим докорм. В первую очередь – сцеженным молоком. И только в крайнем случае, если сцеженного молока не хватает, рекомендуется прибегать к адаптированным смесям.

Критерии набора веса

Когда ребенок набирает свой изначальный вес от рождения, он должен набирать не менее 20-30 г в день либо в среднем 200 г в неделю. То есть к одному месяцу прибавка в весе малыша должна быть минимум + 600 г от веса от рождения. До сих пор идет спор: считать ли прибавку в весе от выписки или от веса от рождения? 

Графики Всемирной организации здравоохранения, которые как раз были придуманы и адаптированы для развития здоровых детей на грудном вскармливании, предлагают отсчитывать эту минимальную прибавку от веса от рождения. Как правило, дети, которым хватает питания, набирают больше. И средняя прибавка за первый месяц может колебаться в районе 1 кг. Мальчики, как правило, набирают чуть больше, девочки – чуть меньше.

Самый активный набор происходит в первые 3-4 месяца, когда ребенок, как правило, удваивает свой вес от рождения. Дальше уже динамика набора веса снижается. Следующий момент – это то, что, кроме непосредственно веса, мы можем оценивать более субъективные критерии:

мочеиспускание ребенка
стул ребенка
Считается, что если в сутки вы меняете малышу не менее 6-8 подгузников, то, скорее всего, ему питания хватает. Тест на мокрые пеленки, когда считают сколько раз ребенок намочил пеленку, не всегда является объективным критерием, потому что некоторые дети с проблемами (например, заболеваниями почек) могут делать больше мочеиспусканий, но они при этом могут не набирать вес. То есть объективный критерий – это все же набор веса.

Теперь я бы хотела поговорить о таких очень субъективных критериях, но которые часто беспокоят современных мам, и иногда они могут давать ложное чувство того, что ребенку всего хватает или, наоборот, не хватает грудного молока. 

Частота кормлений
Дети на грудном вскармливании в первые месяцы жизни могут сосать грудь периодически каждый час. То есть может быть от 8 до 12 кормлений в сутки. Днем – чуть чаще, ночью – чуть реже. В среднем дети сосут грудь каждые 2-3 часа днем и ближе к вечеру их желание приложиться к груди учащается. Это называется иногда кластерными кормлениями, а ночью, соответственно, это примерно один раз в 3-4 часа. Поэтому если ребенок так часто просит грудь, но при этом нормально набирает в весе, значит, ему всего хватает. 

Также иногда мамы очень переживают, что ребенок быстро засыпает на груди. Здесь важно понимать, когда именно ребенок засыпает. Если он засыпает в самом начале кормления, не успев, так скажем, активно поглотать грудное молоко, то это, действительно, может быть не очень хорошим знаком. Если же он активно поглотал несколько минут, а потом только заснул, то это считается вариантом нормы.

Беспокойство
Когда малыш не успокаивается сразу после кормления, а продолжает плакать. Это вызывает множество сомнений у мам: прикладывать дальше или не прикладывать, он хочет есть или его тревожит что-то другое. Дети так устроены, что любое свое беспокойство они хотят решать с помощью сосания груди, поэтому не сомневайтесь, не колебайтесь – каждый раз предлагайте ребенку грудь. Вы его таким образом не перекормите и не избалуете.

Еще часто мам беспокоит такой момент, что они сцеживают молоко, а оно как синяя водичка, или, например, начинает в холодильнике расслаивается. И бытует миф, что такое молоко не совсем хорошего качества. Так ли это на самом деле? На самом деле, любое молоко, которое вырабатывается у разных женщин, оно априори питательно для ребенка, если мама не имеет вредных привычек и хорошо питается.

Хотелось бы отметить и то, о чем молодые родители часто даже не подозревают. Это так называемые голодные счастливцы. Да, вы не ослышались. Это детки, которые, наоборот, ведут себя очень спокойно в первый месяц жизни. Они много спят, практически не плачут, редко просят грудь и очень мало сосут. И кажется, что это просто идеальный ребенок и родителям повезло. 

Но на самом деле, когда этот малыш оказывается у педиатра на приеме, то получается, что он очень мало или вообще не набрал свой вес и остался с первоначальным весом от выписки. Часто такая ситуация приводит родителей в шок. Поэтому очень важно понимать, что если ребенок до одного месяца постоянно спит больше 2-3 часов, и каждый раз его приходится будить, нужно удостовериться в том, что он хорошо набирает вес. Если прибавка хорошая, значит, вы можете оставить его в покое. Пусть он просыпается так часто, как считает нужным. Если же с весом беда – необходимо ребенка будить и побуждать его к кормлению, для того чтобы малыш вовремя получил необходимое молоко.

Причины, по которым дети могут так долго спать, –  это незрелость, слабость, падение сахара в крови. Вы в силах переубедить малыша и в буквальном смысле взять проблему  ребенка в свои руки.  

Взвешивание младенца

Взвешивать ребенка лучше на детских весах, которые стоят на одной и той же поверхности. Переносить их каждый раз с одного места на другое крайне не рекомендуется.

Ребенок может быть голеньким или одетым в пеленку. Пеленку затем можно взвесить отдельно, чтобы отнять ее вес и увидеть более точное значение, сколько же весит малыш. 
Взвешивание лучше проводить не чаще одного раза в сутки. Не совсем правильно, когда начинается бесконтрольное взвешивание детей несколько раз в сутки (до и после кормления), когда родителей что-то пугает или не устраивает в весе малыша. Такие манипуляции не дадут правильной информации о реальной прибавке в весе. 
В ситуации, когда с весом все хорошо, взвешивание можно проводить реже (например, раз в неделю) и на обычных напольных весах. Советую вам встать на них с малышом, а затем – без него, отняв соответствующие значение. Разница «до» и «после» – и есть вес вашего ребенка.

Помните, что ваше молоко – самая ценная пища для малыша. От его количества и качества в том числе зависит успешный набор веса ребенка, его самочувствие и настроение. Именно поэтому женщине как никогда важно не только выполнять правила грудного вскармливания, но и следить за собственным питанием.

${tb4c1_8}

Урок 6. Питание кормящей матери

Успех грудного вскармливания зависит не только от настроя мамы, состояния ее здоровья, условий отдыха, но и от качества питания. 

Основа питания кормящей матери – это сбалансированный рацион. В общем, считается, что кормящей маме рекомендовано употреблять примерно на 500-600 ккал больше, чем до беременности. Но на практике достаточно есть по аппетиту. То есть прямо за двоих есть не нужно, но важно и не забывать о приемах пищи. Желательно маме подготовиться к тому, что уход за маленьким ребенком будет отвлекать ее от приготовления пищи. Поэтому можно заранее заготовить свежезамороженные фрукты, овощи, и таким образом облегчить себе готовку. 

Популярные мифы о питании на ГВ

Миф 1. Во время кормления грудью необходимо пить очень много жидкости

На самом деле, выработка молока не зависит от количества выпитой жидкости. Она связана напрямую с действием гормонов. И, по сути, механизм регулируется именно опорожнением груди, а не выпитой водой. Поэтому пить надо по жажде, не пропуская сигналы организма о необходимости влаги. Насильно вливать в себя 5 л жидкости точно не стоит.

Миф 2. Когда приходит молоко, нужно ограничить выпиваемую жидкость

На самом деле это приводит к обезвоживанию, еще большим застоям молока и поднятию температуры тела, так как молоко может становиться более вязким. 

Поэтому все, что нужно сделать, – это понимать стимулирующие факторы, которые отвечают за механизм лактации. Выработку гормона окситоцина, который отвечает за прилив молока, провоцирует теплое питье. То есть если мы хотим, чтобы прилив произошел быстрее, можно выпить любое теплое питье накануне кормления или сцеживания. Если же мы хотим, наоборот, не провоцировать излишние приливы, то, соответственно, от употребления теплой жидкости накануне кормления либо сцеживания лучше отказаться.

Но еще раз напоминаю, что на протяжении всего периода грудного вскармливания важно пить достаточное количество воды. Это примерно 30 мл жидкости на 1 кг веса. Также мама может пить чай, кофе. Исследования говорят о том, что если женщина не превышает дозу более 3 чашек в сутки и не употребляет другие кофеиносодержащие напитки, то, как правило, это не влияет негативно на поведение ребенка.  

Секреты сбалансированного питания

Когда мы говорим о сбалансированной диете, мы предполагаем, что это, по сути, тарелка здорового питания, которая была разработана учеными в Гарварде еще в 2014 году. Она представляет собой четырехкомпонентный состав, то есть в нашем суточном объеме питания обязательно должны присутствовать фрукты и овощи порядка 400 г в сутки, а также цельнозерновые крупы и полезные белки (мясо либо рыба). Очень важно, чтобы в этой тарелке не было продуктов мясопереработки. Также к этой тарелке здорового питания мы относим употребление растительных масел, богатых омега-3 и омега-6 жирами, и достаточное поступление воды.

${tb4c1_9}

Также отмечу, что количество некоторых питательных элементов в грудном молоке зависит от питания кормящей мамы. 

Витамины группы В можно найти в цельнозерновых продуктах, бобовых, орехах, мясе, молочной продукции, зеленых овощах, рыбе и печени.
Количество витамина D зависит от продуктов, которые употребляет кормящая мама. И, конечно, важно и то, сколько мама находится на солнце, поэтому не забывайте о прогулках. 
Витамин А содержится в больших количествах в оранжевых и зеленых овощах, а также в печени и в рыбьем жире. 
Йод – это в первую очередь йодированная соль. 
И селен, который содержится в мясе, рыбе и зернах злаков. 
А вот такие микроэлементы, как цинк, железо и кальций присутствуют в грудном молоке в достаточном количестве и не зависят от материнского питания. Поэтому основная позиция Всемирной организации здравоохранения в вопросе материнской диеты после родов – это сбалансированная, разнообразная, простая здоровая пища. 

Поливитамины в период кормления грудью не требуются, если состав пищи женщины не обеднен белками и другими полезными веществами. Если будущая мама – строгий веган, то ей желательно добавлять в свое меню добавки витамина В12. Для женщин, которые не употребляют молочные продукты, – кальций. А при анемии у женщины – железо. Также можно добавлять дополнительно йод в регионах с дефицитом этого элемента. Если у мамы есть некоторые ограничения по выходу на улицу или в случае, если она активно использует солнцезащитные кремы, носит закрытую одежду – дополнительно важен витамин D.

Как видите, нет никаких ограничений по употреблению каких-то определенных продуктов. 

Миф 3. Не рекомендуется есть разноцветные фрукты и овощи

Кормящей маме можно есть и красные, и желтые, и зеленые, и разнообразные овощи и фрукты. Также нет никаких исследований, которые бы доказали напрямую взаимосвязь употребления огурцов, капусты, бобовых с повышенным газообразованием у ребенка. Также нет доказательной базы о том, что употребление пряностей (например, лука или чеснока) влияет на вкус молока, и ребенок может отказаться от груди.

Питание и аллергия у ребенка

Как еда попадает в грудное молоко и как она влияет на состав грудного молока? Дело в том, что грудное молоко делается из элементов плазмы крови. И некоторые чужеродные белки могут попадать в грудное молоко через кровь. И, к сожалению, некоторые из этих белков могут вызывать аллергическую реакцию у детей, которые склонны к аллергии или имеют к ней наследственную предрасположенность. 

К этой группе риска относится, в первую очередь, белок коровьего молока, а также другие чужеродные белки животного происхождения (куриный белок, рыба, морепродукты), а также такие растительные белки, как соя, глютен, орехи. 

Однако здесь важно понимать такую вещь, что, прежде чем случается непосредственно аллергическая реакция у ребенка, происходит встреча организма с этим белком. И возникает повышенная чувствительность к этому белку. Однако нужно, чтобы прошло некоторое время, прежде чем разовьется аллергическая реакция.

Первые проявления аллергических реакций (например, высыпания либо беспокойства со стороны желудочно-кишечного тракта) проявляются ближе к 2-3 месяцам. До этого чаще всего можно наблюдать физиологические высыпания, их еще называют акне новорожденных. Они представляют собой мелкие красные прыщики с белой головкой, которые могут обсыпать верхнюю часть тела и прежде всего лицо. 

Происхождение этих высыпаний гормональное. Оно связано с перестройкой детского организма после родов и влиянием материнских гормонов. В этом случае никаких действий по ограничению в питании кормящей мамы совершать не нужно. 

Если все-таки это истинные аллергические проявления и они связаны с употреблением определенных продуктов, определенных чужеродных белков, то в этом случае мама может попробовать вести так называемый пищевой дневник. Это значит, что мама записывает все, что ест и пьет, включая медицинские препараты, в течение дня. И если она действительно увидит закономерное ухудшение состояния кожи либо поведения ребенка после употребления тех же молочных продуктов, то рекомендуется ограничить их на 1-2 недели или проследить, какая будет реакция у ребенка. Если ребенку становится легче, значит, скорее всего, на какое-то время (например, на один месяц) стоит ввести ограничение и заменить эти продукты на другие. 

В это время советуем продолжать кормить грудью. Аллергическая реакция – это не повод переходить на гипоаллергенное детское питание, потому что в грудном молоке содержится очень много факторов, которые снижают аллергическую чувствительность к чужеродным для детского организма белкам и профилактируют воспалительные проявления со стороны кишечника и кожи.  

Очень важно маме найти педиатра или аллерголога, дружественных к грудному вскармливанию. Они будут подсказывать, какие профилактические или лечебные меры могут улучшить состояние ребенка без отлучения его от груди.

Жирность грудного молока

Часто мамы переживают, что их грудное молоко может быть непитательным и нежирным, пытаются налегать на жирную сметану, сливки, орехи. И это часто, к сожалению, провоцирует застои в молочной железе. 

Исследования показали, что мамино питание не влияет на общую жирность грудного молока, но может влиять именно на состав жиров грудного молока. Состав жиров – это полезные полиненасыщенные жирные кислоты. И они увеличиваются в грудном молоке, если мама употребляет много продуктов, богатых этими полиненасыщенными жирными кислотами (например, растительные масла, орехи или рыба). 

Помните, что важная задача мамы – прежде всего проявлять заботу о своем питании и здоровье. Только в этом случае можно рассчитывать на то, что малыш будет получать все полезное и необходимое без ущерба для вашего самочувствия, красоты и положительного опыта в родительстве и материнстве.

${separator}

Урок 7. Аксессуары для грудного вскармливания

Грудное вскармливание – это естественный процесс. Как правило, если маме комфортно кормить, ребенок хорошо сосет грудь, то дополнительно никаких аксессуаров не требуется. Обсудим те аксессуары, которые будут полезны для всех мам.

Удобный бюстгальтер 
Лучше всего, чтобы выбор пал на бесшовный бюстгальтер из микрофибры, который не будет стеснять грудь и обеспечит удобное как ношение, так и кормление.

Специальная удобная одежда для кормления с потайными вырезами для груди
Это делает кормление комфортным и в то же время незаметным для окружающих. Это позволяет маме кормить не только дома, но и за его пределами.

Дополнительные подушки
Они помогут удобно расположиться на время кормления так, чтобы не напрягалась спина, руки и ноги. Специальные подушки для кормления нужны не для всех кормящих женщин, а только в определенных ситуациях:

когда у женщины большая грудь
мамам с двойней
когда ребенок достаточно тяжелый и беспокойный
если у мамы есть некоторые ограничения в опорно-двигательном аппарате
если у есть мамы проблемы со спиной
если происходит заживление шва после операции кесарева сечения
В этом случае может помочь специальная подушка для кормления, которая позволяет ребенка удобно держать. 

Молокоотсос
Когда он может пригодиться? Если нужно увеличить выработку молока, если мама разлучена с ребенком, если мама регулярно уходит из дома (например, на работу или учебу). А также молокоотсос помогает при нагрубании, при застоях молока в груди и когда необходим докорм сцеженным молоком. А также  когда мама хочет быть донором грудного молока или хочет кормить исключительно сцеженным молоком. 

Итак, давайте разберемся, какие молокоотсосы бывают и какие считаются наиболее эффективными. Молокоотсосы делятся на: 

Механические (или ручные). Они приводятся в действие за счет силы рук. Там находится специальный поршень или рычаг, который создает отрицательное давление. 
Электрические. В нем все делает сам электромотор. 
Также молокоотсосы подразделяются на:

Однофазные
Двухфазные. Двухфазный молокоотсос предполагает, что можно переключать режимы между стимуляцией и экспрессией грудного молока, что очень важно для качественного и эффективного сцеживания. Режим стимуляции имитирует быстрое сосание ребенка в начале кормления, а режим экспрессии – более размеренное сосание во время прилива молока. 
Также существуют: 

Одинарные молокоотсосы. 
Двойные молокоотсосы. Он позволяет сразу сцеживать две груди. Это более оптимально, экономит время и силы кормящей мамы.
Существует также разделение молокоотсосов на: 

Госпитальный (или клинический). Он должен находиться в больницах, в роддомах, в послеродовых отделениях. Спросите об этом обязательно у медперсонала. 
Молокоотсос индивидуального пользования. Он предназначен непосредственно для использования одной женщиной.
Самым эффективным считается, конечно, клинический молокоотсос. Но также хорошо себя показывает двойной электрический молокоотсос для индивидуального пользования. Советую избегать грушевых, а также цилиндровых молокоотсосов, потому что они часто травмируют грудь и не являются эффективными.

Очень важна в молокоотсосе такая деталь как воронка. Воронка может быть разных размеров. Очень хорошо, если диаметр ее узкой части подходит под размер вашего соска. В стандартных молокоотсосах чаще всего встречается воронка 24 мм. Однако если вам становится больно или молоко через молокоотсос идет неэффективно, значит, нужно поменять размер воронки. У некоторых производителей молокоотсосов она может продаваться дополнительно.

Есть миф, что молокоотсос не может поддерживать необходимую выработку молока, если необходимо длительное сцеживание.  Однако это не так. Если молокоотсос хороший и электрический, то он может эффективно использоваться достаточно долго и поддерживать хорошую выработку молока. 

Как хранить и использовать сцеженное молоко? 

Оно хранится либо в специальных пакетах для сцеженного молока, либо в бутылках, баночках из пищевого пластика либо стекла. 

Вне холодильника при температуре 25 °С молоко может храниться  3-4 часа
В холодильнике – 4-5 дней у задней стенки
В морозильной камере – до 3-6 месяцев
Очень важно не подогревать молоко в микроволновой печи либо на плите. В таком случае молоко может разогреться неравномерно, может снизиться питательная ценность, а также некоторые элементы и витамины могут разрушиться. Поэтому лучше всего подогревать сцеженное молоко на водяной бане. 

Также есть специальные молокосборники, которые предназначены для пассивного сцеживания. Что это значит? Это значит, что их кладут в бюстгальтер и носят между кормлениями. Ведь молоко может периодически самопроизвольно подтекать из груди, это нормально. И с помощью молокосборников его можно собирать и использовать для кормления малыша. Если собирать молоко необязательно маме, то для профилактики подтекания молока можно вкладывать в бюстгальтер специальные одноразовые лактационные вкладыши.  

Накладки для сосков
Они бывают очень разные: латексные, силиконовые. Советую мамам использовать именно силиконовые, так как на латекс может возникнуть аллергия. 

Также накладки отличаются друг от друга формой, размером и диаметром. Рекомендуется подобрать накладку под размер непосредственно диаметра вашего соска. Почему это важно? Дело в том, что накладка может быть достаточно большая и подходящая для вашего крупного соска, но не подходящая под физиологические параметры ребенка.

Как надеть накладку? Ее необходимо немного вывернуть, чтобы она стала похожей на шляпу сомбреро – и приложить ее плотно к груди. Далее мы расправляем края накладки, и сосок оказывается внутри. Очень важно, чтобы расстояние между соском и кончиком накладки было хотя бы 1 см. Это важно, для того чтобы сосок мог двигаться в накладке во время кормления. Дальше мы должны приложить ребенка очень плотно к груди так, чтобы он правильно взял накладку. 

В каких случаях можно использовать накладку? 

когда у мамы втянутые, неэластичные либо плоские соски; 
когда ребенок очень слабо и дезорганизованно сосет; 
когда это недоношенные дети, детки с неврологическими проблемами;
когда у ребенка есть определенные челюстно-лицевые особенности (например, короткая уздечка либо высокое небо);
когда у мамы болезненное прикладывание;
когда накладки используются для кормления детей у ВИЧ-положительных мам.
Если используется накладка, маме очень важно контролировать прибавки в весе у малыша. Конечно, помним о том, что такой аксессуар важно правильно мыть и использовать. Как понять, что накладка используется правильно? Молоко должно оставаться в накладке, после того как малыш отпустит грудь, мама слышит, что ребенок глотает, и сама не испытывает болезненных ощущений.

Использовать накладки весь период грудного вскармливания рекомендуется в очень редких ситуациях. Как правило, к их помощи прибегают в первые 14-33 дня после родов. Затем лучше их убирать. Как это сделать? Мама начинает кормить в накладке, а затем, по мере того как ребенок присасывается к ней, пробует ее снять. 

Детские весы
Отлично, если это специальные детские, установленные по уровню и стоящие на одном и том же месте весы. Лучше проверить их на точность, и обязательно проверить батарейки. 

Пустышка – это частый аксессуар, который, к сожалению, несет риски для грудного вскармливания. Исследования доказывают, что при регулярном использовании пустышки продолжительность грудного вскармливания снижается в 3 раза. Особенно если начать ее использовать до 4 недель жизни ребенка. Также она может повышать риск появления отитов, проблем с прикусом и впоследствии с речью. Помним о том, что соска может быть переносчиком инфекции. Именно поэтому Всемирная организация здравоохранения не рекомендует использовать пустышки при грудном вскармливании, особенно в 1-й месяц после родов. 

В каких ситуациях пустышка может помочь? Использовать пустышки рекомендуется: 

при длительной разлуке ребенка с мамой
при различных болезненных манипуляциях
если ребенок недоношенный, находится на зондовом питании
для стимуляции тренировки его сосательного рефлекса
Старайтесь давать пустышку только тогда, когда она действительно требуется ребенку. А по мере взросления малыша используйте разные способы для его успокоения. Отвлекайте его на игрушки по возрасту и, конечно, чаще контактируйте с ним и обнимайте малыша.

${tb4c1_10}

Урок 8. Организация ГВ в первые 3 месяца жизни малыша

Как только ребенок родился, очень важно приложить его к груди и обеспечить контакт кожа к коже, выложить малыша на грудь и позволить ему самому найти сосок. После того как малыш осуществил свое первое прикладывание к груди, он засыпает. В это время его можно одеть или запеленать, а маму перевести в послеродовое отделение.

Очень важно, чтобы мама с ребенком оказались там вместе как можно раньше. Если это невозможно по каким-то показаниям (например, ребенок нуждается в интенсивной терапии либо мама лежит в реанимации долго после кесарева сечения), то очень важно уже через 6 часов после родов начать регулярно стимулировать  одну и вторую грудь по очереди по 15 минут каждые 3 часа. 

Даже сцеживание первого молока, первых капель молозива даст организму запрос на выработку молока, на более быстрый приход молока и более быстрое сокращение матки после родов. 

Дорогие мамы, хочу напомнить, что первые несколько дней ребенок питается только молозивом. Оно насыщено иммуноглобулинами и другими защитными факторами. Желудок ребенка при рождении составляет всего 7-10 мл, поэтому ему не нужно обильное питание. Но рекомендую вам следить за тем, чтобы малыш сосал грудь хотя бы каждые 2-3 часа днем и каждые 3-4 часа ночью. То есть в сумме чтобы у вас было не менее 8 кормлений в сутки. Также важно, чтобы  ребенок получал грудь по первому требованию. 

Как ребенок дает понять, что хочет есть?

Ведь малыш еще совсем не умеет разговаривать, а может только плакать и строить разные смешные рожицы. 

Первая стадия такого желания – это как раз самое время приложить его к груди. Ребенок еще не плачет, он просто облизывает губы, поворачивает голову из стороны в сторону и причмокивает. Если мама по каким-то причинам проигнорировала эту стадию, то малыш переходит ко второй стадии. 
Она называется «терпение на исходе». Малыш начинает очень активно суетиться, извиваться, пыхтеть и еще с большим рвением засовывает ручки в рот. Эта стадия может длиться от одной до нескольких минут. Если мама ее пропустила и решила, что ребенок пока не голоден, а просто играется, то ребенок переходит к третьей стадии, назовем ее условно «терпение лопнуло». 
Малыш начинает уже интенсивно краснеть, кричать и плакать. Вот уже на этой третьей стадии приложить ребенка просто к груди не получится. В этом случае я рекомендую его вначале успокоить, и только потом он сможет приложиться к груди.
Важный совет: старайтесь своим материнским взглядом оценивать, что ребенок сосет грудь эффективно. Как это понять? Вы увидите, что малыш не только сосет и спит на самой груди, но также слышатся активные глотки. 

Как понять, хватает ребенку молока или нет? В первую очередь важно, чтобы он не потерял более 10% веса от своего рождения в первые дни после родов, и набрал свой вес от рождения к 2 неделям. Далее набор веса происходит в среднем по 200 г в неделю или 20-30 г в день. Таковы рекомендации Всемирной организации здравоохранения по развитию в первые месяцы жизни. Лишний раз не переживайте и спокойно продолжайте грудное вскармливание.

Кормление по требованию предполагает, что не только ребенок требует, но и мама может кормить малыша тогда, когда это необходимо по своему требованию. Например, это может быть ситуация, когда ребенок спит слишком долго. Таких детей, как мы уже обсуждали ранее, называют голодными счастливцами. Они могут спать по 3-6 часов и никак не проявлять своего желания пососать грудь.

Если ваш малыш спит более 2-3 часов от момента откладывания после кормления в кроватку, то его рекомендуется разбудить и приложить к груди по своему требованию, чтобы он не проспал свое кормление. 

Ночные кормления

Почему это важно? Ночные кормления создают выработку молока на следующий день, ведь самые высокие пики пролактина у мам именно ночью. Пролактин – это гормон, который отвечает за выработку молока. Поэтому очень советую прикладывать ребенка хотя бы 2-3 раза ночью. Например, в 12 часов ночи, затем в 3-4 утра и уже далее в районе 6 часов утра. Если ребенок просит грудь чаще, естественно, отказывать не нужно.

Очень многих волнует вопрос чередования груди. Как часто нужно чередовать грудь, чтобы ребенок добрался до заднего жирного молока? К сожалению, страх того, что ребенок не доберется до этого жирного молока часто приводит к необоснованному длительному дежурству одной груди. Это значит, что мама не меняет грудь более 1 часа, что может снизить количество молока, которое может высосать ребенок. 

Рекомендация «длительного дежурства одной груди» (когда грудь не меняется более 1-2-3 часов) распространяется только на ситуации гиперлактации, когда у мам очень много молока. И в этой ситуации мы советуем возле одной груди держать ребенка подольше, чтобы он добрался до этого необходимого заднего молока. 

В обычной ситуации при достаточном количестве молока при нормальной выработке, можно говорить о том, что хорошо сосущий малыш первого месяца жизни опорожняет грудь за 15-30 минут. Есть и такие, которые высасывают грудь за 5-10 минут. И в этом случае уже через 15-30 минут можно поменять грудь, если мама видит, что ребенок хочет еще.

Если малыш сосет вяло, быстро засыпает на груди, то тем более очень важно успеть дать ему две груди. Тогда одна грудь дежурит у мамы не более 15 минут, при этом мама может активно сжимать грудь, чтобы дать малышу больше молока. А еще через 15 минут лучше переместить его на другую грудь. Таким образом ребенок получит больше молока.

Режим ребенка на ГВ

Как такового режима у детей первых месяцев жизни нет, он очень хаотичен. Это связано с недостатком выработки мелатонина (гормона сна). И бывает так, что ребенок достаточно хорошо спит днем, но часто просыпается и беспокойно ведет себя по ночам. Так или иначе все дети к 1-2-му месяцу жизни вырабатывают свой собственный ритм, то есть смену сна и бодрствования ребенка. Эти характеристики индивидуальны. Одним достаточно пободрствовать полчаса и поспать 30-40 минут, а другой ребенок будет бодрствовать чуть больше, и спать он будет тоже в районе 1,5-2 часов.

Еще одна распространенная ошибка мам – сравнивать режим дня ребенка на грудном вскармливании и на искусственном. Ребенок на искусственном вскармливании, скорее всего, будет спать дольше и делать больше промежутки между кормлениями. Это связано с особенностями состава смесей и с объемом кормления смесью.

При грудном вскармливании ребенок может сосать грудь достаточно часто. Почему так происходит? Это связано с тем, что ребенок сосет грудь не только, чтобы поесть, но и чтобы успокоиться или заснуть. Основное важное правило организации грудного вскармливания первых 3 месяцев – необходимость ребенка в сосании груди на засыпание и на просыпание. Именно сосание груди во время засыпания нормализует деятельность желудочно-кишечного тракта ребенка и ускоряет выработку необходимых гормонов и ферментов для переваривания грудного молока.

Когда дети просыпаются, они, как правило, сразу же хотят сосать мамину грудь, а уже потом менять подгузник и делать другие необходимые ритуалы. Поэтому первые 3 месяца жизни грудное вскармливание выглядит следующим образом: ребенок проснулся, пососал грудь, потом происходит смена подгузника, после этого малыш может некоторое время пободрствовать, можно сделать ему массаж, гимнастику, просто поговорить с ним. 

Дальше, как правило, через 30-60 минут малыш начинает уже зевать, засовывать свои кулачки в рот – так он подает сигнал, что хочет снова сосать. Его можно приложить к груди, он отлично засыпает.

Часто встречается такой страх, что ребенок недоедает, потому что очень быстро после засыпания при откладывании снова просыпается. Но бояться этого не стоит. Если ребенок хорошо набирает вес, нужно понимать, что дети требуют много физического контакта в первые месяцы жизни. Это необходимые условия их развития. Поэтому не бойтесь приучать ребенка к рукам. Это не прихоть, а необходимая часть  их гармоничного развития.

${separator}

Урок 9. Организация ГВ после 3 месяцев

К 3 месяцам жизни дети и мамы меняются.

Проходят колики и периоды сильных беспокойств.
Мамы становятся более уверенными в себе. Они уже хорошо знают ребенка и понимают, когда он хочет грудь, когда он хочет играть, когда он хочет спать. 
Проходят дискофмортные ощущения, связанные с первыми прикладываниями к груди. 
У мамы нормализуется гормональный фон, уходят приливы. 
У большинства женщин грудь становится более мягкая, спокойная, заживают соски, если ранее наблюдались трещины. 
Пропадают сильные позывы к жажде и обостренное чувство голода. 
К этому моменту организм после родов уже пришел в себя, мама уже может выбирать больше поз для кормления. Однако на данном этапе может беспокоить женщину, что грудь становится более мягкая и приспосабливается под запросы ребенка. Некоторые мамы это воспринимают как признак лактационного криза. Дорогие мамы, не переживайте, если малыш продолжает хорошо набирать вес, то это говорит о том, что лактация окончательно установилась, и нет причин волноваться.

Как меняется ситуация после 3 месяцев ГВ? 

Меняется режим кормлений
Детям уже все интересно вокруг: они начинают тактильно изучать свои ноги, руки, тело. Возрастает их моторная активность. И кормление грудью тоже меняется. Давайте разбираться, как  это происходит.

Может поменяться поза для кормления
Например, если в 1-й месяц вы чаще кормили ребенка лежа или в расслабленной позиции, то, возможно, сейчас вы будете предпочитать именно сидячее положение. 

Может сократиться в несколько раз продолжительность кормления 
Это то время, которое ждут мамы, у которых дети сосут долго в первые месяцы жизни. Других это может, наоборот, напугать, натолкнуть на вопросы. А наедается ли малыш за такое короткое время, получает ли он все необходимое молоко за 2-3 минуты кормления? Не бойтесь, ребенок в этом возрасте не оставит себя голодным. Такими короткими промежутками он может прикладываться несколько раз в течение всего бодрствования. Кстати, время бодрствования увеличивается, и может достигать 2-3 часов.

Отмечу важную особенность. Дети компенсируют эти короткие прикладывания более длительными кормлениями на дневной и ночной сон. Советую продолжать кормить малыша по требованию. Ребенок будет сам регулировать частоту и режим прикладываний. 

! До 6 месяцев мы не допаиваем ребенка водой. 

В грудном молоке ее достаточно. Даже в жаркую погоду Всемирная организация здравоохранения не рекомендует допаивать детей на грудном вскармливании. Точно так же лучше поступать и с прикормом – вводить его до 6 месяцев не рекомендуется. 

В районе полугода у детей начинают активно расти зубы. Это может влиять как на само прикладывание, так и на частоту ночных просыпаний. Относитесь к этому с пониманием. Помните, что эти более частые, возросшие кормления, беспокойства носят временный характер. И это связано действительно с тем, что ребенку дискомфортно, ведь у него режутся зубки.

В 6 месяцев можно начинать дополнять молоко энергетически питательными продуктами. В первую очередь Всемирная организация здравоохранения рекомендует уделить внимание продуктам, богатым железом, витамином А и белком. Поэтому, дорогие мамы, добавляйте в свой рацион мясо, рыбу, орехи, молочные продукты, злаковые, овощи и фрукты.

Важно понимать, что при грудном вскармливании до года молоко остается основным источником питания ребенка. А продукты прикорма лишь дополняют его. Что это значит? Что прежде чем покормить малыша кашей или фруктовым пюре, лучше предложить ему грудь. А уже после дать ему возможность попробовать другие продукты в том объеме, в котором он хочет.

После года, наоборот, грудное молоко отходит на второй план, а основным источником питания энергии становится прикорм, который ребенок ест в большем объеме.

${separator}

Урок 10. Когда завершать ГВ? Правила мягкого отлучения от груди

Грудное вскармливание порой не заканчивается, когда ребенку исполняется 12 месяцев. Исследования ученых доказали, что ценность грудного молока не снижается после года. Хотя мы уже говорим о том, что после года грудное вскармливание не является основным источником питания ребенка. Ведь на первый план выходит прикорм. И малыш начинает есть большое количество продуктов с общего семейного стола. 

Грудное вскармливание: 

помогает передать малышу иммуноглобулины
успокаивает малыша
помогает ребенку снижать риски респираторных и желудочно-кишечных инфекций
Поэтому Всемирная организация здравоохранения рекомендует сохранять грудное вскармливание из-за снижения этих рисков хотя бы до 2 лет. Далее грудное вскармливание может продолжаться по желанию матери и ребенка столько, сколько они хотят.

Организация ГВ подросшего ребенка

Основное его основное питание – это прикорм. Соответственно, кормления грудью становятся менее значимыми, более редкими и короткими. Ночью ребенок также может просить грудь. Если мама по каким-то причинам решает завершить грудное вскармливание раньше двух лет, то очень важно понимать, что нужно делать это по возможности постепенно. 

Сначала, как правило, ребенку легче отказаться от утренних кормлений. 
Мы их можем заменить другой едой и отвлекать ребенка другими делами или играми. На это обычно уходит от нескольких дней до нескольких недель. Это зависит от потребностей ребенка, его темперамента, особенностей его питания и здоровья.

Когда ребенок уже не просит грудь на дневные кормления, то лучше начать пробовать не давать ему грудь на дневной сон. 
Обычно к этому возрасту (т.е. после года) у ребенка остается один дневной сон. И здесь формируется другой ритуал на засыпания. Это может быть колыбельная, песенка, массаж, покачивания и поглаживания. Как правило, детям требуется несколько дней, чтобы привыкнуть к этому новому ритуалу и обходиться уже  без груди.

Когда ребенок уже научился засыпать днем без груди, то маме рекомендуется начать формировать идентичный ритуал на вечернее время. 
И самыми последними уходят ночные кормления. 
Обычно в утреннее и дневное время  деток легко успокоить, отвлечь их внимание на какие-то другие игры или еду. В возрасте после полугода дети просыпаются ночью не столько оттого, что голодны, а потому что у них еще незрелая нервная система. И поэтому они пробуждаются и хотят пососать грудь. Отказ от уже сформированного обычая дается ребенку не с первого раза. 

Помните, что каждый малыш, каждая мама индивидуальны. Вы можете остановиться на любом из этих шагов. Например, убрав только дневные кормления, и оставить кормления на засыпания днем и ночью. Вы можете убрать все дневные кормления и оставить все ночные. Все зависит от ваших целей и поведения малыша. 

Даже ничего не предпринимая, ребенок может отказаться от грудного вскармливания самостоятельно. Когда это может произойти? Например, когда его нервная система становится более зрелой и устойчивой. Обычно это происходит после 2 лет. В этот период ребенок легче может отказаться от грудного вскармливания, чем до этого возраста. К 2 годам уже завершается прорезывание всех молочных зубов, а его нервная система, а также другие системы и органы развиты достаточно хорошо.

На практике случается и такое, когда мама поспешила с отлучением от груди, поддавшись на не совсем верные советы. Не готовый к резкому отлучению ребенок может заболеть или очень сильно изменить свое поведение – стать нервным и беспокойным, могут появляться такие привычки, как сосания пальцев, тики, энурез. В такой ситуации грудное вскармливание можно вернуть.

Завершение лактации

Грудное молоко не уходит из груди за один день. Это постепенный, длительный процесс. И считается, что завершение лактации длится примерно 40 дней. Поэтому в течение этого срока маме достаточно легко восстановить грудное вскармливание, даже если она резко перестала кормить ребенка грудью и приняла таблетки, подавляющие лактацию.

Хотелось бы отметить, что подобные таблетки необходимо принимать только в исключительных ситуациях и только под четким контролем врача. Мама может вернуть ребенка на грудь и продолжить дальше грудное вскармливание ровно на столько, на сколько считает нужным.

Если все же отлучение от груди произошло достаточно резко, и вы ощущаете уплотнение в груди, побаливания – их не нужно терпеть. Рекомендуется подцеживать грудь до чувства облегчения. Можно прикладывать холодные компрессы, чтобы дополнительно снимать отек и чувство боли. Если поднимается температура или болезненные ощущения слишком выражены, можно выпить болеутоляющее либо жаропонижающее средство.

Лучше не перевязывать грудь никакими тугими эластичными бинтами либо пеленками. Все это устаревшая практика. Таким образом вы можете травмировать молочные железы, и у вас могут развиться воспалительные процессы.

Организм женщины со зрелой лактацией работает определенным образом. Спрос начинает рождать предложение. И чем меньше малыш прикладывается к груди, тем реже приходит молоко. Именно поэтому вы можете постепенно уменьшать частоту и длительность кормлений. И это, как правило, приводит к самопроизвольному снижению лактации.
    `,
};
