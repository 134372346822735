import { FC } from "react";
import styles from './Menu.module.css';
import { MenuItemSettings } from "./components/MenuItemSettings/MenuItemSettings";
import { MenuItemSupport } from "./components/MenuItemSupport/MenuItemSupport";
import { MenuItemLogout } from "./components/MenuItemLogout/MenuItemLogout";


export const Menu: FC = () => {
  return (
    <div className={ styles.container }>
      <MenuItemSettings />
      <MenuItemSupport />
      <MenuItemLogout />
    </div>
  )
};
