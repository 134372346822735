import React, { Suspense } from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { store } from './store/store';
import Switch from 'navigation/Switch';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

const App = () => {
  return (
    <Provider store={store}>
      <Suspense>
        <Switch />
      </Suspense>
    </Provider>
  );
};

export default App;
