import { useMemo, useState } from 'react';
import { CourseData, RecommendationType } from 'types';

export const usePagination = (initialItemsPerPage: number) => {
  const [counter, setCounter] = useState(0);
  const itemsPerPage = initialItemsPerPage;

  const handleButton = () => {
    setCounter((prevCounter) => prevCounter + 1);
  };

  const getEndIndex = () =>
    counter > 0 ? counter * itemsPerPage : itemsPerPage;

  return {
    handleButton,
    getEndIndex,
  };
};

export const useFilteredContent = (
  content: CourseData[] | undefined,
  type: RecommendationType | null,
  endIndex: number
) => {
  return useMemo(() => {
    if (!content) return [];

    return content
      .filter((course) => (type ? course.type === type : true))
      .slice(0, endIndex);
  }, [content, type, endIndex]);
};
