import { separator, tb6c1_1, tb6c1_2, tb6c1_3, tb6c1_4 } from 'assets/images';

export const b6_c20_text = {
  b6_c1_title: `Курс «Роль будущего отца в период беременности и в родах. Партнерские роды»`,
  b6_c1_description: `Спикер: Сергей Пацеев, врач акушер-гинеколог, заместитель главного врача Городского клинического родильного дома №2 г.Минска`,
  b6_c20_text: `
    ${tb6c1_1}

    Урок 1. Смена акцентов в отношениях между партнерами с наступлением беременности

Что меняется в семье с наступлением беременности? В первую очередь, меняются роли, взаимоотношения между мужем и женой. 

С наступлением беременности у женщины меняется гормональный фон, обмен веществ, меняются условия ее жизни, меняется ее внешний вид, ее ощущение себя. Женщине необходимо больше внимания. И тут на помощь может прийти супруг. То, как женщина будет ходить всю беременность, все этих 9 месяцев, как она встретит роды, как пройдет послеродовой период, во многом зависит от того, как поддерживает ее семья, близкие, особенно муж.

За счет изменения гормонального фона в организме женщины происходит существенная перестройка. У женщин часто меняется настроение. У нее появляются необычные пристрастия: ей хочется то сладкого, то кислого. Будет здорово, если мужчина придет на помощь женщине. И поможет ей достичь комфортного состояния.

Мы все играем роли – роли в семье, роли в обществе. И с наступлением беременности акценты должны сместиться. И на первое место (в плане человека, который нуждается в помощи) вступает женщина. Женщина, которая носит вашего ребенка.

В чем заключается помощь? Это, во-первых, физическая помощь. Помогайте, готовьте, убирайте, стирайте, гуляйте, выносите мусор – это отлично поможет вашей любой. И будет здорово, если и после родов часть обязанностей перейдет вам.

Это эмоциональная поддержка. За счет действия гормональных изменений, которые происходят во время беременности, меняется гормональный фон, меняется психологическое состояние беременной. И эти изменения требуют поддержки. Поддержка заключается во всеобъемлющей теплоте и ласке, которой нужно окружить женщину. 

Помните: ваша помощь – неоценима. То, как вы сейчас относитесь к вашей жене, скажется на том, как ваш ребенок впоследствии будет относиться в том числе и к вам. Если у вас есть какие-либо недопонимания – вы можете обратиться к психологу, можете сходить парой.

Также могут возникнуть проблемы взаимоотношения со старшими детьми. Ведь все внимание женщина может уделять своему состоянию беременности. Возьмите на себя часть заботы о детях – помогайте, водите в садик, водите в школу, делайте уроки.

Во время беременности нужно меняться не только женщине, но и партнеру. Меняться и всецело помогать жене вынашивать беременность. И не забывайте, что у вас одна цель – это здоровый малыш.

${separator}

Урок 2. Партнерские роды. Возможности учреждений здравоохранения

Если говорить о давних временах, разные племена по-разному относились к партнерским родам и участию родни или мужа в родах. Были племена, в которых беременная женщина, вступающая в роды, изгонялась до того, как она родит, из племени, и возвращалась уже с ребенком. Были племена, где мужчина (муж) сам принимал роды у своей жены. Но чаще всего были те племена, где женщина рожала, а соплеменники могли находиться рядом, могли присутствовать на родах, но помощь не оказывали. Впоследствии появились люди (чаще женщины), которые потом стали называться повитухами (это люди, которые более-менее разбирались в родовом процессе). 

Что касается Древней Руси, то мужчины редко когда присутствовали на родах, чаще всего они находились где-нибудь поблизости. Если роды осложнялись или затягивались – да, мужья приглашались, и тогда роды велись в том числе в присутствии мужа, зачастую даже на коленях мужа. И уже с тех пор эта помощь считалась очень эффективной! Если у женщины были осложнения в родах, мужу предписывали очень усердно и длительно молиться, чтобы роды завершились успешно.

А если говорить о современных партнерских родах, то особенности ведения партнерских родов были оговорены Всемирной организацией здравоохранения еще в 1985 году. Были заложены основы – и впоследствии  они распространялись по странам и совершенствовались, в зависимости от тех национальных особенностей и традиций, которые существуют в каждом обществе.

Хотел бы поговорить о тех возможностях, которые предоставляют родовспомогательные учреждения нашей страны для проведения партнерских родов.

Сегодня во многих роддомах созданы условия для совместного пребывания партнеров. В этом случае в роддомах создаются отдельные палаты, где имеется все необходимое для постоянного пребывания партнеров. Партнером может быть не только муж, может быть мама, сестра, подруга или любой человек по желанию женщины, с кем ей комфортно быть в учреждении здравоохранения.

Итак, обсудим партнерские роды с первого этапа. Есть два варианта развития событий:

Женщина поступает в роддом сразу с мужем (с началом родовой деятельности)
Или, если пациентка находится в отделении патологии беременности (ее туда направил участковый гинеколог), партнер по телефону приглашается на роды. Он обращается в приемное отделение, ему выдается определенное белье, для того чтобы можно было переодеться, и после этого провожается в предродовую палату. 
Предродовая палата чаще всего тоже представляет собой отдельное помещение, где созданы условия для нахождения партнеров в родах. Здесь находится кровать, где женщина проводит первый период родов. Если создано условие для родоразрешения в этом же помещении – значит, здесь еще находится кровать для приема родов (родовое кресло) и находится все необходимое оборудование для приема малыша и для оказания помощи родильнице.

Коллектив любого роддома, где проводятся партнерские роды, настроен на доброжелательное отношение, на помощь, на объяснение ситуаций, которые происходят с беременной, для того чтобы как можно удобнее и комфортнее организовать помощь будущей маме.

Кто будет всегда рядом с вами? Для проведения партнерских родов выделяются самые опытные врачи. Это врачи первой и высшей категорий. Если вы рожаете в дневное время, то, безусловно, скорее всего, вам будет оказывать помощь заведующий родовым отделением. Возможно, будут приглашены на консультацию сотрудники кафедры, ассистенты, доценты, возможно, будут приглашаться на консилиум администрация учреждения, заместители главных врачей. Безусловно, рядом с вами всегда будут акушерки, которые помогут, будут принимать роды, а также окружат вас заботой, теплотой и вниманием. Ваши постоянные помощники – санитарки. Они тоже сделают все, чтобы ваше пребывание было комфортным. И конечно, персонал роддома всегда ответит на вопросы мам и партнеров.

${tb6c1_2}

Урок 3. Совместное пребывание и помощь партнера в роддоме

Чем же партнер может помочь в роддоме? Сначала поговорим о пребывании во время беременности и пребывании в родах. 

Конечно, это помощь физическая. Ваш партнер может сходить к медицинскому персоналу, обратиться, задать вопрос, позвать акушерку. Может принести вам необходимые вещи, чтобы пребывание в палате было комфортным. Партнер может помочь вам встать, может сделать массаж, может отвести вас в душ, может помочь сходить в туалет.

И конечно, самое важное – это эмоциональная поддержка. Постоянное нахождение с человеком, которого вы знаете, которому вы доверяете, безусловно, сделает ваше пребывание комфортным, более спокойным. 

Если говорить об участии партнера в разных периодах родов, то большое значение имеет то, как партнер будет вести себя в родах. Именно для этого важно подготовиться, пройти курсы. И уже в каждом периоде родов партнер всегда может вспомнить то, что вы можете забыть ввиду того, что вам больно. Он может напомнить вам о правильном дыхании. Может помочь куда-то пройти, сесть, выбрать удобную позу, поменять положение тела, погладить ваш животик, погладить вашу поясницу, размять крестец, размять мышцы, которые затекли.

Нужно помнить, что медицинский персонал, к сожалению, не всегда будет рядом с вами. Особенно это касается того периода, когда еще не идет быстрое раскрытие шейки матки. И если у вас возникают вопросы, партнер может подойти к медицинскому персоналу, узнать все необходимое, принести вам все, что нужно.

На этапе, когда у женщины начинаются сильные схватки, основная задача партнера – просто быть рядом с вами, утешать вас, поддерживать, говорить добрые, ласковые, приятные и теплые слова.

Хочу отметить, что совместное пребывание в родах не улучшит ваши взаимоотношения, если они были каким-то образом нарушены до родов. Если вы пытаетесь таким образом улучшить ваши взаимоотношения – это не всегда лучший вариант. 

Итак, наступает самый важный, самый ответственный период в родах – это период рождения ребенка, период изгнания. Советуем вам заранее обсудить с партнером, будет ли он находиться в родильном зале в этот момент. Он может стоять рядом с вами в изголовье, будет поддерживать, гладить вас по голове. Будет помогать объяснять, что вы должны делать, и служить передаточным звеном между медицинским персоналом и вами. Он будет переводить на понятный для вас язык то, что от вас просят; то, что требует медицинский персонал, для того чтобы роды прошли хорошо и комфортно.

Если ваш партнер не присутствует в родзале, то его могут пригласить в родзал после рождения малыша, чтобы перерезать пуповину (при желании мамы и партнера).

После рождения малыша медицинский персонал остается с мамой, а чаще всего папу приглашают в комнату, где проводят первичную обработку новорожденного. И там папа (или партнер) может взять ребенка на руки и побыть вместе с ним, установить первую и очень важную связь с малышом.

${separator}

Урок 4. Кто может быть партнером в родах?

Партнерские роды – это проведение родов, родового процесса с партнером.

Кто может быть партнером? Это может быть не только муж (любимый человек), это может быть любой человек, с кем женщине удобно, комфортно, безопасно находиться в родильном доме. Это могут быть:

мама
сестра
отец
лучшая подруга
Условия для партнерских родов

Что должен сделать человек, который хочет быть партнером в родах? 

Чтобы организовать партнерские роды, необходимо прежде всего желание. Как женщины, которая рожает и которая будет переживать все психологические, и эмоциональные, и физические трудности в родах, так и того человека, который будет рядом. Важно, чтобы желание было обоюдным.

Заранее обсудите с вашим партнером (будущим партнером в родах), хочет ли он этого на самом деле или просто хочет выполнить ваше желание без психологической подготовки или готовности быть с вами и проходить вместе этот тяжелый путь. 

Здорово, если партнер будет психологически настроен и подготовлен. Ведь на нем будет лежать очень большая ответственность. Партнер может стать объектом, на который будет направлен какой-то негатив, агрессия, претензии со стороны роженицы. Ведь ту боль и то состояние, которое испытывает женщина, она попытается каким-то образом переложить на вас. И нужно быть готовым принять ситуацию, помочь и оказать теплую поддержку. 

Следующее условие – это знания. Будет здорово, если партнер будет знать, что происходит в родах – от самого начала родов, когда начинаются схватки или изливаются воды. Партнеру важно понимать, когда стоит обращаться в родильный дом, важно понимать, как помочь будущей маме с началом родовой деятельности.

Для этого во многих роддомах, в женских консультациях, в частных центрах организованы курсы партнерских родов. Всем желающим рассказывают про физиологию родового акта, про те изменения, которые происходят во время беременности, про те изменения, которые происходят в родах. На курсах помогают освоить и какие-то физические и практические навыки. Например, как сделать массаж, поглаживание, как помочь будущей маме принять удобную позу, чтобы переживать родовую боль. 

Там вас научат и правильному дыханию. И конечно, мужчина или ваш партнер в родах может следить за всеми этими процессами и вовремя подсказать или направить вас. 

Конечно, партнер должен быть готов морально и психологически. Возможно, то, что вы увидите в родзале или в предродовой может вас шокировать. Важно оценить: сможет ли партнер пережить эти эмоциональные моменты? Например, если партнер боится вида крови или пугается каких-то критических ситуаций – возможно, стоит задуматься о присутствии на родах.

Еще один важный момент – партнерские роды не должны быть моментом манипуляции. Они не должны быть инструментом, чтобы улучшить взаимоотношения в паре, чтобы спасти треснувшие отношения.

Важно, чтобы и будущая мама оценила, готова ли она видеть партнера на родах. Есть женщины, которые очень переживают о своем внешнем виде. Тогда, возможно, стоит задуматься, планировать ли партнерские роды. Ведь ни комфорта, ни возможности расслабиться такие партнерские роды не принесут.

${tb6c1_3}

Урок 5. Помощь партнера в каждом периоде родов

Итак, у вас развилась родовая деятельность, начались схватки или излились околоплодные воды. Вы вызываете скорую помощь, и вас транспортируют вместе с вашим мужем в приемный покой родильного дома.

Поскольку вы планируете партнерские роды, вы обращаетесь накануне в родильный дом, где вы планируете эти роды, и заключаете договор на проведение партнерских родов. Этот договор необходимо взять с собой.

Также у партнера с собой должна быть справка о состоянии здоровья. Ее вы берете в поликлинике по месту жительства, и она является обязательным условием для проведения партнерских родов. 

 

В приемном покое, пока проводится опрос или осмотр вашей жены, вы можете принимать участие в оформлении медицинской документации – предоставить паспорт, обменную карту. Вы можете при необходимости отвечать на вопросы медицинского персонала. 

К тому времени, когда будет готова ваша жена, вы будете уже переодеты, чтобы сопровождать вашу жену в родзал. 

Если же ваша жена уже лежит в родильном доме (у нее была дородовая госпитализация), то с началом родов вы будете приглашены в родильный дом. Вы также обращаетесь в приемный покой, где вас переоденут и проводят в родильный зал.

Вы вместе с женой отправляетесь в родильное отделение. Вам предоставляется отдельный родзал (индивидуальный родзал), где вы будете находиться до момента родоразрешения. Здесь, в зависимости от роддома, может быть несколько вариантов.

Это может быть отдельная предродовая палата, где вы будете находиться, пока ваша супруга будет переживать схватки (до второго периода родов). Потом вас переведут в родзал, где вы сможете также присутствовать на родах. А после этого вы будете находиться в палате послеродового наблюдения.
Или это может быть объединенная палата (индивидуальный родильный зал), где вы будете находиться перед родами, во время родов и после родов в течение 2 часов. Это зависит от тех условий проведения партнерских родов, которыми располагает родовспомогательное учреждение. 
Помощь в предродовой палате

Если это отдельная предродовая палата, то тут вы будете находиться до периода, когда откроется шейка матки и ребеночек сможет продвигаться по родовым путям. После чего вы будете переведены в родильный зал.

Чем вы, как партнер, можете помочь? Самое главное, что партнер будет делать,  – это находиться рядом с вами. Он будет связующим звеном между вами и медицинским персоналом. 

Вы можете попросить своего партнера обратиться к медицинскому персоналу с той просьбой, которая у вас возникла. Партнер будет говорить вам приятные слова, поддерживать. Партнеру важно говорить о том, какая вы молодец, как здорово вы переживаете ваши роды, как здорово вы переживаете ту боль, которая встречает вас. 

Вы можете использовать телесный контакт: партнер может держать вас за руку, может гладить животик, разминать плечи. Может поддерживать вас, пока вы сидите на фитболе. Вы можете обсуждать какие-то вопросы, отвлекаться, если это удобно. Партнер может принести воды или еды. Да, иногда во время родов для поддержания сил мы разрешаем что-то перекусить.

Итак, наступает момент, когда заканчивается длительный период (первый период родов) и начинается период, когда ребеночек продвигается по родовому каналу, и женщину переводят на кровать для родоразрешения. 

И здесь важно заранее обсудить, присутствует партнер в родзале в момент рождения ребенка или нет.  Сегодня эта тема широко обсуждается и даже есть мнение, что после родоразрешения не всегда восстанавливается интимная жизнь. Очень важно обсудить заранее этот вопрос вместе.

Если партнер не присутствует в родзале, то после рождения ребенка его могут пригласить перерезать пуповину. Безусловно, партнер будет присутствовать в период обработки новорожденного, в период пеленания. Ваш партнер может взять на руки ребенка – это сильное поддержание эмоционального состояния. И, как заметили психологи, у папы гораздо быстрее и прочнее возникает связь между ним и новорожденным. Ему не нужно привыкать к мысли о том, что это его ребенок. Ведь он это прошел сам и участвовал во всех периодах родов, поддерживая свою супругу.

${separator}

Урок 6. Партнерское кесарево сечение

Каким образом партнер может присутствовать на родах, если необходимо выполнение планового кесарева сечения?

Плановое кесарево сечение выполняется в том случае, если в процессе обследования в конце беременности выявлены какие-либо показания для операции и рождение ребенка через естественные родовые пути невозможно. В этом случае мы говорим о выполнении кесарева сечения в плановом порядке. И вот в этом случае можно говорить о таком понятии, как партнерское кесарево сечение.

Нахождение в операционной во время проведения операции мы не приветствуем. Партнер во время проведения планового кесарева сечения может находиться в палате, где происходит обработка новорожденного. 

Большинство операций кесарева сечения выполняется под регионарными методами обезболивания (когда женщина находиться в сознании). В таком случае ей могут выложить малыша на грудь. Но также малыша могут выложить на грудь папе (в том числе в ситуациях, когда операция выполняется под общей анестезией). Когда ребенок рождается и передается акушерке для проведения первичного туалета новорожденного, партнер может уже впервые увидеть ребенка, познакомиться с ним, подержать на руках. Безусловно, это способствует тому, чтобы между ребенком и папой (партнером) наладились тесные эмоциональные связи. Партнерское кесарево сечение – это возможность укрепить эмоциональные взаимоотношения мамы, папы и ребенка.

${tb6c1_4}

Урок 7. Помощь партнера и семьи после выписки из роддома

С возвращением мамы вместе с ребенком домой в семье появляется новый центр притяжения – это, конечно, новорожденный малыш. Новорожденные нередко приносят вместе с собой совершенное переосмысление всего. Меняется жизнь, расписание, режим дня. И иногда мама в этом случае как бы уходит на второй план. Но не стоит забывать, что мама тоже нуждается в очень большой поддержке.

Как можно помочь маме?

После родов у мамы меняется гормональный фон, обмен веществ, происходит восстановление органов и тканей после беременности. И, безусловно, женщина уже не может выдержать тех нагрузок, которые она могла выдержать до беременности. И, безусловно, ей нужно помогать.

Возьмите на себя какую-то физическую нагрузку – это может быть уборка, стирка, любая другая помощь по дому, походы в магазин. Окружите женщину заботой, вниманием. Чтобы мама всецело могла уделять внимание ребенку и своему восстановлению.

Если говорить о роли других членов семьи, то на первый план выходит помощь эмоциональная. Дедушки и бабушки тоже будут пытаться как можно большим вниманием и большей заботой окружить молодую маму. Но эта помощь не должна быть навязчивой. Не все женщины готовы к тому, чтобы оказаться круглосуточно под опекой мужа, дедушек или бабушек. Постарайтесь обсудить этот вопрос и оговорить свое участие заранее. Обсудите, хочет ли женщина отдать часть своей нагрузки по уходу за ребенком или по бытовым домашним обязанностями.

Что касается старших детей, они редко выступают помощниками (особенно если они не совсем взрослые). Чаще всего это любопытные наблюдатели. Конечно, теперь максимум внимания все-таки уделяется новорожденному ребенку. И родителям важно найти золотую середину, чтобы уделять внимание и старшим деткам. В этом могут помочь и папа, и бабушки-дедушки. 

Если говорить об отношении к новорожденному ребенку, основная нагрузка, конечно, падает на маму. Но и папа может помочь – например, поменять подгузники, сделать массаж, искупать малыша. Можно просто быть вместе с вашей женой. Создавать комфорт, уют, тепло в семье и для молодой мамы, и для новорожденного ребенка.

И, безусловно, то эмоциональное состояние, которое есть в семье, будет передаваться к ребенку. Если в семье царит взаимопонимание, уют, если в семье нет ни ссор, ни криков, ни ругани – конечно ребенку будет спокойнее, в будущем он будет брать с вас пример. И, безусловно, вы потом получите только самые положительные результаты от того, что вы объединились, от того, что вы вместе ухаживаете за ребенком. Уделяйте внимание друг другу, и пусть в вашей семье всегда будет лад, комфорт и добро.
    `,
};
