import {
  ClockIcon,
  PregnancyIcon,
  ChildIcon,
  WomenHealthIcon,
  MenHealthIcon,
  FlaskIcon,
  BananIcon,
  LampIcon,
  SignIcon,
  EmbryoIcon,
  ChildBirthIcon,
  PacifierIcon,
  CubesIcon,
  SexIcon,
} from 'assets/icons';

// **** /

export enum UsageOptions {
  PLANNING_PREGNANCY = 'planningPregnancyAndWomenHealth',
  MANAGING_PREGNANCY = 'pregnancyAndChildbirth',
  AFTER_CHILDBIRTH = 'afterChildbirth',
}

export const USAGE_OPTIONS = [
  { title: UsageOptions.PLANNING_PREGNANCY, icon: ClockIcon },
  { title: UsageOptions.MANAGING_PREGNANCY, icon: PregnancyIcon },
  { title: UsageOptions.AFTER_CHILDBIRTH, icon: ChildIcon },
];

// **** /

export enum Interests {
  WOMEN_HEALTH = 'womenHealth',
  MEN_HEALTH = 'menHealth',
  ANALYZES_AND_EXAMINATIONS = 'analyzesAndExaminations',
  NUTRITION_AND_LIFESTYLE = 'nutritionAndLifestyle',
  PSYCHOLOGY = 'psychology',
  RISK_FACTORS = 'riskFactors',
  CHILD_DEVELOPMENT_BY_WEEKS = 'childDevelopmentByWeeks',
  CHILDBIRTH_AND_RECOVERY = 'childbirthAndRecovery',
  CHILD_HEALTH = 'childHealh',
  CHILD_DEVELOPMENT = 'childDevelopment',
  SEX_AND_CONTRACEPTION = 'sexAndContraception',
}

export const INTERESTS = {
  [UsageOptions.PLANNING_PREGNANCY]: [
    { title: Interests.WOMEN_HEALTH, icon: WomenHealthIcon },
    { title: Interests.MEN_HEALTH, icon: MenHealthIcon },
    { title: Interests.ANALYZES_AND_EXAMINATIONS, icon: FlaskIcon },
    { title: Interests.NUTRITION_AND_LIFESTYLE, icon: BananIcon },
    { title: Interests.PSYCHOLOGY, icon: LampIcon },
    { title: Interests.RISK_FACTORS, icon: SignIcon },
  ],
  [UsageOptions.MANAGING_PREGNANCY]: [
    { title: Interests.WOMEN_HEALTH, icon: WomenHealthIcon },
    { title: Interests.CHILD_DEVELOPMENT_BY_WEEKS, icon: EmbryoIcon },
    { title: Interests.ANALYZES_AND_EXAMINATIONS, icon: FlaskIcon },
    { title: Interests.NUTRITION_AND_LIFESTYLE, icon: BananIcon },
    { title: Interests.PSYCHOLOGY, icon: LampIcon },
    { title: Interests.CHILDBIRTH_AND_RECOVERY, icon: ChildBirthIcon },
  ],
  [UsageOptions.AFTER_CHILDBIRTH]: [
    { title: Interests.WOMEN_HEALTH, icon: WomenHealthIcon },
    { title: Interests.CHILD_HEALTH, icon: PacifierIcon },
    { title: Interests.CHILD_DEVELOPMENT, icon: CubesIcon },
    { title: Interests.NUTRITION_AND_LIFESTYLE, icon: BananIcon },
    { title: Interests.PSYCHOLOGY, icon: LampIcon },
    { title: Interests.SEX_AND_CONTRACEPTION, icon: SexIcon },
  ],
};

// **** /

export type CertificateWAEB = {
  id: string;
  firstName: string;
  lastName: string;
  createdAt: number | null; // timestamp
  country: string;
  town: string;
};

// **** /

export type ProgressWAEB = {
  block: string;
  course: string;
};

// **** /

export enum Providers {
  GOGGLE = 'google',
  APPLE = 'apple',
}

// ***** /
export const UNKNOWN_NAME = 'unknown name';
export const UNKNOWN_NAME_VALUE = '? ? ?';
export const UNKNOWN_DATE_10 = '??';
export const UNKNOWN_DATE_1000 = '????';
