import { checkIsMobile, checkIsTablet } from 'utils/other';
import { useWindowSize } from './useWindowSize';

export const useAppWindowSize = () => {
  const { width, height } = useWindowSize();
  const isTablet = checkIsTablet(width);
  const isMobile = checkIsMobile(width);
  const isDesktop = !isTablet && !isMobile;
  return {
    isTablet,
    isMobile,
    isDesktop,
    width,
    height,
  };
};
