import { watchHomeEvent } from 'analytic/trackEvents';
import { Lullabies, MeditationIcon } from 'assets/icons';
import CourseEmptyWrapper from 'components/CommonCoursesCategory/components/CourseEmpty/CourseEmptyWrapper';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import { USAGE_OPTIONS, UsageOptions } from 'constants/constants';
import { getWeeksAndDays } from 'features/Profile/utils';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { useShtickApi } from 'hooks/useShtickApi';
import { useTranslate } from 'hooks/useTranslate';
import { useUser } from 'hooks/useUser';
import { useEffect } from 'react';
import { RecommendationType } from 'types';
import styles from './MainPage.module.css';
import { MainPageEmptyDate } from './MainPageEmptyDate/MainPageEmptyDate';
import LatePregnancy from './components/LatePregnancy/LatePregnancy';
import { MainPageTitleSection } from './components/MainPageTitleSection/MainPageTitleSection';
import Meditation from './components/Meditation/Meditation';
import StatusBox from './components/StatusBox/StatusBox';
import TodaysShtick from './components/TodaysShtick/TodaysShtick';
import WeeklyRecommendations from './components/WeeklyRecommendations';
import { useWindowSize } from 'hooks/useWindowSize';
import { useCoursesApi } from 'hooks/useCoursesApi';

import { optional } from 'utils/other';
import {
  getChildAge,
  getChildMonth,
  getItemsByTopic,
  getItemsByChildMonth,
  getItemsByPregnancyWeek,
} from './MainPage.utils';

function MainPage() {
  useEffect(() => {
    watchHomeEvent();
  }, []);
  const { isEmptyDate, user } = useUser();
  const noStatus = user && !user?.usageOption;
  const { shtick } = useShtickApi();

  const { isTablet } = useAppWindowSize();
  const { width } = useWindowSize();
  const isMobile = width < 750;
  const shitckShow = width < 1240;
  const t = useTranslate();

  const { dateOfPregnancy } = user;

  const [pregnancyDays, pregnancyWeek] = getWeeksAndDays(dateOfPregnancy);
  const Icon = USAGE_OPTIONS.filter(
    (option) => option.title === user?.usageOption
  )[0]?.icon;
  const title = USAGE_OPTIONS.filter(
    (option) => option.title === user?.usageOption
  )[0]?.title;

  const style =
    user?.usageOption !== UsageOptions.PLANNING_PREGNANCY && !!shtick;
  const { courses: coursesMeditation } = useCoursesApi(
    RecommendationType.MEDITATION
  );

  const { courses: coursesLullabies } = useCoursesApi(
    RecommendationType.LULLABIES
  );

  const { dateOfChildBirth, usageOption } = optional<{
    usageOption: UsageOptions;
    dateOfPregnancy: number;
    dateOfChildBirth: number;
  }>(user);

  const childAge = getChildAge(dateOfChildBirth!);
  const childMonth = getChildMonth(dateOfChildBirth);
  const isPregnant = usageOption === UsageOptions.MANAGING_PREGNANCY;
  const isPlanningPregnancy = usageOption === UsageOptions.PLANNING_PREGNANCY;
  const isChildLessYear =
    usageOption === UsageOptions.AFTER_CHILDBIRTH && childAge < 1;
  const isChildOverYear =
    usageOption === UsageOptions.AFTER_CHILDBIRTH && childAge >= 1;

  const filteredMeditation = isPlanningPregnancy
    ? getItemsByTopic(coursesMeditation, 'planning-pregnancy')
    : isPregnant
    ? // @ts-ignore
      getItemsByPregnancyWeek(coursesMeditation, pregnancyWeek)
    : isChildLessYear
    ? getItemsByChildMonth(coursesMeditation, childMonth)
    : isChildOverYear
    ? getItemsByTopic(coursesMeditation, 'child-month-age-12-and-over')
    : [];

  const filteredMeditationWithEnabled = filteredMeditation.filter(
    // @ts-ignore
    (item) => item?.enable
  );

  const filteredLullabies = isPlanningPregnancy
    ? getItemsByTopic(coursesLullabies, 'planning-pregnancy')
    : isPregnant
    ? // @ts-ignore
      getItemsByPregnancyWeek(coursesLullabies, pregnancyWeek)
    : isChildLessYear
    ? getItemsByChildMonth(coursesLullabies, childMonth)
    : isChildOverYear
    ? getItemsByTopic(coursesLullabies, 'child-month-age-12-and-over')
    : [];
  // @ts-ignore
  const filteredMeditationWithLullabies = filteredLullabies.filter(
    // @ts-ignore
    (item) => item?.enable
  );

  if (!user?.id) {
    return (
      <>
        <CourseEmptyWrapper
          isTablet={isTablet}
          isMobile={isMobile}
          title={t('Мой Mama Pro')}
          withoutButton
        />
      </>
    );
  } else if (isEmptyDate || noStatus) {
    return (
      <div className={styles.background}>
        <Header styled />
        <div className={styles.emptyContainer}>
          <MainPageTitleSection
            status={user?.usageOption}
            showTitle={!!shtick}
            title={title}
            emptyTitle
          />
          <MainPageEmptyDate />
        </div>
        <Footer />
      </div>
    );
  } else if (
    user.usageOption === UsageOptions.MANAGING_PREGNANCY &&
    (pregnancyWeek as number) > 42
  ) {
    return <LatePregnancy title={title} />;
  }

  return (
    <div className={styles.background}>
      <Header styled />
      <div
        className={
          user?.usageOption === UsageOptions.PLANNING_PREGNANCY
            ? styles.planingChildContainer
            : style
            ? styles.container
            : styles.containerPlanning
        }
      >
        <MainPageTitleSection
          status={user.usageOption}
          title={title}
          showTitle={!!shtick}
        />
        <div className={style ? styles.wrapper : undefined}>
          <div
            className={style ? styles.wrapperWeekly : styles.wrapperPlanning}
          >
            <div className={styles.weaklyContainer}>
              {user?.usageOption !== UsageOptions.PLANNING_PREGNANCY && (
                <StatusBox status={user?.usageOption} isShtick={!shtick} />
              )}
              {user?.usageOption !== UsageOptions.PLANNING_PREGNANCY &&
                shitckShow &&
                !!shtick && <TodaysShtick shtick={shtick} />}
              <div className={styles.infoContainer}></div>
              <WeeklyRecommendations />
              {(shtick || isTablet || isMobile) && (
                <div className={styles.meditationSecondContainer}>
                  {!!filteredMeditationWithEnabled?.length && (
                    <Meditation
                      icon={MeditationIcon}
                      title={t('home_meditation')}
                      style={
                        isMobile
                          ? { marginTop: 0, marginBottom: 0 }
                          : isTablet
                          ? { marginTop: 30, marginRight: 20 }
                          : { marginTop: 0, marginBottom: 0, paddingTop: 0 }
                      }
                      type={RecommendationType.MEDITATION}
                    />
                  )}
                  {!!filteredMeditationWithLullabies?.length && (
                    <Meditation
                      icon={Lullabies}
                      title={t('home_lullabies')}
                      type={RecommendationType.LULLABIES}
                      style={
                        isMobile
                          ? { marginTop: 0 }
                          : isTablet
                          ? {
                              marginTop: !!filteredMeditationWithEnabled?.length
                                ? 30
                                : 15,
                              marginBottom: 150,
                            }
                          : { marginTop: 0 }
                      }
                      isTablet={isTablet}
                    />
                  )}
                </div>
              )}
            </div>

            <div
              className={
                user?.usageOption === UsageOptions.PLANNING_PREGNANCY
                  ? styles.planingChildStatus
                  : style
                  ? styles.status
                  : styles.statusPlanning
              }
            >
              {!shtick && (
                <p className={styles.statusTitle}>
                  {t('profile_status_title')}
                </p>
              )}
              {!shtick && (
                <StatusBox
                  icon={Icon}
                  title={t(title)}
                  status={user?.usageOption}
                  isShtick={!shtick}
                  withoutIcon
                  iEmpty
                />
              )}
              {!isTablet && !isMobile && !shtick && (
                <div className={styles.meditationSecondContainer}>
                  {!shtick && !!filteredMeditationWithEnabled?.length && (
                    <Meditation
                      icon={MeditationIcon}
                      title={t('home_meditation')}
                      type={RecommendationType.MEDITATION}
                    />
                  )}
                  {!shtick && !!filteredMeditationWithLullabies?.length && (
                    <Meditation
                      icon={Lullabies}
                      title={t('home_lullabies')}
                      type={RecommendationType.LULLABIES}
                      style={{
                        marginTop: !!filteredMeditationWithEnabled?.length
                          ? 30
                          : 0,
                        marginBottom: 150,
                      }}
                      isTablet={isTablet}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          {user?.usageOption !== UsageOptions.PLANNING_PREGNANCY &&
            !shitckShow &&
            !!shtick && <TodaysShtick shtick={shtick} />}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MainPage;
