import {
  separator,
  tb2c3_1,
  tb2c3_2,
  tb2c3_3,
  tb2c3_4,
  tb2c3_5,
  tb2c3_6,
} from 'assets/images';

export const b2_c5_text = {
  b2_c3_title: `Курс «Питание будущей мамы» `,
  b2_c3_description: `Спикер: Марта Марудова, врач диетолог-нутрициолог`,
  b2_c5_text: ` 
    ${tb2c3_1}

    Урок 1. Питание по триместрам: основные советы и важные продукты

Правильное питание и физическая нагрузка лежат в основе хорошего здоровья. А хорошее здоровье поможет выносить и родить здорового малыша. В то время как плохое питание может привести к ослаблению иммунитета, нарушению физического и психического развития, снижению продуктивности.

Энергетические потребности во время беременности возрастают (не на много – лишь на 10-15%), а потребности в пищевых веществах (такие как витамины, минеральные вещества и микроэлементы) становятся значительно выше. Важно придерживаться полноценного и сбалансированного рациона, отдавать предпочтение полезным продуктам. На разных этапах вашей беременности (в каждом из триместров) будут свои особенности и свои акценты. Предлагаю прямо сейчас разобраться вместе.

1 триместр беременности

В начале беременности рацион может не отличаться от обычного абсолютно. Но в этот период у будущих мам возникают странные кулинарные предпочтения – хочется то соленого, то кислого, то сладкого. Происходит гормональная перестройка организма, а вместе с ней меняется порог чувствительности к разным вкусам. Например, желание сладкого тесно связано с перестройкой гормональной системы.

Но лучше слепо не потакать гормонам. Избытка соленой и сладкой пищи следует избегать, чтобы не провоцировать отеки и не набирать вес больше положенного. Есть несъедобные предметы вроде мела, который иногда хочется беременным, тоже не рекомендуется.

Питание при токсикозе

уменьшайте порции
сокращайте интервалы между приемами пищи (ешьте каждые 2 часа)
употребляйте продукты с кислым послевкусием (сезонные ягоды, квашеная капуста, лимон)
ешьте теплую (комфортной температуры) пищу
не ложитесь сразу после еды
Будущим мамам важно сделать упор на кальций, магний и цинк, потому что они помогают росту малыша. Помните, что в этот период закладывается основа здорового скелета ребенка. Из каких же  продуктов мы можем взять полезные вещества? 

Безусловно, это белковые продукты. Обращайте внимание на индейку и на кисломолочные продукты, если нет их непереносимости. 

Если выбираем индейку – ставим ее на обычные кухонные весы и берем в дозировке не менее 100-120 г в качестве одной порции раз в день. Можно есть яйца. Если нет противопоказаний и все в порядке с холестерином – съедайте 1-2 куриных яйца. Не забывайте также о кисломолочных продуктах. Например, творог и его составляющие – это чудесный продукт. Добавляйте в рацион кисломолочные продукты в виде кефира, ряженки, пахты, сыров.

Рекомендую есть бобовые, зелень, шпинат, морковку, кабачки, цукини, баклажаны. Это те сезонные овощи, которые  есть в нашей стране.

2 триместр беременности

В этот период важно следить за питанием чуть пристальнее. Чем крупнее становится плод, тем больше нагрузка на желудочно-кишечный тракт. И зачастую будущая мама сталкивается с такими неприятными явлениями как изжога и запоры. Какие советы и рекомендации помогут? 

Максимально ограничить десерты и другие продукты, в состав которых входят трансжиры. При их употреблении моментально вырабатывается инсулин и быстрее происходит процесс утилизации глюкозы в жировые отложения, чего бы очень не хотелось. 
Минимизировать, а лучше исключить газированные и сладкие напитки, копчености, жевательные резинки, конфеты, консервы, крепкий кофе и чай.
Употреблять фрукты и овощи (также в виде перекусов). Лучше всего есть их свежими, без термической обработки, и тщательно мыть перед употреблением. Чрезмерно соленых, сладких, обжаренных фруктов и овощей стоит избегать.
В этот период важно сформировать слух малыша и зрение малыша, и на помощь здесь приходят следующие группы продуктов.

Цельнозерновые каши ежедневно – например, гречка или овес. Они нужны как хороший источник длинных, долгих углеводов, которые дают насыщение и питательную ценность. К этому периоду беременности мамы начинают больше уставать, меньше  двигаться. Так как растет животик, маме все сложнее и сложнее быть более подвижной. Именно поэтому из такого вида продуктов мамы берут полезную энергию, это важно помнить.
Полезные жиры – орехи, масла, авокадо, жирные сорта рыбы.
Высокий уровень такого известного гормона беременности, как прогестерон, замедляет моторику кишечника и часто провоцирует запоры. Беременным Всемирная организация здравоохранения рекомендует употреблять в сутки 20-35 г клетчатки. Причем важно получать ее из еды, а не из пищевых добавок. Рекомендовано есть овощи, зелень, фрукты. Также ВОЗ  рекомендует употреблять не менее 500 грамм свежих овощей и зелени в день. 

Также рекомендуется немного увеличить количество потребляемого белка (в частности, 1,1 г чистого белка на 1 кг веса). По факту – это не менее 100 г белкового продукта 3 раза в день. Белковые продукты (еще раз) – это мясо, рыба, птица, яйца, морепродукты, кисломолочные продукты.

Помимо правильного рациона, не стоит забывать и о движении. Именно прогулки  на свежем воздухе, посильные физические упражнения помогут маме чувствовать себя лучше в совокупности с хорошим рационом.

3 триместр 

Это очень важный период, когда сформированы все органы и системы. Им важно быть здоровыми, крепкими, а малышу – родиться в срок. ВОЗ рекомендует добавить в 3-м триместре до 300 ккал к своему суточному рациону. Но так же, как во 2-м триместре, эти 300 ккал должны быть добавлены за счет только полезных продуктов.

Накануне родов важно потреблять правильное количество жидкости, потому что ближе к родам практически каждая мама сталкивается с проблемами отеков. И когда мама не выпивает свою нужную норму воды (а это 35 мл на 1 кг веса), при этом она  обездвижена – меньше ходит, больше лежит, больше отдыхает.

Помимо воды, есть продукты питания, которые задерживают жидкость и усугубляют проявление отеков. Минимизация соли – это правильный шаг. ВОЗ говорит о том, что ежедневно употреблять соль можно в дозировке не более 5 г в день. Это всего лишь маленькая  чайная ложечка, включая всю соль, которая уже добавлена в продукты питания. Таким образом, мы минимизируем и исключаем все соленые продукты и сами не добавляем много соли во время приготовления пищи во избежание отеков. 

Помним о том, что все яркие, копченые, жирные, терпкие, сладкие вкусы тоже могут провоцировать отеки. Поэтому, если будущая мама страдает большим количеством отеков, первое – это налаживаем рацион и убираем все яркие и непривычные вкусы для себя из рациона. Потому как уже в период, который близится к родам, маме все чаще и чаще хочется напоследок съесть чего-то экзотического. 

Питание будущих мам вегетарианок/веганов

Если беременная женщина является лактовегетарианкой (т.е. употребляет в пищу молочные продукты и яйца), то, подходя внимательно к выбору продуктов, она может получать необходимые питательные вещества, а ее питание сможет считаться полноценным. Дополнительно рекомендуется принимать только фолиевую кислоту, йод и витамин D. Конечно, все после консультации с врачом. 

Если беременная женщина не ест рыбу, то рацион следует дополнить Омега-3 жирными кислотами. 

Если женщина ест рыбу, то можно включать ее в рацион 2 раза в неделю (в общей сложности по 150-300 г приготовленной рыбы), причем один раз в неделю надо потреблять жирную рыбу (например, можно предложить сельдь, форель, лосось, сардину). Источниками белка в рационе также являются молочные продукты, яйца и бобовые. 

Железо поступает в организм с яйцами и бобовыми, зерновыми культурами. Однако у вегетарианок имеется более высокий риск дефицита железа, и им следует чаще дополнительно принимать препараты железа. Если женщина была вегетарианкой задолго до беременности, то возрастает риск дефицита витамина B12 и цинка. Беременным женщинам-вегетарианкам желательно обратиться за индивидуальной консультацией по вопросам питания, и в случае необходимости им назначают конкретные препараты. 

Строгое веганское питание, когда в пищу употребляются только растительные продукты, нередко создает серьезный риск для здоровья матери и ребенка, особенно для развития детской нервной системы. Часто наблюдается недостаточное потребление энергии, белка, Омега-3 жирных кислот, кальция, железа, йода, цинка, витамина В12, витамина D. И без применения специальных добавок к пище веганское питание не может быть полноценным. Женщинам-веганам необходимо обратиться за индивидуальной консультацией к диетологу или квалифицированному специалисту по питанию, чтобы выяснить, какие именно препараты им нужны. Кроме того, они должны тщательно следить за питанием в течение всего периода беременности.

Меню на каждый день для беременной женщины

Завтрак 

Белковая составляющая – например, сырники с фруктами (с бананом).
Бутерброд из цельнозернового хлеба (с нежирным кусочком мяса и небольшим количеством овощей)
Второй завтрак

Ягоды и фрукты, десерты из ягод и фруктов.
Обед

100-120 г белковой пищи. Это могут быть котлеты, отбивная, рыба, мясной стейк.
Гарнир – овощи или цельнозерновая каша, макароны из твердых сортов пшеницы. 
Полдник 

Творожный, кисломолочный продукт (пребиотики, которые находятся в этих продуктах, полезны не только для мамы, но и для здоровья малыша).
Ужин

Белковая составляющая
Большая порция зелени и салата
Слушайте свой организм, его потребности и желания, с удовольствием составляйте свой рацион и не забывайте, что здоровое питание в этот период – основа качественной жизни: как вашей, так и малыша.

${tb2c3_2}

Урок 2. Нормы прибавки веса во время беременности

Женщины всегда особенно трепетно относятся к стрелке на весах. И, к сожалению, зачастую будущие мамы начинают лишать себя важных продуктов, переживая за сохранение своей формы.

Надлежащее питание до и во время беременности – это основа здорового образа жизни, говорит нам Всемирная организация здравоохранения. И в последние годы появляется все больше доказательств того, что высокий индекс массы тела у женщин до беременности, а также большой набор веса и неполноценное питание во время беременности влияют на рост числа неинфекционных заболеваний и способны негативно повлиять на продолжительность жизни и исход беременности. 

Развитие плода зависит от питательных веществ во время беременности, от всех витаминов, минеральных веществ и макроэлементов, которые получает мама из пищи и которые затем циркулируют в ее крови. 

Существует прямая связь между низким весом при рождении и восприимчивостью в дальнейшей жизни к таким заболеваниям, как нарушения метаболизма, инсулина, диабет II-го типа, центральное ожирение, патологический метаболизм липидов, артериальная гипертензия, сердечно-сосудистые заболевания, а также заболевания почек. Именно поэтому маме так важно внимательно подходить к своему рациону, который она выбирает во время заветных 9 месяцев.

Повышает риск осложнений также и ожирение до и после зачатия. Речь о диабете, отеках, повышении свертываемости крови, нарушении работы сердца, токсикозе, головных болях, пороках нервной системы у плода, невынашивание, перенашивание и множество других моментов.

Тем не менее ожирение (ИМТ 30 и более) не является противопоказанием к беременности, поэтому будущей маме лучше скорректировать массу тела, чтобы минимизировать проблемы со здоровьем для себя и для ребенка.

Сколько в норме может набрать в весе будущая мама

${tb2c3_3}

Помните, что прибавка в весе во время беременности – это норма. 

Важно отметить, что гормональная перестройка создает благоприятные условия для формирования жировой клетчатки, биологический смысл которой заключается в защите будущего ребенка. Поэтому физиологическое отложение жировой ткани в области молочных желез, ягодиц, бедер, живота около 4 кг – это абсолютная норма.

Когда будущие мамы становятся на весы, их действительно может пугать вес. Важно понимать, что он складывается из множества факторов – начиная от внутренних органов и заканчивая особенностями организма (например, задерживаемой жидкости, мышечной ткани).

Из чего складывается плюс на весах, который видит женщина?

примерно 4 кг – это  малыш; 
500 г весит плацента; 
1 кг – матка; 
примерно 1 кг – околоплодная жидкость; 
2 кг – вода в вашем теле; 
500 г – увеличение объема молочных желез;
около 4 кг – отложение жира в женском организме.
Говоря о нормах в прибавки в весе, очень важно отметить такое распространенное явление, как ранний токсикоз. Получается, что в начале беременности бывает тошнота, рвота, ничего не лезет и какое-то время женщина попросту полноценно не ест. Зато потом, когда после 12-й недели токсикоз заканчивается и отступает, будущая мама начинает активно и много есть. При этом потребляя все подряд, соскучившись по еде. 

Именно поэтому такая тактика поведения в токсикозе, как «ничего не есть», не совсем верная. Чтобы уменьшить проявление токсикоза, рекомендуется:

уменьшить порции в 2-3 раза
сократить промежутки между приемами пищи
есть каждые 1,5-2 часа маленькими порциями
не рекомендуется ложиться сразу после приема пищи или принимать пищу полусидя-полулежа
Еще одна крайность, о которой мы часто говорим мамам, – это диета во время беременности. Причины могут быть разные. Например, мама боится набрать много лишнего за беременность или мама заранее начинает подготавливаться к тому, что она уже худеет, даже не успев набрать. И такое бывает. 

Поэтому, дорогие мамы, прошу обратить ваше внимание, беременность – это не время для пищевых экспериментов и всевозможных диет. Советую вам сосредоточиться на полноценном, правильном, здоровом питании, при этом без переедания и без того, чтобы бесконтрольно есть и есть. Поэтому крайности в виде голодовок, диет, ограничений или наоборот перееданий абсолютно не нужны.

В вопросе питания золотая середина существует. Секрет в том, чтобы научиться отличать голод от аппетита. То есть существует физиологическая потребность – голод, когда человек действительно хочет, есть. А аппетит – это желание головы сделать себе хорошо, расслабиться, получить удовольствие, наслаждение и релакс. Именно когда будущая мама идет на поводу аппетита, то это  может приводить к набору веса.   

Хотите держать вес в норме? Тогда не только взвешивайтесь, но и следите за питанием. Делюсь с вами несколькими простыми рекомендациями.

Употребляйте сладкое в первой половине дня и в небольшом количестве. На простые  сахара рекомендуется извести не более чем 10% рациона – например, это зефирка или пастила к чаю. И не забывайте, что самое идеальное сладкое, которое может позволить себе мама, – это фрукты и ягоды. Всемирная организация здравоохранения рекомендует употреблять количество свежих овощей и фруктов и зелени в день не менее 500 г. Если маме необходимо подкорректировать вес, то количество фруктов должно быть меньше, чем овощей и зелени. 
Двигайтесь. Необходимо ежедневно устраивать себе прогулки на свежем воздухе, заниматься посильным спортом для беременных, а также упражнения, которые порекомендует специалист.
Практикуйте медленное жевание. Когда человек проглатывает еду быстро, то тяжелые куски растягивают стенки желудка, увеличивая его в объеме и заставляя есть больше и переедать. В итоге женщина стремительно набирает вес. 18-24 жевательных движения на каждый кусочек позволят остаться в форме и сохранить здоровье.
Не переживайте за вес, гораздо важнее сейчас дать малышу все нужное и не волноваться по вопросу плюса на весах. Питайтесь сбалансированно, при необходимости советуйтесь с врачами и помните, что есть лучше не за двоих, а для двоих!

${separator}

Урок 3. Важные продукты во время беременности

Зерновые продукты

Более половины рациона.
Рекомендуется, чтобы большинство продуктов были цельнозерновыми. Цельнозерновые продукты и, например,  картофель могут являться источником сложных углеводов. В них содержится значительное количество витаминов, минералов, пищевых волокон. 
При приготовлении этих продуктов не рекомендуется добавлять слишком много жира.
Овощи и фрукты

5 порций овощей и фруктов в день (500 г – рекомендация ВОЗ). Доля овощей должна быть больше. Следите, чтобы не менее половины овощей были сырыми, термически не обработанными. 
Рекомендуется сократить употребление консервированных, соленых, сильно обжаренных в большом количестве жира овощей. Лучше  отдать предпочтение тушеным и вареным овощам в супах или слегка обжаренным овощам. 
Большинство фруктов должны быть свежими, употребление консервированных фруктов лучше ограничить. 
Выбирайте 100% натуральные соки вместо сокосодержащих напитков и нектаров (в них доля фруктов меньше, но при этом добавлены сахара, подсластители и другие нежелательные вещества). 
Молочные продукты 

Это источник белка, кальция, йода и других питательных веществ. 
Лучше избегать очень жирных молочных продуктов и сладких йогуртов, которые содержат много сахара или искусственных подсластителей. Выбирайте неподслащенные кисломолочные продукты (например, кефир, пахта, натуральный йогурт). 
Творог является ценным источником белка, но помните, что в зернистом твороге также есть соль. Сыр тоже относится к важным источникам белка и кальция, однако из рациона следует исключить сырные изделия, так как они могут содержать трансжирные кислоты (например, плавленые сырки). 
Белковые продукты 

Мясо – важный источник железа. Выбирайте постное мясо (индейка, курица, кролик). 
Рекомендуется щадящий способ приготовления (тушение, запекание в духовке) и не рекомендуется готовить до образования румяной корочки, потому что онкогенность, канцерогенность таких продуктов зашкаливает. 
Следует избегать таких мясных изделий, как колбасы, копчености и ветчина, поскольку содержание в них белка значительно ниже, но при этом они содержат много соли и жира и зачастую нежелательные пищевые добавки. 
Рыба – важный пищевой источник Омега-3 жирных кислот и витамина D. Рыбу рекомендуется употреблять в пищу два раза в неделю, причем один раз – рыбу жирных сортов. 
При выборе растительного масла отдавайте предпочтение оливковому и рапсовому, а также льняному маслу, богатому Омега-3 жирными кислотами. 
Хороший источник белка – продукты растительного происхождения – бобовые (бобы, чечевица, горох), орехи и семена.
Метаболическая тарелка – отличный пример хорошего питания. Она создана экспертами по питанию Гарвардской школы общественного здравоохранения.

${tb2c3_4}

Половина тарелки – это свежие овощи, зелень и фрукты. Не менее 500 г в день.
Четверть тарелки – это белок (мясо, рыба, птица, бобовые, яйца, морепродукты). Ежедневно в количестве не менее 100-120 г 3 раза в день в каждый большой прием пищи. 
Четверть тарелки –  это сложные углеводы (цельнозерновые каши, цельнозерновой хлеб, макароны из твердых сортов пшеницы). 
Полезные жиры. Количество жировых продуктов, даже полезных, в рационе должно быть небольшим, потому что полезные жиры (орехи, масла, авокадо) имеют большую калорийность. А значит, за жирами нужно строго следить. Если с ними перебирать, то будет происходить нежелательный набор веса. 
За границей метаболической тарелки находится одинокое пирожное. Это говорит нам о том, что сладкое или простые сахара в нашем рационе не обязательны. На этот счет ВОЗ дает нам четкие рекомендации, что количество добавленного сахара в рационе должно быть не более 10% от всей суточной калорийности. По факту это 150-200 ккал в день.
Важно не путать метаболическую тарелку с метаболической диетой и помнить, что беременность – это не время для жестких диет и ограничений, это время для того, чтобы питаться правильно и сбалансированно. 

${separator}

Урок 4. Запрещенные и опасные продукты при беременности. Безопасность пищевых продуктов
При планировании беременности, а также во время беременности и в период лактации категорически не рекомендуется употреблять:

наркотические и психотропные вещества
табачные изделия и электронные сигареты
алкоголь
Рекомендуется также максимально исключить токсины, которые как вредные вещества могут попадать в организм с пищей (из-за технологий, используемых при обработке пищевых продуктов), а также из воды и окружающей среды.

Отдельно хочу обсудить алкоголь. У детей, которые подвергаются тяжелому пренатальному воздействию алкоголя, возрастает риск различных физических и психических заболеваний до и после родов, а также в более позднем возрасте. У таких детей повышен риск нарушений роста и патологического развития мозга. В результате употребления матерью алкоголя у ребенка могут возникнуть неврологические расстройства, которые влекут за собой серьезные проблемы с обучением и поведением. 

Доказано, что при употреблении более одной дозы алкогольного напитка в день во время беременности возрастает риск преждевременных родов и пониженного веса при рождении. Безопасная доза алкоголя, которая не вредна для ребенка, не установлена. Поэтому единственным безопасным «уровнем» является полное воздержание от употребления алкоголя во время беременности и лактации. 

ОБРАТИТЕ ВНИМАНИЕ: если у беременной женщины есть проблемы с употреблением алкоголя, наркотических веществ, и она понимает, что отказаться от них будет сложно – настоятельно рекомендуется обратиться в специализированные учреждения за помощью и консультацией.

Неполезные и потенциально опасные вещества в рационе

Искусственные  подсластители. Доказано, что некоторые из них (например, ацесульфам калия и сахарин) проникают через плацентарный барьер и попадают в материнское молоко. Оба они признаны небезопасными для беременных женщин и плода. Эти подсластители часто используются в безалкогольных напитках, при производстве мороженого, конфет, тортов, жевательной резинки.
Подсластитель аспартам нельзя употреблять женщинам, в том числе беременным, страдающим фенилкетонурией, поскольку он оказывает токсическое действие на мозг плода. Согласно последним данным, растительный подсластитель стевия никак не влияет на развитие ребенка.

Ртуть. Она накапливается в крупных сортах океанической рыбы, таких как акула, тунец, рыба-меч и королевская скумбрия. С учетом сильной загрязненности беременным женщинам не рекомендуется употреблять в пищу рыбу из Балтийского моря и лучше избегать употребления щуки из-за накопления в ней ртути, а также ограничить потребление пресноводной рыбы (окуня, судака и налима). Тем не менее беременным женщинам не рекомендуется полностью отказываться от рыбы.
Свинец может поступать в организм при использовании некачественной эмалированной посуды, стеклянной посуды с содержанием свинца, старой кухонной утвари с тефлоновым покрытием. Попадание свинца в организм создает риски для здоровья мамы (повышается риск гипертензии и спонтанного аборта) и ребенка (низкий вес при рождении, нарушение нервной системы). Поэтому вся старая кухонная утварь должна быть выброшена. Если вы подозреваете, что что-то не так с кухонной посудой, вы покупали ее очень давно или при приготовлении пищи выделяются неприятные запахи,большой объем дыма – избавьтесь от такой посуды и купите посуду с керамическим покрытием.
Витамин А. Беременным женщинам не рекомендуется употреблять (в особенности в больших дозировках) печень и ее продукты, а также принимать препараты с ретинолом, включая рыбий жир (в особенности без согласования с врачом). Важно отметить, что растительные продукты, которые содержат каротины (так называемые провитамины витамина А), например красные и оранжевые овощи и фрукты, являются безопасными во время беременности. 
Состав продуктов

Используйте простой лайфхак – принцип  максимальной минимальности. То есть, если мы берем продукты, смотрим на упаковку и  видим там 15-12 строчек в составе, лучше сразу отказаться от покупки такого продукта и не употреблять его. А если состав умещается в 1-2 строчки, можно задуматься о покупке и углубиться в состав.

Безопасность рациона питания

Во время беременности иммунная система женщины в какой-то мере находится в подавленном состоянии, в результате чего возрастает риск заражения, в том числе пищевыми инфекциями (листериозом, токсоплазмозом).

Как обезопасить себя и своего будущего малыша? Не рекомендуется употреблять:

термически не обработанные или недостаточно обработанные продукты животного происхождения (сырое мясо, колбасу, ветчину);
термически не обработанную рыбу и морепродукты, которые часто присутствуют в составе суши, а также сушеную рыбу; 
непастеризованное молоко, сырые яйца; 
термически не обработанные и пророщенные семена, зерно и фасоль; 
мягкие сыры (бри, фета, голубые сыры), если нет полной уверенности в том, что они изготовлены из пастеризованного молока; 
немытые овощи, листья салата и фрукты;
также рекомендуется не оттаивать замороженное мясо и не мариновать мясо при комнатной температуре, а делать это в холодильнике.
И еще несколько простых, но очень важных советов.

Продукты, которые росли в земле или близко к земле, нужно хранить отдельно от других продуктов питания.
Употребляйте пищу сразу же после ее приготовления.
Следует придерживаться строгих требований гигиены — мыть руки, правильно хранить продукты, использовать отдельные кухонные принадлежности для термически не обработанных и приготовленных продуктов.
${separator}
Урок 5. Большая восьмерка аллергенов
Сегодня в мире, по некоторым данным, до половины населения страдает различными аллергическими заболеваниями. Атопический и контактный аллергический дерматит, аллергический ринит и синусит, бронхиальная астма, аллергия на пыльцу растений, пищевая и лекарственная аллергия – все это проявления аллергии, которой страдают даже беременные женщины. Именно поэтому мы включили такой урок в наш большой курс по питанию беременных. Если среди наших зрителей есть женщины-аллергики, обратите особое внимание на этот урок.

Самым частым поражаемым органом при аллергии у беременных бывает нос. Насморк, заложенность и выделения из носа беспокоят каждую вторую женщину во время беременности, как правило, во 2-м и 3-м триместре. Это может вызывать беспокойство и нарушение сна. Если подобные проявления отметились до беременности, например, во время цветения или при контакте с пылью, шерстью животных, то, вероятнее всего, это аллергический насморк. 

Аллергическое заболевание кожи у беременной – например атопический дерматит, экзема, нейродермит, крапивница. Можно предположить, что, если подобные проявления были и до беременности, они могут обостриться и после, и здесь всему виной либо погрешности в сбалансированном рационе питания, когда очень хочется чего-нибудь вкусненького, либо какая-то неправильная диетологическая особенность. 

Дорогие мамы, помните, что абсолютно любой продукт может быть аллергеном. И предсказать, какой именно может вызвать аллергию, современная медицина не в силах. Но самая высокая степень аллергизирующей активности у так называемой большой восьмерки аллергенов. Это:

Коровье молоко
Яйца
Соя
Рыба
Морепродукты
Глютен
Орехи
Арахис
Считается, что это продукты, которые с наибольшей вероятностью (но не обязательно) могут вызвать аллергические проявления, например зуд, отек, крапивницу. Именно поэтому если у будущей мамы в анамнезе имеется непереносимость какого-либо продукта либо кто-то из родственников имеет аллергическую реакцию, то к выбору этих продуктов питания следует отнестись внимательно. 

Если аллергии не было никогда, то  на любой из перечисленных продуктов может появиться реакция. Но это не значит, что нужно сразу исключить их из рациона. Важно понемногу добавлять эти продукты в свой рацион и в период беременности, и в период грудного вскармливания.

Если аллергия проявилась, особое значение мы придаем сахару и рекомендуем минимизировать его. Почему? Все просто – избыточное употребление сахара провоцирует еще большее проявление аллергии. 

В частности, если это кожные заболевания, высыпания, то сладкое усиливает  процессы гниения и брожения в ЖКТ, тем самым провоцируя и усугубляя проявления аллергии и не давая возможности организму восстановиться, даже после того, как аллерген уже перестал в него поступать. 

Во всех рекомендациях по питанию для уменьшения проявления аллергических реакций мы не просто минимизируем, а стараемся исключить весь сахар в рационе. И это не только добавленный сахар, но и сахар, который уже содержится в продуктах питания. Поэтому сокращаем сладкие продукты, и через несколько дней проявления аллергии уменьшаются.

Также аллергенами могут быть любые лекарственные препараты, включая даже противоаллергические средства, а также витамины и так называемые БАДы. Бесконтрольный прием всевозможных БАДов и витаминов крайне не рекомендуется. Принимать их стоит только после консультации с вашим лечащим врачом и после подбора индивидуальной дозировки.

Также помните, дорогие мамы, что некоторые моральные причины (например, нервный срыв, переживания, волнение, страх, тревога) способны усиливать проявления аллергии. Влияют также некоторые факторы риска: наследственность, дефицит витамина Д, ожирение, чрезмерное стремление к стерильности.

Очень часто мамы переживают, что у их деток будет аллергия. И уже с наступлением беременности начинают абсолютно все  исключать из своего рациона, думая, что это может снизить риск возникновения аллергии у ребенка в более позднем возрасте.  На самом деле нет доказательств того, что исключение отдельных продуктов питания во время беременности снижает риск аллергии у ребенка в более позднем возрасте. 

Таким образом, для предотвращения аллергии у ребенка не рекомендуется ограничивать рацион питания. Ведь в результате этого может возникнуть нехватка важных питательных веществ. А вот если беременная женщина сама страдает пищевой аллергией, то ей все же рекомендуется исключить продукты, которые вызывают аллергическую реакцию.

Что можно сделать маме еще до рождения малыша, чтобы снизить вероятность развития аллергического заболевания у ребенка?

Ограничение или в тяжелых случаях исключение высокоаллергенных продуктов из рациона беременной. 
Желудочно-кишечный тракт – это главные входные ворота для аллергенов, проникающих к плоду. Поэтому  формирование повышенной чувствительности происходит при степени зрелости иммунной системы, которая достигается приблизительно к 22 неделям внутриутробного развития. Именно с этого времени ограничение аллергенов в пище оправдано.

Строгая диета с полным исключением высокоаллергенных продуктов может быть показана женщине при обострении имеющегося аллергического заболевания при доказанной непереносимости, то есть когда обострения аллергического заболевания были и ранее связаны с употреблением данного продукта. В остальных случаях (особенно у здоровых мам) достаточно не употреблять продукты все сразу и ежедневно. Возможно периодическое включение их в рацион.

Крайне нежелательно курение – активное и пассивное. 
Беременные и кормящие мамы не должны находиться вблизи курящих, ведь доказано, что курение матери во время беременности влияет на развитие легких плода, приводит к внутриутробной задержке роста и развития. 

Курение мамы – одна из причин стресса малыша. После одной  выкуренной сигареты на 20-30 минут наступает спазм сосудов матки и нарушается поступление кислорода и питательных веществ к ребенку. Почему мы затронули тему в данном вопросе, именно тему курения? Потому что у детей от курящих матерей повышается вероятность развития атопического дерматита, бронхиальной астмы, пневмонии, синдрома внезапной смерти грудных детей.

ОБРАТИТЕ ВНИМАНИЕ: если будущая мама курит, то настоятельно рекомендуется обратиться к врачу или в специализированные учреждения, чтобы вас проконсультировали, оказали помощь, объяснили, какие меры предпринимать, чтобы ваш малыш родился здоровым.

Грудное молоко – это наиболее подходящий продукт для питания детей первых месяцев жизни. 
Оно имеет необходимую температуру, не требует времени для приготовления, не содержит бактерий и аллергенов, легко усваивается, содержит ферменты для собственного переваривания. И раннее прекращение грудного вскармливания (т.е. прекращение до 4 месяцев) увеличивает частоту аллергических реакций в несколько раз.

${separator}

Урок 6. Сладкое во время беременности: как грамотно употреблять?
В период беременности многие женщины пересматривают свой рацион питания. И, пожалуй, одним из самых непростых вопросов был, есть и остается вопрос исключения сладостей. С ними ведь так не хочется расставаться на долгие месяцы. И не нужно. Как? Давайте разберемся вместе с вами в этом уроке.

Для начала обсудим, почему все-таки важно следить за тем, сколько сахара вы едите. Особенно в период беременности.

Доказательства свидетельствуют о том, что одна из причин роста эпидемии ожирения может быть обусловлена несоответствующим (т.е. недостаточным или избыточным) питанием ребенка еще в дородовом периоде с последующим несбалансированным рационом. Что это за рацион? Это рацион  с  высоким содержанием жиров, большое количество соли и сахара.
Немаловажным является тот факт, что потребление свободных сахаров повышает риск развития зубного кариеса. И будущей маме очень важно сохранить здоровые зубы и красивую улыбку. К тому же последствия осложнения кариеса могут быть опасны для беременности. 
Избыточные калории, поступающие вместе с едой и напитками, содержащими свободные сахара, способствуют также нездоровой прибавке в весе, что может приводить к избыточному весу и ожирению. В свою очередь сокращение употребления сахара способствует снижению риска развития сердечно-сосудистых болезней.
Что же такое свободный сахар и сколько его можно есть?

Свободные сахара – это все сахара, которые  добавляются в пищевые продукты, а также сахар, который  естественным образом присутствует в меде, сиропе, фруктовых соках и их концентратах.

Рекомендуется, чтобы свободные сахара составляли менее 10% от общей потребляемой энергии. Это эквивалент 50 г (или 12 чайным ложкам сахара без верха) на человека с нормальным весом, потребляющего около 2 000 ккал в день. Однако хотелось бы отметить, что в идеале их значение должно быть ближе  к 5% от общей потребляемой энергии. Это обеспечит дополнительные преимущества для здоровья мам и малыша.

Потребление сахаров можно сократить следующими способами:

Ограничить потребление пищевых продуктов и напитков с высоким содержанием сахара – сладкие закуски, конфеты, пирожные, подслащенные напитки (т.е. все типы напитков, содержащие свободные сахара, которые включают газированные и негазированные прохладительные напитки, также фруктовые и овощные соки и напитки, жидкие и порошковые концентраты, воды со вкусовыми добавками, энергетические и спортивные напитки, готовые чаи, готовый кофе и молочные напитки со вкусовыми добавками).
Заменять сладкие закуски на свежие фрукты, ягоды и овощи. Помните о самых полезных и рекомендуемых ВОЗ сладостях – это фрукты, ягоды и десерты на их основе.
Поднимая тему сахара и беременности, не могу не сказать о сахарном диабете беременных. Это такое заболевание, которое характеризуется повышением сахара в крови, выявленное на фоне беременности.

Питание при гестационном диабете

Есть малыми, небольшими порциями в одинаковые часы. Рекомендуется равномерное распределение суточного объема пищи на 4-6 приемов.
Важно помнить, что акцент необходимо сделать на снижении калорийности пищи, но без снижения ее питательной ценности.
Лучше исключить из рациона жирные продукты, насыщенные, легкоусвояемые углеводы. Это торты, выпечка, конфеты, пирожные, булки,  а также продукты быстрого приготовления (фастфуд) и продукты с высоким гликемическим индексом (инжир, виноград, бананы).
Лучше обогатить рацион кашами из различных круп (например, гречка, рис, перловка, овес), салатами из овощей и фруктов, хлебом и макаронными изделиями из цельного зерна, то есть продуктами, которые богаты клетчаткой.
Советую употреблять в пищу мясо нежирных сортов, птицу, рыбу, а также исключить сосиски, сардельки, копченые колбасы и содержащие в себе много жиров и трансжиров продукты.
Готовить еду, используя небольшое количество масла растительного происхождения (как заправку).
Пить достаточное количество жидкости. Гулять, придерживаться дозированных физических нагрузок. А также советую взять в привычку контролировать уровень гликемии и массу тела.

${tb2c3_5}

Урок 7. Питьевой режим беременной
Вода – это основа всей жизни. В период беременности все развитие ребенка происходит в условиях водной среды. С момента слияния половых клеток до рождения плод окружен специальной жидкостью. Без нее развитие малыша невозможно.

Как же влияет вода на организм будущей мамы?

Вода нужна для множества химических реакций и, конечно же, обменных процессов.
Во время беременности вода необходима для увеличения объема крови будущей мамы, обновления околоплодных вод, поддержания кровообращения эмбриона и обеспечения его питательными веществами. 
Вода выводит токсины из организма и стимулирует работу кишечника (т.е. нормализует стул и предотвращает запоры)
Научные исследования показали, что вода обеспечивает эластичность кожи, предотвращает развитие растяжек, что актуально многим мамам.
Вода участвует в поддержании здоровья суставов и связок.
Как обильное питье влияет на качество околоплодных вод?

Итак, околоплодные воды необходимы для развития ребенка. Жидкость выполняет функции защиты и обеспечивает достаточное пространство для движения малыша, в то время как обезвоживание сокращает количество околоплодных вод, что может иметь опасные последствия для здоровья ребенка. 

Как правило, будущие мамы акцентируют свое внимание на употреблении витаминов и минералов, часто забывая про воду. Поэтому рекомендуется, чтобы каждый день в организм мамы поступало от 2 до 2,5 л жидкости. И помните, чем больше срок беременности, тем больше увеличивается вес будущей мамы, тем еще больше нужно пить воды. 

В последние месяцы беременности стоит пить на 300 мл в день больше. Рассчитать идеальный объем просто: 35 мл воды на 1 кг веса. Помните, что 1,5 л употребляемой жидкости в день – это минимум.

Большее количество воды особенно важно в жаркую погоду или при занятиях фитнесом или спортом, при любой другой физической активности.

Запивать еду или нет?

Потребляемая еда и вода в желудке не смешиваются. Жидкость проходит быстрее по малой кривизне желудка и далее проходит через привратник дальше, а пищевой комок продолжает перевариваться. Именно поэтому запивать еду можно. Советую делать это небольшими глотками во время еды.

Исключение составляют пациенты с установленным диагнозом ГЭРБ (т.е. гастроэзофагеальной рефлюксной болезнью), когда содержимое желудка забрасывается обратно в пищевод. И вот тогда мы можем говорить о том, что будущим мамам с таким диагнозом запивать еду нельзя. Стоит подождать 30-40 минут после приема пищи либо выпить жидкость за 30-40 минут до начала приема пищи. 

Что пить?

Идеальное питье – это чистая негазированная вода.

Сладкие газированные напитки лучше ограничить или исключить. Они могут приводить к нарушению углеводного обмена.  Сюда же относятся пакетированные соки и нектары. 
Отвары трав лучше использовать очень осторожно, так как возможно развитие аллергических реакций и терапевтических воздействий, которые противопоказаны во время беременности. Например, душица оказывает стимулирующее влияние на мышцы матки, что может стать причиной выкидыша либо преждевременных родов.
Можно пить отвары, морсы, молоко, кефир. Но помните, что все это жидкие калории. Безусловно, они полезны, но если выпивать по 2-3 л в день таких жидких калорий, то зачастую калораж напитков превышает всю суточную норму калорийности еды.
Можно ли пить чай и кофе? 

Есть данные о том, что на одну чашку кофе в день нужно выпивать дополнительно стакан (а то и два стакана) воды, так как организм беременной женщины весьма восприимчив к нехватке жидкости и это грозит ему быстрым обезвоживанием.

Зеленый чай обладает также мочегонным эффектом, но в меньшей степени, чем, например, кофе. Кофе способствует вымыванию кальция из костной ткани и мешает всасыванию железа. Дефицит микроэлементов при беременности – весомый довод в пользу отказа от этого замечательного напитка. Также кофе возбуждает нервную систему и стимулирует сердечно-сосудистую систему не только женщины, но и малыша, что может негативно сказаться на развитии ребенка.

Чай содержит танины, что ситуацию с всасыванием железа усугубляет еще больше. Из-за его обильного употребления может нарушаться всасывание такого нужного в первой половине беременности витамина, как фолиевая кислота.

Все дело в том, что кофе и свежезаваренный чай (не только черный, но и зеленый) содержат кофеин. Его допустимая дозировка – не более 200 мг в день. Это, например, 2 чашки кофе или 4 небольшие кружки зеленого чая. Женщинам-гипертоникам или даже при транзиторном повышении артериального давления только во время беременности эти напитки противопоказаны.

Таким образом, лучший источник жидкости – это, конечно же, чистая питьевая негазированная вода с доказанным безопасным составом. Так она меньше задерживается в организме, оптимальна для работы почек, сердечно-сосудистой системы,  эндокринной систем. И большое количество мифов и стереотипов, которые влияют на потребление беременными женщинами воды, связано с отеками. Их боится чуть ли не каждая будущая мама. 

Рекомендации

лучше, чтобы интервал между употреблением воды был 1,5- 2 часа;
рекомендуется пить воду небольшими, маленькими глотками;
рекомендуется всегда иметь при себе небольшую бутылку чистой негазированной воды;
весь объем жидкости должен быть равномерно распределен в течение дня.

${tb2c3_6}

Урок 8. Витамины и микроэлементы во время беременности 
Полноценное питание в период беременности – это основа основ. Ведь плод использует микро- и макроэлементы мамы, а также белок в качестве строительных частиц. И, конечно же, лучше всего обеспечить организм минералами и витаминами за счет полноценного питания. 

Беременным женщинам Всемирная организация здравоохранения рекомендует обязательный прием только фолиевой кислоты. Однако бывают ситуации, когда необходимо включать в свой рацион дополнительные витамины и минералы. Давайте подробно обсудим, какие витамины важны и какие микроэлементы особенно нужны в период беременности, а также разберемся, в каких продуктах питания содержатся те или иные витамины и микроэлементы.

Фолиевая кислота

Она необходима для синтеза ДНК, роста плода и плаценты и развития спинного мозга ребенка непосредственно в первый месяц беременности. 

Потреблять фолиевую кислоту рекомендуется женщинам репродуктивного возраста и тем, кто готовится к беременности. Женщинам с повышенным риском неблагоприятного анамнеза (а именно: сахарный диабет, целиакия, прием антиконвульсантов) рекомендуется принимать фолиевую кислоту в количестве 0,4 мг/сут. 

Риск нехватки или дефицита фолиевой кислоты возрастает у курящих и злоупотребляющих алкоголем женщин. Таким женщинам стоит обратить особое внимание на прием фолиевой кислоты и, конечно же, проконсультироваться с врачом, подобрав индивидуальную дозировку препарата.

Основными источниками фолиевой кислоты в рационе питания являются зеленые листовые овощи, брокколи, шпинат, брюссельская капуста, листовая капуста, зеленый салат, говяжья печень, а также бобовые, свекла, апельсины и томаты. Можно включать в ежедневный рацион свежие, термически  обработанные овощи (или необработанные), и прекрасно себя чувствовать.

Витамин D

Для его образования важно бывать на свежем воздухе. Прогулки в период беременности – это наше все! Если же говорить о продуктах питания,  много витамина D содержится в грибах, а также, например, в сливочном масле и рыбе. 

В группе риска дефицита витамина D находятся женщины, которые не проводят много времени на свежем воздухе, которые не употребляют в пищу рыбу и имеют ИМТ выше 30 кг/㎡, а также женщины с темным цветом кожи. Если женщина имеет серьезные заболевания, инвалидность, не имеет возможности часто находится на свежем воздухе, на прогулках, то советуем обязательно проконсультироваться с врачом по дополнительному приему витамина Д.

Йод

Он способен обеспечить потребности беременной женщины и нормальное развитие центральной нервной системы плода. Если во время беременности в организм поступают более низкие дозы этого витамина, чем рекомендовано, щитовидная железа беременной женщины не производит достаточное  количество гормонов щитовидной железы. И это приводит к нарушениям когнитивного, или поведенческого развития ребенка. 

Дозы для женщины с патологией щитовидной железы должны подбираться индивидуально после консультации с врачом-эндокринологом. Главным пищевым источником йода является рыба, морепродукты, а также молочные продукты. 

Железо

Потребность в нем возрастает в связи с повышенной потребностью в железе тканей плода и плаценты и с увеличением объемом крови и эритроцитов. 

Важно регулярно употреблять в пищу постное красное мясо, а также рыбу, цельнозерновые продукты и овощи. Витамин С (например сок цитрусовых) значительно усиливает всасывание железа. А вот черный и зеленый чай, а также кофе, цельнозерновые продукты, содержащие кальций продукты, его уменьшают. Поэтому, чтобы обеспечить всасывание железа, важно не употреблять железосодержащие продукты вместе с продуктами, которые препятствуют его всасыванию (например чай и молочные продукты), а соблюдать 2-часовой интервал между приемами этих продуктов. 

Кальций

Он нужен для правильного формирования скелета плода. Основным источником кальция в рационе являются, конечно же, молочные продукты (молоко, кефир, пахта, ряженка, йогурт, сыр и творог). И в случае непереносимости лактозы рекомендуется использовать обогащенное кальцием овсяное, миндальное или соевое молоко. Другими источниками кальция являются мелкая рыба с костями, миндаль, бобовые, брокколи и тыквенные семечки.

Витамин B6 

Он помогает будущей маме уменьшить тошноту и рвоту. Его основными пищевыми источниками являются мясо говядины, свинина и курица, а также рыба (лосось, тунец), а также бобовые, овес, бананы, сливы, авокадо и картофель. 

Витамин В12 

Содержится только в продуктах животного происхождения, например, в мясе, особенно в говядине (в том числе и в печени, однако беременным женщинам не рекомендуется употреблять ее в пищу). Также он содержится в молоке и молочных продуктах, в рыбе (скумбрии, сельди, тунце). Особенно высокое содержание этого витамина в морепродуктах (моллюсках и устрицах). 

Дефицит витамина  В12 очень часто может наблюдаться у веганов или у людей после операций, например, операция на желудочно-кишечном тракте. В таких случаях рекомендуется принимать витамин В12 дополнительно. Этот витамин вместе с фолиевой кислотой необходим для развития головного мозга ребенка, причем как для когнитивного, так и для моторного развития малыша.

Витамин С

Это антиоксидант, который нужен нам для синтеза коллагена. Организму беременной женщины дополнительно требуется 10 мг витамина С в сутки, и это количество необходимо обеспечить за счет продуктов питания. Много витамина С содержится в капусте, в томатах, перце, брокколи, клубнике, в ананасах, цитрусовых, черной смородине и киви.

Витамин А

Он необходим для формирования кожи и слизистых оболочек (в том числе слизистых оболочек желудочно-кишечного тракта и дыхательных путей), костей, зубов, а также для развития зрительной и иммунной функций.  Женщины, которые принимают медикаменты или пищевые добавки с витамином А, или ретинолом, должны прекратить их прием до наступления беременности. Во время беременности также нельзя использовать препараты с витамином А, в том числе и препараты рыбьего жира. 

Витамин А содержится в продуктах животного происхождения, а именно: рыба (лосось, тунец, сельдь, скумбрия), морепродукты, яйца, молоко и молочные продукты (особенно в сырах). Концентрация витамина А наиболее высокая в печени, поэтому беременным женщинам не рекомендуется употреблять в пищу печень и ее продукты. 

Витамин E 

Является важным антиоксидантом и содержится в растительных маслах (оливковом, подсолнечном, рапсовом), в цельнозерновых продуктах, в яичном желтке, орехах, а также в тыквенных, и подсолнечных, и кунжутных семечках.

Витамин K 

Необходим для здоровья костей. В изобилии его можно найти в темно-зеленых листовых овощах – брокколи, салате, шпинате, в меньшей степени в таких продуктах животного происхождения, как сыр, яйца и другие продукты. 

Медь

Ее можно встретить в морепродуктах (устрицах, ракообразных), цельнозерновых продуктах, бобах, орехах и субпродуктах животного происхождения. Пищевыми источниками меди также являются темно-зеленые листовые овощи и сухофрукты.

Магний

Во время беременности плод накапливает около 1 г магния в день. Достаточное количество магния в рационе особенно важно для женщин, которые страдают от судорог. Пищевыми источниками магния являются орехи, бобовые, цельнозерновые продукты, темно-зеленые листовые овощи.

Когда женщине все же нужен дополнительный прием питательных веществ (мультивитаминных и минеральных препаратов)?

Беременность с высокой степенью риска у женщин с пониженной массой тела или с другими нарушениями нутритивного статуса. 
Беременные женщины с зависимостями (злоупотребление наркотиками и другими вредными веществами).
Беременные девочки-подростки. 
Повторная беременность или интервал между родами меньше двух лет.
Беременные женщины, у которых в анамнезе имеется новорожденный с низким весом при рождении.
Многоплодная беременность. 
ОБРАТИТЕ ВНИМАНИЕ: медикаменты (как рецептурные, так и безрецептурные лекарства) следует принимать только под строгим наблюдением и контролем вашего врача!

Дефицит питательных микроэлементов особенно часто наблюдается во время беременности  у ВИЧ-позитивных женщин, у женщин с диагнозом СПИД. Их потребности в питательных веществах гораздо выше по сравнению с другими женщинами, поэтому предоставление добавок с питательными полимикроэлементами ВИЧ-позитивным беременным женщинам будет способствовать улучшению состояния здоровья как мамы, так и ее ребенка. Конечно же, не забываем о консультации с врачом. Врач обязательно посоветует безопасные для вас препараты.

${separator}

Урок 9. Соль и отеки: правила питания
Думаю, что ни для кого не секрет, что в период вынашивания ребенка, женщины замечают, что ноги, руки и лицо становятся вдруг пухлее, чем обычно. Могут быть тесноваты любимые туфли или с трудом налазить обручальное кольцо. Такие моменты вызывают у будущей мамы вопросы. В частности, почему отеки мучают женщину в период беременности? И как питание влияет на их появление?

Отеки беременных связаны с нарушением водно-солевого баланса в организме. В большей степени ответственны за такое равновесие два минерала – натрий и калий. В зависимости от их соотношения организм удерживает воду либо отдает ее. 

Механизм простой: клетка – это калий, а вне клетки (в межклеточном пространстве) находится натрий. Когда они в балансе, нет никаких отеков, есть прекрасное самочувствие,  хороший вес.  Когда между калием и натрием есть дисбаланс (например натрия гораздо больше, потому что много соли и сахара в рационе), он приводит к отекам и набору веса, а также к инсулинорезистентности, сахарному диабету. Чтобы калиево-натриевый баланс был в норме, сам калий тоже должен быть в норме. 

Откуда же его брать? Итак, калий мы получаем исключительно из растительной пищи, и здесь нам на помощь приходят овощи, зелень, фрукты. Их количество в рационе должно быть не менее 500 г в день. Рацион с высоким содержанием калия приводит к «вытеснению» натрия из организма и, следовательно, воды, а это помогает бороться с отеками. 

А вот натрий поступает в организм в основном с обычной поваренной солью. Он играет важную роль во внутриклеточном и межтканевом обмене веществ, в регуляции кислотно-щелочного равновесия и давления в клетках, давления в тканях и в крови, способствует накоплению жидкости в организме (то, что напрямую отвечает за отеки будущей мамы). 

Поэтому в  период беременности темы соли и отеков неразделимы между собой. Именно поэтому первое, что рекомендуется сделать, это ограничить количество соли в рационе питания. 

В соответствии с рекомендациями Всемирной организации здравоохранения, лучше выбирать йодированную соль. Рекомендуемое количество – это 4-5 г поваренной соли в день (это одна маленькая чайная ложка). Такое количество соли наряду с употреблением в пищу белка и достаточного количества жидкости необходимо для обеспечения адекватного количества циркулирующей крови. Это очень важно для предотвращения обезвоживания и преждевременных схваток. 

Лучше учитывать, что большинство людей ежедневно употребляют гораздо больше соли, чем 4-5 г (чем чайная ложка), поскольку большая часть соли поступает в организм с продуктами питания и лишь незначительная часть представляет собой добавленную соль. 

Как сократить потребление соли? 

ограничить количество соли и приправ с высоким содержанием натрия (например соевого соуса, рыбного соуса и бульона);
ограничить соусы с высоким содержанием натрия; 
ограничить потребление соленых закусок;
выбирать продукты с низким содержанием натрия.
Калий может смягчать негативное воздействие избыточного потребления натрия на кровяное давление. Как мы обсуждали ранее, поступление в организм калия можно увеличить путем потребления свежих фруктов и овощей. Также помните о том, что малое количество воды, которое пьет мама, приводит к большим отекам. В итоге мы имеем эффект, обратный желаемому. 

Вода – это важнейший пункт в рационе будущей мамы. В день в организм должно поступать от 2 до 2,5 л. И ее количество следует увеличивать по мере прогрессирования беременности и повышения веса женщины. 

В последние месяцы беременности необходимое количество воды возрастает на 300 мл в день. Рассчитывается норма жидкости, исходя из пропорции, – 35 мл воды на 1 кг веса.  

Если имеется склонность к отекам, то рекомендуется: 

Пить воду с промежутками 1,5-2 часа
Пить небольшими глотками
Пить воду комфортной температуры
Не пить обжигающую воду и напитки (кипяток, горячий чай)
Достаточное количество воды необходимо не только для обеспечения жизненно важных функций организма, но и для уменьшения риска инфекций мочевыводящих путей, камней в почках и запоров.

Тревожные симптомы

Сильные, постоянные отеки – это тревожный звоночек. Часто они сигнализируют о развитии гестоза, то есть позднего токсикоза. При этом состоянии почки беременной не справляются с увеличенной нагрузкой, и это чревато негативными последствиями как для женщины, так и для малыша.

При подозрении на отеки беременной женщине рекомендуется вести специальный дневник (т.е. записывать количество воды, которое было выпито за день). И постарайтесь ежедневно взвешиваться и фиксировать свой вес. Это поможет своевременно заметить серьезные нарушения в работе выделительной системы. 

Обсудим также некоторые тревожные признаки:

Отеки появляются в 1-м триместре беременности.
Отеки локализуются не только на нижних конечностях, но и на руках, животе, лице и могут быть асимметричными. 
Отеки не проходят, а также могут увеличиваться после ночного сна.
Отеки сопровождаются снижением выделения мочи, повышенным артериальным давлением, головными болями, мигренями, нарушением зрения, болями в поясничной области, слабостью, одышкой.
В подобных случаях необходимо немедленно обратиться к врачу.


    `,
};
