import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { NotificationMark } from '../NotificationMark/NotificationMark';
import { ProfileTitle } from '../ProfileTitle/ProfileTitle';
import styles from './TitleSection.module.css';

export const TitleSection: FC = () => {
  const { isTablet, isMobile } = useAppWindowSize();
  return (
    <div className={styles.container}>
      <ProfileTitle />
      {!isTablet && !isMobile && <NotificationMark />}
    </div>
  );
};
