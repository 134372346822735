import ExpertInfo from 'components/ExpertInfo/ExpertInfo';
import LabelContainer from 'components/LabelContainer/LabelContainer';
import { CourseData } from 'types';
import { Expert } from 'types/items';
import { getLessonsCaption } from 'utils/time';
import CategoryTitle from '../CategoryTitle/CategoryTitle';
import DescriptionBlock from '../DescriptionBlock/DescriptionBlock';
import NotMobileIcons from '../NotMobileIcons/NotMobileIcons';
import styles from './CategoryInfo.module.css';
import FavoriteContainer from 'components/FavoriteContainer/FavoriteContainer';

type CategoryVideoProps = {
  lessonLength: number;
  title: string;
  order: string;
  isMobile: boolean;
  isTablet: boolean;
  description: string;
  experts: Expert[];
  showFullDescription: boolean;
  toggleDescription: () => void;
  item: CourseData;
  duration?: number;
};
function CategoryInfo({
  lessonLength,
  title,
  order,
  duration,
  isMobile,
  description,
  experts,
  showFullDescription,
  item,
  toggleDescription,
}: CategoryVideoProps) {
  return (
    <div className={styles.infoContainer}>
      <CategoryTitle item={item} title={title} order={order} />
      <div className={styles.clockWrapper}>
        <div className={styles.clockContainer}>
          <LabelContainer
            courseText={`${lessonLength} ${getLessonsCaption(lessonLength)}`}
            duration={duration}
          />
        </div>
        <div className={styles.iconContainer}>
          <FavoriteContainer item={item} />
        </div>
      </div>
      {!isMobile && <NotMobileIcons item={item} experts={experts} />}

      <div className={styles.descriptionContainer}>
        {!!experts?.length &&
          isMobile &&
          experts?.map((expert: Expert) => (
            <ExpertInfo key={expert.id} item={expert} />
          ))}
        {description && (
          <DescriptionBlock
            isMobile={isMobile}
            showFullDescription={showFullDescription}
            //@ts-ignore
            description={description}
            toggleDescription={toggleDescription}
          />
        )}
      </div>
    </div>
  );
}

export default CategoryInfo;
