import { FC, memo } from "react";
import styles from "./StoreLink.module.css";
import {
  AppStoreDarkIcon,
  AppStoreIcon,
  GooglePlayDarkIcon,
  GooglePlayIcon,
} from "assets/icons";
import { Providers } from "constants/constants";
import classNames from "classnames";

const config = {
  [Providers.GOGGLE]: {
    link: "https://play.google.com/store/apps/details?id=health.mamapro.mproapp&pli=1",
    icon: (dark: boolean) => (dark ? GooglePlayDarkIcon : GooglePlayIcon),
  },
  [Providers.APPLE]: {
    link: "https://apps.apple.com/us/app/mama-pro-%D0%B1%D0%B5%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82%D1%8C-%D0%B8-%D1%80%D0%BE%D0%B4%D1%8B/id6449796254",
    icon: (dark: boolean) => (dark ? AppStoreDarkIcon : AppStoreIcon),
  },
};

export const StoreLink: FC<{
  provider: Providers;
  dark?: boolean;
}> = memo(({ provider, dark = false }) => {
  const link = config[provider].link;
  const Icon = config[provider].icon(dark);

  return (
    <a
      href={link}
      className={styles.container}
      target="_blank"
      rel="noreferrer"
    >
      <Icon className={classNames(styles.icon, dark && styles.iconDark)} />
    </a>
  );
});
