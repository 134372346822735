import { FC, useCallback } from 'react';
import { ListButton } from '../ListButton/ListButton';
import { useTranslate } from 'hooks/useTranslate';
import { ArrowRightProfileIcon, FavoritesProfileIcon } from 'assets/icons';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'navigation/constants';

export const Favorites: FC = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(AppRoutes.FAVORITE_PAGE);
  }, []);

  return (
    <>
      <ListButton
        iconLeft={FavoritesProfileIcon}
        iconRight={ArrowRightProfileIcon}
        title={t('profile_favorites')}
        big
        onPress={handleClick}
      />
    </>
  );
};
