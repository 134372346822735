import './Introduction.style.css';
import { useTranslate } from 'hooks/useTranslate';

function Introduction() {
  const t = useTranslate();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Центрирование по горизонтали
        justifyContent: 'center', // Центрирование по вертикали
        margin: '20px', // Внешние отступы
        padding: '20px', // Внутренние отступы
        textAlign: 'center', // Центрирование текста
      }}
    >
      <h1> {t('greeting')}</h1>
      <div className="h_one_desc">{t('welcome_message')}</div>
      <div className="h_one_desc_two">{t('program_steps')}</div>
    </div>
  );
}

export default Introduction;
