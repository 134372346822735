import { certificate } from "assets/images";
import { useTranslate } from "hooks/useTranslate";
import { useNavigate } from "react-router-dom";
import "./CertificatePageMobile.style.css";

function CertificatePageMobile(): JSX.Element {
  const t = useTranslate();
  const navigate = useNavigate();
  const handleReturnToHome = () => {
    navigate("/");
  };

  return (
    <div className="container">
      <div style={{ display: "flex" }}>
        <div className="wrapper">
          <h1 className="certificate-title">{t("certification_ready")}</h1>
          <p className="certificate-ready-text">
            {t("certificate_text_title")}
          </p>
          <p className="certificate-ready-text">
            <strong>{t("certificate_text_subtitle")}</strong>
          </p>
        </div>
        <div>
          <img src={certificate} className="image" />
        </div>
      </div>
      <div className="buttons-container">
        <button
          type="submit"
          className="download-button"
          onClick={handleReturnToHome}
        >
          <div className="button-filler" />
          <span style={{ textAlign: "center" }}>{t("go_to_main_page")}</span>
        </button>
        <img src={certificate} className="image-small" />
      </div>
    </div>
  );
}

export default CertificatePageMobile;
