import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

import { notFoundSearchConfig } from '../StudySearch/StudySearch.utils';
import styles from './SearchNotFound.module.css';

type NotFoundViewProps = {
  onKeywordSelect: (keyword: string) => void;
};

export const SearchNotFound: FC<NotFoundViewProps> = ({ onKeywordSelect }) => {
  const t = useTranslate();
  return (
    <div className={styles.wrapper}>
      <div>
        <p className={styles.quantity}>{t('search_result_quantity')} 0</p>
        <p className={styles.caption}>{t('search_not_found_message')}</p>
      </div>
      <div className={styles.chips}>
        {notFoundSearchConfig.map(({ keyword }) => (
          <div
            key={keyword}
            className={styles.chipWrapper}
            onClick={(e) => {
              e.stopPropagation();
              onKeywordSelect(keyword);
            }}
          >
            <p className={styles.keyword}>{keyword}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
