import {
  separator,
  tb2c2_1,
  tb2c2_10,
  tb2c2_11,
  tb2c2_12,
  tb2c2_13,
  tb2c2_2,
  tb2c2_3,
  tb2c2_4,
  tb2c2_5,
  tb2c2_6,
  tb2c2_7,
  tb2c2_8,
  tb2c2_9,
} from 'assets/images';

export const b2_c4_text = {
  b2_c2_title: `Курс «Внутриутробное развитие ребенка»`,
  b2_c2_description: `Спикер: Вероника Томашева, врач УЗИ, врач акушер-гинеколог`,
  b2_c4_text: `
    ${tb2c2_1}
Урок 1. Беременность малого срока. 1 триместр – от эмбриона до плода

Внутриутробное развитие человека в норме продолжается 280 суток, то есть 39-40 недель, считая с первого дня последних месячных. 

За это время из двух слившихся клеток – сперматозоида и яйцеклетки – формируется уникальный человек, способный жить отдельно от маминого организма.

Медицинская традиция условно делить период беременности на три периода (триместра) не случайна. В каждом из этих периодов будущая мама чувствует себя по-разному. И малыш в каждом из периодов развивается по своим законам.

1 триместр – с 1 по 12-13 неделю беременности

2 триместр – с 13 по 26-27 неделю беременности

3 триместр – с 27 по 40 неделю беременности

Началом начал является клетка. В начале каждого менструального цикла в организме женщины  в яичнике начинает созревать 1 яйцеклетка. Чаще всего одна, реже – две. Примерно через две недели происходит овуляция – то есть выход яйцеклетки из яичника. Время жизни яйцеклетки совсем непродолжительное – 12-48 часов. Тогда как сперматозоиды живут до 5 дней. Если в это время яйцеклетка встречается со сперматозоидом, происходит оплодотворение, или зачатие. 

${tb2c2_2}

В сложном процессе оплодотворения задействована только одна (максимум две) яйцеклетки и около 300 миллионов сперматозоидов! Из которых только один станет победителем, проникнет внутрь яйцеклетки, а остальные погибнут.

После соединения родительских половых клеток формируется одна новая клетка. Она называется зигота. Она начинает интенсивно делиться и двигаться из маточной трубы в полость матки. Параллельно делению происходят закладки всех органов и тканей, формирование всех систем.

Приблизительно на 7-ые сутки после оплодотворения плодное яйцо приплывает в матку и внедряется в слизистую оболочку полости матки, то есть происходит имплантация. 

И со второй недели после оплодотворения стартует эмбриональный период развития человека. И он продолжается до 10-11 акушерских недель. В этот период плод мы называем «эмбрионом». 

3-4 неделя 

Закладываются три основных слоя клеток, из которых формируются все системы будущего малыша. Длина эмбриона увеличивается от 0,5 до 5 миллиметров. Размер эмбриона в этот период приблизительно равен маковому семечку.

Уже к концу третьей недели жизни эмбриона закладывается сердце.

5 неделя 

В это время женщина обычно узнает о своей беременности по задержке очередных месячных и по положительному тесту на беременность. В этот момент плодное яйцо уже окончательно прикрепилось к стенке матки. Эмбрион активно развивается. 

Маленькое сердце уже бьется и осуществляет круговорот крови по организму. 
Формируются зачатки мышечной, костной, нервной тканей.
Чтобы избежать пороков развития нервной трубки, важно, чтобы в организме мамы было достаточно фолатов – это соединения, которые необходимы для правильного формирования нервной системы. И важно начинать прием фолатов уже на этапе планирования беременности, чтобы насытить ими свой организм. Именно поэтому прием фолиевой кислоты должен начинаться как можно раньше. И в 1 триместре всем беременным рекомендуется принимать фолиевую кислоту.

Эмбрион в размерах сейчас примерно как яблочное семечко. Он свернут в форме дуги. На УЗИ это имеет вид обручального кольца, где эмбрион – это камень, а само колечко – это желточный мешок. Это важный элемент, который обеспечивает питание эмбриона на ранних этапах.

${tb2c2_3}

6 неделя

Длина эмбриона – около 6 миллиметров. 

Формируются основные органы и системы, нервная система, глаза, сердце, сосуды, мозг, дыхательная система, кишечник, печень, почки.
Образуются бугорки ручек и ножек. 
Происходит закладка пальчиков.
7 неделя 

Эмбрион размером с небольшую фасолину. 

Намечаются черты лица.
Появляется вестибулярный аппарат.
Происходит закладка временных зубов. 
Определяются грудь и животик, которые в этом сроке равны по размеру. Также выделяются плечики. Существуют все значимые элементы тела, но пока они еще не полностью сформированы.
Длина эмбриона при этом около 18 миллиметров. По размерам он похож на ягоду черной смородины. 

Начинают формироваться половые железы. 
Заканчивается формирование пуповины, через которую малыш скоро будет получать питание от мамы. До этого срока эту функцию выполняет желточный мешок.
8 неделя

Эмбрион в длину около 2 см – как ягода черешни. 

Черты лица становятся более выразительными, четко проглядываются нос, глаза, челюсти. Будущий малыш все меньше и меньше становится похожим на инопланетянина.
У него быстро растут ручки, ножки. Он уже может активно сгибать их в локтях и коленях. И в целом он довольно активен, хотя из-за его малых размеров мама этого еще не ощущает.
9 недель

В этом сроке головка ребенка достаточно крупная. А общий размер малыша можно сравнить с виноградиной.

Активно формируются половые железы. У девочек образуются яичники, а в них – уже первые яйцеклетки. А у мальчиков формируются яички.
Генетический пол плода определяется при оплодотворении – в зависимости от того, какую хромосому X или Y получает он от сперматозоида. Если получает X хромосому – будет девочка. Если Y – будет мальчик. Какого пола малыш, будущие мама и папа смогут узнать на УЗИ после 15 недель беременности.

10 неделя

Самое удивительное в это время – это развитие головного мозга. Процесс происходит с невероятной скоростью. Каждую минуту образуются все новые цепочки нейронов. 

Основные внутренние органы располагаются правильно, они продолжают совершенствоваться.
Начинается синтез половых гормонов. У девочек это эстрогены, у мальчиков – тестостерон.
11-12 неделя

Это финал первого триместра беременности. Совсем скоро малыш уже будет называться не эмбрионом, а плодом. Он активно и много двигается, тренируя свою мышечную систему. Тем более что делать это просто – он окружен околоплодными водами, то есть фактически он в них плавает.

Мама по-прежнему еще не ощущает эти движения, ведь размер эмбриона примерно как слива. Но его тело быстро растет, приобретает все более пропорциональный вид.

Сердце плода бьется быстро, до 160 ударов в минуту. И это неудивительно! Ведь малыш стремительно растет. Требуется большое количество питательных веществ. Процессы обмена веществ ускорены. 
К 12 неделям хорион, который раньше окружал плодное яйцо, трансформируется в плаценту. И плацента берет на себя самую главную функцию – жизнеобеспечения плода (то есть это дыхание, питание, защита и выделение гормонов, направленных на сохранение беременности).

${tb2c2_4}

Все внешние изменения, которые происходят с малышом, можно наблюдать на ультразвуковом исследовании. На сроке 11-13 недель будущую маму ждет первое УЗИ. 

Критические периоды беременности. 1 триместр

Это особые периоды в развитии малыша, которые требуют повышенного внимания. В эти периоды усиливается обмен веществ, он идет наиболее активно, и делящиеся клетки особенно чувствительны к разным повреждающим факторам. И будущая мамочка, и малыш становятся очень уязвимыми. 

В первом триместре таких критических периода три.

Первый период – 2-3 недели беременности. Это время, когда женщина еще может даже не знать о своей беременности. Вот почему врачи так часто говорят о важности планирования беременности. 

Это период имплантации плодного яйца. Период может нарушаться:

если в прошлом были травмы эндометрия (внутреннего слоя матки);
из-за абортов;
из-за воспалительных заболеваний;
если у женщины есть миома матки, рубец на матке после кесарева сечения или других операций;
при аномалиях строения матки.
Кроме того, правильной имплантации могут препятствовать сбои в работе маминого организма – это стрессы, тяжелая физическая нагрузка, смена климата и так далее. Также опасны инфекции. Конечно, более опасны острые инфекции и инфекции, с которыми мамин организм до этого не сталкивался и с которыми не знаком мамин иммунитет. Наиболее изучены и имеют важное значение такие инфекции, как ветряная оспа, краснуха, цитомегаловирус, парвовирус, токсоплазма.

Еще одна причина – это гормональные или генетические аномалии развития плода. То есть своего рода естественный отбор. К этой важной теме мы вернемся, когда будем обсуждать комбинированный скрининг первого триместра беременности.

Второй период – 4-6 недель беременности. Это период, когда активно делятся все клетки, формируются все органы и системы малыша. Особенно чувствительны к неблагоприятным воздействиям нервная и эндокринная система. Поэтому важно максимально исключить в этот период отрицательные факторы и вредные привычки.

Третий период – 11-12 недель. Это период активного формирования плаценты. Плацента выполняет множество функций. Поэтому ее неправильное формирование вызывает осложнения беременности (прежде всего – невынашивание).

Беременность малого срока – это особое состояние, о котором мама узнает, когда основные важные процессы в жизни будущего малыша уже произошли. Поэтому крайне важно планировать беременность. Важно стабилизировать хронические заболевания, пролечить инфекции, сделать здоровый образ жизни привычным для вас. Не забывать про прием фолатов. Женщине рекомендуется начать прием за 3 месяца до планируемой беременности, мужчине – за 1 месяц. Далее прием фолиевой кислоты беременная продолжает до срока 12 недель. 

Также важно пройти обследования на хронические инфекции, в том числе передающиеся половым путем, обследование на сифилис и ВИЧ.

Рекомендации для ВИЧ-позитивных будущих мам 

ВИЧ-позитивным женщинам нужно выполнять те же рекомендации, что и остальным беременным. По данным Всемирной организации здравоохранения риск передачи ВИЧ от мамы к малышу возрастает при естественных родах, грудном вскармливании, а также в случае, если женщина не принимает антиретровирусные препараты. Поэтому важно командная работа с врачом и соблюдение всех рекомендаций. Тогда такая женщина, конечно, сможет насладиться счастьем материнства.

Рекомендации для беременных с сахарным диабетом

Повышение в крови уровня глюкозы негативно влияет и на организм мамы, и на организм малыша. Поэтому важно не только принимать инсулин, но и с ответственностью относится к визитам к врачу. Будущим мамам с сахарным диабетом чаще показана плановая госпитализация.

Рекомендации для беременных с бронхиальной астмой

Опасность бронхиальной астмы в том, что для малыша достаточно вредны частые приступы будущей мамы. Они могут вызывать кислородное голодание малыша, и как следствие – задержку его роста и развития. Поэтому обязательно соблюдайте рекомендации врача по лечению.

Безусловно, нельзя не отметить такие негативные факторы как курение, злоупотребление алкоголем, наркотическими средствами. Они, во-первых могут приводить к снижению способности к зачатию. А во-вторых, по сути отравляют организм. Такие психоактивные вещества токсичны для развивающегося плода в течение всей беременности. Они истощают резервные возможности организмов и мамы, и малыша. А также это факторы, которые способствуют развитию серьезных осложнений. При невозможности самостоятельно справиться с проблемой крайне рекомендуем женщине обратиться за помощью к специалистам.

${separator}

Урок 2. 2 триместр. Как развивается плод

Во втором триместре, с 14 по 26-27 неделю беременности, плод совершает гигантский скачок в росте и развитии.

В этот период:

Головной мозг малыша многократно увеличивается. 
У малыша образуются извилины. 
Развитие головного мозга и органов чувств позволяют ребенку реагировать на свет и на звуки. Резкий звук может испугать малыша, а голос мамы – успокоить.
Ребенок начинает совершать целенаправленные движения, реагировать на сигналы из внешнего мира.
14-17 недель

Вес ребенка около 100 грамм, длина малыша – около 10-11 см. По размеру его можно сравнить с крупным авокадо.

У малыша появляются ресницы, бровки. 
Плод покрыт специальной смазкой, которая защищает его кожу и сохраняет тепло. 
Появляются первые волосики на коже, которые задерживают смазку и защищают ребенка от переохлаждения. Они называются лануго. 
Работают почки ребенка. Они выделяют мочу в околоплодные воды.
Работает кишечник малыша. В нем образуется меконий – первичный кал.
Ножки плода растут с большой скоростью, и тело становится уже более пропорциональным.
16-17 неделя 

К концу 16 недели малыш уже может открывать глаза. С 17 недели начинает функционировать иммунная система. Теперь, если мама заболеет, ребенок сможет себя защитить.

18-22 недели 

Вес малыша около 400 грамм, длина достигает 28 сантиметров. По размеру его можно сравнить с размером среднего кокоса.

Малыш все более активный, его движения все более скоординированные. Он кувыркается, толкается, сжимает и разжимает кулачки. Но большую часть суток он все же еще спит.

В сроках 18-21 неделю беременности будущую маму ждет 2-ой УЗИ скрининг плода. 

Малыш все больше внешне напоминает новорожденного. Тело имеет правильные пропорции. И в середине второго триместра – примерно в 20 недель – будущая мама начинает чувствовать шевеления ребенка. При повторной беременности, как правило, это происходит раньше – в 18-19 недель. 

Многие мамы описывают это ощущение как «бабочки в животе». Такое общение с малышом очень важно и приятно для будущих мамы и папы. И кроме этого, по количеству шевелений мы можем судить о самочувствии малыша.

Также в этот период у малыша начинает работать эндокринная и пищеварительная системы. 

23-26 недель

Вес плода достигает килограмма, рост малыша уже до 30 сантиметров. По размеру его можно сравнить с небольшой дыней.

Происходит активное развитие головного мозга малыша, увеличивается количество нейронных связей, закладываются основы когнитивного мышления – это память и внимание. Малыш тонко чувствует настроение мамы, реагирует на него. Поэтому важно избегать стрессов, помнить об эмоциональном здоровье. 
Малыш реагирует на громкие звуки, а голос мамы успокаивает его.
Тактильные ощущения, то есть поглаживание малыша, больше важны для мамы. Коммуникация с малышом уже активно начинается. Разговаривайте с ним, слушайте хорошую музыку, читайте книги, смотрите любимые сериалы.
В легких начинает вырабатываться сурфактант – это очень важное вещество, которое препятствует слипанию легких, а еще оно жизненно необходимо для правильной работы легких малыша после рождения.
Плод сейчас больше набирает в весе, чем увеличивается в длину. Все органы и системы совершенствуются, происходит отвердение костей, наращивание мышц, накопление подкожно-жировой клетчатки. 

26-27 недель 

Черты лица уже полностью оформились. На УЗИ можно даже понять, на кого больше похож малыш – на маму или на папу.

В эти сроки места в матке еще достаточно много, поэтому малыш свободно и много передвигается, многократно меняет свое положение в матке в течение дня.

${tb2c2_5}

2 триместр – самый спокойный период беременности. Но при этом многое меняется: растущая матка давит на мочевой пузырь и кишечник, поэтому возможны более частые позывы к мочеиспусканию, склонность к запорам. Возможны боли в пояснице, области лона. У будущей мамы появляются ложные схватки. 

Дорогие мамы, если вам что-то непонятно в вашем состоянии – обязательно обсудите это со своим врачом. Также помните про эмоциональное здоровье, сбалансированное питание и хорошее настроение.

${separator}

Урок 3. 3 триместр. Развитие малыша накануне родов

Будущая мама все ближе и ближе к важному событию – рождению малыша.

В этот период малыш активно набирает вес. Все органы и системы готовятся к появлению на свет. У малыша начинает работать собственный иммунитет. Активно работает кишечник, развивается дыхательная система.

28-32 недели

Малыш начинает общаться с мамой и папой, развивается его эмоциональная сфера. Он может различать голоса, реагирует на поглаживания или подталкивания. Если ему что-то не нравится – он сигнализирует об этом.
У малыша налаживается режим сна и отдыха. К сожалению, чаще он не совпадает с маминым. Когда мама двигается – малыш спит, стоит маме прилечь – он начинает активно двигаться. Это можно объяснить своего рода эффектом укачивания в люльке при маминой активности. Что интересно, этот режим сна и бодрствования, который устанавливается внутриутробно, сохраняется еще некоторое время после рождения.
Постепенно исчезают мелкие первичные волосики, покрывающие тело малыша. 
Уже хорошо развиты органы чувств, отмечается непроизвольная мимика. Он открывает и закрывает ротик, достает язык, иногда отмечается непроизвольная улыбка. Он приоткрывает и прищуривает глазки, ведь в основном глазки малыша во время внутриутробной жизни закрыты.
32-33 недели

При первой беременности ребенок обычно уже занимает удобное положение в матке. Чаще всего это положение головкой вниз, в котором он и остается до родов, так как свободного места в матке для какого-то более активного и глобального изменения положения уже нет.

Также малыш может лежать в матке косо, поперечно, а может попой вниз. Все же до 36 недель беременности его положение в матке может измениться. Иногда это происходит самопроизвольно, иногда – с помощью специальной гимнастики. А иногда не происходит вовсе.

Установить положение плода в матке можно на УЗИ. Как раз беременные в 33-35 недель проходят очередной 3-ий УЗИ-осмотр. 

В случае поперечного или косого положения плода, планируются роды путем операции кесарево сечение. В случае тазового предлежания возможны естественные роды. Все решается индивидуально.

33-37 недель

Нервная система совершенствуется. У малыша есть полноценный сон. Он состоит из быстрой и медленной фаз, как у нас с вами. 
Происходит тренировка дыхательной системы, сосательного и глотательного рефлексов. На УЗИ это тоже можно увидеть. Условно дыхательные движения – это сокращения мышц диафрагмы и животика. Также на УЗИ мы часто видим, что малыш держит во рту пальчик, несколько пальчиков или даже кулачок. И малыш делает своего рода сосательно-глотательные движения.
Созревает кровеносная система, стенки сосудов утолщаются и укрепляется.
В этот период изменяется количество околоплодных вод – оно уменьшается. 
Малыш постепенно опускается в полость малого таза. В этот период мама чувствует, что ей становится легче дышать, а давление на спину уменьшается. Мамы часто говорят «опустился живот». Это не значит, что роды совсем близко. Но это говорит о том, что организм к ним уже готовится. Происходит перестройка гормонального фона в организме мамы и происходят процессы созревания родовых путей.
К 36 неделям кожа малыша, ранее морщинистая, выравнивается. 
Все органы к этому сроку уже сформированы. Малыш меньше двигается и больше спит, готовясь к предстоящему появлению на свет. 
38-40 недели

Плод весит более 3 килограмм, достигает в длину более 50 см. И хотя у малыша осталось мало места для совершения движений, благодаря развитию головного мозга эти движения осознанные и слаженные. Кости малыша окрепли, но кости черепа остаются мягкими и гибкими. Это необходимо для прохождения по родовым путям. Ребенок полностью готов к появлению на свет.

Беременность считается доношенной с 38 до 42 недель. При появлении на свет на этом сроке малыш полностью созрел. 

Чем опасна недоношенность? Прежде всего, незрелостью малыша. А именно тем, что у него незрелые головной мозг, кишечник, легкие. Он еще не может правильно и качественно дышать, переваривать пищу.

Что касается переношенной беременности, то и здесь есть опасные моменты для малыша. Это и плотность костной ткани, костей черепа. Ведь во время родов кости будут сдавливаться и могут повредить головной мозг ребенка. Также нарушается питание малыша из-за старения плаценты и нарушения доставки питательных веществ к ребенку.

${tb2c2_6}

Урок 4. УЗИ во время беременности. 1 триместр

Существуют определенные сроки беременности, когда женщине рекомендуется выполнять УЗИ. Именно в эти сроки мы можем обнаружить определенные отклонения и определить тактику ведения беременности. 

Это, как правило, три УЗИ на сроках:

11-13 недель
18-21 недели
33-35 недель
По назначению врача на любом сроке беременности могут проводиться внеплановые УЗИ. Не стоит переживать, если вам их назначили. Они помогут уточнить диагноз, а также своевременно назначить лечение и сохранить здоровье мамы и малыша.

Опасна ли процедура УЗИ для малыша? 

Основной принцип работы врача ультразвуковой диагностики в акушерстве – использовать настолько мало ультразвука, насколько это возможно. На экране монитора всегда отображаются специальные индексы, говорящие, в какой зоне безопасности мы находимся. Также врачи имеют строгие руководства, в которых прописаны требования к УЗИ-осмотру во время беременности.

О безопасности УЗИ говорит и Всемирная организация здравоохранения: «УЗИ считается процедурой с высокой степенью безопасности при минимальных известных побочных эффектах. Из-за отсутствия излучения это исследование широко используется во время дородового наблюдения». 

Таким образом, УЗИ-осмотры не будут влиять на состояние малыша, а лишь помогут нам убедиться, что с ним все хорошо.

Как подготовиться к процедуре УЗИ?

Специальной подготовки УЗИ плода не требует. 

Оно выполняется при пустом мочевом пузыре, т.е. перед приемом необходимо сходить в туалет. 
Время суток не влияет на УЗИ-показатели. 
Желательно, чтобы беременная не была голодной. 
С собой на УЗИ-исследование необходимо взять паспорт и обменную карту. Обменная карта во время беременности является вашим вторым паспортом.
Для ультразвукового исследования малыша используют в основном два вида доступа:

Через живот 
Через влагалище. Этот вид доступа является безопасным и для малыша, и для беременной. Он позволяет более четко видеть необходимые структуры, ведь расстояние между датчиком и плодом сокращается. 
При осмотре через живот иногда доктор просит повернуться маму направо или налево. Иногда доктор может чуть подталкивать малыша датчиком или свободной рукой. Так доктор пытается изменить положение малыша, чтобы рассмотреть его более детально. Малыш при этом никакого дискомфорта не испытывает.

УЗИ и беременность малого срока

Беременность малого срока видна при ультразвуке не сразу. Поэтому, дорогие мамы и папы, не стоит спешить на УЗИ на 1-2 день задержки месячных. Какое-то время плодное яйцо путешествует по маточной трубе. И на УЗИ подтвердить наличие беременности в полости матки мы можем только тогда, когда четко видим плодное яйцо.

{b3c1l6}

Спустя несколько дней внутри плодного яйца появляется желточный мешок (это первичный источник питания и кроветворения эмбриона) и сам эмбрион. Внешне это имеет вид обручального кольца. При этом желточный мешок имеет вид колечка, а эмбрион – вид драгоценного камня на этом обручальном кольце.

${tb2c2_7}

Сердцебиение эмбриона появляется примерно на 5 неделе. Сначала оно достаточно редкое, потом учащается до 170-180 ударов в минуту. К 12-13 неделе оно снижается до 150-155 ударов в минуту.

Когда мама может отправиться на первое УЗИ? Чтобы подтвердить беременность, можно выполнить УЗИ через 14-16 дней после задержки месячных. Если у женщины в прошлом были проблемные, неудачные беременности, сроки осмотров могут изменяться.

Эмбрион стремительно растет, примерно по миллиметру в день. И конечно, внешне его изменения очевидны. Если в 5-6 недель он имеет вид комочка, то в 7-8 уже четко можно отличить головной и тазовый концы, в 9-10 начинают дифференцироваться конечности.

Возможности УЗИ-приборов делают доступным изучение различных отклонений в развитии плода уже на таких малых сроках.

Первое плановое УЗИ

Вашему малышу уже целых 12 недель. Уже сформированы все органы и системы, начинается их активный рост. Перед нами – настоящий человек. Он плавает в околоплодных водах и при помощи пуповины прикреплен в плаценте.

В 12-13 недель плод готов к первому скрининговому УЗИ-осмотру. Скрининг – это исследование, которое рекомендуется абсолютно всем беременным.

Что оценивается в этом сроке?

Точно устанавливается количество плодов, срок беременности и дата предстоящих родов – так называемый ПДР.
Исключается большое число серьезных отклонений в строении ребенка.
Оцениваются маркеры хромосомных отклонений.
Составляется прогноз возможных осложнений в течение беременности.
На первом УЗИ мама увидит примерно такое изображение.

${tb2c2_8}

Четко определяется головка, туловище, ручки и ножки малыша. Видны ребра, позвоночник. Четко можно видеть сердцебиение плода.

Врач обязательно измерит ребенка. По размеру плода определяется срок беременности и устанавливается ПДР. Делать это непросто, ведь плод небольшого размера и достаточно активен. Измеряется рост плода сидя – то есть без учета ножек. Это так называемый копчико-теменной размер (КТР).

Также доктор может порадовать вас красивой картинкой в объемном 3D или 4D изображении. 4D-изображение – это опция приборов экспертного, высокого класса, которые находятся в определенных центрах.

В этом сроке размер ребенка равен примерно длине банковской карточки. Но несмотря на маленький размер ребенка, в этом сроке можно исключить грубые врожденные пороки развития головного мозга, позвоночника, сердца, конечностей ребенка. 

В 12 недель врач оценивает кожно-шейную складку ребенка. Это маркер хромосомных отклонений. Численное значение шейной складочки оценивается вместе со значением длины носовой косточки ребенка и кровотоками в определенных сосудах ребенка. 

Почему это так важно? Доказано, что эти маркеры (шейная складка, носовая кость, кровотоки в определенных сосудах плода) очень помогают в определении возможного риска наличия у ребенка хромосомных изменений. Наиболее известный из хромосомных изменений – это синдром Дауна.

Также на первом УЗИ-осмотре оценивается плацента, ее расположение, состояние яичников. Иногда мы выявляем кисты яичников. Но не спешите расстраиваться, в большинстве случаев эти кисты связаны с беременностью и сами уменьшаются и даже исчезают на более поздних сроках.

${separator}

Урок 5. УЗИ во время беременности. 2 и 3 триместр

Второе плановое УЗИ – 20-22 недели 

Придя на второе УЗИ, будущие родители могут увидеть такое изображение.

${tb2c2_9}

На этом УЗИ, как и на первом, врач измеряет ребенка. Но не целиком, а по частям – отдельно измеряется головка, животик, бедренная кость. Исходя из этих параметров, определяют вес малыша. На этом сроке он равен примерно 280-390 грамм. Рост ребенка на УЗИ не измеряется.

Также осматривается окружение ребенка – то есть количество околоплодных вод, в которых он плавает, состояние и расположение плаценты. Не переживайте, если доктор сказал вам, что плацента располагается низко. Дело в том, что плацента перемещается до 28-29 недель беременности. Это связано с тем, что матка растет, растягиваются ее стенки. Поэтому местоположение плаценты меняется.

На этом осмотре также проводится допплерометрия – то есть изучаются кровотоки в сосудах матки, маточных артериях и в сосудах плода, сосудах пуповины. По этим показателям можно судить о самочувствии малыша, понять, все ли с ним хорошо. Допплерометрия проводится всем мамам на этом скрининговом осмотре, показаний врача для этого не нужно.

Также измеряется шейка матки. Это очень важно, особенно если в прошлом у женщины были проблемные беременности. Если длина шейки матки недостаточная, гинеколог обязательно назначит соответствующее лечение.

На этом УЗИ доктор может озвучить пол ребенка. Пол малыша устанавливается по наружным половым органам. Обычно это достаточно очевидно, но бывают ситуации, когда малыш или находится в состоянии покоя (то есть спит), или петли пуповины, ручки, ножки находятся в области промежности. Тогда определить пол становится непростой задачей. В таком случае доктор может попросить маму повернуться с боку на бок, покашлять или даже немного походить в кабинете или коридоре, чтобы дать возможность и время малышу изменить положение.

Заранее предугадать поведение малыша во время УЗИ-осмотра невозможно. Если он в хорошем приветливом настроении, мы можем увидеть разнообразные движения нижних и верхних конечностей. Можем увидеть, как малыш разгибает и сгибает кулачки, сгибает и разгибает головку, позвоночник. И даже делает полный переворот с ног на голову!

В этом сроке также на УЗИ исключается множество врожденных пороков развития – пороков сердца, желудочно-кишечного тракта, почек, конечностей, лица. 

Наиболее приятные и памятные 3 или 4D изображения получаются как правило в 27-29 недель. Возможность получить эти объемные изображения зависит от аппарата, на котором вам проводят УЗИ.

Третье плановое УЗИ – 32-34 недели

На этом сроке обычно малыш уже занимает окончательное положение в матке. Если головка располагается внизу – это называется головное предлежание. Это самый лучший вариант. Если внизу находится тазовый конец, то есть попа малыша – то это называется тазовое предлежание.

Проводится допплерометрия, только более расширенная. Врач изучает больше сосудистых зон ребеночка. 

Также проводится измерение ребенка, определяется предполагаемый вес к родам. Как правило, разбежка между предполагаемым весом и реальным весом ребенка – около 400-500 грамм.

Обсудим обвитие пуповиной. Действительно, на УЗИ это видно. Малыш постоянно и много двигается. Если у вас отметили обвитие на сроках до 38 недель, то помните, что это состояние не является постоянным. Поэтому волноваться, если доктор отметил обвитие пуповиной в протоколе УЗИ, не стоит.

Также врач оценивает количество околоплодных вод. Их количество может быть нормальным, может быть больше нормы (тогда это называется многоводие), или меньше нормы (это маловодие). Причин этих состояний очень много, а иногда это состояние требует лечения.

К сожалению, есть группа пороков, которые начинают развиваться очень поздно – только в 3 триместре. И предсказать их развитие на ранних стадиях практически нельзя.

${tb2c2_10}

Урок 6. Многоплодная беременность: развитие малышей

В последние два десятилетия количество многоплодных беременностей существенно растет. Отчасти это связано с увеличением возраста беременных и, соответственно, ростом количества беременностей, которые наступили в результате применения репродуктивных технологий.

Двойни и другие многоплодные беременности относятся к беременностям группы высокого риска из-за большого количества возможных осложнений как со стороны мамы, так и со стороны малышей. 

В зависимости от количества оплодотворенных яйцеклеток беременности двойней делятся на две группы:

Дизиготные
Монозиготные
Дизиготные (или двуяйцевые) беременности возникают при оплодотворении двух яйцеклеток двумя разными сперматозоидами. В результате формируется два плода, которые отличаются генетически, но развиваются в одной матке. У каждого малыша будет своя плацента. И они называются двойняшками.

${tb2c2_11}
Монозиготная двойня возникает, когда одна яйцеклетка оплодотворяется одним сперматозоидом, а затем она делится. То есть плоды генетически идентичны. Такие беременности встречаются реже. И таких малышей называют близнецами.
${tb2c2_12}

Очень важно, сколько плацент у малышей при двойнях. Плацента обеспечивает рост и развитие плодов, а также способствует взаимодействию между кровотоком мамы и малышей. Она выполняет множество функций – функцию питания, газообмена, выведения продуктов обмена. В плаценте вырабатываются гормоны, которые поддерживают и сохраняют беременность.

Если плацент две, то двойня называется дихориальной. Если плацента одна, двойня называется монохориальной. Такая беременность встречается реже, и в таком случае могут возникнуть специфические осложнения. Количество плацент можно определить уже на УЗИ в первом триместре.

${tb2c2_13}

В чем опасность ситуации, когда плацента одна? Плоды будут как будто пытаться разделить эту плаценту. Кому-то достается при этом больше питания, кому-то – меньше. Кто-то страдает, а кто-то получает питательные вещества в избытке. В самых сложных ситуациях это может привести даже к гибели одного из плодов.

К сожалению, профилактики развития этих состояний нет. Но мы можем активно наблюдать за детьми при помощи УЗИ и при возникновении малейших предвестников этих состояний сразу назначать лечение.

Именно поэтому во время многоплодной беременности женщине рекомендуется чаще ходить на УЗИ и плановые осмотры. Особенно если это монохориальная двойня, то есть когда плацента одна. Женщине рекомендуется приходить на УЗИ-осмотры 1 раз в две недели.

УЗИ при многоплодной беременности

Именно на УЗИ устанавливается сам факт многоплодия. Чаще всего это происходит на 6-7 неделе беременности. Также на УЗИ устанавливается количество плацент.

Также, в зависимости от количества плацент, врач УЗИ совместно с врачом-гинекологом составляет план ведения беременности. 

Что касается пола малышей, то его, так же как и при одноплодной беременности, устанавливают и озвучивают на скрининговом УЗИ-осмотре в 18-21 неделю.

С 29-30 недель за самочувствием малышей можно наблюдать также при помощи КТГ. Но делать это не всегда просто. Сначала с помощью акушерского стетоскопа определяют зону, где наиболее четко определяется сердцебиение малыша. Далее в эту зону устанавливается датчик КТГ, и начинается запись сердцебиения малыша. Это может быть поочередная запись – сначала одного малыша, а затем другого. А может быть и параллельная запись, то есть одновременная. Это зависит от возможностей оснащения.

Если запись сердцебиения происходит последовательно, то, конечно, эта процедура займет вдвое больше времени, и будущей маме нужно быть к этому готовой.

Может ли сама мама следить за шевелениями малышей? Да, конечно. Удивительно, но чаще всего мама четко может отмечать, где и какой малыш находится и когда и кто из них шевелится.

В остальном все этапы развития плодов при многоплодной беременности наступают в те же сроки, что и при одноплодной беременности. Поэтому будущая мама может ориентироваться на обычный календарь для беременных.

Безусловно, многоплодная беременность – это непростая ситуация. Но двойное или тройное счастье материнства оставляет все тревоги и опасения позади.

${separator}

Урок 7. Как можно наблюдать за внутриутробным состоянием плода?

Обсудим, как можно наблюдать за внутриутробным состоянием малыша.

УЗИ

Это основной инструмент мониторинга за состоянием малыша. На первый основной УЗИ-осмотр будущей маме рекомендуется прийти в 11-13 недель беременности. На нем, кроме всего прочего, определяются маркеры хромосомных отклонений. 

Кроме УЗИ, для полноты обследования в первом триместре беременные также могут сдавать анализ крови на хромосомные отклонения – это так называемый биохимический скрининг первого триместра. В крови мамы исследуют вещества, которые выделяет плод. И в зависимости от уровня этих веществ, можно предположить наличие или отсутствие у него хромосомных изменений. Это обследование широко распространено в нашей стране.

Пару слов о тренде последней декады – это анализ НИПТ, или неинвазивный пренатальный тест. В крови мамы исследуют ДНК плода. Анализ дорогой, и на данный момент не выполняется у нас в стране. Образцы крови мамы исследуются в лабораториях за границей. Анализ помогает выявить большое количество хромосомных отклонений.

Посещение врача-генетика

Бывает, что на основании УЗИ-осмотра первого триместра и биохимического скрининга, а также возраста будущей мамы, выявляется повышенный риск развития хромосомных отклонений плода. В таких случаях беременной рекомендуют посетить прием врача-генетика. 

Врач-генетик на основании всей имеющейся информации – УЗИ, биохимического скрининга, данных о беременной (имелись ли у нее в прошлом какие-либо проблемы со здоровьем) – рассчитывает риск развития проблем во время настоящей беременности.

Также в арсенале генетиков имеются диагностические процедуры:

Биопсия ворсин хориона (хорион – это будущая плацента).
Амниоцентез (взятие околоплодных вод). 
Цель этих исследований – получить материал малыша. Этот материал исследуется, и в заключении мы знаем кариотип ребенка – то есть его набор хромосом. Соответственно, мы можем точно знать, что у него нет хромосомных отклонений. Необходимость проведения этих процедур может определить только врач-генетик.

Также будущей маме рекомендуется прийти на еще два плановых УЗИ во 2 и 3 триместре.

КТГ

На сроке ближе к 30 неделям беременной проводят КТГ – кардиотокографию. На живот будущей мамы крепят специальный датчик, который в виде графических сигналов записывает сердцебиение ребенка. И в зависимости от полученной записи можно делать вывод о его самочувствии.

Когда у малыша все хорошо, то сердцебиение у него ритмичное, частое и учащается при шевелениях.

Почему КТГ проводится только после 30 недель? Это связано со зрелостью плода. Именно в этот срок созревают центры в головном мозге, которые отвечают за двигательную активность, суточные циклы, а также за изменения частоты сердечных сокращений плода при его собственных движениях.

Допплерометрия

КТГ часто оценивают вместе с проведением допплерометрии. Это оценка кровотока в сосудах мамы и малыша. 

Если кровотоки не нарушены, то есть кровь с питательными веществами и кислородом поступает к ребенку беспрепятственно, от него забирает углекислый газ и продукты обмена – значит, у малыша все в порядке. 

Допплерометрия проводится с 20 недель беременности.

Хорошее самочувствие малыша представляет собой наличие:

Общей двигательной активности. Так называемых больших движений, то есть движений всего туловища малыша.
Наличия тонуса, то есть сгибания, разгибания ножек, раскрытия и закрытия кистей рук.
Наличия дыхательных движений. Да-да, дыхательных, вы не ослышались! Мы можем наблюдать одновременное сокращение мышц диафрагмы и передней брюшной стенки малыша.
Если доктор во время проведения УЗИ все это хорошо увидел, также оценил количество околоплодных вод и оно в пределах нормы, и мама имеет хороший результат КТГ – у вас малыш-отличник! У него все хорошо!

Тест «Считай до 10» 

Кроме УЗИ и КТГ, есть и другие методы диагностики состояния малыша – такие, которые может выполнять сама мама. Вы можете следить за его шевелениями. Существует большое количество способов слежения, самый простой из них – считать до 10. За 12 часов (например с 9 утра до 9 вечера) вы должны слышать малыша минимум 10 раз.


    `,
};
