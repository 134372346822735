import { FC, memo } from "react";
import styles from './TextButton.module.css';


const TextButton: FC<{
  title: string;
  onClick: () => void;
}> = memo(({
  title,
  onClick,
}) => {
  return (
    <button 
      className={ styles.container }
      onClick={ onClick }
    >
      { title }
    </button>
  )
});

export default TextButton;
